/* eslint-disable jsx-a11y/alt-text */
import { inject, observer } from "mobx-react";
import { Colors } from "../../Assets/Config/color.config";
import evImage from "../../Assets/Images/EvStations.png";
import licenseImage from "../../Assets/Images/icons/driving-license.png";
import fuelTypeImage from "../../Assets/Images/Battery.png";
import batteryTypeImage from "../../Assets/Images/icons/fuel.png";
import { FuelForm } from "./FuelForm";
import Cookies from "universal-cookie";
import { useEffect, useState } from "react";
import { CO2_Loading } from "../../Assets/CO2_Loading";
import { toJS } from "mobx";
import { baseURL } from "../../network/URL";
import { useTranslation } from "react-i18next";
import { ta } from "date-fns/locale";

export const FuelRecord = inject("store")(
  observer((props) => {
    const {t} = useTranslation("mobile")
    const cookies = new Cookies();
    const vehicleId = cookies.get("vehicleId");
    const default_img = "/cars/default_vehicle.png";

    const { selectedVehicleInfo, _fetchVehicleInfo } = props.store.driver;
    const theme = localStorage.getItem("theme");

    useEffect(() => {
      _fetchVehicleInfo(vehicleId);
    }, []);

    console.log("selectedVehicleInfo", toJS(selectedVehicleInfo));

    // const [imgUrl, setImgUrl] = useState(`/cars/default_vehicle.png`);

    // useEffect(() => {
    //   setImgUrl(`/cars/${selectedVehicleInfo?.vehicle_number_plate ? selectedVehicleInfo?.vehicle_number_plate + ".jpg" : "default_vehicle.png"}`)
    // }, [selectedVehicleInfo])
    return (
      <>
        {selectedVehicleInfo ? (
          <>
            <div className="col-12 col-sm-12 col-lg-6 col-xl-6 pt-2">
              <div
                className="d-flex align-items-center"
                style={{ color: Colors.Dark[5], fontSize: 20 , marginTop: '1em'}}
              >
                <img className="pe-2" width="40px" src={evImage} />
                {t("Fuel Record")}
              </div>
              <div className="pt-3 text-center">
                <span className="col-6 col-lg-3 col-xl-3 col-sm-6">
                  <img
                    className="col-8"
                    style={{ borderRadius: 5 }}
                    src={
                      selectedVehicleInfo.image
                        ? `${baseURL}/uploads/devices/${selectedVehicleInfo.image}`
                        : default_img
                    }
                  />
                </span>
                {/* <div
            className="col-6"
            style={{ color: Colors.Dark[5], fontSize: 12 }}
          >
            <span className="row pb-2">
              <span className="col-lg-3 col-xl-3 col-sm-6  col-6 p-0">
                Plate Number
              </span>
              <span className="col-lg-3 col-xl-3 col-sm-6 col-6 p-0">
                : 300T-TSC9
              </span>
            </span>

            <span className="row pb-2">
              <span className="col-lg-3 col-xl-3 col-sm-6 col-6 p-0">
                Fuel Type
              </span>
              <span className="col-lg-3 col-xl-3 col-sm-6 col-6 p-0">
                : Diesel
              </span>
            </span>

            <span className="row pb-2">
              <span className="col-lg-3 col-xl-3 col-sm-6 col-6 p-0">
                Modal
              </span>
              <span className="col-lg-3 col-xl-3 col-sm-6 col-6 p-0">
                : SKR013253
              </span>
            </span>

            <span className="row pb-2">
              <span className="col-lg-3 col-xl-3 col-sm-6 col-6 p-0">
                Brand
              </span>
              <span className="col-lg-3 col-xl-3 col-sm-6 col-6 p-0">
                : Hino
              </span>
            </span>
          </div> */}
              </div>

              <div
                className="d-flex justify-content-around pt-2"
                style={{ fontSize: 14 }}
              >
                <span className="text-white d-flex align-items-center">
                  <img src={licenseImage} width={25} className="me-1" />
                  {selectedVehicleInfo.license_plate}
                </span>
                <span className="text-white d-flex align-items-center">
                  <img
                    src={
                      selectedVehicleInfo.primary_fuel_type === "Petrol"
                        ? batteryTypeImage
                        : fuelTypeImage
                    }
                    width={25}
                    className="me-1"
                  />
                  {t(selectedVehicleInfo.primary_fuel_type)}
                </span>
              </div>
              <FuelForm
                fuelType={selectedVehicleInfo.primary_fuel_type}
                theme={theme}
              />
            </div>
          </>
        ) : (
          <CO2_Loading />
        )}
      </>
    );
  })
);
