import { Colors, invertDark } from "../../Assets/Config/color.config";
import { My_Button } from "../../Tools";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import { useEffect, useState } from "react";
import { baseURL } from "../../network/URL";
import { useTranslation } from "react-i18next";

export const DeleteCompanyModal = inject("store")(
    observer((props) => {
        const {t} = useTranslation('admin-panel')
        const { theme } = props;
        const { _handleRadioChange, companyOptionData, deleteCompanyOption, _handleCloseModel , GetChildCompanies, _fetchGetChildCompanies, GetAllSameLevelCompanies, _fetchGetAllSameLevelCompanies, userData, _handleInputChange, _fetchdeleteCompanyInfo} = props.store.adminPanel;

        useEffect(() => {
            _fetchGetChildCompanies(); 
            _fetchGetAllSameLevelCompanies();    
        }, []); 

        return (

            <div className="modal fade"
                id="deleteCompanyModal"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true">

                <div className="modal-dialog d-flex justify-content-center" style={{ minWidth: 800 }}>
                    <div className="modal-content" >
                        <div className="modal-header" style={{ backgroundColor: theme === "dark"?  "#091662" : "#2944DF", color:  "#FFFFFF" , borderBottom: 0, borderTopLeftRadius: 3, borderTopRightRadius: 3 }} >
                            <h1 className="modal-title fs-5" id="exampleModalLabel" style={{}}> {t("Delete")} {userData.name}  </h1>
                            <span data-bs-dismiss="modal" onClick={() => _handleCloseModel()} style={{ cursor: 'pointer' }}>
                                <i className="bi bi-x-lg" />   
                            </span>
                        </div>

                        <div className="modal-body row" style={{ background: theme ==="dark"? "#333A63" : "#FFFFFF", color: theme === "dark" ? "#ffffff" : "#303030", marginLeft: 0, marginRight: 0 }}>
                            <div className="col-6 ">
                                <input checked={companyOptionData.deleteCompanyOption === "childCompanies"} className="form-check-input" onChange={(e) => _handleRadioChange(e, 'deleteCompanyOption')} value="childCompanies" type="radio" name="deleteCompanyOption" id="childCompaniesRadio" style={{ marginRight: 1 }} />
                                <label className="form-check-label" for="childCompaniesRadio">
                                    {t("Delete All Child Companies")}
                                </label>
                            </div>
                            <div className="col-6">
                                <input checked={companyOptionData.deleteCompanyOption === "ownerShip"} className="form-check-input" onChange={(e) => _handleRadioChange(e, 'deleteCompanyOption')} value="ownerShip" type="radio" name="deleteCompanyOption" id="ownershipRadio" style={{ marginRight: 1 }} />
                                <label className="form-check-label" for="ownershipRadio">
                                    {t("Transfer Ownership")}
                                </label>
                            </div>

                            {companyOptionData.deleteCompanyOption === "childCompanies" &&
                                <div className="mt-2" style={{ background: theme==="dark" ? "#2E3459" : "#E6E6E6", display: "flex", justifyContent: "start", borderRadius: 3, maxHeight: 200, overflowY: "auto" }}>
                                    <div>
                                        <div className="mt-2" style={{ fontSize: 18 }}>{t("All these child companies will be permanently delete!")}</div>
                                        
                                        <div className="" style={{ textAlign: "left" }}>
                                        
                                        {GetChildCompanies?.map((v,k)=>{
                                            return(
                                                <div className="mb-2" style={{ marginLeft: "2em" }}>-<i className="bi bi-building" style={{ marginRight: "1.5em", marginLeft: "1em" }} /> {v.name} </div>
                                            )
                                        })}
                                        
                                        </div>
                                    </div>
                                </div>
                            }

                            {companyOptionData.deleteCompanyOption === "ownerShip" &&
                                <div className="mt-2" style={{ background: theme==="dark" ? "#2E3459" : "#E6E6E6", display: "flex", justifyContent: "start", borderRadius: 3, maxHeight: 200, overflowY: "auto" }}>
                                    <div>
                                        <div className="mt-2" style={{ fontSize: 18 , marginBottom: 2}}>t{("Transfer ownership to")}</div>
                                        <div className="" style={{ textAlign: "left" }}>
                                        {GetAllSameLevelCompanies.map((v,k)=>{
                                            return(
                                                <div className="mb-2" style={{ marginLeft: 2 }}><input className="form-check-input" value="ascent" type="radio" name="sameLevelCompanies" id="sameLevelCompaniesRadio" style={{ marginRight: "1.5em" }} />
                                                <img className="" style={{ width: 30, borderRadius: 4, marginRight: "1.5em" }} src={baseURL + v.logo}/>
                                                <label className="form-check-label" for="sameLevelCompaniesRadio">
                                                    {v.name}
                                                </label>
                                            </div>
                                            )
                                        })}
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>

                        {companyOptionData.deleteCompanyOption === "childCompanies" &&
                            <div className="modal-footer"  style={{ background: theme ==="dark"? "#333A63" : "#FFFFFF", color: "#ffffff", borderTop: 0, borderEndStartRadius: 3, borderEndEndRadius: 3 }}>
                                <button type="button" data-bs-dismiss="modal" className="btn btn-danger" style={{ minWidth: 100 }} onClick={() => _fetchdeleteCompanyInfo(userData.id)}>{t("Delete")}</button>
                            </div>
                        }

                        {companyOptionData.deleteCompanyOption === "ownerShip" &&
                            <div className="modal-footer" style={{ background: theme ==="dark"? "#333A63" : "#FFFFFF", color: "#ffffff", borderTop: 0, borderEndStartRadius: 3, borderEndEndRadius: 3 }}>
                                <button type="button" data-bs-dismiss="modal" className="btn btn-primary" style={{ minWidth: 100 }}>{t("Transfer")}</button>
                            </div>
                        }

                    </div>
                </div>
            </div>
        );
    }));
