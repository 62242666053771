import { inject, observer } from "mobx-react";
import React, { useState, useEffect } from "react";
import { Colors } from "../../Assets/Config/color.config";
import { Dropdown, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

export const ScopeCarbonSelector = inject("store")(
  observer((props) => {
    const { width, theme,_handleScopeCheckbox ,options} = props;

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);

    // const options = [
    //   { id: 0, label: "Baseline CO₂e Emission" },
    //   { id: 1, label: "Actual CO₂e Emission" },
    //   { id: 2, label: "Average CO₂e Emission" },
    // ];

    useEffect(() => {
      const allOptionLabels = options.map((option) => option.label);
      setSelectedOptions(allOptionLabels);
    }, []);

    const handleCheckboxChange = (option) => {
      const index = selectedOptions.indexOf(option.label);
      let updatedOptions = [...selectedOptions];
    //   console.log("index ===>", index);
      if (index === -1) {
        updatedOptions = [...selectedOptions, option.label];
      } else {
        updatedOptions = updatedOptions.filter(
          (label) => label !== option.label
        );
      }

      setSelectedOptions(updatedOptions);
      _handleScopeCheckbox(option.label);
    };

    const handleDropdownToggle = () => setShowDropdown(!showDropdown);

    return (
      <Dropdown show={showDropdown} onToggle={handleDropdownToggle}>
        <Dropdown.Toggle
          className="text-start"
          variant="secondary"
          id="dropdown-checkbox"
          style={{
            width: width,
            background:
              theme === "dark"
                ? "linear-gradient(65deg, #101841, #202b60)"
                : "rgb(246, 246, 246)",
            color: theme === "light" ? "black" : "silver",
            // border: theme === "dark" ? "1px solid #cfcfcf" : "1px solid black",
          }}
        >
          <div className="d-flex justify-content-between">
            <span>{`${selectedOptions.length} selected`}</span>
            <span>
              <i
                className="fa fa-angle-down"
                style={{
                  color: theme === "light" ? Colors.Dark[4] : "silver",
                  fontSize: "18px",
                  cursor: "pointer",
                }}
              />
            </span>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="light-dropdown-menu" style={{ width: width }}>
          {options.map((option) => (
            <div
              key={option.id}
              style={{
                paddingLeft: "10px",
                borderBottom: option.id === 3 ? "" : "1px solid black",
              }}
            >
              <label>
                {/* {console.log("checked true or false", selectedOptions.includes(option.label))} */}
                <Form.Check
                  type="checkbox"
                  id={`checkbox-${option.id}`}
                  label={option.label}
                  checked={selectedOptions.includes(option.label)}
                  onChange={() => handleCheckboxChange(option)}
                />
              </label>
            </div>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  })
);
