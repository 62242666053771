import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { My_Button, My_DatePicker, My_Input, Selector } from "../../Tools";
import { toJS } from "mobx";
import { useTranslation } from "react-i18next";

export const WebFormModal = inject("store")(
    observer((props) => {
        const {t} = useTranslation("lite-manual-entry")
        const { theme } = props.store.common;
        const { _handleTextChange, entryData, vehicleTypeOption, fuelTypeOption, assetListOption,
             fuelUnitOption, mileageUnitOption, reportTypeOption, _handleSubmit, _handleEdit, editMode, assetList } = props.store.liteEntry;
        
             console.log(toJS(assetListOption))
        return (
            <div
                className="modal fade overflow-scroll show"
                id="webformModal"
                tabIndex="-1"
                aria-labelledby="webformModal"
                aria-hidden="true"
            >

                <div className="modal-dialog modal-sm rounded">
                    <div
                        className="modal-content"
                        style={{ background: Colors[theme]?.background, color: invertDark(theme) }}
                    >
                        <div className="modal-header" style={{ borderBottom: `1px solid rgb(255,255,255,0.2)` }}>
                            <span style={{ fontSize: 20 }}>
                                {editMode ? t('Edit') : t('Co2X Lite Manual Entry')}
                            </span>
                            <span data-bs-dismiss="modal" style={{ cursor: 'pointer' }}>
                                <i className="bi bi-x-lg" />
                            </span>
                        </div>
                        <div className="modal-body">
                            <div className="mb-2">{t("Asset ID")}<Selector menuListColor='black' id='asset_id' value={assetListOption.filter(v => v.value === entryData.asset_id)} _handleSelect={(e) => _handleTextChange(e, 'asset_id')} options={assetListOption}/></div>
                            {/* <div id='vehicle_type' className="mb-2">Vehicle Type<Selector menuListColor='black' value={vehicleTypeOption.filter(v => v.value === entryData.vehicle_type)} _handleSelect={(e) => _handleTextChange(e, 'vehicle_type')} options={vehicleTypeOption} /></div>
                            <div className="mb-2">Fuel Type<Selector menuListColor='black' value={fuelTypeOption.filter(v => v.value === entryData.type)} _handleSelect={(e) => _handleTextChange(e, 'type')} options={fuelTypeOption} /></div>
                            <div className="mb-2">Fuel Units<Selector menuListColor='black' value={fuelUnitOption.filter(v => v.value === entryData.type_unit)} _handleSelect={(e) => _handleTextChange(e, 'type_unit')} options={fuelUnitOption} /></div> */}
                            <div className="mb-2">{t("Fuel consumed")}<My_Input type='number' id='consumed' value={entryData.consumed} onChange={(e) => _handleTextChange(e, 'consumed')} /></div>
                            <div className="mb-2">{t("Mileage")}<My_Input type='number' id='mileage' value={entryData.mileage} onChange={(e) => _handleTextChange(e, 'mileage')} /></div>
                            {/* <div id='mileage_unit' className="mb-2">Mileage Units<Selector menuListColor='black' value={mileageUnitOption.filter(v => v.value === entryData.mileage_unit)} _handleSelect={(e) => _handleTextChange(e, 'mileage_unit')} options={mileageUnitOption} /></div> */}
                            <div id='report_type' className="mb-2">{t("Report Type")}<Selector menuListColor='black' value={reportTypeOption.filter(v => v.value === entryData.report_type)} _handleSelect={(e) => _handleTextChange(e, 'report_type')} options={reportTypeOption} /></div>
                            {entryData.report_type === 'Monthly' ? <div id='time' className="mb-2">{t("Month")}
                                <My_DatePicker height={38} dateFormat='MMMM' selected={entryData.period_start} noTimeInput showMonthYearPicker onChange={(e) => _handleTextChange(e, 'month')} />
                            </div> :
                                <div id='time' className="mb-3">{t("Year")}
                                    <My_DatePicker height={38} dateFormat='yyyy' selected={entryData.period_start} noTimeInput showYearPicker onChange={(e) => _handleTextChange(e, 'year')} />
                                </div>}
                            <My_Button onClick={() => editMode?_handleEdit(): _handleSubmit()} customHoverColor='#0d6efde0' customColor='#0d6efd' style={{ height: 40, width:'100%' }} className='col-3  ' text={t('Submit')} />
                        </div>
                    </div>
                </div>
            </div >
        );

    })
);
