/* eslint-disable react/jsx-pascal-case */
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import * as React from "react";

import {
    Pagination,
    PaginationItem,
    styled,
    TableSortLabel,
} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { My_Button } from "../../Tools";
import { Button } from "react-bootstrap";
import { ChangeOwnerModal } from "./ChangeOwnerModal";
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import { toJS } from "mobx";
import { useTranslation } from "react-i18next";

export const UnitsTable = inject("store")(
    observer(
        withRouter((props) => {
            const {t} = useTranslation("asset_profile_management")
            const { theme, 
                    _handleViewContract, 
                    noPagination, 
                    landingAscent, 
                    lvlCompanies, 
                    filteredData, 
                    page, 
                    setPage,
                } = props;
           
            const {
                deviceList,
                _handleSelectChange,
                _handleEdit,
                _setToDelete,
                companyId,
                setGpsChecked,
                setCanbusChecked,
                
            } = props.store.assetProfile;

            const {
                role
            } = props.store.common;

            // const [selectedCompany, setSelectedCompany] = React.useState()
            const [unitId, setUnitId] = React.useState()

            // const [checkedGps, setCheckedGps] = React.useState()
            // const [checkedCanbus, setCheckedCanbus] = React.useState()

            const Key = [
                "company",
                "grouping",
                "plate_number",
                "model",
                "cargo_type",
                "fuel_type",
                "brand",
                "oem",
                "gps",
                "CAMbus",
                "action",
            ];

            const [order, setOrder] = React.useState("asc");
            const [orderBy, setOrderBy] = React.useState("");
            const [selectedIndex, setSelectedIndex] = React.useState("");
            console.log("selectedIndex", selectedIndex)

            const handleRequestSort = (event, property) => {
                const isAsc = orderBy === property && order === "asc";
                setOrder(isAsc ? "desc" : "asc");
                console.log("property", property);
                setOrderBy(property);
            };


            const headers = [
                { label: t("Company"), key: "company" },
                { label: t("Grouping"), key: "grouping" },
                { label: t("Plate No."), key: "plate_number" },
                { label: t("Model"), key: "model" },
                { label: t("Cargo type"), key: "cargo_type" },
                { label: t("Fuel type"), key: "fuel_type" },
                { label: t("Brand"), key: "brand" },
                {
                    label: "OEM",
                    key: "oem",
                    icon: <i className="fas fa-info-circle"
                        title="OEM Baseline Emission (gCO2e/km)"
                        style={{ display: "flex-row", cursor: "pointer", color: "rgb(198, 198, 198)" }}
                    />
                },
                { label: "GPS", key: "gps" },
                { label: t("CANbus"), key: "CAMbus" },
                { label: t("Action"), key: "action" },
            ];



            const StyledTableRow = styled(TableRow)(({ theme }) => ({
                cursor: "pointer",
                "&:nth-of-type(odd)": {
                    backgroundColor:
                        props.theme === "dark" ? Colors.darkBlue : Colors.Dark[5],
                    color: theme.palette.common.white,
                },
                // hide last border
                "&:last-child td, &:last-child th": {
                    border: 0,
                },
            }));

            const rowCount = 8;
            const handleChange = (event, value) => {
                setPage(value);
            };



            function EnhancedTableHead(props) {
                const {
                    row,
                    order,
                    orderBy,
                    onRequestSort,
                    theme,
                    Key,
                    _handleViewContract,
                } = props;

                const createSortHandler = (property) => (event) => {
                    onRequestSort(event, property);
                };
                return (
                    <TableHead>
                        <TableRow>
                            {headers.map((headCell, index) => (
                                <TableCell
                                    key={index}
                                    align={"left"}
                                    padding={headCell.disablePadding ? "none" : "normal"}
                                    sortDirection={orderBy === headCell.id ? order : false}
                                    style={{ background: theme === "dark" ? Colors.blackblue : invertDark(theme) }}
                                >
                                    {headCell.label === "" || (
                                        <TableSortLabel
                                            style={{
                                                minWidth: 80,
                                                color:
                                                    orderBy === headCell.id
                                                        ? Colors.activeColor
                                                        : theme === "light"
                                                            ? "#F6F6F6"
                                                            : Colors.Dark[4],
                                            }}
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : "asc"}
                                            onClick={createSortHandler(Key[index])}
                                        >
                                            <span className="fw-bold">{headCell.label} {headCell.icon}</span>
                                        </TableSortLabel>
                                    )}

                                </TableCell>
                            ))}

                        </TableRow>
                    </TableHead>
                );
            }

            function descendingComparator(a, b, orderBy) {
                const A = a[orderBy] ? a[orderBy].trim() : undefined;
                const B = b[orderBy] ? b[orderBy].trim() : undefined;
                if (B < A) {
                    return -1;
                }
                if (B > A) {
                    return 1;
                }
                return 0;
            }

            function getComparator(order, orderBy) {
                return order === "desc"
                    ? (a, b) => descendingComparator(a, b, orderBy)
                    : (a, b) => -descendingComparator(a, b, orderBy);
            }

            const useStyles = makeStyles({
                foo: {
                    "& .MuiPagination-ul": { justifyContent: "end" },
                    "& .MuiPaginationItem-text": {
                        color: Colors.Dark[theme === "dark" ? 4 : 1],
                    },
                    "& .Mui-selected": {
                        backgroundColor: Colors.Dark[4],
                        color: Colors.darkBlue,
                    },
                },
            });

            const classes = useStyles();

            return (
                <React.Fragment>
                    <TableContainer
                        component={Paper}
                        style={{ background: "none" }}
                        sx={{ maxHeight: 600 }}
                    >
                        <Table
                            stickyHeader
                            sx={{ minWidth: 650 }}
                            size="small"
                            aria-label="a dense sticky table"
                        >
                            <EnhancedTableHead
                                _handleViewContract={_handleViewContract}
                                Key={Key}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                theme={theme}
                            />
                            <TableBody>
                                {filteredData?.length > 0 &&
                                    // map Rows when data is fetched correctly
                                    filteredData
                                        .sort(getComparator(order, orderBy))
                                        .slice((page - 1) * rowCount, page * rowCount)
                                        .map((row, index) => (

                                            <StyledTableRow
                                                style={{ background: selectedIndex === row.id ? Colors.Dark[3] : "" }}
                                                key={index}
                                                sx={{
                                                    "&:last-child td, &:last-child th": { border: 0 },
                                                }}
                                            >
                                                {Object.values(row)
                                                
                                                    // .filter((f) => {
                                                    //     return !f?.toString().includes('_');
                                                    // })
                                                    .map((d, x) => (
                                                        x !== 0 && x !== 1 && x !== 2 && x !== 3 &&
                                                        <TableCell
                                                            sx={{
                                                                color: invertDark(theme),
                                                                borderBottom: "none",
                                                            }}
                                                            key={x}
                                                        >
                                                            {d === "view" ? (
                                                                role === "user" ? 
                                                                <>
                                                                </> :
                                                                    <div className="d-flex justify-content-between align-items-center me-3">
                                                                        <div>
                                                                            {role === "super_admin" && companyId === row.companyId ?
                                                                                <Button type="button"
                                                                                    data-bs-toggle="modal"
                                                                                    data-bs-target="#changeownermodal"
                                                                                    data-toggle="modal"
                                                                                    data-target=".bd-example-modal-lg"
                                                                                    onClick={() => {
                                                                                        _handleSelectChange(row.companyId)
                                                                                        setUnitId(row.id)
                                                                                        setCanbusChecked(row.enableCANbus)
                                                                                        setGpsChecked(row.enableGPS)
                                                                                    }}
                                                                                    style={{ width: '120px'}}
                                                                                    className="btn btn-sm m-1">Change Owner</Button> : ''}

                                                                        </div>


                                                                        <div className="d-flex">
                                                                            {landingAscent.enableSetupUnit === 1 ?
                                                                                <span className="py-2">
                                                                                    <Button type="button"
                                                                                        data-bs-toggle="modal"
                                                                                        data-bs-target="#addVehicleModal"
                                                                                        data-toggle="modal"
                                                                                        data-target=".bd-example-modal-lg"
                                                                                        onClick={() => {
                                                                                            _handleEdit(row)
                                                                                        }}
                                                                                        className="btn btn-sm m-1">
                                                                                        <i className="fas fa-edit"></i>
                                                                                    </Button>
                                                                                </span> : <></>}

                                                                            {landingAscent.enableSetupUnit === 1 ?
                                                                                <span className="py-2">
                                                                                    <Button type="button"
                                                                                        onClick={() => {
                                                                                            _setToDelete(row)
                                                                                        }}
                                                                                        className="btn btn-danger btn-sm m-1"
                                                                                        data-bs-toggle="modal"
                                                                                        data-bs-target="#confirmationModal"
                                                                                        data-toggle="modal"
                                                                                        data-target=".bd-example-modal-lg">
                                                                                        <i className="bi bi-trash-fill" title="Delete" />
                                                                                    </Button>
                                                                                </span> : <></>}
                                                                        </div>


                                                                    </div>
                                                            ) : (
                                                                d === "true" ? <i className="fa fa-check pe-1" style={{ color: Colors.low }} /> : d === "false" ? <i style={{ color: Colors.high }} className="fas fa-times pe-1"></i> : d
                                                            )}
                                                        </TableCell>
                                                    ))}
                                            </StyledTableRow>
                                        ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <ChangeOwnerModal theme={theme} lvlCompanies={lvlCompanies} unitId={unitId} deviceList={deviceList} />
                    {noPagination || (
                        <div className="py-2">
                            <Pagination
                                size="small"
                                renderItem={(item) => (
                                    <PaginationItem
                                        {...item}
                                        sx={{ color: invertDark(theme) }}
                                        classes={{ selected: classes.selected }}
                                    />
                                )}
                                className={classes.foo}
                                count={
                                    filteredData.length <= rowCount
                                        ? 1
                                        : Math.ceil(filteredData.length / rowCount)}
                                defaultPage={1}
                                page={page}
                                siblingCount={1}
                                boundaryCount={1}
                                onChange={handleChange}
                            />
                        </div>
                    )}
                </React.Fragment>
            );
        })
    )
)