import React from "react";
import { inject, observer } from "mobx-react";
import { Colors } from "../../Assets/Config/color.config";

const Playlist = inject("store")(
  observer((props) => {
    const { onVideoSelect, videos, theme, selectedVideo } = props;

    const isSelectedVideo = (video) => {
      return (
        selectedVideo &&
        video.snippet.resourceId.videoId ===
          selectedVideo.snippet.resourceId.videoId
      );
    };
    const orderedVideos = [...videos].sort((a, b) => {
      const titleA = a.snippet.title.toUpperCase();
      // console.log('titleA',titleA)
      const titleB = b.snippet.title.toUpperCase();
      // console.log('titleB',titleB)
      if (titleA < titleB) {
        return -1;
      }
      if (titleA > titleB) {
        return 1;
      }
      return 0;
    });
    return (
      <div
        style={{
          color: theme === "dark" ? "rgb(198, 198, 198)" : "#000",
        }}
        className="mt-2 ms-2"
      >
        <h5
          className="ms-2 mt-1 mb-3"
          style={{
            color: theme === "dark" ? "rgb(198, 198, 198)" : "#000",
          }}
        >
          CO2X Enterprise Tutorial Video
        </h5>
        {orderedVideos?.map((video) => {
          const { snippet = {} } = video;
          const { title, thumbnails = {}, videoOwnerChannelTitle } = snippet;
          const { medium = {} } = thumbnails;
          return (
            <div
              key={video.snippet.resourceId.videoId}
              className="mb-2 card p-1 ms-2 playlist-card"
              style={{
                cursor: "pointer",
                background: Colors[theme].background,
                border: isSelectedVideo(video)
                  ? theme === "dark"
                    ? "1px solid #fff"
                    : "1px solid black"
                  : "",
                color: isSelectedVideo(video)
                  ? theme === "dark"
                    ? "rgb(198, 198, 198)"
                    : "#000"
                  : theme === "dark"
                  ? "rgb(198, 198, 198)"
                  : "rgba(0,0,0,0.6)",
                boxShadow: isSelectedVideo(video)
                  ? "0px 4px 8px rgba(38, 38, 38, 0.2)"
                  : "",
                top: isSelectedVideo(video) ? "-4px" : "",
              }}
              onClick={() => onVideoSelect(video)}
            >
              <div className="p-1 row-12 d-flex">
                <div
                  className="col-md-4 p-1"
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <img
                    width="140px"
                    height="83px"
                    src={medium.url}
                    alt={title}
                  />
                </div>
                <div className="ms-0 col-md-8 video-playlist">
                  <div className="mb-3">
                    <span className="" >
                      {title}
                    </span>
                  </div>
                  <div className="">
                    <i className="" >
                      {videoOwnerChannelTitle}
                    </i>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  })
);

export default Playlist;
