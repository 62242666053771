import { Colors } from "../Assets/Config/color.config";
import { withRouter } from "react-router";
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import jwt_decode from "jwt-decode";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";

const SideBar = inject("store")(
  observer((props) => {
    const cookies = new Cookies();
    const { theme } = props;
    const token = cookies.get("accessToken");
    const cookieCompanyId = cookies.get("company");
    const decoded = jwt_decode(token);
    const decodedCompanyId = decoded.cookieCompanyId;
    const companyName = cookies.get("companyName");
    const planData = token && jwt_decode(token).planData;
    const data_import_access = cookies.get("data_import_access");

    const { role, companyId, companyType } = props.store.common;
    const { handleTableChange } = props.store.dataImport;
    const {
      _handleFetchDataImportAccess,
      access_status,
      _handleViewCertificate,
      certificateView,
    } = props.store.dashboard;
    const sideBarContainer = {
      height: "100vh",
      position: "fixed",
      left: "10px",
      paddingTop: "60px",
      width: "50px",
      fontSize: "14px",
      zIndex: 200,
    };
    const sideBarStyle = {
      background:
        theme === "dark"
          ? Colors[theme].background
          : Colors[theme].primaryColor,
      left: "10px",
      borderRadius: "10px",
      color: Colors.Dark[5],
      boxShadow: `1px 1px 5px #303030`,
      // #303030
      marginTop: "10px",
    };
    const selectedStyle = {
      background:
        theme === "dark" ? Colors.lightBlue : Colors[theme].secondaryColor,
      paddingTop: "auto",
      borderRadius: 5,
      boxShadow: "1px 1px 5px #303030",
      height: "34px",
      lineHeight: "32px",
      textDecoration: "none",
      color: Colors.Dark[5],
    };

    const defaultStyle = {
      cursor: "pointer",
    };

    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
      _handleFetchDataImportAccess();
      window.addEventListener("resize", () => {
        setWidth(window.innerWidth);
      });

      return () => {
        window.removeEventListener("resize", () => {
          setWidth(window.innerWidth);
        });
      };
    }, [width]);

    // useEffect(()=>{
    //   _handleFetchDataImportAccess()
    // },[])
    const _handleSideIconClick = (e, id) => {
      e.preventDefault();
      // if(id === "Certificate"){
      //   id="organisation-level-carbon-emission-dashboard"
      //   if(!certificateView)
      //   _handleViewCertificate();
      // }
      // else{
      //   if(certificateView)
      //   _handleViewCertificate();
      // }
      props.history.push("/" + id);
      const domain = window.location.hostname;
      cookies.remove("storedTable", { path: "/", domain: domain });
      handleTableChange("table1");
    };

    const sidebarMenuFms = [
      {
        id:
          planData?.enterprise_overview_access == 1 &&
          "organisation-level-carbon-emission-dashboard",
        text: "Organisation-level",
        icon: "fa fa-home",
        link: "/organisation-level-carbon-emission-dashboard",
        access: planData?.enterprise_overview_access,
      },
      {
        id: "carbon_emission_reduction_certificate",
        text: "GHG Emission Reduction Statement",
        icon: "fa fa-award",
        link: "/carbon_emission_reduction_certificate",
        access: role === "user" ? 0 : planData?.enterprise_report_access,
      },
      {
        id: "new-tracking",
        text: "Unit-level",
        icon: "bi bi-pin-map-fill",
        link: "/new-tracking",
        access: planData?.unit_level_access,
      },
      {
        id: "track_and_trace",
        text: "GHG Emissions Report",
        // text: "Not available yet!",
        icon: "fa fa-file-contract",
        link: "/track_and_trace",
        access: role === "user" ? 0 : planData?.enterprise_report_access,
        //access : 0
      },
      {
        id: "asset_profile_management",
        text: "Unit",
        icon: "bi bi-cpu",
        link: "/asset_profile_management",
        access: planData?.unit_access,
      },
    ];

    if (companyType === "1") {
      sidebarMenuFms.push({
        id: "new-manual-entry",
        text: "Data Entry",
        icon: "fa fa-keyboard",
        link: "/new-manual-entry",
        access: planData?.data_entry_access,
      });

      if (role === "super_admin" || access_status === "1") {
        sidebarMenuFms.push({
          id: "data_import",
          text: "Data Import",
          icon: "fa fa-upload",
          link: "/data_import",
          access:
            role === "super_admin" ||
            (companyId === cookieCompanyId && role !== "user")
              ? 1
              : role === "user"
              ? 0
              : planData?.data_import_access,
        });
      }
    } else {
    }

    const sidebarMenu = [
      {
        id: "organisation-level-carbon-emission-dashboard",
        text: "Organisation-level",
        icon: "fa fa-home",
        link: "/organisation-level-carbon-emission-dashboard",
        access: planData?.enterprise_overview_access,
      },
      {
        id: "carbon_emission_reduction_certificate",
        text: "GHG Emission Reduction Statement",
        icon: "fa fa-award",
        link: "/carbon_emission_reduction_certificate",
        access: planData?.enterprise_overview_access,
      },
      {
        id: "new-tracking",
        text: "Unit-level",
        icon: "bi bi-pin-map-fill",
        link: "/new-tracking",
        access: planData?.unit_level_access,
      },
      {
        id: "track_and_trace",
        // text: "GHG Emissions Report",
        text: "Not available yet!",
        icon: "fa fa-file-contract",
        link: "/track_and_trace",
        access: role === "user" ? 0 : planData?.enterprise_report_access,
        //access : 0
      },
      {
        id: "asset_profile_management",
        text: "Unit",
        icon: "bi bi-cpu",
        link: "/asset_profile_management",
        access: planData?.unit_access,
      },
      {
        id: "new-manual-entry",
        text: "Data Entry",
        icon: "fa fa-keyboard",
        link: "/new-manual-entry",
        access: planData?.data_entry_access,
      },
      {
        id: "lite-dashboard",
        text: "Lite Dashboard",
        icon: "bi bi-speedometer",
        link: "/lite-dashboard",
        access: planData?.co2xLite_overview_access,
      },
      {
        id: "lite-manual-entry",
        text: "Lite Manual Entry",
        icon: "far fa-keyboard",
        link: "/lite-manual-entry",
        access: planData?.co2xLite_report_and_dataEntry_access,
      },
      {
        id: "data_import",
        text: "Data Import",
        icon: "fa fa-upload",
        link: "/data_import",
        access:
          role === "user" || companyName === "Co2xLite Demo"
            ? 0
            : role === "super_admin" || companyId === cookieCompanyId
            ? 1
            : planData?.data_import_access,
        // access: 1,
      },

      {
        id: "manual_entry",
        text: "Data Entry",
        icon: "fa fa-keyboard",
        link: "/manual_entry",
      },
    ];
    return width > 701 ? (
      <div style={sideBarContainer}>
        <div style={sideBarStyle}>
          {planData?.plan_name === "CO2XEnterprise"
            ? sidebarMenuFms?.map((v, k) => (
                <div className="px-2 py-3 text-center" key={k} title={v.text}>
                  <div
                    onClick={(e) =>
                      v.access === 1 && _handleSideIconClick(e, v.id)
                    }
                    style={
                      window.location.pathname.includes(v.id)
                        ? selectedStyle
                        : defaultStyle
                    }
                  >
                    <i
                      id={v.id}
                      style={{ opacity: v.access !== 1 ? 0.3 : 1 }}
                      className={v.icon}
                    />
                    {/* {(v.access === 1) && (v.id === 'track_and_trace') && <span style={{ position: 'relative', top: 5, right: 4, fontSize: 10, color: '#ffec67' }}>
                                <i className="fas fa-wrench"></i>
                            </span>} */}
                    {v.access !== 1 && (
                      <span
                        style={{
                          position: "relative",
                          top: -12,
                          right: 0,
                          fontSize: 12,
                          color: "#ffec67",
                        }}
                      >
                        <i className="bi bi-lock-fill"></i>
                      </span>
                    )}
                  </div>
                </div>
              ))
            : sidebarMenu?.map((v, k) => (
                <div className="px-2 py-3 text-center" key={k} title={v.text}>
                  <div
                    onClick={(e) =>
                      v.access === 1 && _handleSideIconClick(e, v.id)
                    }
                    // style={
                    //   window.location.pathname.includes(v.id) && !certificateView
                    //     ? selectedStyle
                    //     : (v.id === "Certificate" && certificateView?selectedStyle:defaultStyle)
                    // }
                    style={
                      window.location.pathname.includes(v.id)
                        ? selectedStyle
                        : defaultStyle
                    }
                  >
                    <i
                      id={v.id}
                      style={{ opacity: v.access !== 1 ? 0.3 : 1 }}
                      className={v.icon}
                    />
                    {v.access !== 1 && (
                      <span
                        style={{
                          position: "relative",
                          top: -12,
                          right: 0,
                          fontSize: 12,
                          color: "#ffec67",
                        }}
                      >
                        <i className="bi bi-lock-fill"></i>
                      </span>
                    )}
                  </div>
                </div>
              ))}
        </div>
      </div>
    ) : null;
  })
);
export default withRouter(SideBar);
