

const USR = "USR";
const SITE="SITE";
const setUserInfo = (user) => {
    var CryptoJs = require("crypto-js");
    // console.log(user)
    var ciphertext = CryptoJs.AES.encrypt(JSON.stringify(user), "ADSUSER")
    localStorage.setItem(USR, ciphertext.toString());
}

const getUserInfo = () => {
    var CryptoJs = require("crypto-js");
    var sessi = localStorage.getItem(USR);
    if (!sessi) return false;

    var bytes = CryptoJs.AES.decrypt(sessi, "ADSUSER");
    // console.log(bytes)
    var decryptedData = JSON.parse(bytes.toString(CryptoJs.enc.Utf8));
    return decryptedData;
}

const removeUser = () => {
    return localStorage.removeItem(USR)
}

const setSelectedSite = (user) => {
    var CryptoJs = require("crypto-js");
    // console.log(user)
    var ciphertext = CryptoJs.AES.encrypt(JSON.stringify(user), "ADSSITE")
    localStorage.setItem(SITE, ciphertext.toString());
}

const getSelectedSite = () => {
    var CryptoJs = require("crypto-js");
    var sessi = localStorage.getItem(SITE);
    if (!sessi) return false;

    var bytes = CryptoJs.AES.decrypt(sessi, "ADSSITE");
    // console.log(bytes)
    var decryptedData = JSON.parse(bytes.toString(CryptoJs.enc.Utf8));
    return decryptedData;
}

const removeSite = () => {
    return localStorage.removeItem(SITE)
}

const user = {
    setUserInfo,
    getUserInfo,
    removeUser,
    setSelectedSite,
    getSelectedSite,
    removeSite
}

export default user;