import React from "react";
import { CookiesProvider } from "react-cookie";
import "react-toastify/dist/ReactToastify.css";
import PasswordReset from "../../Components/PasswordReset/PasswordReset";
import common from "../../Store/common";
import { useTranslation } from "react-i18next";

const PasswordResetContainer = ({ user }) => {
  const errStyle = {
    color: "red",
    fontSize: 12,
    marginTop: "-20px",
  };

  const { theme } = common;
  const { t } = useTranslation("admin-panel");

  // console.log("user:", user); 

  return (
    <CookiesProvider>
      {/* Render PasswordReset component with props */}
      <PasswordReset errStyle={errStyle} theme={theme} t={t} userId={user}/>
    </CookiesProvider>
  );
};

export default PasswordResetContainer;
