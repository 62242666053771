import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
// import TableCell from '@mui/material/TableCell';
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Colors, invertDark } from "../Assets/Config/color.config";
import moment from "moment";
import MuiTableCell from "@mui/material/TableCell";
import { withStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { toJS } from "mobx";
import { CO2_Loading } from "../Assets/CO2_Loading";
import { useTranslation } from "react-i18next";

const TableCell = withStyles({
  root: {
    borderBottom: "none",
    paddingTop: 1,
    paddingBottom: 1,
  },
})(MuiTableCell);

function createData(
  id,
  month,
  energy_consumption,
  gas_diesel_consumption,
  gas_petrol_consumption,
  total_distance,
  current_emission,
  baseline_emission,
  offset,
  certificate,
  detail,
  companyType
) {
  if (companyType === "1") {
    return {
      id,
      month,
      energy_consumption,
      gas_diesel_consumption,
      gas_petrol_consumption,
      total_distance,
      current_emission,
      baseline_emission,
      offset,
      certificate,
      detail,
    };
  } else {
    return {
      id,
      month,
      energy_consumption,
      gas_diesel_consumption,
      gas_petrol_consumption,
      current_emission,
      baseline_emission,
      offset,
      certificate,
      detail,
    };
  }
}

function Row(props) {
  const { t } = useTranslation("dashboard");
  const {
    row,
    index,
    theme,
    setSelectedCertificateId,
    certificates,
    setShowCertiTour,
    companyType,
  } = props;
  const [open, setOpen] = React.useState(false);
  const rotate = open ? "rotate(180deg)" : "rotate(0deg)";
  const isLoading = certificates === null;

  return (
    <React.Fragment>
      {isLoading ? (
        <CO2_Loading />
      ) : (
        <React.Fragment>
          <TableRow
            style={{
              background:
                index % 2
                  ? theme === "dark"
                    ? "#151e4a"
                    : Colors.Dark[5]
                  : null,
              height: 37,
            }}
          >
            <TableCell>
              {row.detail?.length > 0 && (
                <IconButton
                  style={{
                    transform: rotate,
                    transition: "all 0.2s linear",
                    color: Colors.lightBlue,
                  }}
                  // style={{ color: invertDark(theme) }}
                  aria-label="expand row"
                  size="small"
                  // onClick={() => setOpen(!open)}
                >
                  {/* {<KeyboardArrowDownIcon />} */}
                </IconButton>
              )}
            </TableCell>
            <TableCell
              style={{ color: invertDark(theme) }}
              component="th"
              scope="row"
            >
              {row.month}
            </TableCell>
            <TableCell style={{ color: invertDark(theme) }} align="right">
              {row.gas_petrol_consumption === "-"
                ? row.gas_petrol_consumption
                : row.gas_petrol_consumption + " L"}
            </TableCell>
            <TableCell style={{ color: invertDark(theme) }} align="right">
              {row.gas_diesel_consumption === "-"
                ? row.gas_diesel_consumption
                : row.gas_diesel_consumption + " L"}
            </TableCell>
            <TableCell style={{ color: invertDark(theme) }} align="right">
              {row.energy_consumption === "-"
                ? row.energy_consumption
                : row.energy_consumption + " kWh"}
            </TableCell>
            {companyType === "1" ? (
              <TableCell style={{ color: invertDark(theme) }} align="right">
                {row.total_distance === "-"
                  ? row.total_distance
                  : row.total_distance + " km"}
              </TableCell>
            ) : (
              <></>
            )}

            <TableCell style={{ color: invertDark(theme) }} align="right">
              {row.current_emission === "-"
                ? row.current_emission
                : row.current_emission + " kgCO₂e"}
            </TableCell>
            <TableCell style={{ color: invertDark(theme) }} align="right">
              {row.baseline_emission === "-"
                ? row?.baseline_emission
                : row.baseline_emission + " kgCO₂e"}
            </TableCell>
            <TableCell
              style={{ color: Number(row.offset) < 0 ? "green" : "red" }}
              align="right"
            >
              {row.co2_emission == 0
                ? // <span style={{ color: Colors.medium }}>
                  //     <i
                  //         className="fas fa-exclamation-triangle"
                  //         style={{ color: Colors.medium }}
                  //         title="Incomplete Data"
                  //     ></i>
                  // </span>
                  row.offset === "-"
                  ? row.offset
                  : row.offset + " kgCO₂e"
                : row.offset === "-"
                ? row.offset
                : row.offset + " kgCO₂e"}
            </TableCell>
            {/* {Number(row.offset) < 0 && row.co2_emission != 0 ? ( */}
            {Number(row.offset) < 0 ? (
              <TableCell
                style={{ color: invertDark(theme), cursor: "pointer" }}
                align="center"
              >
                <div className="view-org-guide">
                  <span
                    // className="btn text-light w-100"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    data-toggle="modal"
                    data-target=".bd-exapasswordmple-modal-lg"
                  >
                    <Link
                      to="#"
                      onClick={() => {
                        setSelectedCertificateId(row.id);
                        setShowCertiTour(true);
                      }}
                    >
                      {" "}
                      {row.certificate}
                    </Link>
                  </span>
                </div>
              </TableCell>
            ) : (
              <TableCell />
            )}
          </TableRow>
          <TableRow
            style={{
              background:
                index % 2
                  ? theme === "dark"
                    ? "#151e4a"
                    : Colors.Dark[5]
                  : null,
            }}
          >
            <TableCell colSpan={12}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 2 }}>
                  <Typography
                    style={{ color: invertDark(theme), paddingBottom: 0 }}
                    gutterBottom
                    component="div"
                  >
                    <b>{t("Emission Details")}</b>
                  </Typography>
                  <Table size="small" aria-label="detail">
                    <TableHead>
                      <TableRow
                        style={{
                          borderBottom: `1px solid ${Colors.lightBlue}`,
                          background:
                            theme === "dark"
                              ? index % 2
                                ? Colors.darkBlue
                                : Colors[theme].background
                              : index % 2
                              ? Colors.Dark[5]
                              : Colors[theme].background,
                        }}
                      >
                        <TableCell
                          style={{
                            color: invertDark(theme),
                            fontWeight: "bold",
                            borderTopLeftRadius: 5,
                          }}
                        >
                          {t("License Plate")}
                        </TableCell>
                        <TableCell
                          style={{
                            color: invertDark(theme),
                            fontWeight: "bold",
                            paddingRight: 12,
                          }}
                          align="right"
                        >
                          {t("Consumption")}
                        </TableCell>
                        <TableCell
                          style={{
                            color: invertDark(theme),
                            fontWeight: "bold",
                          }}
                          align="right"
                        >
                          {t("CO₂ Emission")}
                        </TableCell>
                        <TableCell
                          style={{
                            color: invertDark(theme),
                            fontWeight: "bold",
                          }}
                          align="right"
                        >
                          {t("Average CO₂ Emission")}
                        </TableCell>
                        <TableCell
                          style={{
                            color: invertDark(theme),
                            fontWeight: "bold",
                            borderTopRightRadius: 5,
                          }}
                          align="right"
                        >
                          {t("Total Distance")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row?.detail?.map((detailRow, index) => {
                        return (
                          <TableRow
                            key={detailRow.license_plate}
                            style={{
                              borderBottom: `1px solid ${Colors.lightBlue}`,
                            }}
                          >
                            <TableCell
                              style={{ color: invertDark(theme) }}
                              component="th"
                              scope="row"
                            >
                              {detailRow.vehicle_number_plate || "-"}
                            </TableCell>
                            <TableCell
                              style={{ color: invertDark(theme) }}
                              align="right"
                            >
                              {detailRow.primary_fuel_type === "fuel"
                                ? detailRow.fuel_consumed
                                  ? detailRow.fuel_consumed.toFixed(2) + " L"
                                  : "0.00 L"
                                : detailRow.battery_consumed
                                ? detailRow.battery_consumed.toFixed(2) + " kWh"
                                : "0.00 kWh"}
                            </TableCell>
                            <TableCell
                              style={{ color: invertDark(theme) }}
                              align="right"
                            >
                              {detailRow.total_emission
                                ? detailRow.total_emission?.toFixed(2) +
                                  " kgCO₂e"
                                : "0.00 kgCO₂e"}
                            </TableCell>
                            <TableCell
                              style={{ color: invertDark(theme) }}
                              align="right"
                            >
                              {detailRow.emission_per_km
                                ? detailRow.emission_per_km + " kgCO₂e/km"
                                : "0.00 kgCO₂e/km"}
                            </TableCell>

                            <TableCell
                              style={{ color: invertDark(theme) }}
                              align="right"
                            >
                              {Number(detailRow.trip_mileage).toFixed(2) +
                                " km"}{" "}
                              {/* {detailRow.trip_mileage ?detailRow.trip_mileage?.toFixed(2) : "0" + " km"} */}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export const CollapsibleTable = (props) => {
  const {
    data,
    theme,
    year,
    setSelectedCertificateId,
    noCertificatesData,
    setShowCertiTour,
    companyType,
  } = props;
  const { t } = useTranslation("dashboard");

  const rows = data?.map((vehicle) => {
    return createData(
      vehicle.id,
      // moment(vehicle.month).format("MMM").toUpperCase(),
      vehicle.certificate_month,
      vehicle?.energy?.energy_consumption?.toFixed(2),
      vehicle?.gas_diesel_by_fuel?.gas_diesel_consumption?.toFixed(2) || "-",
      vehicle?.gas_petrol_by_fuel?.gas_petrol_consumption?.toFixed(2) || "-",
      Number(vehicle.totalDistance).toFixed(2) || "-",
      Number(vehicle.currentEmission).toFixed(2) || "-",
      Number(vehicle.baselineEmission).toFixed(2) || "-",
      Number(vehicle.offset).toFixed(2) || "-",
      t("View"),
      vehicle.emissionByVehicle,
      companyType
    );
  });

  if (data) {
    return (
      <TableContainer component={Paper}>
        <Table
          style={{
            background:
              theme === "dark" ? Colors.darkBlue : Colors[theme].background,
          }}
          aria-label="collapsible table"
        >
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  background: invertDark(theme),
                  color: Colors[theme].active,
                  fontWeight: "bold",
                }}
              />
              <TableCell
                style={{
                  background: invertDark(theme),
                  color: Colors[theme].active,
                  fontWeight: "bold",
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                {t("Month")}
              </TableCell>
              <TableCell
                style={{
                  background: invertDark(theme),
                  color: Colors[theme].active,
                  fontWeight: "bold",
                }}
                align="right"
              >
                {t("Petrol Consumption")}
              </TableCell>
              <TableCell
                style={{
                  background: invertDark(theme),
                  color: Colors[theme].active,
                  fontWeight: "bold",
                }}
                align="right"
              >
                {t("Diesel Consumption")}
              </TableCell>
              <TableCell
                style={{
                  background: invertDark(theme),
                  color: Colors[theme].active,
                  fontWeight: "bold",
                }}
                align="right"
              >
                {t("Energy Consumption")}
              </TableCell>
              {companyType === "1" ? (
                <TableCell
                  style={{
                    background: invertDark(theme),
                    color: Colors[theme].active,
                    fontWeight: "bold",
                  }}
                  align="right"
                >
                  {t("Total Distance")}
                </TableCell>
              ) : (
                <></>
              )}

              <TableCell
                style={{
                  background: invertDark(theme),
                  color: Colors[theme].active,
                  fontWeight: "bold",
                }}
                align="right"
              >
                {t("CO₂ Emission")}
                <i
                  className="fas fa-info-circle p-2"
                  title="Carbon emission generated and/or sequestered as a result of the operation of this vehicle or fleet in the defined time period, expressed in kgCO2-eq."
                  style={{
                    cursor: "pointer",
                  }}
                />
              </TableCell>
              <TableCell
                style={{
                  background: invertDark(theme),
                  color: Colors[theme].active,
                  fontWeight: "bold",
                }}
                align="right"
              >
                {t("Baseline CO₂ Emission")}
                <i
                  className="fas fa-info-circle p-2"
                  title="During the first [year] of data collection, the baseline emission established would be using the OEM’s technical specification of the vehicle or the calculated Baseline Emission Factor (after the first year) as a reference of emission factor multiplied by the mileage it clocked, expressed in kgCO2-eq."
                  style={{
                    cursor: "pointer",
                  }}
                />
              </TableCell>
              <TableCell
                style={{
                  background: invertDark(theme),
                  color: Colors[theme].active,
                  fontWeight: "bold",
                }}
                align="right"
              >
                {t("CO₂e Reduction")}
                <i
                  className="fas fa-info-circle p-2"
                  title="The difference between the actual emission and the baseline emission, expressed in kgCO2-eq. A positive offset indicates the vehicle/fleet performing better than its baseline. In contrast, a negative offset indicates the vehicle/fleet performing poorer than its baseline."
                  style={{
                    cursor: "pointer",
                  }}
                />
              </TableCell>
              <TableCell
                style={{
                  background: invertDark(theme),
                  color: Colors[theme].active,
                  fontWeight: "bold",
                }}
                align="right"
              >
                {t("GHG Emission Reduction Statement")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, index) => (
              <Row
                theme={theme}
                key={index}
                row={row}
                index={index}
                setSelectedCertificateId={setSelectedCertificateId}
                setShowCertiTour={setShowCertiTour}
                companyType={companyType}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else if (noCertificatesData) {
    return (
      <div className="text-center" style={{ color: invertDark(theme) }}>
        <i
          className="fa fa-exclamation-triangle me-2"
          style={{ marginTop: "20%", color: Colors.fuel }}
        />
        {t("No certificate for")} {year}
      </div>
    );
  } else {
    return <CO2_Loading />;
  }
};
