import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import $, { data } from "jquery";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import { makeStyles } from "@mui/styles";
import { toJS } from "mobx";
import { CO2_Loading } from "../../Assets/CO2_Loading";
import { Submit_Loading } from "../../Assets/Submit_Loading";
import '../../stick-table.css';
import { useTranslation } from "react-i18next";

export const EngineHourTable = inject("store")(
  observer((props) => {
      const {t} = useTranslation("data_import")
      const { theme,
        _handleViewContract,
        noPagination,
        vEngineHourData,
        filenotexist,
      } = props;

      const { SubmitLoading,loading } = props.store.dataImport;
      const [scrollOffset, setScrollOffset] = React.useState(0);
      const tableContainerRef = React.useRef(null);
      // console.log(SubmitLoading)


      const [page, setPage] = React.useState(1);
      const [order, setOrder] = React.useState("asc");
      const [orderBy, setOrderBy] = React.useState("");

      const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
        // console.log("property", property);
      };

      const rows = vEngineHourData?.map((v, i) => {
        let engineHour = {
          grouping: v.grouping,
          start_time: v.start_time,
          in_motion: v.in_motion,
          idling: v.idling,
          start_point: v.start_point,
          start_link: v.start_link,
          to_time: v.to_time,
          to_point: v.to_point,
          to_link: v.to_link,
          duration_seconds: v.duration_seconds,
          total_engine_hours: v.total_engine_hours,
          error : v.error
        };

        return engineHour;
      });

    const tableRef = React.useRef(null);


    useEffect(() => {
      const styleElement = document.createElement("style");
      styleElement.innerHTML =
        theme === "dark"
          ? `
          
      
              .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
                  color: black !important;
                  border: 1px solid #e5e5e5!important;
                  
                  background-color: #e5e5e5 !important;
                  /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #e5e5e5), color-stop(100%, #e5e5e5))!important; */
                  background: -webkit-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
                  background: -moz-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
                  background: -ms-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%) !important;
                  background: -o-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
                  background: linear-gradient(to bottom, #e5e5e5 0%, #e5e5e5 100%)!important;
                }
      
                .dataTables_wrapper .dataTables_paginate .paginate_button.current {
                  color: black !important;
                  border: 1px solid #C6C6C6!important;
                  
                  background-color: #C6C6C6 !important;
                  /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fffcfc), color-stop(100%, #969292))!important; */
                  background: -webkit-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                  background: -moz-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                  background: -ms-linear-gradient(top, #fffcfc 0%, #969292 100%) !important;
                  background: -o-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                  background: linear-gradient(to bottom, #fffcfc 0%, #969292 100%)!important;
                }
      
                .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
                  color: black !important;
                  border: 1px solid #C6C6C6!important;
                  
                  background-color: #C6C6C6 !important;
                  /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fffcfc), color-stop(100%, #969292))!important; */
                  background: -webkit-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                  background: -moz-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                  background: -ms-linear-gradient(top, #fffcfc 0%, #969292 100%) !important;
                  background: -o-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                  background: linear-gradient(to bottom, #fffcfc 0%, #969292 100%)!important;
                }
      
      
              `
          : `
                  .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
                      color: black !important;
                      border: 1px solid #e5e5e5!important;
                      
                      background-color: #e5e5e5 !important;
                      /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #e5e5e5), color-stop(100%, #e5e5e5))!important; */
                      background: -webkit-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
                      background: -moz-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
                      background: -ms-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%) !important;
                      background: -o-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
                      background: linear-gradient(to bottom, #e5e5e5 0%, #e5e5e5 100%)!important;
                    }
      
                    .dataTables_wrapper .dataTables_paginate .paginate_button.current {
                      color: black !important;
                      border: 1px solid #C6C6C6!important;
                      
                      background-color: #C6C6C6 !important;
                      /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fffcfc), color-stop(100%, #969292))!important; */
                      background: -webkit-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                      background: -moz-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                      background: -ms-linear-gradient(top, #fffcfc 0%, #969292 100%) !important;
                      background: -o-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                      background: linear-gradient(to bottom, #fffcfc 0%, #969292 100%)!important;
                    }
         
              `;
      document.head.appendChild(styleElement);

      function updateOrderAndDisplayStart(columnIndex, direction) {
        orderColumn = columnIndex;
        orderDirection = direction;

        table.order([[orderColumn, orderDirection]]).draw();
      }

      const storedOrderColumn = localStorage.getItem("orderColumn");
      const storedOrderDirection = localStorage.getItem("orderDirection");

      let orderColumn = storedOrderColumn ? parseInt(storedOrderColumn) : 0;
      let orderDirection = storedOrderDirection || "asc";
      const tableElement = $(tableRef.current);

      const table = tableElement.DataTable({
        autoWidth: true,
        responsive: true,
        retrieve: true,
        scrollX: true,
        order: [[1, "asc"]],

        lengthMenu: [8, 10, 15],

        data: rows,
        columns: [
          {
            title: t("Grouping") +
            ` <span style="color: #ffc107">*</span>`,
            data: "grouping",
          },
          {
            title: t("Start Time") +
            ` <span style="color: #ffc107">*</span>`,
            data: "start_time",
          },
          {
            title: t("In Motion") +
            ` <span style="color: #ffc107">*</span>`,
            data: "in_motion",
          },
          {
            title: t("Idling") +
            ` <span style="color: #ffc107">*</span>`,
            data: "idling",
          },
  
          { title: t("Start Point"), data: "start_point" },
          { title: t("Start Link"), data: "start_link" },
          { title: t("To Time"), data: "to_time" },
          { title: t("To Point"), data: "to_point" },
          { title: t("To Link"), data: "to_link" },
          { title: t("Duration Seconds"), data: "duration_seconds" },
          { title: t("Total Engine Hours"), data: "total_engine_hours" },
          {title: t("Error"), data: 'error'}
        ],
        columnDefs: [
          {
            targets: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
            render: function (data, type, row) {
              return row.error !== null
                ? '<span style="color: red;">' + data + "</span>"
                : data;
            },
          },
        ],
        paging: true,

        drawCallback: function (settings) {
          // console.log($('# ').DataTable().page.info());
          // console.log(settings.oSavedState);
        },
        language: {
          emptyTable:rows.length === 0 && ` 
                        <div class="no-data-found " style="color: ${invertDark(
                          theme
                        )};  align-items: center; justify-content: center">
                        <i class="fa fa-exclamation-triangle me-2" style="color: orange;"></i>
                        ${t("No Data Found")}
                      </div>
                    `,
          // info: t("Showing _START_ to _END_ of _TOTAL_ entries"),
          // infoEmpty: t("Showing 0 to 0 of 0 entries"),
        },
      });

      const searchInput = $(".dataTables_filter input");
      searchInput.css("color", theme === "light" ? "black" : "white");

      const tableRows = $(".dataTables_wrapper tr");
      const tableColumns = $(".dataTables_wrapper td");

      tableRows.css("color", theme === "light" ? "black" : "#fff");
      tableColumns.css("color", theme === "light" ? "black" : "#fff");

      const searchlabel = $(".dataTables_filter label");
      searchlabel.css("color", theme === "light" ? "black" : "white");

      table.cells().every(function () {
        $(this.node()).css(
          "border-color",
          theme === "dark" ? "rgb(211, 211, 211, 0.3)" : ""
        );
      });

      const entriesSelect = $(".dataTables_length select");
      entriesSelect.css(
        "background-color",
        theme === "light" ? "#fff" : "#202b60"
      );
      entriesSelect.css("color", theme === "light" ? "black" : "white");

      const entriesLabel = $(".dataTables_length label");
      entriesLabel.css("color", theme === "light" ? "black" : "white");

      const tableInfo = $(".dataTables_info");
      tableInfo.css("color", theme === "light" ? "#000" : "#fff");

      const tableEmpty = $("#engineHourTable");
      tableEmpty.css("color",theme === "light" ? "#000" : "#fff")
    
      const paginate = $("#engineHourTable_paginate");
      paginate.css("color", theme === "light" ? "#000" : "#fff");

      $("#engineHourTable").on("draw.dt", function () {
        const currentPage = table.page.info().page;
        const rows = table.rows({ page: "current" }).nodes();
        const cells = $(rows).find("td");

        cells.css("color", theme === "light" ? "#000" : "#fff");
      });

      $("#engineHourTable").on("page.dt", function () {
        localStorage.setItem("currentPage", table.page.info().page);
      });

      // $("#engineHourTable").on("page.dt", function () {
      //   localStorage.setItem("currentPage", table.page.info().page);
      // });


      window.addEventListener("beforeunload", function () {
        localStorage.removeItem("orderColumn");
        localStorage.removeItem("orderDirection");
        localStorage.removeItem("currentPage");
      });

      window.addEventListener("load", function () {
        localStorage.removeItem("orderColumn");
        localStorage.removeItem("orderDirection");
        localStorage.removeItem("currentPage");
      });

      const storedPage = localStorage.getItem("currentPage");

      if (storedPage !== null) {
        const currentPage = parseInt(storedPage);
        if (!isNaN(currentPage)) {
          table.page(currentPage).draw("page");
        } else {
          table.page(0).draw("page");
        }
      } else {
        table.page(0).draw("page");
      }

      return () => {
        if ($.fn.DataTable.isDataTable("#engineHourTable")) {
          table.destroy();
        }
      };
    }, [theme, rows]);

    return (
      <>
       {
          // rows?.length === 0
          loading ? (
            <CO2_Loading />
          ) : (
            <div>
              <table
                ref={tableRef}
                id="engineHourTable"
                className="display cell-border hover stripe"
                style={{ width: "100%" }}
              ></table>
            </div>
          )
        }
      </>
    );
  })
);

