import { inject, observer } from "mobx-react";
import React from "react";
import { withRouter } from "react-router-dom";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import {
  Pagination,
  PaginationItem,
  styled,
  TableSortLabel,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { toJS } from "mobx";
import { color } from "d3";
import { DataEntry_Loading } from "../../Assets/DataEntry_Loading";
import { useTranslation } from "react-i18next";

export const DataEntryTable = inject("store")(
  observer(
    withRouter((props) => {
      const {
        theme,
        _handleClearAudit,
        _handleCreateAudit,
        _handleViewContract,
        noPagination,
        rows,
        data,
        type,
        _handleSelectVehicle,
        setVehicleIndex,
        _handleClearAuditImages,
        _handleCreateAuditImages,
        setPaginationPage,
        loadingForDataEntry,
      } = props;
      const { t } = useTranslation("new-manuel-entry");
      const Key = [
        "license",
        "type",
        "fuel_consumed",
        "odometer_start",
        "odometer",
        "trip_mileage",
        "action",
      ];

      console.log("rowsData", toJS(rows));
                // console.log(LoadingForMonthlyTable)


      const [page, setPage] = React.useState(1);
      const [order, setOrder] = React.useState("asc");
      const [orderBy, setOrderBy] = React.useState("");

      const [showHeader, setShowHeader] = useState(false);

      const toggleHeader = () => {
        setShowHeader(!showHeader);
      };

      const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
        console.log("property", property);
      };

      const headers = [
        { label: t("License No"), key: "license" },
        { label: t("Type"), key: "type" },
        {
          label:
            type === "ev"
              ? t("Electricity Consumed(kWh)")
              : t("Fuel Consumed(L)"),
          key: "fuel_consumed",
        },
        { label: t("Start Odometer") + "(km)", key: "odometer_start" },
        { label: t("End Odometer") + "(km)", key: "odometer" },
        { label: t("Trip Mileage") + "(km)", key: "trip_mileage" },
        { label: t("Action"), key: "action" },
      ];

      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(odd)": {
          backgroundColor:
            props.theme === "dark" ? Colors.darkBlue : Colors.Dark[5],
          color: theme.palette.common.white,
        },
        // hide last border
        "&:last-child td, &:last-child th": {
          border: 0,
        },
      }));

      const rowCount = 5;
      const handleChange = (event, value) => {
        setPage(value);
        setPaginationPage(value);
      };

      function EnhancedTableHead(props) {
        const {
          order,
          orderBy,
          onRequestSort,
          theme,
          Key,
          _handleViewContract,
        } = props;
        const createSortHandler = (property) => (event) => {
          console.log(property);
          onRequestSort(event, property);
        };

        const isAction = !rows
          ? false
          : rows.some((row) => Boolean(row.action?.trim()));
        return (
          <TableHead>
            <TableRow>
              {headers.map((headCell, index) =>
                headCell.key === "action" && isAction === false ? null : (
                  <TableCell
                    key={index}
                    align={"left"}
                    padding={headCell.disablePadding ? "none" : "normal"}
                    sortDirection={orderBy === headCell.id ? order : false}
                    style={{
                      background:
                        props.theme === "dark"
                          ? Colors.darkBlue
                          : invertDark(theme),
                    }}
                  >
                    {headCell.label === "" || (
                      <TableSortLabel
                        style={{
                          minWidth: 80,
                          color:
                            orderBy === headCell.id
                              ? Colors.activeColor
                              : props.theme === "light"
                              ? "#F6F6F6"
                              : Colors.Dark[4],
                        }}
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={createSortHandler(Key[index])}
                      >
                        <span className="fw-bold">{headCell.label}</span>
                      </TableSortLabel>
                    )}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
        );
      }
      function descendingComparator(a, b, orderBy) {
        const A = a[orderBy] ? a[orderBy].trim() : undefined;
        const B = b[orderBy] ? b[orderBy].trim() : undefined;
        if (B < A) {
          return -1;
        }
        if (B > A) {
          return 1;
        }
        return 0;
      }

      function getComparator(order, orderBy) {
        return order === "desc"
          ? (a, b) => descendingComparator(a, b, orderBy)
          : (a, b) => -descendingComparator(a, b, orderBy);
      }

      const useStyles = makeStyles({
        foo: {
          "& .MuiPagination-ul": { justifyContent: "end" },
          "& .MuiPaginationItem-text": {
            color: Colors.Dark[theme === "dark" ? 4 : 1],
          },
          "& .Mui-selected": {
            backgroundColor: Colors.Dark[4],
            color: Colors.darkBlue,
          },
        },
      });

      const titleColor = { color: theme === "dark" ? "#ffff" : Colors.Dark[1] };

      const classes = useStyles();

      const StatusCircle = ({ color, title }) => {
        return (
          <div
            className="d-flex align-items-center justify-content-center "
            style={{ width: 30 }}
          >
            <div
              className=""
              style={{
                width: 8,
                height: 8,
                backgroundColor: color,
                borderRadius: "50%",
                cursor: "pointer",
              }}
              title={title}
            />
          </div>
        );
      };

      return (
        <React.Fragment>
          <TableContainer
            component={Paper}
            style={{ background: "none" }}
            sx={{ maxHeight: 450 }}
          >
            <Table
              stickyHeader
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense sticky table"
            >
              <EnhancedTableHead
                _handleViewContract={_handleViewContract}
                Key={Key}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                theme={theme}
                rows={rows}
              />

              {loadingForDataEntry ? (
                <TableBody>
                  {rows?.length > 0 &&
                    rows
                      .sort(getComparator(order, orderBy))
                      .slice((page - 1) * rowCount, page * rowCount)
                      .map((row, index) => (
                        <StyledTableRow
                          style={{}}
                          key={index}
                          onClick={async () => {}}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          {Object.values(row).map(
                            (d, x) =>
                              x !== 0 && (
                                <TableCell
                                  sx={{
                                    color: invertDark(theme),
                                    borderBottom: "none",
                                  }}
                                  key={x}
                                >
                                  {d === "Update" ? (
                                    <>
                                      <div
                                        className="p-1 d-flex justify-content-center"
                                        style={{
                                          background: "#0d6efd",
                                          borderRadius: 5,
                                          ...titleColor,
                                          cursor: "pointer",
                                          color: "#fff",
                                        }}
                                        onClick={() => {
                                          _handleClearAuditImages();
                                          _handleSelectVehicle(data, row);
                                          _handleClearAudit();
                                          setVehicleIndex(data[index], row);
                                        }}
                                      >
                                        {t("Update")}
                                      </div>
                                    </>
                                  ) : d.toString().includes("ascent") ? (
                                    <div className="row">
                                      <StatusCircle
                                        color={Colors.low}
                                        title={"IOT Data"}
                                      />
                                      {d.replace(" ascent", "")}
                                    </div>
                                  ) : d.toString().includes("manual") ? (
                                    <div className="row">
                                      <StatusCircle
                                        color={Colors.fuelLight}
                                        title={"Manual Data"}
                                      />
                                      {d.replace(" manual", "")}
                                    </div>
                                  ) : (
                                    d
                                  )}
                                </TableCell>
                              )
                          )}
                        </StyledTableRow>
                      ))}
                </TableBody>
              ) : (
                <TableBody>
                  {/* <StyledTableRow> */}
                  <TableCell colSpan={8}>
                    <DataEntry_Loading />
                  </TableCell>
                  {/* </StyledTableRow> */}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {noPagination || (
            <div className="py-2">
              <Pagination
                size="small"
                renderItem={(item) => (
                  <PaginationItem
                    {...item}
                    sx={{ color: invertDark(theme) }}
                    classes={{ selected: classes.selected }}
                  />
                )}
                className={classes.foo}
                count={
                  rows?.length <= rowCount
                    ? 1
                    : Math.ceil(rows?.length / rowCount)
                }
                // count={rows.length}
                defaultPage={1}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                onChange={handleChange}
              />
            </div>
          )}
        </React.Fragment>
      );
    })
  )
);
