import { inject, observer } from "mobx-react";
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { My_Button } from "../../Tools";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
export const NoCompanyVehicle = inject("store")(
  observer((props) => {
    const { t } = useTranslation("data_import");
    const { theme, showTable, unAuthorizeVehicles } = props;

    const { role } = props.store.common;

    const { handleClearVehicles, companyName, handleClearUnauthorizeVehicle } = props.store.dataImport;

    const [showVehicleExistModal, setShowVehicleExistModal] = useState(false);
    let cardStyle = {
      color: invertDark(theme),
      background: Colors[theme].background,
      border: "none",
    };

    const closeModal = () => {
      setShowVehicleExistModal(false);
      handleClearVehicles();
    };

    const history =useHistory();

    useEffect(() => {
      if (unAuthorizeVehicles?.unAuthorizeVehicle?.length > 0) {
        setShowVehicleExistModal(true);
      }
    }, [unAuthorizeVehicles]);

    // console.log(toJS(vehicles), 'vehiclesssssss==>')
    // console.log(toJS(vehicles?.vehicleNotExists), 'vehiclesssssssnotexistsss==>')

    return (
      <div>
        <Modal show={showVehicleExistModal} size="lg">
          <Modal.Header style={cardStyle}>
            <Modal.Title className={"text-danger"}>
              {unAuthorizeVehicles?.message}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={cardStyle}>
            <p>
              {/* {t( */}
              The csv data include data from invalid units. You don't have
              permission to submit the other company's data. The following
              unit(s) are invalid.
              {/* )} */}
            </p>

            <ul>
              {unAuthorizeVehicles?.unAuthorizeVehicle?.map((vehicle, index) => (
                <li key={index}>{vehicle}</li>
              ))}
            </ul>
          </Modal.Body>
          <Modal.Footer style={cardStyle}>
            <My_Button
              id="cancel"
              type="button"
              className="btn-lg bg-secondary me-2"
              style={{}}
              text="Back"
              onClick={()=>{
                history.goBack()
                handleClearUnauthorizeVehicle()
              }}
            />
          </Modal.Footer>
        </Modal>
      </div>
    );
  })
);
