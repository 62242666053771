import { inject, observer } from 'mobx-react'
import { Colors } from '../../Assets/Config/color.config'
import { DatePicker } from 'antd'
import React from 'react'

export const DatePickerAntD = inject("store")(observer((props)  => {
    const { picker, theme, disabledDate, defaultValue, value, format, onChange } = props

    const style = {
        background: theme === "dark" ? Colors.darkBlue : Colors.Dark[5],
        // color: theme === "dark" ? Colors.Dark[4] : Colors.Dark[1],
        width: '100%',
        height: '38px',
        borderRadius: 3,
        borderColor: theme === "dark" ? Colors.Dark[2] : Colors.Dark[4],
        // boxShadow: state.isFocused ? null : null,
    }
    return (
        
       
        <DatePicker 
            picker={picker}
            style={style}
            className={`${theme === 'dark' ? 'dark-theme' : 'light-theme'}`}
            disabledDate={disabledDate}
            defaultValue={defaultValue}
            value={value}
            format={format}
            onChange={onChange}
        />
    )
}))
