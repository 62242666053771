import { Colors, invertDark } from "../../Assets/Config/color.config";
import { AssetProfileForm } from "./AssetProfileForm";
import EditVehicleIcon from "../../Assets/Images/icons/editVehicle.png";
import { My_Button, My_Input, My_upload_image, Selector } from "../../Tools";
import { useState } from "react";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import { useTranslation } from "react-i18next";

export const AddVehicleMinMaxModalJQ = inject("store")(
    observer((props) => {
        const { t } = useTranslation("asset_profile_management")
        const { theme, currentAssets } = props;
        const { deviceMinMaxData,
            _handleMinMaxChange,
            _handleAddNewMinMaxDevice,
            validateError,
            disableAddMinMaxVeicle,
            editMinMaxMode,
            _handleCloseModel } = props.store.assetProfile
        const [vehicleType, setVehicleType] = useState(vehicleTypeOption[0].value)
        const { role } = props.store.common


        return (
            <div
                className="modal fade show"
                id="addVehicleMinMaxModalJQ"
                tabIndex="-1"
                aria-labelledby="addVehicleMinMaxModalJQ"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xl rounded" style={{ maxWidth: "800px" }}>
                    <div
                        className="modal-content"
                        style={{ background: Colors[theme].background, color: invertDark(theme) }}
                    >
                        <div className="modal-header" style={{ border: "none" }}>
                            {/* {editMinMaxMode ? <span style={{ fontSize: 24 }}>
                                <img height="30px" src={EditVehicleIcon} style={{ backgroundColor:"#fff" }} /> {t("Edit Vehicle")}
                            </span> */}
                            {/* :  */}
                            <span style={{ fontSize: 24 }}>

                                <i height="30px" className="bi bi-sliders me-2 ms-2" />
                                {currentAssets === "equipment"
                                    ? t("Add New Equipment")
                                    : currentAssets === "infrastructure"
                                        ? t("Add New Infrastructure")
                                        : t("Edit Threshold") + " - " + deviceMinMaxData?.grouping
                                }


                            </span>
                            <span data-bs-dismiss="modal" onClick={() => _handleCloseModel()} style={{ cursor: 'pointer' }}>
                                <i className="bi bi-x-lg " />
                            </span>
                        </div>
                        <div className="modal-body" style={{ paddingRight: "0px" }}>
                            <form onSubmit={(e) => _handleAddNewMinMaxDevice(e, props)} className="row" style={{ fontSize: 14 }}>
                                <div style={{ position: 'relative'}}>
                                    <label style={{
                                        fontSize: '15px',
                                        fontWeight: 'bold',
                                        position: 'absolute',
                                        top: "-10px",
                                        zIndex: '10',
                                        left: "2em",
                                        color: theme === 'dark' ? "white" : '',
                                        padding: "0 4px",
                                        background: theme === "dark" ? "rgb(20, 30, 70)" : 'white'
                                    }}>Daily</label>
                                    <div style={{
                                        border: "1px solid gray",
                                        borderRadius: "6px",
                                        position: "relative",
                                        paddingLeft: "15px",
                                        marginRight: "15px",
                                        lineHeight: "6ex",
                                        zIndex: "1"
                                    }}>
                                        {deviceMinMaxData.fuel_type !== 'Battery' ?

                                            <>
                                                <div className="row">
                                                    <div className="col-6 col-sm-6 col-lg-6 col-md-6 col-xl-6 col-xs-6">
                                                        <div>

                                                            <div >{t("Fuel Consumption")} {t("(Minimum)")} </div>
                                                            <div className="col-11 col-sm-11 col-lg-11 col-md-11 col-xl-11 col-xs-11 d-flex">
                                                                <My_Input
                                                                    // type='number'
                                                                    height='38px'
                                                                    required={false}
                                                                    id={"daily_fuel_consumed_min"}
                                                                    placeHolder={"fuel consumed (Min)"}
                                                                    value={deviceMinMaxData?.daily_fuel_consumed_min}
                                                                    onChange={(e) => _handleMinMaxChange(e, 'daily_fuel_consumed_min')}
                                                                />
                                                                <div className="col-1 col-sm-1 col-lg-1 col-md-1 col-xl-1 col-xs-1 justify-content-center my-2 ms-2"> (L)</div></div>


                                                        </div>
                                                    </div>

                                                    <div className="col-6 col-sm-6 col-lg-6 col-md-6 col-xl-6 col-xs-6">
                                                        <div>
                                                            <div>{t("Fuel Consumption")} {t("(Maximum)")} </div>
                                                            <div className="col-11 col-sm-11 col-lg-11 col-md-11 col-xl-11 col-xs-11 d-flex">
                                                                <My_Input
                                                                    // type='number'
                                                                    height='38px'
                                                                    required={false}
                                                                    id={"daily_fuel_consumed_max"}
                                                                    placeHolder={"fuel consumed (Max)"}
                                                                    value={deviceMinMaxData.daily_fuel_consumed_max}
                                                                    onChange={(e) => _handleMinMaxChange(e, 'daily_fuel_consumed_max')}
                                                                />
                                                                <div className="col-1 col-sm-1 col-lg-1 col-md-1 col-xl-1 col-xs-1 justify-content-center my-2 ms-2"> (L)</div></div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <div className="row">
                                                    <div className="col-6 col-sm-6 col-lg-6 col-md-6 col-xl-6 col-xs-6">
                                                        <div className="pb-3">

                                                            <div className="mb-1 ">{t("Monthly Fuel Consumption")} {t("(Minimum)")} </div>
                                                            <div className="col-11 col-sm-11 col-lg-11 col-md-11 col-xl-11 col-xs-11 d-flex">
                                                                <My_Input
                                                                    type='number'
                                                                    height='38px'
                                                                    required={false}
                                                                    id={"fuel_consumed_min"}
                                                                    placeHolder={"fuel consumed (Min)"}
                                                                    value={deviceMinMaxData?.monthly_fuel_consumed_min}
                                                                    onChange={(e) => _handleMinMaxChange(e, 'monthly_fuel_consumed_min')}
                                                                />
                                                                <div className="col-1 col-sm-1 col-lg-1 col-md-1 col-xl-1 col-xs-1 justify-content-center my-2 ms-2"> (L)</div></div>


                                                        </div>
                                                    </div>

                                                    <div className="col-6 col-sm-6 col-lg-6 col-md-6 col-xl-6 col-xs-6">
                                                        <div className="pb-3">
                                                            <div className="mb-1">{t("Monthly Fuel Consumption")} {t("(Maximum)")} </div>
                                                            <div className="col-11 col-sm-11 col-lg-11 col-md-11 col-xl-11 col-xs-11 d-flex">
                                                                <My_Input
                                                                    type='number'
                                                                    height='38px'
                                                                    required={false}
                                                                    id={"fuel_consumed_max"}
                                                                    placeHolder={"fuel consumed (Max)"}
                                                                    value={deviceMinMaxData.monthly_fuel_consumed_max}
                                                                    onChange={(e) => _handleMinMaxChange(e, 'monthly_fuel_consumed_max')}
                                                                />
                                                                <div className="col-1 col-sm-1 col-lg-1 col-md-1 col-xl-1 col-xs-1 justify-content-center my-2 ms-2"> (L)</div></div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </>
                                            :
                                            <>
                                                <div className="row">
                                                    <div className="col-6 col-sm-6 col-lg-6 col-md-6 col-xl-6 col-xs-6">
                                                        <div>
                                                            <div>{t("Battery Consumption")} {t("(Minimum)")}</div>
                                                            <div className="col-11 col-sm-11 col-lg-11 col-md-11 col-xl-11 col-xs-11 d-flex">
                                                                <My_Input
                                                                    // type='number'
                                                                    height='38px'
                                                                    required={false}
                                                                    id={"daily_battery_consumed_min"}
                                                                    placeHolder={"battery consumed (Min)"}
                                                                    value={deviceMinMaxData?.daily_battery_consumed_min}
                                                                    onChange={(e) => _handleMinMaxChange(e, 'daily_battery_consumed_min')}
                                                                />
                                                                <div className="col-1 col-sm-1 col-lg-1 col-md-1 col-xl-1 col-xs-1 justify-content-center my-2 ms-2"> (kWh)</div></div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-sm-6 col-lg-6 col-md-6 col-xl-6 col-xs-6">
                                                        <div >

                                                            <div>{t("Battery Consumption")} {t("(Maximum)")}</div>
                                                            <div className="col-11 col-sm-11 col-lg-11 col-md-11 col-xl-11 col-xs-11 d-flex">
                                                                <My_Input
                                                                    // type='number'
                                                                    height='38px'
                                                                    required={false}
                                                                    id={"daily_battery_consumed_max"}
                                                                    placeHolder={"battery consumed (Max)"}
                                                                    value={deviceMinMaxData?.daily_battery_consumed_max}
                                                                    onChange={(e) => _handleMinMaxChange(e, 'daily_battery_consumed_max')}
                                                                />
                                                                <div className="col-1 col-sm-1 col-lg-1 col-md-1 col-xl-1 col-xs-1 justify-content-center my-2 ms-2"> (kWh)</div></div>

                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <div className="row">
                                                    <div className="col-6 col-sm-6 col-lg-6 col-md-6 col-xl-6 col-xs-6">
                                                        <div className="pb-3">
                                                            <div className="mb-1">{t("Monthloy Battery Consumption")} {t("(Minimum)")}</div>
                                                            <div className="col-11 col-sm-11 col-lg-11 col-md-11 col-xl-11 col-xs-11 d-flex">
                                                                <My_Input
                                                                    type='number'
                                                                    height='38px'
                                                                    required={false}
                                                                    id={"battery_consumed_min"}
                                                                    placeHolder={"battery consumed (Min)"}
                                                                    value={deviceMinMaxData.monthly_battery_consumed_min}
                                                                    onChange={(e) => _handleMinMaxChange(e, 'monthly_battery_consumed_min')}
                                                                />
                                                                <div className="col-1 col-sm-1 col-lg-1 col-md-1 col-xl-1 col-xs-1 justify-content-center my-2 ms-2"> (kWh)</div></div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-sm-6 col-lg-6 col-md-6 col-xl-6 col-xs-6">
                                                        <div className="pb-3">

                                                            <div className="mb-1">{t("Monthly Battery Consumption")} {t("(Maximum)")}</div>
                                                            <div className="col-11 col-sm-11 col-lg-11 col-md-11 col-xl-11 col-xs-11 d-flex">
                                                                <My_Input
                                                                    type='number'
                                                                    height='38px'
                                                                    required={false}
                                                                    id={"battery_consumed_max"}
                                                                    placeHolder={"battery consumed (Max)"}
                                                                    value={deviceMinMaxData.monthly_battery_consumed_max}
                                                                    onChange={(e) => _handleMinMaxChange(e, 'monthly_battery_consumed_max')}
                                                                />
                                                                <div className="col-1 col-sm-1 col-lg-1 col-md-1 col-xl-1 col-xs-1 justify-content-center my-2 ms-2"> (kWh)</div></div>

                                                        </div>
                                                    </div>
                                                </div> */}
                                            </>
                                        }
                                        <div className="row">
                                            <div className="col-6 col-sm-6 col-lg-6 col-md-6 col-xl-6 col-xs-6">
                                                <div>
                                                    <div>{t("Mileage")} {t("(Minimum)")}</div>
                                                    <div className="col-11 col-sm-11 col-lg-11 col-md-11 col-xl-11 col-xs-11 d-flex">
                                                        <My_Input
                                                            // type='number'
                                                            height='38px'
                                                            required={false}
                                                            id={"daily_mileage_min"}
                                                            placeHolder={"mileage (Min)"}
                                                            value={deviceMinMaxData.daily_mileage_min}
                                                            onChange={(e) => _handleMinMaxChange(e, 'daily_mileage_min')}
                                                        />
                                                        <div className="col-1 col-sm-1 col-lg-1 col-md-1 col-xl-1 col-xs-1 justify-content-center my-2 ms-2"> (km)</div></div>

                                                </div>
                                            </div>
                                            <div className="col-6 col-sm-6 col-lg-6 col-md-6 col-xl-6 col-xs-6">
                                                <div>

                                                    <div>{t("Mileage")} {t("(Maximum)")} </div>
                                                    <div className="col-11 col-sm-11 col-lg-11 col-md-11 col-xl-11 col-xs-11 d-flex">
                                                        <My_Input
                                                            // type='number'
                                                            height='38px'
                                                            required={false}
                                                            id={"daily_mileage_max"}
                                                            placeHolder={"mileage (Max)"}
                                                            value={deviceMinMaxData?.daily_mileage_max}
                                                            onChange={(e) => _handleMinMaxChange(e, 'daily_mileage_max')}
                                                        />
                                                        <div className="col-1 col-sm-1 col-lg-1 col-md-1 col-xl-1 col-xs-1 justify-content-center my-2 ms-2"> (km)</div></div>

                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-6 col-sm-6 col-lg-6 col-md-6 col-xl-6 col-xs-6">                                       
                                            <div className="pb-3">

                                                <div className="mb-1">{t("Speed")} {t("(Minimum)")} </div>
                                                <div className="col-11 col-sm-11 col-lg-11 col-md-11 col-xl-11 col-xs-11 d-flex">
                                                    <My_Input
                                                        type='number'
                                                        height='38px'
                                                        required={false}
                                                        id={"speed_min"}
                                                        placeHolder={"speed (Min)"}
                                                        value={deviceMinMaxData.speed_min}
                                                        onChange={(e) => _handleMinMaxChange(e, 'speed_min')}
                                                    />
                                                <div className="col-1 col-sm-1 col-lg-1 col-md-1 col-xl-1 col-xs-1 justify-content-center my-2 ms-2 "> (km/h)</div></div>
                                            </div>
                                            </div>
                                            <div className="col-6 col-sm-6 col-lg-6 col-md-6 col-xl-6 col-xs-6">                                       
                                            <div className="pb-3">

                                                <div className="mb-1">{t("Speed")} {t("(Maximum)")} </div>
                                                <div className="col-11 col-sm-11 col-lg-11 col-md-11 col-xl-11 col-xs-11 d-flex">

                                                    <My_Input
                                                        type='number'
                                                        height='38px'
                                                        required={false}
                                                        id={"speed_max"}
                                                        placeHolder={"speed (Max)"}
                                                        value={deviceMinMaxData.speed_max}
                                                        onChange={(e) => _handleMinMaxChange(e, 'speed_max')}
                                                    />
                                            <div className="col-1 col-sm-1 col-lg-1 col-md-1 col-xl-1 col-xs-1 justify-content-center my-2 ms-2"> (km/h)</div></div>
                                            </div>

                                            </div> */}

                                        {/* <div className="col-6 col-sm-6 col-lg-6 col-md-6 col-xl-6 col-xs-6">  </div> */}

                                    </div>
                                </div>

                                <div className="mt-4" style={{ position: 'relative'}}>
                                    <label style={{
                                        fontSize: '15px',
                                        fontWeight: 'bold',
                                        position: 'absolute',
                                        top: "-10px",
                                        zIndex: '10',
                                        left: "2em",
                                        color: theme === 'dark' ? "white" : '',
                                        padding: "0 4px",
                                        background: theme === "dark" ? "rgb(20, 30, 70)" : 'white'
                                    }}>Monthly</label>
                                    <div style={{
                                        border: "1px solid gray",
                                        borderRadius: "6px",
                                        position: "relative",
                                        paddingLeft: "15px",
                                        marginRight: "15px",
                                        lineHeight: "6ex",
                                        zIndex: "1"
                                    }}>
                                        {deviceMinMaxData.fuel_type !== 'Battery' ?

                                            <>
                                                <div className="row">
                                                    <div className="col-6 col-sm-6 col-lg-6 col-md-6 col-xl-6 col-xs-6">
                                                        <div>

                                                            <div >{t("Fuel Consumption")} {t("(Minimum)")} </div>
                                                            <div className="col-11 col-sm-11 col-lg-11 col-md-11 col-xl-11 col-xs-11 d-flex">
                                                                <My_Input
                                                                    // type='number'
                                                                    height='38px'
                                                                    required={false}
                                                                    id={"monthly_fuel_consumed_min"}
                                                                    placeHolder={"fuel consumed (Min)"}
                                                                    value={deviceMinMaxData?.monthly_fuel_consumed_min}
                                                                    onChange={(e) => _handleMinMaxChange(e, 'monthly_fuel_consumed_min')}
                                                                />
                                                                <div className="col-1 col-sm-1 col-lg-1 col-md-1 col-xl-1 col-xs-1 justify-content-center my-2 ms-2"> (L)</div></div>


                                                        </div>
                                                    </div>

                                                    <div className="col-6 col-sm-6 col-lg-6 col-md-6 col-xl-6 col-xs-6">
                                                        <div>
                                                            <div>{t("Fuel Consumption")} {t("(Maximum)")} </div>
                                                            <div className="col-11 col-sm-11 col-lg-11 col-md-11 col-xl-11 col-xs-11 d-flex">
                                                                <My_Input
                                                                    // type='number'
                                                                    height='38px'
                                                                    required={false}
                                                                    id={"monthly_fuel_consumed_max"}
                                                                    placeHolder={"fuel consumed (Max)"}
                                                                    value={deviceMinMaxData.monthly_fuel_consumed_max}
                                                                    onChange={(e) => _handleMinMaxChange(e, 'monthly_fuel_consumed_max')}
                                                                />
                                                                <div className="col-1 col-sm-1 col-lg-1 col-md-1 col-xl-1 col-xs-1 justify-content-center my-2 ms-2"> (L)</div></div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <div className="row">
                                                    <div className="col-6 col-sm-6 col-lg-6 col-md-6 col-xl-6 col-xs-6">
                                                        <div className="pb-3">

                                                            <div className="mb-1 ">{t("Monthly Fuel Consumption")} {t("(Minimum)")} </div>
                                                            <div className="col-11 col-sm-11 col-lg-11 col-md-11 col-xl-11 col-xs-11 d-flex">
                                                                <My_Input
                                                                    type='number'
                                                                    height='38px'
                                                                    required={false}
                                                                    id={"fuel_consumed_min"}
                                                                    placeHolder={"fuel consumed (Min)"}
                                                                    value={deviceMinMaxData?.monthly_fuel_consumed_min}
                                                                    onChange={(e) => _handleMinMaxChange(e, 'monthly_fuel_consumed_min')}
                                                                />
                                                                <div className="col-1 col-sm-1 col-lg-1 col-md-1 col-xl-1 col-xs-1 justify-content-center my-2 ms-2"> (L)</div></div>


                                                        </div>
                                                    </div>

                                                    <div className="col-6 col-sm-6 col-lg-6 col-md-6 col-xl-6 col-xs-6">
                                                        <div className="pb-3">
                                                            <div className="mb-1">{t("Monthly Fuel Consumption")} {t("(Maximum)")} </div>
                                                            <div className="col-11 col-sm-11 col-lg-11 col-md-11 col-xl-11 col-xs-11 d-flex">
                                                                <My_Input
                                                                    type='number'
                                                                    height='38px'
                                                                    required={false}
                                                                    id={"fuel_consumed_max"}
                                                                    placeHolder={"fuel consumed (Max)"}
                                                                    value={deviceMinMaxData.monthly_fuel_consumed_max}
                                                                    onChange={(e) => _handleMinMaxChange(e, 'monthly_fuel_consumed_max')}
                                                                />
                                                                <div className="col-1 col-sm-1 col-lg-1 col-md-1 col-xl-1 col-xs-1 justify-content-center my-2 ms-2"> (L)</div></div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </>
                                            :
                                            <>
                                                <div className="row">
                                                    <div className="col-6 col-sm-6 col-lg-6 col-md-6 col-xl-6 col-xs-6">
                                                        <div>
                                                            <div>{t("Battery Consumption")} {t("(Minimum)")}</div>
                                                            <div className="col-11 col-sm-11 col-lg-11 col-md-11 col-xl-11 col-xs-11 d-flex">
                                                                <My_Input
                                                                    // type='number'
                                                                    height='38px'
                                                                    required={false}
                                                                    id={"monthly_battery_consumed_min"}
                                                                    placeHolder={"battery consumed (Min)"}
                                                                    value={deviceMinMaxData.monthly_battery_consumed_min}
                                                                    onChange={(e) => _handleMinMaxChange(e, 'monthly_battery_consumed_min')}
                                                                />
                                                                <div className="col-1 col-sm-1 col-lg-1 col-md-1 col-xl-1 col-xs-1 justify-content-center my-2 ms-2"> (kWh)</div></div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-sm-6 col-lg-6 col-md-6 col-xl-6 col-xs-6">
                                                        <div >

                                                            <div>{t("Battery Consumption")} {t("(Maximum)")}</div>
                                                            <div className="col-11 col-sm-11 col-lg-11 col-md-11 col-xl-11 col-xs-11 d-flex">
                                                                <My_Input
                                                                    // type='number'
                                                                    height='38px'
                                                                    required={false}
                                                                    id={"monthly_battery_consumed_max"}
                                                                    placeHolder={"battery consumed (Max)"}
                                                                    value={deviceMinMaxData.monthly_battery_consumed_max}
                                                                    onChange={(e) => _handleMinMaxChange(e, 'monthly_battery_consumed_max')}
                                                                />
                                                                <div className="col-1 col-sm-1 col-lg-1 col-md-1 col-xl-1 col-xs-1 justify-content-center my-2 ms-2"> (kWh)</div></div>

                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <div className="row">
                                                    <div className="col-6 col-sm-6 col-lg-6 col-md-6 col-xl-6 col-xs-6">
                                                        <div className="pb-3">
                                                            <div className="mb-1">{t("Monthloy Battery Consumption")} {t("(Minimum)")}</div>
                                                            <div className="col-11 col-sm-11 col-lg-11 col-md-11 col-xl-11 col-xs-11 d-flex">
                                                                <My_Input
                                                                    type='number'
                                                                    height='38px'
                                                                    required={false}
                                                                    id={"battery_consumed_min"}
                                                                    placeHolder={"battery consumed (Min)"}
                                                                    value={deviceMinMaxData.monthly_battery_consumed_min}
                                                                    onChange={(e) => _handleMinMaxChange(e, 'monthly_battery_consumed_min')}
                                                                />
                                                                <div className="col-1 col-sm-1 col-lg-1 col-md-1 col-xl-1 col-xs-1 justify-content-center my-2 ms-2"> (kWh)</div></div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-sm-6 col-lg-6 col-md-6 col-xl-6 col-xs-6">
                                                        <div className="pb-3">

                                                            <div className="mb-1">{t("Monthly Battery Consumption")} {t("(Maximum)")}</div>
                                                            <div className="col-11 col-sm-11 col-lg-11 col-md-11 col-xl-11 col-xs-11 d-flex">
                                                                <My_Input
                                                                    type='number'
                                                                    height='38px'
                                                                    required={false}
                                                                    id={"battery_consumed_max"}
                                                                    placeHolder={"battery consumed (Max)"}
                                                                    value={deviceMinMaxData.monthly_battery_consumed_max}
                                                                    onChange={(e) => _handleMinMaxChange(e, 'monthly_battery_consumed_max')}
                                                                />
                                                                <div className="col-1 col-sm-1 col-lg-1 col-md-1 col-xl-1 col-xs-1 justify-content-center my-2 ms-2"> (kWh)</div></div>

                                                        </div>
                                                    </div>
                                                </div> */}
                                            </>
                                        }
                                        <div className="row">
                                            <div className="col-6 col-sm-6 col-lg-6 col-md-6 col-xl-6 col-xs-6">
                                                <div>
                                                    <div>{t("Mileage")} {t("(Minimum)")}</div>
                                                    <div className="col-11 col-sm-11 col-lg-11 col-md-11 col-xl-11 col-xs-11 d-flex">
                                                        <My_Input
                                                            // type='number'
                                                            height='38px'
                                                            required={false}
                                                            id={"monthly_mileage_min"}
                                                            placeHolder={"mileage (Min)"}
                                                            value={deviceMinMaxData.monthly_mileage_min}
                                                            onChange={(e) => _handleMinMaxChange(e, 'monthly_mileage_min')}
                                                        />
                                                        <div className="col-1 col-sm-1 col-lg-1 col-md-1 col-xl-1 col-xs-1 justify-content-center my-2 ms-2"> (km)</div></div>

                                                </div>
                                            </div>
                                            <div className="col-6 col-sm-6 col-lg-6 col-md-6 col-xl-6 col-xs-6">
                                                <div>

                                                    <div>{t("Mileage")} {t("(Maximum)")} </div>
                                                    <div className="col-11 col-sm-11 col-lg-11 col-md-11 col-xl-11 col-xs-11 d-flex">
                                                        <My_Input
                                                            // type='number'
                                                            height='38px'
                                                            required={false}
                                                            id={"monthly_mileage_max"}
                                                            placeHolder={"mileage (Max)"}
                                                            value={deviceMinMaxData?.monthly_mileage_max}
                                                            onChange={(e) => _handleMinMaxChange(e, 'monthly_mileage_max')}
                                                        />
                                                        <div className="col-1 col-sm-1 col-lg-1 col-md-1 col-xl-1 col-xs-1 justify-content-center my-2 ms-2"> (km)</div></div>

                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-6 col-sm-6 col-lg-6 col-md-6 col-xl-6 col-xs-6">                                       
                                            <div className="pb-3">

                                                <div className="mb-1">{t("Speed")} {t("(Minimum)")} </div>
                                                <div className="col-11 col-sm-11 col-lg-11 col-md-11 col-xl-11 col-xs-11 d-flex">
                                                    <My_Input
                                                        type='number'
                                                        height='38px'
                                                        required={false}
                                                        id={"speed_min"}
                                                        placeHolder={"speed (Min)"}
                                                        value={deviceMinMaxData.speed_min}
                                                        onChange={(e) => _handleMinMaxChange(e, 'speed_min')}
                                                    />
                                                <div className="col-1 col-sm-1 col-lg-1 col-md-1 col-xl-1 col-xs-1 justify-content-center my-2 ms-2 "> (km/h)</div></div>
                                            </div>
                                            </div>
                                            <div className="col-6 col-sm-6 col-lg-6 col-md-6 col-xl-6 col-xs-6">                                       
                                            <div className="pb-3">

                                                <div className="mb-1">{t("Speed")} {t("(Maximum)")} </div>
                                                <div className="col-11 col-sm-11 col-lg-11 col-md-11 col-xl-11 col-xs-11 d-flex">

                                                    <My_Input
                                                        type='number'
                                                        height='38px'
                                                        required={false}
                                                        id={"speed_max"}
                                                        placeHolder={"speed (Max)"}
                                                        value={deviceMinMaxData.speed_max}
                                                        onChange={(e) => _handleMinMaxChange(e, 'speed_max')}
                                                    />
                                            <div className="col-1 col-sm-1 col-lg-1 col-md-1 col-xl-1 col-xs-1 justify-content-center my-2 ms-2"> (km/h)</div></div>
                                            </div>

                                            </div> */}

                                        {/* <div className="col-6 col-sm-6 col-lg-6 col-md-6 col-xl-6 col-xs-6">  </div> */}

                                    </div>
                                </div>
                                <div className="text-end pt-3" style={{ paddingRight: "25px" }}>
                                    <span style={{ cursor: 'pointer' }}>
                                        <My_Button
                                            // disabled={role === "viewer" ? disableAddMinMaxVeicle : "" }
                                            type="submit"
                                            customColor={theme === 'dark' ? "#76A7DC" : "green"}
                                            className={`${theme === 'dark' ? 'text-black' : 'text-white'}`}
                                            text={t("Update")}
                                            // data-bs-dismiss="modal"
                                            style={{ width: "20%" }}

                                        />
                                    </span>
                                </div>

                            </form>


                        </div>
                    </div>
                </div>
            </div>
        );
    }));

const vehicleTypeOption = [
    { value: 'Battery', label: 'Battery' },
    { value: 'Diesel', label: 'Diesel' },
    { value: 'Petrol', label: 'Petrol' },
]

const assetTypeOption = [
    { value: 'Vehicle', label: 'Vehicle' },
    { value: 'Equipment', label: 'Equipment' }
]

const UploadImage = inject("store")(
    observer((props) => {
        const { t } = useTranslation("asset_profile_management")
        const theme = props.theme;
        const { _handleTextChange, deviceMinMaxData, deviceImage, _handleUploadImage } = props.store.assetProfile;



        return (
            <div
                className="d-flex flex-column justify-content-center align-items-center"
                style={{ background: "rgb(0,0,0,0.2)" }}
            >
                <label
                    style={{
                        width: "100%",
                        borderRadius: 5,
                        cursor: "pointer",
                    }}
                    htmlFor="image-upload"
                >
                    <input
                        type="file"
                        style={{ display: "none" }}
                        id="image-upload"
                        accept="image/x-png,image/gif,image/jpeg"
                        onChange={(e) => _handleUploadImage(e)}
                    />
                    {!deviceImage ? (
                        <span className="d-flex flex-column justify-content-center align-items-center h-100 p-0 m-3">
                            <i
                                style={{ fontSize: 30 }}
                                className="bi bi-cloud-arrow-up-fill p-5"
                            ></i>
                            <div>{t("Add an Image")}</div>
                        </span>
                    ) : (
                        <span className="d-flex justify-content-center m-3">
                            <img src={deviceImage} height={'84px'} />
                        </span>
                    )}
                </label>
            </div>
        );
    }));
