import { observable, action, makeObservable } from "mobx";
import { getMonth, getYear } from "date-fns";
class ManualEntry {
  currentYear = getYear(new Date());
  currentMonth = getMonth(new Date());
  selectTable = null; 
  constructor() {
    makeObservable(this, {
      currentYear: observable,
      setCurrentYear: action.bound,
      currentMonth: observable,
      setCurrentMonth: action.bound,
      selectTable: observable,
      setSelectTable: action.bound,
    });
  }
  setCurrentYear = (e) => {
    this.currentYear = e;
  };
  setCurrentMonth = (e) => {
    this.currentMonth = e;
  };
  setSelectTable = (e) => {
    this.selectTable = e;
  };
}
export default new ManualEntry();
