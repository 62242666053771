import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import $, { data } from "jquery";
import { withRouter } from "react-router-dom";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import {
  Pagination,
  PaginationItem,
  styled,
  TableSorttitle,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import { makeStyles } from "@mui/styles";
import { action, toJS } from "mobx";
import { Cookies } from "react-cookie";
import { CO2_Loading } from "../../Assets/CO2_Loading";
import { Submit_Loading } from "../../Assets/Submit_Loading";
import "../../stick-table.css";
import { useTranslation } from "react-i18next";
import { UnitProfilesInfoModal } from "./UnitProfilesInfoModal";

export const VehicleProfilesTable = inject("store")(
  observer((props) => {
    const { t } = useTranslation("data_import");
    const {
      theme,
      _handleViewContract,
      noPagination,
      vProfilesData,
      filenotexist,
    } = props;

    const { handlegetImportedVProfileData, SubmitLoading, loading } =
      props.store.dataImport;
    const cookies = new Cookies();
    const companyId = cookies.get("company");
    const companyName = cookies.get("companyName");

    const [unitProfile, setUnitProfile] = useState("")

    const rows = vProfilesData?.map((v, i) => {
      let profiles = {
        grouping: v.grouping,
        start_time: v.start_date,
        company: companyName,
        plate_number: v.plate_number,
        brand: v.brand,
        model: v.model,
        primary_fuel_type: v.primary_fuel_type,
        // vin_number: v.vin_number,
        oem_baseline_emission: v.oem_baseline_emission,
        // vehicle_type: v.vehicle_type,
        // vehicle_sub_type: v.vehicle_sub_type,
        // vehicle_length: v.vehicle_length,
        // vehicle_width: v.vehicle_width,
        // vehicle_high: v.vehicle_high,
        // gross_vehicle_weight: v.gross_vehicle_weight,
        cargo_type: v.cargo_type,
        carrying_capacity: v.carrying_capacity,
        secondary_fuel_type: v.secondary_fuel_type,
        fuel_tank_capacity: v.fuel_tank_capacity,
        battery_type: v.battery_type,
        battery_capacity: v.battery_capacity,
        battery_energy_consumption: v.battery_energy_consumption,
        error: v.error,
        // created_utc_time: v.created_utc_time,
        //   ownerId: companyId,
        action: ""
      };
      return profiles;
    });
    // console.log("vProfilesData =====>", toJS(rows));

    const openUnitProfilesModal = () => {
      let myModal = new window.bootstrap.Modal(
        document.getElementById("unitprofilesmodal")
      );
      myModal.show();
    };

    handlegetImportedVProfileData(rows);
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      cursor: "pointer",
      "&:nth-of-type(odd)": {
        backgroundColor:
          props.theme === "dark" ? Colors.darkBlue : Colors.Dark[5],
        color: theme.palette.common.white,
      },
      // hide last border
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    }));

    const tableRef = React.useRef(null);

    useEffect(() => {
      const styleElement = document.createElement("style");
      styleElement.innerHTML =
        theme === "dark"
          ? `
    
            .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
                color: black !important;
                border: 1px solid #e5e5e5!important;
                
                background-color: #e5e5e5 !important;
                /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #e5e5e5), color-stop(100%, #e5e5e5))!important; */
                background: -webkit-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
                background: -moz-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
                background: -ms-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%) !important;
                background: -o-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
                background: linear-gradient(to bottom, #e5e5e5 0%, #e5e5e5 100%)!important;
              }
    
              .dataTables_wrapper .dataTables_paginate .paginate_button.current {
                color: black !important;
                border: 1px solid #C6C6C6!important;
                
                background-color: #C6C6C6 !important;
                /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fffcfc), color-stop(100%, #969292))!important; */
                background: -webkit-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                background: -moz-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                background: -ms-linear-gradient(top, #fffcfc 0%, #969292 100%) !important;
                background: -o-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                background: linear-gradient(to bottom, #fffcfc 0%, #969292 100%)!important;
              }
    
              .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
                color: black !important;
                border: 1px solid #C6C6C6!important;
                
                background-color: #C6C6C6 !important;
                /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fffcfc), color-stop(100%, #969292))!important; */
                background: -webkit-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                background: -moz-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                background: -ms-linear-gradient(top, #fffcfc 0%, #969292 100%) !important;
                background: -o-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                background: linear-gradient(to bottom, #fffcfc 0%, #969292 100%)!important;
              }
    
    
            `
          : `
                .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
                    color: black !important;
                    border: 1px solid #e5e5e5!important;
                    
                    background-color: #e5e5e5 !important;
                    /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #e5e5e5), color-stop(100%, #e5e5e5))!important; */
                    background: -webkit-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
                    background: -moz-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
                    background: -ms-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%) !important;
                    background: -o-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
                    background: linear-gradient(to bottom, #e5e5e5 0%, #e5e5e5 100%)!important;
                  }
    
                  .dataTables_wrapper .dataTables_paginate .paginate_button.current {
                    color: black !important;
                    border: 1px solid #C6C6C6!important;
                    
                    background-color: #C6C6C6 !important;
                    /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fffcfc), color-stop(100%, #969292))!important; */
                    background: -webkit-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                    background: -moz-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                    background: -ms-linear-gradient(top, #fffcfc 0%, #969292 100%) !important;
                    background: -o-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                    background: linear-gradient(to bottom, #fffcfc 0%, #969292 100%)!important;
                  }
       
            `;
      document.head.appendChild(styleElement);

      function updateOrderAndDisplayStart(columnIndex, direction) {
        orderColumn = columnIndex;
        orderDirection = direction;

        table.order([[orderColumn, orderDirection]]).draw();
      }

      const storedOrderColumn = localStorage.getItem("orderColumn");
      const storedOrderDirection = localStorage.getItem("orderDirection");

      let orderColumn = storedOrderColumn ? parseInt(storedOrderColumn) : 0;
      let orderDirection = storedOrderDirection || "asc";
      const tableElement = $(tableRef.current);

      const table = tableElement.DataTable({
        autoWidth: false,
        responsive: true,
        retrieve: true,
        // scrollX: true,
        scrollCollapse: true,
        order: [[1, "asc"]],

        lengthMenu: [8, 10, 15],

        data: rows,
        columns: [
          {
            title: t("Grouping") + '<span style="color: #ffc107">*</span>',
            data: "grouping",

          },
          {
            title: t("Start Time") + '<span style="color: #ffc107">*</span>',
            data: "start_time",

          },
          {
            title: t("Company") + '<span style="color: #ffc107">*</span>',
            data: "company",
          },
          {
            title: t("Plate Number") + '<span style="color: #ffc107">*</span>',
            data: "plate_number",
          },
          {
            title: t("Brand") + '<span style="color: #ffc107">*</span>',
            data: "brand",
          },
          {
            title: t("Model") + '<span style="color: #ffc107">*</span>',
            data: "model",
          },
          {
            title:
              t("Primary Fuel Type") + '<span style="color: #ffc107">*</span>',
            data: "primary_fuel_type",
          },
          // {
          //   title: t("Vin Number") + '<span style="color: #ffc107">*</span>',
          //   data: "vin_number",
          // },
          {
            title:
              t("OEM Baseline Emission") +
              '<span style="color: #ffc107">*</span>',
            data: "oem_baseline_emission",
          },
          // { title: t("Vehicle Type"), data: "vehicle_type" },
          // { title: t("Vehicle Sub Type"), data: "vehicle_sub_type" },
          // { title: t("Vehicle Length"), data: "vehicle_length" },
          // { title: t("Vehicle Width"), data: "vehicle_width" },
          // { title: t("Vehicle High"), data: "vehicle_high" },
          // { title: t("Gross Vehicle Weight"), data: "gross_vehicle_weight" },
          { title: t("Cargo Type"), data: "cargo_type" },
          { title: t("Carrying Capacity"), data: "carrying_capacity" },
          { title: t("Secondary Fuel Type"), data: "secondary_fuel_type" },
          { title: t("Fuel Tank Capacity"), data: "fuel_tank_capacity" },
          { title: t("Battery Type"), data: "battery_type" },
          { title: t("Battery Capacity"), data: "battery_capacity" },
          {
            title: t("Battery Energy Consumption"),
            data: "battery_energy_consumption",
          },
          { title: t("Error"), data: 'error' },
          // { title: t("Created UTC Time"), data: "created_utc_time" },
          {
            title: t("Action"),
            render: function (data, type, row) {
              return `<button type="button" class="btn btn-sm m-1"
                        style="background-color: #4c5d75; color: white;"
                        data-unitId=${JSON.stringify(row.grouping)}
                        onclick="viewMore(event)"
                      >
                        <span data-unitId=${JSON.stringify(
                            row.grouping
                          )}>View More</span>   
                      </button>`
            }
          }
        ],
        "columnDefs": [
          {
            targets: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
            render: function (data, type, row) {
              return row.error !== ""
                ? '<span style="color: red;">' + data + "</span>"
                : data;
            },
          },
        ],
        paging: true,

        drawCallback: function (settings) {
          // console.log($('# ').DataTable().page.info());
          // console.log(settings.oSavedState);
        },
        language: {
          emptyTable:
            rows.length === 0 &&
            ` 
                      <div class="no-data-found " style="color: ${invertDark(
              theme
            )};  align-items: center; justify-content: center">
                      <i class="fa fa-exclamation-triangle me-2" style="color: orange;"></i>
                      ${t("No Data Found")}
                    </div>
                  `,
          // info: t("Showing _START_ to _END_ of _TOTAL_ entries"),
          // infoEmpty: t("Showing 0 to 0 of 0 entries"),
        },
      });

      window.viewMore = function (event) {
        const editId = event.target.dataset.unitid;
        const unit = vProfilesData.find((r) => r.grouping == editId);
        // addVehicleModal();
        // _handleEdit(unit);
        // updateOrderAndDisplayStart('asc');
        setUnitProfile(unit)
        let myModal = new window.bootstrap.Modal(
          document.getElementById("unitprofilesmodal")
        );
        myModal.show();
      };


      table.cells().every(function () {
        $(this.node()).css("border-color", "");
      });

      const searchInput = $(".dataTables_filter input");
      searchInput.css("color", theme === "light" ? "black" : "white");

      const tableRows = $(".dataTables_wrapper tr");
      const tableColumns = $(".dataTables_wrapper td");

      tableRows.css("color", theme === "light" ? "black" : "#fff");
      tableColumns.css("color", theme === "light" ? "black" : "#fff");

      const searchlabel = $(".dataTables_filter label");
      searchlabel.css("color", theme === "light" ? "black" : "white");

      const entriesSelect = $(".dataTables_length select");
      entriesSelect.css(
        "background-color",
        theme === "light" ? "#fff" : "#202b60"
      );
      entriesSelect.css("color", theme === "light" ? "black" : "white");

      const entriesLabel = $(".dataTables_length label");
      entriesLabel.css("color", theme === "light" ? "black" : "white");

      const tableInfo = $(".dataTables_info");
      tableInfo.css("color", theme === "light" ? "#000" : "#fff");

      const paginate = $("#vehicleProfilesTable_paginate");
      paginate.css("color", theme === "light" ? "#000" : "#fff");

      $("#vehicleProfilesTable").on("draw.dt", function () {
        const currentPage = table.page.info().page;
        const rows = table.rows({ page: "current" }).nodes();
        const cells = $(rows).find("td");

        cells.css("color", theme === "light" ? "#000" : "#fff");
      });

      $("#vehicleProfilesTable").on("page.dt", function () {
        localStorage.setItem("currentPage", table.page.info().page);
      });

      table.cells().every(function () {
        $(this.node()).css(
          "border-color",
          theme === "dark" ? "rgb(211, 211, 211, 0.3)" : ""
        );
      });

      const storedCurrentPage = localStorage.getItem("currentPage");
      const storedSearch = localStorage.getItem("search");

      const currentPage = storedCurrentPage ? parseInt(storedCurrentPage) : 0;
      table.page(currentPage).draw(false);

      if (storedSearch) {
        table.search(storedSearch).draw();
      }

      table.on("page.dt", function () {
        localStorage.setItem("currentPage", table.page.info().page);
      });

      table.on("search.dt", function () {
        const search = table.search();
        localStorage.setItem("search", search);
      });

      window.addEventListener("beforeunload", function () {
        localStorage.removeItem("orderColumn");
        localStorage.removeItem("orderDirection");
        localStorage.removeItem("currentPage");
      });

      window.addEventListener("load", function () {
        localStorage.removeItem("orderColumn");
        localStorage.removeItem("orderDirection");
        localStorage.removeItem("currentPage");
      });

      const storedPage = localStorage.getItem("currentPage");

      if (storedPage !== null) {
        const currentPage = parseInt(storedPage);
        if (!isNaN(currentPage)) {
          table.page(currentPage).draw("page");
        } else {
          table.page(0).draw("page");
        }
      } else {
        table.page(0).draw("page");
      }

      return () => {
        if ($.fn.DataTable.isDataTable("#vehicleProfilesTable")) {
          table.destroy();
        }
      };
    }, [theme, rows]);

    return (
      <>
        {loading ? (
          <CO2_Loading />
        ) : (
          <div>
            <table
              ref={tableRef}
              id="vehicleProfilesTable"
              className="display cell-border hover stripe"
              style={{ width: "100%" }}
            ></table>
          </div>
        )}
        <UnitProfilesInfoModal theme={theme} unitProfile={unitProfile} />
      </>
    );
  })
);
