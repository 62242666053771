import React, { useEffect, useState, useRef } from "react";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import { invertDark, Colors } from "../../Assets/Config/color.config";
import { CO2_Loading } from "../../Assets/CO2_Loading";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const LogsNotiCard = inject("store")(
  observer((props) => {
    const iconStyle = {
      fontSize: "16px",
      color: Colors.Dark[props.theme === "light" ? 2 : 4],
      cursor: "pointer",
    };
    const {handleClose} = props
    const history = useHistory()
    const { theme } = props;
    const { logsNoti, isLoading, _handleGetLogsNoti } = props.store.adminPanel;
    
   
    // console.log("logsNoti ===>",logsNoti)
    const [displayedLogs, setDisplayedLogs] = useState([]);
    const containerRef = useRef(null);

    useEffect(() => {
      _handleGetLogsNoti();
    }, []);

    useEffect(() => {
      setDisplayedLogs(logsNoti.slice(0, 10)); 
    }, [logsNoti]);

    console.log("displayedLogsNOTI", toJS(displayedLogs))

    const latestNewNoti = logsNoti[0];
    const newNotiCount = logsNoti.indexOf(latestNewNoti) + 1;
    // console.log("newNotiCount===>", newNotiCount) 

    const handleMoreViewClick = () => {
      history.push('/logs')
      handleClose()
    };

    return (
      <>
        {!isLoading ? (
          <div>
            <CO2_Loading />
          </div>
        ) : (
          <div
            className=""
            ref={containerRef}
            style={{ overflowY: "auto", overflowX: "hidden", height: 400 }}
          >
            {displayedLogs?.map((log, index) => (
              <div
                className="logsNotiCard mb-1"
                style={{
                  color: invertDark(theme),
                  background: "rgb(255,255,255,0.05)",
                  cursor: "pointer",
                  minHeight: 100,
                  maxWidth: 490,
                  border: theme === "light" && "1px solid rgb(0,0,0,0.3)",
                }}
                key={index}
              >
                {/* Render your log data */}
                <div className="p-2">
                  <div className="px-3">
                    <div>
                      <div className="row" style={{ fontSize: '20px'}}>
                        Action:
                        <span className="col text-truncate">{log.action}</span>
                      </div>
                      {/* <div>
                        Company:
                        <span></span>
                      </div> */}
                    </div>
                    <div className="row">{log.action_detail}</div>
                  </div>
                </div>
                <div className="row px-3">
                  <div className="col-md-5">
                    <span style={iconStyle}>
                      <i className="bi-person-circle pe-2" />
                      {log.name}
                    </span>
                  </div>
                  <div className="col-md-7">
                    <span style={iconStyle}>
                      <i className="bi bi-clock-fill pe-2" /> {log.time}
                    </span>
                  </div>
                </div>
              </div>
            ))}

          {displayedLogs.length < 10 ? (
              <div className="text-center mt-2">
                All logs data has been loaded.
              </div> ): 
               <button
               onClick={handleMoreViewClick}
               type="button"
               className={`${
                theme === "dark"
                  ? "btn  btn-outline-info btn-sm mt-2 mb-2 w-100"
                  : "btn btn-outline-success btn-sm mt-2 mb-2 w-100"
              }`}             >
                View More<i class="bi bi-arrow-right-circle-fill ps-2"></i>
             </button>
           }
           
            
             
          </div>
        )}
      </>
    );
  })
);

export default LogsNotiCard;
