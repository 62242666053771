import $, { data } from "jquery";
import { Colors } from "../../Assets/Config/color.config";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { useRef, useEffect, useState } from "react";
import "datatables.net-dt";
import "datatables.net-dt/css/jquery.dataTables.css";
import { useTranslation } from "react-i18next";
import { toJS } from "mobx";
import React from "react";

export const DataEntryTableJQ = inject("store")(
  observer((props) => {
    const {
      theme,
      _handleClearAudit,
      rows,
      _handleSelectVehicle,

      setVehicleIndex,
      _handleClearAuditImages,
      _handleCreateAuditImages,
      setPaginationPage,
      loadingForDataEntry,
      vehicleData,
      cardStyle1,
    } = props;

    const { role, companyId } = props.store.common;

    const {
      showManualEntryHistory,
      setShowManualEntryHistory,
      setManualIndex,
      _handleViewManual,
      manualsRowData,
      manualdataData,
      manuals,
      historyTableData,
    } = props.store.dataEntry;
    const { t } = useTranslation("new-manuel-entry");

    const tableRef = useRef(null);

    useEffect(() => {
      const styleElement = document.createElement("style");
      styleElement.innerHTML =
        theme === "dark"
          ? `
           

            .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
                color: black !important;
                border: 1px solid #e5e5e5!important;
                
                background-color: #e5e5e5 !important;
                /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #e5e5e5), color-stop(100%, #e5e5e5))!important; */
                background: -webkit-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
                background: -moz-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
                background: -ms-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%) !important;
                background: -o-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
                background: linear-gradient(to bottom, #e5e5e5 0%, #e5e5e5 100%)!important;
              }

              .dataTables_wrapper .dataTables_paginate .paginate_button.current {
                color: black !important;
                border: 1px solid #C6C6C6!important;
                
                background-color: #C6C6C6 !important;
                /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fffcfc), color-stop(100%, #969292))!important; */
                background: -webkit-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                background: -moz-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                background: -ms-linear-gradient(top, #fffcfc 0%, #969292 100%) !important;
                background: -o-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                background: linear-gradient(to bottom, #fffcfc 0%, #969292 100%)!important;
              }

              .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
                color: black !important;
                border: 1px solid #C6C6C6!important;
                
                background-color: #C6C6C6 !important;
                /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fffcfc), color-stop(100%, #969292))!important; */
                background: -webkit-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                background: -moz-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                background: -ms-linear-gradient(top, #fffcfc 0%, #969292 100%) !important;
                background: -o-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                background: linear-gradient(to bottom, #fffcfc 0%, #969292 100%)!important;
              }


            `
          : `
                .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
                    color: black !important;
                    border: 1px solid #e5e5e5!important;
                    
                    background-color: #e5e5e5 !important;
                    /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #e5e5e5), color-stop(100%, #e5e5e5))!important; */
                    background: -webkit-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
                    background: -moz-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
                    background: -ms-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%) !important;
                    background: -o-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
                    background: linear-gradient(to bottom, #e5e5e5 0%, #e5e5e5 100%)!important;
                  }

                  .dataTables_wrapper .dataTables_paginate .paginate_button.current {
                    color: black !important;
                    border: 1px solid #C6C6C6!important;
                    
                    background-color: #C6C6C6 !important;
                    /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fffcfc), color-stop(100%, #969292))!important; */
                    background: -webkit-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                    background: -moz-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                    background: -ms-linear-gradient(top, #fffcfc 0%, #969292 100%) !important;
                    background: -o-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                    background: linear-gradient(to bottom, #fffcfc 0%, #969292 100%)!important;
                  }
       
            `;
      document.head.appendChild(styleElement);

      function updateOrderAndDisplayStart(columnIndex, direction) {
        orderColumn = columnIndex;
        orderDirection = direction;

        table.order([[orderColumn, orderDirection]]).draw();
      }

      const storedOrderColumn = localStorage.getItem("orderColumn");
      const storedOrderDirection = localStorage.getItem("orderDirection");

      let orderColumn = storedOrderColumn ? parseInt(storedOrderColumn) : 0;
      let orderDirection = storedOrderDirection || "asc";

      const table = $("#dataEntryTable").DataTable({
        autoWidth: true,
        responsive: true,
        retrieve: true,
        scrollX: true,
        order: [[1, "asc"]],

        lengthMenu: [5],

        data: rows,
        columns: [
          { title: t("License No"), data: "license" },
          { title: t("Type"), data: "type" },
          {
            title: `${
              rows[0]?.type === "battery"
                ? "Fuel Consumed(kWh)"
                : "Fuel Consumed(L)"
            }`,
            render: function (data, type, row) {
              return row?.fuel_consumed?.includes("ascent")
                ? `<div><i class="fa fa-circle fa-xs" title="CANBus Data" style="color: #00B100; font-size: 10px; padding-right: 5px"></i>${row?.fuel_consumed?.replace(
                    " ascent",
                    ""
                  )} </div>`
                : row?.fuel_consumed?.includes("manual")
                ? `<div><i class="fa fa-circle fa-xs" title="Manual Data" style="color: #f4b183; font-size: 10px; padding-right: 5px"></i>${row?.fuel_consumed?.replace(
                    " manual",
                    ""
                  )} </div>`
                : row?.fuel_consumed;
            },
          },
          { title: t("Start Odometer") + " (km)", data: "odometer_start" },
          { title: t("End Odometer") + " (km)", data: "odometer" },
          {
            title: t("Trip Mileage (km)"),
            render: function (data, type, row) {
              return row?.trip_mileage?.includes("ascent")
                ? `<div><i class="fa fa-circle fa-xs" title="CANBus Data" style="color: #00B100; font-size: 10px; padding-right: 5px"></i>${row?.trip_mileage?.replace(
                    " ascent",
                    ""
                  )} </div>`
                : row?.trip_mileage?.includes("manual")
                ? `<div><i class="fa fa-circle fa-xs" title="Manual Data" style="color: #f4b183; font-size: 10px; padding-right: 5px"></i>${row?.trip_mileage?.replace(
                    " manual",
                    ""
                  )} </div>`
                : row?.trip_mileage;
            },
          },
          {
            title: t("GPS") + "",
            data: "gps",
            render: function (data, type, row, meta) {
              const Colors = {
                low: "green",
                high: "red",
              };
              if (row.gps) {
                return (
                  '<i class="fa fa-check pe-1" style="color: ' +
                  Colors.low +
                  ';"></i>'
                );
              } else {
                return (
                  '<i class="fas fa-times pe-1" style="color: ' +
                  Colors.high +
                  ';"></i>'
                );
              }
            },
          },
          {
            title: t("CANBus") + "",
            data: "canbus",
            render: function (data, type, row, meta) {
              let btns = "";
              const Colors = {
                low: "green",
                high: "red",
              };
              if (row.canbus) {
                return (
                  '<i class="fa fa-check pe-1" style="color: ' +
                  Colors.low +
                  ';"></i>'
                );
              } else {
                return (
                  '<i class="fas fa-times pe-1" style="color: ' +
                  Colors.high +
                  ';"></i>'
                );
              }
            },
          },
          {
            title: t("Action"),
            render: function (data, type, row, meta) {
              const index = meta.row;
              let btns = "";

              if (row.action === "Update") {
                btns += `
                                    <button type="button" class="btn btn-sm update-dataEntry-guide" style="background-color: #4c5d75; color: white"
                                        data-rowData=${JSON.stringify(row.id)}
                                        onclick="handleSelectVehicle(event)">
                                        Update
                                    </button>
                                `;
              } else if (row.action === "View") {
                btns += `
                                <button type="button" class="btn btn-sm update-dataEntry-guide" style="background-color: #4c5d75; color: white" 
                                    data-rowData=${JSON.stringify(row.id)}
                                    onclick="handleViewManual(event)">
                                    <i class="bi bi-eye" 
                                        data-rowData=${JSON.stringify(
                                          row.id
                                        )}></i>
                                </button>
                            `;
              } else {
                btns +=
                  '<div class="btn btn-sm noupdate-dataEntry-guide" style="padding:0;"></div>';
              }

              return btns;
            },
          },
        ],
        paging: true,

        drawCallback: function (settings) {
          console.log($("#dataEntryTable").DataTable().page.info());
          console.log(settings.oSavedState);
        },
      });

      table.column(0).nodes().to$().css("width", "14%");
      table.column(1).nodes().to$().css("width", "14%");
      table.column(2).nodes().to$().css("width", "14%");
      table.column(3).nodes().to$().css("width", "14%");
      table.column(4).nodes().to$().css("width", "14%");
      table.column(5).nodes().to$().css("width", "14%");
      table.column(6).nodes().to$().css("width", "8%");
      table.column(7).nodes().to$().css("width", "8%");
      table.column(8).nodes().to$().css("width", "14%");

      table.cells().every(function () {
        $(this.node()).css("border-color", "");
      });

      const searchInput = $(".dataTables_filter input");
      searchInput.css("color", theme === "light" ? "black" : "white");

      const searchlabel = $(".dataTables_filter label");
      searchlabel.css("color", theme === "light" ? "black" : "white");

      const entriesSelect = $(".dataTables_length select");
      entriesSelect.css(
        "background-color",
        theme === "light" ? "#fff" : "#202b60"
      );
      entriesSelect.css("color", theme === "light" ? "black" : "white");

      const entriesLabel = $(".dataTables_length label");
      entriesLabel.css("color", theme === "light" ? "black" : "white");

      const tableInfo = $(".dataTables_info");
      tableInfo.css("color", theme === "light" ? "#000" : "#fff");

      const paginate = $(".dataTables_paginate paginate_button");
      paginate.css("color", "#000 !important");

      $("#dataEntryTable").on("click", "tbody tr", function () {
        const data = table.row(this).data();
      });

      $("#dataEntryTable").on("page.dt", function () {
        localStorage.setItem("currentPage", table.page.info().page);
      });

      const storedCurrentPage = localStorage.getItem("currentPage");
      const storedSearch = localStorage.getItem("search");

      const currentPage = storedCurrentPage ? parseInt(storedCurrentPage) : 0;
      table.page(currentPage).draw(false);

      if (storedSearch) {
        table.search(storedSearch).draw();
      }

      table.on("page.dt", function () {
        localStorage.setItem("currentPage", table.page.info().page);
      });

      table.on("search.dt", function () {
        const search = table.search();
        localStorage.setItem("search", search);
      });

      window.handleSelectVehicle = function (event) {
        event.preventDefault();

        const index = table.row($(this).parents("tr")).index();
        const rowId = event.target.dataset.rowdata;
        const rowdata = rows.find((v) => v.id === rowId);

        const handleClick = () => {
          _handleSelectVehicle(rowdata);
          _handleClearAudit();
          setVehicleIndex(index, rowdata);
        };

        handleClick();
      };

      window.handleViewManual = function (event) {
        event.preventDefault();

        const index = table.row($(this).parents("tr")).index();
        const rowId = event.target.dataset.rowdata;
        const rowdata = rows.find((v) => v.id === rowId);

        const handleClick = () => {
          _handleViewManual(rowdata);
          setShowManualEntryHistory(true);
          setManualIndex(index, rowdata);
        };

        handleClick();
      };

      window.addEventListener("beforeunload", function () {
        localStorage.removeItem("orderColumn");
        localStorage.removeItem("orderDirection");
        localStorage.removeItem("currentPage");
      });

      window.addEventListener("load", function () {
        localStorage.removeItem("orderColumn");
        localStorage.removeItem("orderDirection");
        localStorage.removeItem("currentPage");
      });

      const storedPage = localStorage.getItem("currentPage");

      if (storedPage !== null) {
        const currentPage = parseInt(storedPage);
        if (!isNaN(currentPage)) {
          table.page(currentPage).draw("page");
        } else {
          table.page(0).draw("page");
        }
      } else {
        table.page(0).draw("page");
      }

      return () => {
        if ($.fn.DataTable.isDataTable("#dataEntryTable")) {
          table.destroy();
        }
      };
    }, [theme, rows]);

    return (
      <div>
        <div>
          <table
            ref={tableRef}
            id="dataEntryTable"
            className="display cell-border hover stripe"
            style={{ width: "100%" }}
          ></table>
        </div>
      </div>
    );
  })
);
