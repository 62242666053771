import moment from "moment";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { useTranslation } from "react-i18next";

export const ContractDetail = (props) => {
  const { contractDetailData, contractData, theme } = props;
  const {t} = useTranslation("track_and_trace")
  return (
    <div className="table-responsive text-light w-100">
        
        <div style={{ color: invertDark(theme), paddingBottom: '20px' }}>
            <div className="row">
                <strong  className="col ">{t("Batch ID")}</strong>
                <span className="col ">
                <div className="d-flex"> <div className="me-1">:</div><span>{contractDetailData?.batchId}</span></div>
                </span>
            </div>
            <div className="row mt-1">
                <strong  className="col ">{t("Month")}</strong>
                <span className="col ">
                  : {contractDetailData?.dataList?.month}
                </span>
            </div>

            <div className="row mt-1">
                <strong  className="col">{t("Carbon Emission")}</strong>
                <span className="col">
                : {Number((contractDetailData?.dataList?.currentCo2Emission)).toFixed(2)} kgCO2e
                </span>
            </div>
            <div className="row mt-1">
                <strong className="col">{t("Carbon Emission Offset")}</strong>
                <span className="col">: {Number(contractDetailData?.dataList?.offset).toFixed(2)} kgCO2e</span>
            </div>
            <div className="row mt-1">
                <strong className="col">{t("Total Distance Travelled")}</strong>
                <span className="col">: {Number(contractDetailData?.dataList?.totalDistance).toFixed(2)} km</span>
            </div>
            <div className="row mt-1">
                <strong className="col">{t("Total Trip")}</strong>
                <span className="col">: {contractDetailData?.dataList?.totalTrip}</span>
            </div>
            <div className="row mt-1">
                <strong className="col">{t("Vehicle Count")}</strong>
                <span className="col">: {contractDetailData?.dataList?.vehicleCount}</span>
            </div>
                    
        </div>
      
    </div>
  );
};
