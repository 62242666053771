import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { CO2_Loading } from "../../Assets/CO2_Loading";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { My_Button, My_Input } from "../../Tools";
import { baseURL } from "../../network/URL";
import { useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import { Cookies } from "react-cookie";
import { toJS } from "mobx";
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import { CompanyListWithChildCompanies } from "./CompanyListWithChildCompanies";

import { useMediaPredicate } from "react-media-hook";
import { useTranslation } from "react-i18next";
import { FilterList } from "@mui/icons-material";



const CompanyList = inject("store")(
    observer((props) => {
        const { t } = useTranslation('dashboard')
        const { _handleClick, theme, logo, landingAscent } = props;
        const { companyList, companyListWithChildCompanies, fetchCompany, _handleMouseHover, hoverId, isHover, fetchCompanyWithChildCompanies } =
            props.store.landing;
        const { role, companyId, companyName, ascentAccountId, ascentTokenKey, level } = props.store.common
        const cookie = new Cookies();
        const token = cookie.get('accessToken')
        const planData = token && jwtDecode(token).planData;

        const isMobile = useMediaPredicate("(min-width: 250px) and (max-width: 500px)");
        const isTablet = useMediaPredicate("(min-width: 500px) and (max-width: 1000px)");
        const isDestop = useMediaPredicate("(min-width: 1000px) and (max-width: 2000px)");
        const surfaceDuo = useMediaPredicate("(min-width : 500 px) and (max-width : 600px)");

        const [searchTerm, setSearchTerm] = useState()

        useEffect(() => {
            // fetchCompany(role, companyId)
            fetchCompanyWithChildCompanies(companyId)
        }, [])

        // companyListWithChildCompanies?.map(v => v.childCompanies)[0].map((d, k) => d.childCompanies.map((i, k) => console.log("===>", toJS(i.name))));
        // const company = companyListWithChildCompanies?.map((v,i) => toJS(v.childCompanies))


        const filterCompany = (companyList) => {
            let filteredCompanies = [];


            for (let i = 0; i < companyList?.length; i++) {
                const currentCompany = companyList[i];
                filteredCompanies.push(currentCompany);
                const filteredChildCompanies = filterCompany(currentCompany.childCompanies);
                filteredCompanies = filteredCompanies.concat(filteredChildCompanies);
            }

            return filteredCompanies;
        }

        let companies = filterCompany(companyListWithChildCompanies)

        const filteredList = companies?.filter((company) =>
            company?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
        );



        if (role === role) {
            return (

                <div
                    className="text-center text-light col-12 mx-3"
                    style={{
                        background:
                            theme === "dark" ? "rgb(0,0,0,0.3)" : "rgb(0,0,0,0.05)",
                        // landingAscent === 1 ? 'red': '',
                        borderRadius: 10,
                        boxShadow: theme === "light" && "0 0 2px rgb(0,0,0,0.4)",
                        maxWidth: isMobile ? '' : isTablet ? '' : landingAscent === 1 ? '650px' : '650px',

                    }}
                >
                    <img src="CO2_Logo.png" alt="icon" className="mt-3" />

                    <h4 style={{ color: theme === 'dark' ? Colors.Dark[3] : Colors.Dark[0] }}>
                        {t("Select a company")}
                    </h4>

                    {companyListWithChildCompanies?.length > 0 ?
                        <div style={{ position: 'relative', marginTop: '20px' }}>
                            <span style={{ position: 'absolute', right: '10%', top: '13%', paddingRight: '10px', color: 'grey' }}><i className="fa fa-search"></i></span>
                            <div className="d-flex justify-content-center"><input style={{ width: '82%', height: '40px', marginBottom: '20px', background: "none", color: theme === "dark" ?'white':'black', outline: 'none', borderBottom: "1px solid silver", borderLeft: 'none', borderTop: 'none', borderRight: 'none' }} value={searchTerm} placeHolder={"Search Company..."} onChange={(e) => setSearchTerm(e.target.value)} /></div>
                        </div>
                        : 
                        ""
                    }
                    <div className="d-flex justify-content-center pb-3 pt-2  flex-wrap"
                        style={{ height: '620px' }}>

                        <div className="text-start col-10" style={{ height: '100%', overflowX: 'hidden', overflowY: 'scroll' }} >

                            <div className="d-flex flex-column justify-content-center pb-3 pt-2  flex-wrap"
                            // style={{ overflowX: 'hidden' }}
                            >
                                <CompanyListWithChildCompanies
                                    level={level}
                                    companyList={companyListWithChildCompanies}
                                    _handleMouseHover={_handleMouseHover}
                                    hoverId={hoverId}
                                    theme={theme}
                                    _handleClick={_handleClick}
                                    filteredList={filteredList}
                                    searchTerm={searchTerm}
                                />
                            </div>

                        </div>

                    </div>
                </div>
            );
        } else {
            return (
                <div
                    className="text-center text-light col-5"
                    style={{
                        background: theme === "dark" ? "rgb(0,0,0,0.3)" : "rgb(0,0,0,0.05)",
                        borderRadius: 10,
                        boxShadow: theme === "light" && "0 0 2px rgb(0,0,0,0.4)",
                        height: role !== 'super_admin' ? 225 : 180,
                    }}
                >
                    <img src={`${baseURL + logo}`} alt="icon" className="mt-3 bg-white p-2 rounded mb-2" height={role !== 'super_admin' ? 100 : 45} />
                    <h4 style={{ color: theme === 'dark' ? Colors.Dark[3] : Colors.Dark[0] }}>{companyName}</h4>
                    <div
                        className="col-12 py-2"
                        // style={{position:'absolute', bottom:20}}
                        onMouseEnter={() => _handleMouseHover("co2")}
                        onMouseLeave={() => _handleMouseHover("co2")}
                    >
                        <div
                            onClick={() => { }
                                // (window.location.href = planData.enterprise_overview_access ? "/organisation-level-carbon-emission-dashboard" : "/lite-dashboard")
                            }
                            className="p-2 rounded mx-3 d-flex justify-content-between"
                            style={{
                                background: theme === "light" ? Colors.Dark[3] : Colors.darkBlue,
                                cursor: "pointer",
                                color:
                                    hoverId === "co2" && isHover
                                        ? Colors.Dark[5]
                                        : invertDark(theme),
                            }}
                        >
                            {companyName} <i className="bi bi-arrow-right"></i>
                        </div>
                    </div>
                </div>
            );
        }
        // return (
        //   <div
        //     id="/organisation-level-carbon-emission-dashboard"
        //     className="text-white d-flex justify-content-center position-relative"
        //     style={{
        //       cursor: "pointer",
        //       background: theme === "dark" ? "rgb(0,0,0,0.2)" : "rgb(0,0,0,0.05)",
        //       borderRadius: 10,
        //       // boxShadow: theme === "light" && "0 0 10px rgb(0,0,0,0.4)",
        //     }}
        //     onMouseEnter={() => _handleMouseHover("co2")}
        //     onMouseLeave={() => _handleMouseHover("co2")}
        //     onClick={() =>
        //       (window.location.href =
        //         "/organisation-level-carbon-emission-dashboard")
        //     }
        //   >
        //     <img
        //       style={{
        //         boxShadow:
        //           isHover && hoverId === "co2"
        //             ? theme === "dark"
        //               ? "0 0 5px #fff"
        //               : "0 0 15px #C6C6C6"
        //             : theme === "dark"
        //             ? "0 0 5px #000"
        //             : "0 0 3px #C6C6C6",
        //         // borderRadius: 10,
        //       }}
        //       className="bg-white p-3 m-3 rounded"
        //       width={300}
        //       src={`${baseURL + logo}`}
        //     />
        //     <span
        //       style={{
        //         position: "absolute",
        //         fontSize: 20,
        //         bottom: -35,
        //         color:
        //           isHover && hoverId === "co2"
        //             ? theme === "dark"
        //               ? Colors.Dark[5]
        //               : Colors.Dark[0]
        //             : theme === "dark"
        //             ? Colors.Dark[3]
        //             : Colors.Dark[2],
        //       }}
        //     >
        //       {companyName}
        //     </span>
        //   </div>
        // );
    }))

export default inject("store")(withRouter(observer(CompanyList)));





// Tree View Test

{/* <div
                className="text-center text-light col-5"
                style={{
                    background: theme === "dark" ? "rgb(0,0,0,0.3)" : "rgb(0,0,0,0.05)",
                    borderRadius: 10,
                    boxShadow: theme === "light" && "0 0 2px rgb(0,0,0,0.4)",
                }}
            >
                <img src="CO2_Logo.png" alt="icon" className="mt-3" />
                <h4 style={{ color: Colors.Dark[3] }}>Select a company</h4>
                <div className="d-flex flex-row justify-content-center pb-3 pt-2 flex-wrap">
                    <TreeView
                        aria-label="file system navigator"
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        sx={{ height: 500, flexGrow: 1, maxWidth: '100%', overflowY: 'auto' }}
                    >
                        <TreeItem style={{ width: '100%'}} nodeId="1" label={
                            <div className="flex-column text-start col-10">
                            {companyList ? (
                                Object.values(companyList.response).map((v, k) => (
                                    <div
                                        key={k}
                                        id={"card" + k}
                                        className="col-12 py-1"
                                        onMouseEnter={() => _handleMouseHover(k)}
                                        onMouseLeave={() => _handleMouseHover(k)}
                                    >
                                        <div
                                            className="row py-2 rounded  px-2"
                                            style={{
                                                background:
                                                    theme === "light" ? Colors.Dark[3] : Colors.darkBlue,
                                                cursor: "pointer",
                                                color: invertDark(theme),
                                                opacity: k === hoverId ? 1 : hoverId === null ? 1 : 0.5,
                                            }}
                                            onClick={(e) => _handleClick(e, v.name, v.logo)}
                                            id={v.id}
                                        >
                                            <div id={v.id} className='d-flex justify-content-between align-items-center'>{v.name}<img className="p-2" style={{ background: '#fff', borderRadius: 5 }} height='40px' src={`${baseURL + v.logo}`} /></div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <CO2_Loading />
                            )}
                        </div>
                        } >
                            <TreeItem style={{ width: '100%'}} nodeId="2" className='pt-3' label={<div className="flex-column text-start col-10">
                            {companyList ? (
                                Object.values(companyList.response).map((v, k) => (
                                    
                                    <div
                                            key={k}
                                            id={"card" + k}
                                            className="col-12 py-1"
                                            onMouseEnter={() => _handleMouseHover(k)}
                                            onMouseLeave={() => _handleMouseHover(k)}
                                        >
                                            <div
                                                className="row py-2 rounded  px-2"
                                                style={{
                                                    background:
                                                        theme === "light" ? Colors.Dark[3] : Colors.darkBlue,
                                                    cursor: "pointer",
                                                    color: invertDark(theme),
                                                    opacity: k === hoverId ? 1 : hoverId === null ? 1 : 0.5,
                                                }}
                                                onClick={(e) => _handleClick(e, v.name, v.logo)}
                                                id={v.id}
                                            >
                                                <div id={v.id} className='d-flex justify-content-between align-items-center'>{v.name}<img className="p-2" style={{ background: '#fff', borderRadius: 5 }} height='40px' src={`${baseURL + v.logo}`} /></div>
                                            </div>
                                        </div>
                                        
                                    
                                ))
                            ) : (
                                <CO2_Loading />
                            )}
                        </div>} ></TreeItem>
                        </TreeItem>
                    </TreeView>
                    
                </div>
            </div> */}