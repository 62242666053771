import { inject, observer } from "mobx-react";
import React, { useState } from 'react'
import { Colors } from "../../Assets/Config/color.config";
import { invertDark } from "../../Assets/Config/color.config";
import { toJS } from "mobx";

export const DataEntryVehicleList = inject("store")(
    observer((props) => {

        const { theme } = props
        const { selectedUnit, _setSelectedUnit, dataEntryData, currentMonth, _handleGetConsumptionByMonthPerUnit, setLoadingForDataEntry, _handleSearchVehicles, searchedUnit, setShowManualEntryHistory } = props.store.dataEntry;

        const [hover, setHover] = useState("")
        
        const VehicleList = dataEntryData?.payload?.find(v => v?.month === (currentMonth + 1))?.vehicles

        const filteredVehicles = VehicleList?.filter(v => v.grouping.toLowerCase().includes(searchedUnit?.toLowerCase()))

        return (
            <div className='d-flex flex-column gap-2 pt-2'>
                {dataEntryData && dataEntryData?.payload?.length > 0 ?
                    <div className='d-flex justify-content-between align-items-center p-2' style={{ background: theme === "dark" ? "#101841" : "", color: invertDark(theme), borderRadius: '6px', width: '100%', height: '60px', boxShadow: theme === 'dark' ? '' : "0 3px 10px rgb(0 0 0 / 0.2)" }}>
                        <span style={{ width: '100%' }}> 
                            <input type='text' value={searchedUnit} className="p-1" onChange={(e) => _handleSearchVehicles(e.target.value)} style={{ width: '100%', background: theme === "dark" ? "#202b60" : "", color: invertDark(theme), borderRadius: '4px', border: theme === "dark" ? "1px solid rgba(255, 255, 255, 0.2)" : "1px solid silver", outline: "none" }} placeholder="Search..." />
                        </span> 
                        <div><label className="pe-2" style={{ color: theme === "dark" ? 'white' : "grey" }}><i className="fa fa-search" style={{ paddingLeft: "10px" }} /></label></div> 

                        {/* <div className="mt-3justify-content-center">

                            <div className="input-group mb-3">
                                <input type="text" className="form-control" placeholder="Search products...." aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                <div className="">
                                    <button className="btn btn-lg" style={{ background: "white", borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px'}} type="button"><i className="fa fa-search"></i></button>
                                </div>
                            </div>

                        </div> */}
                    </div>
                    :
                    ""
                }
                <div style={{ height: '580px', overflowY: 'scroll' }}>
                    {filteredVehicles?.map((v, index) =>
                        v.GPS && v.CANbus ? "" : <div key={index} className='d-flex justify-content-center align-items-center p-2 mt-2 text-center'
                            onMouseOver={() => setHover(index)}
                            onMouseLeave={() => setHover("")}
                            onClick={() => {
                                setShowManualEntryHistory(false)
                                _setSelectedUnit(v.id)
                                setLoadingForDataEntry(false)
                                _handleGetConsumptionByMonthPerUnit(v.grouping)
                            }}
                            style={{
                                background: theme === 'dark' ? selectedUnit === v.id ? "grey" : "#101841" : selectedUnit === v.id ? "grey" : "",
                                // background: "#091662",
                                color: theme === "dark" ? "white" : selectedUnit === v.id ? "white" : "black",
                                border: theme === "dark" ? hover === index ? '1px solid white' : "" : hover === index ? "1px solid black" : "1px solid silver", // Fix typo: change 'broder' to 'border'
                                borderRadius: '6px',
                                // width: '100%',
                                height: '50px',
                                cursor: 'pointer'
                            }}>
                            <span className="p-1 text-center" style={{ width: '100%' }}>
                                {v.grouping}
                            </span>
                        </div>
                        
                    )}
                </div>
            </div>
        )
    }))
