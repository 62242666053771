/* eslint-disable no-lone-blocks */
import { action, makeObservable, observable, runInAction, toJS } from "mobx";
import { getMonth, getYear } from "date-fns";
import {
  FetchEntryData,
  UploadFile,
  UploadDataEntry,
  FetchCompanyVehiclesForAddNewUser,
  AssigningVehicle,
  AssigningUnitsToCompany,
  FetchUnitsForCompany,
  FetchSelectedVehicleList,
  FetchManualEntry,
  DeleteFMSEntryFile,
  CreateManualEntryNew,
  GetUnitMonthByMonth,
  GetConsumptionByMonth,
  UploadFileForOther,
  AssigningAllVehicles,
  GetConsumptionByMonthV2,
  UploadDataEntryV2,
  GetNewConsumptionByMonth,
  FetchActionStatus,
  GetConsumptionByMonthPerUnit
} from "../network/fetchDataEntryAPI";
import Common from "./common";
import { toast } from "react-toastify";
import { Cookies } from "react-cookie";
import { baseIMGURL, baseURL } from "../network/URL";
import { ro } from "date-fns/locale";
import Tesseract from "tesseract.js";
import { createWorker } from "tesseract.js";
import React from "react";
import preprocessImage from "./preprocess";
import Swal from "sweetalert2";
import * as pdfjsLib from "pdfjs-dist";
import "pdfjs-dist/build/pdf.worker.entry";
import $, { data } from 'jquery'

class DataEntry {
  cookies = new Cookies();
  companyId = this.cookies.get("company");
  currentYear = getYear(new Date()) + "";
  currentType = "";
  fuelType = "";
  consumedStr = "";
  isLoading = false;

  imagePreviewNames = [
    ["ev" === "ev" ? "Charging Receipt" : "Fuel Receipt"],
    "Odometer Start",
    "Odometer End",
  ];
  currentTitle = "";
  currentTableData = null;
  currentMonth = getMonth(new Date());
  dataEntryData = [];

  captureOdometer = new Date();

  fileStartOfMonth = null;
  fileEndOfMonth = null;
  fileFuelRecords = null;

  vehicleType = {};
  vehicleIndex = "";
  manualIndex = '';
  tableVehicleRow = [];
  tableManualRow = [];
  vehicleId = "";

  fileStart = "";
  fileEnd = "";
  fileFuel = "";
  text = "";
  filefueltype = "";
  fuelname = "";
  quantity = "";
  totalamount = "";
  pricePerL = "";
  invoiceNo = "";
  date = "";
  typeStatus = "Not Applicable";
  fuelStatus = "Not Applicable";
  canvasRef = React.createRef();
  imageRef = React.createRef();
  convertText = "";
  fuelTypesDict = [
    { id: 1, filefueltype: "Petrol", name: "95", oriTxt: "95" },
    { id: 2, filefueltype: "Gas", name: "CNG", oriTxt: "CNG" },
    { id: 3, filefueltype: "Gas", name: "LNG", oriTxt: "LNG" },
    { id: 4, filefueltype: "Petrol", name: "LPG", oriTxt: "LPG" },
    { id: 5, filefueltype: "Petrol", name: "92", oriTxt: "92" },
    { id: 6, filefueltype: "Diesel", name: "HSD", oriTxt: "HSD" },
    { id: 7, filefueltype: "Diesel", name: "LOD", oriTxt: "LOD" },
    { id: 8, filefueltype: "Gas", name: "Gas", oriTxt: "Gas" },
    { id: 9, filefueltype: "Petrol", name: "Petrol", oriTxt: "Petrol" },
    { id: 10, filefueltype: "Diesel", name: "Diesel", oriTxt: "Diesel" },
    { id: 11, filefueltype: "Petrol", name: "Regular", oriTxt: "regular" },
    { id: 12, filefueltype: "Petrol", name: "Midgrade", oriTxt: "midgrade" },
    { id: 13, filefueltype: "Petrol", name: "Premium", oriTxt: "premium" },
    { id: 14, filefueltype: "Petrol", name: "Plus", oriTxt: "plus" },
    { id: 15, filefueltype: "Petrol", name: "Octane", oriTxt: "Octane" },
    {
      id: 16,
      filefueltype: "Petrol",
      name: "FUELSAVE 95",
      oriTxt: "FUELSAVE 95",
    },
    {
      id: 17,
      filefueltype: "Petrol",
      name: "Premium 95",
      oriTxt: "Premium 35",
    },
  ];

  fuelVolumesDict = [
    { id: 1, quantity: "Gal", unit: "gal" },
    { id: 2, quantity: "Ltr", unit: "ltr" },
    { id: 3, quantity: "Gallons", unit: "gal" },
    { id: 4, quantity: "Liters", unit: "ltr" },
    { id: 5, quantity: "Litres", unit: "ltr" },
    { id: 6, quantity: "Volume", unit: "" },
    { id: 7, quantity: "Premium 35 with T/", unit: "ltr" },
  ];

  fuelCostsDict = [
    { id: 1, amount: "Amount" },
    { id: 2, amount: "Total" },
    { id: 3, amount: "Total sale" },
    { id: 4, amount: "FUEL SALE" },
    { id: 5, amount: "Total Cost" },
  ];

  confusedformat = [
    { id: 1, amount: "Total pts" },
    { id: 2, amount: "total GST" },
    { id: 3, amount: "GST Rate" },
    { id: 4, amount: "PETROLEUM (PTE)" },
    { id: 5, amount: "Invoice due date" },
  ];

  pricePerLDict = [
    { id: 1, text: "@", format: "L" },
    { id: 2, text: "RATE", format: "" },
    { id: 3, text: "PRICE/G", format: "" },
  ];

  invoiceNoDict = [
    { id: 1, text: "CRN" },
    { id: 2, text: "SN" },
    { id: 3, text: "Bill No" },
    { id: 4, text: "INVOICE NO" },
  ];

  dateDict = [{ id: 1, text: "Date" }];

  evTypesDict = [
    { id: 1, filefueltype: "Battery", name: "DC", oriTxt: "DC" },
    {
      id: 2,
      filefueltype: "Battery",
      name: "Electricity",
      oriTxt: "Electricity",
    },
    { id: 3, filefueltype: "Battery", name: "Energy", oriTxt: "Energy" },
    { id: 4, filefueltype: "Battery", name: "Battery", oriTxt: "Battery" },
  ];

  evCostsDict = [
    { id: 1, amount: "Amount" },
    { id: 2, amount: "Total sale" },
    { id: 3, amount: "GST" },
    { id: 4, amount: "Total Cost" },
  ];

  evVolumesDict = [
    { id: 1, quantity: "Electricity consumed", unit: "kwh" },
    { id: 2, quantity: "Total Energy", unit: "kwh" },
    { id: 3, quantity: "Energy Usage", unit: "kwh" },
  ];

  evpricePerkwhDict = [
    { id: 1, text: "Public rate" },
    { id: 2, text: "/kwh" },
  ];

  evinvoiceNoDict = [
    { id: 1, text: "CRN" },
    { id: 2, text: "SN" },
    { id: 3, text: "Bill No" },
    { id: 4, text: "INVOICE NO" },
    { id: 5, text: "Text:" },
  ];

  evDateDict = [
    { id: 1, text: "Date" },
    { id: 2, text: "Time" },
  ];

  evConfusedformat = [
    { id: 1, amount: "Total pts" },
    { id: 2, amount: "total GST" },
    { id: 3, amount: "GST Rate" },
    { id: 4, amount: "UEN/GST No" },
    { id: 5, amount: "Invoice due date" },
    { id: 6, amount: "Total Time" },
    { id: 7, amount: "Timezone" },
  ];

  fileStartName = "";
  fileEndName = "";
  fileFuelName = "";

  odometerStartRecord = "";
  odometerEndRecord = "";

  odometerStartStr = "";
  odometerEndStr = "";
  totalMileageStr = "";

  vehicleData = null;
  VData = null;
  manualdataData = null;
  manuals = null;
  vehicleId = "";

  userId = "";
  isCheck = false;
  showAscent = false;
  rows = [];

  currentImage = "";
  currentImageName = "";

  fmsUploadData = "";

  consumedInfo = "";
  odometerStartInfo = "";
  odometerEndInfo = "";
  tripMilageInfo = "";

  selectedYearFromNav = getYear(new Date()) + "";
  dataForNavBar = [];

  checkedVehicles = [];
  moveVehicles = [];

  isCheckClick = false;

  companyVehicles = [];
  selectedUser = "";
  selectedCompany = "";
  userCompanyId = "";
  fromCompanyId = "";

  selectedVehicle = [];
  availableVehicle = [];
  checkedValues = {
    assignChecked: false,
    unassignChecked: false,
  };
  showAlert = false;
  istimeout = false;
  isImgNotFound = false;

  newAudit = [];
  auditId = "";
  deleteAuditId = "";
  dataEntryRow = "";
  activeCarousel = true;
  auditImages = [];
  image_text = "";
  imageTextView = false;

  page = "";
  manualData = null;

  odometerStartInputValue = "0.00";
  auditData = "";
  loadTime = false;

  imageFile = "";
  loadingForDataEntry = false;

  consumptionByMonthRowData = "";

  manualsRowData = "";

  odometerFiles = "";
  odometerStartImgPath = "";
  odometerEndImgPath = "";

  auditFileRecords = [];
  fileRecord = "";
  loading = false;
  manualsData = ''
  showManualEntryHistory = false;
  closeManualDataTable = false;
  loadingforManualsDatatable = false;
  dataEntryVehicle = false;
  actionStatus = null;

  uploadFileType = null
  imageIndex = ""
  manualRowIndex = ""

  consumptionDataPerUnit = ""
  searchedUnit = ""
  selectedGrouping = ""
  selectedUnit = ""

  constructor() {
    makeObservable(this, {
      loading: observable,
      isLoading: observable,
      newAudit: observable,
      selectedVehicle: observable,
      availableVehicle: observable,
      checkedValues: observable,
      companyId: observable,
      currentYear: observable,
      currentType: observable,
      fuelType: observable,
      currentMonth: observable,
      currentTableData: observable,
      dataEntryData: observable,
      consumedStr: observable,
      captureOdometer: observable,
      fileEndOfMonth: observable,
      fileStartOfMonth: observable,
      isCheck: observable,
      odometerStartStr: observable,
      odometerEndStr: observable,
      totalMileageStr: observable,
      vehicleData: observable,
      vehicleId: observable,
      userId: observable,
      vehicleType: observable,
      rows: observable,
      fileStart: observable,
      fileEnd: observable,
      fileFuel: observable,
      text: observable,
      filefueltype: observable,
      fuelname: observable,
      quantity: observable,
      totalamount: observable,
      pricePerL: observable,
      invoiceNo: observable,
      date: observable,
      typeStatus: observable,
      fuelStatus: observable,
      canvasRef: observable,
      imageRef: observable,
      convertText: observable,
      fuelTypesDict: observable,
      fuelVolumesDict: observable,
      fuelCostsDict: observable,
      pricePerLDict: observable,
      invoiceNoDict: observable,
      evTypesDict: observable,
      evCostsDict: observable,
      evVolumesDict: observable,
      evpricePerkwhDict: observable,
      evinvoiceNoDict: observable,
      confusedformat: observable,
      fileFuelRecords: observable,
      fileStartName: observable,
      fileEndName: observable,
      fileFuelName: observable,
      currentImage: observable,
      currentImageName: observable,
      consumedInfo: observable,
      odometerStartInfo: observable,
      odometerEndInfo: observable,
      tripMilageInfo: observable,
      selectedYearFromNav: observable,
      dataForNavBar: observable,
      checkedVehicles: observable,
      moveVehicles: observable,
      companyVehicles: observable,
      selectedUser: observable,
      selectedCompany: observable,
      imagePreviewNames: observable,
      showAlert: observable,
      istimeout: observable,
      isImgNotFound: observable,
      auditId: observable,
      vehicleIndex: observable,
      manualIndex: observable,
      tableVehicleRow: observable,
      tableManualRow: observable,
      deleteAuditId: observable,
      activeCarousel: observable,
      auditImages: observable,
      image_text: observable,
      imageTextView: observable,
      page: observable,
      manualData: observable,
      odometerStartInputValue: observable,
      auditData: observable,
      loadTime: observable,
      manualsRowData: observable,
      manualRowIndex: observable,

      imageFile: observable,
      loadingForDataEntry: observable,
      consumptionByMonthRowData: observable,
      odometerFiles: observable,
      odometerStartImgPath: observable,
      odometerEndImgPath: observable,

      odometerStartRecord: observable,
      odometerEndRecord: observable,
      fileRecord: observable,
      auditFileRecords: observable,
      manualsData: observable,
      showManualEntryHistory: observable,
      vehicleId: observable,
      manualdataData: observable,
      manuals: observable,
      VData: observable,
      loadingforManualsDatatable: observable,
      dataEntryVehicle: observable,
      dataEntryVehicle: observable,
      actionStatus: observable,

      uploadFileType: observable,
      imageIndex: observable,

      consumptionDataPerUnit: observable,
      searchedUnit: observable,
      selectedGrouping: observable,

      selectedUnit:observable,

      _handleSelectYear: action.bound,
      _handleSelectType: action.bound,
      _handleSelectMonth: action.bound,
      _fetchDataEntry: action.bound,
      _handleValueChange: action.bound,
      _handleChangeCaptureDate: action.bound,
      _handleFileChange: action.bound,
      _handleImageToTextClick: action.bound,

      _handleChangeImgToTextValue: action.bound,
      _handleChangeFuelValue: action.bound,
      _findCommonTextsFuel: action.bound,
      _findCommonTextsEv: action.bound,
      _handleGetVehicleType: action.bound,

      _handleCheckBox: action.bound,
      _handleClearCheckBox: action.bound,
      _handleSubmitEntryData: action.bound,

      _handleSelectVehicle: action.bound,
      _handleViewManual: action.bound,

      _uploadFile: action.bound,
      _handleClearForm: action.bound,
      _handleClearImage: action.bound,
      _handleImageViewClick: action.bound,
      _handleYearChange: action.bound,
      _fetchDataEntryForNav: action.bound,
      _handleVehicleCheck: action.bound,
      _handleMoveVehicle: action.bound,
      _handleRemoveVehicle: action.bound,
      _handleFetchCompanyUnitsForAssign: action.bound,
      _handleAssgningVehicle: action.bound,
      setCheckedValue: action.bound,

      _handleAssignUnitToCompany: action.bound,
      _handleCloseModel: action.bound,
      _FetchUnitsForCompanyAssign: action.bound,
      setCroppedImage: action.bound,
      _handleShowAlert: action.bound,
      _setIstimeoutValue: action.bound,
      handleAddAudit: action.bound,
      _handleCreateAudit: action.bound,
      _handleClearAudit: action.bound,
      _handleDeleteAudit: action.bound,
      setVehicleIndex: action.bound,
      setManualIndex: action.bound,
      setActiveCarousel: action.bound,
      _handleCreateAuditImages: action.bound,
      _handleClearAuditImages: action.bound,
      setImageText: action.bound,
      _handleImageTextView: action.bound,
      _handleImageTextValue: action.bound,
      setPaginationPage: action.bound,
      _handleCreateManualEntryNew: action.bound,
      _handleCreateManualData: action.bound,
      setManualData: action.bound,
      setLoadTime: action.bound,
      setImageFile: action.bound,
      _handleImageText: action.bound,
      _handleGetConsumptionByMonth: action.bound,
      setLoadingForDataEntry: action.bound,
      _handleCreateOdometerFiles: action.bound,
      _handleLoading: action.bound,
      _handleAssignLoading: action.bound,
      _handleGetNewConsumptionByMonth: action.bound,

      _handleURLtoFile: action.bound,
      _handleAudittoFile: action.bound,
      _handleSetManualsData: action.bound,

      setShowManualEntryHistory: action.bound,
      setLoadingforManualsDatatable: action.bound,
      _handleUpdateActionStatus: action.bound,

      _handleGetConsumptionByMonthPerUnit: action.bound,
      _handleSearchVehicles: action.bound,
      _setSelectedUnit: action.bound,
    });
  }


  _handleAudittoFile = () => {
    runInAction(() => {
      this.newAudit.map((v) => {
        this._handleURLtoFile(v.fuel_recordFilePath, v.fileFuelName, "file");
      });
    });
  };

  _handleURLtoFile = async (image, name, type) => {
    const response = await fetch(image);
    // here image is url/location of image
    const blob = await response.blob();
    const file = new File([blob], `${name}`, { type: blob.type });

    if (type === "start") {
      this.odometerStartRecord = file;
    } else if (type === "end") {
      this.odometerEndRecord = file;
    } else {
      this.fileRecord = file;
    }
  };

  setCheckedValue(value, toAssign) {
    if (toAssign === "assignAll") {
      this.checkedValues.assignChecked = value;
    } else if (toAssign === "unassignAll") {
      this.checkedValues.unassignChecked = value;
    } else {
      this.checkedValues.assignChecked = false;
      this.assignChecked.unassignChecked = false;
    }
  }

  _handleAssignLoading = (value) => {
    this.loading = value;
  };

  _handleLoading = (value) => {
    this.isLoading = value;
  };

  _handleCreateOdometerFiles(value) {
    this.odometerFiles = value?.vehicles?.map((v) => {
      let data = {
        id: v?.id,
        fileStart:
          v.manual_data?.startOfMonth_img_path === null ||
            v.manual_data?.startOfMonth_img_path === "null" ||
            v.manual_data?.startOfMonth_img_path === ""
            ? ""
            : baseURL + v.manual_data?.startOfMonth_img_path,
        fileStartRecord:
          this.fileStartOfMonth === null
            ? this.odometerStartRecord
            : this.fileStartOfMonth,
        fileStartName:
          v.manual_data?.startOfMonth_img_name === null ||
            v.manual_data?.startOfMonth_img_name === "null"
            ? ""
            : v.manual_data?.startOfMonth_img_name,
        // this.cookies.get('fileStartName'),
        fileEnd:
          v.manual_data?.endOfMonth_img_path === null ||
            v.manual_data?.endOfMonth_img_path === "null" ||
            v.manual_data?.endOfMonth_img_path === ""
            ? ""
            : baseURL + v.manual_data?.endOfMonth_img_path,
        fileEndRecord:
          this.fileEndOfMonth === null
            ? this.odometerEndRecord
            : this.fileEndOfMonth,
        fileEndName:
          v?.manual_data?.endOfMonth_img_name === null ||
            v?.manual_data?.endOfMonth_img_name === "null"
            ? ""
            : v.manual_data?.endOfMonth_img_name,
        // this.cookies.get('fileEndName')
      };

      return data;
    });
    console.log("OdometerFiles = >", toJS(this.odometerFiles));
  }

  _handleGetConsumptionByMonth() {
    GetConsumptionByMonth(
      this.currentYear,
      this.companyId,
      this.currentType,
      this.currentMonth + 1,
      Common.userId,
      Common.token,
      (err, data) => {
        if (data) {
          // this.currentTableData = data.payload;
          this.setLoadingForDataEntry(true);
          this._handleCreateOdometerFiles(data.payload);
        } else {
          toast.error(data?.message);
        }
      }
    );
  }


  _handleGetConsumptionByMonthV2() {
    runInAction(() => {
      this.showManualEntryHistory = false
    })
    GetConsumptionByMonthV2(
      this.currentYear,
      this.companyId,
      this.currentType,
      this.currentMonth + 1,
      Common.userId,
      Common.token,
      (err, data) => {
        if (data) {
          this.currentTableData = data?.payload;
          this.setLoadingForDataEntry(true);
          this._handleCreateOdometerFiles(data.payload);
          // console.log(toJS(this.currentTableData),'currenttabledataorconcumptionbymonth Neww API?')
        } else {
          toast.error(data?.message);

        }
      }
    );
  }

  _handleGetNewConsumptionByMonth() {
    GetNewConsumptionByMonth(
      this.currentYear,
      this.companyId,
      this.currentType,
      this.currentMonth + 1,
      Common.userId,
      Common.token,
      (err, data) => {
        if (data) {
          this.currentTableData = data.payload;
          this.setLoadingForDataEntry(true);
          this._handleCreateOdometerFiles(data.payload);
        } else {
          toast.error(data?.message);

        }
      }
    );
  }

  _handleUpdateActionStatus(id, status) {
    const data = { id, status };
    FetchActionStatus(Common.token, data, (err, response) => {
      if (response) {
        this.actionStatus = response.payload;
        this.manuals = response.payload;
      } else {
        toast.error(response?.message);
      }
    });
  }



  setLoadingForDataEntry(value) {
    this.loadingForDataEntry = value;
  }

  setLoadingforManualsDatatable(value) {
    this.loadingforManualsDatatable = value;
  }

  _handleShowAlert = (val) => {
    this.showAlert = val;
  };

  _setIstimeoutValue = (val) => {
    this.istimeout = val;
  };

  _handleYearChange = (year) => {
    this.selectedYearFromNav = year;
    this._fetchDataEntryForNav();
  };

  _handleClearImage = (fileType, index) => {
    console.log(
      "vehicleData",
      this.vehicleData.map((v) => v.manual_data)
    );
    if (fileType === "start") {
      this.fileStartOfMonth = null;
      this.fileStart = "";
      this.fileStartName = "";
      this.odometerFiles.find(
        (v) => v.id === this.consumptionByMonthRowData.id
      ).fileStart = "";
      this.odometerFiles.find(
        (v) => v.id === this.consumptionByMonthRowData.id
      ).fileStartName = "";
    } else if (fileType === "end") {
      this.fileEndOfMonth = null;
      this.fileEnd = "";
      this.odometerEndImgPath = "";
      this.fileEndName = "";
      this.odometerFiles.find(
        (v) => v.id === this.consumptionByMonthRowData.id
      ).fileEnd = "";
      this.odometerFiles.find(
        (v) => v.id === this.consumptionByMonthRowData.id
      ).fileEndName = "";
    } else {
      // this.fileFuelRecords = null
      this.newAudit.find((v, i) => i === index).fuel_recordFilePath = "";
      this.newAudit.find((v, i) => i === index).fileFuel = "";
      this.newAudit.find((v, i) => i === index).fileFuelName = "";
      this.newAudit.find((v, i) => i === index).uploadFileType = "";
      this.newAudit.find((v, i) => i === index).quantity = "No data";
      this.newAudit.find((v, i) => i === index).type_status = "N/A";
      this.newAudit.find((v, i) => i === index).fuel_status = "N/A";
      this.newAudit.find((v, i) => i === index).image_text = "";

      this.vehicleData.map((d) =>
        d.manual_data.manuals.find((v, i) => i === index)
      ).fuel_recordFilePath = "";
      this.vehicleData.map((d) =>
        d.manual_data.manuals.find((v, i) => i === index)
      ).fuel_recordFileName = "";
      this.vehicleData[0].fileFuelRecords = "";
      this.vehicleData[0].fuelRecordsFiles = "";
    }
    var formdata = new FormData();

    formdata.append("fileType", fileType);
    formdata.append("editedBy", Common.userId);
    formdata.append("vUid", this.vehicleData[0].manual_data?._id);

    // {
    //   this.vehicleData[0].manuals.find((v, i) => i === index)
    //     ? formdata.append(
    //       "id",
    //       this.vehicleData[0].manuals.find((v, i) => i === index).id
    //     )
    //     : formdata.append("id", 0);
    // }

    if (fileType === "start" || fileType === "end") {
      formdata.append("id", this.vehicleData[0].manual_data?._id);
    } else {
      this.vehicleData[0].manual_data?.manuals.find((v, i) => i === index)
        ? formdata.append(
          "id",
          this.vehicleData[0].manual_data?.manuals.find((v, i) => i === index)
            .id
        )
        : formdata.append("id", 0);
    }

    formdata.append("capturedData", this.captureOdometer);

    if (fileType === "start" || fileType === "end") {
      UploadFileForOther(formdata, Common.token, (err, data) => {
        if (err) {
          toast.error(err.message);
        } else {
          toast.success(data.payload.message);
        }
      });
    } else {
      UploadFile(formdata, Common.token, (err, data) => {
        if (this.newAudit.find((v, i) => i === index).id === 0) {
          this.newAudit.find((v, i) => i === index).id = data.id;
        }
        if (err) {
          toast.error(err.message);
        } else {
          toast.success(data.message, { autoClose: 400 });
        }
      });
    }
  };

  _handleClearForm = () => {
    this.vehicleData = null;
  };

  _handleViewManual = (row) => {
    
    this.manualsRowData = row;

    let value = this.consumptionDataPerUnit?.vehicles;
    

    this.VData = value?.filter((v) => v.id === row.id);

    this.manualdataData = this.VData?.map((v) => v?.manual_data)

    this.manuals = this.manualdataData?.map((v) => v?.manuals)

    // if (this.VData?.length<=0) {
    //   this.showManualEntryHistory = false;
    // }

  }

  _handleSelectVehicle = (row) => {
    this.text = "";
    this.consumptionByMonthRowData = row;

    // let value = this.currentTableData?.vehicles;
    let value = this.consumptionDataPerUnit?.vehicles;

    this.vehicleData = value.filter((v) => v.id === row.id);

    this.auditData = [
      { ...this.vehicleData[0].manual_data, image_record: this.imageFile },
    ];

    this.isCheck = false;
    this.captureOdometer = new Date();

    let month = this.currentMonth + 1;

    let date = this.currentYear + `-` + month + `-` + `01`;
    // value.find(v => v.id === row.id).date ? new Date(value.find(v => v.id === row.id).date) :

    let tripMilage = 0;

    this.fuelType = row.type;
    if (row.fuel_consumed === "No Data") {
      this.consumedStr = "0";
    } else {
      if (
        this.vehicleData[0].manual_data?.manuals.map((v) => v.id) == 0 &&
        this.vehicleData[0].manual_data?.manuals.map((v) => v.consumed).length >
        0
      ) {
        this.consumedStr = row.fuel_consumed.replace(" ascent", "");
        this.consumedInfo = "(Ascent Data)";
      } else {
        this.consumedStr = row.fuel_consumed.replace(" manual", "");
        this.consumedInfo = "(Manual Data)";
      }
    }

    if (row.trip_mileage === "No Data") {
      this.totalMileageStr = "0";
      this.tripMilageInfo = "";
    } else {
      if (row.trip_mileage?.includes("ascent")) {
        this.totalMileageStr = row.trip_mileage.replace(" ascent", "");
        this.tripMilageInfo = "(Ascent Data)";
      } else {
        this.totalMileageStr = row.trip_mileage.replace(" manual", "");
        this.tripMilageInfo = "(Manual Data)";
      }
    }

    tripMilage = parseFloat(this.totalMileageStr);
    if (tripMilage === 0) {
      this.odometerStartStr =
        row.odometer_start === "No Data" ? "0" : row.odometer_start;
      this.odometerStartInputValue =
        row.odometer_start === "No Data" ? "0" : row.odometer_start;
      this.odometerEndStr = row.odometer === "No Data" ? "0" : row.odometer;
      this.odometerStartInfo = "";
      this.odometerEndInfo = "";
    } else if (row.odometer_start === "No Data") {
      this.odometerStartStr = "0";
      this.odometerStartInputValue = "0";
      this.odometerEndStr = row.odometer;
      this.odometerStartInfo = "(Calculated Data from Ascent Data)";
      this.odometerEndInfo = "(Calculated Data from Ascent Data)";
      this.tripMilageInfo = "(Ascent Data)";
    } else {
      this.odometerStartStr = row.odometer_start;
      this.odometerStartInputValue = row.odometer_start;
      this.odometerEndStr = row.odometer;
      this.odometerStartInfo = "(Calculated Data from Ascent Data)";
      this.odometerEndInfo = "(Calculated Data from Ascent Data)";

      let end = parseFloat(this.odometerEndStr);
      let start = parseFloat(
        this.odometerStartStr == 0 || this.odometerEndStr === null
          ? this.odometerStartInputValue
          : this.odometerStartStr
      );
      this.totalMileageStr = end - start;
      this.tripMilageInfo = "(Ascent Data)";
    }

    // this.fileStart =
    //   this.odometerStartImgPath === "" &&
    //     this.vehicleData[0].manual_data.startOfMonth_img_path == "null"
    //     ? ""
    //     : this.vehicleData[0].manual_data.startOfMonth_img_path &&
    //       this.vehicleData[0].manual_data.startOfMonth_img_path !== "null"
    //       ? baseIMGURL + this.vehicleData[0].manual_data.startOfMonth_img_path
    //       : baseIMGURL + this.odometerStartImgPath;
    // this.fileEnd =
    //   this.odometerEndImgPath === "" &&
    //     this.vehicleData[0].manual_data.endOfMonth_img_path == "null"
    //     ? ""
    //     : this.vehicleData[0].manual_data.endOfMonth_img_path &&
    //       this.vehicleData[0].manual_data.endOfMonth_img_path !== "null"
    //       ? baseIMGURL + this.vehicleData[0].manual_data.endOfMonth_img_path
    //       : baseIMGURL + this.odometerEndImgPath;
    // this.fileFuel =
    //   value.fuelRecordsFiles && value.fuelRecordsFiles !== "null"
    //     ? baseIMGURL + value.fuelRecordsFiles
    //     : "";

    // this.fileStartName =
    //   this.vehicleData[0].manual_data?.startOfMonth_img_name &&
    //     this.vehicleData[0].manual_data?.startOfMonth_img_name !== "null"
    //     ? this.vehicleData[0].manual_data?.startOfMonth_img_name
    //     : "";
    // this.fileEndName =
    //   this.vehicleData[0].manual_data.endOfMonth_img_name &&
    //     this.vehicleData[0].manual_data.endOfMonth_img_name !== "null"
    //     ? this.vehicleData[0].manual_data.endOfMonth_img_name
    //     : "";
    // this.fileFuelName =
    //   value.fuelFilename && value.fuelFilename !== "null"
    //     ? value.fuelFilename
    //     : "";

    // this._handleCreateAuditImages()
    if (!this.vehicleData[0].manual_data) {
      this._handleCreateManualEntryNew(row.id, date);
    }
  };

  _handleImageViewClick = (name, imageUrl, title, fileType, index) => {
    this.currentImage = imageUrl;
    this.imageIndex = index

    this.currentImageName = name;
    this.currentTitle = title;

    this.uploadFileType = fileType
  };

  _handleCroppedImage = () => { };

  _handleCheckBox = () => {
    this.isCheck = !this.isCheck;
  };

  _handleClearCheckBox = () => {
    this.isCheck = false
  }

  _handleFileChange = (value, fileType, index) => {
    if (fileType === "start") {
      this.fileStartOfMonth = value.target?.files[0];
      this.fileStart = value.target.files[0]
        ? URL.createObjectURL(value.target?.files[0])
        : "";
      this.odometerStartOfMonthFile = this.fileStart;
      this.fileStartName = this.fileStartOfMonth?.name;
      this.cookies.set("fileStartName", this.fileStartName);

      this.odometerFiles.find(
        (v) => v.id === this.consumptionByMonthRowData.id
      ).fileStart = URL.createObjectURL(value.target?.files[0]);
      this.odometerFiles.find(
        (v) => v.id === this.consumptionByMonthRowData.id
      ).fileStartName = this.fileStartName;

      this.odometerStartRecord = this.fileStartOfMonth;
      this.cookies.set("file", this.fileStart);
    } else if (fileType === "end") {
      this.fileEndOfMonth = value.target?.files[0];
      this.fileEnd = value.target.files[0]
        ? URL.createObjectURL(value.target?.files[0])
        : "";
      this.fileEndName = this.fileEndOfMonth?.name;
      this.cookies.set("fileEndName", this.fileEndName);

      this.odometerFiles.find(
        (v) => v.id === this.consumptionByMonthRowData.id
      ).fileEnd = URL.createObjectURL(value.target?.files[0]);
      this.odometerFiles.find(
        (v) => v.id === this.consumptionByMonthRowData.id
      ).fileEndName = this.fileEndName;

      this.odometerEndRecord = this.fileEndOfMonth;
      // this._uploadFile(value.target.files[0], fileType, index);
    } else {
      // this._uploadFile(value.ta_handleFileChangerget.files[0], fileType, index);
      this.newAudit.find((v, i) => i === index).fileFuelRecords =
        value.target?.files[0];

      this.newAudit.find((v, i) => i === index).fileFuel = value.target.files[0]
        ? URL.createObjectURL(value.target?.files[0])
        : "";
      this.setImageFile(this.newAudit.find((v, i) => i === index).fileFuel);
      this.newAudit.find((v, i) => i === index).fileFuelName =
        this.newAudit.find((v, i) => i === index).fileFuelRecords?.name;

      this.vehicleData[0].manual_data.manuals.find(
        (v, i) => i === index
      ).fuel_recordFileName = value.target.files[0].name;
      this.auditData[0].image_record = this.newAudit.find(
        (v, i) => i === index
      ).fileFuel;
      this.newAudit.find((v, i) => i === index).uploadFileType =
        value.target?.files[0].type;
    }

    // this._uploadFile(value.target.files[0], fileType, index);
    this._handleCreateAuditImages();
  };

  // _handleFileChange = (value, fileType) => {
  //     if (fileType === "start") {
  //         this.fileStartOfMonth = value.target?.files[0];
  //         this.fileStart = value.target.files[0]
  //             ? URL.createObjectURL(value.target?.files[0])
  //             : "";
  //         this.fileStartName = this.fileStartOfMonth?.name;
  //     } else if (fileType === "end") {
  //         this.fileEndOfMonth = value.target?.files[0];
  //         this.fileEnd = value.target.files[0]
  //             ? URL.createObjectURL(value.target?.files[0])
  //             : "";
  //         this.fileEndName = this.fileEndOfMonth?.name;
  //     } else {
  //         this.fileFuelRecords = value.target?.files[0];
  //         this.fileFuel = value.target.files[0]
  //             ? URL.createObjectURL(v_handleImageToTextalue.target?.files[0])
  //             : "";
  //         this.fileFuelName = this.fileFuelRecords?.name;
  //     }

  //     this._uploadFile(value.target.files[0], fileType);
  // };

  _handleImageToTextClick = (index, id) => {
    console.log(
      "OCR TEST",
      !this.newAudit.find((v, i) => i === index).fileFuel
        ? baseIMGURL +
        this.newAudit.find((v, i) => i === index).fuel_recordFilePath
        : this.newAudit.find((v, i) => i === index).fileFuel
    );

    this.auditId = id;
    this.manualRowIndex = index
    console.log("auditId STORE ===>>>", this.auditId)
    this._setIstimeoutValue(false);
    this._handleChangeImgToTextValue("");
    this._handleChangeFuelValue("", "", "", "", "", "", "");
    this.convertText = "convert";
    this.isImgNotFound = false;
    var keys;
    let text = "";

    if (
      !this.newAudit.find((v, i) => i === index).fileFuel &&
      !this.newAudit.find((v, i) => i === index).fuel_recordFilePath
    ) {
      Swal.fire({
        icon: "error",
        text: "No image is uploaded yet",
        showConfirmButton: true,
      }).then((result) => { });

      return;
    }

    console.log(
      "OCR TEST",
      !this.newAudit.find((v, i) => i === index).fileFuel
        ? baseIMGURL +
        this.newAudit.find((v, i) => i === index).fuel_recordFilePath
        : this.newAudit.find((v, i) => i === index).fileFuel
    );

    let pdfUrl = this.newAudit.find((v, i) => i === index).fileFuel
      ? this.newAudit.find((v, i) => i === index).fileFuel
      : baseIMGURL +
      this.newAudit.find((v, i) => i === index).fuel_recordFilePath;
    if (!this.newAudit.find((v, i) => i === index).fileFuel) {
      var http = new XMLHttpRequest();
      http.open("HEAD", pdfUrl, false);
      http.send();

      if (http.status === 404) {
        this.isImgNotFound = true;
        Swal.fire({
          icon: "error",
          text: "Image is not found in server",
          showConfirmButton: true,
        }).then((result) => { });

        return;
      }
    }

    // Add the resizing logic

    // const canvas = this.canvasRef.current;
    // const ctx = canvas.getContext('2d');

    // ctx.drawImage(this.imageRef.current, 0, 0);
    // //ctx.putImageData(preprocessImage(canvas),0,0);
    // const dataUrl = canvas.toDataURL("image/jpeg");
    (async () => {
      const worker = await createWorker();
      const timer = setTimeout(() => {
        this._setIstimeoutValue(true);
        worker?.terminate();
      }, 10000);
      if (
        this.newAudit.find((v, i) => i === index).uploadFileType ===
        "application/pdf"
      ) {
        const loadingTask = pdfjsLib.getDocument(pdfUrl);
        const pdf = await loadingTask.promise;

        const pageNumber = 1; //Specify the page number you want to convert
        const page = await pdf.getPage(pageNumber);
        const textContent = await page.getTextContent();
        let extractedText = "";
        let extractedLines = "";
        const textPromises = [];
        textContent.items.forEach((textItem, i) => {
          if (textItem.str === "") {
            extractedLines += "\n";
            textPromises.push({ text: extractedText });
            extractedText = "";
          } else {
            extractedLines += textItem.str;
            extractedText += textItem.str;
            if (i === textContent.items.length - 1) {
              textPromises.push({ text: extractedText });
            }
          }
        });
        keys = this._extractKeys(textPromises, extractedLines);
        if (!this.istimeout) {
          clearTimeout(timer);
          this._handleShowAlert(true);
        }
        text = keys.text;
      } else {
        await worker.loadLanguage("eng");
        await worker.initialize("eng");

        await worker.setParameters({
          user_defined_dpi: "300", // Set the desired DPI value here
        });
        await worker
          .recognize(
            pdfUrl,
            "eng",
            //dataUrl, 'eng',
            {
              //logger: m => console.log(m)
            }
          )
          .catch((err) => {
            console.log("error", err);
          })
          .then((result) => {
            // Get Confidence score
            if (!this.istimeout) {
              clearTimeout(timer);
              this._handleShowAlert(true);
              console.log("result", result);

              if (result.data.confidence > 20) {
                keys = this._extractKeys(result, "");

                text = keys.text;
              } else {
                text =
                  "Image quality is not good. Try another. Confidence level is " +
                  result.data.confidence;
              }
            }
          });
      }

      // this.newAudit.find((v, i) => i === index).fileFuel=imageDataUrl;
      this._handleChangeImgToTextValue(text);
      if (this.currentType === "fuel") {
        this._handleChangeFuelValue(
          keys.filefueltype,
          keys.fuelname,
          keys.quantity,
          keys.amount,
          keys.pricePerL,
          keys.invoiceNo,
          keys.date
        );
        this._handleImageText(index);
      } else {
        this._handleChangeFuelValue(
          keys.evfilefueltype,
          keys.evfuelname,
          keys.evquantity,
          keys.evamount,
          keys.evpricePerkwh,
          keys.evinvoiceNo,
          keys.evdate
        );
        this._handleImageText(index);
      }
    })();
  };

  _extractKeys = (result, resultText) => {
    let text = "";

    let filefueltype = "";
    let fuelname = "Fuel name : not found";
    let quantity = "Quantity : not found";
    let amount = "Amount : not found";
    let pricePerL = "Price per liter : not found";
    let invoiceNo = "Invoice no. : not found";
    let date = "Date : not found";

    let evfilefueltype = "";
    let evfuelname = "EV name : not found";
    let evquantity = "Quantity : not found";
    let evamount = "Amount : not found";
    let evpricePerkwh = "Price per kWh : not found";
    let evinvoiceNo = "Invoice no. : not found";
    let evdate = "Date : not found";
    if (this.currentType === "fuel") {
      let dictfilter = [
        ...this._findCommonTextsFuel(
          toJS(this.fuelTypesDict),
          toJS(this.fuelVolumesDict),
          toJS(this.fuelCostsDict),
          toJS(this.pricePerLDict),
          toJS(this.invoiceNoDict),
          toJS(this.dateDict),
          result.data ? result.data.lines : result
        ),
      ];
      dictfilter = dictfilter.flat(10);
      dictfilter = [...new Set(dictfilter)];
      dictfilter.forEach((x) => {
        if (x) {
          if (x.dictTxtType) {
            filefueltype = x.dictTxtType;
            // fuelname = x.extractName.replace("\n", "");
            fuelname = "Fuel name : " + x.dictTxtName;
          } else if (x.extractQuantity) {
            quantity = x.extractQuantity.toLowerCase().includes(x.dictTxtUnit)
              ? x.extractQuantity.replace("\n", "")
              : (x.extractQuantity + x.dictTxtUnit).replace("\n", "");
          } else if (x.extractAmount) {
            amount = x.extractAmount.replace("\n", "");
          } else if (x.extractPricePerL) {
            pricePerL = x.extractPricePerL.replace("\n", "");
          } else if (x.extractInvoiceNo) {
            invoiceNo = x.extractInvoiceNo.replace("\n", "");
          } else if (x.extractDate) {
            date = x.extractDate.replace("\n", "");
          }
        }
      });

      text = result.data
        ? "Confidence level is " +
        result.data.confidence +
        "\n" +
        result.data.text
        : resultText;
    } else {
      let dictfilter = [
        ...this._findCommonTextsEv(
          toJS(this.evTypesDict),
          toJS(this.evVolumesDict),
          toJS(this.evCostsDict),
          toJS(this.evpricePerkwhDict),
          toJS(this.evinvoiceNoDict),
          toJS(this.evDateDict),
          result.data ? result.data.lines : result
        ),
      ];
      dictfilter = dictfilter.flat(10);
      dictfilter = [...new Set(dictfilter)];

      dictfilter.forEach((x) => {
        if (x) {
          if (x.dictTxtType) {
            evfilefueltype = x.dictTxtType;
            // evfuelname = x.extractName.replace("\n", "");
            evfuelname = "EV name : " + x.dictTxtName;
          } else if (x.extractQuantity) {
            evquantity = x.extractQuantity.toLowerCase().includes(x.dictTxtUnit)
              ? x.extractQuantity.replace("\n", "")
              : (x.extractQuantity + x.dictTxtUnit).replace("\n", "");
          } else if (x.extractAmount) {
            evamount = x.extractAmount.replace("\n", "");
          } else if (x.extractPricePerkwh) {
            evpricePerkwh = x.extractPricePerkwh.replace("\n", "");
          } else if (x.extractInvoiceNo) {
            evinvoiceNo = x.extractInvoiceNo.replace("\n", "");
          } else if (x.extractDate) {
            evdate = x.extractDate.replace("\n", "");
          }
        }
      });

      text = result.data
        ? "Confidence level is " +
        result.data.confidence +
        "\n" +
        result.data.text
        : resultText;
    }
    return {
      text,
      filefueltype,
      fuelname,
      quantity,
      amount,
      pricePerL,
      invoiceNo,
      date,
      evfilefueltype,
      evfuelname,
      evquantity,
      evamount,
      evpricePerkwh,
      evinvoiceNo,
      evdate,
    };
  };

  _handleImageText(index) {
    var matchedQuantity =
      this.newAudit[index]?.quantity?.match(/\d+\.\d+|\d+ltr+/g) ||
      this.newAudit[index]?.quantity?.match(/\d+\.\d+|\d+kwh+/g);

    if (
      this.newAudit[index].filefueltype.toLowerCase() !==
      this.fuelType.toLowerCase()
    ) {
      this.setImageText(
        `Fuel type of uploaded file does not match with expected (Expected ${this.fuelType})`,
        index
      );
      console.log("does not match");
    } else if (
      matchedQuantity &&
      matchedQuantity[0] !== this.newAudit[index]?.consumedStr
    ) {
      this.setImageText(
        `Data does not match (Manual Entry: ${this.newAudit[index].consumedStr}, File OCR: ${matchedQuantity[0]})`,
        index
      );
    } else if (
      this.newAudit[index].filefueltype.toLowerCase() ===
      this.fuelType.toLowerCase() &&
      matchedQuantity &&
      matchedQuantity[0] === this.newAudit[index].consumedStr
    ) {
      this.setImageText("Audited with OCR", index);
    } else {
      return null;
    }
  }

  _findCommonTextsFuel = (arr1, arr2, arr3, arr4, arr5, arr6, lines) => {
    let result1 = [];
    let result2 = [];
    let result3 = [];
    let result4 = [];
    let result5 = [];
    let result6 = [];

    return lines.map((extractTxt) => {
      arr1.forEach((dictTxt) => {
        let inclu = false;
        let confused = false;

        this.confusedformat.forEach((confusedTxt) => {
          confused =
            confused ||
            extractTxt.text
              .toLowerCase()
              .includes(confusedTxt.amount.toLowerCase());
        });
        inclu =
          extractTxt.text
            .toLowerCase()
            .includes(dictTxt.oriTxt.toLowerCase()) && !confused;
        if (inclu)
          result1.push({
            extractName: extractTxt.text,
            dictTxtName: dictTxt.name,
            dictTxtType: dictTxt.filefueltype,
          });
      });

      arr2.forEach((dictTxt) => {
        const inclu = extractTxt.text
          .toLowerCase()
          .includes(dictTxt.quantity.toLowerCase());
        if (inclu)
          result2.push({
            extractQuantity: extractTxt.text,
            dictTxtQuantity: dictTxt.quantity,
            dictTxtUnit: dictTxt.unit,
          });
      });

      arr3.forEach((dictTxt) => {
        let inclu = false;
        let confused = false;

        this.confusedformat.forEach((confusedTxt) => {
          confused =
            confused ||
            extractTxt.text
              .toLowerCase()
              .includes(confusedTxt.amount.toLowerCase());
        });

        inclu =
          extractTxt.text
            .toLowerCase()
            .includes(dictTxt.amount.toLowerCase()) && !confused;
        if (inclu)
          result3.push({
            extractAmount: extractTxt.text,
            dictTxtAmount: dictTxt.amount,
          });
      });

      arr4.forEach((dictTxt) => {
        let inclu = false;
        let confused = false;

        this.confusedformat.forEach((confusedTxt) => {
          confused =
            confused ||
            extractTxt.text
              .toLowerCase()
              .includes(confusedTxt.amount.toLowerCase());
        });
        inclu =
          extractTxt.text.toLowerCase().includes(dictTxt.text.toLowerCase()) &&
          extractTxt.text
            .toLowerCase()
            .includes(dictTxt.format.toLowerCase()) &&
          !confused;
        if (inclu)
          result4.push({
            extractPricePerL: extractTxt.text,
            dictTxtPricePerL: dictTxt.text,
          });
      });

      arr5.forEach((dictTxt) => {
        const inclu = extractTxt.text
          .toLowerCase()
          .includes(dictTxt.text.toLowerCase());
        if (inclu)
          result5.push({
            extractInvoiceNo: extractTxt.text,
            dictTxtInvoiceNo: dictTxt.text,
          });
      });

      arr6.forEach((dictTxt) => {
        let inclu = false;
        let confused = false;

        this.confusedformat.forEach((confusedTxt) => {
          confused =
            confused ||
            extractTxt.text
              .toLowerCase()
              .includes(confusedTxt.amount.toLowerCase());
        });
        inclu =
          extractTxt.text.toLowerCase().includes(dictTxt.text.toLowerCase()) &&
          !confused;
        if (inclu)
          result6.push({
            extractDate: extractTxt.text,
            dictTxtDate: dictTxt.text,
          });
      });

      if (
        result6.length === 0 &&
        extractTxt.text.match(
          "[0-9]{2}[-/ .][0-9]{2}[-/ .][0-9]{4}|[0-9]{4}[-/ .][0-9]{2}[-/ .][0-9]{2}" +
          "|[0-9]{2}[-/ .][A-Z]{3}[-/ .][0-9]{4}|[0-9]{4}[-/ .][A-Z]{3}[-/ .][0-9]{2}"
        )
      ) {
        result6.push({ extractDate: extractTxt.text });
      }

      // return arr4.map((extractTxt, index) => {

      // let dictfilter1 = arr1.map(dictTxt => {
      //     const inclu = extractTxt.text.includes(dictTxt.name)
      //     return { ...dictTxt, index: inclu ? index : -1 }
      // })

      // let dictfilter2 = arr2.map(dictTxt => {
      //     const inclu = extractTxt.text.includes(dictTxt.unit)
      //     return { ...dictTxt, index: inclu ? index : -1 }
      // })

      // let dictfilter3 = arr3.map(dictTxt => {
      //     const inclu = extractTxt.text.includes(dictTxt.amount)
      //     return { ...dictTxt, index: inclu ? index : -1 }
      // })
      // }

      // dictfilter1 = dictfilter1.filter(f => f.index > -1)
      // dictfilter2 = dictfilter2.filter(f => f.index > -1)
      // dictfilter3 = dictfilter3.filter(f => f.index > -1)

      // if (dictfilter1.length) result1.push(dictfilter1)
      // if (dictfilter2.length) result2.push(dictfilter2)
      // if (dictfilter3.length) result3.push(dictfilter3)

      return [result1, result2, result3, result4, result5, result6];
    });
  };

  _findCommonTextsEv = (arr1, arr2, arr3, arr4, arr5, arr6, lines) => {
    let result1 = [];
    let result2 = [];
    let result3 = [];
    let result4 = [];
    let result5 = [];
    let result6 = [];

    return lines.map((extractTxt) => {
      arr1.forEach((dictTxt) => {
        const inclu = extractTxt.text
          .toLowerCase()
          .includes(dictTxt.oriTxt.toLowerCase());
        if (inclu)
          result1.push({
            extractName: extractTxt.text,
            dictTxtName: dictTxt.name,
            dictTxtType: dictTxt.filefueltype,
          });
      });

      arr2.forEach((dictTxt) => {
        const inclu = extractTxt.text
          .toLowerCase()
          .includes(dictTxt.quantity.toLowerCase());
        if (inclu)
          result2.push({
            extractQuantity: extractTxt.text,
            dictTxtQuantity: dictTxt.quantity,
            dictTxtUnit: dictTxt.unit,
          });
      });

      arr3.forEach((dictTxt) => {
        let inclu = false;
        let confused = false;

        this.evConfusedformat.forEach((confusedTxt) => {
          confused =
            confused ||
            extractTxt.text
              .toLowerCase()
              .includes(confusedTxt.amount.toLowerCase());
        });
        inclu =
          extractTxt.text
            .toLowerCase()
            .includes(dictTxt.amount.toLowerCase()) && !confused;
        if (inclu)
          result3.push({
            extractAmount: extractTxt.text,
            dictTxtAmount: dictTxt.amount,
          });
      });

      arr4.forEach((dictTxt) => {
        const inclu = extractTxt.text
          .toLowerCase()
          .includes(dictTxt.text.toLowerCase());
        if (inclu)
          result4.push({
            extractPricePerkwh: extractTxt.text,
            dictTxtPricePerkwh: dictTxt.text,
          });
      });

      arr5.forEach((dictTxt) => {
        const inclu = extractTxt.text
          .toLowerCase()
          .includes(dictTxt.text.toLowerCase());
        if (inclu)
          result5.push({
            extractInvoiceNo: extractTxt.text,
            dictTxtInvoiceNo: dictTxt.text,
          });
      });

      arr6.forEach((dictTxt) => {
        let inclu = false;
        let confused = false;

        this.evConfusedformat.forEach((confusedTxt) => {
          confused =
            confused ||
            extractTxt.text
              .toLowerCase()
              .includes(confusedTxt.amount.toLowerCase());
        });
        inclu =
          extractTxt.text.toLowerCase().includes(dictTxt.text.toLowerCase()) &&
          !confused;
        if (inclu)
          result6.push({
            extractDate: extractTxt.text,
            dictTxtDate: dictTxt.text,
          });
      });

      return [result1, result2, result3, result4, result5, result6];
    });
  };

  // _handleChangeFuelValue = (
  //   filefueltype,
  //   fuelname,
  //   quantity,
  //   totalamount,
  //   pricePerL,
  //   invoiceNo,
  //   date
  // ) => {
  //   this.newAudit.find((v) => v.id === this.auditId).filefueltype =
  //     filefueltype;
  //   this.newAudit.find((v) => v.id === this.auditId).fuelname = fuelname;
  //   quantity =
  //     quantity.indexOf(",") !== -1
  //       ? quantity.charAt(quantity.indexOf(",") + 4).match(/\d/g)
  //         ? quantity.replace(",", ".")
  //         : quantity.replace(",", "")
  //       : quantity;
  //   this.newAudit.find((v) => v.id === this.auditId).quantity = quantity;
  //   this.newAudit.find((v) => v.id === this.auditId).totalamount = totalamount;
  //   this.newAudit.find((v) => v.id === this.auditId).pricePerL = pricePerL;
  //   this.newAudit.find((v) => v.id === this.auditId).invoiceNo = invoiceNo;
  //   this.newAudit.find((v) => v.id === this.auditId).date = date;

  //   var matchedQuantity =
  //     this.newAudit
  //       .find((v) => v.id === this.auditId)
  //       .quantity?.match(/\d+\.\d+|\d+ltr+/g) ||
  //     this.newAudit
  //       .find((v) => v.id === this.auditId)
  //       .quantity?.match(/\d+\.\d+|\d+kwh+/g);
  //   this.typeStatus =
  //     this.fuelType.toLowerCase() ===
  //       this.newAudit
  //         .find((v) => v.id === this.auditId)
  //         .filefueltype.toLowerCase()
  //       ? "Accepted"
  //       : "Rejected";
  //   this.fuelStatus =
  //     matchedQuantity &&
  //       matchedQuantity[0] ===
  //       this.newAudit.find((v) => v.id === this.auditId).consumedStr
  //       ? "Accepted"
  //       : "Rejected";
  //   this.newAudit.find((v) => v.id === this.auditId).fuel_status =
  //     this.fuelStatus;
  //   this.newAudit.find((v) => v.id === this.auditId).type_status =
  //     this.typeStatus;

  //   this.filefueltype = filefueltype;
  //   this.fuelname = fuelname;
  //   this.quantity = quantity;
  //   this.totalamount = totalamount;
  //   this.pricePerL = pricePerL;
  //   this.invoiceNo = invoiceNo;
  //   this.date = date;
  // };

  _handleChangeFuelValue = (
    filefueltype,
    fuelname,
    quantity,
    totalamount,
    pricePerL,
    invoiceNo,
    date
  ) => {
    this.newAudit.find((v,i) => i === this.manualRowIndex).filefueltype =
      filefueltype;
    this.newAudit.find((v,i) => i === this.manualRowIndex).fuelname = fuelname;
    quantity =
      quantity.indexOf(",") !== -1
        ? quantity.charAt(quantity.indexOf(",") + 4).match(/\d/g)
          ? quantity.replace(",", ".")
          : quantity.replace(",", "")
        : quantity;
    this.newAudit.find((v,i) => i === this.manualRowIndex).quantity = quantity;
    this.newAudit.find((v,i) => i === this.manualRowIndex).totalamount = totalamount;
    this.newAudit.find((v,i) => i === this.manualRowIndex).pricePerL = pricePerL;
    this.newAudit.find((v,i) => i === this.manualRowIndex).invoiceNo = invoiceNo;
    this.newAudit.find((v,i) => i === this.manualRowIndex).date = date;

    var matchedQuantity =
      this.newAudit
        .find((v,i) => i === this.manualRowIndex)
        .quantity?.match(/\d+\.\d+|\d+ltr+/g) ||
      this.newAudit
        .find((v,i) => i === this.manualRowIndex)
        .quantity?.match(/\d+\.\d+|\d+kwh+/g);
    this.typeStatus =
      this.fuelType.toLowerCase() ===
        this.newAudit
          .find((v,i) => i === this.manualRowIndex)
          .filefueltype.toLowerCase()
        ? "Accepted"
        : "Rejected";
    this.fuelStatus =
      matchedQuantity &&
        matchedQuantity[0] ===
        this.newAudit.find((v,i) => i === this.manualRowIndex).consumedStr
        ? "Accepted"
        : "Rejected";
    this.newAudit.find((v,i) => i === this.manualRowIndex).fuel_status =
      this.fuelStatus;
    this.newAudit.find((v,i) => i === this.manualRowIndex).type_status =
      this.typeStatus;

    this.filefueltype = filefueltype;
    this.fuelname = fuelname;
    this.quantity = quantity;
    this.totalamount = totalamount;
    this.pricePerL = pricePerL;
    this.invoiceNo = invoiceNo;
    this.date = date;
  };

  _handleChangeImgToTextValue = (text) => {
    this.text = text;
  };

  _handleChangeCaptureDate = (value) => {
    this.captureOdometer = value;
  };

  _handleSelectYear = (value) => {
    this.currentYear = value;
    this._fetchDataEntry();
  };

  _handleSelectType = (v) => {
    this.vehicleType = v;
    this.currentType = v.value;
    this.dataEntryVehicle = false
    this._fetchDataEntry();
  };

  _handleSelectMonth = (value, index) => {
    this._setIstimeoutValue(false);
    this.currentMonth = index;
    // this.currentTableData = value;
    this.vehicleData = null;
    this.setLoadingForDataEntry(false);
    // this._handleGetConsumptionByMonth();
    // this._handleGetConsumptionByMonthV2();
    this._handleGetNewConsumptionByMonth()
    this.setShowManualEntryHistory(false)
    
    // const table = $('#dataEntryTable').DataTable();
    // table.page(0).draw('page');
    localStorage.removeItem('search');
    this.consumptionDataPerUnit = null
    this.selectedGrouping = ""
    this._setSelectedUnit("")
  };

  // _handleValueChange = (value, key) => {
  //     if (key === "consumed") {
  //         this.consumedStr = value.target.value;
  //     } else if (key === "odometerStart") {
  //         this.odometerStartStr = value.target.value;
  //     } else if (key === "odometerEnd") {
  //         this.odometerEndStr = value.target.value;
  //     } else if (key === "mileage") {
  //         this.totalMileageStr = value.target.value;
  //     }

  //     let start = parseInt(
  //         this.odometerStartStr === "" ? "0" : this.odometerStartStr
  //     );
  //     let end = parseInt(this.odometerEndStr === "" ? "0" : this.odometerEndStr);
  //     let mileage = end - start;
  //     this.totalMileageStr = mileage;
  // };

  _handleValueChange = (value, key, index) => {
    if (key === "consumed") {
      this.newAudit.find((v, i) => i === index).consumedStr =
        value.target.value;
    } else if (key === "odometerStart") {
      // this.odometerStartStr = value.target.value;
      this.odometerStartInputValue = value.target.value;
    } else if (key === "odometerEnd") {
      this.odometerEndStr = value.target.value;
    } else if (key === "mileage") {
      this.totalMileageStr = value.target.value;
    }

    let start = parseFloat(
      this.odometerStartStr == 0 || this.odometerEndStr === null
        ? this.odometerStartInputValue
        : this.odometerStartStr
    );

    let end = parseInt(this.odometerEndStr === "" ? "0" : this.odometerEndStr);
    let mileage = end - start;
    this.totalMileageStr = mileage;

    var matchedQuantity =
      this.newAudit
        .find((v, i) => i === index)
        .quantity?.match(/\d+\.\d+|\d+ltr+/g) ||
      this.newAudit
        .find((v, i) => i === index)
        .quantity?.match(/\d+\.\d+|\d+kwh+/g);
    this.fuelStatus =
      matchedQuantity &&
        matchedQuantity[0] ===
        this.newAudit.find((v, i) => i === index)?.consumedStr
        ? "Accepted"
        : "Rejected";
    this.newAudit.find((v, i) => i === index).fuel_status =
      this.fuelStatus;
  };

  _fetchDataEntryForNav = () => {
    // if (this.companyId === "88883333") {
    //     FetchEntryData(
    //         this.selectedYearFromNav,
    //         "all",
    //         Common.token,
    //         (err, data) => {
    //             if (data) {
    //                 this.dataForNavBar = data;
    //             } else {
    //             }
    //         }
    //     );
    // } else {
    //     FetchEntryDataOther(
    //         this.selectedYearFromNav,
    //         "all",
    //         this.companyId,
    //         Common.token,
    //         (err, data) => {
    //             if (data) {
    //                 this.dataForNavBar = data.consumptionsByMonth;
    //             } else {
    //             }
    //         }
    //     );
    // }
  };

  _fetchDataEntry = () => {
    this.currentTableData = null;
    this._setIstimeoutValue(false);

    // FetchEntryData(
    //     this.currentYear,
    //     this.currentType,
    //     Common.token,
    //     this.companyId,
    //     (err, data) => {

    //         if (data) {
    //             if (data.message === "Token Expired!") {
    //                 window.location.pathname = "/";
    //                 toast.info(data.message);
    //             } else this.dataEntryData = data;
    //         } else {
    //         }
    //     }
    // );

    // let page = 2

    // FetchManualEntry(
    //   this.currentYear,
    //   this.currentType,
    //   Common.token,
    //   this.companyId,
    //   (err, data) => {
    //     if (data) {
    //       if (data.message === "Token Expired!") {
    //         window.location.pathname = "/";
    //         toast.info(data.message);
    //       } else this.dataEntryData = data;
    //     } else {
    //     }
    //   }
    // );

    GetUnitMonthByMonth(
      this.currentYear,
      this.companyId,
      this.currentType,
      Common.token,
      (err, data) => {
        if (data) {
        
          if (data.message === "Token Expired!") {
            window.location.pathname = "/";
            toast.info(data.message);
          } else {
            if (data.error) {
              this.currentTableData = null
              this.dataEntryVehicle = true
              toast.error(data.error);
            } else {
              this.dataEntryData = data;
            }
          }
          
        }
      }
    );
  };

  // _fetchDataEntry = async () => {
  //     this.currentTableData = null;
  //     this._setIstimeoutValue(false);
  //     let timer
  //     try {
  //       const dataPromise = FetchEntryData(
  //         this.currentYear,_handleCreateManualEntryNew
  //         this.currentType,
  //         Common.token,
  //         this.companyId
  //       );
  //       const timeoutPromise = new Promise((resolve, reject) => {
  //         const timer = setTimeout(() => {
  //           clearTimeout(timer);
  //           reject(new Error('Request timed out'));
  //         }, 10000);
  //       });

  //       const data = await Promise.race([dataPromise, timeoutPromise]);
  //       clearTimeout(timer);
  //       this._setIstimeoutValue(false);

  //       if (data && data.message === 'Token Expired!') {
  //         window.location.pathname = '/';
  //         toast.info(data.message);
  //       } else {
  //         this.dataEntryData = data;
  //       }

  //       return data;
  //     } catch (error) {
  //       clearTimeout(timer);
  //       this._setIstimeoutValue(false);
  //       console.error(error);
  //       throw error;
  //     }
  //   };

  _uploadFile = (file, fileType, index) => {
    var formdata = new FormData();
    formdata.append("file", file);
    formdata.append("fileType", fileType);
    formdata.append("editedBy", Common.userId);
    formdata.append("vUid", this.vehicleData[0].manual_data?._id);

    if (fileType === "start" || fileType === "end") {
      formdata.append("id", this.vehicleData[0].manual_data?._id);
    } else {
      this.vehicleData[0].manual_data?.manuals.find((v, i) => i === index)
        ? formdata.append(
          "id",
          this.vehicleData[0].manual_data?.manuals.find((v, i) => i === index)
            .id
        )
        : formdata.append("id", 0);
    }

    formdata.append("capturedData", this.captureOdometer);

    if (fileType === "start" || fileType === "end") {
      UploadFileForOther(formdata, Common.token, (err, data) => {
        if (err) {
          toast.error(err.message);
        } else {
          if (fileType === "start") {
            this.odometerStartImgPath = data.payload.image_path;
          } else {
            this.odometerEndImgPath = data.payload.image_path;
          }
          toast.success(data.payload.message);
        }
      });
    } else {
      UploadFile(formdata, Common.token, (err, data) => {
        if (this.newAudit.find((v, i) => i === index).id === 0) {
          this.newAudit.find((v, i) => i === index).id = data.id;
        }
        if (err) {
          toast.error(err.message);
        } else {
          toast.success(data.message, { autoClose: 400 });
        }
      });
    }

    // if (this.companyId === "88883333") {

    // } else {
    // UploadFileForOther(formdata, Common.token, (err, data) => {
    //     if (err) {
    //         toast.error(err.message);
    //     } else {
    //         toast.success(data.message);
    //     }
    // });
    // }
  };

  _handleSubmitEntryData = () => {
    runInAction(() => {
      this.loadingForDataEntry = true;
    });
    let start = parseInt(
      this.odometerStartStr === "" ? "0" : this.odometerStartStr
    );
    let end = parseInt(this.odometerEndStr === "" ? "0" : this.odometerEndStr);

    if (start > end) {
      toast.error(
        "Odometer - End of Month(km) must be greater than  Odometer - Start of Month(km)"
      );
    } else {
      // let data = {
      //   odometerStart:
      //     this.odometerStartStr == 0 || this.odometerStartStr === null
      //       ? this.odometerStartInputValue
      //       : this.odometerStartInputValue == "0"
      //       ? "0"
      //       : "0",
      //   odometerEnd: this.odometerEndStr,
      //   tripMileage: this.totalMileageStr,
      //   capturedDate: this.captureOdometer,
      //   editedBy: Common.userId,
      //   editedId: this.vehicleData[0].manual_data._id,
      //   type: this.currentType,
      //   date: this.date,
      //   manuals: this.newAudit.map((v) => {
      //     let data = {
      //       id: v.id,
      //       amount: v.totalamount,
      //       consumed: v.consumedStr,
      //       fuel_quantity: v.quantity,
      //       fuel_status: v.fuel_status,
      //       fuel_type: v.fuelname,
      //       invoice_no: v.invoiceNo,
      //       price_per_liter: v.pricePerL,
      //       type_status: v.type_status,
      //       image_text: v.image_text || "",
      //     };
      //     return data;
      //   }),
      // };

      let consumed_data = this.newAudit.map((v) => {
        let data = {
          id: v.id,
          amount: v.totalamount,
          invoice_no: v.invoiceNo,
          price_per_liter: v.pricePerL,
          consumed: v.consumedStr,
          fuel_quantity: v.quantity,
          fuel_status: v.fuel_status,
          fuel_type: v.fuelname,
          invoice_no: v.invoiceNo,
          price_per_liter: v.pricePerL,
          type_status: v.type_status,
          image_text: v.image_text || "",
          image_name: v.fileFuelName,
        };
        return data;
      });

      let manual_data = {
        odometerStart: this.odometerStartStr,
        odometerEnd: this.odometerEndStr,
        capturedDate: this.captureOdometer,
        tripMileage: this.totalMileageStr,
        manual_id: this.vehicleData[0].manual_data._id,
      };

      const formdata = new FormData();
      formdata.append("consumed_data", JSON.stringify(consumed_data));
      formdata.append("manual_data", JSON.stringify(manual_data));


      if (this.vehicleData[0]?.GPS && !this.vehicleData[0]?.CAMBus) {
        console.log('odometer')
      } else {
        if (this.fileStartOfMonth == null) {
          formdata.append("odometer_start", this.odometerStartRecord);
        } else {
          formdata.append("odometer_start", this.fileStartOfMonth);
        }

        if (this.fileEndOfMonth == null) {
          formdata.append("odometer_end", this.odometerEndRecord);
        } else {
          formdata.append("odometer_end", this.fileEndOfMonth);
        }
      }


      this.newAudit.map((v) => {
        if (v.fileFuelRecords !== "") {
          formdata.append("consumed_files", v.fileFuelRecords);
        } else {
          return;
        }
      });

      for (const value of formdata.values()) {
        console.log("===>", value);
      }

      UploadDataEntryV2(formdata, Common.token, (err, data) => {
        if (err) {
          toast.error(err.message);
          this.loadingForDataEntry = false;
        } else {
          toast.success(data.message);
          // this._fetchDataEntry();
          // this._handleGetConsumptionByMonth();
          this._handleGetNewConsumptionByMonth()
          this.vehicleData = null;
          this.auditFileRecords = [];
          this.loadingForDataEntry = false;
        }
      });
    }
  };

  _handleVehicleCheck = (e) => {
    const isSelected = this.checkedVehicles.findIndex((v) => v === e);
    if (isSelected === -1) {
      this.checkedVehicles.push(e);
    } else {
      this.checkedVehicles.splice(isSelected, 1);
    }
  };

  _handleMoveVehicle = (filteredVehicles) => {
    this.moveVehicles = filteredVehicles;
    this.isCheckClick = true;
    const temp = [...this.checkedVehicles];
    const filter = temp.filter(
      (a) => !filteredVehicles.map((p) => p.vName).includes(a)
    );
    this.checkedVehicles = filter;
  };

  _handleRemoveVehicle = (index) => {
    console.log(index);
    // this.companyVehicles.push(this.moveVehicles[index])
    this.moveVehicles.splice(index, 1);
  };

  _handleFetchCompanyUnitsForAssign = (
    fromCompanyId,
    userForVehicleId,
    userCompanyId
  ) => {
    this.selectedUser = userForVehicleId;
    this.userCompanyId = userCompanyId;
    this.fromCompanyId = fromCompanyId;

    this.selectedVehicle = [];
    this.availableVehicle = [];

    FetchCompanyVehiclesForAddNewUser(
      fromCompanyId,
      userForVehicleId,
      Common.token,
      userCompanyId,
      (err, data) => {
        if (data) {
          this.isLoading = false;
          if (data.message === "Token Expired!") {
            window.location.pathname = "/";
            toast.info(data.message);
          } else {
            this.selectedVehicle = data.selectedUnits;
            this.availableVehicle = data.availableUnits;
          }
        }
      }
    );
  };

  _handleAssgningVehicle = (vehicleId, userId, isAssign) => {
    let unitId = [];
    let vehicleIds = "";

    if (isAssign === "assignAll") {
      vehicleIds = vehicleId?.map((v) => v.id);
    } else if (isAssign === "unassignAll") {
      vehicleIds = vehicleId?.map((v) => v.id);
    } else {
      unitId.push(vehicleId);
      vehicleIds = unitId;
    }

    AssigningVehicle(vehicleIds, userId, Common.token, (err, data) => {
      if (data) {
        this.setCheckedValue(false, "assignAll");
        this.setCheckedValue(false, "unassignAll");
        if (data.message === "Token Expired!") window.location.pathname = "/";
        else {
          toast.success(data.message);
        }
      }
      this._handleFetchCompanyUnitsForAssign(
        this.fromCompanyId,
        this.selectedUser,
        this.userCompanyId
      );
    });
  };

  _FetchUnitsForCompanyAssign = async (forCompanyId) => {
    this.selectedCompany = forCompanyId;
    this.selectedVehicle = [];
    this.availableVehicle = [];
    FetchUnitsForCompany(
      this.companyId,
      forCompanyId,
      Common.token,
      (err, data) => {
        if (data) {
          this._handleAssignLoading(false);
          if (data.message === "Token Expired!") {
            window.location.pathname = "/";
            toast.info(data.message);
          } else {
            this.selectedVehicle = data.selectedUnits;
            this.availableVehicle = data.availableUnits;
            this._handleLoading(true);
          }
        }
      }
    );
  };

  _handleAssignUnitToCompany = async (vehicleIds, companyId) => {
    AssigningUnitsToCompany(
      vehicleIds,
      companyId,
      Common.token,
      (err, data) => {
        if (data) {
          if (data.message === "Token Expired!") window.location.pathname = "/";
          else {
            if (data.error) {
              toast.error(data.error);
            } else {
              toast.success(data.payload.message);
            }
          }
        }
        this._FetchUnitsForCompanyAssign(this.selectedCompany);
      }
    );
  };

  _handleCloseModel = () => {
    runInAction(() => {
      this.moveVehicles = [];
    });
  };

  setCroppedImage = (currentImage, currentTitle) => {
    runInAction(() => {
      switch (currentTitle) {
        case "Fuel Receipt":
          this.fileFuel = currentImage;
          break;
        case "Odometer Start":
          this.fileStart = currentImage;
          break;
        case "Odometer End":
          this.fileEnd = currentImage;
          break;
        default:
      }
    });
  };

  _handleGetVehicleType = async () => {
    runInAction(() => {
      this.vehicleType = {};
    });

    FetchSelectedVehicleList(Common.token, this.companyId, (err, data) => {
      if (data?.message === "Token Expired!") {
        toast.info(data?.message);
        window.location.pathname = "/";
      } else {
        if (
          data[0]?.primary_fuel_type === "" ||
          data[0]?.primary_fuel_type === "Battery"
        ) {
          this.vehicleType = { value: "ev", label: "EV" };
          this._handleSelectType(this.vehicleType);
        } else if (
          data[0]?.primary_fuel_type === "Petrol" ||
          data[0]?.primary_fuel_type === "Diesel"
        ) {
          this.vehicleType = { value: "fuel", label: "ICE" };
          this._handleSelectType(this.vehicleType);
        } else {
          this.vehicleType = { value: "ev", label: "EV" };
          this._handleSelectType(this.vehicleType);
        }
      }
    });
  };


  handleAddAudit = (index) => {
    this.consumedStr = "";
    this.fileFuelRecords = null;
    this.fileFuelName = "";
    runInAction(() => {
      this.newAudit.push({
        id: 0,
        consumedStr: this.consumedStr,
        fileFuelRecords: "",
        fileFuelName: "",
        fileFuel: null,
        filefueltype: "",
        fuelname: "",
        quantity: "",
        totalamount: "",
        pricePerL: "",
        invoiceNo: "",
        date: "",
        image_text: "",
        uploadFileType: "",
        status: 0
      });
    });

    let selectedAudit =
      this.newAudit[this.newAudit.length - 1].id === 0
        ? this.newAudit[this.newAudit.length - 1]
        : " ";

    if (selectedAudit) {
      this.vehicleData[0].manual_data?.manuals.push({
        id: selectedAudit.id,
        consumed: selectedAudit.consumedStr,
        fuel_quantity: selectedAudit.quantity,
        fuel_recordFileName: selectedAudit.fileFuelName,
        fuel_recordFilePath: selectedAudit.fuel_recordFilePath,
        fuel_status: selectedAudit.fuel_status,
        fuel_type: "",
        image_text: selectedAudit.image_text,
        invoice_no: selectedAudit.invoiceNo,
        isAscent: "",
        price_per_liter: selectedAudit.pricePerL,
        type_status: selectedAudit.type_status,
      });
    }
  };

  setManualData(value) {
    this.manualData = value;
  }

  _handleCreateAudit() {
    if (this.vehicleData) {
      this.auditData[0]?.manuals?.map((v) => {
        this.newAudit.push({
          id: v.id,
          status: v.status,
          status_info: v.status_info,
          consumedStr:
            v.consumed == null
              ? this.vehicleData[0]?.ascent_data?.battery_consumed.toFixed(4)
              : Number(v.consumed).toFixed(4),
          fileFuelRecords: "",
          fileFuelName: v.fuel_recordFileName,
          // fileFuel: v.fuel_recordFilePath ? "" : this.auditData[0]?.image_record,
          fileFuel: "",
          type_status: v.type_status,
          fuel_status: v.fuel_status,
          filefueltype: "",
          fuelname: "",
          quantity: v.fuel_quantity,
          totalamount: "",
          pricePerL: "",
          invoiceNo: "",
          date: "",
          fuel_recordFilePath: v.fuel_recordFilePath,
          image_text: v.image_text,
          uploadFileType: v.fuel_recordFileName?.includes(".pdf")
            ? "application/pdf"
            : "img",
        });
      });
    }
    console.log("AUDIT DATA --->", toJS(this.auditData))
    console.log("NEW AUDIT --->", toJS(this.newAudit))
  }

  _handleCreateManualData() {
    this.manualData?.manuals?.map((v) => {
      this.newAudit.push({
        id: v.id,
        consumedStr: Number(v.consumed).toFixed(4),
        fileFuelRecords: "",
        fileFuelName: v.fuel_recordFileName,
        fileFuel: this.vehicleData[0].fuelRecordsFiles,
        type_status: v.type_status,
        fuel_status: v.fuel_status,
        filefueltype: "",
        fuelname: "",
        quantity: v.fuel_quantity,
        totalamount: "",
        pricePerL: "",
        invoiceNo: "",
        date: "",
        fuel_recordFilePath: v.fuel_recordFilePath,
        image_text: v.image_text,
      });
    });
  }

  _handleClearAudit() {
    this.newAudit = [];
  }

  setVehicleIndex(index, row) {
    let value = this.currentTableData?.vehicles[index];
    this.vehicleIndex = value;
    this.tableVehicleRow = row;
  }

  setManualIndex(index, row) {
    let value1 = JSON.parse(JSON.stringify(this.consumptionDataPerUnit?.vehicles));

    let value2 = value1?.map((v) => {
      const a = JSON.parse(JSON.stringify(v?.manual_data));
      return a;
    });

    let value2_index = value2[index]

    this.manualIndex = value2_index;

    this.tableManualRow = row;

  }


  _handleDeleteAudit(Id, index) {
    if (Id == 0) {
      this.newAudit = this.newAudit.filter((v, i) => i != index);
    } else {
      DeleteFMSEntryFile(Common.token, Id, (err, data) => {
        if (data) {
          this.newAudit = this.newAudit.filter((v) => v.id != Id);
          toast.success(data.message);
        } else {
          toast.error(err.message);
        }
      });
    }
  }

  setActiveCarousel(value) {
    this.activeCarousel = value;
  }

  _handleClearAuditImages() {
    this.auditImages = [];
  }

  _handleCreateAuditImages() {
    this.newAudit
      ?.filter((v) => v.fuel_recordFilePath?.length > 0)
      .map((d) =>
        this.auditImages.push({
          id: d.id,
          imagePath: d.fuel_recordFilePath,
          image: d.fileFuel,
        })
      );

    console.log("newauditFilter", toJS(this.auditImages));
  }

  setImageText(value, index) {
    this.newAudit.find((v, i) => i === index).image_text = value;
  }

  _handleImageTextView() {
    this.imageTextView = true;
  }

  _handleImageTextValue() {
    this.imageTextView = false;
  }

  setPaginationPage(value) {
    this.page = value;
  }

  _handleCreateManualEntryNew(vId, date) {
    CreateManualEntryNew(Common.token, vId, date, (err, data) => {
      if (data.payload) {
        this.auditData = data.payload;
      }
    });
  }

  setLoadTime(value) {
    this.loadTime = value;
  }

  setImageFile(value) {
    this.imageFile = value;
  }

  _handleSetManualsData(value) {
    this.manualsData = value;
  }


  setShowManualEntryHistory = (value) => {
    runInAction(() => {
      this.showManualEntryHistory = value;
    })
  }

  _handleGetConsumptionByMonthPerUnit(grouping) {
    this.selectedGrouping = grouping;
    GetConsumptionByMonthPerUnit(
      this.currentYear,
      this.companyId,
      this.currentType,
      this.currentMonth + 1,
      Common.userId,
      grouping,
      Common.token,
      (err, data) => {
        if (data) {
          this.consumptionDataPerUnit = data.payload;
          this.setLoadingForDataEntry(true);
          // this._handleSearchVehicles("")
          this._handleCreateOdometerFiles(data.payload);
        } else {
          toast.error(data?.message);

        }
      }
    );
  }

  _handleSearchVehicles(searchedUnit) {
    this.searchedUnit = searchedUnit
  }

  _setSelectedUnit(selectedId) {
    this.selectedUnit = selectedId
  }

}

export default new DataEntry();
