import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { CompanySelector } from "../Dashboard/companySelector";
import { ManualEntryTable } from "./ManualEntryTable";
import { useHistory, useLocation } from "react-router-dom";
import { TripTable } from "./TripTable";
import { EngineHourTable } from "./EngineHourTable";
import { VehicleProfilesTable } from "./VehicleProfilesTable";
import { ConfirmDeleteCSVfileModal } from "./ConfirmDeleteCSVfileModal";
import { ConfirmSubmitCSVfileModal } from "./ConfirmSubmitCSVfileModal";
import { CO2_Loading } from "../../Assets/CO2_Loading";
import { ImportValidationModal } from "./ImportValidationModal";
import { Cookies } from "react-cookie";
import { OverWriteConfirmModal } from "./OverWriteConfirmModal";

import ProgressBar from "react-bootstrap/ProgressBar";
import { useTranslation } from "react-i18next";
import { NoCompanyVehicle } from "./NoCompanyVehicle";
import { My_Button } from "../../Tools";
import { Checkbox } from "@mui/material";

export const DataImportDetailView = inject("store")(
  observer((props) => {
    const { theme, rowData } = props;
    const { t } = useTranslation("data_import");
    const { role } = props.store.common;

    const history = useHistory();
    const location = useLocation();
    const showTable = location.state.showTable;
    const id = location.state.id;
    // console.log("id =========>",id)
    const status = location.state.status;
    const isSubmitted = location.state.isSubmitted;

    // const cookies = new Cookies();
    // const companyName = cookies.get("companyName");

    const {
      handleGetVTripsFileData,
      handleGetVTripsData,
      handleGetVEngineHourData,
      handleGetVProfilesData,
      handleGetManualEntryData,
      vProfilesData,
      vEngineHourData,
      vTripsData,
      manualEntryData,
      errorResponse,
      filenotexist,
      companyName,
      handleSetLoading,
      loading,
      // dataAlreadyExist
      unAuthorizeVehicles,
      handleClearUnauthorizeVehicle,
      handleGetUnitProfilesData
    } = props.store.dataImport;

    const { isCheck, _handleCheckBox, _handleClearCheckBox } =
      props.store.dataEntry;

    useEffect(() => {
      if (role === "super_admin" || companyName === "Volvo") {
        if (showTable === "table1") {
          // handleGetVProfilesData(id, history);
          handleGetUnitProfilesData(id, history)
        } else if (showTable === "table2") {
          // handleGetVTripsData(id, history);
          handleGetVTripsFileData(id, history)
        } else if (showTable === "table3") {
          handleGetVEngineHourData(id, history);
        } else {
          handleGetManualEntryData(id, history);
        }
      } else {
        handleGetManualEntryData(id, history);
      }
    }, []);

    const [open, setOpen] = React.useState(false);
    const handleCloseDeleteCSVfileModal = () => setOpen(false);
    const handleOpenSubmitCSVfileModal = () => setOpen(!open);

    const cardStyle1 = {
      background: Colors[theme].background,
      boxShadow: "0px 5px 10px rgba(0,0,0,0.25)",
      padding: "15px",
      borderRadius: "5px",
      marginBottom: 8,
      color: invertDark(theme),
      // cursor: "pointer",
    };

    // const rowStyle = {
    //   background: theme === "dark" ? Colors.darkBlue : Colors.Dark[5],
    //   borderRadius: "5px",
    //   fontSize: "12px",
    //   flex: 1,
    // };

    const cardStyle = {
      background: Colors[theme]?.background,
      boxShadow: "2px 4px 11px rgba(0,0,0,0.25)",
      padding: "10px",
      width: "420px",
      borderRadius: "15px",
      fontSize: "12px",
      color: Colors.Dark[theme === "dark" ? 4 : 1],
      height: "145px",
      transitionDuration: "0.1s",
    };

    const handleGoBackClick = () => {
      history.goBack();
    };

    return (
      <>
        <div>
          <div className="row">
            <span className="col-12">
              <div className="d-flex flex-row justify-content-between align-items-center flex-wrap pt-2 pb-2">
                <div className="align-self-center">
                  <CompanySelector
                    theme={theme}
                    label={t("Organisation") + ": "}
                  />
                </div>
                <div>
                  {/* <span data-bs-toggle="modal" data-bs-target="#confirmDeleteCSVfileModal">
                    <div
                      type="button"
                      className={"btn btn-danger btn-1 px-1 mx-2"}
                      style={{ width: 150 }}
                      onClick={handleCloseDeleteCSVfileModal}
                    >
                      Clear Imports
                    </div>
                    <ConfirmDeleteCSVfileModal id={id} fileName={fileName} open={open} onClose={handleCloseDeleteCSVfileModal} />
                  </span> */}

                  {/* <span data-bs-toggle="modal" data-bs-target="#confirmSubmitCSVfileModal">
                    <div
                      type="button"
                      className={"btn btn-primary btn-1 px-1"}
                      style={{ width: 100 }}
                      onClick={handleOpenSubmitCSVfileModal}
                    >
                      Submit
                    </div>
                    <ConfirmSubmitCSVfileModal fileName={fileName} showTable={showTable} open={open} history={history} onClose={handleOpenSubmitCSVfileModal} />
                  </span>

                  <div
                    type="button"
                    className={"btn btn-danger btn-1 px-1 mx-2"}
                    style={{ width: 100 }}
                    onClick={handleGoBackClick}
                  >
                    Cancel
                  </div> */}
                </div>
              </div>
            </span>
          </div>

          <div className="d-flex justify-content-between align-items-center mb-2">
            <div style={{ color: Colors.Dark[theme === "light" ? 2 : 4] }}>
              File Name -{" "}
              <span style={{ fontWeight: "bold" }}>
                {rowData?.uploadedFileName}
              </span>
            </div>
          </div>

          {role === "super_admin" || companyName === "Volvo" ? (
            <>
              {showTable === "table1" ? (
                <>
                  {loading ? (
                    <CO2_Loading />
                  ) : (
                    <VehicleProfilesTable
                      theme={theme}
                      vProfilesData={vProfilesData}
                      cardStyle={cardStyle}
                      cardStyle1={cardStyle1}
                      filenotexist={filenotexist}
                      status={status}
                    />
                  )}
                </>
              ) : showTable === "table2" ? (
                <>
                  {loading ? (
                    <CO2_Loading />
                  ) : (
                    <TripTable
                      theme={theme}
                      vTripsData={vTripsData}
                      filenotexist={filenotexist}
                      cardStyle={cardStyle}
                      cardStyle1={cardStyle1}
                      status={status}
                    />
                  )}{" "}
                </>
              ) : showTable === "table3" ? (
                <EngineHourTable
                  theme={theme}
                  vEngineHourData={vEngineHourData}
                  filenotexist={filenotexist}
                  cardStyle={cardStyle}
                  cardStyle1={cardStyle1}
                  status={status}
                />
              ) : (
                <ManualEntryTable
                  theme={theme}
                  manualEntryData={manualEntryData}
                  filenotexist={filenotexist}
                  cardStyle={cardStyle}
                  cardStyle1={cardStyle1}
                  status={status}
                />
              )}
            </>
          ) : (
            <>
              {
                <ManualEntryTable
                  theme={theme}
                  manualEntryData={manualEntryData}
                  filenotexist={filenotexist}
                  cardStyle={cardStyle}
                  cardStyle1={cardStyle1}
                  status={status}
                />
              }
            </>
          )}
          {/* ! do not delete this comment */}
          {/* status === "0"?
                      ( */}

          {/* )
                      :
                      <>
                      </> */}
          <NoCompanyVehicle
            theme={theme}
            unAuthorizeVehicles={unAuthorizeVehicles}
            showTable={showTable}
          />
        </div>
        {loading ? null : (
          <>
            {role === "super_admin" || companyName === "Volvo" ? (
              <>
                {showTable === "table1" ? (
                  vProfilesData?.length > 0 ? (
                    <>
                      {/* <div
                        style={{
                          color: Colors.Dark[theme === "light" ? 2 : 4],
                          marginTop: 10,
                        }}
                      >
                        <i
                          className={`fas fa-exclamation-circle pe-2`}
                          style={{
                            color: Colors.medium,
                          }}
                        />
                        {t(
                          "Please note that editing these fields will affect carbon emissions calculation of the vehicle."
                        )}
                      </div> */}
                      <div
                        style={{
                          color: Colors.Dark[theme === "light" ? 2 : 4],
                          marginTop: 10,
                        }}
                      >
                        <i
                          className={`fas fa-exclamation-circle pe-2`}
                          style={{
                            color: Colors.medium,
                          }}
                        />
                        {t(
                          "By entering data into the CO2X Enterprise application, the user acknowledges and agrees that they are solely responsible for the accuracy, integrity, and truthfulness of the information provided."
                        )}<br></br>{" "}
                         <i
                          className={`pe-4`}
                        />{t("CO2 Connect assumes no liability or responsibility for any inaccuracies, errors, or misrepresentations in the data submitted by the user.")}
                      </div>
                      <div className="d-flex flex-row flex-wrap justify-content-between mt-1">
                        <span
                          style={{
                            color: Colors.Dark[theme === "light" ? 2 : 4],
                            cursor: "pointer",
                            marginBottom: 30,
                          }}
                          onClick={() => _handleCheckBox()}
                        >
                          <Checkbox
                            checked={isCheck}
                            style={{ color: Colors.activeColor, padding: 0 }}
                          />{" "}
                          {t(
                            "I confirm that the above data is accurate to the best of my knowledge."
                          )}
                          <br></br>
                        </span>

                        <span
                          className="mx-1"
                          data-bs-toggle={!isCheck ? "" : "modal"}
                          data-bs-target={
                            !isCheck ? "" : "#confirmSubmitCSVfileModal"
                          }
                        >
                          <My_Button
                            text={t("Submit")}
                            disabled={!isCheck}
                            type={"submit"}
                            data-bs-toggle="modal"
                            data-bs-target="#confirmSubmitCSVfileModal"
                            customColor={Colors.low}
                            style={{
                              backgroundColor: Colors.low,
                              width: "100px",
                              height: "35px",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: "center",
                            }}
                            onClick={handleOpenSubmitCSVfileModal}
                          />
                        </span>
                      </div>
                      <ConfirmSubmitCSVfileModal
                        id={id}
                        showTable={showTable}
                        open={open}
                        history={history}
                        onClose={handleOpenSubmitCSVfileModal}
                      />

                      <ImportValidationModal
                        theme={theme}
                        errorResponse={errorResponse}
                        showTable={showTable}
                      />
                      <OverWriteConfirmModal
                        theme={theme}
                        id={id}
                        // dataAlreadyExist={dataAlreadyExist}
                      />
                    </>
                  ) : (
                    <></>
                  )
                ) : showTable === "table2" ? (
                  vTripsData?.length > 0 ? (
                    <>
                        {/* <div
                        style={{
                          color: Colors.Dark[theme === "light" ? 2 : 4],
                          marginTop: 10,
                        }}
                      >
                        <i
                          className={`fas fa-exclamation-circle pe-2`}
                          style={{
                            color: Colors.medium,
                          }}
                        />
                        {t(
                          "Please note that editing these fields will affect carbon emissions calculation of the vehicle."
                        )}
                      </div> */}
                      <div
                        style={{
                          color: Colors.Dark[theme === "light" ? 2 : 4],
                          marginTop: 10,
                        }}
                      >
                        <i
                          className={`fas fa-exclamation-circle pe-2`}
                          style={{
                            color: Colors.medium,
                          }}
                        />
                        {t(
                          "By entering data into the CO2X Enterprise application, the user acknowledges and agrees that they are solely responsible for the accuracy, integrity, and truthfulness of the information provided."
                        )}<br></br>{" "}
                         <i
                          className={`pe-4`}
                        />{t("CO2 Connect assumes no liability or responsibility for any inaccuracies, errors, or misrepresentations in the data submitted by the user.")}
                      </div>
                      <div className="d-flex flex-row flex-wrap justify-content-between mt-1">
                        <span
                          style={{
                            color: Colors.Dark[theme === "light" ? 2 : 4],
                            cursor: "pointer",
                            marginBottom: 30,
                          }}
                          onClick={() => _handleCheckBox()}
                        >
                          <Checkbox
                            checked={isCheck}
                            style={{ color: Colors.activeColor, padding: 0 }}
                          />{" "}
                          {t(
                            "I confirm that the above data is accurate to the best of my knowledge."
                          )}
                          <br></br>
                        </span>

                        <span
                          className="mx-1"
                          data-bs-toggle={!isCheck ? "" : "modal"}
                          data-bs-target={
                            !isCheck ? "" : "#confirmSubmitCSVfileModal"
                          }
                        >
                          <My_Button
                            text={t("Submit")}
                            disabled={!isCheck}
                            type={"submit"}
                            data-bs-toggle="modal"
                            data-bs-target="#confirmSubmitCSVfileModal"
                            customColor={Colors.low}
                            style={{
                              backgroundColor: Colors.low,
                              width: "100px",
                              height: "35px",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: "center",
                            }}
                            onClick={handleOpenSubmitCSVfileModal}
                          />
                        </span>
                      </div>
                      <ConfirmSubmitCSVfileModal
                        id={id}
                        showTable={showTable}
                        open={open}
                        history={history}
                        onClose={handleOpenSubmitCSVfileModal}
                      />

                      <ImportValidationModal
                        theme={theme}
                        errorResponse={errorResponse}
                        showTable={showTable}
                      />
                      <OverWriteConfirmModal
                        theme={theme}
                        id={id}
                        // dataAlreadyExist={dataAlreadyExist}
                      />
                    </>
                  ) : (
                    <></>
                  )
                ) : showTable === "table3" ? (
                  vEngineHourData?.length > 0 ? (
                    <>
                        {/* <div
                        style={{
                          color: Colors.Dark[theme === "light" ? 2 : 4],
                          marginTop: 10,
                        }}
                      >
                        <i
                          className={`fas fa-exclamation-circle pe-2`}
                          style={{
                            color: Colors.medium,
                          }}
                        />
                        {t(
                          "Please note that editing these fields will affect carbon emissions calculation of the vehicle."
                        )}
                      </div> */}
                      <div
                        style={{
                          color: Colors.Dark[theme === "light" ? 2 : 4],
                          marginTop: 10,
                        }}
                      >
                        <i
                          className={`fas fa-exclamation-circle pe-2`}
                          style={{
                            color: Colors.medium,
                          }}
                        />
                        {t(
                          "By entering data into the CO2X Enterprise application, the user acknowledges and agrees that they are solely responsible for the accuracy, integrity, and truthfulness of the information provided."
                        )}<br></br>{" "}
                         <i
                          className={`pe-4`}
                        />{t("CO2 Connect assumes no liability or responsibility for any inaccuracies, errors, or misrepresentations in the data submitted by the user.")}
                      </div>
                      <div className="d-flex flex-row flex-wrap justify-content-between mt-1">
                        <span
                          style={{
                            color: Colors.Dark[theme === "light" ? 2 : 4],
                            cursor: "pointer",
                            marginBottom: 30,
                          }}
                          onClick={() => _handleCheckBox()}
                        >
                          <Checkbox
                            checked={isCheck}
                            style={{ color: Colors.activeColor, padding: 0 }}
                          />{" "}
                          {t(
                            "I confirm that the above data is accurate to the best of my knowledge."
                          )}
                          <br></br>
                        </span>

                        <span
                          className="mx-1"
                          data-bs-toggle={!isCheck ? "" : "modal"}
                          data-bs-target={
                            !isCheck ? "" : "#confirmSubmitCSVfileModal"
                          }
                        >
                          <My_Button
                            text={t("Submit")}
                            disabled={!isCheck}
                            type={"submit"}
                            data-bs-toggle="modal"
                            data-bs-target="#confirmSubmitCSVfileModal"
                            customColor={Colors.low}
                            style={{
                              backgroundColor: Colors.low,
                              width: "100px",
                              height: "35px",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: "center",
                            }}
                            onClick={handleOpenSubmitCSVfileModal}
                          />
                        </span>
                      </div>
                      <ConfirmSubmitCSVfileModal
                        id={id}
                        showTable={showTable}
                        open={open}
                        history={history}
                        onClose={handleOpenSubmitCSVfileModal}
                      />

                      <ImportValidationModal
                        theme={theme}
                        errorResponse={errorResponse}
                        showTable={showTable}
                      />
                      <OverWriteConfirmModal
                        theme={theme}
                        id={id}
                        // dataAlreadyExist={dataAlreadyExist}
                      />
                    </>
                  ) : (
                    <></>
                  )
                ) : showTable === "table4" ? (
                  manualEntryData?.length > 0 ? (
                    <>
                      {/* <div
                        style={{
                          color: Colors.Dark[theme === "light" ? 2 : 4],
                          marginTop: 10,
                        }}
                      >
                        <i
                          className={`fas fa-exclamation-circle pe-2`}
                          style={{
                            color: Colors.medium,
                          }}
                        />
                        {t(
                          "Please note that editing these fields will affect carbon emissions calculation of the vehicle."
                        )}
                      </div> */}
                      <div
                        style={{
                          color: Colors.Dark[theme === "light" ? 2 : 4],
                          marginTop: 10,
                        }}
                      >
                        <i
                          className={`fas fa-exclamation-circle pe-2`}
                          style={{
                            color: Colors.medium,
                          }}
                        />
                        {t(
                          "By entering data into the CO2X Enterprise application, the user acknowledges and agrees that they are solely responsible for the accuracy, integrity, and truthfulness of the information provided."
                        )}<br></br>{" "}
                         <i
                          className={`pe-4`}
                        />{t("CO2 Connect assumes no liability or responsibility for any inaccuracies, errors, or misrepresentations in the data submitted by the user.")}
                      </div>
                      <div className="d-flex flex-row flex-wrap justify-content-between mt-1">
                        <span
                          style={{
                            color: Colors.Dark[theme === "light" ? 2 : 4],
                            cursor: "pointer",
                            marginBottom: 30,
                          }}
                          onClick={() => _handleCheckBox()}
                        >
                          <Checkbox
                            checked={isCheck}
                            style={{ color: Colors.activeColor, padding: 0 }}
                          />{" "}
                          {t(
                            "I confirm that the above data is accurate to the best of my knowledge."
                          )}
                          <br></br>
                        </span>

                        <span
                          className="mx-1"
                          data-bs-toggle={!isCheck ? "" : "modal"}
                          data-bs-target={
                            !isCheck ? "" : "#confirmSubmitCSVfileModal"
                          }
                        >
                          <My_Button
                            text={t("Submit")}
                            disabled={!isCheck}
                            type={"submit"}
                            data-bs-toggle="modal"
                            data-bs-target="#confirmSubmitCSVfileModal"
                            customColor={Colors.low}
                            style={{
                              backgroundColor: Colors.low,
                              width: "100px",
                              height: "35px",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: "center",
                            }}
                            onClick={handleOpenSubmitCSVfileModal}
                          />
                        </span>
                      </div>
                      <ConfirmSubmitCSVfileModal
                        id={id}
                        showTable={showTable}
                        open={open}
                        history={history}
                        onClose={handleOpenSubmitCSVfileModal}
                      />

                      <ImportValidationModal
                        theme={theme}
                        errorResponse={errorResponse}
                        showTable={showTable}
                      />
                      <OverWriteConfirmModal
                        theme={theme}
                        id={id}
                        // dataAlreadyExist={dataAlreadyExist}
                      />
                    </>
                  ) : (
                    <></>
                  )
                ) : null}
              </>
            ) : (
              <>
                {manualEntryData?.length > 0 ? (
                  <>
                      {/* <div
                        style={{
                          color: Colors.Dark[theme === "light" ? 2 : 4],
                          marginTop: 10,
                        }}
                      >
                        <i
                          className={`fas fa-exclamation-circle pe-2`}
                          style={{
                            color: Colors.medium,
                          }}
                        />
                        {t(
                          "Please note that editing these fields will affect carbon emissions calculation of the vehicle."
                        )}
                      </div> */}
                      <div
                        style={{
                          color: Colors.Dark[theme === "light" ? 2 : 4],
                          marginTop: 10,
                        }}
                      >
                        <i
                          className={`fas fa-exclamation-circle pe-2`}
                          style={{
                            color: Colors.medium,
                          }}
                        />
                        {t(
                          "By entering data into the CO2X Enterprise application, the user acknowledges and agrees that they are solely responsible for the accuracy, integrity, and truthfulness of the information provided."
                        )}<br></br>{" "}
                         <i
                          className={`pe-4`}
                        />{t("CO2 Connect assumes no liability or responsibility for any inaccuracies, errors, or misrepresentations in the data submitted by the user.")}
                      </div>
                    <div className="d-flex flex-row flex-wrap justify-content-between mt-1">
                      <span
                        style={{
                          color: Colors.Dark[theme === "light" ? 2 : 4],
                          cursor: "pointer",
                          marginBottom: 30,
                        }}
                        onClick={() => _handleCheckBox()}
                      >
                        <Checkbox
                          checked={isCheck}
                          style={{ color: Colors.activeColor, padding: 0 }}
                        />{" "}
                        {t(
                          "I confirm that the above data is accurate to the best of my knowledge."
                        )}
                        <br></br>
                      </span>

                      <span
                        className="mx-1"
                        data-bs-toggle={!isCheck ? "" : "modal"}
                        data-bs-target={
                          !isCheck ? "" : "#confirmSubmitCSVfileModal"
                        }
                      >
                        <My_Button
                          text={t("Submit")}
                          disabled={!isCheck}
                          type={"submit"}
                          data-bs-toggle="modal"
                          data-bs-target="#confirmSubmitCSVfileModal"
                          customColor={Colors.low}
                          style={{
                            backgroundColor: Colors.low,
                            width: "100px",
                            height: "35px",
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                          }}
                          onClick={handleOpenSubmitCSVfileModal}
                        />
                      </span>
                    </div>
                    <ConfirmSubmitCSVfileModal
                      id={id}
                      showTable={showTable}
                      open={open}
                      history={history}
                      onClose={handleOpenSubmitCSVfileModal}
                    />

                    <ImportValidationModal
                      theme={theme}
                      errorResponse={errorResponse}
                      showTable={showTable}
                    />
                    <OverWriteConfirmModal
                      theme={theme}
                      id={id}
                      // dataAlreadyExist={dataAlreadyExist}
                    />
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        )}
      </>
    );
  })
);
