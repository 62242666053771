import { Colors } from "./Config/color.config";
import Spinner from "./Images/icons/spinner.gif";

export const DataEntry_Loading = (props) => {
  return (
    <div className="text-center" style={{ margin: 40 }}>
      <img src={Spinner} alt="loading" width={50} />
      <br />
      <span style={{ color: Colors.Dark[4] }}>Loading... </span>
    </div>
  );
};
