import * as API from "./URL";

export const fetchVehicleInfo = async (token, vehicleId, callback) => {
  return fetch(`${API.vehicleInfo}?vehicleId=${vehicleId}`, {
    method: "GET",
    headers: {
      //"Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
};

export const fetchVehiclesForDriver = async (companyId, token, callback) => {
  return fetch(`${API.vehicleLstAPI}?userId=${companyId}`, {
    method: "GET",
    headers: {
      //"Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
};

export const fetchFuelRecord = async (formData, token, callback) => {
  return fetch(API.vehicleFuelRecordAPI, {
    method: "POST",
    body: formData,
    headers: {
      //"Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
};

export const fetchFuelHistory = async (
  driverId,
  year,
  month,
  token,
  callback
) => {
  return fetch(
    `${API.fuelHistoryAPI}?userId=${driverId}&year=${year}&month=${month}`,
    {
      method: "GET",
      headers: {
        //"Content-Type": "application/json",
        Accept: "*/*",
        "x-access-token": token,
      },
    }
  )
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
};

export const fetchVehicleInfoNew = async (token, vehicleId, callback) => {
  return fetch(`${API.vehicleInfoNewAPI}?vehicleId=${vehicleId}`, {
    method: "GET",
    headers: {
      //"Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
};

export const fetchOdometerStartEnd = async (token, date, unitId, callback) => {
  return fetch(`${API.odometerStartEndAPI}?date=${date}&unitId=${unitId}`, {
    method: "GET",
    headers: {
      //"Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
};


