import { toJS } from "mobx";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { My_Button, My_Input, My_upload_image, Selector } from "../../Tools";
import { useHistory } from "react-router-dom";
import { inject, observer } from "mobx-react";
import Store from "../../Store";
import { useEffect, useState } from "react";
import user from "../../helper/localStorage";
import { useTranslation } from "react-i18next";

export const AddNewUserModal = inject("store")(
    observer((props) => {
        const {t} = useTranslation("admin-panel")
        const { theme,  } = props;
        const history = useHistory()
        const data = Store.adminPanel.userList;
        const { role, companyId } = props.store.common;
        const { _handleInputChange, companies, _fetchCompanyList ,_handleFormClear, userData, getUserPlan, _setAddNewUser, _handleCloseModel, addnewuserlist, _handleTestUserListOnClick } = props.store.adminPanel;
        // {this.userData && this.addnewuserlist.push(this.userData);}
  
        let landingRole = ''
        role === 'admin' ? landingRole = 'super_admin' : landingRole = role
                

        useEffect(() => {
            getUserPlan()
            _fetchCompanyList(landingRole, companyId)
            
        }, [])

        const CompanyOptions = companies.map((v) => ({
            value: v?.id,
            label: v?.name,
        }));


        // const companyOptions = CompanyOptions.slice(0, CompanyOptions.length - 1);
        console.log("company options", CompanyOptions);

        return (
            <div
                className="modal fade show"
                id="addnewusermodal"
                tabIndex="-1"
                aria-labelledby="addnewusermodal"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xl rounded" >
                    <div
                        className="modal-content"
                        style={{ background: Colors[theme]?.background, color: invertDark(theme) }}
                    >
                        <div className="modal-header" style={{ background: "#192353", color: invertDark(theme), border: "none", padding: 6 }}>

                            <span style={{ fontSize: 24, color: '#fff', fontWeight: 'bold', borderTopLeftRadius: 10, borderTopRightRadius: 10, marginLeft: 2 }}>
                                {/* <img height="40px" style={{ text: 'center' }} src={AddUser} /> */}
                                <i className="bi bi-person-plus-fill pe-2"></i>
                                {t("Create New User")}
                            </span>

                            <span data-bs-dismiss="modal" onClick={() => _handleCloseModel()} style={{ cursor: 'pointer', marginRight: 10 }}>
                                <i className="bi bi-x-lg" />
                            </span>
                        </div>

                        <div className="modal-body" style={{ background: "#374379" }}>

                            <form onSubmit={(e) => _setAddNewUser(e, props)} className="row" style={{ fontSize: 14 }}>

                                <div className="row">

                                    <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12 d-flex align-items-stretch" >

                                        <div style={{ background: "#29325B", padding: 20, borderRadius: 5, width: "100%" }}>

                                            <div className="text-start m-0 mb-3" style={{ fontSize: 20, fontWeight: 'bold' }}>{t("Fill User Information")} </div>

                                            <div className="pb-3">
                                                <div className="row">
                                                    <div className="col-4 col align-self-center">
                                                        <span style={{ color: "#C3C6D1", fontWeight: "bold" }}>
                                                            <div className="mb-1">{t("User Name")}</div>

                                                        </span>
                                                    </div>
                                                    <div className="col-8">

                                                        <My_Input
                                                            id='username'
                                                            value={userData.username}
                                                            height='38px'
                                                            placeHolder={"User Name"}
                                                            onChange={(e) => _handleInputChange({ username: e.target.value })} />

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="pb-3">
                                                <div className="row">
                                                    <div className="col-4 col align-self-center">

                                                        <span style={{ color: "#C3C6D1", fontWeight: "bold" }}>

                                                            <div className="mb-1">{t("Email")}</div>

                                                        </span>
                                                    </div>
                                                    <div className="col-8">
                                                        <My_Input
                                                            id='email'
                                                            value={userData.email}
                                                            height='38px'
                                                            placeHolder={"Email"}
                                                            onChange={(e) => _handleInputChange({ email: e.target.value })} 
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <div className="pb-3">
                                                <div className="row">
                                                    <div className="col-4 col align-self-center">
                                                        <span style={{ color: "#C3C6D1", fontWeight: "bold" }}>
                                                            <div className="mb-1">{t("Password")}</div>

                                                        </span>
                                                    </div>
                                                    <div className="col-8">
                                                        <My_Input
                                                            id='password'
                                                            value={userData.password}
                                                            height='38px'
                                                            placeHolder={"Password"}
                                                            onChange={(e) => _handleInputChange({ password: e.target.value })} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="pb-3">
                                                <div className="row">
                                                    <div className="col-4 col align-self-center">
                                                        <span style={{ color: "#C3C6D1", fontWeight: "bold" }}>
                                                            <div className="mb-1">{t("Confirm Password")}</div>

                                                        </span>
                                                    </div>
                                                    <div className="col-8">
                                                        <My_Input
                                                            id='confirmpassword'
                                                            value={userData.confirmPassword}
                                                            height='38px'
                                                            placeHolder={"Confirm Password"}
                                                            onChange={(e) => _handleInputChange({ confirmPassword: e.target.value })} />
                                                    </div>
                                                </div>
                                            </div> */}

                                            <div className="pb-3">
                                                <div className="row">
                                                    <div className="col-4 col align-self-center">
                                                        <span style={{ color: "#C3C6D1", fontWeight: "bold" }}>
                                                            <div className="mb-1">{t("Assign Company")}</div>
                                                        </span>
                                                    </div>
                                                    <div className="col-8">
                                                        <Selector
                                                            
                                                            // value={companies?.find(v => v.value === userData.value)}
                                                            _handleSelect={(e) => _handleInputChange(e)} menuListColor={Colors.darkBlue} options={CompanyOptions} />

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row pb-2">

                                                <div className="col-4 col align-self-center">
                                                    <span style={{ color: "#C3C6D1", fontWeight: "bold" }}>
                                                        <div className="mb-1">{t("Role")}</div>
                                                    </span>
                                                </div>

                                                <span className="col-8">
                                                    <label className="pe-3" htmlFor="admin">
                                                        <input className="me-1" id='admin' type='radio' onClick={(e) => _handleInputChange({ role: e.target.value })}
                                                            value='admin' name='role'
                                                            height='38px'></input>
                                                        {t("Admin")}
                                                    </label>
                                                    <label className="pe-3" htmlFor="user">
                                                        <input className="me-1" id='user' type='radio' onClick={(e) => _handleInputChange({ role: e.target.value })}
                                                            value='user' name='role'
                                                            height='38px'></input>
                                                        {t("User")}
                                                    </label>
                                                    <label htmlFor="driver">
                                                        <input className="me-1" id='driver' type='radio' onClick={(e) => _handleInputChange({ role: e.target.value })}
                                                            value='driver' name='role'
                                                            height='38px'></input>
                                                        {t("Driver")}
                                                    </label>
                                                </span>

                                            </div>


                                            <div className="mt-3 text-center" >

                                                <span style={{ cursor: 'pointer' }}>
                                                    <My_Button
                                                        customColor="#192353"
                                                        text={t('Add')}
                                                        onClick = {()=>{_handleTestUserListOnClick()
                                                        }
                                                        }
                                                        
                                                        style={{ minWidth: 100 }}
                                                        customAdd={<i className="bi bi-plus-square-dotted" style={{ marginLeft: "0.5em" }}></i>}
                                                    />
                                                </span>

                                            </div>

                                        </div>

                                    </div>

                                    
                                    <div className="col-sm-12 col-lg-6 col-md-6 col-xl-6 col-xs-12 d-flex align-items-stretch">

                                        <div style={{ background: "#29325B", padding: 20, borderRadius: 5, width: "100%" }}>

                                            <div className="text-start m-0 mb-3" style={{ fontSize: 20, fontWeight: 'bold' }}>User List </div>

                                            {addnewuserlist.map((v, k) => {
                                                
                                                return(

                                                <div key={k} className="py-2 my-2"  style={{ background: "#384067", borderRadius: 6 }}>

                                                <div className="row px-2">
                                                    <div className="col-5">
                                                        <i className="bi bi-person-circle px-1" ></i>{v.username}
                                                    </div>
                                                    <div className="col-6">
                                                        <i className="bi bi-people-fill mx-1" ></i> {v.label}
                                                    </div>
                                                    <div className="col-1 ">
                                                        <i className="bi bi-trash-fill" style={{ color: "#8EBBFF" }}></i>
                                                    </div>
                                                </div>

                                            </div>
                                        )})}

                                            

                                        </div>

                                    </div>

                                    <div className="mt-3 text-end" >

                                        <span style={{ cursor: 'pointer' }}>
                                            <My_Button
                                                type="submit"
                                                customColor="#192353"
                                                text='Finish'
                                                // onClick={_handleCreateUser}
                                                // data-bs-dismiss="modal"
                                                customConfirm={<i className="bi bi-check-lg" style={{ marginLeft: 2 }}></i>}
                                            />
                                        </span>

                                    </div>

                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }));


// const assigncompanyoption = [
//     {value: 'KumoSolution', label: 'Kumo Solution'},
//     {value: 'Evercomm', label: 'Evercomm'},
//     {value: 'ABCcompany', label: 'ABC Company'}
// ]
