import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { CompanySelector } from "../Dashboard/companySelector";
import LogsTable from "./LogsTable";
import { invertDark, Colors } from "../../Assets/Config/color.config";
import { Selector } from "../../Tools";
import { toJS } from "mobx";
import month from "../../Assets/month.json";
import { useTranslation } from "react-i18next";
import IssueTable from "./IssueTable";
import { My_Button, My_DatePicker, My_Input } from "../../Tools";
import dayjs from "dayjs";
import { DatePicker } from "antd";

import moment from "moment";
import { CO2_Loading } from "../../Assets/CO2_Loading";
import { LogsReport } from "./LogsReport";

const Logs = inject("store")(
  observer((props) => {
    const { t } = useTranslation("admin-panel");
    const {
      theme,
      filteredLogs,
      // filteredIssues,
      filterData,
    } = props;
    const { role } = props.store.common;
    const { dashboardYears, _handleFetchCompanyYears } = props.store.dashboard;
    const {
      logsData,
      issuesData,
      _handleGetActivityLogs,
      _handleGetLogIssues,
      // startDate,
      // endDate,
      _handleSelectCustom,
      _handleViewChange,
      showLogView,
      setLoading,
      isLoading,
    } = props.store.adminPanel;

    const history = useHistory();

    const { RangePicker } = DatePicker;
    const defaultStartDate = dayjs().subtract(7, "day");
    const defaultEndDate = dayjs();

    const [startDate, setStartDate] = useState(defaultStartDate);
    const [endDate, setEndDate] = useState(defaultEndDate);

    const disabledDate = (current) => {
      return current && current > defaultEndDate.endOf("day");
    };

    const LogsReportModal = () => {
      let myModal = new window.bootstrap.Modal(
        document.getElementById("logsreport")
      );
      myModal.show();
    };

    useEffect(() => {
      history.push("/logs");
      if (!props.startDate || !props.endDate) {
        _handleSelectCustom(
          [defaultStartDate, defaultEndDate],
          [
            defaultStartDate.format("YYYY-MM-DD"),
            defaultEndDate.format("YYYY-MM-DD"),
          ]
        );
        _handleGetActivityLogs();
        _handleGetLogIssues();
      }
    }, []);

    const filteredIssues = issuesData?.filter((data) => {
      var dataTime = moment(data.issue_time).format("YYYY-MM-DD HH:mm:ss");
      const filterStartDate = props.startDate
        ? dayjs(props.startDate)
        : startDate;
      const filterEndDate = props.endDate ? dayjs(props.endDate) : endDate;

      return (
        moment(dataTime).isSameOrAfter(
          filterStartDate.format("YYYY-MM-DD HH:mm:ss")
        ) &&
        moment(dataTime).isSameOrBefore(
          filterEndDate.add(+1, "day").format("YYYY-MM-DD HH:mm:ss")
        )
      );
    });



    return (
      <div className="row px-2 m-0">
        <h4 style={{ color: invertDark(theme), padding: "0px" }}>
          {t("Activity Logs")}
        </h4>

        <div style={{ width: "100%", padding: "0px" }}>
          <div
            className={`d-flex justify-content-between pb-3 ${window.innerWidth < 701
              ? `flex-column justify-content-center`
              : ""
              }`}
            style={{ width: "inherit" }}
          >
            <div
              className={`col-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 d-flex justify-content-start align-items-center  ${theme === "dark" ? "dark-theme" : "light-theme"
                }`}
              style={{ color: invertDark(theme) }}
            >
              <CompanySelector theme={theme} label={t("Organisation") + ": "} />
            </div>
            {/* <div
                        // style={{ paddingLeft: width > 701 && width < 921 ? 80 : 0 }}
                        className={`d-flex flex-nowrap ${window.innerWidth < 701 ? `flex-column justify-content-center` : ""}`}
                    > */}
            <div
              className={`col-5 col-xl-5 col-lg-5 col-md-5 col-sm-5 d-flex justify-content-end align-items-center  ${theme === "dark" ? "dark-theme" : "light-theme"
                }`}
              style={{ color: invertDark(theme) }}
            >
              {t("Filter")} :
            </div>

            <div
              className={`col-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 d-flex justify-content-center align-items-center me-1 ms-1 ${theme === "dark" ? "dark-theme" : "light-theme"
                }`}
              style={{ color: invertDark(theme) }}
            >
              {/* {t("Filter")}:  */}

              <RangePicker
                // mode='date'
                disabledDate={disabledDate}
                defaultValue={[defaultStartDate, defaultEndDate]}
                style={{
                  background:
                    theme === "dark" ? Colors.darkBlue : Colors.Dark[5],
                  // color: theme === "dark" ? Colors.Dark[4] : Colors.Dark[1],
                  width: "100%",
                  height: "38px",
                  borderRadius: 3,
                  borderColor:
                    theme === "dark" ? Colors.Dark[2] : Colors.Dark[4],
                }}
                className={`${theme === "dark" ? "dark-theme" : "light-theme"}`}
                onChange={(value, dateString) => {
                  _handleSelectCustom(value, dateString);
                  _handleGetActivityLogs();
                  _handleGetLogIssues();
                }}
              // onChange={(value, dateString) => _handleSelectCustom(value, dateString)}
              />
              <button
                className={`${theme === "dark" ? "btn btn-custom-individual-search ms-2 me-2" : "btn btn-custom-individual-light-search ms-2 me-2"}`}
                onClick={() => {
                  _handleGetActivityLogs();
                  _handleGetLogIssues();
                }}
              >
                Search
              </button>
            </div>
          </div>
        </div>
        {role === "super_admin" ? (
          <div className="d-flex p-0 justify-content-between align-items-center">
            <div className="col-md-3">
              <div className="btn-group btn-group-sm" role="group">
                <div
                  type="button"
                  style={{ zIndex: 0, maxWidth: '4rem' }}
                  className={`${showLogView === "logs"
                    ? theme === "dark"
                      ? "btn-custom-individual"
                      : "btn-custom-individual-light"
                    : theme === "dark"
                      ? "btn-custom-individual-inactive"
                      : "btn-custom-individual-light-inactive"
                    }`}
                  onClick={() => _handleViewChange("logs")}
                >
                  {t("Logs")}
                </div>
                <div
                  type="button"
                  style={{ zIndex: 0, maxWidth: '5rem' }}
                  className={`${showLogView === "table"
                    ? theme === "dark"
                      ? "btn-custom-comparison"
                      : "btn-custom-comparison-light"
                    : theme === "dark"
                      ? "btn-custom-comparison-inactive"
                      : "btn-custom-comparison-light-inactive"
                    }`}
                  onClick={() => _handleViewChange("table")}
                >
                  {t("Issues")}
                </div>
              </div>
            </div>
            <div onClick={() => LogsReportModal()}>
              <button
                style={{
                  color: 'white',
                  background: '#4c5d75',
                  height: '30.48px',
                  borderRadius: '5px',
                  border: 'none',
                  outline: 'none',
                  marginBottom: '1px',
                  fontSize: '14px'
                }}

              >
                Create Report
              </button>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div
          style={{
            background:
              theme === "dark"
                ? "linear-gradient(65deg, rgb(16, 24, 65), rgb(32, 43, 96))"
                : "",
            boxShadow: "rgba(0, 0, 0, 0.25) 0px 5px 10px",
            padding: "15px",
            borderRadius: "5px",
            marginBottom: "8px",
            color: "rgb(198, 198, 198)",
          }}
        >
          {isLoading ? (
            <CO2_Loading />
          ) : showLogView === "logs" ? (
            <LogsTable
              theme={theme}
              filteredLogs={filteredLogs}
              filterData={filterData}
            />
          ) : (
            <IssueTable
              theme={theme}
              issuesData={issuesData}
              filteredIssues={filteredIssues}
            />
          )}
        </div>
        <LogsReport theme={theme} logsData={logsData} showLogView={showLogView} />
      </div>
    );
  })
);

export default Logs;
