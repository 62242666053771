import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { toJS } from "mobx";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import Store from "../../Store";
import { CO2_Loading } from "../../Assets/CO2_Loading";
import { FaInfoCircle } from "react-icons/fa";
import { ViewScopeImage } from "./viewScopeImage";
import scope_image from "../../Assets/Images/scopeImage.png";
import { ScopeCarbonSelector } from "./scopeCarbonSelector";

const ScopeCarbonChart = (props) => {
  const theme = Store.login.theme;
  const { t } = useTranslation("dashboard");
  const {
    scope3_Emission,
    scope2_Emission,
    scope1_Emission,
    _handleScopeChange,
    loading,
    selectedYear,
    setSelectedYear,
    scope1CheckData,
    scope2CheckData,
    scope3CheckData,
    monthlyScopeData,
    _handleFetchMonthlyCarbonData,
  } = props.store.dashboard;
  const [windowWidth, setWidth] = useState(window.innerWidth);

  console.log("monthly scope data ======>",toJS(monthlyScopeData))

  const WindowSize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", WindowSize);
    return () => {
      window.removeEventListener("resize", WindowSize);
    };
  }, []);
  const { scope, companyType } = props;
  //scope1
  const scope1_check = monthlyScopeData?.map((v) =>
    toJS(v?.scope1_emissions?.baseline_co2_emission)
  );
  // console.log("scope 2 check====>", toJS(scope1_check));
  const isAllUndefinedScope1 = (array) => {
    return array.every((value) => value === undefined || value === 0);
  };
  const isScope1 = scope1_check ? isAllUndefinedScope1(scope1_check) : true;
  // console.log("isScope1", isScope1);

  //scope2
  const scope2_check = monthlyScopeData?.map((v) =>
    toJS(v?.scope2_emissions?.baseline_co2_emission)
  );
  // console.log("scope 2 check====>", toJS(scope2_check));
  const isAllUndefined = (array) => {
    return array.every((value) => value === undefined || value === 0);
  };
  const isScope2 = scope2_check ? isAllUndefined(scope2_check) : true;
  // console.log("isScope2", isScope2);

  //scope3
  const scope3_check = monthlyScopeData?.map((v) =>
    toJS(v?.scope3_emissions?.baseline_co2_emission)
  );
  // console.log("scope 2 check====>", toJS(scope3_check));
  const isAllUndefinedScope3 = (array) => {
    return array.every((value) => value === undefined || value === 0);
  };
  const isScope3 = scope3_check ? isAllUndefinedScope3(scope3_check) : true;
  // console.log("isScope3", isScope3);

  let unit = monthlyScopeData?.map((v) => toJS(v?.average_co2_Emission_unit));
  const checkUnit = unit.some((value) => value === "gCO2e/km") ? "gCO2e/km" : "gCO2e/hr";
  // console.log("unit =====>", checkUnit);
  

  const getInitialScope = () => {
    if (scope1CheckData !== 0) {
      return "scope1";
    } else if (scope2CheckData !== 0) {
      return "scope2";
    } else if (scope3CheckData !== 0) {
      return "scope3";
    } else {
      return "";
    }
  };
  const [checkScope, setCheckScope] = useState(getInitialScope());
  const [animationKey, setAnimationKey] = useState(0);
  const [image, setImage] = useState();
  const [lineVisibility, setLineVisibility] = useState({
    baseline: true,
    actual: true,
    average: true,
  });

  // const scope_img = "../../Assets/Images/scopeImage.png";

  const handleIconClick = () => {
    setImage(scope_image);
  };

  useEffect(() => {
    _handleScopeChange(checkScope);
    setImage(scope_image);
  }, [checkScope, scope_image]);

  const cardStyle = {
    boxShadow: "2px 4px 11px rgba(0,0,0,0.25)",
    padding: "10px",
    fontSize: "12px",
    borderRadius: "8px",
    height: "585px ",
    color: invertDark(theme),
    background: Colors[theme].background,
    marginLeft: 3,
  };

  
const generateChartData = (emissionData) => {
    if (
      !emissionData ||
      !Array.isArray(emissionData) ||
      emissionData.length === 0
    ) {
      return [];
    }
    const months = [
      { value: 0, label: "All" },
      { value: 1, label: "Jan" },
      { value: 2, label: "Feb" },
      { value: 3, label: "Mar" },
      { value: 4, label: "Apr" },
      { value: 5, label: "May" },
      { value: 6, label: "Jun" },
      { value: 7, label: "Jul" },
      { value: 8, label: "Aug" },
      { value: 9, label: "Sep" },
      { value: 10, label: "Oct" },
      { value: 11, label: "Nov" },
      { value: 12, label: "Dec" },
    ];
    return monthlyScopeData?.map((item, index) => {
      const monthLabel =
        months.find((month) => month.value === index + 1)?.label || "";
      const baselineEmission = emissionData[index]?.baseline_co2_emission;
      const currentEmission = emissionData[index]?.current_co2_emission;
      const averageEmission = emissionData[index]?.average_co2_Emission;

      return {
        month: monthLabel,
        baseline_co2_emission: baselineEmission,
        current_co2_emission: currentEmission,
        average_co2_Emission: averageEmission,
      };
    });
  };

  console.log("generate chart data ====>",generateChartData())
  const defaultChartData = [
    { month: "Jan", emission: "" },
    { month: "Feb", emission: "" },
    { month: "Mar", emission: "" },
    { month: "Apr", emission: "" },
    { month: "May", emission: "" },
    { month: "Jun", emission: "" },
    { month: "Jul", emission: "" },
    { month: "Aug", emission: "" },
    { month: "Sep", emission: "" },
    { month: "Oct", emission: "" },
    { month: "Nov", emission: "" },
    { month: "Dec", emission: "" },
  ];
  
  let chartData = [];
  switch (checkScope) {
    case "scope1":
      chartData = generateChartData(scope1_Emission);
      break;
    case "scope2":
      chartData = generateChartData(scope2_Emission);
      break;
    case "scope3":
      chartData = generateChartData(scope3_Emission);
      break;
      default:
        chartData = defaultChartData; 
        break;
  }
  useEffect(() => {
    _handleScopeChange(checkScope);
  }, [checkScope]);

  // console.log("chart Data =====>", chartData);

  const renderXAxisTick = ({ x, y, payload }) => {
    return (
      <text
        x={x}
        y={y}
        dy={16}
        fill={invertDark(theme)}
        fontSize={12}
        textAnchor="middle"
      >
        {payload.value}
      </text>
    );
  };

  const renderYAxisTick = ({ x, y, payload }) => {
    return (
      <text
        x={x}
        y={y}
        dy={16}
        fill={invertDark(theme)}
        fontSize={12}
        textAnchor="end"
      >
        {payload.value === "0" ? 0 : payload.value}
      </text>
    );
  };
  const renderYAxisTick2 = ({ x, y, payload }) => {
    return (
      <text
        x={x}
        y={y}
        dy={16}
        fill={invertDark(theme)}
        fontSize={12}
        textAnchor="start"
      >
        {payload.value === "0" ? 0 : payload.value}
      </text>
    );
  };
  const axisColor = theme === "dark" ? invertDark(theme) : "#333";
  const gridStrokeColor =
    theme === "dark" ? "rgba(255, 255, 255, 0.5)" : "rgba(0, 0, 0, 1)";
  const currentYear = new Date().getFullYear();
  const minimumYear = 2017;
  const [visibleYears, setVisibleYears] = useState([
    currentYear - 1,
    currentYear,
    currentYear + 1,
  ]);
  useEffect(() => {
    if (selectedYear > minimumYear && selectedYear < currentYear) {
      setVisibleYears([selectedYear - 1, selectedYear, selectedYear + 1]);
    }
  }, [selectedYear]);

  const handlePrevious = () => {
    // dashboardYear = null;
    if (visibleYears[0] > minimumYear) {
      const newYear = visibleYears[1] - 1;
      setSelectedYear(newYear);
    }
  };

  const handleNext = () => {
    // dashboardYear = null;
    if (visibleYears[1] < currentYear) {
      const newYear = visibleYears[1] + 1;
      setSelectedYear(newYear);
    }
  };

  const getButtonClass = (year) => {
    let baseClass = "btn-custom-individual-year";
    if (year === selectedYear) {
      if (theme === "dark") {
        baseClass = "btn-custom-individual-year";
      } else {
        baseClass = "btn-custom-individual-year-light";
      }
    } else {
      if (theme === "dark") {
        baseClass = "btn-custom-individual-year-inactive";
      } else {
        baseClass = "btn-custom-individual-year-light-inactive";
      }
    }
    return baseClass;
  };
  useEffect(() => {
    setAnimationKey((prevKey) => prevKey + 1);
  }, [checkScope]);

  const scopeDisableStyle = {
    zIndex: 0,
    cursor: "not-allowed",
    color: "rgba(255, 255, 255, 0.1)",
    background: "rgba(79, 92, 115, 0.6)",
  };

  const scopeStyle = {
    zIndex: 0,
    cursor: "pointer",
    // color: "#c6c6c6 !important",
    // background: "linear-gradient(65deg, #101841, #202b60)",
    color: "rgba(255, 255, 255, 0.3)",
    background: "#4F5C73",
  };

  const scopeActiveStyle = {
    color: "#c6c6c6 !important",
    background: "linear-gradient(65deg, #101841, #202b60)",
  };
  const scopeDataOptions = [
    { id: 0, label: "Baseline CO₂e Emission", value: "baseline_co2_emission" },
    { id: 1, label: "Actual CO₂e Emission", value: "actual_co2_emission" },
    {
      id: 2,
      label: "Average Emission Intensity",
      value: "average_co2_emission",
    },
  ];

  const _handleScopeCheckbox = (label) => {
    setLineVisibility((prevVisibility) => {
      switch (label) {
        case "Baseline CO₂e Emission":
          return { ...prevVisibility, baseline: !prevVisibility.baseline };
        case "Actual CO₂e Emission":
          return { ...prevVisibility, actual: !prevVisibility.actual };
        case "Average Emission Intensity":
          return { ...prevVisibility, average: !prevVisibility.average };
        default:
          return prevVisibility;
      }
    });
  };

  const CustomTooltip = ({ active, payload, label, lineVisibility }) => {
    // console.log("linevisibility", lineVisibility);
    if (active && payload && payload.length) {
      const item = payload[0].payload;
      return (
        <div
          className="custom-tooltip p-2 rounded"
          style={{
            background: theme === "dark" ? Colors.darkBlue : Colors.Dark[5],
            boxShadow: "0px 10px 10px rgba(0,0,0,0.1)",
            color: invertDark(theme),
          }}
        >
          <div className="label">Month : {item.month}</div>
          <hr className="my-0" />

          <div
            className="label"
            style={{
              color:
                checkScope === "scope1"
                  ? "#81D4FA"
                  : checkScope === "scope2"
                  ? "#64B5F6"
                  : checkScope === "scope3"
                  ? "#4369b9"
                  : "#81D4FA",
            }}
          >
            {`${t("Baseline CO₂e Emission")} : ${
              lineVisibility?.baseline
                ? item.baseline_co2_emission.toFixed(2)
                : "0"
            } ton`}
          </div>

          <div
            className="label"
            style={{
              color:
                checkScope === "scope1"
                  ? "#F1B04C"
                  : checkScope === "scope2"
                  ? "#ED9106"
                  : checkScope === "scope3"
                  ? "#D77103"
                  : "#F0B27A",
            }}
          >
            {`${t("Actual CO₂e Emission")} : ${
              lineVisibility?.actual
                ? item.current_co2_emission
                  ? item.current_co2_emission.toFixed(2)
                  : 0
                : "0"
            } ton`}
          </div>

          <div className="label" style={{ color: "#63B963" }}>
            {`${t("Average Emission Intensity ")} : ${
              lineVisibility?.average
                ? item.average_co2_Emission
                  ? item.average_co2_Emission.toFixed(2)
                  : 0
                : "0"
            } ${checkUnit}`}
          </div>
        </div>
      );
    }

    return null;
  };
  const CustomYAxisLabel = ({ viewBox, value }) => {
    const { x, y, width, height } = viewBox;
    const xPos = x + width / 0.9;
    const yPos = y + 190;

    return (
      <text
        x={xPos}
        y={yPos}
        transform={`rotate(-90, ${xPos}, ${yPos})`}
        textAnchor="middle"
        fill={invertDark(theme)}
        style={{ fontSize: 16 }}
      >
        {value}
      </text>
    );
  };
  const CustomY2AxisLabel = ({ viewBox, value }) => {
    const { x, y, width, height } = viewBox;
    const xPos = x + width / 6.2;
    const yPos = y + 210;

    return (
      <text
        x={xPos}
        y={yPos}
        transform={`rotate(-90, ${xPos}, ${yPos})`}
        textAnchor="middle"
        fill={invertDark(theme)}
        style={{ fontSize: 16 }}
      >
        {value}
      </text>
    );
  };

  return (
    <div>
      <div className="row mt-3">
        <div className="col-md-6">
          <div className="btn-group btn-group-sm me-2" role="group">
            <div
              type="button"
              className={`${
                checkScope === "scope1"
                  ? theme === "dark"
                    ? "btn-custom-individual-scope1"
                    : "btn-custom-individual-scope1-light"
                  : theme === "dark"
                  ? "btn-custom-individual-scope1-inactive"
                  : "btn-custom-individual-scope1-light-inactive"
              }`}
              style={
                toJS(scope1CheckData) === 0
                  ? scopeDisableStyle
                  : checkScope === "scope1"
                  ? scopeActiveStyle
                  : scopeStyle
              }
              onClick={() => {
                if (toJS(scope1CheckData) != 0) {
                  setCheckScope("scope1");
                }
              }}
            >
              {t("Scope 1")}
            </div>
            <div
              type="button"
              // style={}
              className={`${
                checkScope === "scope2"
                  ? theme === "dark"
                    ? "btn-custom-individual-scope2"
                    : "btn-custom-individual-scope2-light"
                  : theme === "dark"
                  ? "btn-custom-individual-scope2-inactive"
                  : "btn-custom-individual-scope2-light-inactive"
              }`}
              style={
                // toJS(scope2CheckData) === 0
                isScope2
                  ? scopeDisableStyle
                  : checkScope === "scope2"
                  ? scopeActiveStyle
                  : scopeStyle
              }
              onClick={() => {
                if (!isScope2) {
                  setCheckScope("scope2");
                }
              }}
            >
              {t("Scope 2")}
            </div>
            <div
              type="button"
              style={
                toJS(scope3CheckData) === 0
                  ? scopeDisableStyle
                  : checkScope === "scope3"
                  ? scopeActiveStyle
                  : scopeStyle
              }
              className={`${
                checkScope === "scope3"
                  ? theme === "dark"
                    ? "btn-custom-individual-scope3"
                    : "btn-custom-individual-scope3-light"
                  : theme === "dark"
                  ? "btn-custom-individual-scope3-inactive"
                  : "btn-custom-individual-scope3-light-inactive"
              }`}
              onClick={() => {
                if (toJS(scope3CheckData) != 0) {
                  setCheckScope("scope3");
                }
              }}
            >
              {t("Scope 3")}
            </div>
            <div
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              className="ms-2 align-items-center justify-content-center mt-1"
            >
              <FaInfoCircle
                style={{ cursor: "pointer" }}
                className={`${
                  theme === "dark"
                    ? "infocircleatDataimport"
                    : "infocircleatDataimport-light"
                }`}
                data-tip
                data-for="tooltip-example"
                onClick={handleIconClick}
              />
              <ViewScopeImage imgUrl={image} theme={theme} />
            </div>
          </div>
        </div>
        <div
          className="col-md-6 "
          style={{
            textAlign: windowWidth > 700 ? "end" : "start",
            marginTop: windowWidth > 700 ? "" : "10px",
          }}
        >
          <div className="btn-group btn-group-sm" role="group">
            <div
              type="button"
              style={{
                zIndex: 0,
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                padding: 7,
                color: "#c6c6c6",
                borderRadius: 3,
              }}
              onClick={handlePrevious}
              disabled={visibleYears[1] <= minimumYear}
            >
              <i className="fas fa-angle-left"></i>
            </div>
            {visibleYears.map((year) => (
              <div
                style={{
                  cursor:
                    year <= new Date().getFullYear() && year > minimumYear
                      ? "pointer"
                      : "not-allowed",
                }}
                type="bottom"
                key={year}
                className={getButtonClass(year)}
                disabled={
                  year <= new Date().getFullYear() && year > minimumYear
                }
                onClick={() => {
                  if (year <= new Date().getFullYear() && year > minimumYear) {
                    setSelectedYear(year);
                  }
                }}
              >
                {/* {selectedYear?selectedYear:year} */}
                {year}
              </div>
            ))}

            <div
              type="button"
              style={{
                zIndex: 0,
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                // background: Colors[theme].background,
                padding: 7,
                color: "#c6c6c6",
                borderRadius: 5,
              }}
              onClick={handleNext}
              // disabled={visibleYears[1] >= currentYear}
            >
              <i className="fas fa-angle-right"></i>
            </div>
          </div>
        </div>
      </div>
      <div style={cardStyle} className=" mt-0">
        <div className="text-end p-0 m-0">
          <ScopeCarbonSelector
            width={"265px"}
            theme={theme}
            options={scopeDataOptions}
            _handleScopeCheckbox={_handleScopeCheckbox}
          />
        </div>

        <div className="mt-4 mb-0 ">
          {loading || monthlyScopeData === null ? (
            <div
              className="d-flex justify-content-center align-items-center text-center "
              style={{ height: "400px" }}
            >
              <CO2_Loading />
            </div>
          ) : (
            <div>
              {/* <div className="row-12 d-flex mb-2">
                  <div className="col-md-6 text-start">
                    <span className="ms-4" style={{fontSize:15}}>CO2e Emission (ton)</span>
                  </div>
                  <div className="col-md-6 text-end" style={{fontSize:15}}>
                    <span>Average Emission Intensity (gCO2e/km)</span>
                  </div>
                </div> */}
              <ResponsiveContainer width="100%" height={480}>
                <LineChart
                  key={`line-chart-${animationKey}`}
                  height={300}
                  width={400}
                  data={toJS(chartData)}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 30,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid
                    stroke={gridStrokeColor}
                    strokeOpacity={0.27}
                    strokeDasharray="1 1"
                  />
                  <XAxis
                    dataKey="month"
                    stroke={axisColor}
                    className="mt-5 mb-5"
                    tick={renderXAxisTick}
                  />
                  <YAxis
                    stroke={axisColor}
                    orientation="left"
                    tick={renderYAxisTick}
                    label={
                      // value: "CO2e Emission (ton)",
                      <CustomY2AxisLabel value="CO2e Emission (ton)" />
                    }
                  />

                  <YAxis
                    stroke={axisColor}
                    orientation="right"
                    yAxisId="right"
                    tick={renderYAxisTick2}
                    label={
                      <CustomYAxisLabel value={`Average Emission Intensity (${checkUnit})`} />
                    }
                  />
                  <Tooltip
                    content={<CustomTooltip lineVisibility={lineVisibility} />}
                  />
                  <Legend
                    payload={[
                      {
                        value: t("Baseline CO₂e Emission (ton)"),
                        type: "square",
                        color: lineVisibility.baseline
                          ? checkScope === "scope1"
                            ? "#81D4FA"
                            : checkScope === "scope2"
                            ? "#64B5F6"
                            : checkScope === "scope3"
                            ? "#4369b9"
                            : "#81D4FA"
                          : "#cfcfcf",
                        style: { fontSize: "140px" },
                      },
                      {
                        value: t("Actual CO₂e Emission (ton)"),
                        type: "square",
                        color: lineVisibility.actual
                          ? checkScope === "scope1"
                            ? "#F1B04C"
                            : checkScope === "scope2"
                            ? "#ED9106"
                            : checkScope === "scope3"
                            ? "#D77103"
                            : "#F0B27A"
                          : "#cfcfcf",
                      },
                      {
                        value: `Average Emission Intensity (${checkUnit})`,
                        type: "square",
                        color: lineVisibility.average ? "#63B963" : "#cfcfcf",
                      },
                    ]}
                  />
                  <Line
                    strokeWidth={2}
                    type="monotone"
                    dataKey="baseline_co2_emission"
                    stroke={
                      checkScope === "scope1"
                        ? "#81D4FA"
                        : checkScope === "scope2"
                        ? "#64B5F6"
                        : checkScope === "scope3"
                        ? "#4369b9"
                        : "#81D4FA"
                    }
                    name={t("Baseline Emission")}
                    isAnimationActive={true}
                    animationBegin={0}
                    animationDuration={2000}
                    hide={!lineVisibility.baseline}
                  />
                  <Line
                    strokeWidth={2}
                    type="monotone"
                    dataKey="current_co2_emission"
                    stroke={
                      checkScope === "scope1"
                        ? "#F1B04C"
                        : checkScope === "scope2"
                        ? "#ED9106"
                        : checkScope === "scope3"
                        ? "#D77103"
                        : "#F0B27A"
                    }
                    name={t("Current Emission")}
                    isAnimationActive={true}
                    animationBegin={0}
                    animationDuration={1500}
                    hide={!lineVisibility.actual}
                  />
                  <Line
                    strokeWidth={2}
                    type="monotone"
                    dataKey="average_co2_Emission"
                    stroke="#63B963"
                    name={t("Average Emission")}
                    isAnimationActive={true}
                    animationBegin={0}
                    animationDuration={1500}
                    hide={!lineVisibility.average}
                    yAxisId="right"
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default inject("store")(observer(ScopeCarbonChart));
