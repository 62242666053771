import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { CSVLink } from "react-csv";
import { Cookies } from "react-cookie";
// import { Button } from "react-bootstrap";
import { ShowInfoTooltips } from "./ShowInfoTooltips";
import { UploadCSVFileModal } from "./UploadCSVFileModal";
import { VehicleExistModal } from "./VehicleExistModal";
import { FaInfoCircle } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";

export const SampleDownloadCSV = inject("store")(
  observer((props) => {
    const { t } = useTranslation("data_import");
    const { theme, showInfo } = props;
    const { role } = props.store.common;
    const { companyList } = props;
    // const cookies = new Cookies();
    // const companyName = cookies.get("companyName");

    // console.log('companyName==>', companyName)

    const {
      showTable,
      vehicles,
      handleTableChange,
      vTripHeaders,
      handleTripCsvExport,
      handleVehicleCsvExport,
      handleEngineHoursExport,
      handleManualEntryExport,
      vVehicleProfile,
      vEngineHours,
      manualEntry,
      handleGetTableNumber,
      companyName,
    } = props.store.dataImport;

    // const [showInfo, setShowInfo] = useState(false);
    // const _handleClose = (e) => {
    //   setShowInfo(false);
    // };

    // const { role, showInfo, _handleClose, showTable, theme, cardStyle } = props;
    const [isTooltipVisible, setTooltipVisible] = useState(false);
    const [isModalVisiable, setIsModalVisiable] = useState(false);
    // console.log('modal ======>', isModalVisiable);

    const handleIconClick = () => {
      setTooltipVisible(true);
    };

    const handleTooltipClose = () => {
      setTooltipVisible(false);
    };

    // const [fileName, setFileName] = React.useState("");

    // const [open, setOpen] = React.useState(false);
    // const handleOpenUploadCSVfileModal = () => setOpen(!open);
    // const handleCloseUploadCSVfileModal = () => setOpen(!open);

    const cardStyle = {
      background: Colors[theme]?.background,
      boxShadow: "2px 4px 11px rgba(0,0,0,0.25)",
      padding: "10px",
      width: "420px",
      borderRadius: "15px",
      fontSize: "12px",
      color: Colors.Dark[theme === "dark" ? 4 : 1],
      height: "145px",
      transitionDuration: "0.1s",
    };

    const headerStyle = {
      color: "red",
      fontWeight: "bold",
    };

    useEffect(() => {
      handleTripCsvExport();
      handleVehicleCsvExport();
      handleEngineHoursExport();
      handleManualEntryExport();
      handleGetTableNumber();
    }, []);

    // console.log("fjajdlfjaljfdkljfklaj",role === "super_admin")

    return (
      <>
        <div className="d-flex flex-wrap row align-items-center my-2">
          <div className="d-flex col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xs-12">
            {role === "super_admin" || companyName === "Volvo" ? (
              <div className="btn-group btn p-0" role="group">
                <div
                  type="button"
                  // className={`btn btn-outline-primary  btn-1 px-1 ${showTable === "table1" ? "active" : ""}`}

                  className={`${
                    showTable === "table1"
                      ? theme === "dark"
                        ? "btn-custom-table1"
                        : "btn-custom-table1-light"
                      : theme === "dark"
                      ? "btn-custom-table1-inactive"
                      : "btn-custom-table1-light-inactive"
                  }`}
                  style={{ width: 120 }}
                  onClick={() => handleTableChange("table1")}
                >
                  {t("Unit Profile")}
                </div>
                <div
                  type="button"
                  // className={`btn btn-outline-primary btn-2 px-1 ${showTable === "table2" ? "active" : ""}`}
                  className={`${
                    showTable === "table2"
                      ? theme === "dark"
                        ? "btn-custom-table2"
                        : "btn-custom-table2-light"
                      : theme === "dark"
                      ? "btn-custom-table2-inactive"
                      : "btn-custom-table2-light-inactive"
                  }`}
                  style={{ width: 120 }}
                  onClick={() => handleTableChange("table2")}
                >
                  {t("Trips")}
                </div>
                <div
                  type="button"
                  // className={`btn btn-outline-primary btn-2 px-1 ${showTable === "table3" ? "active" : ""}`}
                  className={`${
                    showTable === "table3"
                      ? theme === "dark"
                        ? "btn-custom-table3"
                        : "btn-custom-table3-light"
                      : theme === "dark"
                      ? "btn-custom-table3-inactive"
                      : "btn-custom-table3-light-inactive"
                  }`}
                  style={{ width: 120 }}
                  onClick={() => handleTableChange("table3")}
                >
                  {t("Engine Hours")}
                </div>
                <div
                  type="button"
                  // className={`btn btn-outline-primary btn-2 px-1 ${showTable === "table4" ? "active" : ""}`}
                  className={`${
                    showTable === "table4"
                      ? theme === "dark"
                        ? "btn-custom-table4"
                        : "btn-custom-table4-light"
                      : theme === "dark"
                      ? "btn-custom-table4-inactive"
                      : "btn-custom-table4-light-inactive"
                  }`}
                  style={{ width: 120 }}
                  onClick={() => handleTableChange("table4")}
                >
                  {t("Manual Entry")}
                </div>
              </div>
            ) : (
              <div
                type="button"
                // className={`btn btn-primary btn-2 px-1`}
                className={`${
                  theme === "dark" ? "btn-custom" : "btn-custom-light"
                }`}
                style={{ width: 120 }}
                // onClick={() => handleTableChange("table4")}
              >
                {t("Manual Entry")}
              </div>
            )}
          </div>

          <div className="d-flex col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xs-12 my-1 justify-content-lg-end align-items-center">
            {/*Don't delete*/}
            {/*
            <label className="btn btn-outline-primary btn-md me-1">
              <input
                type="file"
                accept={".csv"}
                // data-bs-toggle="modal"
                // data-bs-target={
                // //  "#confirmuploadModal"
                // fileName.length >0 ?"#confirmuploadModal":""
                // }
                // data-toggle="modal"
                // data-target=".bd-example-modal-lg"
                style={{ display: "none" }}
                onChange={
                  showTable === "table1"
                    ? (e) => handleImportVProfilesCSV(e)
                    : showTable === "table2"
                      ? (e) => handleImportVTripCSV(e)
                      : showTable === "table3"
                        ? (e) => handleImportVEngineHourCSV(e)
                        : (e) => handleImportManualEntyDataCSV(e)
                }
              // onChange={(e) => handleOpenUploadmodal(e)}
              // onClick={(e) => handleOpenUploadmodal(e)}

              />
              <i className="fa fa-upload" ></i> Choose a File
            </label>

              */}
            {/* {showModal ? <UploadModal /> : <></>} */}

            <span>
              <button
                type="button"
                className={`${
                  theme === "dark"
                    ? "btn btn-custom-upload"
                    : "btn btn-custom-upload-light"
                }`}
                data-bs-toggle="modal"
                data-bs-target="#uploadCSVFileModal"
              >
                <i className="fa fa-upload me-2"></i> {t("Upload")}
              </button>
            </span>

            {/* {isModalVisiable && ( */}
            <UploadCSVFileModal
              role={role}
              companyName={companyName}
              theme={theme}
              showTable={showTable}
            />
            {/* )} */}

            <CSVLink
              className={`${
                theme === "dark"
                  ? "btn btn-custom-upload btn-md mx-1"
                  : "btn btn-custom-upload-light btn-md mx-1"
              }`}
              data={[]}
              style={{ headerStyle }}
              headers={
                showTable === "table1" &&
                (role === "super_admin" || companyName === "Volvo")
                  ? vVehicleProfile
                  : showTable === "table2" &&
                    (role === "super_admin" || companyName === "Volvo")
                  ? vTripHeaders
                  : showTable === "table3" &&
                    (role === "super_admin" || companyName === "Volvo")
                  ? vEngineHours
                  : manualEntry
              }
              filename={
                showTable === "table1" &&
                (role === "super_admin" || companyName === "Volvo")
                  ? "CSV_Template_For_UnitProfile.csv"
                  : showTable === "table2" &&
                    (role === "super_admin" || companyName === "Volvo")
                  ? "CSV_Template_For_Trip.csv"
                  : showTable === "table3" &&
                    (role === "super_admin" || companyName === "Volvo")
                  ? "CSV_Template_For_EngineHours.csv"
                  : "CSV_Template_For_ManualEntry.csv"
              }
            >
              <i className="fa fa-download me-2"> </i>{" "}
              {t("Sample CSV Download for")}{" "}
              {showTable === "table1" &&
              (role === "super_admin" || companyName === "Volvo")
                ? t("Unit Profile")
                : showTable === "table2" &&
                  (role === "super_admin" || companyName === "Volvo")
                ? t("Trips")
                : showTable === "table3" &&
                  (role === "super_admin" || companyName === "Volvo")
                ? t("Engine Hours")
                : t("Manual Entry")}
            </CSVLink>

            <VehicleExistModal
              theme={theme}
              vehicles={vehicles}
              showTable={showTable}
            />

            {/* <span className="">
              {showTable === "table1" 
                ? (
                  <i
                    className="fas fa-info-circle mx-2"
                    onClick={() => setShowInfo(true)}
                    onClickCapture={() => setShowInfo(true)}
                    style={{
                      cursor: "pointer",
                      color: "#0d6efd",
                    }}
                  />
                ) : showTable === "table2" 
                  ? (
                    <i
                      className="fas fa-info-circle mx-2"
                      onClick={() => setShowInfo(true)}
                      onClickCapture={() => setShowInfo(true)}
                      style={{
                        cursor: "pointer",
                        color: "#0d6efd",
                      }}
                    />
                  ) : showTable === "table3" 
                    ? (
                      <i
                        className="fas fa-info-circle mx-2"
                        onClick={() => setShowInfo(true)}
                        onClickCapture={() => setShowInfo(true)}
                        style={{
                          cursor: "pointer",
                          color: "#0d6efd",
                        }}
                      />
                    ) : (
                      <i
                        className="fas fa-info-circle mx-2"
                        onClick={() => setShowInfo(true)}
                        onClickCapture={() => setShowInfo(true)}
                        style={{
                          cursor: "pointer",
                          color: "#0d6efd",
                        }}
                      />
                    )}

            </span>
            <ShowInfo
              role={role}
              showInfo={showInfo}
              showTable={showTable}
              _handleClose={_handleClose}
              cardStyle={cardStyle}
              theme={theme}
              Colors={Colors}
              handleTableChange={handleTableChange}
            /> */}

            <div>
              {/* Icon */}
              <FaInfoCircle
                className={`${
                  theme === "dark"
                    ? "infocircleatDataimport"
                    : "infocircleatDataimport-light"
                }`}
                data-tip
                data-for="tooltip-example"
                onClick={handleIconClick}
                companyList={companyName}
                role={role}
              />

              {/* Tooltip */}
              {isTooltipVisible && (
                <div
                  style={{
                    ...cardStyle,
                    position: "fixed",
                    zIndex: 10,
                    top: 56,
                    right: 10,
                    maxHeight: 145,
                    maxWidth: 400,
                    display: showInfo !== true,
                    borderRadius: 15,
                  }}
                >
                  <div className="mx-1">
                    <div className="row">
                      <span
                        className="col"
                        style={{
                          color: theme === "dark" ? "#fff" : "rgb(48, 48, 48)",
                          fontSize: 19,
                        }}
                      >
                        {t("Tips for Data Import")}
                      </span>
                      <span className="d-flex justify-content-end col">
                        <i
                          style={{
                            cursor: "pointer",
                            fontSize: 20,
                            color:
                              theme === "dark" ? "#fff" : "rgb(48, 48, 48)",
                          }}
                          onClick={handleTooltipClose}
                          className="bi bi-x"
                        ></i>
                      </span>
                    </div>

                    <div className="rounded" style={{ color: "#fff" }}>
                      <div
                        className="mx-2"
                        style={{
                          color:
                            theme === "dark" ? "rgb(195 150 40)" : "#0d6efd",
                          maxHeight: "100px",
                          maxWidth: "370px",
                          position: "relative",
                          overflow: "scroll",
                        }}
                      >
                        {showTable === "table1" &&
                        (role === "super_admin" || companyName === "Volvo") ? (
                          <>
                            <h6>
                              {t("These field must include for Unit Profile")}
                            </h6>
                            <ul>
                              <li>Date format : DD/MM/YYYY</li>
                              <li>grouping</li>
                              <li>company</li>
                              <li>plate_number</li>
                              <li>brand</li>
                              <li>model</li>
                              <li>primary_fuel_type</li>
                              <li>vin_number</li>
                              <li>oem_baseline_emission</li>
                            </ul>
                          </>
                        ) : showTable === "table2" &&
                          (role === "super_admin" ||
                            companyName === "Volvo") ? (
                          <>
                            <h6>{t("These field must include for Trips")}</h6>
                            <ul>
                              <li>Date format : DD/MM/YYYY</li>
                              <li>grouping</li>
                              <li>start_time</li>
                              <li>to_time</li>
                              <li>trip_mileage</li>
                              <li>consumed</li>
                            </ul>
                          </>
                        ) : showTable === "table3" &&
                          (role === "super_admin" ||
                            companyName === "Volvo") ? (
                          <>
                            <h6>
                              {t("These field must include for Engine Hours")}
                            </h6>
                            <ul>
                              <li>Date format : DD/MM/YYYY</li>
                              <li>grouping</li>
                              <li>start_time</li>
                              <li>in_motion</li>
                              <li>idling</li>
                            </ul>
                          </>
                        ) : (
                          <>
                            <h6>
                              {t("These field must include for Manual Entry")}
                            </h6>
                            <ul>
                              <li>Date format : DD/MM/YYYY</li>
                              <li>date</li>
                              <li>license_plate</li>
                              <li>trip mileage (optional)</li>
                              <li>consumed (optional)</li>
                            </ul>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <Tooltip
                id="tooltip-example"
                role={role}
                companyName={companyName}
              />
            </div>
          </div>
        </div>
      </>
    );
  })
);

// const ShowInfo = (props) => {
//   const { showInfo, _handleClose, theme, cardStyle, showTable, role } = props;

//   return (
//     <>

//       {showInfo === true && showTable === "table1"  ? (
//         <ShowInfoTooltips
//           role={role}
//           cardStyle={cardStyle}
//           theme={theme}
//           _handleClose={_handleClose}
//           showTable={showTable}
//         />
//       ) : showInfo === true && showTable === "table2"  ? (
//         <ShowInfoTooltips
//           role={role}
//           cardStyle={cardStyle}
//           theme={theme}
//           _handleClose={_handleClose}
//           showTable={showTable}
//         />
//       ) : showInfo === true && showTable === "table3"  ? (
//         <ShowInfoTooltips
//           role={role}
//           cardStyle={cardStyle}
//           theme={theme}
//           _handleClose={_handleClose}
//           showTable={showTable}
//         />
//       ) : (
//         <ShowInfoTooltips
//           role={role}
//           cardStyle={cardStyle}
//           theme={theme}
//           _handleClose={_handleClose}
//           showTable={showTable}
//         />
//       )
//       }

//     </>
//   );
// };
