import { useTranslation } from "react-i18next";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import IOT from "../../Assets/Images/icons/iot.png";
import Store from "../../Store";
export const DataSource = (props) => {
  const { t } = useTranslation("dashboard");
  const { isAds } = Store.common;
  const theme = localStorage.getItem("theme");
  const { totalFuelData } = props;
  const cardStyle = {
    background: Colors[theme].background,
    boxShadow: "2px 4px 11px rgba(0,0,0,0.25)",
    padding: "10px",
    fontSize: "12px",
    borderRadius: "15px",
    color: invertDark(theme),
  };
  return (
    <div style={cardStyle}>
      <span style={{ fontWeight: 800 }}>{t("Data Source")}(s)</span>
      <div
        style={{ background: Colors.Dark[4], height: "1px", opacity: 0.1 }}
        className="my-1"
      ></div>
      <div className="d-flex flex-row justify-content-between py-1">
        <div>
          <i className="fas fa-satellite" style={{ color: Colors.Dark[2] }} />
          <span className="ps-1">{t("GPS Tracker")}</span>
        </div>

        <div>
          {" "}
          {totalFuelData.enableGPS === 0 ? (
            <i style={{ color: Colors.high }} className="fas fa-times pe-1"></i>
          ) : (
            <i className="fa fa-check pe-1" style={{ color: Colors.low }} />
          )}{" "}
        </div>
      </div>

      <div className="d-flex flex-row justify-content-between py-1">
        <div>
          <i
            className="fas fa-network-wired"
            style={{ color: Colors.Dark[2] }}
          />
          <span className="ps-1">{t("CANBus Adaptor")}</span>
        </div>

        <div>
          {" "}
          {totalFuelData.enableCANbus === 0 ? (
            <i style={{ color: Colors.high }} className="fas fa-times pe-1"></i>
          ) : (
            <i className="fa fa-check pe-1" style={{ color: Colors.low }} />
          )}{" "}
        </div>
      </div>
    </div>
  );
};

const complianceData = [
  { id: 1, icon: "fas fa-satellite", name: "GPS Tracker", status: "Passed" },
  {
    id: 2,
    icon: "fas fa-network-wired",
    name: "CANBus Adaptor",
    status: "Passed",
  },
  // { id: 4, icon: undefined, name: "IoT Sensor", status: "Passed" },
];

const adsComplianceData = [
  { id: 1, icon: "fas fa-city", name: "BMS System", status: "Passed" },
];
