import React from 'react'
import { useState } from 'react';
import { inject, observer } from "mobx-react";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import autoTable from "jspdf-autotable";
import { invertDark } from '../../Assets/Config/color.config';
import { Colors } from '../../Assets/Config/color.config'
import * as XLSX from 'xlsx';
import * as FileSaver from "file-saver";
import { Button } from 'react-bootstrap';
import dayjs from "dayjs";
import { Cookies } from 'react-cookie';


export const TableReport = inject("store")(
    observer((props) => {

        const cookies = new Cookies()

        const companyName = cookies.get("companyName");

        const { theme, selectedYear } = props;
        const { selectedMonthlyDetail, unitsTab} = props.store.trackAndTrace;

        console.log("selected_monthly -->", selectedMonthlyDetail)


        const rows = selectedMonthlyDetail?.units?.map((data) => {
            // console.log("data =====>",toJS(data))
            const selectedmonthlydetailUnits = {
                plate_number: data.plate_number ? data.plate_number : "N/A",
                consumption:
                    data.type === "battery"
                        ? Number(data.battery_consumed).toFixed(2) + " kWh"
                        : Number(data.fuel_consumed).toFixed(2) + " L",
                mileage:
                    data.trip_mileage === null
                        ? "0 km"
                        : Number(data.trip_mileage).toFixed(2) + " km",
                co2e_emission: Number(data.carbon_emission).toFixed(2) + " kgCO2e",
                baseline_co2e_emission:
                    Number(data.baseline_emission).toFixed(2) + " kgCO2e",
                baseline_type: data.baselineEmissionType,
            };

            return selectedmonthlydetailUnits;
        });


        const [showPreview, setShowPreview] = useState(false);

        var wsTableData = rows && XLSX.utils.json_to_sheet(rows);
        var csvTableData = XLSX.utils.sheet_to_csv(wsTableData);

        function s2ab(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
            return buf;
        }

        const generatePDF = () => {

            const pdf = new jsPDF("l", undefined, "a4");

            if (!rows || rows.length === 0) {
                console.error('No data available for PDF generation');
                return;
            }

            pdf.setFontSize(20);
            pdf.setTextColor(Colors.Dark[3]);

            pdf.text(`Monthly Emission Detail Report for ${dayjs().month(selectedMonthlyDetail?.month - 1).format("MMMM")}-${selectedYear}`, 15, 30);


            autoTable(pdf, {
                styles: { fontSize: 10 },
                startY: 50,
                body: rows,
                columns: [
                    { header: "Plate Number", dataKey: "plate_number" },
                    { header: "Consumption", dataKey: "consumption" },
                    { header: "Mileage", dataKey: "mileage" },
                    { header: "Co2e Emission", dataKey: "co2e_emission" },
                    { header: "Baseline Co2e Emission", dataKey: "baseline_co2e_emission" },
                    { header: "Baseline Emission Type", dataKey: "baseline_type" },
                ],
            });
            unitsTab === "own" ? pdf.save(`${companyName}-${dayjs().month(selectedMonthlyDetail?.month - 1).format("MMMM")}_${selectedYear}_Own-Units_Monthly_Emission_Detail_Report.pdf`)
            :
            pdf.save(`${companyName}-${dayjs().month(selectedMonthlyDetail?.month - 1).format("MMMM")}_${selectedYear}_All-Units_Monthly_Emission_Detail_Report.pdf`)

        };

        const downloadXLSX = () => {
            FileSaver.saveAs(
                new Blob(
                    [
                        s2ab(csvTableData),
                    ],
                    { type: "application/octet-stream" }
                ),
                unitsTab === 'own' ? `${companyName}-${dayjs().month(selectedMonthlyDetail?.month - 1).format("MMMM")}_${selectedYear}_Own-Units_Monthly_Emission_Detail_Report.csv`
                : 
                `${companyName}-${dayjs().month(selectedMonthlyDetail?.month - 1).format("MMMM")}_${selectedYear}_All-Units_Monthly_Emission_Detail_Report.csv`
            );
        };
        return (
            <div
                className="modal fade"
                id="tablereport"
                tabIndex="-1"
                aria-labelledby="tablereport"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content" style={{

                    }}>
                        <div className="modal-header">
                            <h5 className="modal-title">Monthly Emission Details Report for {dayjs()
                                .month(selectedMonthlyDetail?.month - 1)
                                .format("MMMM")} - {selectedYear}</h5>
                            <button type="button" className="btn-close" aria-label="Close" data-bs-dismiss="modal"></button>
                        </div>
                        <div className="modal-body" style={{ overflowY: 'scroll', height: '550px' }}>
                            {/* {showPreview && ( */}
                            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                <thead>
                                    <tr style={{ border: '1px solid gray' }}>
                                        {rows && rows[0] && Object.keys(rows[0]).map((key, index) => (
                                            <th style={{ border: '1px solid gray', padding: '30px 8px', textAlign: 'left' }} key={index}>{key.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase())}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {rows?.map((row, rowIndex) => (
                                        <tr key={rowIndex} style={{ border: '1px solid gray' }}>
                                            {Object.values(row).map((value, cellIndex) => (
                                                <td style={{ border: '1px solid gray', padding: '8px' }} key={cellIndex}>{value}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            {/* )} */}
                        </div>
                        <div className="modal-footer">
                            <Button
                                type="button"
                                onClick={() => generatePDF()}
                                style={{
                                    background: "#4c5d75",
                                    color: "white",
                                    border: "none",
                                    padding: "8px"
                                }}
                                className="btn btn-sm detail-report-guide"
                            >
                                Download PDF Report
                            </Button>

                            <Button
                                type="button"
                                onClick={() => downloadXLSX()}
                                style={{
                                    background: "#4c5d75",
                                    color: "white",
                                    border: "none",
                                    padding: "8px"
                                }}
                                className="btn btn-sm detail-report-guide"
                            >
                                Download XLSX Report
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }))

