import { action, makeObservable, observable, runInAction, toJS } from "mobx";
import {
  fetchFuelHistory,
  fetchFuelRecord,
  fetchVehiclesForDriver,
  fetchVehicleInfo,
  fetchVehicleInfoNew,
  fetchOdometerStartEnd,
} from "../network/driverAPI";
import { Cookies } from "react-cookie";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import { FuelRecordValidation } from "../util/formValidation";
import Tesseract from "tesseract.js";
import { createWorker } from "tesseract.js";
import React from "react";
import preprocessImage from "./preprocess";
import Common from "./common";
import { UploadFile } from "../network/fetchDataEntryAPI";
import Swal from "sweetalert2";
import * as pdfjsLib from "pdfjs-dist";
import "pdfjs-dist/build/pdf.worker.entry";
import moment from "moment";

class Driver {
  cookies = new Cookies();
  token = this.cookies.get("accessToken");
  vehicleId = this.cookies.get("vehicleId");
  companyId = this.token && jwt_decode(this.token).companyId;
  userId = this.token && jwt_decode(this.token).userId;
  vehicles = null;
  vehicleType = null;
  vehicleOption = [{ value: "0", label: "All" }];
  vehicleHistoryOption = [{ value: "0", label: "All" }];
  filterVehicles = [];
  selectedVehicle = null;
  vehicle = null;
  selectedVehicleInfo = null;
  vehicleTypeOption = [
    { value: "ev", label: "EV" },
    { value: "fuel", label: "ICE" },
  ];
  forAll = { value: "0", label: "All" };
  month = 0;
  year = 0;
  yearOption = [
    { value: 0, label: "All" },
    { value: 2018, label: 2018 },
    { value: 2019, label: 2019 },
    { value: 2020, label: 2020 },
    { value: 2021, label: 2021 },
    { value: 2022, label: 2022 },
    { value: 2023, label: 2023 },
    { value: 2024, label: 2024 },
    { value: 2025, label: 2025 },
    { value: 2026, label: 2026 },
  ];
  monthOption = [
    { value: 0, label: "All" },
    { value: 1, label: "Janurary" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];

  fuelData = {
    userId: this.userId,
    vehicleId: this.vehicleId,
    fuelType: "Diesel",
    quantity: "",
    amount: "",
    fuelRecords: null,
    imgStartOfMonth: null,
    imgEndOfMonth: null,
    recordDateTime: new Date(),
    consumed: "",
    odometerStart: "",
    odometerEnd: "",
    mileage: "",
    vuid: "",
    id: "",
  };

  fuelHistoryData = [];
  filterFuelHistoryData = [];
  uploadImage = "";
  photo = "";
  selectedHistoryVehicles = { value: "0", label: "All" };
  selectedDriverId = 0;
  fuelHistoryDataForDriver = [];

  consumedStr = "";
  consumedInfo = "";
  fileFuelRecords = null;
  fileStart = "";
  fileEnd = "";
  fileFuel = "";
  fileStartName = "";
  fileEndName = "";
  fileFuelName = "";
  uploadFileType = "";

  odometerStartStr = "";
  odometerEndStr = "";
  totalMileageStr = "";

  odometerStartInfo = "";
  odometerEndInfo = "";
  tripMilageInfo = "";

  fileStartOfMonth = null;
  fileEndOfMonth = null;

  text = "";
  filefueltype = "";
  fuelname = "";
  quantity = "";
  totalamount = "";
  pricePerL = "";
  invoiceNo = "";
  date = "";
  typeStatus = "Not Applicable";
  fuelStatus = "Not Applicable";
  canvasRef = React.createRef();
  imageRef = React.createRef();
  convertText = "";
  showAlert = false;
  istimeout = false;
  setviewimage = [];

  fuelTypesDict = [
    { id: 1, filefueltype: "Petrol", name: "95", oriTxt: "95" },
    { id: 2, filefueltype: "Gas", name: "CNG", oriTxt: "CNG" },
    { id: 3, filefueltype: "Gas", name: "LNG", oriTxt: "LNG" },
    { id: 4, filefueltype: "Petrol", name: "LPG", oriTxt: "LPG" },
    { id: 5, filefueltype: "Petrol", name: "92", oriTxt: "92" },
    { id: 6, filefueltype: "Diesel", name: "HSD", oriTxt: "HSD" },
    { id: 7, filefueltype: "Diesel", name: "LOD", oriTxt: "LOD" },
    { id: 8, filefueltype: "Gas", name: "Gas", oriTxt: "Gas" },
    { id: 9, filefueltype: "Petrol", name: "Petrol", oriTxt: "Petrol" },
    { id: 10, filefueltype: "Diesel", name: "Diesel", oriTxt: "Diesel" },
    { id: 11, filefueltype: "Petrol", name: "Regular", oriTxt: "regular" },
    { id: 12, filefueltype: "Petrol", name: "Midgrade", oriTxt: "midgrade" },
    { id: 13, filefueltype: "Petrol", name: "Premium", oriTxt: "premium" },
    { id: 14, filefueltype: "Petrol", name: "Plus", oriTxt: "plus" },
    { id: 15, filefueltype: "Petrol", name: "Octane", oriTxt: "Octane" },
    {
      id: 16,
      filefueltype: "Petrol",
      name: "FUELSAVE 95",
      oriTxt: "FUELSAVE 95",
    },
    {
      id: 17,
      filefueltype: "Petrol",
      name: "Premium 95",
      oriTxt: "Premium 35",
    },
  ];

  fuelVolumesDict = [
    { id: 1, quantity: "Gal", unit: "gal" },
    { id: 2, quantity: "Ltr", unit: "ltr" },
    { id: 3, quantity: "Gallons", unit: "gal" },
    { id: 4, quantity: "Liters", unit: "ltr" },
    { id: 5, quantity: "Litres", unit: "ltr" },
    { id: 6, quantity: "Volume", unit: "" },
    { id: 7, quantity: "Premium 35 with T/", unit: "ltr" },
  ];

  fuelCostsDict = [
    { id: 1, amount: "Amount" },
    { id: 2, amount: "Total" },
    { id: 3, amount: "Total sale" },
    { id: 4, amount: "FUEL SALE" },
    { id: 5, amount: "Total Cost" },
  ];

  confusedformat = [
    { id: 1, amount: "Total pts" },
    { id: 2, amount: "total GST" },
    { id: 3, amount: "GST Rate" },
    { id: 4, amount: "PETROLEUM (PTE)" },
    { id: 5, amount: "Invoice due date" },
  ];

  pricePerLDict = [
    { id: 1, text: "@", format: "L" },
    { id: 2, text: "RATE", format: "" },
    { id: 3, text: "PRICE/G", format: "" },
  ];

  invoiceNoDict = [
    { id: 1, text: "CRN" },
    { id: 2, text: "SN" },
    { id: 3, text: "Bill No" },
    { id: 4, text: "INVOICE NO" },
  ];

  dateDict = [{ id: 1, text: "Date" }];

  evTypesDict = [
    { id: 1, filefueltype: "Battery", name: "DC", oriTxt: "DC" },
    {
      id: 2,
      filefueltype: "Battery",
      name: "Electricity",
      oriTxt: "Electricity",
    },
    { id: 3, filefueltype: "Battery", name: "Energy", oriTxt: "Energy" },
    { id: 4, filefueltype: "Battery", name: "Battery", oriTxt: "Battery" },
  ];

  evCostsDict = [
    { id: 1, amount: "Amount" },
    { id: 2, amount: "Total sale" },
    { id: 3, amount: "GST" },
    { id: 4, amount: "Total Cost" },
  ];

  evVolumesDict = [
    { id: 1, quantity: "Electricity consumed", unit: "kwh" },
    { id: 2, quantity: "Total Energy", unit: "kwh" },
    { id: 3, quantity: "Energy Usage", unit: "kwh" },
  ];

  evpricePerkwhDict = [
    { id: 1, text: "Public rate" },
    { id: 2, text: "/kwh" },
  ];

  evinvoiceNoDict = [
    { id: 1, text: "CRN" },
    { id: 2, text: "SN" },
    { id: 3, text: "Bill No" },
    { id: 4, text: "INVOICE NO" },
    { id: 5, text: "Text:" },
  ];

  evDateDict = [
    { id: 1, text: "Date" },
    { id: 2, text: "Time" },
  ];

  evConfusedformat = [
    { id: 1, amount: "Total pts" },
    { id: 2, amount: "total GST" },
    { id: 3, amount: "GST Rate" },
    { id: 4, amount: "UEN/GST No" },
    { id: 5, amount: "Invoice due date" },
    { id: 6, amount: "Total Time" },
    { id: 7, amount: "Timezone" },
  ];

  imageStatusOne = "";
  imageStatusTwo = "";
  imageStatusThree = "";
  imageStatusFour = "";
  imageStatusFive = "";

  fuelHistory = false

  odometerStartEnd = ''

  constructor() {
    makeObservable(this, {
      vehicleType: observable,
      vehicles: observable,
      vehicleOption: observable,
      filterVehicles: observable,
      selectedVehicle: observable,
      vehicleTypeOption: observable,
      monthOption: observable,
      fuelData: observable,
      fuelHistoryData: observable,
      month: observable,
      year: observable,
      selectedVehicleInfo: observable,
      vehicleHistoryOption: observable,
      filterFuelHistoryData: observable,
      selectedHistoryVehicles: observable,
      fuelHistoryDataForDriver: observable,
      selectedDriverId: observable,
      odometerStartEnd: observable,
      setviewimage: observable,

      consumedStr: observable,
      fileFuelRecords: observable,
      fileStart: observable,
      fileEnd: observable,
      fileFuel: observable,
      fileStartName: observable,
      fileEndName: observable,
      fileFuelName: observable,
      uploadFileType: observable,
      odometerStartStr: observable,
      odometerEndStr: observable,
      totalMileageStr: observable,
      odometerStartInfo: observable,
      odometerEndInfo: observable,
      tripMilageInfo: observable,
      fileStartOfMonth: observable,
      fileEndOfMonth: observable,
      text: observable,
      filefueltype: observable,
      fuelname: observable,
      quantity: observable,
      totalamount: observable,
      pricePerL: observable,
      invoiceNo: observable,
      date: observable,
      typeStatus: observable,
      fuelStatus: observable,
      canvasRef: observable,
      imageRef: observable,
      convertText: observable,
      fuelTypesDict: observable,
      fuelVolumesDict: observable,
      fuelCostsDict: observable,
      pricePerLDict: observable,
      invoiceNoDict: observable,
      evTypesDict: observable,
      evCostsDict: observable,
      evVolumesDict: observable,
      evpricePerkwhDict: observable,
      evinvoiceNoDict: observable,
      confusedformat: observable,
      showAlert: observable,
      istimeout: observable,
      fuelHistory: observable,

      _handleSelectMonth: action.bound,
      _getFuelHistoryData: action.bound,
      _fetchVehicleLst: action.bound,
      _handleSelectedVehicleType: action.bound,
      _handleSelectedVehicle: action.bound,
      _handleTextChange: action.bound,
      setFuelData: action.bound,
      _handleSubmit: action.bound,
      _handleSelectYear: action.bound,
      _fetchVehicleInfo: action.bound,
      _handleSelectVehicleFromHistory: action.bound,
      _fetchHistoryData: action.bound,
      _handleSelectedDriver: action.bound,
      _handleSelectVehicle: action.bound,
      _handleImageToTextClick: action.bound,
      _handleChangeImgToTextValue: action.bound,
      _handleChangeFuelValue: action.bound,
      _findCommonTextsFuel: action.bound,
      _findCommonTextsEv: action.bound,
      _handleShowAlert: action.bound,
      _setIstimeoutValue: action.bound,
      _handleFileChange: action.bound,
      _handleImageViewClick: action.bound,
      _handleClearImage: action.bound,
      setHistory: action.bound,
      setImageStatus: action.bound,
      _handleFetchOdometerStartEnd: action.bound,
      _setViewImage: action.bound,
    });
  }


  // _setViewImage = (row) => {
  //   this.setviewimage = this.fuelHistoryData.filter(
  //     (value) => value.plate_number ? value.plate_number : value.plate_number === row.plate_number ? row.plate_number : row.plate_number
  //   )[0];

  //   console.log(this.setviewimage, "setviewimage?=====>")
   
  // };

  _setViewImage = (row) => {
    console.log("this.fuelHistoryData:", toJS(this.fuelHistoryDataForDriver));
    this.setviewimage = this.fuelHistoryDataForDriver.find(
      (value) => value.plate_number === row.plate_number
    );

    console.log(row.plate_number,"rowplatenumber?=====>")
  
    console.log(toJS(this.setviewimage), "setviewimage?=====>");
  };
  
  setHistory(value) {
    this.fuelHistory = value
  }

  _handleSelectVehicle(vehicle) {
    this.selectedHistoryVehicles = vehicle;

    if (this.selectedHistoryVehicles.value === this.forAll.value) {
      this.fuelHistoryDataForDriver = this.fuelHistoryData;
      this._fetchHistoryData();
    } else {
      this.fuelHistoryDataForDriver = this.fuelHistoryData.filter(
        (value) =>
          value.vehicle_number_plate?.trim() ===
          this.selectedHistoryVehicles.label.trim()
      );
      this._fetchHistoryData();
    }
    
  }

  _handleSelectedDriver(dId) {
    this.selectedDriverId = dId;
    if (this.selectedDriverId) {
      this._fetchHistoryData();
    }
  }

  _handleSelectVehicleFromHistory(vehicle) {
    this.selectedHistoryVehicles = vehicle;
    if (this.selectedHistoryVehicles.value === this.forAll.value) {
      this.filterFuelHistoryData = this.fuelHistoryData;
    } else {
      this.filterFuelHistoryData = this.fuelHistoryData.filter((value) => {
        return (
          value.vehicle_number_plate?.trim() ===
          this.selectedHistoryVehicles.label.trim()
        );
      });
    }
  }

  _handleVehicleOnClick(vehicle) {
    this.vehicle = vehicle;
  }

  _fetchVehicleInfo(vehicleId) {
    fetchVehicleInfoNew(this.token, vehicleId, (err, data) => {
      if (data) {
        this.selectedVehicleInfo = data;
      } else {
        toast.error(err.message);
      }
    });
  }

  _fetchVehicleLst() {
    fetchVehiclesForDriver(this.userId, this.token, (err, data) => {
      if (data) {
        this.vehicleOption = [];
        this.vehicles = data;
        this.vehicleType = this.vehicleTypeOption[0];
        this.filterVehicles = this.vehicles.filter((value) => {
          const type = value.primary_fuel_type.trim();
          return this.vehicleType.value === "fuel"
            ? ["Petrol", "Diesel"].includes(type)
            : type === "Battery";
        });
        this.vehicleOption.push(this.forAll);
        this.filterVehicles.map((v) => {
          this.vehicleOption.push({
            value: v.id,
            label: v.plate_number || "-",
          });
        });
        this.selectedVehicle = this.vehicleOption[0];

        this.vehicleHistoryOption = [];
        this.vehicleHistoryOption.push(this.forAll);
        data.map((v) => {
          // console.log("fakdklfjaldjf",v)
          this.vehicleHistoryOption.push({
            value: v.uid,
            label: v.vehicle_number_plate || "-",
          });
        });
      } else {
        toast.error(err.message);
      }
    });
  }

  _handleSelectedVehicle(value) {
    runInAction(() => {
      this.selectedVehicle = value;
      if (value.value === "0") {
        this.filterVehicles = this.vehicles.filter((value) => {
          const type = value.primary_fuel_type.trim();
          return this.vehicleType.value === "fuel"
            ? ["Petrol", "Diesel"].includes(type)
            : type === "Battery";
        });
      } else {
        this.filterVehicles = this.vehicles.filter(
          (value) => value.id === this.selectedVehicle.value
        );
      }
    });
  }

  _handleSelectedVehicleType(value) {
    runInAction(() => {
      this.vehicleType = value;
      this.vehicleOption = [];
      this.filterVehicles = this.vehicles.filter((value) => {
        const type = value.primary_fuel_type.trim();
        return this.vehicleType.value === "fuel"
          ? ["Petrol", "Diesel"].includes(type)
          : type === "Battery";
      });
      this.vehicleOption.push(this.forAll);
      this.filterVehicles.map((v) => {
        this.vehicleOption.push({
          value: v.id,
          label: v.plate_number || "-",
        });
      });
      this.selectedVehicle = this.vehicleOption[0];
    });
  }

  _handleSelectMonth = (e) => {
    this.month = e.value;
    this._fetchHistoryData();
  };

  _handleSelectYear = (e) => {
    this.year = e.value;
    this._fetchHistoryData();
  };

  _handleSubmit = (e, history) => {
    e.preventDefault();

    const err = FuelRecordValidation(this.fuelData);

    if (err.quantityErr) {
      document.getElementById("liter").focus();
      toast.error(err.quantityErr);
    } else if (err.amountErr) {
      document.getElementById("amount").focus();
      toast.error(err.amountErr);
    } else {
      let start = parseInt(
        this.fuelData.odometerStart === "" ? "0" : this.fuelData.odometerStart
      );
      let end = parseInt(
        this.fuelData.odometerEnd === "" ? "0" : this.fuelData.odometerEnd
      );

      if (start > end) {
        toast.error(
          "Odometer - End of Month(km) must be greater than  Odometer - Start of Month(km)"
        );
      } else {
        let data = {
          odometerStart: parseFloat(this.fuelData.odometerStart),
          odometerEnd: parseFloat(this.fuelData.odometerEnd),
          tripMileage: this.fuelData.mileage,
          capturedDate: this.fuelData.recordDateTime,
          editedBy: Common.userId.toString(),
          editedId: this.fuelData.id,
          type: this.fuelData.fuelType,
          date: this.date,
          unitId: this.fuelData.vuid,
          manual: {
            id: !this.fuelData.fuelRecords ? 0 : this.fuelData.id,
            amount: this.fuelData.amount,
            consumed: parseFloat(this.fuelData.quantity),
            fuel_quantity: this.quantity,
            fuel_status: this.fuelStatus,
            fuel_type: this.fuelData.fuelType,
            invoice_no: this.invoiceNo,
            price_per_liter: this.pricePerL,
            type_status: this.typeStatus,
            image_status: this.imageStatus ? this.imageStatus : "",
          },
        };

        const formData = new FormData();

        formData.append("fuel_record", JSON.stringify(data));
        formData.append("file", this.fileFuelRecords);

        fetchFuelRecord(formData, this.token, (err, data) => {
          if (data.error) {
            toast.error(data.error);
          } else if (err) {
            toast.error(err.message)
          } else {
            toast.success("Added fuel record successfully");
            history.push('m_fuel_history')
            this.fuelData = {
              userId: this.userId,
              vehicleId: this.vehicleId,
              fuelType: "Diesel",
              quantity: "",
              amount: "",
              fuelRecords: null,
              imgStartOfMonth: null,
              imgEndOfMonth: null,
              recordDateTime: new Date(),
              consumed: "",
              odometerStart: "",
              odometerEnd: "",
              mileage: "",
              vuid: "",
              id: "",
            };
          }
        });
      }
    }
  };

  setFuelData = (data) => {
    if (!data) return;
    this.fuelData = {
      userId: this.userId,
      vehicleId: this.vehicleId,
      fuelType: data.primary_fuel_type,
      quantity: "",
      amount: "",
      fuelRecords: null,
      imgStartOfMonth: null,
      imgEndOfMonth: null,
      recordDateTime: data.data_form?.captured_date
        ? new Date(data.data_form?.captured_date)
        : new Date(),
      consumed: data.data_form?.fuel_consumed,
      odometerStart: data.data_form?.odometer_start,
      odometerEnd: data.data_form?.odometer_end,
      mileage: data.data_form?.trip_mileage,
      vuid: data.data_form?.vUid,
      id: data.data_form?.id,
    };
  };

  _handleTextChange = (e, key) => {
    if (key === "recordDateTime") {
      this.fuelData[key] = e;
    } else if (key === "quantity") {
      if (e.target.value < 100000000) {
        this.fuelData[key] = e.target.value;
      }
    } else if (key === "amount") {
      this.fuelData[key] = e.target.value;
    } else if (key === "consumed") {
      this.fuelData[key] = e.target.value;
    } else if (key === "odometerStart") {
      this.fuelData[key] = e.target.value;
    } else if (key === "odometerEnd") {
      this.fuelData[key] = e.target.value;
    } else if (key === "mileage") {
      this.fuelData[key] = e.target.value;
    }

    let start = parseInt(
      this.fuelData.odometerStart === "" ? "0" : this.fuelData.odometerStart
    );
    let end = parseInt(
      this.fuelData.odometerEnd === "" ? "0" : this.fuelData.odometerEnd
    );
    let mileage = end - start;
    this.fuelData.mileage = mileage;
  };

  _fetchHistoryData = () => {
    // if (this.selectedDriverId) {
    // } else {
    //   this.selectedDriverId = "0";
    // }
    
    if(this.selectedDriverId == undefined){
      this.selectedDriverId = "0"
    }

    fetchFuelHistory(
      this.selectedDriverId,
      this.year,
      this.month,
      this.token,
      (err, data) => {
        if (data?.error) {
          toast.error(data.error);
        } else {
          this.fuelHistoryData = data?.payload;
          if (this?.selectedHistoryVehicles?.value === this.forAll.value) {
            this.fuelHistoryDataForDriver = data?.payload;
          } else {
            this.fuelHistoryDataForDriver = data.filter(
              (value) =>
                value.vehicle_number_plate?.trim() ===
                this.selectedHistoryVehicles.label.trim()
            );
          }
        }
      }
    );
  };

  _getFuelHistoryData = async () => {
    fetchFuelHistory(
      Common.userId,
      this.year,
      this.month,
      this.token,
      (err, data) => {
        if (data?.error) {
          toast.error(data?.error);
        } else {
          this.fuelHistoryData = data?.payload;
          if (this.selectedHistoryVehicles.value === this.forAll.value) {
            this.filterFuelHistoryData = data?.payload;
          } else {
            this.filterFuelHistoryData = data?.filter(
              (value) =>
                value.vehicle_number_plate?.trim() ===
                this.selectedHistoryVehicles.label.trim()
            );
          }
        }
      }
    );
  };

  _handleImageToTextClick = () => {
    this._setIstimeoutValue(false);
    this._handleChangeImgToTextValue("");
    this._handleChangeFuelValue("", "", "", "", "", "", "");
    this.convertText = "convert";
    var keys;
    let text = "";

    if (!this.fileFuel) {
      Swal.fire({
        icon: "error",
        text: "No image is uploaded yet",
        showConfirmButton: true,
      }).then((result) => {});

      return;
    }

    var pdfUrl = this.fileFuel;

    // const canvas = this.canvasRef.current;
    // const ctx = canvas.getContext('2d');

    // ctx.drawImage(this.imageRef.current, 0, 0);
    // ctx.putImageData(preprocessImage(canvas), 0, 0);
    // const dataUrl = canvas.toDataURL("image/jpeg");
    (async () => {
      const worker = await createWorker();
      const timer = setTimeout(() => {
        this._setIstimeoutValue(true);
        worker?.terminate();
      }, 10000);

      if (this.uploadFileType === "application/pdf") {
        const loadingTask = pdfjsLib.getDocument(pdfUrl);
        const pdf = await loadingTask.promise;

        const pageNumber = 1; //Specify the page number you want to convert
        const page = await pdf.getPage(pageNumber);
        const textContent = await page.getTextContent();
        let extractedText = "";
        let extractedLines = "";
        const textPromises = [];
        textContent.items.forEach((textItem, i) => {
          if (textItem.str === "") {
            extractedLines += "\n";
            textPromises.push({ text: extractedText });
            extractedText = "";
          } else {
            extractedLines += textItem.str;
            extractedText += textItem.str;
            if (i === textContent.items.length - 1) {
              textPromises.push({ text: extractedText });
            }
          }
        });
        keys = this._extractKeys(textPromises, extractedLines);
        if (!this.istimeout) {
          clearTimeout(timer);
          this._handleShowAlert(true);
        }
        text = keys.text;
      } else {
        await worker.loadLanguage("eng");
        await worker.initialize("eng");

        await worker.setParameters({
          user_defined_dpi: "300", // Set the desired DPI value here
        });
        await worker
          .recognize(pdfUrl, "eng", {
            //logger: m => console.log(m)
          })
          .catch((err) => {
            console.log("error", err);
          })
          .then((result) => {
            // Get Confidence score
            if (!this.istimeout) {
              clearTimeout(timer);
              this._handleShowAlert(true);
              console.log("result", result);

              if (result.data.confidence > 20) {
                keys = this._extractKeys(result, "");

                text = keys.text;
              } else {
                text =
                  "Image quality is not good. Try another. Confidence level is " +
                  result.data.confidence;
              }
            }
          });
      }

      //   this.newAudit.find((v, i) => i === index).fileFuel=imageDataUrl;
      this._handleChangeImgToTextValue(text);
      if (this.fuelData.fuelType !== "Battery") {
        this._handleChangeFuelValue(
          keys.filefueltype,
          keys.fuelname,
          keys.quantity,
          keys.amount,
          keys.pricePerL,
          keys.invoiceNo,
          keys.date
        );
      } else {
        this._handleChangeFuelValue(
          keys.evfilefueltype,
          keys.evfuelname,
          keys.evquantity,
          keys.evamount,
          keys.evpricePerkwh,
          keys.evinvoiceNo,
          keys.evdate
        );
      }
    })();
  };

  _extractKeys = (result, resultText) => {
    let text = "";

    let filefueltype = "";
    let fuelname = "Fuel name : not found";
    let quantity = "Quantity : not found";
    let amount = "Amount : not found";
    let pricePerL = "Price per liter : not found";
    let invoiceNo = "Invoice no. : not found";
    let date = "Date : not found";

    let evfilefueltype = "";
    let evfuelname = "EV name : not found";
    let evquantity = "Quantity : not found";
    let evamount = "Amount : not found";
    let evpricePerkwh = "Price per kWh : not found";
    let evinvoiceNo = "Invoice no. : not found";
    let evdate = "Date : not found";

    if (this.fuelData.fuelType !== "Battery") {
      let dictfilter = [
        ...this._findCommonTextsFuel(
          toJS(this.fuelTypesDict),
          toJS(this.fuelVolumesDict),
          toJS(this.fuelCostsDict),
          toJS(this.pricePerLDict),
          toJS(this.invoiceNoDict),
          toJS(this.dateDict),
          result.data ? result.data.lines : result
        ),
      ];
      dictfilter = dictfilter.flat(10);
      dictfilter = [...new Set(dictfilter)];
      dictfilter.forEach((x) => {
        if (x) {
          if (x.dictTxtType) {
            filefueltype = x.dictTxtType;
            // fuelname = x.extractName.replace("\n", "");
            fuelname = "Fuel name : " + x.dictTxtName;
          } else if (x.extractQuantity) {
            quantity = x.extractQuantity.toLowerCase().includes(x.dictTxtUnit)
              ? x.extractQuantity.replace("\n", "")
              : (x.extractQuantity + x.dictTxtUnit).replace("\n", "");
          } else if (x.extractAmount) {
            amount = x.extractAmount.replace("\n", "");
          } else if (x.extractPricePerL) {
            pricePerL = x.extractPricePerL.replace("\n", "");
          } else if (x.extractInvoiceNo) {
            invoiceNo = x.extractInvoiceNo.replace("\n", "");
          } else if (x.extractDate) {
            date = x.extractDate.replace("\n", "");
          }
        }
      });

      text = result.data
        ? "Confidence level is " +
          result.data.confidence +
          "\n" +
          result.data.text
        : resultText;
    } else {
      let dictfilter = [
        ...this._findCommonTextsEv(
          toJS(this.evTypesDict),
          toJS(this.evVolumesDict),
          toJS(this.evCostsDict),
          toJS(this.evpricePerkwhDict),
          toJS(this.evinvoiceNoDict),
          toJS(this.evDateDict),
          result.data ? result.data.lines : result
        ),
      ];
      dictfilter = dictfilter.flat(10);
      dictfilter = [...new Set(dictfilter)];

      dictfilter.forEach((x) => {
        if (x) {
          if (x.dictTxtType) {
            evfilefueltype = x.dictTxtType;
            // evfuelname = x.extractName.replace("\n", "");
            evfuelname = "EV name : " + x.dictTxtName;
          } else if (x.extractQuantity) {
            evquantity = x.extractQuantity.toLowerCase().includes(x.dictTxtUnit)
              ? x.extractQuantity.replace("\n", "")
              : (x.extractQuantity + x.dictTxtUnit).replace("\n", "");
          } else if (x.extractAmount) {
            evamount = x.extractAmount.replace("\n", "");
          } else if (x.extractPricePerkwh) {
            evpricePerkwh = x.extractPricePerkwh.replace("\n", "");
          } else if (x.extractInvoiceNo) {
            evinvoiceNo = x.extractInvoiceNo.replace("\n", "");
          } else if (x.extractDate) {
            evdate = x.extractDate.replace("\n", "");
          }
        }
      });

      text = result.data
        ? "Confidence level is " +
          result.data.confidence +
          "\n" +
          result.data.text
        : resultText;
    }
    return {
      text,
      filefueltype,
      fuelname,
      quantity,
      amount,
      pricePerL,
      invoiceNo,
      date,
      evfilefueltype,
      evfuelname,
      evquantity,
      evamount,
      evpricePerkwh,
      evinvoiceNo,
      evdate,
    };
  };

  _findCommonTextsFuel = (arr1, arr2, arr3, arr4, arr5, arr6, lines) => {
    let result1 = [];
    let result2 = [];
    let result3 = [];
    let result4 = [];
    let result5 = [];
    let result6 = [];

    return lines.map((extractTxt) => {
      arr1.forEach((dictTxt) => {
        let inclu = false;
        let confused = false;

        this.confusedformat.forEach((confusedTxt) => {
          confused =
            confused ||
            extractTxt.text
              .toLowerCase()
              .includes(confusedTxt.amount.toLowerCase());
        });
        inclu =
          extractTxt.text
            .toLowerCase()
            .includes(dictTxt.oriTxt.toLowerCase()) && !confused;
        if (inclu)
          result1.push({
            extractName: extractTxt.text,
            dictTxtName: dictTxt.name,
            dictTxtType: dictTxt.filefueltype,
          });
      });

      arr2.forEach((dictTxt) => {
        const inclu = extractTxt.text
          .toLowerCase()
          .includes(dictTxt.quantity.toLowerCase());
        if (inclu)
          result2.push({
            extractQuantity: extractTxt.text,
            dictTxtQuantity: dictTxt.quantity,
            dictTxtUnit: dictTxt.unit,
          });
      });

      arr3.forEach((dictTxt) => {
        let inclu = false;
        let confused = false;

        this.confusedformat.forEach((confusedTxt) => {
          confused =
            confused ||
            extractTxt.text
              .toLowerCase()
              .includes(confusedTxt.amount.toLowerCase());
        });

        inclu =
          extractTxt.text
            .toLowerCase()
            .includes(dictTxt.amount.toLowerCase()) && !confused;
        if (inclu)
          result3.push({
            extractAmount: extractTxt.text,
            dictTxtAmount: dictTxt.amount,
          });
      });

      arr4.forEach((dictTxt) => {
        let inclu = false;
        let confused = false;

        this.confusedformat.forEach((confusedTxt) => {
          confused =
            confused ||
            extractTxt.text
              .toLowerCase()
              .includes(confusedTxt.amount.toLowerCase());
        });
        inclu =
          extractTxt.text.toLowerCase().includes(dictTxt.text.toLowerCase()) &&
          extractTxt.text
            .toLowerCase()
            .includes(dictTxt.format.toLowerCase()) &&
          !confused;
        if (inclu)
          result4.push({
            extractPricePerL: extractTxt.text,
            dictTxtPricePerL: dictTxt.text,
          });
      });

      arr5.forEach((dictTxt) => {
        const inclu = extractTxt.text
          .toLowerCase()
          .includes(dictTxt.text.toLowerCase());
        if (inclu)
          result5.push({
            extractInvoiceNo: extractTxt.text,
            dictTxtInvoiceNo: dictTxt.text,
          });
      });

      arr6.forEach((dictTxt) => {
        let inclu = false;
        let confused = false;

        this.confusedformat.forEach((confusedTxt) => {
          confused =
            confused ||
            extractTxt.text
              .toLowerCase()
              .includes(confusedTxt.amount.toLowerCase());
        });
        inclu =
          extractTxt.text.toLowerCase().includes(dictTxt.text.toLowerCase()) &&
          !confused;
        if (inclu)
          result6.push({
            extractDate: extractTxt.text,
            dictTxtDate: dictTxt.text,
          });
      });

      if (
        result6.length === 0 &&
        extractTxt.text.match(
          "[0-9]{2}[-/ .][0-9]{2}[-/ .][0-9]{4}|[0-9]{4}[-/ .][0-9]{2}[-/ .][0-9]{2}" +
            "|[0-9]{2}[-/ .][A-Z]{3}[-/ .][0-9]{4}|[0-9]{4}[-/ .][A-Z]{3}[-/ .][0-9]{2}"
        )
      ) {
        result6.push({ extractDate: extractTxt.text });
      }

      // return arr4.map((extractTxt, index) => {

      // let dictfilter1 = arr1.map(dictTxt => {
      //     const inclu = extractTxt.text.includes(dictTxt.name)
      //     return { ...dictTxt, index: inclu ? index : -1 }
      // })

      // let dictfilter2 = arr2.map(dictTxt => {
      //     const inclu = extractTxt.text.includes(dictTxt.unit)
      //     return { ...dictTxt, index: inclu ? index : -1 }
      // })

      // let dictfilter3 = arr3.map(dictTxt => {
      //     const inclu = extractTxt.text.includes(dictTxt.amount)
      //     return { ...dictTxt, index: inclu ? index : -1 }
      // })
      // }

      // dictfilter1 = dictfilter1.filter(f => f.index > -1)
      // dictfilter2 = dictfilter2.filter(f => f.index > -1)
      // dictfilter3 = dictfilter3.filter(f => f.index > -1)

      // if (dictfilter1.length) result1.push(dictfilter1)
      // if (dictfilter2.length) result2.push(dictfilter2)
      // if (dictfilter3.length) result3.push(dictfilter3)

      return [result1, result2, result3, result4, result5, result6];
    });
  };

  _findCommonTextsEv = (arr1, arr2, arr3, arr4, arr5, arr6, lines) => {
    let result1 = [];
    let result2 = [];
    let result3 = [];
    let result4 = [];
    let result5 = [];
    let result6 = [];

    return lines.map((extractTxt) => {
      arr1.forEach((dictTxt) => {
        const inclu = extractTxt.text
          .toLowerCase()
          .includes(dictTxt.oriTxt.toLowerCase());
        if (inclu)
          result1.push({
            extractName: extractTxt.text,
            dictTxtName: dictTxt.name,
            dictTxtType: dictTxt.filefueltype,
          });
      });

      arr2.forEach((dictTxt) => {
        const inclu = extractTxt.text
          .toLowerCase()
          .includes(dictTxt.quantity.toLowerCase());
        if (inclu)
          result2.push({
            extractQuantity: extractTxt.text,
            dictTxtQuantity: dictTxt.quantity,
            dictTxtUnit: dictTxt.unit,
          });
      });

      arr3.forEach((dictTxt) => {
        let inclu = false;
        let confused = false;

        this.evConfusedformat.forEach((confusedTxt) => {
          confused =
            confused ||
            extractTxt.text
              .toLowerCase()
              .includes(confusedTxt.amount.toLowerCase());
        });
        inclu =
          extractTxt.text
            .toLowerCase()
            .includes(dictTxt.amount.toLowerCase()) && !confused;
        if (inclu)
          result3.push({
            extractAmount: extractTxt.text,
            dictTxtAmount: dictTxt.amount,
          });
      });

      arr4.forEach((dictTxt) => {
        const inclu = extractTxt.text
          .toLowerCase()
          .includes(dictTxt.text.toLowerCase());
        if (inclu)
          result4.push({
            extractPricePerkwh: extractTxt.text,
            dictTxtPricePerkwh: dictTxt.text,
          });
      });

      arr5.forEach((dictTxt) => {
        const inclu = extractTxt.text
          .toLowerCase()
          .includes(dictTxt.text.toLowerCase());
        if (inclu)
          result5.push({
            extractInvoiceNo: extractTxt.text,
            dictTxtInvoiceNo: dictTxt.text,
          });
      });

      arr6.forEach((dictTxt) => {
        let inclu = false;
        let confused = false;

        this.evConfusedformat.forEach((confusedTxt) => {
          confused =
            confused ||
            extractTxt.text
              .toLowerCase()
              .includes(confusedTxt.amount.toLowerCase());
        });
        inclu =
          extractTxt.text.toLowerCase().includes(dictTxt.text.toLowerCase()) &&
          !confused;
        if (inclu)
          result6.push({
            extractDate: extractTxt.text,
            dictTxtDate: dictTxt.text,
          });
      });

      return [result1, result2, result3, result4, result5, result6];
    });
  };

  _handleChangeFuelValue = (
    filefueltype,
    fuelname,
    quantity,
    totalamount,
    pricePerL,
    invoiceNo,
    date
  ) => {
    this.filefueltype = filefueltype;
    this.fuelname = fuelname;
    this.quantity =
      quantity.indexOf(",") !== -1
        ? quantity.charAt(quantity.indexOf(",") + 4).match(/\d/g)
          ? quantity.replace(",", ".")
          : quantity.replace(",", "")
        : quantity;
    this.totalamount = totalamount;
    this.pricePerL = pricePerL;
    this.invoiceNo = invoiceNo;
    this.date = date;

    var matchedQuantity =
      this.quantity?.match(/\d+\.\d+|\d+ltr+/g) ||
      this.quantity?.match(/\d+\.\d+|\d+kwh+/g);
    this.typeStatus =
      this.fuelData.fuelType.toLowerCase() === this.filefueltype.toLowerCase()
        ? "Accepted"
        : "Rejected";
    this.fuelStatus =
      matchedQuantity && matchedQuantity[0] === this.fuelData.quantity
        ? "Accepted"
        : "Rejected";
  };

  _handleChangeImgToTextValue = (text) => {
    this.text = text;
  };

  _handleShowAlert = (val) => {
    this.showAlert = val;
  };

  _setIstimeoutValue = (val) => {
    this.istimeout = val;
  };

  _handleFileChange = (value, fileType) => {
    if (fileType === "start") {
      this.fuelData.imgStartOfMonth = value.target?.files[0];
      this.fileStartOfMonth = value.target?.files[0];
      this.fileStart = value.target.files[0]
        ? URL.createObjectURL(value.target?.files[0])
        : "";
      this.fileStartName = this.fileStartOfMonth?.name;
      this._uploadFile(value.target.files[0], fileType);
    } else if (fileType === "end") {
      this.fuelData.imgEndOfMonth = value.target?.files[0];
      this.fileEndOfMonth = value.target?.files[0];
      this.fileEnd = value.target.files[0]
        ? URL.createObjectURL(value.target?.files[0])
        : "";
      this.fileEndName = this.fileEndOfMonth?.name;
      this._uploadFile(value.target.files[0], fileType);
    } else {
      this.fuelData.fuelRecords = value.target?.files[0];
      this.fileFuelRecords = value.target?.files[0];
      this.fileFuel = value.target.files[0]
        ? URL.createObjectURL(value.target?.files[0])
        : "";
      this.fileFuelName = this.fileFuelRecords?.name;
      this.uploadFileType = value.target?.files[0].type;
    }
  };

  _handleImageViewClick = (name, imageUrl, title) => {
    this.currentImage = imageUrl;
    this.currentImageName = name;
    this.currentTitle = title;
  };

  _handleClearImage = (fileType) => {
    if (fileType === "start") {
      this.fileStartOfMonth = null;
      this.fileStart = "";
      this.fileStartName = "";
    } else if (fileType === "end") {
      this.fileEndOfMonth = null;
      this.fileEnd = "";
      this.fileEndName = "";
    } else {
      this.fileFuelRecords = null;
      this.fileFuel = "";
      this.fileFuelName = "";
      this.uploadFileType = "";
    }
    this._uploadFile(null, fileType);
  };

  _uploadFile = (file, fileType) => {
    var formdata = new FormData();
    formdata.append("file", file);
    formdata.append("fileType", fileType);
    formdata.append("editedBy", Common.userId);
    // formdata.append("id", this.vehicleData.id);
    // formdata.append("capturedData", this.captureOdometer);

    {
      this.fuelData.id
        ? formdata.append("id", this.fuelData.id)
        : formdata.append("id", 0);
    }

    UploadFile(formdata, Common.token, (err, data) => {
      this.fuelData.id = data.id;

      if (err) {
        toast.error(err.message);
      } else {
        toast.success(data.message, { autoClose: 400 });
      }
    });
  };

  setImageStatus(value) {
    this.imageStatus = value;
  }

  _handleFetchOdometerStartEnd() {
    fetchOdometerStartEnd(Common.token, moment(this.fuelData.recordDateTime).format('YYYY-MM-DD'), this.fuelData.vehicleId, (err, data) => {
      if (err) {
        toast.error(err.message)
      } else {
        this.odometerStartEnd = data.payload
        this.fuelData.odometerStart = this.odometerStartEnd.odometer_start === null ? "0.00" : this.odometerStartEnd.odometer_start
        this.fuelData.odometerEnd = this.odometerStartEnd.odometer_end === null ? "0.00" : this.odometerStartEnd.odometer_end
        let start = parseInt(
          this.fuelData.odometerStart === "" ? "0" : this.fuelData.odometerStart
        );
        let end = parseInt(
          this.fuelData.odometerEnd === "" ? "0" : this.fuelData.odometerEnd
        );
        let mileage = end - start;
        this.fuelData.mileage = mileage;
      }
    })
  }
}

export default new Driver();
