import * as API from "./URL";

export const getFileImportHistory = async (
  token,
  companyId,
  year,
  month,
  category,
  callback
) => {
  return fetch(
    `${API.getImportHistoryAPI}?companyId=${companyId}&category=${category}&year=${year}&month=${month}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        "x-access-token": token,
      },
    }
  )
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

// export const geImportedDateHistory = async(token, companyId,)

export const downloadImportedFile = async (token, fileName,uploadedFileName, callback) => {
  return fetch(`${API.downloadImportedFileAPI}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
    body: JSON.stringify({ fileName: fileName }),
  })
    .then((response) => {
      if (response.status === 404) {
        throw new Error("File not exist")
      } else {
        return response.blob();
      }
    }
    )
    .then((blob) => {
      // // Create a URL for the blob
      const blobUrl = URL.createObjectURL(blob);

      // Create a link with that URL and simulate a click
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = uploadedFileName;
      link.click();
    })
    .catch((err) => callback(err, null));
};

export const downloadRefFile = async (token, refFileName, callback) => {
  return fetch(`${API.downloadRefFileAPI}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
    body: JSON.stringify({ refFileName: refFileName }),
  })
    .then((response) => {
      if (response.status === 404) {
        throw new Error("File not exist")
      } else {
        return response.blob();
      }
    }
    )
    .then((blob) => {
      const blobUrl = URL.createObjectURL(blob);

      // Create a link with that URL and simulate a click
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = refFileName;
      link.click();
    })
    .catch((err) => callback(err, null));
};

export const getVTripsData = async (token, id, companyId, callback) => {
  return fetch(`${API.getImportedTripsAPI}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "x-access-token": token,
    },
    body: JSON.stringify({ id, companyId }),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const getVTripsFileData = async (token, id, companyId, callback) => {
  return fetch(`${API.getTripsFileAPI}?id=${id}&companyId=${companyId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "x-access-token": token,
    },
    // body: JSON.stringify({ id, companyId }),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};


export const getVEngineHourData = async (token, id, companyId, callback) => {
  return fetch(`${API.getImportedEngineHoursAPI}?id=${id}&companyId=${companyId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "x-access-token": token,
    },
    // body: JSON.stringify({ id, companyId }),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const getVProfilesData = async (token, id, callback) => {
  return fetch(`${API.getImportedUnitsAPI}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
    body: JSON.stringify({ id: id }),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const getUnitProfilesData = async (token, id, companyId, callback) => {
  return fetch(`${API.getUnitProfilesDataAPI}?id=${id}&companyId=${companyId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
    // body: JSON.stringify({ id: id }),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const getManualEntryData = async (token, id, companyId, callback) => {
  return fetch(`${API.getImpotedManualEntryAPI}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
    body: JSON.stringify({ id: id, companyId: companyId }),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const importVTripCSV = async (token, data, callback) => {
  return fetch(`${API.vTripDataImportAPI}`, {
    method: "POST",
    headers: {
      // "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
    body: data,
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const createVTripsData = async (token, data, id, callback) => {
  const vTripData = {
    data: data,
    id: id,
  };
  return fetch(`${API.createVTripAPI}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
    body: JSON.stringify(vTripData),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const storeVTripsFile = async (token, data, id, callback) => {
  const vTripData = {
    file_id: id,
    accept_data: data,
  };
  return fetch(`${API.storeTripsFileAPI}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
    body: JSON.stringify(vTripData),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const importVEngineHourCsv = async (token, data, callback) => {
  return fetch(`${API.vEngineHourImportAPI}`, {
    method: "POST",
    headers: {
      // "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
    body: data,
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const createVEngineHoursData = async (token, data, id, callback) => {
  const vEngineHourData = {
    file_id: id,
    accept_data: data,
  };
  return fetch(`${API.storeEngineHoursAPI}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
    body: JSON.stringify(vEngineHourData),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const importVprofileCSV = async (token, data, callback) => {
  return fetch(`${API.vProfileImportAPI}`, {
    method: "POST",
    headers: {
      // "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
    body: data,
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const createVprofileData = async (
  token,
  data,
  id,
  selectedDate,
  callback
) => {
  const vProfileData = {
    data: data,
    id: id,
    selectedDate: selectedDate,
  };
  return fetch(`${API.createVProfileAPI}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
    body: JSON.stringify(vProfileData),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const storeUnitProfiles = async (
  token,
  id,
  accept_data,
  callback
) => {
  const unitProfilesData = {
    file_id: id,
    accept_data: accept_data,
  };
  return fetch(`${API.storeUnitProfilesDataAPI}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
    body: JSON.stringify(unitProfilesData),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const importManualEntryCSV = async (token, data, callback) => {
  return fetch(`${API.manualEntryDataImportAPI}`, {
    method: "POST",
    headers: {
      // "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
    body: data,
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const createManualEntryData = async (token, data, id, callback) => {
  const manualEntryData = {
    data: data,
    id: id,
  };
  return fetch(`${API.createManualEntryAPI}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
    body: JSON.stringify(manualEntryData),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const manualEntryCreateNewData = async (token, data, id, callback) => {
  const manualEntryData = {
    data: data,
    id: id,
  };
  return fetch(`${API.manualEntryCreateNewApi}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
    body: JSON.stringify(manualEntryData),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};


export const manualEntryCreateOverwriteData = async (token, data, id, overwrite, callback) => {
  const manualEntryData = {
    data: data,
    id: id,
    overwrite: overwrite,
  };
  return fetch(`${API.manualEntryCreateNewApi}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
    body: JSON.stringify(manualEntryData),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const clearImportData = async (token, id, fileName, callback) => {
  // console.log(fileName)
  return fetch(`${API.clearImportsAPI}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
    body: JSON.stringify({ id: id, fileName: fileName }),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const deleteManualEntryFileData = async (token,id,companyId, callback) => {
  // console.log(fileName)
  return fetch(`${API.deleteManualEntryFileData}?id=${id}&companyId=${companyId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
    // body: JSON.stringify({ id: id, fileName: fileName }),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};
//extract-manual-file
export const fetchGetExtractManualFile = async (token, companyId, id, callback) => {
  return fetch(`${API.getExtractManualFile}?companyId=${companyId}&id=${id}`, {
    method: "GET",
    headers: {
      //"Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
};

//store-extract-manual-data
export const storeExtractManualData = async (token, data,id, callback) => {
  const requestData = {accept_data: data,file_id :id};

  return await fetch(API.storeExtractManualData, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
    body: JSON.stringify(requestData),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
}

