import React, { useState } from "react";
import { useEffect, useRef } from "react";
import { inject, observer } from "mobx-react";
import "datatables.net-dt";
import "datatables.net-dt/css/jquery.dataTables.css";
import $ from "jquery";
import { action, toJS } from "mobx";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { CO2_Loading } from "../../Assets/CO2_Loading";
import { IssueDetailModal } from "./IssueDetailModal";

const IssuesTable = inject("store")(
  observer((props) => {
    const { t } = useTranslation("admin-panel");
    const { theme } = props;

    const {
      issuesData,
      _handleGetLogIssues,
      isLoading,
      startDate,
      endDate,
      today,
      setInfoModalView,
      _handleIssueDetail,
      setSelectedIssue,
      loading,
    } = props.store.adminPanel;
    const [selectedIssueId, setSelectedIssueId] = useState(null);

    // console.log("IssuesData ===> ",issuesData)

    const tableRef = useRef(null);

    let table = null;

    const filteredIssues = issuesData?.filter((data) => {
      var dataTime = moment(data.issue_time).format("YYYY-MM-DD HH:mm:ss");
      return (
        moment(dataTime).isSameOrAfter(
          moment(startDate).add(0, "day").format("YYYY-MM-DD HH:mm:ss")
        ) &&
        moment(dataTime).isSameOrBefore(
          moment(endDate).add(+1, "day").format("YYYY-MM-DD HH:mm:ss")
        )
      );
    });

    useEffect(() => {
      const styleElement = document.createElement(`style`);
      styleElement.innerHTML =
        theme === "dark"
          ? `
          .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
              color: black !important;
              border: 1px solid #E5E5E5!important;
              background-color: #E5E5E5 !important;
              /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #E5E5E5), color-stop(100%, #E5E5E5))!important; */
              background: -webkit-linear-gradient(top, #E5E5E5 0%, #E5E5E5 100%)!important;
              background: -moz-linear-gradient(top, #E5E5E5 0%, #E5E5E5 100%)!important;
              background: -ms-linear-gradient(top, #E5E5E5 0%, #E5E5E5 100%) !important;
              background: -o-linear-gradient(top, #E5E5E5 0%, #E5E5E5 100%)!important;
              background: linear-gradient(to bottom, #E5E5E5 0%, #E5E5E5 100%)!important;
            }
            .dataTables_wrapper .dataTables_paginate .paginate_button.current {
              color: black !important;
              border: 1px solid #C6C6C6!important;
              background-color: #C6C6C6 !important;
              /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FFFCFC), color-stop(100%, #969292))!important; */
              background: -webkit-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
              background: -moz-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
              background: -ms-linear-gradient(top, #FFFCFC 0%, #969292 100%) !important;
              background: -o-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
              background: linear-gradient(to bottom, #FFFCFC 0%, #969292 100%)!important;
            }
            .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
              color: black !important;
              border: 1px solid #C6C6C6!important;
              background-color: #C6C6C6 !important;
              /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FFFCFC), color-stop(100%, #969292))!important; */
              background: -webkit-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
              background: -moz-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
              background: -ms-linear-gradient(top, #FFFCFC 0%, #969292 100%) !important;
              background: -o-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
              background: linear-gradient(to bottom, #FFFCFC 0%, #969292 100%)!important;
            }

            .dataTables_wrapper .dataTables_paginate .paginate_button {
              color: white !important; 
            }

            .dataTables_wrapper .dataTables_paginate .ellipsis {
              color: white !important; 
            }

            .dataTables_wrapper .dataTables_length{
              color: white !important;
            }

            .dataTables_wrapper .dataTables_filter {
              color: white !important;
            }

            .dataTables_wrapper .dataTables_info {
              color: white !important;
            }
          `
          : `
              .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
                  color: black !important;
                  border: 1px solid #E5E5E5!important;
                  background-color: #E5E5E5 !important;
                  /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #E5E5E5), color-stop(100%, #E5E5E5))!important; */
                  background: -webkit-linear-gradient(top, #E5E5E5 0%, #E5E5E5 100%)!important;
                  background: -moz-linear-gradient(top, #E5E5E5 0%, #E5E5E5 100%)!important;
                  background: -ms-linear-gradient(top, #E5E5E5 0%, #E5E5E5 100%) !important;
                  background: -o-linear-gradient(top, #E5E5E5 0%, #E5E5E5 100%)!important;
                  background: linear-gradient(to bottom, #E5E5E5 0%, #E5E5E5 100%)!important;
                }
                .dataTables_wrapper .dataTables_paginate .paginate_button.current {
                  color: black !important;
                  border: 1px solid #C6C6C6!important;
                  background-color: #C6C6C6 !important;
                  /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FFFCFC), color-stop(100%, #969292))!important; */
                  background: -webkit-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
                  background: -moz-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
                  background: -ms-linear-gradient(top, #FFFCFC 0%, #969292 100%) !important;
                  background: -o-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
                  background: linear-gradient(to bottom, #FFFCFC 0%, #969292 100%)!important;
                }

                .dataTables_wrapper .dataTables_paginate .paginate_button {
                  color: black !important; 
                }  

                .dataTables_wrapper .dataTables_paginate .ellipsis {
                  color: black !important; 
                }

                .dataTables_wrapper .dataTables_length{
                  color: black !important;
                }

                .dataTables_wrapper .dataTables_filter {
                  color: black !important;
                }

                .dataTables_wrapper .dataTables_info {
                  color: black !important;
                }
          `;
      document.head.appendChild(styleElement);

      const updatedLengthMenu =
        issuesData?.length > 15 ? [15, 20, 25] : [8, 10, 15];
      const tableElement = $(tableRef.current);

      if ($.fn.DataTable.isDataTable(tableElement)) {
        tableElement.DataTable().destroy();
      }

      const filteredIssues = issuesData?.filter((data) => {
        var dataTime = moment(data.issue_time).format("YYYY-MM-DD HH:mm:ss");
        return (
          moment(dataTime).isSameOrAfter(
            moment(startDate).add(0, "day").format("YYYY-MM-DD HH:mm:ss")
          ) &&
          moment(dataTime).isSameOrBefore(
            moment(endDate).add(+1, "day").format("YYYY-MM-DD HH:mm:ss")
          )
        );
      });

      // const updatedLengthMenu = filteredIssues?.length > 15 ? [15, 20, 25] : [8, 10, 15];

      tableElement.DataTable({
        retrieve: true,
        scrollX: true,
        order: [[0, "desc"]],
        lengthMenu: updatedLengthMenu,
        data: filteredIssues || [],
        pageLength: filteredIssues?.length > 15 ? 15 : 8,
        columns: [
          { title: t("Issue Time"), data: "issue_time" },
          { title: t("Company"), data: "issue_company" },
          { title: t("Issue"), data: "issue" },
          { title: t("Issue Detail"), data: "issue_detail" },
          {
            title: t("Issue Value"),
            data: function (row) {
              return row.issue_value ? row.issue_value : "N/A";
            },
          },
          {
            title: t("Action"),
            data: null,
            render: function (data, type, row) {
              return `<button
              type="button"
              class="btn btn-sm m-1"
              style="background: #4c5d75; color: white;"
              data-issueListId=${JSON.stringify(row.id)}
              data-toggle="modal"
              data-target="#issuedetailmodal"
              onclick="openIssueDetailModal(event)"
            >
              View
            </button>
            
            `;

              // '<button class="btn btn-primary view-button" data-item-id="' +
              // row.id +
              // '">View</button>'
            },
          },
        ],
        columnDefs: [
          {
            targets: [0],
            width: "15%",
          },
          {
            targets: [1],
            width: "15%",
          },
          {
            targets: [2],
            width: "15%",
          },
          {
            targets: [3],
            width: "50%",
          },
          {
            targets: [4],
            width: "10%",
          },
          {
            targets: [5],
            width: "10%",
          },
        ],
      });

      const searchInput = $(".dataTables_filter input");
      searchInput.css("color", theme === "light" ? "black" : "white");

      const entriesSelect = $(".dataTables_length select");
      entriesSelect.css(
        "background-color",
        theme === "light" ? "" : "rgb(32, 43, 96)"
      );
      entriesSelect.css("color", theme === "light" ? "black" : "white");

      let tableRow = $("table.dataTable tbody");
      tableRow.css("cursor", "pointer");

      window.openIssueDetailModal = function (event) {
        setInfoModalView(true);

        const issueListId = event.target.dataset.issuelistid;
        setSelectedIssueId(issueListId);

        // console.log("id==>", issueListId)

        let myModal = new window.bootstrap.Modal(
          document.getElementById("issuedetailmodal")
        );
        myModal.show();

        const issueListRow = filteredIssues?.find((r) => r.id === issueListId);

        // console.log("filterissue=>", issueListRow)

        setSelectedIssue(issueListRow.id);
        _handleIssueDetail(issueListRow.id);
      };
    }, [theme, issuesData, t, startDate, endDate]);

    useEffect(() => {

      if (table) {

        table.clear();

        var newHeaderNames = table.columns().header().toArray().map(function (header) {
          return t($(header).text());
        });
        const updatedLengthMenu = filteredIssues?.length > 15 ? [15, 20, 25] : [8, 10, 15];

        $('div.dataTables_length select option').remove();

        updatedLengthMenu.forEach(optionValue => {
          $('div.dataTables_length select').append(`<option value="${optionValue}">${optionValue}</option>`);
        });

        table.draw();

        table.rows.add(filteredIssues || []).draw()

        table.cells().every(function () {
          $(this.node()).css('border-color', 'rgb(211,211,211, 0.3)');
        });

      }
    }, [theme, issuesData, t, filteredIssues]);

    return (
      <>
        {loading ? (
            <CO2_Loading />
        ) : (
          <div>
          <table
            ref={tableRef}
            className="display cell-border hover stripe"
            style={{
              width: "100%",
              color: theme === "dark" ? "white" : "black",
            }}
          ></table>
          </div>
        )}
        <IssueDetailModal
          theme={theme}
          filteredIssues={filteredIssues}
          selectedIssueId={selectedIssueId}
        />
      </>
    );
  })
);

export default IssuesTable;
