import { inject, observer } from "mobx-react";
import { Colors } from "../../Assets/Config/color.config";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import moment from "moment";
import Month from "../../Assets/month.json";
import { useTranslation } from "react-i18next";
import { TrackingFileExportModal } from "./trackingFileExportModal";
import dayjs from "dayjs";
import { toJS } from "mobx";
import { useState } from "react";
import { Checkbox } from "@mui/material";
import { CarbonSelector } from "./CarbonSelector";
import { bs } from "date-fns/locale";

export const CustomCarbonChart = inject("store")(
  observer((props) => {
    const { t } = useTranslation("new-tracking");
    const { theme, assetProfile, pdf, chartView, singleUnitSetting } = props;

    const { role } = props.store.common;
    // const { year, month, vehicleType, showChartViewVTwo, dateFormat,
    //   startDateFormat,
    //   endDateFormat,
    //   yearSelected,
    //   unitSetting
    // } =
    //   props.store.trackingVTwo;

    const {
      year,
      month,
      yearSelected,
      vehicleType,
      handleShowChartView,
      handleShowChartViewVTwo,
      unitSetting,
      showView,
      dateFormat,
      startDateFormat,
      endDateFormat,
      showChartViewVTwo,
      showComparisonSelect,
      reportDownload,
      setReportDownload,
      showTooltip,
      baselineEmission,
      baselineEmissionFactor,
      actualEmissionFactor,
      co2Emission,
      idlingCarbonEmission,
      _handleChartCheckbox,
    } = props.store.trackingVTwo;

    console.log("asset profile --->", toJS(assetProfile))

    const carbonEmissionColor = "#413ea0";
    let data1 = [];

    let labelFormat;
    let maxLeftY;
    let leftYTicks;
    let maxRightY;
    let rightYTicks;
    let CustomTooltip;

    let motionCarbonEmission = 30;

    const emissionDataOptions = [
      { label: "Baseline Emission", value: "Baseline Emission" },
      { label: "Baseline Emission Factor", value: "Baseline Emission Factor" },
      { label: "Emission Factor", value: "Emission Factor" },
      { label: "CO2 Emission", value: "CO2 Emission" },
    ];

    const showChartView = chartView
      ? chartView
      : props.store.trackingVTwo.showChartView;

    if (assetProfile?.enableCANbus && assetProfile?.enableGPS) {
      const emissionByLive = assetProfile?.live?.carbonEmission;

      if (dateFormat === "Year") {
        const Months = Month.filter((v) => v.value !== 0);
        let baseline = assetProfile.baseline_emission_factor;
        

        Months.map((v) => {
          if (
            emissionByLive?.filter((em) => em.days_months === v.value).length >
            0
          ) {
            let result = emissionByLive?.filter(
              (em) => em.days_months === v.value
            )[0];

            data1.push({
              name: `${v.label.substring(0, 3)}-${yearSelected % 100}`,
              baselineEmissionIntensity: baselineEmissionFactor
                ? result.historyBaselineEmissionFactor === 0
                  ? result.baselineEmissionFactor || "0"
                  : result.historyBaselineEmissionFactor || "0"
                : "0",
              emissionIntensity: actualEmissionFactor
                ? result.emissionFactor || "0"
                : "0", //(gCo2/km)
              inMotionCarbonEmission: co2Emission ? result.totalEmission || "0" : "0", //(kgCo2)
              baselineEmission: baselineEmission
                ? result.totalBaselineEmission || "0"
                : "0", // kgCo2
              // idlingCarbonEmission: idlingCarbonEmission
              //   ? result.idlingEmission || "0"
              //   : "0",
            });
          } else {
            data1.push({
              name: `${v.label.substring(0, 3)}-${yearSelected % 100}`,
              baselineEmissionIntensity: baseline,
              inMotionCarbonEmission: "0",
              emissionIntensity: "0",
              baselineEmission: "0",
              // idlingCarbonEmission: "0",
            });
          }
        });
      } else {
        const firstDay = new Date(startDateFormat);
        const lastDay = new Date(endDateFormat);

        let dateArr = [];
        while (firstDay <= lastDay) {
          dateArr.push(new Date(firstDay));
          firstDay.setDate(firstDay.getDate() + 1);
        }

        dateArr.map((value) => {
          let strDate = moment(value).format("DD/MM/yyyy");
          let showDateStr = moment(value).format("DD-MM");
          let baseline = assetProfile.baseline_emission_factor;

          if (
            emissionByLive?.filter((v) => v.days_months === strDate).length > 0
          ) {
            let result = emissionByLive?.filter(
              (v) => v.days_months === strDate
            )[0];
            data1.push({
              name: showDateStr,
              baselineEmissionIntensity: baselineEmissionFactor
                ? result.historyBaselineEmissionFactor === 0
                  ? result.baselineEmissionFactor || "0"
                  : result.historyBaselineEmissionFactor || "0"
                : "0",
              emissionIntensity: actualEmissionFactor
                ? result.emissionFactor || "0"
                : "0", //(gCo2/km)
              inMotionCarbonEmission: co2Emission ? result.totalEmission || "0" : "0", //(kgCo2)
              baselineEmission: baselineEmission
                ? result.totalBaselineEmission || "0"
                : "0", // kgCo2
              // idlingCarbonEmission: idlingCarbonEmission
              //   ? result.idlingEmission || "0"
              //   : "0",
            });
          } else {
            data1.push({
              name: showDateStr,
              baselineEmissionIntensity: baseline,
              inMotionCarbonEmission: "0",
              emissionIntensity: "0",
              baselineEmission: "0",
              // idlingCarbonEmission: "0",
            });
          }
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      labelFormat = (text) => {
        if (text === "inMotionCarbonEmission") {
          return "In-motion Carbon Emission"
        }
        if (text) {
          return (
            text
              .replace(/([A-Z])/g, " $1")
              .trim()
              .charAt(0)
              .toUpperCase() +
            text
              .replace(/([A-Z])/g, " $1")
              .trim()
              .slice(1)
          );
        }
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
      maxLeftY = Math.ceil(
        Math.max(
          ...data1.map((d) => d.inMotionCarbonEmission),
          ...data1.map((d) => d.baselineEmission)
        )
      );

      // eslint-disable-next-line react-hooks/exhaustive-deps
      maxRightY = Math.ceil(
        Math.max(
          ...data1.map((d) => d.emissionIntensity),
          ...data1.map((d) => d.baselineEmissionIntensity)
        )
      );

      if (vehicleType.value === "Battery") {
        // eslint-disable-next-line react-hooks/exhaustive-deps

        try {
          if (maxLeftY > 1000000) {
            leftYTicks = Array(Math.ceil(maxLeftY / 1000000) + 1)
              .fill({})
              .map((_, i) => i * 1000000);
          } else if (maxLeftY > 100000) {
            leftYTicks = Array(Math.ceil(maxLeftY / 50000) + 1)
              .fill({})
              .map((_, i) => i * 50000);
          } else if (maxLeftY > 10000) {
            leftYTicks = Array(Math.ceil(maxLeftY / 5000) + 1)
              .fill({})
              .map((_, i) => i * 5000);
          } else if (maxLeftY > 1000) {
            leftYTicks = Array(Math.ceil(maxLeftY / 500) + 1)
              .fill({})
              .map((_, i) => i * 500);
          } else if (maxLeftY > 300) {
            leftYTicks = Array(Math.ceil(maxLeftY / 100) + 1)
              .fill({})
              .map((_, i) => i * 100);
          } else if (maxLeftY > 100) {
            leftYTicks = Array(Math.ceil(maxLeftY / 50) + 1)
              .fill({})
              .map((_, i) => i * 50);
          } else {
            leftYTicks = Array(Math.ceil(maxLeftY / 6) + 1)
              .fill({})
              .map((_, i) => i * 5);
          }
        } catch (e) {
          console.log("err>>", e.message);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
        rightYTicks = Array(Math.ceil(maxRightY / 50) + 1)
          .fill({})
          .map((_, i) => i * 50);
      } else {
        if (maxLeftY < 100) {
          leftYTicks = Array(Math.ceil(maxLeftY / 5) + 1)
            .fill({})
            .map((_, i) => i * 5);
        } else {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          leftYTicks = Array(Math.ceil(maxLeftY / 100) + 1)
            .fill({})
            .map((_, i) => i * 100);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        rightYTicks = Array(Math.ceil(maxRightY / 1000) + 1)
          .fill({})
          .map((_, i) => i * 1000);
      }

      CustomTooltip = ({ active, payload, label }) => {
        if (active) {
          
          return (
            <div
              className="custom-tooltip p-2 rounded"
              style={{
                background: theme === "dark" ? Colors.darkBlue : Colors.Dark[5],
                boxShadow: "0px 10px 10px rgba(0,0,0,0.1)",
              }}
            >
              {dateFormat === "Month" ? (
                <div className="label">{`${t("Date")}: ${
                  label + 1
                }-${month}-${year}`}</div>
              ) : (
                <div className="label">{`${t("Date")}: ${label}-${year}`}</div>
              )}
              <hr className="my-0" />
              <div className="label" style={{ color: Colors?.chartColor[0] }}>
                {`${t(labelFormat(payload[0]?.name))}: ${Number(
                  payload[0].value
                ).toLocaleString("en-US")} kgCO₂e`}
              </div>
              {/* <div className="label" style={{ color: Colors?.chartColor[3] }}>
                {`${t(labelFormat(payload[1]?.name))}: ${Number(
                  payload[1].value
                ).toLocaleString("en-US")} kgCO₂e`}
              </div> */}
              <div className="label" style={{ color: Colors?.chartColor[1] }}>
                {`${t(labelFormat(payload[1]?.name))}: ${Number(
                  payload[1].value
                ).toLocaleString("en-US")} kgCO₂e`}
              </div>
              <div className="label" style={{ color: Colors?.fuelLight }}>
                {`${t(labelFormat(payload[2]?.name))}: ${Number(
                  payload[2].value
                ).toLocaleString("en-US")} ${assetProfile?.emission_type_unit === "gCO2e/km" ? "gCO₂e/km" : "gCO₂e/h"}`}
              </div>
              <div className="label" style={{ color: Colors?.yellow }}>
                {`${t(labelFormat(payload[3]?.name))}: ${Number(
                  payload[3].value
                ).toLocaleString("en-US")} ${assetProfile?.emission_type_unit === "gCO2e/km" ? "gCO₂e/km" : "gCO₂e/h"}`}
              </div>
              {/* <p className="intro">{getIntroOfPage(label)}</p> */}
            </div>
          );
        }

        return null;
      };
    } else if (assetProfile?.enableCANbus || assetProfile?.enableGPS) {
      const emissionByLive = assetProfile?.live_manual?.carbonEmission;

      if (dateFormat === "Year") {
        const Months = Month.filter((v) => v.value !== 0);
        let baseline = assetProfile.manualBaselineEmissionPerKm;
        Months.map((v) => {
          if (
            emissionByLive?.filter((em) => em.days_months === v.value).length >
            0
          ) {
            let result = emissionByLive?.filter(
              (em) => em.days_months === v.value
            )[0];

            data1.push({
              name: `${v.label.substring(0, 3)}-${yearSelected % 100}`,
              baselineEmissionIntensity: baselineEmissionFactor
                ? result.historyBaselineEmissionFactor === 0
                  ? result.baselineEmissionFactor || "0"
                  : result.historyBaselineEmissionFactor || "0"
                : "0",
              emissionIntensity: actualEmissionFactor
                ? result.emissionFactor || "0"
                : "0", //(gCo2/km)
              inMotionCarbonEmission: co2Emission ? result.totalEmission || "0" : "0", //(kgCo2)
              baselineEmission: baselineEmission
                ? result.totalBaselineEmission || "0"
                : "0", // kgCo2
              // idlingCarbonEmission: idlingCarbonEmission
              //   ? result.idlingEmission || "0"
              //   : "0",
            });
          } else {
            data1.push({
              name: `${v.label.substring(0, 3)}-${yearSelected % 100}`,
              baselineEmissionIntensity: baselineEmissionFactor
                ? baseline || "0"
                : "0",
              inMotionCarbonEmission: "0",
              emissionIntensity: "0",
              baselineEmission: "0",
              // idlingCarbonEmission: "0",
            });
          }
        });
      } else {
        const firstDay = new Date(startDateFormat);
        const lastDay = new Date(endDateFormat);

        let dateArr = [];
        while (firstDay <= lastDay) {
          dateArr.push(new Date(firstDay));
          firstDay.setDate(firstDay.getDate() + 1);
        }

        dateArr.map((value) => {
          let strDate = moment(value).format("DD/MM/yyyy");
          let showDateStr = moment(value).format("DD-MM");
          let baseline = assetProfile.manualBaselineEmissionPerKm;
          const result = emissionByLive?.filter((v) => {
            return v.days_months === strDate;
          });

          if (
            emissionByLive?.filter((v) => v.days_months === strDate).length > 0
          ) {
            let result = emissionByLive?.filter(
              (v) => v.days_months === strDate
            )[0];
            data1.push({
              name: showDateStr,
              baselineEmissionIntensity: baselineEmissionFactor
                ? result.historyBaselineEmissionFactor === 0
                  ? result.baselineEmissionFactor || "0"
                  : result.historyBaselineEmissionFactor || "0"
                : "0",
              emissionIntensity: actualEmissionFactor
                ? result.emissionFactor || "0"
                : "0", //(gCo2/km)
              inMotionCarbonEmission: co2Emission ? result.totalEmission || "0" : "0", //(kgCo2)
              baselineEmission: baselineEmission
                ? result.totalBaselineEmission || "0"
                : "0", // kgCo2
              // idlingCarbonEmission: idlingCarbonEmission
              //   ? result.idlingEmission || "0"
              //   : "0",
            });
          } else {
            data1.push({
              name: showDateStr,
              baselineEmissionIntensity: baselineEmissionFactor
                ? baseline || "0"
                : "0",
              inMotionCarbonEmission: "0",
              emissionIntensity: "0",
              baselineEmission: "0",
              // idlingCarbonEmission: "0",
            });
          }
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      labelFormat = (text) => {
        if (text === "inMotionCarbonEmission") {
          return "In-motion Carbon Emission"
        }
        if (text) {
          return (
            text
              .replace(/([A-Z])/g, " $1")
              .trim()
              .charAt(0)
              .toUpperCase() +
            text
              .replace(/([A-Z])/g, " $1")
              .trim()
              .slice(1)
          );
        }
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
      maxLeftY = Math.ceil(
        Math.max(
          ...data1.map((d) => d.inMotionCarbonEmission),
          ...data1.map((d) => d.baselineEmission)
        )
      );

      // eslint-disable-next-line react-hooks/exhaustive-deps
      maxRightY = Math.ceil(
        Math.max(
          ...data1.map((d) => d.emissionIntensity),
          ...data1.map((d) => d.baselineEmissionIntensity)
        )
      );

      if (vehicleType.value === "Battery") {
        // eslint-disable-next-line react-hooks/exhaustive-deps

        try {
          if (maxLeftY > 1000000) {
            leftYTicks = Array(Math.ceil(maxLeftY / 1000000) + 1)
              .fill({})
              .map((_, i) => i * 1000000);
          } else if (maxLeftY > 100000) {
            leftYTicks = Array(Math.ceil(maxLeftY / 50000) + 1)
              .fill({})
              .map((_, i) => i * 50000);
          } else if (maxLeftY > 10000) {
            leftYTicks = Array(Math.ceil(maxLeftY / 5000) + 1)
              .fill({})
              .map((_, i) => i * 5000);
          } else if (maxLeftY > 1000) {
            leftYTicks = Array(Math.ceil(maxLeftY / 500) + 1)
              .fill({})
              .map((_, i) => i * 500);
          } else if (maxLeftY > 300) {
            leftYTicks = Array(Math.ceil(maxLeftY / 100) + 1)
              .fill({})
              .map((_, i) => i * 100);
          } else if (maxLeftY > 100) {
            leftYTicks = Array(Math.ceil(maxLeftY / 50) + 1)
              .fill({})
              .map((_, i) => i * 50);
          } else {
            leftYTicks = Array(Math.ceil(maxLeftY / 6) + 1)
              .fill({})
              .map((_, i) => i * 5);
          }
        } catch (e) {
          console.log("err>>", e.message);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
        rightYTicks = Array(Math.ceil(maxRightY / 50) + 1)
          .fill({})
          .map((_, i) => i * 50);
      } else {
        if (maxLeftY < 100) {
          leftYTicks = Array(Math.ceil(maxLeftY / 5) + 1)
            .fill({})
            .map((_, i) => i * 5);
        } else {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          leftYTicks = Array(Math.ceil(maxLeftY / 100) + 1)
            .fill({})
            .map((_, i) => i * 100);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        console.log("maxRightY --->", maxRightY);
        if (maxRightY > 10000) {
          rightYTicks = Array(Math.ceil(maxRightY / 5000) + 1)
            .fill({})
            .map((_, i) => i * 5000);
        } else {
          rightYTicks = Array(Math.ceil(maxRightY / 1000) + 1)
            .fill({})
            .map((_, i) => i * 1000);
        }
      }

      CustomTooltip = ({ active, payload, label }) => {
        if (active) {
          return (
            <div
              className="custom-tooltip p-2 rounded"
              style={{
                background: theme === "dark" ? Colors.darkBlue : Colors.Dark[5],
                boxShadow: "0px 10px 10px rgba(0,0,0,0.1)",
              }}
            >
              {dateFormat === "Month" ? (
                <div className="label">{`${t("Date")}: ${
                  label + 1
                }-${month}-${year}`}</div>
              ) : (
                <div className="label">{`${t("Date")}: ${label}-${year}`}</div>
              )}
              <hr className="my-0" />
              <div className="label" style={{ color: Colors?.chartColor[0] }}>
                {`${t(labelFormat(payload[0]?.name))}: ${Number(
                  payload[0].value
                ).toLocaleString("en-US")} kgCO₂e`}
              </div>
              {/* <div className="label" style={{ color: Colors?.chartColor[3] }}>
                {`${t(labelFormat(payload[1]?.name))}: ${Number(
                  payload[1].value
                ).toLocaleString("en-US")} kgCO₂e`}
              </div> */}
              <div className="label" style={{ color: Colors?.chartColor[1] }}>
                {`${t(labelFormat(payload[1]?.name))}: ${Number(
                  payload[1].value
                ).toLocaleString("en-US")} kgCO₂e`}
              </div>
              <div className="label" style={{ color: Colors?.fuelLight }}>
                {`${t(labelFormat(payload[2]?.name))}: ${Number(
                  payload[2].value
                ).toLocaleString("en-US")} ${assetProfile?.emission_type_unit === "gCO2e/km" ? "gCO₂e/km" : "gCO₂e/h"}`}
              </div>
              <div className="label" style={{ color: Colors?.yellow }}>
                {`${t(labelFormat(payload[3]?.name))}: ${Number(
                  payload[3].value
                ).toLocaleString("en-US")} ${assetProfile?.emission_type_unit === "gCO2e/km" ? "gCO₂e/km" : "gCO₂e/h"}`}
              </div>
              {/* <p className="intro">{getIntroOfPage(label)}</p> */}
            </div>
          );
        }

        return null;
      };
    } else {
      const emissionByManual = assetProfile?.manual?.carbonEmission;
      // const Months = Month.filter((v) => v.value !== 0);
      let baseline = assetProfile?.manualBaselineEmissionPerKm;

      if (dateFormat === "Year") {
        const Months = Month?.filter((v) => v.value !== 0);
        let baseline = assetProfile?.manualBaselineEmissionPerKm;
        Months.map((v) => {
          if (
            emissionByManual?.filter((em) => em.days_months === v.value)
              .length > 0
          ) {
            let result = emissionByManual?.filter(
              (em) => em.days_months === v.value
            )[0];

            data1.push({
              name: `${v.label.substring(0, 3)}-${yearSelected % 100}`,
              baselineEmissionIntensity:
                result.historyBaselineEmissionFactor === 0
                  ? result.baselineEmissionFactor || "0"
                  : result.historyBaselineEmissionFactor || "0",
              emissionIntensity: actualEmissionFactor
                ? result.emissionFactor || "0"
                : "0", //(gCo2/km)
              inMotionCarbonEmission: co2Emission ? result.totalEmission || "0" : "0", //(kgCo2)
              baselineEmission: baselineEmission
                ? result.totalBaselineEmission || "0"
                : "0", // kgCo2
              // idlingCarbonEmission: idlingCarbonEmission
              //   ? result.idlingEmission || "0"
              //   : "0",
            });
          } else {
            data1.push({
              name: `${v.label.substring(0, 3)}-${yearSelected % 100}`,
              baselineEmissionIntensity: baselineEmissionFactor
                ? baseline || "0"
                : "0",
              inMotionCarbonEmission: "0",
              emissionIntensity: "0",
              baselineEmission: "0",
              // idlingCarbonEmission: "0",
            });
          }
        });
      } else {
        const firstDay = new Date(startDateFormat);
        const lastDay = new Date(endDateFormat);

        let dateArr = [];
        while (firstDay <= lastDay) {
          dateArr.push(new Date(firstDay));
          firstDay.setDate(firstDay.getDate() + 1);
        }

        dateArr.map((v) => {
          let strDate = moment(v).format("DD/MM/yyyy");
          let showDateStr = moment(v).format("DD-MM");
          let baseline = assetProfile.manualBaselineEmissionPerKm;

          if (
            emissionByManual?.filter((v) => v.days_months === strDate).length >
            0
          ) {
            let result = emissionByManual?.filter(
              (v) => v.days_months === strDate
            )[0];

            data1.push({
              // name: `${v.label.substring(0, 3)}-${year % 100}`,
              name: showDateStr,
              baselineEmissionIntensity: baselineEmissionFactor
                ? result.historyBaselineEmissionFactor === 0
                  ? result.baselineEmissionFactor || "0"
                  : result.historyBaselineEmissionFactor || "0"
                : "0",
              emissionIntensity: actualEmissionFactor
                ? result.emissionFactor || "0"
                : "0", //(gCo2/km)
              inMotionCarbonEmission: co2Emission ? result.totalEmission || "0" : "0", //(kgCo2)
              baselineEmission: baselineEmission
                ? result.totalBaselineEmission || "0"
                : "0", // kgCo2
              // idlingCarbonEmission: idlingCarbonEmission
              //   ? result.idlingEmission || "0"
              //   : "0",
            });
          } else {
            data1.push({
              // name: `${v.label.substring(0, 3)}-${year % 100}`,
              name: showDateStr,
              baselineEmissionIntensity: baselineEmissionFactor
                ? baseline || "0"
                : "0",
              inMotionCarbonEmission: "0",
              emissionIntensity: "0",
              baselineEmission: "0",
              // idlingCarbonEmission: "0",
            });
          }
        });
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      labelFormat = (text) => {
        if (text === "inMotionCarbonEmission") {
          return "In-motion Carbon Emission"
        }
        if (text) {
          return (
            text
              .replace(/([A-Z])/g, " $1")
              .trim()
              .charAt(0)
              .toUpperCase() +
            text
              .replace(/([A-Z])/g, " $1")
              .trim()
              .slice(1)
          );
        }
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
      maxLeftY = Math.ceil(
        Math.max(
          ...data1.map((d) => d.inMotionCarbonEmission),
          ...data1.map((d) => d.baselineEmission)
        )
      );

      // eslint-disable-next-line react-hooks/exhaustive-deps
      maxRightY = Math.ceil(
        Math.max(
          ...data1.map((d) => d.emissionIntensity),
          ...data1.map((d) => d.baselineEmissionIntensity)
        )
      );

      if (vehicleType.value === "Battery") {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        leftYTicks = Array(Math.ceil(maxLeftY / 100) + 1)
          .fill({})
          .map((_, i) => i * 100);

        // eslint-disable-next-line react-hooks/exhaustive-deps
        rightYTicks = Array(Math.ceil(maxRightY / 5) + 1)
          .fill({})
          .map((_, i) => i * 5);
      } else {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        if (maxLeftY > 1000000) {
          leftYTicks = Array(Math.ceil(maxLeftY / 1000000) + 1)
            .fill({})
            .map((_, i) => i * 1000000);
        } else if (maxLeftY < 1000) {
          leftYTicks = Array(Math.ceil(maxLeftY / 100) + 1)
            .fill({})
            .map((_, i) => i * 100);
        } else if (maxLeftY < 100) {
          leftYTicks = Array(Math.ceil(maxLeftY / 5) + 1)
            .fill({})
            .map((_, i) => i * 5);
        } else {
          leftYTicks = Array(Math.ceil(maxLeftY / 1000) + 1)
            .fill({})
            .map((_, i) => i * 1000);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
        rightYTicks = Array(Math.ceil(maxRightY / 100) + 1)
          .fill({})
          .map((_, i) => i * 100);
      }

      CustomTooltip = ({ active, payload, label }) => {
        if (active) {
          return (
            <div
              className="custom-tooltip p-2 rounded"
              style={{
                background: theme === "dark" ? Colors.darkBlue : Colors.Dark[5],
                boxShadow: "0px 10px 10px rgba(0,0,0,0.1)",
              }}
            >
              <div className="label">{`${t("Month")}: ${label}`}</div>
              <hr className="my-0" />
              <div className="label" style={{ color: Colors?.chartColor[0] }}>
                {`${t(labelFormat(payload[0]?.name))}: ${Number(
                  payload[0].value
                ).toLocaleString("en-US")} kgCO₂e`}
              </div>
              {/* <div className="label" style={{ color: Colors?.chartColor[3] }}>
                {`${t(labelFormat(payload[1]?.name))}: ${Number(
                  payload[1].value
                ).toLocaleString("en-US")} kgCO₂e`}
              </div> */}
              <div className="label" style={{ color: Colors?.chartColor[1] }}>
                {`${t(labelFormat(payload[1]?.name))}: ${Number(
                  payload[1].value
                ).toLocaleString("en-US")} kgCO₂e`}
              </div>
              <div className="label" style={{ color: Colors?.fuelLight }}>
                {`${t(labelFormat(payload[2]?.name))}: ${Number(
                  payload[2].value
                ).toLocaleString("en-US")} ${assetProfile?.emission_type_unit === "gCO2e/km" ? "gCO₂e/km" : "gCO₂e/h"}`}
              </div>
              <div className="label" style={{ color: Colors?.yellow }}>
                {`${t(labelFormat(payload[3]?.name))}: ${Number(
                  payload[3].value
                ).toLocaleString("en-US")} ${assetProfile?.emission_type_unit === "gCO2e/km" ? "gCO₂e/km" : "gCO₂e/h"}`}
              </div>
              {/* <p className="intro">{getIntroOfPage(label)}</p> */}
            </div>
          );
        }

        return null;
      };
    }

    return (
      <div
        className=""
        style={{
          height: 380,
          width: window.innerWidth > 552 ? "100%" : "350px",
        }}
      >
        {!pdf && (
          <>
            <div
              className={
                window.innerWidth < 701
                  ? "d-flex flex-column px-2 pt-3 mb-3"
                  : "d-flex justify-content-center align-items-center mb-3"
              }
              style={{ width: window.innerWidth < 701 ? "100%" : "" }}
            >
              <div
                className={`d-flex ${
                  window.innerWidth < 701
                    ? "flex-column align-items-center"
                    : ""
                } justify-content-between`}
                style={{ width: "100%" }}
              >
                <div
                  className={
                    `d-flex align-items-center justify-content-between w-100`
                    // ${showComparisonSelect === "individual" ? "" : "w-100"
                    //     }`
                  }
                >
                  <div className="d-flex align-items-center">
                    <div>
                      {dateFormat === "Month" ? (
                        <h5 className="mb-0">
                          {t(
                            `Emission Profile - ${dayjs()
                              .month(month - 1)
                              .format("MMMM")}-${yearSelected}`
                          )}
                        </h5>
                      ) : dateFormat === "Year" ? (
                        <h5 className="mb-0">
                          {t(`Emission Profile - ${yearSelected}`)}
                        </h5>
                      ) : dateFormat === "7 Days" || dateFormat === "Custom" ? (
                        <h5 className="mb-0">
                          {t(
                            `Emission Profile - ${startDateFormat} - ${endDateFormat}`
                          )}
                        </h5>
                      ) : (
                        ""
                      )}
                    </div>

                    {showComparisonSelect === "individual" ? (
                      ""
                    ) : (
                      <div
                        className="d-flex justify-content-between align-items-center"
                        style={{ width: "170px" }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <span>CANBus</span>
                          <span className="ps-1 pe-1">:</span>
                          {assetProfile?.enableCANbus ? (
                            <span>
                              <i
                                className="fa fa-check pe-1"
                                style={{ color: "rgb(0, 177, 0)" }}
                              ></i>
                            </span>
                          ) : (
                            <span>
                              <i
                                className="fas fa-times pe-1"
                                style={{ color: "red" }}
                              ></i>
                            </span>
                          )}
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <span>GPS</span>
                          <span className="ps-1 pe-1">:</span>
                          {assetProfile?.enableGPS ? (
                            <span>
                              <i
                                className="fa fa-check pe-1"
                                style={{ color: "rgb(0, 177, 0)" }}
                              ></i>
                            </span>
                          ) : (
                            <span>
                              <i
                                className="fas fa-times pe-1"
                                style={{ color: "red" }}
                              ></i>
                            </span>
                          )}
                        </div>
                      </div>
                    )}

                    <div>
                      {showComparisonSelect === "individual" ? (
                        role === "user" ? (
                          ""
                        ) : (
                          <span
                            className="report-unitLevel-guide"
                            data-bs-toggle="modal"
                            data-bs-target="#newtrackingPdfModal"
                            data-toggle="modal"
                            data-target=".bd-example-modal-lg"
                            onClick={() => setReportDownload(true)}
                          >
                            <button
                              type="button"
                              className={`${
                                theme === "dark"
                                  ? "btn  btn-outline-info btn-sm m-2"
                                  : "btn btn-outline-success btn-sm m-2"
                              }`}
                              style={{
                                fontSize: 12,
                              }}
                            >
                              {t("Create Report")}
                            </button>
                          </span>
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div>
                    <CarbonSelector
                      options={emissionDataOptions}
                      width={"300px"}
                      theme={theme}
                    />
                  </div>
                </div>

                {/* <div className="d-flex align-items-center">
                  <div
                    className="btn-group btn-group-sm"
                    role="group"
                    style={{ width: window.innerWidth < 701 ? "100%" : "" }}
                  >
                    <div
                      type="button"
                      className={`${
                        showChartViewVTwo === "CANBus"
                          ? theme === "dark"
                            ? "btn-custom-canbus"
                            : "btn-custom-canbus-light"
                          : theme === "dark"
                          ? "btn-custom-canbus-inactive"
                          : "btn-custom-canbus-light-inactive"
                      }`}
                      onClick={() => handleShowChartViewVTwo("CANBus")}
                      style={{ zIndex: 0 }}
                    >
                      {t("CANBus")}
                    </div>
                    <div
                      type="button"
                      className={`${
                        showChartViewVTwo === "manual"
                          ? theme === "dark"
                            ? "btn-custom-manual"
                            : "btn-custom-manual-light"
                          : theme === "dark"
                          ? "btn-custom-manual-inactive"
                          : "btn-custom-manual-light-inactive"
                      }`}
                      onClick={() => handleShowChartViewVTwo("manual")}
                      style={{ zIndex: 0 }}
                    >
                      {t("Manual")}
                    </div>
                  </div>
                </div> */}
              </div>
            </div>

            <div
              className="d-flex justify-content-between align-items-center"
              style={{ fontSize: window.innerWidth < 701 ? ".8rem" : "" }}
            >
              <p
                className="text-center"
                style={{
                  maxWidth: 150,
                  color:
                    theme === "dark" ? Colors.lightBlue : carbonEmissionColor,
                  marginLeft: "30px",
                }}
              >
                {/* Carbon Emission(kgCO₂) */} kgCO₂e
              </p>
              <h2
                style={{
                  fontSize: window.innerWidth < 701 ? "1.3rem" : "1.5rem",
                }}
              >
                {assetProfile.vehicle_number_plate
                  ? assetProfile.vehicle_number_plate
                  : "No Data for that vehicle"}
              </h2>
              <p
                className="text-center"
                style={{
                  maxWidth: 150,
                  color: Colors.fuelLight,
                  marginRight: "25px",
                }}
              >
                {/* Emission Factor(gCO₂/km) */}{assetProfile?.emission_type_unit === "gCO2e/km" ? "gCO₂e/km" : "gCO₂e/h"}
              </p>
            </div>
          </>
        )}
        <ResponsiveContainer width="100%" height="110%">
          <ComposedChart
            width={500}
            height={500}
            data={data1}
            margin={{ left: 30, right: 30, top: 10, bottom: 50 }}
          >
            <CartesianGrid
              stroke={
                theme === "dark" ? "rgba(255,255,255,0.1)" : Colors.light.active
              }
              // strokeDasharray="3 3"
            />
            {dateFormat === "Month" ? (
              <XAxis
                // dataKey="name"
                // scale="band"
                tick={{ fill: "currentColor" }}
                tickFormatter={(value) =>
                  dateFormat === "Month" ? value + 1 : value
                }
              />
            ) : (
              <XAxis
                dataKey="name"
                // scale="band"
                tick={{ fill: "currentColor" }}
                angle={-90}
                textAnchor="end"
              />
            )}
            <YAxis
              yAxisId="left"
              orientation="left"
              tick={{
                fill: theme === "dark" ? Colors.lightBlue : carbonEmissionColor,
              }}
              interval={"auto"}
              ticks={leftYTicks}
              tickCount={0}
            ></YAxis>
            <YAxis
              yAxisId="right"
              orientation="right"
              tick={{
                fill: theme === "dark" ? Colors.fuelLight : carbonEmissionColor,
              }}
              interval={
                window.innerWidth > 700 &&
                (rightYTicks?.length > 50
                  ? parseInt(rightYTicks?.length / 10) - 1
                  : 0)
              }
              ticks={rightYTicks}
              tickCount={0}
            ></YAxis>

            <Bar
              yAxisId="left"
              dataKey="inMotionCarbonEmission"
              barSize={10}
              stackId="a"
              //fill={theme === "dark" ? Colors.lightBlue : carbonEmissionColor}
              // fill={Colors.chartColor[0]}
              fill={co2Emission ? "#29B156" : "transparent"}
              // fill={co2Emission ? Colors.chartColor[0] : "transparent"}
            />
            {/* <Bar
              yAxisId="left"
              dataKey="idlingCarbonEmission"
              barSize={10}
              stackId="a"
              //fill={theme === "dark" ? Colors.lightBlue : carbonEmissionColor}
              // fill={Colors.chartColor[0]}
              fill={idlingCarbonEmission ? Colors.chartColor[3] : "transparent"}
            /> */}
            <Bar
              yAxisId="left"
              dataKey="baselineEmission"
              barSize={10}
              //fill={theme === "dark" ? Colors.fuelBaseline : Colors.fuelBaseline}
              fill={baselineEmission ? Colors.chartColor[1] : "transparent"}
            />
            <Line
              yAxisId="right"
              // type="monotone"
              dataKey="emissionIntensity"
              stroke={actualEmissionFactor ? Colors.fuelLight : "transparent"}
              // stroke="#0000FF"
            />

            <Line
              yAxisId="right"
              // type="monotone"
              dataKey="baselineEmissionIntensity"
              stroke={baselineEmissionFactor ? Colors.yellow : "transparent"}
              //stroke="#000000"
            />
            {showTooltip && <Tooltip content={<CustomTooltip />} />}
            <Legend
              wrapperStyle={{
                bottom: window.innerWidth > 460 ? 50 : 50,
                textShadow: "0.5px 0.5px black",
              }}
              formatter={(value, entry, index) => {
                switch (value) {
                  case "inMotionCarbonEmission":
                    value = (
                      <span
                        style={{
                          color: co2Emission ? Colors.chartColor[0] : "silver",
                        }}
                      >
                        {t("In-motion CO₂e Emission")} (kgCO₂e)
                      </span>
                    );
                    break;
                  case "emissionIntensity":
                    value = (
                      <span
                        style={{
                          color: actualEmissionFactor
                            ? Colors.fuelLight
                            : "silver",
                        }}
                      >
                        {t("Actual Emission Intensity")} ({assetProfile?.emission_type_unit === "gCO2e/km" ? "gCO₂e/km" : "gCO₂e/h"})
                      </span>
                    );
                    break;
                  case "baselineEmission":
                    value = (
                      <span
                        style={{
                          color: baselineEmission
                            ? Colors.chartColor[1]
                            : "silver",
                        }}
                      >
                        {t("Baseline Emission")} (kgCO₂e)
                      </span>
                    );
                    break;
                  case "baselineEmissionIntensity":
                    value = (
                      <span
                        style={{
                          color: baselineEmissionFactor
                            ? Colors.yellow
                            : "silver",
                        }}
                      >
                        {t("Baseline Emission Intensity")} ({assetProfile?.emission_type_unit === "gCO2e/km" ? "gCO₂e/km" : "gCO₂e/h"})
                      </span>
                    );
                    break;
                  // default:
                  //   value = (
                  //     <span
                  //       style={{
                  //         color: idlingCarbonEmission
                  //           ? Colors.chartColor[3]
                  //           : "silver",
                  //       }}
                  //     >
                  //       {t("Idling CO₂e Emission")} (kgCO₂e)
                  //     </span>
                  //   );
                  //   break;
                }
                return value;
              }}
            />
          </ComposedChart>
        </ResponsiveContainer>
        <TrackingFileExportModal
          showView={showView}
          showChartView={showChartView}
          showChartViewVTwo={showChartViewVTwo}
        />
      </div>
    );
  })
);
