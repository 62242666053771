import { useTranslation } from "react-i18next";
import { Colors } from "../../Assets/Config/color.config";
import { AssetProfileForm } from "./AssetProfileForm";

export const AssetProfileModal = (props) => {
    const {
        theme,
        deviceData,
        typeOptions,
        selectedType,
        _handleSelectType,
        tableIndex,
        _handleTextChange,
        fetchUpdateDevice
      }=props
      const {t} = useTranslation("asset_profile_management")
      
  return (
    <div
      className="modal fade"
      id="assetProfileModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl rounded">
        <div
          className="modal-content"
          style={{ background: Colors[theme].background }}
        >
          <div className="modal-header" style={{ border: "none" }}>
            <h4>{t("Asset Profile")} ({deviceData?.device})</h4>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="">
             {deviceData&& <AssetProfileForm
              {...props}
                theme={theme}
                deviceData={deviceData}
                typeOptions={typeOptions}
                selectedType={selectedType}
                _handleSelectType={_handleSelectType}
                tableIndex={tableIndex}
                _handleTextChange={_handleTextChange}
                fetchUpdateDevice={fetchUpdateDevice}
              />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
