import { UnfoldLess } from "@mui/icons-material";

const specialCharacterRegx = /[`!#$%^&*()+\-=[\]{};:"\\|<>/?~]/;
// const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)+$/;
const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)+$/;


export const LoginFormValidation = ({ eMail, password }) => {
  const err = {};
  if (eMail === "" || !eMail) {
    err.userErr = "Email can't be empty!";
  } else if (!emailRegex.test(eMail)) {
    err.userErr = "Invalid email address!";
  } else if (password === "") {
    err.passwordErr = "Password can't be empty!";
  }
  return err;
};

export const RegisterFormValidator = (data) => {
    const err = {};
    const { name,country, company_incorporation, level, sector,  website, company_size, address, revenue, isCompact_signup, isWrittenEnv_policy, isDeclarationOfLegalBusiness, first_name, last_name , email, password, confirmPassword, role, label, companyId , registration_number} = data;

    if(companyId === undefined || companyId === "" ) {
      err.companyErr = "Please select company"
    }

    if(country === undefined || country === ""){
      err.countryErr = "Please select country"
    }
    
    if(name === undefined || name === "") {
      err.nameErr = "Please enter company name"
    }

    if(company_incorporation === undefined || company_incorporation === "") {
      err.incorporationErr = "Please select company incorporation"
    }

    if(level === undefined || level === "") {
      err.levelErr = "Please select company level"
    }

    if (sector === undefined || sector === "" ) {
      err.industrySectorErr = "Please select industry sector"
    }

    if (address === undefined || address === "" ) {
      err.addressErr = "Please enter company address"
    }
    if (website === undefined || website === "" ) {
      err.websiteErr = "Please enter company website"
    }
    if (company_size === undefined || company_size === "" ) {
      err.sizeErr = "Please select company size"
    }
    if (revenue === undefined || revenue === "" ) {
      err.revenueErr = "Please select company revenue"
    }
    if (isCompact_signup === undefined || isCompact_signup === "" || isCompact_signup === null || isCompact_signup === "null" || isCompact_signup === "undefined") {
      err.signupErr = "Please select UN Global Compact SignUp"
    }
    if (isWrittenEnv_policy === undefined || isWrittenEnv_policy === "" ) {
      err.policyErr = "Please select Environmental Policy"
    }

    if (registration_number === undefined || registration_number === "" ) {
      err.registErr = "Please enter Registration Number"
    }

    if ( isDeclarationOfLegalBusiness === undefined || isDeclarationOfLegalBusiness === "" ) {
      err.declareErr = "Please select if this Business is Legally Declared or Not"
    }

    if(first_name === "" || first_name === undefined){
        err.firstNameErr = "Please enter first name";
    }

    if(last_name === "" || last_name === undefined){
      err.lastNameErr = "Please enter last name";
  }

    if(email === ""){
        err.emailEmptyErr = "Please enter email"
    }

    if(!emailRegex.test(email)){
        err.emailErr = "Invalid email address"
    }

    if(password === ""){
      // err.passwordErr="";
        err.passwordErr = "Please enter password";
    }

    if(password !== "" && !/\d/.test(password)){
      // err.passwordErr = "";
      err.passwordErr = "Password should include numeric characters";
    }

    if(password !== "" && !/[a-zA-Z]/.test(password)){
      // err.passwordErr="";
      err.passwordErr = "Password should include alphabetic characters";
    }

    if(confirmPassword === ""){
        err.confirmPasswordErr = "Please enter confirm password"
    }

    if(password != confirmPassword){
        err.passwordMatchErr = "Password & confirm password are not match" 
    }

    if(label === undefined || label === '') {
      err.labelErr = "Please select a company"    }
    
    if( role == undefined || role === '' ) {
      err.roleErr = "Please select role"
    }

    return err;
}

export const UpdateFormValidator = (data) => {
  const err = {};
  const { name, country, type} = data;

  if(name === undefined || name === "") {
    err.nameErr = "Please enter company name"
  }

  if(type === undefined || type === "") {
    err.typeErr = "Please enter company type"
  }

  if(country === undefined || country === ""){
    err.countryErr = "Please select country"
  }

  return err;
}

export const CompanyRegisterFormValidator = (data) => {
  const err = {};
  const { incorporation, sector, website, phone, size, revenue, designation, registration_number   } = data;

  if(incorporation === undefined || incorporation === "") {
    err.incorporationErr = "Please select incorporation"
  }

  if (sector === undefined || sector === "" ) {
    err.sectorErr = "Please enter industrial sector"
  }

  if (website === undefined || website === "") {
    err.websiteErr = "Please enter company website"
  }

  if (phone === undefined || website === "" ) {
    err.phoneErr = "Please enter company phone number"
  }

  if (size === undefined || size === "" ) {
    err.sizeErr = "Please select company size"
  }

  if (revenue === undefined || revenue === "" ) {
    err.revenueErr = "Please enter company revenue"
  }

  if(designation === "" || designation === undefined){
      err.designationErr = "Please enter designation";
  }
  return err;
}

export const RegisterFormValidation = (data) => {
  const err = {};
  const { password, userName } = data;
  if (userName === "") {
    err.userNameErr = "Fill User Name!";
  } else if (specialCharacterRegx.test(userName)) {
    err.userNameErr = "Not Allow Special Characters";
  }
  if (password === null) {
    return err;
  } else if (password === "") {
    err.passwordErr = "Fill Password!";
  } else if (password.length < 8) {
    err.passwordErr = "Minium 8 Characters";
  }else if(password !== "" && !/\d/.test(password)){
    err.passwordErr = "Should include numeric characters";
  }else if(password !== "" && !/[a-zA-Z]/.test(password)){
    err.passwordErr = "Should include alphabetic characters";
  }

  return err;
};

export const AssetFormValidation = (data) => {
  const err = {};
  const { asset_type,brand, fuel_type,battery_type,  vehicle_number_plate, oem_baseline_emission, model, vin_number, color } = data;
  
  if (!asset_type) {
    err.asset_type = "Please select asset type!";
  } 

  if (!brand){
    err.brand = "Please enter brand!";
  }

  if (!vehicle_number_plate){
    err.vehicle_number_plate = "Please enter plate number!";
  }

  if (!fuel_type) {
    err.fuel_type = "Please select fuel type!";
  } 

   if (!oem_baseline_emission){
    err.oem_baseline_emission = "Please enter OEM baseline emission!";
  }

   if (!model){
    err.model = "Please enter model!";
  }

   if (!vin_number){
    err.vin_number = "Please enter VIN number!";
  }
  if (fuel_type==="Battery" && !battery_type){
    err.battery_type = "Please enter battery type!";
  }

  return err;

};


export const liteEntryFormValidation = (entryData) => {
  const err = {};
  const { asset_id, vehicle_type,type, consumed, type_unit, mileage, mileage_unit, report_type, start_time } = entryData;
  // console.log('vlidation', asset_id,vehicle_type)
  if (!asset_id) {
    err.asset_id = "Please enter Asset ID!";
  } 
  else if (!vehicle_type) {
    err.vehicle_type = "Please select Vehicle Type!";
  } 
  else if (!type) {
    err.type = "Please select Fuel Type!";
  } 
  else if (!consumed) {
    err.consumed = "Please select Fuel Consumption";
  } 
  // else if (!type_unit) {
  //   err.type_unit = "Please select Fuel Unit!";
  // } 
  else if (!mileage) {
    err.mileage = "Please select Mileage!";
  } 
  // else if (!mileage_unit) {
  //   err.mileage_unit = "Please select Mileage Unit!";
  // } 
  else if (!report_type) {
    err.report_type = "Please select Report Type!";
  } 
  else if (!start_time) {
    err.start_time = "Please select Time(year/month)!";
  } 

  return err;
};

export const liteCreateReportValidation = (entryData) => {
  const err = {};
  const { asset_id, vehicle_type,type, consumed, type_unit, mileage, mileage_unit, report_type, start_time } = entryData;
  // console.log('vlidation', asset_id,vehicle_type)
  if (!asset_id) {
    err.asset_id = "Please select Asset ID!";
  }
  else if (!consumed) {
    err.consumed = "Please select Fuel Consumption";
  } 
  
  else if (!mileage) {
    err.mileage = "Please select Mileage!";
  } 
  else if (!report_type) {
    err.report_type = "Please select Report Type!";
  } 
  else if (!start_time) {
    err.start_time = "Please select Time(year/month)!";
  } 

  return err;
};