import * as API from "./URL";

export const getUnits = async (token, asset, companyId, callback) => {
    return fetch(`${API.getUnits}?companyId=${companyId}&asset=${asset}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "x-access-token": token,
        },
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err, null));
}

export const getAllFMSDevice = async (token, asset, companyId, callback) => {
    return fetch(`${API.getAllFMSDeviceAPI}?asset=${asset}&companyId=${companyId}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "x-access-token": token,
        },
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err, null));
}

export const getAllDevice = async (token, data, callback) => {
    const searchParams = new URLSearchParams(data);

    return fetch(`${API.getAllDeviceAPI}?${searchParams.toString()}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "x-access-token": token,
        },
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err, null));
};

export const updateDevice = async (token, data, callback) => {
    return fetch(`${API.updateDeviceWithIdAPI}/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "x-access-token": token,
        },
        body: JSON.stringify(data),
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err, null));
};

export const getAllAssetType = async (companyId, token, callback) => {
    return fetch(`${API.getAllAssetTypeAPI}/?companyId=` + companyId, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "x-access-token": token,
        },
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err, null));
};

export const getAllScheduleList = async (token, callback) => {
    return fetch(`${API.getAllScheduleListAPI}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "x-access-token": token,
        },
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err, null));
};

export const getAlgorithmParam = async (token, callback) => {
    return fetch(`${API.getAlgorithmParamAPI}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "x-access-token": token,
        },
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err, null));
};

export const addNewDevice = async (token, companyId, data, callback) => {
    return fetch(`${API.addNewDeviceAPI}?companyId=${companyId}`, {
        method: "POST",
        headers: {
            // "Content-Type": "application/json",
            Accept: "*/*",
            "x-access-token": token,
        },
        body: data,
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err, null));
};

//getDeviceThresholdAPI
export const getDeviceThreshold = async (unitId, token) => {
    try {
      const response = await fetch(`${API.getDeviceThresholdAPI}?unitId=${unitId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          "x-access-token": token,
        },
      });
  
      if (response.status === 200) {
        const data = await response.json();
        return data; 
      } else {
        console.log('API request failed with status:', response.status);
        throw new Error('API request failed');
      }
    } catch (err) {
      console.log('An error occurred:', err);
      throw err;
    }
  };
  


export const editDeviceMinMaxThreshold = async (token, data) => {
    try {
      const response = await fetch(API.editDeviceMinMaxThresholdAPI, {
        method: "PUT",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json", 
          "x-access-token": token,
        },
        body: JSON.stringify({data : data}), 
      });
  
      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }
  
      const responseData = await response.json();
  
      return responseData;
    } catch (error) {
      throw error;
    }
  };
  
  

export const editDeviceAPI = async (token, companyId, data, callback) => {
    return fetch(`${API.editDeviceAPI}?companyId=${companyId}`, {
        method: "PUT",
        headers: {
            // "Content-Type": "multipart/formdata",
            Accept: "*/*",
            "x-access-token": token,
        },
        body: data,
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err, null));
};

export const editMinMaxDeviceAPI = async (token, companyId, data, callback) => {
    return fetch(`${API.editMinMaxDeviceAPI}?companyId=${companyId}`, {
        method: "PUT",
        headers: {
            // "Content-Type": "multipart/formdata",
            Accept: "*/*",
            "x-access-token": token,
        },
        body: data,
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err, null));
};

export const removeDevice = async (uid, companyId, token, callback) => {
    return fetch(`${API.removeDeviceAPI}?uid=${uid}&companyId=${companyId}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "x-access-token": token,
        },
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err, null));
};

export const getLvlCompanies = async (token, companyId, callback) => {
    return fetch(`${API.lvlCompanies}?companyId=${companyId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        "x-access-token": token,
      },
    })
      .then((res) => res.json())
      .then((data) => callback(null, data))
      .catch((err) => callback(err, null));
  };

export const changeOwner = async (token, unitId, companyId, data, callback) => {
return fetch(`${API.changeOwner}?unitId=${unitId}&companyId=${companyId}`, {
    method: "PUT",
    headers: {
    "Content-Type": "application/json",
    Accept: "*/*",
    "x-access-token": token,
    },
    body: JSON.stringify(data),
})
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};


export const getEmissionTypeUnits = async (token, callback) => {
    return fetch(`${API.getEmissionTypeUnitsAPI}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "x-access-token": token,
        },
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err, null));
}