import { useState } from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { inject, observer } from "mobx-react";
import { My_Button } from "../../Tools";

const MonthlyMissingDataTable = (props) => {
    const {
        month,
        monthlyEntry,
        index,
        theme,
        _handleMonthSelect,
        section
    } = props;
    const [isExpand, setIsExpand] = useState({});
    const _handleExpand = (title) => {
        var obj = { ...isExpand };
        obj[title] = !obj[title];
        setIsExpand(obj);
    };
    const rotate = isExpand[month] ? "rotate(180deg)" : "rotate(0deg)";


    return (
        <div
            style={{
                background: null,
                borderBottom: `1px solid rgba(100,100,100,0.2)`,
                width: "100%",
                height: isExpand[month] ? "auto" : 40,
                padding: 10,
            }}
            id={section}
        >
            <div
                onClick={() => _handleExpand(month)}
                className="align-items-center d-flex justify-content-between"
            >
                <h6>{month}</h6>
                <div>
                    <a href={`#${section}`}>
                        <i
                            className="fas fa-angle-down"
                            style={{
                                cursor: 'pointer',
                                color: Colors.lightBlue,
                                transform: rotate,
                                transition: "all 0.2s linear",
                            }}
                        /></a>
                </div>
            </div>
            {isExpand[month] && (
                <>
                    <div
                        className="mt-2 rounded"
                        style={{ height: "auto", border: ` 1px solid ${invertDark(theme)}` }}
                    >
                        <div className="row p-2" style={{ color: invertDark(theme) }}>
                            <strong className="col-3">License Plate</strong>
                            <strong className="col-3 text-center">Fuel Consumed</strong>
                            <strong className="col-3 text-center">Electricity Consumed</strong>
                            <strong className="col-3 text-center">Trip Mileage</strong>
                        </div>
                        <div
                            className="mb-2"
                            style={{ height: "1px", background: invertDark(theme), width: "100%" }}
                        ></div>
                        <div className="pb-2">
                            {monthlyEntry &&
                                monthlyEntry.map((d, i) => (
                                    <div className="row px-2" key={i}>
                                        <span className="col-3">{d.licensePlate}</span>
                                        <span className="col-3 text-center">
                                            {(d.fuelConsumed === 0  || d.fuelConsumed === null)
                                                ? (
                                                    <i
                                                        className="fas fa-exclamation-circle"
                                                        style={{ color: Colors.medium }}
                                                    ></i>
                                                ) : (
                                                    <i
                                                        className="fas fa-check-circle"
                                                        style={{ color: Colors.low }}
                                                    ></i>
                                                )}
                                        </span>
                                        <span className="col-3 text-center">
                                            {(d.batteryConsumed === 0 || d.batteryConsumed === null)
                                                ? (
                                                    <i
                                                        className="fas fa-exclamation-circle"
                                                        style={{ color: Colors.medium }}
                                                    ></i>
                                                ) : (
                                                    <i
                                                        className="fas fa-check-circle"
                                                        style={{ color: Colors.low }}
                                                    ></i>
                                                )}
                                        </span>
                                        <span className="col-3 text-center">
                                            {d.ascentTripMileage === 0 ? (
                                                <i
                                                    className="fas fa-exclamation-circle"
                                                    style={{ color: Colors.medium }}
                                                ></i>
                                            ) : (
                                                <i
                                                    className="fas fa-check-circle"
                                                    style={{ color: Colors.low }}
                                                ></i>
                                            )}
                                        </span>
                                    </div>
                                ))}
                        </div>
                    </div>
                    <div className="text-end pt-2" id="clickbox2">
                        <My_Button
                            small
                            customColor={Colors.lightBlue}
                            text={"Fill Data"}
                            onClick={() => _handleMonthSelect(index)}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default inject("store")(observer(MonthlyMissingDataTable));
