import React, { useEffect, useRef } from "react";
import { select, scaleBand, axisBottom, axisLeft, stack, max, scaleLinear,scaleOrdinal } from "d3";
import { Colors, invertDark } from "../Config/color.config";
import zIndex from "@mui/material/styles/zIndex";
import { useTranslation } from "react-i18next";

/**
 * Component that renders a StackedBarChart
 */

function StackedBarChart({
  data,
  keys,
  theme,
  currentCo2Emission,
  scope1_currentCo2Emission, 
  scope2_currentCo2Emission, 
  scope3_currentCo2Emission,
  baseLineCo2Emission,
  scope1_baseLineCo2Emission, 
  scope2_baseLineCo2Emission, 
  scope3_baseLineCo2Emission,
  offset,
  Scope1,
  Scope2,
  Scope3
}) {
  // const m = { top: 20, right: 20, bottom: 20, left: 20 };
  const svgRef = useRef();
  useEffect(() => {
    var yPosition = [];


    const svg = select(svgRef.current);
    const width = 225;
    const height = 350;
    svg.selectAll("*").remove();

    // stacks / layers
    const stackGenerator = stack().keys(keys);
    const layers = stackGenerator(data);
    console.log(layers);
    const extent = [
      0,
      max(layers, (layer) => max(layer, (sequence) => sequence[1])),
    ];
    var baseline_color = scaleOrdinal()
    .domain(keys)
    .range([Colors.Scope1_lightBlue,Colors.Scope2_lightBlue,Colors.Scope3_lightBlue])

    var current_color = scaleOrdinal()
    .domain(keys)
    .range([Colors.Scope1_fuelLight,Colors.Scope2_fuelLight,Colors.Scope3_fuelLight])
    // scales
    const xScale = scaleBand()
      .domain(data.map((d) => d.data))
      .range([0, width])
      .padding(0.25);

    const xScale1 = scaleBand().range([0, 375]);

    const yScale = scaleLinear().domain(extent).range([height, 0]);

    const differencePercent = (a, b, c) => {
      return (b / a / c) * 100;
    };
    // example

    var bars=svg
      .selectAll(".layer")
      .data(layers)
      .join("g")
      .attr("class", "layer")
      .selectAll("rect")
      .data((layer) => {return layer.map(d=> {
        return {...d,KEY:layer.key}
      });}).enter();
      bars.append("rect")
      .attr("fill", (sequence) =>
        (sequence.KEY === "Scope1" && Scope1) || (sequence.KEY === "Scope2" && Scope2) || (sequence.KEY === "Scope3" && Scope3)
          ? (xScale(sequence.data.data) > 100 ? current_color(sequence.KEY) : baseline_color(sequence.KEY)) :
          null    
      )
      .attr("x", (sequence) =>
        xScale(sequence.data.data) > 100 ? xScale(sequence.data.data) + 116 : 60
      )
      .attr("width", xScale.bandwidth())
      .attr("stroke", (sequence) =>
        (sequence.KEY === "Scope1" && Scope1) || (sequence.KEY === "Scope2" && Scope2) || (sequence.KEY === "Scope3" && Scope3)
          ? (theme === "dark" ? Colors.darkBlue : Colors.Dark[5]) :
          (theme === "dark" ? (xScale(sequence.data.data) > 100 ? "#151E4A" : "#17214F") : "#FFFFFF")
      )
      .attr("y", (sequence) => {
        yPosition.push(yScale(sequence[1]) + 40);
        return yScale(sequence[1]) + 40;
      })
      .attr("height", 0);
      bars.append("text")
      .attr("fill", "#181F4A")
      .attr("text-anchor", "middle")
      .attr("x", (sequence) =>
        xScale(sequence.data.data) > 100 ? xScale(sequence.data.data) + 153 : 97
      )
      .attr("y", (sequence) => {
        return yScale(sequence[1]) + 50;
      })
      .attr("font-weight", "bold")
      .attr("font-size", "10px")
      .text((sequence) => xScale(sequence.data.data) > 100 ?
        (sequence.KEY === "Scope3" ?
          data[1].Scope3 && Scope3 ? Number(parseFloat(scope3_currentCo2Emission).toFixed(5)).toLocaleString("en-US") : null
          :
          sequence.KEY === "Scope2" ?
            data[1].Scope2 && Scope2 ? Number(parseFloat(scope2_currentCo2Emission).toFixed(5)).toLocaleString("en-US") : null
            :
            sequence.KEY === "Scope1" ?
              data[1].Scope1 && Scope1 ? Number(parseFloat(scope1_currentCo2Emission).toFixed(5)).toLocaleString("en-US") : null
              : null)
        :
        (sequence.KEY === "Scope3" ?
          data[0].Scope3 && Scope3 ? Number(parseFloat(scope3_baseLineCo2Emission).toFixed(5)).toLocaleString("en-US") : null
          :
          sequence.KEY === "Scope2" ?
            data[0].Scope2 && Scope2 ? Number(parseFloat(scope2_baseLineCo2Emission).toFixed(5)).toLocaleString("en-US") : null
            :
            sequence.KEY === "Scope1" ?
              data[0].Scope1 && Scope1 ? Number(parseFloat(scope1_baseLineCo2Emission).toFixed(5)).toLocaleString("en-US") : null
              : null))

    const xAxis = axisBottom(xScale1);
    const yAxis = axisLeft(yScale);
    svg
      .append("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(35, ${height + 42})`)
      .style("color", theme === "dark" ? null : Colors.Dark[2])
      .style("stroke-width", "1px")
      .call(xAxis);
    svg.append("g")
      .attr("class", "y-axis")
      .attr("transform", `translate(35,42)`)
      .call(yAxis)
      .style("color", theme === "dark" ? null : Colors.Dark[2])
      .style("stroke-width", "1px")
    localStorage.setItem(
      "yPosition",
      JSON.stringify(
        yPosition.filter(function (n) {
          return n;
        })
      )
    );

    const fuelLabel = JSON.parse(localStorage.getItem("yPosition"))
      .slice(0, 4)
      .sort(function (a, b) {
        return b - a;
      });

    svg
      .append("text")
      .attr("fill", theme === "dark" ? "#181F4A" : "#fff")
      .attr("text-anchor", "middle")
      .attr("x", 37)
      .attr("y", yPosition[0] + 10)
      .attr("font-size", "10px")
      .text(data[0].Diesel ? null : null)
      .append("tspan")
      .attr("y", yPosition[4] - 30)
      .attr("x", 97)
      .attr("font-weight", "bold")
      .attr("font-size", "12px")
      .style("fill", invertDark(theme))
      .text(
        Number(parseFloat(baseLineCo2Emission).toFixed(5)).toLocaleString(
          "en-US"
        )
      )
      .append("tspan")
      .attr("y", yPosition[4] - 10)
      .attr("x", 97)
      .text("tonnes");

    svg
      .append("text")
      .attr("fill", theme === "dark" ? "#181F4A" : "#fff")
      .attr("text-anchor", "middle")
      .attr("x", 337)
      .attr("y", yPosition[1] + 10)
      .attr("font-size", "10px")
      .text(data[1].Diesel ? null : null)
      .append("tspan")
      .attr("fill", "white")
      .attr("text-anchor", "middle")
      .attr("x", 337)
      .attr("y", yPosition[3] + 10)
      .attr("font-size", "10px")
      .text(() => {
        return data[1].Petrol &&
          differencePercent(data[1].Diesel, data[1].Petrol, data[1].Electric) >
            2
          ? null
          : null;
      })
      .append("tspan")
      .attr("x", 355)
      .attr("font-size", "12px")
      .attr("font-weight", "bold")
      .attr("y", yPosition[5] - 30)
      .attr("x", 277)
      .style("fill", invertDark(theme))
      .text(
        Number(parseFloat(currentCo2Emission).toFixed(5)).toLocaleString(
          "en-US"
        )
      )
      .append("tspan")
      .attr("y", yPosition[5] - 10)
      .attr("x", 277)
      .text("tonnes");

    svg
      .selectAll("rect")
      .transition()
      .duration(500)
      .attr("y", (sequence) => {
        yPosition.push(yScale(sequence[1]) + 40);
        return yScale(sequence[1]) + 40;
      })
      .attr("height", (sequence) => yScale(sequence[0]) - yScale(sequence[1]))
      .delay((d, i) => {
        return i * 30;
      });
  }, [theme, data, baseLineCo2Emission, currentCo2Emission]);
  const {t} = useTranslation("dashboard")

  return (
    <div style={{ paddingTop: 8, color: "white", width: 375 }}>
      <div
        className="d-flex justify-content-around fw-bold text-center pb-3"
        style={{
          color: theme !== "dark" ? Colors.Dark[2] : Colors.Dark[4],
          fontSize: 15,
        }}
      >
        <span className="col-2 ms-2">{t("Baseline Emission Profile")}</span>
        <span className="col-2 me-2">{t("Current Emission Profile")}</span>
      </div>
      <div style={{  zIndex: 100 }}>
        <svg ref={svgRef} id="StackBar" />
      </div>
    </div>
  );
}

export default StackedBarChart;

//Target line
// svg
//   .append("line")
//   .attr("x1", 316)
//   .attr("x2", 83)
//   .attr("y1", "74%")
//   .attr("y2", "74%")
//   .attr("stroke-width", 6)
//   .attr("stroke", theme === "dark" ? Colors.lightBlue : Colors.darkBlue)
//   .attr("margin");
