import { inject, observer } from "mobx-react";
import { My_Button } from "../../Tools";
import { invertDark, Colors} from "../../Assets/Config/color.config";
import { useTranslation } from "react-i18next";

export const ResendCertificateConfirmModal = inject("store")(
  observer((props) => {
    const {t} = useTranslation("track_and_trace")
    const { theme, _handleUpdateCertificates, _handleUpdateCertificatesV2, id } = props;
    const cardStyle = { color: invertDark(theme), background: Colors[theme].background }

    return (

      <div className="modal fade"
        id="resendcertificateconfirmmodal"
        tabIndex="-1"
        aria-labelledby="resendcertificateconfirmmodal"
        aria-hidden="true"
        >

        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content" style={{background: theme === "dark"? "#202859" : "#ffffff"}} >
            <div className="modal-header" style={{justifyContent:"start", borderBottom: "1px solid #454B73"}}>
                <i className="bi bi-exclamation-triangle-fill" style={{ color: theme === "dark"? "yellow" : "orange", marginRight: 4}}></i>
                <h1 className="modal-title fs-5 text-warning" id="resendcertificateconfirmmodal">{t("Warning")}</h1>
            </div>
            <div className="modal-body">
              <div style={{ fontSize: 18, color: theme === 'dark' ? 'white' : 'black'}}>
                {t("Are you sure you want to update this?")}
              </div>
            </div>
            <div className="modal-footer" style={{borderTop: "1px solid #454B73"}}>
              <div className="d-flex justify-content-end">
                <span className="me-2" data-bs-dismiss="modal">
                  <My_Button
                    customColor="#1976d2"
                    customHoverColor="#0d6efd"
                    text={t("Cancel")}
                  />
                </span>
                <span data-bs-dismiss="modal" onClick={() => _handleUpdateCertificatesV2(id)}>
                  <My_Button
                    customColor="#1976d2"
                    customHoverColor="#0d6efd"
                    text={t("Confirm")}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>

      </div>
      

    );
  }));

