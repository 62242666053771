import {
  action,
  makeObservable,
  observable,
  ObservableMap,
  runInAction,
  toJS,
} from "mobx";
import moment from "moment";
import Cookies from "universal-cookie";
import { baseURL } from "../network/URL";
import { toast } from "react-toastify";
import common from "./common";
import {
  getProfileAndDailyData,
  getProfileAndDailyDataV2,
} from "../network/trackingAPI";
import dayjs from "dayjs";
import { th } from "date-fns/locale";

class Tracking {
  cookies = new Cookies();
  token = this.cookies.get("accessToken");
  companyId = this.cookies.get("company");
  year = moment().year();

  month = moment().month() + 1;

  isAds = common.isAds;
  selectedAsset = null;
  selectedUid = null;
  selectedAssetName = null;
  selectedAssetId = this.selectedAsset;
  assetProfile = null;
  assetProfiles = [];
  vehicleList = null;
  selectedPlate = "GBL6140C-5005320";
  fmsVehicleLst = null;
  selectedVehicle = null;
  //   vehicleType = { value: "Battery", label: "EV", };
  vehicleType = {};
  vehicleOptions = [];
  showView = "chart";
  trackingDetail = null;
  trackingDetailVTwo = null;
  selectedDetail = null;
  selectedIndex = 0;
  theme = localStorage.getItem("theme");
  showChartView = this.unitSetting?.enableCANbus === true ? "daily" : "monthly";
  showChartViewVTwo = 'CANBus';
  forallCompanyVehicleOptions = [];
  forallCompanyVehicles = null;
  unitSetting = null;
  showComparisonSelect = "individual";
  number_plates = [];
  vehicleIds = [];
  selectedIndex = "";

  sessionExpire = false;
  placeholder = false;
  isLoading = false;

  dateFormat = "Month";
  yearSelected = new Date().getFullYear();

  startDate = "";
  endDate = "";

  startDateFormat = "";
  endDateFormat = "";

  constructor() {
    this.fetchVehicleList = this.fetchVehicleList.bind(this);
    this.fetchAssetProfile = this.fetchAssetProfile.bind(this);
    this.fetchFMSVehicles = this.fetchFMSVehicles.bind(this);
    this.fetchTrackingDetail = this.fetchTrackingDetail.bind(this);
    this.fetchTrackingDetailV2 = this.fetchTrackingDetailV2.bind(this);
    this.fetchGetAllCompanyVehicles =
      this.fetchGetAllCompanyVehicles.bind(this);

    makeObservable(this, {
      theme: observable,
      showChartView: observable,
      showChartViewVTwo: observable,
      selectedIndex: observable,
      selectedDetail: observable,
      unitSetting: observable,
      number_plates: observable,
      showView: observable,
      showComparisonSelect: observable,
      year: observable,
      token: observable,
      companyId: observable,
      trackingDetail: observable,
      trackingDetailVTwo: observable,
      selectedAsset: observable,
      vehicleList: observable,
      assetProfile: observable,
      assetProfiles: observable,
      selectedIndex: observable,
      vehicleIds: observable,
      placeholder: observable,
      isLoading: observable,
      dateFormat: observable,
      startDate: observable,
      endDate: observable,
      startDateFormat: observable,
      endDateFormat: observable,

      handleYearSelect: action.bound,
      handleVehicleSelect: action.bound,
      handleRowSelect: action.bound,
      sessionExpire: observable,
      month: observable,
      handleMonthSelect: action.bound,
      fmsVehicleLst: observable,
      handleVehicleSelected: action.bound,
      selectedVehicle: observable,
      vehicleType: observable,
      handleSelectedVehicleType: action.bound,
      handleSelectedRowForFMS: action.bound,
      handleViewChange: action.bound,
      handleGetVehicleType: action.bound,
      handleSelecteDetail: action.bound,
      handleShowChartView: action.bound,
      handleShowChartViewVTwo: action.bound,
      fetchFMSVehicles: action.bound,
      fetchGetAllCompanyVehicles: action.bound,
      forallCompanyVehicles: observable,
      handleComparisonSelectChange: action.bound,
      fetchAssetProfileAndDailyData: action.bound,
      // fetchAssetProfileAndDailyDataV2: action.bound,
      _handleSelectYear: action.bound,
      setSelectDateFormat: action.bound,
      setSelectedIndex: action.bound,
      setPlaceHolder: action.bound,
      setLoading: action.bound,
      _handleGetDaysRange: action.bound,
      _handleSelectCustom: action.bound,
    });
  }

  _handleSelectCustom(date, dateString) {
    this.startDateFormat = dateString[0];
    this.endDateFormat = dateString[1];

    this.fetchAssetProfileAndDailyDataV2();
    this.fetchTrackingDetailV2();
  }

  _handleGetDaysRange() {
    if (this.dateFormat === "Month") {
      this.startDate = dayjs()
        .year(this.yearSelected)
        .month(this.month - 1)
        .startOf("month");
      this.endDate = dayjs()
        .year(this.yearSelected)
        .month(this.month - 1)
        .endOf("month");

      this.startDateFormat = moment(this.startDate.$d).format("YYYY/MM/DD");
      this.endDateFormat = moment(this.endDate.$d).format("YYYY/MM/DD");
    } else if (this.dateFormat === "Year") {
      this.startDate = dayjs().year(this.yearSelected).startOf("year");
      this.endDate = dayjs().year(this.yearSelected).endOf("year");

      this.startDateFormat = moment(this.startDate.$d).format("YYYY/MM/DD");
      this.endDateFormat = moment(this.endDate.$d).format("YYYY/MM/DD");
    } else if (this.dateFormat === "7 Days") {
      let dates = [];
      let currentDate = new Date();
      let week = 7;

      for (let i = 1; i <= week; i++) {
        dates.push(currentDate);
        currentDate = new Date(currentDate);
        currentDate.setDate(currentDate.getDate() - 1);
      }
      this.endDateFormat = moment(dates[0]).format("YYYY/MM/DD");
      this.startDateFormat = moment(dates[dates.length - 1]).format(
        "YYYY/MM/DD"
      );
    } else {
      this.startDate = dayjs()
        .year(this.yearSelected)
        .month(this.month - 1)
        .startOf("month");

      this.startDateFormat = moment(this.startDate.$d).format("YYYY/MM/DD");
      this.endDateFormat = moment(new Date()).format("YYYY/MM/DD");
    }
  }

  setLoading(value) {
    this.isLoading = value;
  }

  _handleSelectYear(year) {
    this.showView = "chart";

    runInAction(() => {
      this.yearSelected = year;
    });
    // this._handleGetDaysRange();
    // this.fetchAssetProfileAndDailyDataV2();

    if (this.number_plates.length === 0) {
      this.number_plates.push(this.selectedVehicle?.label);
      this.vehicleIds.push(this.selectedVehicle?.value);
    }
    this.fetchAssetProfileAndDailyData();
    // this.fetchAssetProfileAndDailyDataV2();
    if (this.showComparisonSelect === "individual") {
      this.fetchTrackingDetail();
      // this.fetchTrackingDetailV2();
    }
  }

  setSelectDateFormat(value) {
    this.dateFormat = value;
    this._handleGetDaysRange();
    this.fetchAssetProfileAndDailyDataV2();
    this.fetchTrackingDetailV2();
  }

  handleShowChartViewVTwo(view) {
    runInAction(() => {
      this.showChartViewVTwo = view;
    });
  }

  handleShowChartView(view) {
 
    this.showChartView = view;
   
  }

  handleComparisonSelectChange(view) {
    runInAction(() => {
      this.showComparisonSelect = view;
    });
    this.selectedVehicle = this.vehicleOptions[0];
    if (view === "comparison") {
      this.number_plates = [this.selectedVehicle?.label];
      this.vehicleIds = [this.selectedVehicle?.value];
      // this.fetchAssetProfileAndDailyDataV2();
      this.fetchAssetProfileAndDailyData();
      this.showChartView = "daily";
      this.year = moment().year();
      this.month = moment().month() + 1;
    } else {
      this.year = moment().year();
      this.month = moment().month() + 1;
      const filter = this.fmsVehicleLst?.filter(
        (v) => v.id === this.selectedVehicle.value
      )[0];
      this.unitSetting = filter?.unitSetting;
      if (this.unitSetting?.enableCANbus === true) {
        this.showChartView = "daily";
      } else {
        this.showChartView = "monthly";
      }
    }
  }

  handleSelecteDetail(value, index) {
    runInAction(() => {
      this.selectedDetail = value;
      this.selectedIndex = index;
    });
  }

  handleViewChange(view) {
    runInAction(() => {
      this.showView = view;
    });
  }

  handleSelectedVehicleType = (value) => {
    this.showView = "chart";
    this.vehicleType = value;
    this.selectedVehicle = "";
    this.vehicleOptions = [];
    this.number_plates = [];
    this.vehicleIds = [];

    this.vehicleOptions = this.fmsVehicleLst
      .filter((v) => v.fuel_type.trim() === value.value)
      ?.map((a) => {
        return {
          label: a.grouping,
          value: a.id,
          image: a.image,
        };
      });

    this.selectedVehicle = this.vehicleOptions[0];
    if (this.number_plates.length === 0) {
      this.number_plates = [this.selectedVehicle?.label];
      this.vehicleIds = [this.selectedVehicle?.value];
    }

    if (this.showComparisonSelect === "individual") {
      this.number_plates = [this.selectedVehicle?.label];
      this.vehicleIds = [this.selectedVehicle?.value];
      this.fetchTrackingDetail();
      this.fetchAssetProfileAndDailyData();

      // this._handleGetDaysRange();
      // this.fetchAssetProfileAndDailyDataV2();

      // this.fetchTrackingDetailV2();
    } else {
      this.fetchAssetProfileAndDailyData();
    }
  };

  handleMonthSelect(month) {
    this.showView = "chart";

    runInAction(() => {
      this.month = month;
    });

    // this._handleGetDaysRange();

    if (this.number_plates.length === 0) {
      console.log("number_plates");
      this.number_plates.push(this.selectedVehicle?.label);
      this.vehicleIds.push(this.selectedVehicle?.value);
    }
    if (this.showComparisonSelect === "individual") {
      this.fetchTrackingDetail();
      this.fetchAssetProfileAndDailyData();

      // this.fetchTrackingDetailV2();
      // this.fetchAssetProfileAndDailyDataV2();
    } else {
      this.fetchAssetProfileAndDailyData();
    }
    // this.fetchAssetProfileAndDailyData();
  }

  handleYearSelect(year) {
    this.showView = "chart";
    runInAction(() => {
      this.year = year;
    });
    if (this.number_plates.length === 0) {
      this.number_plates.push(this.selectedVehicle?.label);
      this.vehicleIds.push(this.selectedVehicle?.value);
    }
    // this.fetchAssetProfileAndDailyData();
    if (this.showComparisonSelect === "individual") {
      this.fetchTrackingDetail();
      this.fetchAssetProfileAndDailyData();

      // this.fetchTrackingDetailV2();
      // this.fetchAssetProfileAndDailyDataV2();
    } else {
      this.fetchAssetProfileAndDailyData();
    }
  }

  handleRowSelect(select) {
    runInAction(() => {
      this.selectedAsset = select;
      this.selectedUid = select;
      this.selectedAssetId = select;
      // this.selectedAssetName=this.vehicleList.find(v=>v.uid===select).asset_name
    });
    this.fetchAssetProfile();
  }

  handleSelectedRowForFMS = (vehicle) => {
    this.vehicleType = { value: "Battery", label: "EV" };

    this.selectedVehicle = {
      value: vehicle.uid,
      label: vehicle.grouping,
    };

    this.fetchAssetProfileAndDailyData();
    this.fetchTrackingDetail();
    // this.fetchAssetProfileAndDailyDataV2();
    // this.fetchTrackingDetailV2();
  };

  handleVehicleSelected = (value) => {
    this.showView = "chart";
    this.selectedVehicle = value;

    if (this.showComparisonSelect === "individual") {
      this.number_plates = [value.label];
      this.vehicleIds = [value.value];
      this.fetchTrackingDetail();
      this.fetchAssetProfileAndDailyData();
      // this.fetchAssetProfileAndDailyDataV2();
      // this.fetchTrackingDetailV2();
    } else {
      this.number_plates = [];
      this.vehicleIds = [];
      value?.map((data) => {
        this.number_plates.push(data.label);
        this.vehicleIds.push(data.value);
      });
      // this.fetchAssetProfileAndDailyDataV2();
      this.fetchAssetProfileAndDailyData();
    }

    if (this.showComparisonSelect === "individual") {
      const filter = this.fmsVehicleLst?.filter((v) => v.id === value.value)[0];
      this.unitSetting = filter?.unitSetting;
      if (this.unitSetting?.enableCANbus === true) {
        this.showChartView = "daily";
      } else {
        this.showChartView = "monthly";
      }
    }
  };

  handleVehicleSelect(assetUid, assetName, assetId) {
    runInAction(() => {
      this.selectedUid = assetUid;
      this.selectedAsset = assetId;
      this.selectedAssetName = assetName;
      this.selectedAssetId = assetId;
    });
    this.fetchAssetProfile();
  }

  async fetchVehicleList(props) {
    runInAction(() => {
      this.vehicleList = null;
    });

    let data;
    try {
      const params = this.isAds
        ? {
            companyId: this.companyId,
            siteId: 14,
          }
        : {
            companyId: this.companyId,
          };
      const searchParams = new URLSearchParams(params);

      const response = await fetch(
        `${baseURL}/co2/device/getVehicleList?${searchParams.toString()}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Access-Token": this.token,
          },
        }
      );
      data = await response.json();
      if (data.message === "Token Expired!") {
        toast.info(data.message);
        window.location.pathname = "/";
      } else {
        runInAction(() => {
          this.vehicleList = data.asset;
          this.selectedUid = this.vehicleList[0].uid;
          this.selectedAssetId = this.vehicleList[0].asset_id;
          this.unitSetting = this.vehicleList[0].unitSetting;
        });

        this.fetchAssetProfile();
      }
    } catch (e) {
      console.log(e);
    }
  }

  async fetchAssetProfileAndDailyData(props) {
    runInAction(() => {
      this.assetProfile = null;
      this.assetProfiles = [];
    });
    const request_body = {
      // vehical_plates: this.number_plates,
      vehicleIds: this.vehicleIds,
      year: this.year,
      month: this.month,
      dataStatus: this.showChartView,
    };

    getProfileAndDailyData(request_body, this.token, (err, data) => {
      if (data.message === "Token Expired!") {
        toast.info(data.message);
        window.location.pathname = "/";
      } else {
        runInAction(() => {
          if (this.showComparisonSelect === "individual") {
            this.assetProfile = data[0];
           
          } else {
            this.assetProfiles = data;
          }
        });
      }
    });

    console.log(this.assetProfiles);
  }

  // async fetchAssetProfileAndDailyDataV2() {
  //   runInAction(() => {
  //     this.assetProfile = null;
  //     this.assetProfiles = [];
  //   });
  //   const request_body = {
  //     // vehical_plates: this.number_plates,
  //     start_date: this.startDateFormat,
  //     end_date: this.endDateFormat,
  //     // unitIds: this.vehicleOptions.map(v => v.value),
  //     unitIds: this.vehicleIds,
  //     key: this.dateFormat === "Year" ? "year" : "daily",
  //   };

  //   getProfileAndDailyDataV2(request_body, this.token, (err, data) => {
  //     if (err) {
  //       toast.info(err.message);
  //       // window.location.pathname = "/";
  //     } else {
  //       runInAction(() => {
  //         if (this.showComparisonSelect === "individual") {
  //           this.assetProfile = data.payload[0];
  //           this.handleViewChange("chart");
  //         } else {
  //           this.assetProfiles = data.payload;
  //         }
  //       });
  //     }
  //   });
  // }

  //   async fetchAssetProfileAndDailyData(props) {
  //     runInAction(() => {
  //       this.assetProfile = null;
  //     });
  //     let data;
  //     try {
  //       const response = await fetch(
  //         `${baseURL}/co2/device/profileAndDailyData?year=${this.year}&month=${this.month}
  //             &assetId=${this.selectedVehicle?.value}&vehical_plate=${[this.selectedVehicle?.label]}&companyId=${this.companyId}`,
  //         {
  //           method: "GET",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Accept: "application/json",
  //             "X-Access-Token": this.token,
  //           },
  //         }
  //       );

  //       data = await response.json();
  //       if (data.message === "Token Expired!") {
  //         toast.info(data.message);
  //         window.location.pathname = "/";
  //       } else {
  //         console.log(data);
  //         runInAction(() => {
  //           this.assetProfile = data;
  //         });
  //       }
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   }

  async fetchTrackingDetail(props) {
    runInAction(() => {
      this.trackingDetail = [];
      this.selectedDetail = null;
    });
    let data;
    try {
      const response = await fetch(
        `${baseURL}/co2/device/getTrackingDetail?year=${this.year}&month=${this.month}&assetId=${this.selectedVehicle?.value}&vehical_plate=${this.selectedVehicle?.label}&companyId=${this.companyId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Access-Token": this.token,
          },
        }
      );

      data = await response.json();
      if (data.message === "Token Expired!") {
        toast.info(data.message);
        window.location.pathname = "/";
      } else {
        runInAction(() => {
          this.trackingDetail = data;
          // this.selectedDetail = data[0];
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  async fetchTrackingDetailV2() {
    runInAction(() => {
      this.trackingDetailVTwo = [];
      this.selectedDetail = null;
    });
    let data;
    try {
      const response = await fetch(
        `${baseURL}/co2/device/v2/getTrackingDetail?start_date=${this.startDateFormat}&end_date=${this.endDateFormat}&unitId=${this.selectedVehicle?.value}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Access-Token": this.token,
          },
        }
      );

      data = await response.json();
      if (data.message === "Token Expired!") {
        toast.info(data.message);
        window.location.pathname = "/";
      } else {
        runInAction(() => {
          this.trackingDetailVTwo = data.payload;
          // this.selectedDetail = data[0];
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  async fetchFMSVehicles(props) {
    runInAction(() => {
      this.fmsVehicleLst = null;
    });
    try {
      const response = await fetch(
        `${baseURL}/co2/device/v2/getVehiclesByUserRole?companyId=${this.companyId}&asset=0`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Access-Token": this.token,
          },
        }
      );
      let data = await response.json();
      if (data.message === "Token Expired!") {
        toast.info(data.message);
        window.location.pathname = "/";
      } else {
        runInAction(() => {
          this.fmsVehicleLst = data;
          this.vehicleOptions = this.fmsVehicleLst
            .filter((v) => {
              return v.fuel_type.trim() === this.vehicleType.value;
            })
            ?.map((a) => {
              return {
                label: a.grouping,
                value: a.id,
                image: a.image,
              };
            });

          this.selectedVehicle = this.vehicleOptions[0];
          this.unitSetting = this.fmsVehicleLst[0].unitSetting;
          if (this.unitSetting.enableCANbus === true) {
            this.showChartViewVTwo = "daily";
          } else {
            this.showChartViewVTwo = "monthly";
          }
          if (this.showComparisonSelect === "individual") {
            // this.fetchAssetProfileAndDailyDataV2();
            // this.fetchTrackingDetailV2();

            this.fetchAssetProfileAndDailyData();
            this.fetchTrackingDetail();
          } else {
            this.fetchAssetProfileAndDailyData();
            this.fetchTrackingDetail();
          }
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  async handleGetVehicleType(props) {
    runInAction(() => {
      this.vehicleType = {};
    });
    try {
      const response = await fetch(
        `${baseURL}/co2/device/v2/vehicleType?companyId=${this.companyId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Access-Token": this.token,
          },
        }
      );
      let data = await response.json();

      if (data.message === "Token Expired!") {
        toast.info(data.message);
        window.location.pathname = "/";
      } else {
        runInAction(() => {
          if (
            data[0]?.primary_fuel_type === "" ||
            data[0]?.primary_fuel_type === "Battery"
          ) {
            this.vehicleType = { value: "Battery", label: "EV" };
            this.handleSelectedVehicleType(this.vehicleType);
          } else if (data[0]?.primary_fuel_type === "Diesel") {
            this.vehicleType = { value: "Diesel", label: "Diesel" };
            this.handleSelectedVehicleType(this.vehicleType);
          } else if (data[0]?.primary_fuel_type === "Petrol") {
            this.vehicleType = { value: "Petrol", label: "Petrol" };
            this.handleSelectedVehicleType(this.vehicleType);
          } else {
            this.vehicleType = { value: "Battery", label: "EV" };
            this.handleSelectedVehicleType(this.vehicleType);
          }
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  async fetchGetAllCompanyVehicles(props) {
    // console.log("........................");
    // runInAction(() => {
    //     this.forallCompanyVehicles = null;
    // });
    // try {
    //     console.log('in try ...............');
    //     const response = await fetch(`${baseURL}/co2/device/v2/getVehiclesByUserRole?companyId=${this.companyId}`, {
    //         method: "GET",
    //         headers: {
    //             "Content-Type": "application/json",
    //             Accept: "application/json",
    //             "X-Access-Token": this.token,
    //         },
    //     });
    //     let data = await response.json();
    //     if (data.message === "Token Expired!") {
    //         toast.info(data.message);
    //         window.location.pathname='/';
    //     } else {
    //         runInAction(() => {
    //             this.forallCompanyVehicles = data;
    //             console.log("favdata>>",data, toJS(this.forallCompanyVehicles));
    //             this.forallCompanyVehicleOptions = this.forallCompanyVehicles?.filter((v) => {
    //                 return v.type.trim() === this.vehicleType.value;
    //             })?.map((a) => {
    //                 return {
    //                     label: a.grouping,
    //                     value: a.id,
    //                     image: a.image,
    //                 }
    //             })
    //             this.selectedVehicle = this.forallCompanyVehicleOptions[0];
    //             this.fetchAssetProfileAndDailyData();
    //             this.fetchTrackingDetail();
    //         });
    //     }
    // } catch (e) {
    //     console.log(e)
    // }
  }

  async fetchAssetProfile(props) {
    runInAction(() => {
      this.assetProfile = null;
    });
    const body = {
      deviceId: this.isAds
        ? this.selectedAssetId
          ? this.selectedAssetId
          : this.vehicleList[0]?.asset_id
        : this.selectedUid
        ? this.selectedUid
        : this.vehicleList[0]?.uid,
      siteId: 14,
    };
    let data;
    try {
      const response = this.isAds
        ? await fetch(`${baseURL}/co2/device/profile?year=${this.year}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-Access-Token": this.token,
            },
            body: JSON.stringify(body),
          })
        : await fetch(
            `${baseURL}/co2/device/profile?assetId=${this.selectedUid}&year=${this.year}`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "X-Access-Token": this.token,
              },
            }
          );
      const selectedAsset = this.vehicleList?.find(
        (asset) => Number(asset.uid) === Number(this.selectedUid)
      );
      data = await response.json();
      if (data.message === "Token Expired!") {
        toast.info(data.message);
        window.location.pathname = "/";
      } else {
        runInAction(() => {
          data.vehicle_number_plate =
            !this.isAds && selectedAsset.vehicle_number_plate;
          this.assetProfile = data;
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  setSelectedIndex(value) {
    this.selectedIndex = value;
  }

  setPlaceHolder(value) {
    this.placeholder = value;
  }
}

export default new Tracking();
