import React, { useState } from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { My_Input } from "../../Tools";
import { inject, observer } from "mobx-react";
import "react-phone-input-2/lib/style.css";
import { Cookies } from "react-cookie";
import jwtDecode from "jwt-decode";

export const PasswordResetModal = inject("store")(
  observer((props) => {
    const { theme, closeModal, show } = props;
    const errStyle = {
      color: "red",
      fontSize: 12,
      marginTop: "-20px",
    };
    const { passwordResetView, setPasswordResetView } = props.store.adminPanel;
    const {
      oldPassword,
      newPassword,
      confirmNewPassword,
      oldPasswordView,
      newPasswordView,
      _handleOldPasswordChange,
      _handleConfirmNewPasswordChange,
      _handleNewPasswordChange,
      _handleOldPasswordView,
      _handleNewPasswordView,
      _handleUserPasswordReset,
    } = props.store.register;
    const cookies = new Cookies();
    const token = cookies.get("accessToken");
    const decode = jwtDecode(token);

    const cardStyle = {
      color: invertDark(theme),
      background: Colors[theme].background,
    };

    const resetPassword = async () => {
      try {
        await _handleUserPasswordReset(token, decode.userId);
        closeModal();
      } catch (error) {
        console.error("An error occurred while resetting the password:", error);
      }
    };

    return (
      <>
        <div
          className="modal fade"
          id="passwordresetmodal"
          tabIndex="-1"
          aria-labelledby="passwordresetmodal"
          aria-hidden={!show}
        >
          <div
            className={`modal-dialog ${
              !passwordResetView ? "modal-xl" : "modal-lg"
            }`}
            style={{ maxWidth: "550px" }}
          >
            <div className="modal-content" style={cardStyle}>
              <>
                <div className="modal-header  justify-content-between">
                  <h5 className="modal-title d-flex   " id="passwordresetmodal">
                    Reset Your Password
                  </h5>
                  <button
                    type="button"
                    //  onClick={() => _handleLoading(false)}
                    className={`btn-close ${
                      theme === "dark" ? "btn-close-white" : ""
                    }`}
                    data-bs-dismiss="modal"
                    // aria-label="Close"
                    onClick={() => closeModal()}
                  ></button>
                </div>
                <div className="modal-body">
                  <div
                    className="row"
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <div className="d-flex align-items-center justify-content-center pb-0">
                      <span className="col-5">
                        <span style={{ color: "#ffc107" }}>*</span> Current
                        Password
                      </span>
                      <div className="w-100" style={{ paddingTop: "10.5px" }}>
                        <My_Input
                          className="justify-content-center"
                          required={true}
                          id={"oldPassword"}
                          type={oldPasswordView ? "text" : "password"}
                          placeHolder={"Current Password"}
                          value={oldPassword}
                          onChange={(e) => {
                            _handleOldPasswordChange(e.target.value);
                          }}
                        />
                        <span
                          style={{
                            float: "right",
                            position: "relative",
                            marginTop: "-55px",
                            fontSize: "18px",
                            marginRight: "20px",
                            cursor: "pointer",
                          }}
                          onClick={() => _handleOldPasswordView()}
                        >
                          <i
                            className={`fa fa-eye${
                              oldPasswordView ? "-slash" : ""
                            } py-4 text-secondary`}
                          />
                        </span>
                      </div>
                    </div>

                    <div className="d-flex align-items-center pb-0">
                      <span className="col-5 justify-content-center">
                        <span style={{ color: "#ffc107" }}>*</span> New Password
                      </span>
                      <div className="w-100" style={{ paddingTop: "10.5px" }}>
                        <My_Input
                          required={true}
                          id={"newPassword"}
                          type={newPasswordView ? "text" : "password"}
                          placeHolder={"New Password"}
                          value={newPassword}
                          disabled={!oldPassword}
                          onChange={(e) => {
                            _handleNewPasswordChange(e.target.value);
                          }}
                        />
                        <span
                          style={{
                            float: "right",
                            position: "relative",
                            marginTop: "-55px",
                            fontSize: "18px",
                            marginRight: "20px",
                            cursor: "pointer",
                          }}
                          onClick={() => _handleNewPasswordView()}
                        >
                          <i
                            className={`fa fa-eye${
                              newPasswordView ? "-slash" : ""
                            } py-4 text-secondary`}
                          />
                        </span>
                      </div>
                    </div>

                    <div className="d-flex align-items-center pb-0">
                      <span className="col-5 justify-content-center">
                        <span style={{ color: "#ffc107" }}>*</span>Confirm New
                        Password
                      </span>
                      <div className="w-100" style={{ paddingTop: "10.5px" }}>
                        <My_Input
                          required={true}
                          id={"confirmNewPassword"}
                          type={newPasswordView ? "text" : "password"}
                          placeHolder={"Confirm New Password"}
                          value={confirmNewPassword}
                          onChange={(e) => {
                            _handleConfirmNewPasswordChange(e.target.value);
                          }}
                        />
                        <span
                          style={{
                            float: "right",
                            position: "relative",
                            marginTop: "-55px",
                            fontSize: "18px",
                            marginRight: "20px",
                            cursor: "pointer",
                          }}
                          onClick={() => _handleNewPasswordView()}
                        >
                          <i
                            className={`fa fa-eye${
                              newPasswordView ? "-slash" : ""
                            } py-4 text-secondary`}
                          />
                        </span>
                      </div>
                    </div>

                    <div className="col-12 d-flex align-items-center justify-content-end text-end ">
                      <button
                        type="button"
                        style={{
                          fontSize: "15px",
                          background: "#4c5d75",
                          color: "white",
                        }}
                        disabled={!newPassword}
                        className="btn justify-content-end w-25"
                        onClick={resetPassword}
                        data-bs-dismiss={confirmNewPassword ? "modal" : ""}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </>
    );
  })
);
