import { Colors, invertDark } from "../../Assets/Config/color.config";
import GRI from "../../Assets/Images/icons/gri.png";
import ISO from "../../Assets/Images/icons/iso.png";
import ICCT from "../../Assets/Images/icons/icct.jpg";
import GH from "../../Assets/Images/icons/greenhouse.png";
import { useTranslation } from "react-i18next";

export const Compliance = () => {
  const { t } = useTranslation("dashboard");
  const theme = localStorage.getItem("theme");
  const cardStyle = {
    background: Colors[theme].background,
    boxShadow: "2px 4px 11px rgba(0,0,0,0.25)",
    padding: "10px",
    marginBottom: "8px",
    borderRadius: "15px",
    fontSize: "12px",
    color: invertDark(theme),
  };
  return (
    <div style={cardStyle}>
      <span style={{ fontWeight: 800 }}>{t("Reporting Framework")}</span>
      <div
        style={{ background: Colors.Dark[4], height: "1px", opacity: 0.1 }}
        className="my-1"
      ></div>
      {complianceData.map((v, k) => (
        <div className="d-flex flex-row justify-content-between py-1" key={k}>
          <div className="d-flex justify-content-start">
            <img
              src={v.id === 1 ? ISO : v.id === 2 ? GRI : v.id === 3 ? GH : ICCT}
              alt="icon"
              width={20}
              height={22}
            />
            <span className="ps-1">{v.name}</span>
          </div>
          <div>
            <i
              className="fa fa-check pe-1 ps-5"
              style={{ color: Colors.low }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

const complianceData = [
  { id: 1, icon: "", name: "ISO 14064-1", status: "Passed" },
  // { id: 2, icon: "", name: "Global Reporting Initiative", status: "Passed" },
  { id: 3, icon: "", name: "Greenhouse Gas Protocol", status: "Passed" },
  {
    id: 4,
    icon: "",
    name: "The International Council on Clean Transportation",
    status: "Passed",
  },
];
