export const Colors = {
  light: {
    primaryColor: "#005A00",
    secondaryColor: "#98C355",
    background: "#ffffff",
    darkBackground: "#191F40",
    textColor: "#303030",
    active: "#e5e5e5",
    activeTimeline: "#00B100",
    noActiveTimeline: "#C6C6C6",
    timelineBorder : "#C6C6C6",
    liteSmallCard: "linear-gradient(96.14deg, #005a00 0%, #009a00 100%)"
  },
  dark: {
    primaryColor: "#C6C6C6",
    secondaryColor: "#5E5E5E",
    background: "linear-gradient(65deg,#101841,#202b60)",
    darkBackground: "#191F40",
    textColor: "#c6c6c6",
    active: "#202b60",
    activeTimeline: "#2f528f",
    noActiveTimeline: "#ffffff",
    timelineBorder : "#F5F5F5",
    liteSmallCard: "linear-gradient(96.14deg, #00249C 0%, #005BC9 100%)",
  },
  Dark: ["#000000", "#303030", "#5E5E5E", "#919191", "#C6C6C6", "#F6F6F6"],
  chartColor : ["#50C878", "#00FFFF", "#808080", "#74E89A"],
  fuel: "#FF3400",
  fuelLight: "#f4b183",
  Scope1_fuelLight: "#FFE9D4",
  Scope2_fuelLight: "#FCC990",
  Scope3_fuelLight: "#EFA05D",
  fuelBaseline: "red",
  medium: "#FFD106",
  high: "#FF0000",
  low: "#00B100",
  lightBlue: "#2f528f",
  Scope1_lightBlue: "#6CA6FF",
  Scope2_lightBlue: "#5084C3",
  Scope3_lightBlue: "#37518B",
  darkBlue: "#202b60",
  activeColor: "#919191",
  yellow: "#00FF00",
  silver: "#C3C6D1",
  blackblue: "#29325B",
};

export const invertDark=(theme)=>{
  return (theme==="dark"?"#C6C6C6":"#303030")
}
