import { inject, observer } from "mobx-react";
import { CO2_Loading } from "../../Assets/CO2_Loading";
import { CollapsibleTable } from "../../Tools/collapsibleTable";
import React, { useEffect } from "react";
import { toJS } from "mobx";

export const CertificateTable = inject("store")(
  observer((props) => {
    const theme = props.store.login.theme;

    const { certificatesV2, companyType } = props;

    const {
      noCertificatesData,
      certificateYear,
      setSelectedCertificateId,
      _handleFetchCompanyYears,
      setShowCertiTour,
    } = props.store.dashboard;

    useEffect(() => {
      _handleFetchCompanyYears();
    }, []);

    if (certificatesV2)
      return (
        <>
          <CollapsibleTable
            theme={theme}
            noCertificatesData={noCertificatesData}
            year={certificateYear}
            data={certificatesV2}
            setSelectedCertificateId={setSelectedCertificateId}
            setShowCertiTour={setShowCertiTour}
            companyType={companyType}
          />
        </>
      );
    else return <CO2_Loading />;
  })
);

export default CertificateTable;
