/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
import { useHistory } from "react-router-dom";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { My_Button } from "../../Tools/My_Button";
import Store from "../../Store";
import { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";

export const TotalViews = inject("store")(
  observer((props) => {
    const { t } = useTranslation("dashboard");
    // const theme = localStorage.getItem("theme");
    const { theme } = props;
    const { _handleSelectAssets } = Store.assetProfile;
    const history = useHistory();
    const { vehicleCount, deviceCount, currentAssets } = Store.dashboard;

    console.log("theme", theme);

    const cardStyle = {
      background: Colors[theme].background,
      boxShadow: "2px 4px 11px rgba(0,0,0,0.25)",
      padding: "10px",
      width: "390px",
      borderRadius: "15px",
      marginBottom: 8,
      color: invertDark(theme),
      fontSize: 12,
    };

    let vehiclesAndEquipment = [];

    if (currentAssets === "0") {
      vehiclesAndEquipment = [
        { icon: "fa fa-car", title: "Total Vehicles", yoyValue: "0" },
        { icon: "fa fa-fan", title: "Total Equipment", yoyValue: "0" },
        { icon: "fa fa-cog", title: "Total Infrastructure", yoyValue: "0" },
      ];
    } else if (currentAssets === "vehicle") {
      vehiclesAndEquipment = [
        { icon: "fa fa-car", title: t("Total Vehicles"), yoyValue: "0" },
      ];
    } else if (currentAssets === "equipment") {
      vehiclesAndEquipment = [
        { icon: "fa fa-fan", title: "Total Equipment", yoyValue: "0" },
      ];
    } else if (currentAssets === "infrastructure") {
      vehiclesAndEquipment = [
        { icon: "fa fa-cog", title: "Total Infrastructure", yoyValue: "0" },
      ];
    }

    return vehiclesAndEquipment.map((v, k) => (
      <div style={cardStyle} key={k}>
        <div className="d-flex flex-row justify-content-between">
          <span className="fw-bold">
            <i className={`${v.icon} pe-1 text-secondary`} />
            {t(v.title)}
          </span>
          <div>
            {v.title === "Total Vehicles" ? (
              <My_Button
                text={t("View Details")}
                style={{ fontSize: 12 }}
                small
                onClick={() => {
                  history.push("/asset_profile_management");
                  _handleSelectAssets("vehicle");
                }}
              />
            ) : v.title === "Total Equipment" ? (
              <My_Button
                text={t("View Details")}
                style={{ fontSize: 12 }}
                small
                onClick={() => {
                  history.push("/asset_profile_management");
                  _handleSelectAssets("equipment");
                }}
              />
            ) : (
              <My_Button
                text={t("View Details")}
                style={{ fontSize: 12 }}
                small
                onClick={() => {
                  history.push("/asset_profile_management");
                  _handleSelectAssets("infrastructure");
                }}
              />
            )}
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between align-items-baseline">
          <span>
            {" "}
            <strong
              className="pe-1"
              style={{ color: Colors[theme].primaryColor }}
            >
              {v.yoyValue}
            </strong>
            YOY
          </span>
          <span style={{ fontSize: 20 }}>
            {v.title === "Total Vehicles" ? vehicleCount : 0}
          </span>
        </div>
      </div>
    ));
  })
);
