import { inject, observer } from 'mobx-react';
import React from 'react'
import { useTranslation } from 'react-i18next';

export const ShowInfoTooltips = inject("store")(
  observer((props) => {
    const {t} = useTranslation("dat_import")

    const { role, showInfo, _handleClose, showTable, theme, cardStyle } = props;

    return (
      <div
        style={{
          ...cardStyle,
          position: "fixed",
          zIndex: 10,
          top: 56,
          right: 10,
          maxHeight: 145,
          maxWidth: 400,
          display: showInfo !== true,
          borderRadius: 15,
        }}
      >
        <div className="mx-1">
          <div className="row">
            <span
              className="col"
              style={{
                color: theme === "dark" ? "#fff" : "rgb(48, 48, 48)",
                fontSize: 19,
              }}
            >
              {t("Tips for Data Import")}
            </span>
            <span className="d-flex justify-content-end col">
              <i
                style={{
                  cursor: "pointer",
                  fontSize: 20,
                  color: theme === "dark" ? "#fff" : "rgb(48, 48, 48)",
                }}
                onClick={_handleClose}
                className="bi bi-x"
              ></i>
            </span>
          </div>

          <div className="rounded" style={{ color: "#fff" }}>
            <div
              className="mx-2"
              style={{
                color: theme === "dark" ? "rgb(195 150 40)" : "#0d6efd",
                maxHeight: "100px",
                maxWidth: "370px",
                position: "relative",
                overflow: "scroll",
              }}
            >

              {/* Manual Entry 1 */}
              {/* enginehour 3 */}
              {/* manual entry 4 */}
              
             
              {showTable === "table1" ? <>
                <h6>{t("These field must include for Unit Profile")}</h6>
                <ul>
                  <li>grouping</li>
                  <li>company</li>
                  <li>plate_number</li>
                  <li>brand</li>
                  <li>model</li>
                  <li>primary_fuel_type</li>
                  <li>vin_number</li>
                  <li>oem_baseline_emission</li>
                  <li>Date format for Unit Profile(“DD/MM/YYYY h:mm:ss”)</li>
                </ul>
                
              </> : showTable === "table2" ? <>
                <h6>{t("These field must include for Trips")}</h6>
                <ul>
                  <li>grouping</li>
                  <li>start_time</li>
                  <li>to_time</li>
                  <li>trip_mileage</li>
                  <li>consumed</li>

                </ul>
                <h6>{t("Date format for Trips")}</h6>
                  <ul>
                    <li>(“DD/MM/YYYY h:mm:ss”)</li>
                  </ul>
              </> : showTable === "table3" ?
                <>
                  <h6>{t("These field must include for Engine Hours")}</h6>
                  <ul>
                    <li>grouping</li>
                    <li>start_time</li>
                    <li>in_motion</li>
                    <li>idling</li>
                  </ul>
                  <h6>{t("Date format for Engine Hours")}</h6>
                  <ul>
                    <li>(“DD/MM/YYYY h:mm:ss”)</li>
                  </ul>

                </> :
                <>
                  <h6>{t('These field must include for Manual Entry')}</h6>
                  <ul>
                    <li>date</li>
                    <li>grouping</li>
                    <li>odometer_end</li>
                  </ul>
                  <h6>{t("Date format for Manual Entry")}</h6>
                  <ul>
                    <li>(“DD/MM/YYYY h:mm:ss”)</li>
                  </ul>
                </>
              }

            </div>
          </div>
        </div>

      </div>
    );
  })

);


