import { toJS } from "mobx";
import * as API from "./URL";

export const fetchGetManualEntry = (token,data, callback) => {
    const searchParams = new URLSearchParams(data);
    return fetch(`${API.getManualEntryAPI}?${searchParams.toString()}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: " */*",
        "x-access-token": token,
      },
    })
      .then((res) => res.json())
      .then((data) => callback(null, data))
      .catch((err) => callback(err, null));
  };

export const fetchEditManualEntry = (token,data, callback) => {
    return fetch(API.editManualEntryAPI, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "x-access-token": token,
      },
      body: data,
      file: data
    })
      .then((res) => res.json())
      .then((data) => callback(null, data))
      .catch((err) => callback(err, null));
  };

  export const fetchUpdateFuelRecords = (token,data, callback) => {
    return fetch(`${API.getManualEntryAPI}/fuel-records`, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "x-access-token": token,
      },
      body: data,
      file: data
    })
      .then((res) => res.json())
      .then((data) => callback(null, data))
      .catch((err) => callback(err, null));
  };

  export const fetchYearOfMissingData=(token,data,callback)=>{
    const searchParams = new URLSearchParams(data);
    console.log("ucreateManualEntryNewrl search param",toJS(searchParams))
    return fetch(`${API.getYearOfMissingData}/?`+searchParams.toString(), {
      method: "Get",
      headers: {
        Accept: "*/*",
        "x-access-token": token,
      },
    })
      .then((res) => res.json())
      .then((data) => callback(null, data))
      .catch((err) => callback(err, null));
  }

  