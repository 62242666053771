import React, { useState } from "react";
import Select, { components } from "react-select";
import { Colors, invertDark } from "../Assets/Config/color.config";
import Store from "../Store";


export const Selector = (props) => {

    const {
        options,
        value,
        label,
        defaultValue,
        _handleSelect,
        disabled,
        menuListColor,
        isMulti,
        id,
        isSearchable,
        getOptionLabel,
        searchToggle,
        customInputSelect,
        width
    } = props;

    const {  placeholder, setPlaceHolder } = Store.tracking
    const theme = localStorage.getItem("theme");
    

    const customStyles = {
        control: (base, state) => ({
            ...base,
            width: width,
            background: theme === "dark" ? Colors.darkBlue : customInputSelect ? "#fff" : Colors.Dark[5],
            color: theme === "dark" ? Colors.Dark[4] : Colors.Dark[1],
            borderRadius: 3,
            borderColor: theme === "dark" ? Colors.Dark[2] : Colors.Dark[4],
            border: customInputSelect ? "none" : "",
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                borderColor: Colors.Dark[3],
                cursor: "pointer",
            },
        }),
        menu: (base) => ({
            ...base,
            borderBottomRadius: 10,
            zIndex: 100
        }),
        menuList: (base) => ({
            ...base,
            color: menuListColor ? menuListColor : "#000000",
        }),
        input: (base, state) => ({
            ...base,
            color: invertDark(theme),
        }),
    };

    
    

    return (      
        <div className={`${id === "manualSector" && "d-flex m-2"}`}>
            {label && (
                <>
                    <label
                        className={`${id === "manualSector" && "mt-2"}`}
                        style={{
                            fontWeight: "bold",
                            color: invertDark(theme),
                        }}
                    >
                        {label}:
                    </label>
                    <br />
                </>
            )}
            {label === 'Country' ?
                <Select
                    getOptionLabel={e => (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img width={30} src={`/countryflags/${e.code}.svg`} />
                            <span style={{ marginLeft: 5 }}>{e.label}</span>
                        </div>
                    )}
                    required
                    classNamePrefix="my-custom-select"
                    isSearchable={isSearchable}
                    value={value}
                    closeMenuOnSelect={isMulti ? false : true}
                    isMulti={isMulti ? true : false}
                    styles={customStyles}
                    options={options}
                    defaultValue={defaultValue}
                    onChange={(e) => _handleSelect(e)}
                    isDisabled={disabled}

                /> : searchToggle ? 
                    <Select
                        required
                        classNamePrefix="my-custom-select"
                        isSearchable={isSearchable}
                        value={value}
                        closeMenuOnSelect={isMulti ? false : true}
                        isMulti={isMulti ? true : false}
                        styles={customStyles}
                        options={options}
                        defaultValue={defaultValue}
                        onChange={(e) => _handleSelect(e)}
                        
                        isDisabled={disabled}
                        components={{ Control: CustomControl }}
                        placeholder={placeholder ? "Search Units..." : 'Select...'}
                        
                    />
                    
                    :
                    
                    <Select
                        required
                        classNamePrefix="my-custom-select"
                        isSearchable={isSearchable}
                        value={value}
                        closeMenuOnSelect={isMulti ? false : true}
                        isMulti={isMulti ? true : false}
                        styles={customStyles}
                        options={options}
                        defaultValue={defaultValue}
                        onChange={(e) => _handleSelect(e)}
                        isDisabled={disabled}
                        
                    />
                }

        </div>
    );
};

const CustomControl = ({ children, ...props }) => (
    <components.Control {...props}>
        <i className="fa fa-search" style={{ paddingLeft: '10px', color: 'grey', fontSize: '15px'}}></i> 
        {children}
    </components.Control>
);
  

