import { Selector } from "../../Tools";
import { VehicleDetailImageFMS } from "./vehicleDetailImage";
import { PreviewVehicleModalFMS } from "./PreviewVehicleModal";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { inject, observer } from "mobx-react";
import { EmissionIndicatiorFMS } from "./EmissionIndicator";
import { useEffect, useState } from "react";
import { Custom_Select } from "../../Tools/Custom_Select";
import { My_DatePicker } from "../../Tools";
import { useTranslation } from "react-i18next";
import moment from "moment";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import { DatePickerAntD } from "./DatePickerAntD";
import { toJS } from "mobx";
// import 'antd/dist/antd.css';

export const FilterBarWithMonth = inject("store")(
  observer((props) => {
    const { t } = useTranslation("new-tracking");
    const { theme, width } = props;

    const {
      month,
      handleVehicleSelected,
      selectedVehicle,
      handleMonthSelect,
      handleSelectedVehicleType,
      vehicleType,
      vehicleOptions,
      handleGetVehicleType,
      placeholder,
      setPlaceHolder,
      dateFormat,
      setSelectDateFormat,
      _handleSelectYear,
      _handleSelectCustom,
      _handleSelectDay,
      endDateFormat,
      dateOptionsType,
      unitSetting
    } = props.store.trackingVTwo;

    const { companyyearlydata } = props.store.dashboard;

    // const theme = localStorage.getItem("theme");
    const { isAds } = props.store.common;

    const types = [
      { value: "Battery", label: "EV" },
      { value: "Petrol", label: "Petrol" },
      { value: "Diesel", label: "Diesel" },
    ];

    useEffect(() => {
      handleGetVehicleType();
      // if(unitSetting?.enableCANbus && !unitSetting?.enableGPS || !unitSetting?.enableCANbus && unitSetting?.enableGPS) {
      //     setSelectDateFormat("Year")
      // }
      
    }, []);

   
    console.log("vehicleOptions ----->", toJS(vehicleOptions))

    const dateOptions =
      dateOptionsType === "Year"
        ? [{ value: "Year", label: "Yearly" }]
        : [
            { value: "7 Days", label: "Weekly" },
            { value: "Month", label: "Monthly" },
            { value: "Year", label: "Yearly" },
            { value: "Custom", label: "Custom" },
          ];

    const months = [
      { value: 1, label: "January" },
      { value: 2, label: "February" },
      { value: 3, label: "March" },
      { value: 4, label: "April" },
      { value: 5, label: "May" },
      { value: 6, label: "June" },
      { value: 7, label: "July" },
      { value: 8, label: "August" },
      { value: 9, label: "September" },
      { value: 10, label: "October" },
      { value: 11, label: "November" },
      { value: 12, label: "December" },
    ];

    const { RangePicker } = DatePicker;

    const defaultDate = new Date();
    const currentDate = dayjs();

    const disabledDate = (current) => {
      return current && current > currentDate.endOf("day");
    };
    // Example usage:
    const start = new Date("2023-01-01");
    const end = new Date("2023-01-11");
    const cDate = new Date();

    // useEffect(() => {
    //     handleVehicleSelected(vehicleOptions[0]);
    // },[vehicleOptions]);

    // if (width < 701) {
    //     return (
    //         <>
    //             <div className="d-flex flex-column flex-wrap justify-content-between pb-3 px-0 mx-0">
    //                 <div
    //                     className="d-flex gap-4 col-xl-6 col-lg-6 col-sm-12 mb-3"
    //                     style={{ width: '100%'}}
    //                 >
    //                     <div className="d-flex flex-column pt-2" style={{ width: '100%'}}>

    //                         <div style={{ color: invertDark(theme) }}>
    //                             <Selector
    //                                 isSearchable={false}
    //                                 label={"Type"}
    //                                 options={types}
    //                                 value={vehicleType ? vehicleType : types[0]}

    //                                 // value={(vehicleType && primaryFuelTypeForCompany === vehicleType) ? primaryFuelTypeForCompany : (vehicleType ? vehicleType : types[0])}
    //                                 _handleSelect={(value) => {
    //                                     handleSelectedVehicleType(value)
    //                                 }}
    //                                 disabled={false}
    //                             />
    //                         </div>

    //                         <div>
    //                             {/* <Selector
    //                                     label={"Unit(s)"}
    //                                     options={vehicleOptions}
    //                                     value={
    //                                         placeholder ? null : vehicleOptions.find((v) => v.value === selectedVehicle?.value)
    //                                     }
    //                                     _handleSelect={(vehicle) => {
    //                                         handleVehicleSelected(vehicle);
    //                                     }}

    //                                     disabled={false}
    //                                     isSearchable={true}
    //                                     searchToggle={true}

    //                                 />                                   */}
    //                             <Custom_Select options={vehicleOptions} value={vehicleOptions.find((v) => v.value === selectedVehicle?.value)?.label} label={'Units'} theme={theme} menuListColor={"black"} selectedValue={selectedVehicle?.value} _handleClick={(value) => handleVehicleSelected(value)} placeholder={placeholder} setPlaceHolder={setPlaceHolder} />
    //                         </div>

    //                         <div>
    //                             <Selector
    //                                 isSearchable={false}
    //                                 label={"Year"}
    //                                 options={companyyearlydata}
    //                                 defaultValue={
    //                                     companyyearlydata.find((y) => y.value === new Date().getFullYear())
    //                                 }
    //                                 _handleSelect={({ value, label }) => {
    //                                     const year = value;
    //                                     handleYearSelect(year);
    //                                 }}
    //                             />
    //                         </div>
    //                         <div>
    //                             <Selector
    //                                 isSearchable={false}
    //                                 label={"Month"}
    //                                 options={months}
    //                                 value={months.find((m) => m.value === month)}
    //                                 _handleSelect={({ value, label }) => {
    //                                     const month = value;
    //                                     handleMonthSelect(month);
    //                                 }}
    //                             />
    //                         </div>

    //                     </div>
    //                 </div>

    //                 <div
    //                     className="d-flex flex-column gap-4"
    //                 >
    //                     <div
    //                         data-bs-toggle="modal"
    //                         data-bs-target="#exampleModal"
    //                         className="d-flex align-items-center justify-content-center w-100 h-100"
    //                         style={{ ...props.cardStyle1, cursor: "pointer" }}
    //                     >
    //                         <VehicleDetailImageFMS
    //                             text={selectedVehicle ? selectedVehicle.label : vehicleOptions[0]?.label}
    //                             isAds={isAds}
    //                             asset_name={selectedVehicle ? selectedVehicle.label : vehicleOptions[0]?.label}
    //                             image={selectedVehicle ? selectedVehicle.image : selectedVehicle?.[0]?.image}
    //                         />

    //                     </div>

    //                     <div
    //                         id="indicator"
    //                         className="w-100 h-100"
    //                         // style={rowStyle }
    //                         style={props.cardStyle1}
    //                     >
    //                         <EmissionIndicatiorFMS theme={theme} />
    //                     </div>
    //                 </div>
    //             </div>

    //             {selectedVehicle && (
    //                 <PreviewVehicleModalFMS theme={theme}
    //                     asset_name={selectedVehicle ? selectedVehicle.label : vehicleOptions[0]?.label}
    //                     image={selectedVehicle ? selectedVehicle?.image : vehicleOptions[0]?.image} />
    //             )}
    //         </>
    //     );
    // } else {

    function getDatesInRange(start, end) {
      const dates = [];
      let currentDate = start;

      while (currentDate <= end) {
        dates.push(currentDate);
        currentDate = new Date(currentDate);
        currentDate.setDate(currentDate.getDate() + 1);
      }

      return dates;
    }
    const dateRange = getDatesInRange(start, end);

    // function getWeekdaysRange (date) {
    //     const dates = []
    //     let currentDate = date
    //     let week = 7

    //     for (let i = 1; i <= week; i++) {
    //         dates.push(currentDate)
    //         currentDate = new Date(currentDate)
    //         currentDate.setDate(currentDate.getDate() - 1)
    //     }
    //     return dates
    // }

    // console.log("weekDays", getWeekdaysRange(cDate))

    return (
      <>
        <div className="d-flex flex-row flex-wrap justify-content-between pb-3 px-0 mx-0">
          <div
            className={`${
              window.innerWidth > 540 && "d-flex"
            }  selector-unitLevel-guide gap-4 col-xl-6 col-lg-6 col-sm-12 mb-3`}
            style={{ width: window.innerWidth < 701 ? "100%" : "" }}
          >
            <div className="col-12 pt-2">
              <div className="row px-2">
                <div
                  className="col-xl-6 col-lg-6 col-md-6 col-sm-12"
                  style={{ color: invertDark(theme) }}
                >
                  <Selector
                    isSearchable={false}
                    label={t("Type")}
                    options={types}
                    value={vehicleType ? vehicleType : types[0]}
                    // value={(vehicleType && primaryFuelTypeForCompany === vehicleType) ? primaryFuelTypeForCompany : (vehicleType ? vehicleType : types[0])}
                    _handleSelect={(value) => {
                      handleSelectedVehicleType(value);
                    }}
                    disabled={false}
                  />
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <p
                    className=" mb-0"
                    style={{ color: invertDark(theme), fontWeight: "bold" }}
                  >
                    Unit(s):
                  </p>

                  {/* <Selector
                                            label={"Unit(s)"}
                                            options={vehicleOptions}
                                            value={
                                                placeholder ? null : vehicleOptions.find((v) => v.value === selectedVehicle?.value)
                                            }
                                            _handleSelect={(vehicle) => {
                                                handleVehicleSelected(vehicle);
                                            }}
                                            
                                            disabled={false}
                                            isSearchable={true}
                                            searchToggle={true}
                                           
                                        />                                   */}
                  <Custom_Select
                    options={vehicleOptions}
                    value={
                      vehicleOptions.find(
                        (v) => v.value === selectedVehicle?.value
                      )?.label
                    }
                    theme={theme}
                    menuListColor={"black"}
                    selectedValue={selectedVehicle?.value}
                    _handleClick={(value) => handleVehicleSelected(value)}
                    placeholder={placeholder}
                    setPlaceHolder={setPlaceHolder}
                  />
                </div>
              </div>
              <div className="row px-2 pt-4">
                <div
                  className="col-xl-6 col-lg-6 col-md-6 col-sm-12"
                  // className="col-xl-4 col-lg-4 col-md-4 col-sm-12"
                  // className={`${dateFormat === 'Custom' || dateFormat === 'Year' || dateFormat === '7 Days'
                  //     ? 'col-xl-6 col-lg-6 col-md-6 col-sm-12'
                  //     : dateFormat === 'Month'
                  //         ? 'col-xl-6 col-lg-6 col-md-6 col-sm-12'
                  //         : 'col-xl-6 col-lg-6 col-md-6 col-sm-12'}`}
                >
                  <Selector
                    isSearchable={false}
                    // label={t("Year")}
                    label={t("Timeline")}
                    options={dateOptions}
                    value={dateOptions.find((v) => v.value === dateFormat)}
                    _handleSelect={({ value, label }) => {
                      // const year = value;
                      // handleYearSelect(year);
                      setSelectDateFormat(value);
                    }}
                  />
                </div>
                {dateFormat === "Month" ? (
                  <>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                      <Selector
                        isSearchable={false}
                        label={t("Month")}
                        options={months}
                        value={months.find((m) => m.value === month)}
                        _handleSelect={({ value, label }) => {
                          const month = value;
                          handleMonthSelect(month);
                        }}
                      />
                    </div>
                    <div
                      className="col-xl-3 col-lg-3 col-md-3 col-sm-12"
                      style={{ color: invertDark(theme) }}
                    >
                      <p
                        className=" mb-0 font-weight-bold"
                        style={{ fontWeight: "bold" }}
                      >
                        Year:
                      </p>
                      <DatePickerAntD
                        picker={"year"}
                        label="Year"
                        format="YYYY"
                        theme={theme}
                        defaultValue={dayjs(defaultDate)}
                        disabledDate={(current) => {
                          return (
                            current &&
                            (current < moment("2000", "YYYY") ||
                              current > moment(new Date(), "YYYY"))
                          );
                        }}
                        onChange={(date, dateString) =>
                          _handleSelectYear(dateString)
                        }
                      />
                    </div>
                  </>
                ) : dateFormat === "Year" ? (
                  <>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                      <p
                        className=" mb-0"
                        style={{ color: invertDark(theme), fontWeight: "bold" }}
                      >
                        Year:
                      </p>
                      <DatePickerAntD
                        picker={"year"}
                        label={t("Year")}
                        theme={theme}
                        defaultValue={dayjs(defaultDate)}
                        disabledDate={(current) => {
                          return (
                            current &&
                            (current < moment("2000", "YYYY") ||
                              current > moment(new Date(), "YYYY"))
                          );
                        }}
                        onChange={(date, dateString) =>
                          _handleSelectYear(dateString)
                        }
                      />
                    </div>
                  </>
                ) : dateFormat === "Custom" ? (
                  <>
                    <div
                      className={`col-xl-6 col-lg-6 col-md-6 col-sm-12 ${
                        theme === "dark" ? "dark-theme" : "light-theme"
                      }`}
                    >
                      <p
                        className=" mb-0"
                        style={{ color: invertDark(theme), fontWeight: "bold" }}
                      >
                        Start Date - End Date:
                      </p>
                      <RangePicker
                        disabledDate={disabledDate}
                        format="YYYY-MM-DD"
                        style={{
                          background:
                            theme === "dark" ? Colors.darkBlue : Colors.Dark[5],
                          // color: theme === "dark" ? Colors.Dark[4] : Colors.Dark[1],
                          width: "100%",
                          height: "38px",
                          borderRadius: 3,
                          borderColor:
                            theme === "dark" ? Colors.Dark[2] : Colors.Dark[4],
                        }}
                        className={`${
                          theme === "dark" ? "dark-theme" : "light-theme"
                        }`}
                        onChange={(value, dateString) =>
                          _handleSelectCustom(value, dateString)
                        }
                      />
                    </div>
                  </>
                ) : dateFormat === "7 Days" ? (
                  <>
                    <div
                      className={`col-xl-6 col-lg-6 col-md-6 col-sm-12 ${
                        theme === "dark" ? "dark-theme" : "light-theme"
                      }`}
                    >
                      <p
                        className=" mb-0"
                        style={{ color: invertDark(theme), fontWeight: "bold" }}
                      >
                        Date:
                      </p>
                      <DatePicker
                        disabledDate={disabledDate}
                        value={dayjs(endDateFormat)}
                        picker="week"
                        format="YYYY-MM-DD"
                        style={{
                          background:
                            theme === "dark" ? Colors.darkBlue : Colors.Dark[5],
                          width: "100%",
                          height: "38px",
                          borderRadius: "3px",
                          borderColor:
                            theme === "dark" ? Colors.Dark[2] : Colors.Dark[4],
                        }}
                        className={`${
                          theme === "dark" ? "dark-theme" : "light-theme"
                        }`}
                        onChange={(dates, dateStrings) =>
                          _handleSelectDay(dates, dateStrings)
                        }
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>

          {width < 701 ? (
            <div className="d-flex flex-column">
              <div
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                className="d-flex align-items-center justify-content-center w-100 h-100"
                style={{ ...props.cardStyle1, cursor: "pointer" }}
              >
                <VehicleDetailImageFMS
                  text={
                    selectedVehicle
                      ? selectedVehicle.label
                      : vehicleOptions[0]?.label
                  }
                  isAds={isAds}
                  asset_name={
                    selectedVehicle
                      ? selectedVehicle.label
                      : vehicleOptions[0]?.label
                  }
                  image={
                    selectedVehicle
                      ? selectedVehicle.image
                      : selectedVehicle?.[0]?.image
                  }
                />
              </div>

              <div
                id="indicator"
                className="w-100 h-100"
                // style={rowStyle }
                style={props.cardStyle1}
              >
                <EmissionIndicatiorFMS theme={theme} />
              </div>
            </div>
          ) : (
            <div
              className={`${
                window.innerWidth > 540 && "d-flex"
              } gap-4 col-xl-6 col-lg-6 col-sm-12`}
            >
              <div
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                className="d-flex align-items-center justify-content-center w-100 h-100"
                style={{ ...props.cardStyle1, cursor: "pointer" }}
              >
                <VehicleDetailImageFMS
                  text={
                    selectedVehicle
                      ? selectedVehicle.label
                      : vehicleOptions[0]?.label
                  }
                  isAds={isAds}
                  asset_name={
                    selectedVehicle
                      ? selectedVehicle.label
                      : vehicleOptions[0]?.label
                  }
                  image={
                    selectedVehicle
                      ? selectedVehicle.image
                      : selectedVehicle?.[0]?.image
                  }
                />
              </div>

              <div
                id="indicator"
                className="w-100 h-100"
                // style={rowStyle }
                style={props.cardStyle1}
              >
                <EmissionIndicatiorFMS theme={theme} />
              </div>
            </div>
          )}
        </div>

        {selectedVehicle && (
          <PreviewVehicleModalFMS
            theme={theme}
            asset_name={
              selectedVehicle ? selectedVehicle.label : vehicleOptions[0]?.label
            }
            image={
              selectedVehicle
                ? selectedVehicle?.image
                : vehicleOptions[0]?.image
            }
          />
        )}
      </>
    );
  })
);
