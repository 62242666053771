import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { baseURL } from '../network/URL';

export const My_Carousel = ({images}) => {

        return (
            <Carousel>

                {images.map(v=>(<div>
                    <img src={baseURL + "/" + v.image} />
                    <p className="legend">{v.name}</p>
                </div>))}
                
            </Carousel>
        );

};