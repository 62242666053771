import { inject, observer } from 'mobx-react';
import { CO2_Loading } from "../../Assets/CO2_Loading";
import 'datatables.net-dt';
import 'datatables.net-dt/css/jquery.dataTables.css';
import $ from 'jquery'
import React, { useEffect } from "react";
import { toJS } from 'mobx';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

export const RegCertificateTable = inject("store")(observer((props) => {
    const theme = props.store.login.theme;
    const {t} = useTranslation("dashboard")
    const { regCertificates } = props;

    const { _handleFetchStacsCertificates } = props.store.dashboard;

    useEffect(() => {
        _handleFetchStacsCertificates();
    }, [])


    const tableRef = useRef(null);

    let table = null;

    useEffect(() => {
        // Initialize DataTable when the component mount

        const styleElement = document.createElement(`style`);
        styleElement.innerHTML = theme === 'dark' ? `
            .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
                color: black !important;
                border: 1px solid #E5E5E5!important;
                background-color: #E5E5E5 !important;
                /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #E5E5E5), color-stop(100%, #E5E5E5))!important; */
                background: -webkit-linear-gradient(top, #E5E5E5 0%, #E5E5E5 100%)!important;
                background: -moz-linear-gradient(top, #E5E5E5 0%, #E5E5E5 100%)!important;
                background: -ms-linear-gradient(top, #E5E5E5 0%, #E5E5E5 100%) !important;
                background: -o-linear-gradient(top, #E5E5E5 0%, #E5E5E5 100%)!important;
                background: linear-gradient(to bottom, #E5E5E5 0%, #E5E5E5 100%)!important;
              }
              .dataTables_wrapper .dataTables_paginate .paginate_button.current {
                color: black !important;
                border: 1px solid #C6C6C6!important;
                background-color: #C6C6C6 !important;
                /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FFFCFC), color-stop(100%, #969292))!important; */
                background: -webkit-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
                background: -moz-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
                background: -ms-linear-gradient(top, #FFFCFC 0%, #969292 100%) !important;
                background: -o-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
                background: linear-gradient(to bottom, #FFFCFC 0%, #969292 100%)!important;
              }
              .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
                color: black !important;
                border: 1px solid #C6C6C6!important;
                background-color: #C6C6C6 !important;
                /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FFFCFC), color-stop(100%, #969292))!important; */
                background: -webkit-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
                background: -moz-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
                background: -ms-linear-gradient(top, #FFFCFC 0%, #969292 100%) !important;
                background: -o-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
                background: linear-gradient(to bottom, #FFFCFC 0%, #969292 100%)!important;
              }

              .dataTables_wrapper .dataTables_paginate .paginate_button {
                color: white !important; 
              }

              .dataTables_wrapper .dataTables_paginate .ellipsis {
                color: white !important; 
              }

              .dataTables_wrapper .dataTables_length{
                color: white !important;
              }

              .dataTables_wrapper .dataTables_filter {
                color: white !important;
              }

              .dataTables_wrapper .dataTables_info {
                color: white !important;
              }

              table.dataTable thead th {
                color: white !important;
              }
            ` :
            `
                .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
                    color: black !important;
                    border: 1px solid #E5E5E5!important;
                    background-color: #E5E5E5 !important;
                    /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #E5E5E5), color-stop(100%, #E5E5E5))!important; */
                    background: -webkit-linear-gradient(top, #E5E5E5 0%, #E5E5E5 100%)!important;
                    background: -moz-linear-gradient(top, #E5E5E5 0%, #E5E5E5 100%)!important;
                    background: -ms-linear-gradient(top, #E5E5E5 0%, #E5E5E5 100%) !important;
                    background: -o-linear-gradient(top, #E5E5E5 0%, #E5E5E5 100%)!important;
                    background: linear-gradient(to bottom, #E5E5E5 0%, #E5E5E5 100%)!important;
                  }
                  .dataTables_wrapper .dataTables_paginate .paginate_button.current {
                    color: black !important;
                    border: 1px solid #C6C6C6!important;
                    background-color: #C6C6C6 !important;
                    /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FFFCFC), color-stop(100%, #969292))!important; */
                    background: -webkit-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
                    background: -moz-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
                    background: -ms-linear-gradient(top, #FFFCFC 0%, #969292 100%) !important;
                    background: -o-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
                    background: linear-gradient(to bottom, #FFFCFC 0%, #969292 100%)!important;
                  }

                  .dataTables_wrapper .dataTables_paginate .paginate_button {
                    color: black !important; 
                  }  
                  
                  .dataTables_wrapper .dataTables_paginate .ellipsis {
                    color: black !important; 
                  }

                  .dataTables_wrapper .dataTables_length{
                    color: black !important;
                  }

                  .dataTables_wrapper .dataTables_filter {
                    color: black !important;
                  }
    
                  .dataTables_wrapper .dataTables_info {
                    color: black !important;
                  }

                  table.dataTable thead th {
                    color: black !important;
                  }
            `
        document.head.appendChild(styleElement);


        table = $(tableRef.current).DataTable({
            retrieve: true,
            scrollX: true,
            lengthMenu: [8, 10, 15],
            data: [
                {
                    certFramework: 'Verra',
                    certIssuer: 'Verra',
                    certSector: 'Carbon Credits/RECs',
                    asset: 'Installation of high efficiency wood burning cookstoves in Laos',
                    assetType: 'Energy demand; Energy industries (renewable/non-renewable sources',
                    assetAddress: 'N/A',
                    countryName: 'Laos',
                    companyName: 'C-Quest Capital SGT Asia Stoves Private Limited',
                    companyNameWIP: 'C-Quest Capital SGT Asia Stoves Private Limited',
                    certStatus: 'Certified',
                    certType: 'Verified Carbon Standard (VCS) Project',
                    certLevel: 'N/A',
                    certCategory: 'Enveronmental',
                    startDate: '2022-05-04',
                    endDate: '2032-05-03',
                    assetAddressLatitude: 'N/A',
                    assetAddressLongitude: 'N/A',
                    originalCertStatus: "Registered"
                },
            ],
            columns: [
                { title: t('Cert Framework'), data: 'certFramework' },
                { title: t('Cert Issuer'), data: 'certIssuer' },
                { title: t('Cert Sector'), data: 'certSector' },
                { title: t('Asset'), data: 'asset' },
                { title: t('Asset Type'), data: 'assetType' },
                { title: t('Asset Address'), data: 'assetAddress' },
                { title: t('Country Name'), data: 'countryName' },
                { title: t('Country Name WIP '), data: 'companyNameWIP' },
                { title: t('Cert Status'), data: 'certStatus' },
                { title: t('Cert Type'), data: 'certType' },
                { title: t('Cert Level'), data: 'certLevel' },
                { title: t('Cert Category'), data: 'certCategory' },
                { title: t('Start Date'), data: 'startDate' },
                { title: t('End Date'), data: 'endDate' },
                { title: t('Asset Address Latitude'), data: 'assetAddressLatitude' },
                { title: t('Asset Address Longitude'), data: 'assetAddressLongitude' },
                { title: t('Original Cert Status'), data: 'originalCertStatus' },
            ],
        })
          

        table.cells().every(function () {
            $(this.node()).css('border-color', 'rgb(211,211,211, 0.3)');
        });


        const searchInput = $('.dataTables_filter input');
        searchInput.css('color', theme === 'light' ? 'black' : 'white');

        const entriesSelect = $('.dataTables_length select');
        entriesSelect.css('background-color', theme === 'light' ? '' : 'rgb(32, 43, 96)');
        entriesSelect.css('color', theme === 'light' ? 'black' : 'white');

        let tableRow = $('table.dataTable tbody')
        tableRow.css('cursor', 'pointer')

    }, [theme])

    // useEffect(() => {
    //   // This effect runs when logsData changes
    //   if (table) {
    //       // Clear the existing data in the DataTable
    //       table.clear();

    //       // Add the updated data to the DataTable
    //       table.rows.add(logsData);

    //       // Redraw the DataTable with the new data
    //       table.draw();

        //   table.column(0).nodes().to$().css('width', '5%');
        //   table.column(1).nodes().to$().css('width', '5%');
        //   table.column(2).nodes().to$().css('width', '25%');
        //   table.column(3).nodes().to$().css('width', '5%');

    //       table.cells().every(function () {
    //         $(this.node()).css('border-color', 'rgb(211,211,211, 0.3)');
    //       });

    //   }
    // }, [logsData]);

    if (regCertificates) {
        return (
            <div>
                <table ref={tableRef} className='display cell-border hover stripe' style={{ width: '100%', color: theme === "dark" ? 'white' : 'black' }}>
                </table>
            </div>
        )
    }
    else
        return <CO2_Loading />
}))


export default RegCertificateTable