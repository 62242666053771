import { Selector } from "../../Tools";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { inject, observer } from "mobx-react";
import Store from "../../Store";
import { useEffect, useState } from "react";
import { toJS } from "mobx";
import { useTranslation } from "react-i18next";

export const YearMonthSelector = inject("store")(
    observer((props) => {
        const {t} = useTranslation("data_import")
        const {
            year,
            month,
            handleYearSelect,
            handleMonthSelect,
            handleGetImportUnitsHistory,
        } = props.store.dataImport;

        const { companyyearlydata, _handleFetchCompanyYears } = props.store.dashboard;

        const currentYear = new Date().getFullYear();
        const [selectedYear, setSelectedYear] = useState(currentYear);

        const months = [
            { value: 0, label: t("All") },
            { value: 1, label: t("January") },
            { value: 2, label: t("February") },
            { value: 3, label: t("March") },
            { value: 4, label: t("April") },
            { value: 5, label: t("May") },
            { value: 6, label: t("June") },
            { value: 7, label: t("July") },
            { value: 8, label: t("August") },
            { value: 9, label: t("September") },
            { value: 10, label: t("October") },
            { value: 11, label: t("November") },
            { value: 12, label: t("December") },
        ];
        const [selectedMonth, setSelectedMonth] = useState(0);

        useEffect(() => {
            _handleFetchCompanyYears();
            // handleGetImportUnitsHistory();
            handleMonthSelect(0);

            const storedYear = localStorage.getItem("selectedYear");
            if (storedYear) {
                setSelectedYear(parseInt(storedYear));
            }
        }, []);

        const handleYearChange = (value) => {
            const year = value;
            setSelectedYear(year);
            handleYearSelect(year);
            localStorage.setItem("selectedYear", year.toString());
        };

        const handleMonthChange = (value) => {
            const month = value;
            setSelectedMonth(month);
            handleMonthSelect(month);
        };

        // console.log("selectedmonth==>", selectedMonth);
        return (
            <>
                <div>
                    <div className="row m-0 d-flex flex-nowrap">
                        <div className="ps-0" style={{ width: 120 }}>
                            <Selector
                                isSearchable={false}
                                label={t("Year")}
                                options={companyyearlydata}
                                value={companyyearlydata.find((y) => y.value === selectedYear)}
                                _handleSelect={({ value, label }) => handleYearChange(value)}
                            />
                        </div>

                        <div className="pe-0" style={{ width: 180 }}>
                            <Selector
                                isSearchable={false}
                                label={t("Month")}
                                options={months}
                                value={months.find((m) => m.value === selectedMonth)}
                                _handleSelect={({ value, label }) => handleMonthChange(value)}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    })
);


