import React from 'react';
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { baseIMGURL } from '../../network/URL';
import Store from '../../Store';

export const PreviewVehicleModal = (props) => {
  const { asset_name, theme } = props;
  const [imgUrl, setImgUrl] = React.useState(`/cars/${  asset_name ? asset_name.split('-')[0].split('_')[0]: 'default_vehicle'}.jpg`);
  const {isAds} = Store.common;
  React.useEffect(() => {
    setImgUrl(`/cars/${  asset_name ? asset_name.split('-')[0].split('_')[0]+'.jpg': 'default_vehicle.png'}`);
  }, [asset_name]);
  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content"  style={{background:Colors[theme].background, color:invertDark(theme)}}>
          <div className="modal-header border-0">
            <h5 className="modal-title" id="exampleModalLabel">
              {asset_name ? asset_name.split('-')[0].split('_')[0] : "no image"}
            </h5>
            <button
              type="button"
              className={`btn-close ${theme==='dark'&&'btn-close-white'}`}
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <img
              src={imgUrl}
              alt="car_image"
              className="img-fluid py-1"
              style={{ width:'100%',borderRadius:5}}
              onError={(e) => {
                setImgUrl(isAds?`/equipments/default_equipment.png`:`/cars/default_vehicle.png`);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};


export const PreviewVehicleModalFMS = (props) => {  
  const { asset_name, theme, image } = props;
  const default_img = '/cars/default_vehicle.png';
  const [imgUrl, setImgUrl] = React.useState(`/cars/${  asset_name ? asset_name : 'default_vehicle'}.jpg`);
  React.useEffect(() => {
    setImgUrl(image ? `${baseIMGURL}uploads/devices/${image}` : default_img);
  }, [asset_name]);
  return (
    <div
      className="modal  fade"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content"  style={{background:Colors[theme].background, color:invertDark(theme)}}>
          <div className="modal-header border-0">
            <h5 className="modal-title" id="exampleModalLabel">
              {asset_name ? asset_name : "no image"}
            </h5>
            <button
              type="button"
              className={`btn-close ${theme==='dark'&&'btn-close-white'}`}
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <img
              src={imgUrl}
              alt="car_image"
              className="img-fluid py-1"
              style={{ width:'100%',borderRadius:5 }}
              onError={(e) => {
                setImgUrl(`/cars/default_vehicle.png`);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
