import { Colors, invertDark } from "../../Assets/Config/color.config";
import { My_Button, My_Input, My_upload_image, Selector } from "../../Tools";
import { inject, observer } from "mobx-react";
import { useState } from "react";
import allCountry from '../../Assets/countries.json'
import { baseURL } from "../../network/URL";
import { UploadImage } from "../../Containers/AdminPanel/UploadImage";
import { My_DatePicker } from "../../Tools";
import { toJS } from "mobx";
import Tour from './adminUpdateTour';


export const UpdateCompanyModalJQ = inject("store")(
    observer((props) => {
        const { theme, selectedCountry, setSelectedCountry } = props;

        const { switchValue, setSwitchValue, level, selectedLevel, companyLevel, setSelectedLevel, _handleCompanyInputChange, photo, companyInfo, _handleFormClear, _handleUpdateCompany, _handleChangeExpireDate, _handleChangeRegisterDate } = props.store.adminPanel;

        const [editImg, setEditImg] = useState(false)


        const CountryOptions = allCountry?.map((country) => ({
            value: country.name,
            label: country.name,
            code: country.code.toLowerCase(),
        }));

        console.log("companyIfo", toJS(companyInfo))


        const handleSwitchChange = () => {
            setSwitchValue(!switchValue)
        }


        const levels = [0, 1, 2, 3, 4, 5]
        // const levels = [1,2,3,4,5]

        let levelOptions = levels.map((d, i) => ({
            value: d,
            label: d
        }))

        levelOptions = levelOptions.filter(v => v.value >= companyLevel)

        return (
            <div
                className="modal fade show"
                id="updatecompanymodalJQ"
                tabIndex="-1"
                aria-labelledby="updatecompanymodalJQ"
                aria-hidden="true"

            >
                <div className="w-100 d-flex align-items-center justify-content-end py-2 px-3">
                    {/* {<Tour/>} */}
                </div>
                <div className="modal-dialog modal-xl rounded" >
                    <div
                        className="update-admin-guide modal-content"
                        style={{ background: Colors[theme]?.background, color: invertDark(theme) }}
                    >
                        <div className="modal-header" >

                            <h5 className="modal-title" style={{ fontSize: 24, color: theme === 'dark' ? '#fff' : Colors[theme].color, borderTopLeftRadius: 10, borderTopRightRadius: 10, marginLeft: 2 }}>

                                Update Company Info
                            </h5>

                            <button className={`btn-close ${theme === 'dark' ? 'btn-close-white' : ''}`} data-bs-dismiss="modal" onClick={() => {
                                _handleFormClear()
                                setEditImg(false)
                            }} style={{ cursor: 'pointer', marginRight: 10 }}>
                               
                            </button>
                        </div>

                        <div className="modal-body">

                            <div className="d-flex flex-column" style={{ borderBottomRightRadius: 5, borderBottomLeftRadius: 5, minHeight: 430 }}>
                                <div className="d-flex justify-content-center">
                                    <div className="col-4 d-flex justify-content-center py-5">
                                        <div style={{ width: '150px', height: '150px', borderRadius: 5, cursor: 'pointer' }} className='d-flex align-items-center justify-content-center'>
                                            {editImg ? <UploadImage photo={photo} />
                                                : <img width='150px' src={baseURL + companyInfo.logo} onClick={() => setEditImg(true)} style={{ border: theme === 'dark' ? '' : '1px solid black' }} />}

                                        </div>
                                    </div>
                                    <div className="col-8 py-5 pe-5">
                                        <div className='d-flex align-items-center pb-3'>
                                            <span className='col-4'>
                                                <span style={{ color: '#ffc107' }}>*</span> Name
                                            </span>
                                            <span className='col'>
                                                <My_Input id={"companyName"}
                                                    value={companyInfo.name}
                                                    placeHolder={"Enter your company name..."}
                                                    onChange={(e) => _handleCompanyInputChange({ name: e.target.value })}
                                                />
                                            </span>
                                        </div>

                                        <div className='d-flex align-items-center pb-3'>
                                            <span className='col-4'>
                                                <span style={{ color: '#ffc107' }}>*</span> Country
                                            </span>
                                            <span className='col-8'>
                                                <Selector value={CountryOptions.find(v => v.value === selectedCountry)} options={CountryOptions} menuListColor='black' _handleSelect={(e) => {
                                                    _handleCompanyInputChange({ country: e.value })
                                                    setSelectedCountry(e.value)
                                                }} />
                                            </span>
                                        </div>
                                        <div className='d-flex align-items-center pb-3'>
                                            <span className='col-4'>
                                                <span style={{ color: '#ffc107' }}>*</span> Type of Company
                                            </span>
                                            <span className='col'>
                                                <My_Input
                                                    id={"type"}
                                                    value={companyInfo.type}
                                                    placeHolder={"Enter type..."}
                                                    onChange={(e) => _handleCompanyInputChange({ type: e.target.value })}
                                                />
                                            </span>
                                        </div>

                                        {/* <div className='d-flex align-items-center pb-3'>
                                                <span className='col-4'>
                                                <span style={{ color: '#ffc107' }}>*</span> Company Level
                                                </span>
                                                <span className='col-8'>
                                                    <Selector value={levelOptions.find(v => v.value == selectedLevel)} options={levelOptions}  menuListColor='black' _handleSelect={(e) => {
                                                        _handleCompanyInputChange({ level: e.value })
                                                       setSelectedLevel(e.value)
                                                        }} />
                                                </span>
                                            </div> */}

                                        <div className='d-flex align-items-center pb-3'>
                                            <span className='col-4'>
                                                <span style={{ color: '#ffc107' }}>*</span> Company Registration Number (Company in UEN context)
                                            </span>
                                            <span className='col'>
                                                <My_Input
                                                    // type={'number'}
                                                    id={"companyRegNumber"}
                                                    value={companyInfo.companyRegNumber === "undefined " || companyInfo.companyRegNumber === null ? "" : companyInfo.companyRegNumber}
                                                    placeHolder={"Enter company registration number..."}
                                                    onChange={(e) => _handleCompanyInputChange({ companyRegNumber: e.target.value })}
                                                />
                                            </span>
                                        </div>



                                        <div className='d-flex align-items-center pb-3'>
                                            <span className='col-4'>
                                                Date of Account Creation
                                            </span>
                                            <span className='col'>
                                                <My_DatePicker height={'43px'} selected={companyInfo.registerDate} onChange={(e) => _handleChangeRegisterDate(e)} />
                                            </span>
                                        </div>

                                        <div className='d-flex align-items-center pb-3'>
                                            <span className='col-4'>
                                                Subscription Expiry Date
                                            </span>
                                            <span className='col'>
                                                <My_DatePicker height={"43px"} selected={companyInfo.expireDate} onChange={(e) => _handleChangeExpireDate(e)} />
                                            </span>
                                        </div>

                                        {/* <div className='d-flex align-items-center pb-3'>
                                                <span className='col-4'>
                                                    Data Import
                                                </span>
                                                
                                                <div className="form-check form-switch col">
                                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={switchValue} onChange={() => handleSwitchChange()}/>
                                                </div>
                                                
                                            </div> */}
                                        {/* <div className='d-flex align-items-center pb-3'>
                                                <span className='col-4'>
                                                    Ascent Account ID
                                                </span>
                                                <span className='col'>
                                                    <My_Input id={"companyName"}
                                                        value={userData.ascentAccountId}
                                                        placeHolder={"Enter your ascent account id..."}
                                                        onChange={(e) => _handleInputChange({ ascentAccountId: e.target.value })}
                                                    />
                                                </span>
                                            </div> */}


                                    </div>
                                </div>
                            </div>




                        </div>
                        <div className="modal-footer">
                            <div className='float-end' data-bs-dismiss="modal">
                                <My_Button onClick={() => _handleUpdateCompany()} customColor={'#4c5d75'} customHoverColor={theme === 'dark' ? '' : '#05e700'} className='px-3 ' text='Update' />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }));

