import { useTranslation } from "react-i18next";
import { Colors } from "../../Assets/Config/color.config";
export const ReportTable = (props) => {
    const {t} = useTranslation("lite-manual-entry")
    const { reportData } = props;
    if(reportData.length!==0)
    return (
        <>
            <table className="mt-4 text-center" style={{
                width: "100%",
                // border: `1px solid ${Colors.Dark[4]}`,
            }}>
                <tr style={{ height: 100, background: Colors.darkBlue, color: '#ffff' }}>
                    <th className="text-start ps-3" style={{ borderRight: '4px solid white' }}>{t("Description")}</th>
                    <th>{t("Amount")}</th>
                    <th>{t("Unit")}</th>
                </tr>
                <tr style={{ height: 50 }}>
                    <td className="text-start ps-3" >{t("Baseline Co2 Emission")}(kgCO2e/km)</td>
                    <td>{
                        parseInt(reportData?.baselineCo2Emission).toFixed(2) || '--'}</td>
                    <td>kgCO2e/{reportData.data?.mileage_unit}</td>
                </tr>
                <tr style={{ height: 50, background: Colors.Dark[5] }}>
                    <td className="text-start ps-3">{t("Total Emission")}(kg)</td>
                    <td>{
                        parseInt(reportData?.totalEmission).toFixed(2) || '--'}</td>
                    <td>kg</td>
                </tr>
                <tr style={{ height: 50 }}>
                    <td className="text-start ps-3">{t("Total Mileage")}(km)</td>
                    <td>{
                        parseInt(reportData?.totalMileage).toFixed(2) || '--'}</td>
                    <td>{reportData.data?.mileage_unit}</td>
                </tr>
                <tr style={{ height: 50, background: Colors.Dark[5] }}>
                    <td className="text-start ps-3">{t("Total Consumption")}</td>
                    <td>{
                        parseInt(reportData?.totalConsumed).toFixed(2) || '--'}</td>
                    <td>{reportData.data?.type_unit}</td>
                </tr>
            </table>
        </>
    )
    else return null;
}