import { My_Button, My_Input, Selector } from '../../Tools'
import { Colors } from '../../Assets/Config/color.config';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { minHeight } from '@mui/system';

export const CreateNewUser = inject("store")(
    observer((props) => {

    const { CompanyOptions } = props
    const { role, companyId} = props.store.common
    const { userId, userData, _handleInputChange, _handleUserRegister, selectedCompany, editView, _handleUpdateUser } = props.store.landing
    const { theme } = props.store.login;

    
    
 
    return (<div className="col-6">
        <div >
            <div style={{  background: theme==='dark'?'#192353':'rgb(0,0,0,0.12)', borderTopRightRadius: 5, borderTopLeftRadius: 5 }}>
                <div className='p-2'>Create New User</div>
            </div>
            <div className="row m-0" style={{ background: theme==='dark'?'#192353':'#fff', borderBottomRightRadius: 5, borderBottomLeftRadius: 5, minHeight: 480}}>
                
                <div className="py-5 px-5">
                    <div className='d-flex align-items-center pb-3'>
                        <span className='col-4'>
                            Username
                        </span>
                        <span className='col-8'>
                            <My_Input 
                                id={'username'}
                                required={false}
                                value={userData.username}
                                height='38px'
                                placeHolder={"Enter your username"}
                                onChange={(e) => _handleInputChange({username: e.target.value})} 
                            />
                        </span>
                    </div>
                    <div className='d-flex align-items-center pb-3'>
                        <span className='col-4'>
                            Email
                        </span>
                        <span className='col-8'>
                            <My_Input required={false}
                                value={userData.email}
                                height='38px'
                                placeHolder={"Enter your email"}
                                onChange={(e) => _handleInputChange({email: e.target.value})}/>
                        </span>
                    </div>
                    <div className='d-flex align-items-center pb-3'>
                        <span className='col-4'>
                            Password
                        </span>
                        <span className='col-8'>
                            <My_Input required={false}
                                value={userData.password}
                                disabled={editView === true ? true : ''}
                                type={props.store.login.visible ? "text" : "password"} 
                                height='38px'
                                placeHolder={"Enter your password"}
                                onChange={(e) => _handleInputChange({password: e.target.value})}/>
                                <span
                                style={{
                                    float: "right",
                                    position: "relative",
                                    marginTop: "-52px",
                                    marginBottom: "-10px",
                                    fontSize: "18px",
                                    marginRight: "8px",
                                    cursor: "pointer",
                                }}
                                onClick={() => props.store.login._handleView()}
                            >
                                <i
                                    className={`fa fa-eye${props.store.login.visible ? "-slash" : ""
                                        } py-4 text-secondary`}
                                />
                            </span>
                        </span>
                    </div>
                    {editView === true ? '' :  <div className='d-flex align-items-center pb-3'>
                        <span className='col-4'>
                            Confirm Password
                        </span>
                        <span className='col-8'>
                            <My_Input required={false}
                                value={userData.confirmPassword}
                                type={props.store.login.visible ? "text" : "password"}
                                height='38px'
                                placeHolder={"Confirm your password"}
                                onChange={(e) => _handleInputChange({confirmPassword: e.target.value})}/>
                            <span
                                style={{
                                    float: "right",
                                    position: "relative",
                                    marginTop: "-52px",
                                    marginBottom: "-10px",
                                    fontSize: "18px",
                                    marginRight: "8px",
                                    cursor: "pointer",
                                }}
                                onClick={() => props.store.login._handleView()}
                            >
                                <i
                                    className={`fa fa-eye${props.store.login.visible ? "-slash" : ""
                                        } py-4 text-secondary`}
                                />
                            </span>
                        </span>
                    </div>}
                    {editView === true ? '' :  <div className='d-flex align-items-center pb-3'>
                        <span className='col-4'>
                            Assign Company
                        </span>
                        <span className='col-8'>
                           <Selector options={CompanyOptions} value={CompanyOptions?.find(v => v.label === selectedCompany )} menuListColor={Colors.darkBlue} _handleSelect={(e) => _handleInputChange({companyId: e.value})} />
                        </span>
                    </div>}
                    <div className='d-flex align-items-center pb-3'>
                        <span className='col-4'>
                            Role
                        </span>
                        <div className='col-8 d-flex justify-content-between align-items-center'>
                            
                                <label className="pe-3" htmlFor="admin">
                                    <input className="me-1" id='admin' type='radio' value='admin' name='role'  checked={userData.role === 'admin' ? true : ''}
                                    height='38px' onChange={(e) => _handleInputChange({role: e.target.value})}></input>
                                    Admin
                                </label>
                                <label className="pe-3" htmlFor="user">
                                    <input className="me-1" id='user' type='radio' value='user' name='role' checked={userData.role === 'user' ? true: ''}
                                    height='38px' onChange={(e) => _handleInputChange({role: e.target.value})}></input>
                                    User
                                </label>
                                <label htmlFor="driver">
                                <input className="me-1" id='driver' type='radio' 
                                    value='driver' name='role' checked={userData.role === 'driver' ? true: ''}
                                    height='38px' onChange={(e) => _handleInputChange({role: e.target.value})}></input>
                                    Driver
                                </label>
                            
                        </div>
                    </div>
                    {editView === true ? <div className="mt-3 float-end" >
                        <span style={{ cursor: 'pointer' }}>
                            <My_Button
                                customColor={theme === 'dark'? '#0064FB':'#04c600'} customHoverColor={theme === 'dark'?'':'#05e700'} 
                                text='Update'
                                onClick={() => _handleUpdateUser(userId)}
                                                        
                                style={{ minWidth: 100 }}
                                customAdd={<i className="bi bi-plus-square-dotted" style={{ marginLeft: "0.5em" }}></i>}
                            />
                        </span>

                    </div> : 
                    <div className="mt-3 float-end" >
                        <span style={{ cursor: 'pointer' }}>
                            <My_Button
                                customColor={theme === 'dark'? '#0064FB':'#04c600'} customHoverColor={theme === 'dark'?'':'#05e700'} 
                                text='Add'
                                onClick={() => _handleUserRegister()}
                                                        
                                style={{ minWidth: 100 }}
                                customAdd={<i className="bi bi-plus-square-dotted" style={{ marginLeft: "0.5em" }}></i>}
                            />
                        </span>

                    </div>}


                </div>

            </div>
        </div>
    </div>)
}))