import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { DarkModeSwitch } from "../Tools/darkModeSwitch";
import { Colors } from "../Assets/Config/color.config";
import Cookies from "universal-cookie";
import jwt_decode from "jwt-decode";
import Store from "../Store";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom";

export default function DrawerMenu(props) {
  const cookies = new Cookies();
  let companyId = cookies.get("company");
  const token = cookies.get("accessToken");
  const cookieCompanyId = cookies.get("company");
  const companyName = cookies.get("companyName");
  const planData = token && jwt_decode(token).planData;
  const { role } = Store.common;
  const { handleLogout, theme } = props;
  const {_handleViewCertificate, certificateView} = Store.dashboard;
  // const [state, setState] = React.useState({
  //   right: false,
  // });
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  // const toggleDrawer = (anchor, open) => (event) => {
  //   if (
  //     event.type === "keydown" &&
  //     (event.key === "Tab" || event.key === "Shift")
  //   ) {
  //     return;
  //   }

  //   setState({ ...state, [anchor]: open });
  // };
  const selectedStyle = {
    background:
      theme === "dark" ? Colors.lightBlue : Colors[theme].secondaryColor,
    // paddingTop: "auto",
    borderRadius: 5,
    // boxShadow: "1px 1px 5px #303030",
    height: "34px",
    lineHeight: "32px",
    textDecoration: "none",
    color: Colors.Dark[5],
  };

  const defaultStyle = {
    cursor: "pointer",
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const history = useHistory();

  const handleDropdownClick = (path) => {
    toggleDrawer();
    history.push(path);
  };

  const sidebarMenu = [
    {
      id: "organisation-level-carbon-emission-dashboard",
      text: "Organisation-level",
      icon: "fa fa-home",
      link: "/organisation-level-carbon-emission-dashboard",
      access: planData?.enterprise_overview_access,
    },
    {
      id: "Certificate",
      text: "Certificate",
      icon: "fa fa-award",
      link: "/organisation-level-carbon-emission-dashboard",
      access: planData?.enterprise_overview_access,
    },
    {
      id: "new-tracking",
      text: "Unit-level",
      icon: "bi bi-pin-map-fill",
      link: "/new-tracking",
      access: planData?.unit_level_access,
    },
    {
      id: "track_and_trace",
      text: "GHG Emissions Report",
      // text: "Not available yet!",
      icon: "fa fa-file-contract",
      link: "/track_and_trace",
      access: role === "user" ? 0 : planData?.enterprise_report_access,
      //access : 0
    },
    {
      id: "asset_profile_management",
      text: "Unit",
      icon: "bi bi-cpu",
      link: "/asset_profile_management",
      access: planData?.unit_access,
    },
    {
      id: "new-manual-entry",
      text: "Data Entry",
      icon: "fa fa-keyboard",
      link: "/new-manual-entry",
      access: planData?.data_entry_access,
    },
    {
      id: "lite-dashboard",
      text: "Lite Dashboard",
      icon: "bi bi-speedometer",
      link: "/lite-dashboard",
      access: planData?.co2xLite_overview_access,
    },
    {
      id: "lite-manual-entry",
      text: "Lite Manual Entry",
      icon: "far fa-keyboard",
      link: "/lite-manual-entry",
      access: planData?.co2xLite_report_and_dataEntry_access,
    },
    {
      id: "data_import",
      text: "Data Import",
      icon: "fa fa-upload",
      link: "/data_import",
      access:
        role === "user" || companyName === "Co2xLite Demo"
          ? 0
          : role === "super_admin" || companyId === cookieCompanyId
          ? 1
          : planData?.data_import_access,

      // access: 1,
    },

    // {
    //   id: "manual_entry",
    //   text: "Data Entry",
    //   icon: "fa fa-keyboard",
    //   link: "/manual_entry",
    // },
  ];

  const sidebarMenuFms = [
    {
      id:
        planData?.enterprise_overview_access == 1 &&
        "organisation-level-carbon-emission-dashboard",
      text: "Organisation-level",
      icon: "fa fa-home",
      link: "/organisation-level-carbon-emission-dashboard",
      access: planData?.enterprise_overview_access,
    },
    {
      id: "Certificate",
      text: "Certificate",
      icon: "fa fa-award",
      link: "/organisation-level-carbon-emission-dashboard",
      access: planData?.enterprise_overview_access,
    },
    {
      id: "new-tracking",
      text: "Unit-level",
      icon: "bi bi-pin-map-fill",
      link: "/new-tracking",
      access: planData?.unit_level_access,
    },
    {
      id: "track_and_trace",
      text: "GHG Emissions Report",
      // text: "Not available yet!",
      icon: "fa fa-file-contract",
      link: "/track_and_trace",
      access: role === "user" ? 0 : planData?.enterprise_report_access,
      //access : 0
    },
    {
      id: "asset_profile_management",
      text: "Unit",
      icon: "bi bi-cpu",
      link: "/asset_profile_management",
      access: planData?.unit_access,
    },
    {
      id: "new-manual-entry",
      text: "Data Entry",
      icon: "fa fa-keyboard",
      link: "/new-manual-entry",
      access: planData?.data_entry_access,
    },
    {
      id: "data_import",
      text: "Data Import",
      icon: "fa fa-upload",
      link: "/data_import",
      // don't delete
      // access: companyId === decodedCompanyId? planData?.data_import_access:0
      access:
        role === "super_admin" || companyId === cookieCompanyId
          ? 1
          : role === "user"
          ? 0
          : planData?.data_import_access,
      // access: role === "user" ? 0 : 1
    },
    // {
    //     id: "customer_support",
    //     text: "Customer Support",
    //     icon: "fa-solid fa-comments ",
    //     link: "/customer_support",
    //     access: planData?.data_import_access || 1
    // }

    // {
    //     id: "lite-dashboard",
    //     text: "Lite Dashboard",
    //     icon: "bi bi-speedometer",
    //     link: "/lite-dashboard",
    //     access: planData?.co2xLite_overview_access
    // },
    // {
    //     id: "lite-manual-entry",
    //     text: "Lite Manual Entry",
    //     icon: "bi bi-box-arrow-in-right",
    //     link: "/lite-manual-entry",
    //     access: planData?.co2xLite_report_and_dataEntry_access
    // },
  ];

  sidebarMenu.map((value) => {
    console.log(
      "value ======>",
      value.access === 1 && value.text.includes("Lite")
    );
  });

  const list = (anchor) => (
    <Box
      sx={{
        width: 250,
        background:
          theme === "dark"
            ? Colors[theme].background
            : Colors[theme].primaryColor,
        height: "100%",
        color: Colors.Dark[4],
      }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer}
      className="pt-5"
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <div className="d-flex justify-content-between">
          {["theme", "user"].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon
                className="d-flex justify-content-center"
                style={{ color: Colors.Dark[4] }}
              >
                {index === 0 ? (
                  <div className="ps-4">
                    <DarkModeSwitch
                      onChangeTheme={props.onChangeTheme}
                      theme={props.theme}
                    />
                  </div>
                ) : (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="primary"
                      id="dropdown-basic"
                      style={{
                        background:
                          theme === "dark"
                            ? Colors.lightBlue
                            : Colors[theme].secondaryColor,
                        color: "#fff",
                      }}
                    >
                      <i className="bi bi-person-circle"></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      style={{
                        background:
                          theme === "dark"
                            ? Colors.lightBlue
                            : Colors[theme].secondaryColor,
                        color: "#fff",
                      }}
                    >
                      {companyName !== "Co2xLite Demo" ? role === 'driver' ? "" : (
                        <Dropdown.Item
                          style={{
                            background:
                              theme === "dark"
                                ? Colors.lightBlue
                                : Colors[theme].secondaryColor,
                            color: "#fff",
                          }}
                          onClick={() => {
                            handleDropdownClick("/admin_panel");
                          }}
                        >
                          Admin Panel
                        </Dropdown.Item>
                      ) : null}
                      {companyName !== "Co2xLite Demo" ? role === 'driver' ? "" : (
                        <Dropdown.Item
                          style={{
                            background:
                              theme === "dark"
                                ? Colors.lightBlue
                                : Colors[theme].secondaryColor,
                            color: "#fff",
                          }}
                          onClick={() => {
                            handleDropdownClick("/logs");
                            toggleDrawer();
                          }}
                        >
                          Activity Logs
                        </Dropdown.Item>
                      ) : null}
                      <Dropdown.Item
                        style={{
                          background:
                            theme === "dark"
                              ? Colors.lightBlue
                              : Colors[theme].secondaryColor,
                          color: "#fff",
                        }}
                        onClick={() => {
                          toggleDrawer();
                          window.open(
                            "https://zfrmz.com/UDrFovbc4R4qxaZsLGKV",
                            "_blank"
                          );
                        }}
                      >
                        {/* <Link
                          to="/customer_support"
                          style={{ textDecoration: "none", color: "white" }}
                        > */}
                        Customer Support
                        {/* </Link> */}
                      </Dropdown.Item>
                      {/* Add more Dropdown.Items as needed */}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </ListItemIcon>
            </ListItem>
          ))}
        </div>
      </List>
      <Divider />
      <List>
        {role === "driver"
          ? ["Home", "History"].map((text, index) => (
              <ListItem
                button
                key={text}
                onClick={() =>
                  text === "Home"
                    ? (window.location.pathname = "/m_vehicles")
                    : text === "History"
                    ? (window.location.pathname = "/m_fuel_history")
                    : text === "History"
                }
              >
                <ListItemIcon style={{ color: Colors.Dark[3] }}>
                  {index === 0 ? (
                    <i className="fa fa-home" />
                  ) : index === 1 ? (
                    <i className="fas fa-history" />
                  ) : null}
                </ListItemIcon>
                <ListItemText primary={text} />
                <Divider />
              </ListItem>
            ))
          : companyId === "88883333"
          ? ["Home", "Tracking", "Track and Trace", "Assets"].map(
              (text, index) => (
                <ListItem
                  button
                  key={text}
                  onClick={() =>
                    text === "Home"
                      ? (window.location.pathname =
                          "/organisation-level-carbon-emission-dashboard")
                      : text === "Tracking"
                      ? (window.location.pathname = "/new-tracking")
                      : text === "Track and Trace"
                      ? (window.location.pathname = "/track_and_trace")
                      : text === "Assets"
                      ? (window.location.pathname = "/asset_profile_management")
                      : text === "Manual Entry"
                      ? (window.location.pathname = "/manual_entry")
                      : null
                  }
                >
                  <ListItemIcon style={{ color: Colors.Dark[3] }}>
                    {index === 0 ? (
                      <i className="fa fa-home" />
                    ) : index === 1 ? (
                      <i className="bi-speedometer2" />
                    ) : index === 2 ? (
                      <i className="fa fa-file-contract" />
                    ) : index === 3 ? (
                      <i className="bi bi-cpu" />
                    ) : null}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                  <Divider />
                </ListItem>
              )
            )
          : sidebarMenu.map(
              (value, index) =>
                value.access == 1 ? (
                  <Link
                    to={value.link}
                    key={value.id}
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <ListItem
                      key={value.id}
                      onClick={value.id==="Certificate" ? 
                        (!certificateView ?
                          (() => {
                            _handleViewCertificate();
                            toggleDrawer();
                          })
                          : toggleDrawer
                        )
                        : 
                        (certificateView ?
                          (() => {
                            _handleViewCertificate();
                            toggleDrawer();
                          })
                          : toggleDrawer
                        )
                      }
                      style={
                        window.location.pathname.includes(value.id) && !certificateView
                        ? selectedStyle
                        : (value.id === "Certificate" && certificateView?selectedStyle:defaultStyle)
                      }
                    >
                      <ListItemIcon style={{ color: "white" }}>
                        <i className={`${value.icon}`} />
                      </ListItemIcon>
                      <ListItemText primary={value.text} />
                    </ListItem>
                  </Link>
                ) : value.text.includes("Lite") ? null : (
                  <ListItem key={value.id}>
                    <ListItemIcon style={{ color: Colors.Dark[3] }}>
                      <i className={`${value.icon}`} />
                      <span
                        style={{
                          position: "relative",
                          top: -12,
                          right: 0,
                          fontSize: 12,
                          color: "#ffec67",
                        }}
                      >
                        <i className="bi bi-lock-fill"></i>
                      </span>
                    </ListItemIcon>
                    <ListItemText primary={value.text} />
                  </ListItem>
                )

              // (

              //   companyId === 28130482  &&

              //   <ListItem key={value.id}>
              //     <ListItemIcon style={{ color: Colors.Dark[3] }}>
              //       <i className={`${value.icon}`} />
              //       <span
              //         style={{
              //           position: "relative",
              //           top: -12,
              //           right: 0,
              //           fontSize: 12,
              //           color: "#ffec67",
              //         }}
              //       >
              //         <i className="bi bi-lock-fill"></i>
              //       </span>
              //     </ListItemIcon>
              //     <ListItemText primary={value.text} />
              //   </ListItem>
              // )
            )}
      </List>
    </Box>
  );

  return (
    <React.Fragment key="left">
      <Button
        // onClick={toggleDrawer("left", true)}
        onClick={toggleDrawer}
      >
        {isDrawerOpen === false ? (
          <i
            className={`bi bi-list ${theme === "dark" && "text-light"}`}
            style={{ fontSize: "1.2rem" }}
          ></i>
        ) : (
          <i
            className={`bi bi-x ${theme === "dark" && "text-light"}`}
            style={{ fontSize: "1.2rem" }}
          ></i>
        )}
      </Button>
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        // onClose={toggleDrawer("left", false)}
        onClose={toggleDrawer}
      >
        {list("left")}
        <div
          style={{
            bottom: 0,
            cursor: "pointer",
            width: 250,
            background:
              theme === "dark"
                ? Colors[theme].background
                : Colors[theme].primaryColor,
            color: Colors.Dark[4],
          }}
          onClick={() => handleLogout((window.location.pathname = "/"))}
        >
          <List>
            <ListItem>
              <ListItemIcon style={{ color: Colors.Dark[3] }}>
                {" "}
                <i className="fas fa-sign-out-alt" />
              </ListItemIcon>
              <ListItemText primary={"Log out"} />
            </ListItem>
          </List>
        </div>
      </Drawer>
    </React.Fragment>
  );
}
{
  /* (["Home", "Tracking", "Track and Trace", "Assets", "Manual Entry"].map((text, index) => (
  <ListItem
    button
    key={text}
    onClick={() =>
      text === "Home"
        ? (window.location.pathname = "/organisation-level-carbon-emission-dashboard")
        : text === "Tracking"
          ? (window.location.pathname = "/tracking")
          : text === "Track and Trace"
            ? (window.location.pathname = "/track_and_trace")
            : text === "Assets"
              ? (window.location.pathname = "/asset_profile_management")
              : text === "Manual Entry"
                ? (window.location.pathname = "/manual_entry")
                : null
    }
  >

    <ListItemIcon style={{ color: Colors.Dark[3] }}>
      {index === 0 ? (
        <i className="fa fa-home" />
      ) : index === 1 ? (
        <i className="bi-speedometer2" />
      ) : index === 2 ? (
        <i className="fa fa-file-contract" />
      ) : index === 3 ? (
        <i className="bi bi-cpu" />
      ) : index === 4 ? (
        <i className="fa fa-keyboard" />
      ) : null
      }
    </ListItemIcon>
    <ListItemText primary={text} />
    <Divider />
  </ListItem>
))
) */
}
