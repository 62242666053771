import { inject, observer } from "mobx-react";
import React, { useState, useRef, useEffect } from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { Alert } from "react-bootstrap";
import { My_DatePicker } from "../../Tools";
import { Cookies } from "react-cookie";
import { My_Button } from "../../Tools";
import { useTranslation } from "react-i18next";

export const UploadCSVFileModal = inject("store")(
  observer((props) => {
    const { t } = useTranslation("data_import");
    const { fileName, theme, showTable } = props;
    const { role } = props.store.common;
    const {
      handleImportVTripCSV,
      handleImportVProfilesCSV,
      handleImportVEngineHourCSV,
      handleImportManualEntyDataCSV,
      handleFileUploadCSV,
      handleCloseModel,
      showconfirmsubmitmodal,
      companyName,
      setRefFileObj,
      // refFileObj,
    } = props.store.dataImport;

    const fileInputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState("Upload CSV file");
    const [showAlert, setShowAlert] = useState(false);
    const [showRepoFileAlert, setShowRepoFileAlert] = useState(false);

    const [isSubmitted, setIsSubmitted] = useState(false);

    // const cookies = new Cookies();
    // const companyName = cookies.get("companyName");

    // const handleFileChange = (e) => {
    //     setSelectedFile(e.target.files[0]);
    // };

    // const handleSubmit = () => {
    //     if (selectedFile) {
    //         handleFileUploadCSV();
    //     } else {
    //         setShowAlert(true);
    //         // Handle validation error or display a message to the user
    //     }
    // };
    const [refFile, setRefFile] = useState("Upload JPEG, PNG, or PDF file");

    // const handleFileChange = (e) => {
    //   setSelectedFile(e.target.files[0]);
    // };

    const handleFileChange = (e) => {
      console.log("click");
      const file = e.target.files[0];
      if (file) {
        setSelectedFile(file.name);
      } else {
        setSelectedFile("Upload CSV file");
      }
    };

    const handleAlertClose = () => {
      setShowAlert(false);
    };

    const handleRepoAlertClose = () => {
      setShowRepoFileAlert(false);
    };

    const handleRefFileChange = (e) => {
      const file = e.target.files[0];
      if (file) {
        setRefFileObj(file);
        setRefFile(file.name);
      } else {
        setRefFile("Upload JPEG, PNG, or PDF file");
      }
    };
    const refFileInputRef = useRef(null);

    const [fileDate, setFileDate] = useState("");

    const [modalVisible, setModalVisible] = useState(false);
    const [check, setCheck] = useState(false);

    const handleSubmit = () => {
      if (selectedFile === "Upload CSV file") {
        setShowAlert(true);
      } else {
        setShowAlert(false);
        handleFileUploadCSV();
        setCheck(false);
      }

      setRefFile("Upload JPEG, PNG, or PDF file");
      setSelectedFile("Upload CSV file");
      setRefFileObj("");
    };
    const handleManualEntrySubmit = () => {
      if (selectedFile === "Upload CSV file") {
        setShowAlert(true);
        return;
      }
      if (refFile === "Upload JPEG, PNG, or PDF file") {
        setShowRepoFileAlert(true);
        return;
      }

      handleFileUploadCSV();
      setShowAlert(false);
      setShowRepoFileAlert(false);
      setCheck(false);
      setRefFile("Upload JPEG, PNG, or PDF file");
      setSelectedFile("Upload CSV file");
      setRefFileObj("");
    };

    const handleCheckChange = () => {
      setCheck((prevCheck) => !prevCheck);
    };

    const handleModalClose = () => {
      setRefFile("Upload JPEG, PNG, or PDF file");
      setSelectedFile("Upload CSV file");
      setShowAlert(false);
      setShowRepoFileAlert(false);
      setIsSubmitted(false);
      setFileDate("");
      handleCloseModel();
      setCheck(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      if (refFileInputRef.current) {
        refFileInputRef.current.value = "";
      }
    };

    const [showModal, setShowModal] = useState(true);

    useEffect(() => {
      setShowModal(showconfirmsubmitmodal);
    }, [showconfirmsubmitmodal]);

    const chooseFileStyle = {
      fontSize: "16px ",
      width: "150px",
      height: "35px ",
      borderTopLeftRadius: "5px",
      borderBottomLeftRadius: "5px",
      border: theme === "dark" ? "1px solid white" : "1px solid black",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: theme === "dark" ? "black" : "#303030",
      background: theme === "dark" ? "#fff" : "#c6c6c6",
    };
    const fileStyle = {
      fontSize: "16px ",
      width: "100%",
      height: "35px",
      borderBottomRightRadius: "5px",
      borderTopRightRadius: "5px",
      border: theme === "dark" ? "1px solid white" : "1px solid black",
      display: "flex ",
      alignItems: "center ",
      color: theme === "dark" ? "#c6c6c6" : "rgba(0,0,0,0.7)",
      // background: "#fff",
    };
    return (
      <>
        {/* {modalVisible && ( */}
        <div
          className="modal fade "
          // className={`modal fade ${showModal ? 'modal' : ''}`}
          id="uploadCSVFileModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          // tabindex="-1"
          aria-labelledby="uploadCSVFileModal"
          aria-hidden="true"
          // style={{maxWidth:"600px"}}
        >
          <div
            className="modal-dialog modal-md rounded"
            style={{
              backgroundColor: theme === "dark" ? "rgb(32, 43, 96)" : "#fff",
              borderRadius: 5,
            }}
          >
            <div
              className="modal-content "
              style={{
                backgroundColor: theme === "dark" ? "rgb(32, 43, 96)" : "#fff",
                borderRadius: 5,
                // width:'800px'
              }}
            >
              <div
                className="modal-header"
                style={{ borderBottom: 0, borderBottomColor: "1px solid red" }}
              >
                <h1
                  className="modal-title fs-5"
                  id="uploadCSVFileModal"
                  style={{ color: theme === "dark" ? "#fff" : "#000" }}
                >
                  {t("Upload file for")}{" "}
                  {showTable === "table1" &&
                  (role === "super_admin" || companyName === "Volvo")
                    ? t("Unit Profile")
                    : showTable === "table2" &&
                      (role === "super_admin" || companyName === "Volvo")
                    ? t("Trips")
                    : showTable === "table3" &&
                      (role === "super_admin" || companyName === "Volvo")
                    ? t("Engine Hours")
                    : t("Manual Entry")}
                </h1>
              </div>
              <div className="modal-body">
                {showAlert && (
                  <Alert
                    variant="secondary"
                    onClose={handleAlertClose}
                    dismissible
                  >
                    {t("Please choose a CSV file.")}
                  </Alert>
                )}
                {showRepoFileAlert && (
                  <Alert
                    variant="secondary"
                    onClose={handleRepoAlertClose}
                    dismissible
                  >
                    {t("Please choose Repository File.")}
                  </Alert>
                )}
                <label
                  className="mb-1 mt-0"
                  style={{ color: invertDark(theme) }}
                >
                  <span style={{ color: "#ffc107" }}>*</span>{" "}
                  <strong>{t("CSV File Upload")}</strong>
                </label>
                <div
                  className="mb-3"
                  style={{
                    background: theme === "dark" && Colors.darkBlue,
                    color: invertDark(theme),
                    cursor: "pointer",
                    width: "100%",
                    // padding: "10px",
                    borderRadius: "5px",
                  }}
                  onClick={() =>
                    fileInputRef.current && fileInputRef.current.click()
                  }
                >
                  <div
                    className="btn-group btn-group-sm me-2 w-100"
                    role="group"
                  >
                    <div type="button" className="btn" style={chooseFileStyle}>
                      {t("Choose File")}
                    </div>
                    <div type="button" className="btn" style={fileStyle}>
                      {selectedFile}
                    </div>
                  </div>
                  <input
                    ref={fileInputRef}
                    aria-describedby="basic-addon2"
                    className="form-control"
                    accept={".csv"}
                    type="file"
                    onChange={(e) => {
                      handleFileChange(e);

                      showTable === "table1"
                        ? handleImportVProfilesCSV(e)
                        : showTable === "table2"
                        ? handleImportVTripCSV(e)
                        : showTable === "table3"
                        ? handleImportVEngineHourCSV(e)
                        : handleImportManualEntyDataCSV(e);
                    }}
                    style={{ display: "none" }}
                  />
                </div>
                {(showTable === "table1" && role==='super_admin') ||
                (showTable === "table2" && role==='super_admin') ||
                (showTable === "table3"&& role==='super_admin') ? (
                  <div
                    className="d-flex align-items-center justify-content-start mb-1 "
                    style={{
                      background: theme === "dark" && Colors.darkBlue,
                      color: invertDark(theme),
                      // padding: "5px"
                    }}
                  >
                    <input
                      checked={check}
                      onChange={(e) => handleCheckChange(e)}
                      className="me-1"
                      type="checkbox"
                      style={{
                        width: "20px",
                        height: "18px",
                        accentColor: "#4FAE32",
                      }}
                    />
                    <span className="justify-content-center p-0">
                      <strong>{t("Repository File Upload")}</strong>
                    </span>
                  </div>
                ) : (
                  <>
                    <label
                      className="mb-1 "
                      style={{
                        color: invertDark(theme),
                      }}
                    >
                      <span style={{ color: "#ffc107" }}>*</span>{" "}
                      <strong>
                        {t("Upload Documentation for Evidential Proof")}
                      </strong>
                    </label>
                    <div
                      style={{
                        background: theme === "dark" && Colors.darkBlue,
                        color: invertDark(theme),
                        cursor: "pointer",
                        width: "100%",
                        // padding: "10px",
                        borderRadius: "5px",
                      }}
                      onClick={() =>
                        refFileInputRef.current &&
                        refFileInputRef.current.click()
                      }
                    >
                      <div
                        className="btn-group btn-group-sm me-2 w-100"
                        role="group"
                      >
                        <div
                          type="button"
                          className="btn"
                          style={chooseFileStyle}
                        >
                          {t("Choose File")}
                        </div>
                        <div type="button" className="btn" style={fileStyle}>
                          {refFile}
                        </div>
                      </div>
                      <input
                        ref={refFileInputRef}
                        aria-describedby="basic-addon2"
                        className="form-control"
                        accept=".png,.pdf,.jpg,.JPEG"
                        type="file"
                        onChange={handleRefFileChange}
                        style={{ display: "none" }}
                      />
                    </div>
                  </>
                )}
                {check && (
                  <>
                    {" "}
                    <label
                      className="mb-1 mt-1"
                      style={{
                        color: invertDark(theme),
                      }}
                    >
                      <span style={{ color: "#ffc107" }}>*</span>{" "}
                      <strong>
                        {t("Upload Documentation for Evidential Proof")}
                      </strong>
                    </label>
                    {/* <div
                        className="input-group mt-0"
                        style={{ cursor: "pointer", width: "100%" }}
                      >
                        <input
                          ref={refFileInputRef}
                          aria-describedby="basic-addon2"
                          className="form-control"
                          accept=".png,.pdf,.jpg,.JPEG"
                          type="file"
                          onChange={(e) => handleRefFileChange(e)}
                          style={{
                            background: theme === "dark" && Colors.darkBlue,
                            color: invertDark(theme),
                          }}
                        ></input>
                      </div> */}
                    <div
                      style={{
                        background: theme === "dark" && Colors.darkBlue,
                        color: invertDark(theme),
                        cursor: "pointer",
                        width: "100%",
                        // padding: "10px",
                        borderRadius: "5px",
                      }}
                      onClick={() =>
                        refFileInputRef.current &&
                        refFileInputRef.current.click()
                      }
                    >
                      <div
                        className="btn-group btn-group-sm me-2 w-100"
                        role="group"
                      >
                        <div
                          type="button"
                          className="btn"
                          style={chooseFileStyle}
                        >
                          {t("Choose File")}
                        </div>
                        <div type="button" className="btn" style={fileStyle}>
                          {refFile}
                        </div>
                      </div>
                      <input
                        ref={refFileInputRef}
                        aria-describedby="basic-addon2"
                        className="form-control"
                        accept=".png,.pdf,.jpg,.JPEG"
                        type="file"
                        onChange={handleRefFileChange}
                        style={{ display: "none" }}
                      />
                    </div>
                  </>
                )}

                {/* {showTable === "table1" && (role === "super_admin" || companyName === "Volvo") ? (
                <div className="mt-4">
                  <span
                    className=""
                    style={{ color: theme === "dark" ? "#fff" : "#000" }}
                  >
                    Vehicle started using date(optional)
                  </span>
                  <div className="mt-2">
                    <My_DatePicker
                      id={"date"}
                      height={"43px"}
                      selected={fileDate}
                      // handleFileCaptureDate={handleImportVProfilesCSV}
                      onChange={(e) => {
                        handleFileCaptureDate(e);
                        setFileDate(uploadInfo.fileimportDate);
                      }}
                      dateFormat="yyyy-MM-dd"
                    />
                  </div>
                </div>
              ) : null} */}
              </div>
              <div className="modal-footer" style={{ borderTop: 0 }}>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleModalClose}
                >
                  {t("Close")}
                </button>
                {(showTable === "table1" && role==='super_admin') ||
                (showTable === "table2" && role==='super_admin') ||
                (showTable === "table3"&& role==='super_admin')  ? (
                  <button
                    type="button"
                    className={`${
                      theme === "dark" ? "btn btn-primary" : "btn btn-success"
                    }`}
                    id="submitModal"
                    data-bs-dismiss={
                      selectedFile === "Upload CSV file" ? null : "modal"
                    }
                    data-bs-target="#uploadCSVFileModal"
                    onClick={handleSubmit}
                  >
                    {" "}
                    {t("Submit")}
                  </button>
                ) : (
                  <button
                    type="button"
                    className={`${
                      theme === "dark" ? "btn btn-primary" : "btn btn-success"
                    }`}
                    id="submitModal"
                    data-bs-dismiss={
                      refFile === "Upload JPEG, PNG, or PDF file"
                        ? null
                        : "modal"
                    }
                    data-bs-target="#uploadCSVFileModal"
                    onClick={handleManualEntrySubmit}
                  >
                    {" "}
                    {t("Submit")}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* )} */}
      </>
    );
  })
);
