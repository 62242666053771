
import React from "react";
import { inject, observer } from "mobx-react";
import Cookies from "universal-cookie";

import CompanyList from "../../Components/Landing/companyList";
import { withRouter } from "react-router-dom";
import { ISPOTcomponent } from "../../Components/Landing/iSPOTcomponent";
import { StacsComponent } from "../../Components/Landing/StacsComponent";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import Store from "../../Store";
import jwt_decode from "jwt-decode";
import i18n from "i18next";
import { Translation, useTranslation } from 'react-i18next';
import { width } from "@mui/system";
import { useState, useEffect } from 'react';
import { useMediaPredicate } from "react-media-hook";
class LandingContainer extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }


    cookies = new Cookies();
    key = this.cookies.get("key");
    token = this.cookies.get("accessToken")
    _handleClick = this.props.store.landing._handleClick;
    logo =
        this.token && jwt_decode(this.token).logo;
    role = Store.common
    companyId = Store.common
    showStacs = Store.common
    landingAscent = Store.common.landingAscent


    componentDidMount() {
        this.role?.role === 'super_admin' && this.props.store.landing.fetchCompany(this.role?.role, this.companyId?.companyId);
        if (this.token) {
            this.props.store.login.auth = true;
        }
    }



    render() {
        const t = this.props.t.t;
        console.log("landingAscent", this.landingAscent?.enableAscent);
        return (
            <>
            
                <p className="welcome  text-center mx-auto  pt-5 pb-3 fw-bold" style={{ color: invertDark(this.props.theme),fontSize:"2rem" }}>
                    {t("Welcome!")}
                    {/* <br /><span className="appselect" style={{ color: this.props.theme === 'dark' ? Colors.Dark[4] : Colors.Dark[2] }}> Please Select an application to start</span> */}
                </p>

                <div className="d-flex justify-content-center">
                    <AppSelect
                        _handleClick={this._handleClick}
                        theme={this.props.theme}
                        logo={this.logo}
                        landingAscent={this.landingAscent?.enableAscent}
                        companyName={this.companyName}
                        showStacs={this.showStacs.showStacs}
                    />
                </div>
            </>
        );
    }
}


const AppSelect = (props) => {
    const { _handleClick,
        theme,
        landingAscent,
        companyName,
        showStacs
    } = props;
 

    const isMobile = useMediaPredicate("(min-width: 250px) and (max-width: 500px)");
    const isTablet = useMediaPredicate("(min-width: 500px) and (max-width: 1000px)");
    const isDestop = useMediaPredicate("(min-width: 1000px) and (max-width: 2000px)");
    const surfaceDuo = useMediaPredicate("(min-width : 500 px) and (max-width : 600px)");

    return (

        <div className={`col ${isMobile ? "" : "col-12"} ${isTablet ? "" : ""}`}>

            {
                isMobile || isTablet ?
                    <>

                        {landingAscent === 1 ?
                            <div className="ismobile d-flex align-content-center row col-12 mb-5">

                                <div className=""
                                    style={{ marginBottom: '1em' }}>
                                    <div className="ispotmobile" style={{ backgroundColor: '' }} >
                                        <ISPOTcomponent theme={props.theme} />
                                    </div>
                                </div>


                                <div className="companylistmobile" style={{}}>
                                    <div className="" style={{ marginLeft: '', backgroundColor: '' }}>
                                        <CompanyList _handleClick={_handleClick} theme={props.theme} logo={props.logo} />
                                    </div>
                                </div>

                            </div>
                            :

                            <div className="d-flex justify-content-center col-12">
                                <div className="clist col-12" style={{ backgroundColor: '' }}>
                                    <CompanyList _handleClick={_handleClick} theme={props.theme} logo={props.logo} />
                                </div>
                            </div>

                        }

                    </>
                    :
                    <>

                        {
                            landingAscent === 1 ?

                                <div className="landingdesktop d-flex justify-content-center">
                                    <CompanyList className="companylistdesktop" _handleClick={_handleClick} theme={props.theme} logo={props.logo} />
                                    <div className="landingdesktop d-flex flex-column gap-2" style={{ width: '350px'}}>
                                        <ISPOTcomponent className="ispotdesktop" theme={props.theme} />
                                        {showStacs == 1 ? <StacsComponent className="ispotdesktop" theme={props.theme} /> : ""}
                                    </div>
                                    {/* <ISPOTcomponent className="ispotdesktop" theme={props.theme} /> */}
                                </div>
                                :

                                <div className="d-flex justify-content-center col-12">
                                    <div className="clistonly col-12" style={{}}>
                                        <CompanyList className="col-12" _handleClick={_handleClick} theme={props.theme} logo={props.logo} />
                                    </div>
                                </div>

                        }

                    </>
            }

        </div>
    )
}
export default inject("store")(withRouter(observer(LandingContainer)));







