import React, { useEffect } from "react";
import UserLogin from "../../Components/Login/loginComponent";
import { CookiesProvider } from "react-cookie";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router";

const LoginContainer = (props) => {
  const errStyle = {
    color: "red",
    fontSize: 12,
    marginTop: "-20px",
  };

  useEffect(() => {
    document.getElementById("email").focus();
  }, []);

  return (
    <CookiesProvider>
      <UserLogin errStyle={errStyle} />
    </CookiesProvider>
  );
};

export default withRouter(LoginContainer);
