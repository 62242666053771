import { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { AddNewUser } from "./AddNewUser";
import { EditUser } from "./EditUser";
import { UserListGridView } from "./UserListGridView";
import { UserListTable } from "./userListTable";
import { AssignVehiclesModal } from "./AssignVehiclesModal";
import { AssignVehiclesCompanyModal } from "./AssignVehicleCompanyModal";
export const UserList = inject("store")(
    observer((props) => {
        const { editUserId, addNewUser, addNewVehicle } = props.store.adminPanel;
        const data =props.store.adminPanel.userList;


        return (
            <>
                {/* <UserListTable                              //***dont Delete!!!
      companyList={props.companyList}
      theme={props.theme}
      cardStyle={props.cardStyle}
      header={[
        { id: 'username', label: 'User Name' },
        { id: 'email', label: 'Email' },
        { id: 'companyName', label: 'Company' },
        { id: 'role', label: 'Role' },
        { id: 'edit', label: '' }
      ]}AddVehicleModal
      _handleTextChange={props._handleTextChange}
    /> */}
                <div className="row py-3" style={{ overflow: 'hidden' }}>

                    <div className="col p-0">
                    <UserListGridView editUserId={editUserId} theme={props.theme} />
                    </div>
                    {
                        addNewUser ? <div className=" col-xl-4 col-lg-5 col-md-6 col-sm-6 m-0 p-0 " style={{ background: props.theme === 'dark' ? "#0000007a" : "rgb(0,0,0,0.1)", borderLeft: '1px solid rgb(255,255,255,0.1)', boxShadow: 'rgb(0,0,0,0.03) 5px 0 10px 6px inset', transition: 'all 0.5s' }}>
                            <AddNewUser theme={props.theme} />
                        </div> :
                            editUserId ? <div className=" col-xl-4 col-lg-5 col-md-6 col-sm-6 m-0 p-0" style={{ background: props.theme === 'dark' ? "#0000007a" : "rgb(0,0,0,0.1)", borderLeft: '1px solid rgb(255,255,255,0.1)', boxShadow: 'rgb(0,0,0,0.03) 5px 0 10px 6px inset', transition: 'all 0.5s' }}>
                                <EditUser theme={props.theme} />
                            </div> :
                            addNewVehicle ? <div className=" col-xl-4 col-lg-5 col-md-6 col-sm-6 m-0 p-0" style={{ background: props.theme === 'dark' ? "#0000007a" : "rgb(0,0,0,0.1)", borderLeft: '1px solid rgb(255,255,255,0.1)', boxShadow: 'rgb(0,0,0,0.03) 5px 0 10px 6px inset', transition: 'all 0.5s'}}>
                            <AssignVehiclesModal theme={props.theme} user={data} />
                           </div> :
                             <div className="p-0" style={{ width: 0, transition: 'all 0.5s', borderTopRightRadius: 10, borderBottomRightRadius: 10 }}>
                                
                            </div>}

                </div>
            </>
        );
    }));
