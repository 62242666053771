import { Colors } from "chart.js";
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { invertDark } from "../../Assets/Config/color.config";
import AddUser from "../../Assets/Images/icons/addUser.png";
import { My_Button, My_Input, Selector } from "../../Tools";
import { PasswordValidationModal } from "./PasswordValidationModal";
import { useTranslation } from "react-i18next";

export const AddNewUser = inject("store")(
  observer((props) => {
    const { t } = useTranslation("admin-panel");
    const {
      _handleInputChange,
      companies,
      userData,
      _handleCreateUser,
      getUserPlan,
      userPlanOptions,
      setUserPlanId,
      userPlanId,
      _setAddNewUser,
      _fetchCompanyList,
    } = props.store.adminPanel;

    const { theme } = props;
    const { role, companyId } = props.store.common;
    const [visible, setVisible] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
      getUserPlan();
      _fetchCompanyList(role, companyId);
    }, []);

    const [selectedCompany, setSelectedCompany] = useState(
      companies.find((v) => v.id === props.store.adminPanel.companyId)?.id
    );

    const [checked, setChecked] = useState(true);

    const CompanyOptions = companies.map((v) => ({
      value: v?.id,
      label: v?.name,
    }));

    return (
      <div style={{ color: invertDark(theme) }}>
        <div
          className="d-flex justify-content-between align-items-center"
          style={{
            backgroundColor:  theme === 'dark' ? "#76A7DC" : '#198754',
            fontWeight: "bold",
            borderTopRightRadius: 10,
          }}
        >
          <span
            style={{ fontSize: 20, color: theme === 'dark' ? "#222B5D" : '#fff'}}
            className="px-2 d-flex align-items-center"
          >
            <i className="bi bi-person-plus-fill pe-2"></i>
            <span style={{ fontWeight: "normal" }}>{t("Add New User")}</span>
          </span>
          <span
            style={{ cursor: "pointer" }}
            className=""
            onClick={() => _setAddNewUser(null)}
          >
            <i className={`${theme === 'dark' ? 'text-dark bi bi-x fa-2x' : 'text-white bi bi-x fa-2x'}`} />
          </span>
        </div>
        <div className="container py-3">
          <div style={{ fontSize: 18, fontWeight: "bold" }} className="pb-3">
            {t("User Info")}
          </div>
          <div className="row pb-2">
            <span className="col-3">{t("Company")}</span>
            <span className="col-1">:</span>
            <span className="col">
              <Selector
                options={CompanyOptions}
                menuListColor="black"
                value={CompanyOptions.find((v) => v.value === selectedCompany)}
                _handleSelect={(e) => {
                  _handleInputChange({ companyId: e.value });
                  setSelectedCompany("");
                  setSelectedCompany(e.value);
                }}
              />
            </span>
          </div>
          <div className="row pb-2">
            <span className="col-3">{t("Name")}</span>
            <span className="col-1">:</span>
            <span className="col">
              <My_Input
                id="username"
                onChange={(e) =>
                  _handleInputChange({ username: e.target.value })
                }
                value={userData.username}
                height="38px"
              ></My_Input>
            </span>
          </div>
          <div className="row pb-2">
            <span className="col-3">{t("Email")}</span>
            <span className="col-1">:</span>

            <span className="col">
              <My_Input
                id="email"
                onChange={(e) => _handleInputChange({ email: e.target.value })}
                value={userData.email}
                height="38px"
              ></My_Input>
            </span>
          </div>
          {/* <div className="row pb-2">
            <span className="col-3">{t("Password")}</span>
            <span className="col-1">:</span>
            <span className="col-8">
              <My_Input
                id="password"
                type={props.store.login.visible ? "text" : "password"}
                onChange={(e) =>
                  _handleInputChange({ password: e.target.value })
                }
                value={userData.password}
                height="38px"
              ></My_Input>
              <span
                style={{
                  float: "right",
                  position: "relative",
                  marginTop: "-52px",
                  marginBottom: "-10px",
                  fontSize: "18px",
                  marginRight: "8px",
                  cursor: "pointer",
                }}
                onClick={() => props.store.login._handleView()}
              >
                <i
                  className={`fa fa-eye${
                    props.store.login.visible ? "-slash" : ""
                  } py-4 text-secondary`}
                />
              </span>
            </span>

            <span className="col-4" /><div className="col text-end" style={{ fontSize: 12, color: pswValidationText === 'valid' ? '#00ff00' : 'yellow' }}>{pswValidationText === 'valid' ? <i className="bi bi-check2" /> : pswValidationText}</div>
          </div> */}
          {/* <div className="row pb-4">
            <span className="col-3">{t("Confirm Password")}</span>
            <span className="col-1">:</span>
            <span className="col-8">
              <My_Input
                id="password"
                type={props.store.login.visible ? "text" : "password"}
                onChange={(e) =>
                  _handleInputChange({ confirmPassword: e.target.value })
                }
                value={userData.confirmPassword}
                height="38px"
              ></My_Input>
              <span
                style={{
                  float: "right",
                  position: "relative",
                  marginTop: "-52px",
                  marginBottom: "-10px",
                  fontSize: "18px",
                  marginRight: "8px",
                  cursor: "pointer",
                }}
                onClick={() => props.store.login._handleView()}
              >
                <i
                  className={`fa fa-eye${
                    props.store.login.visible ? "-slash" : ""
                  } py-4 text-secondary`}
                />
              </span>
            </span>

            <span className="col-4" /><div className="col text-end" style={{ fontSize: 12, color: pswMatchValidation === 'valid' ? '#00ff00' : 'yellow' }}>{pswMatchValidation === 'valid' ? <i className="bi bi-check2" /> : pswMatchValidation }</div>
          </div> */}

          {/* {showAlert && (
                        <div className="alert alert-danger" role="alert">
                            Password must have at least 8 characters, including alphabet, number, and special characters.
                        </div>
                    )} */}
          <div className="row pb-2">
            <span className="col-3">{t("Role")}</span>
            <span className="col-1">:</span>
            <span className="col">
              <label className="pe-3" htmlFor="admin">
                <input
                  className="me-1"
                  id="admin"
                  type="radio"
                  onClick={(e) => {
                    _handleInputChange({ role: e.target.value });
                    setChecked(!checked);
                  }}
                  value="admin"
                  name="role"
                  checked={checked ? "checked" : ""}
                  // value={userData.role}
                  height="38px"
                ></input>
                {t("Admin")}
              </label>
              <label className="pe-3" htmlFor="user">
                <input
                  className="me-1"
                  id="user"
                  type="radio"
                  onClick={(e) => {
                    _handleInputChange({ role: e.target.value });
                    setChecked(false);
                  }}
                  value="user"
                  name="role"
                  // value={userData.role}
                  height="38px"
                ></input>
                {t("User")}
              </label>
              {role === 'super_admin' &&
              <label className="pe-3" htmlFor="operator">
                <input
                  className="me-1"
                  id="operator"
                  type="radio"
                  onClick={(e) => {
                    _handleInputChange({ role: e.target.value });
                    setChecked(false);
                  }}
                  value="operator"
                  name="role"
                  // value={userData.role}
                  height="38px"
                ></input>
                {t("Ops Admin")}
              </label>}
              <label htmlFor="driver">
                <input
                  className="me-1"
                  id="driver"
                  type="radio"
                  onClick={(e) => {
                    _handleInputChange({ role: e.target.value });
                    setChecked(false);
                  }}
                  value="driver"
                  name="role"
                  height="38px"
                ></input>
                {t("Driver")}
              </label>
            </span>
          </div>
          <My_Button
            customColor={theme === "dark" ? "#4c5d75" : '#198754'}
            customHoverColor={theme === "dark" ? "#70839e" : '#1dad6b'}
            onClick={() => {
              _handleCreateUser();
              // setShowAlert(true);
            }}
            className="w-100 my-3"
            text={t("Create")}
          />

          {/* <PasswordValidationModal theme={theme} showModal={showModal} setShowModal={setShowModal} /> */}
        </div>
      </div>
    );
  })
);
