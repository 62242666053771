import jwtDecode from "jwt-decode";
import { observable, action, makeObservable, runInAction, toJS } from "mobx";
import { Row } from "react-bootstrap";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import {
  getCompanyList,
  CreateNewUser,
  deleteUser,
  CreateNewCompany,
  getAllUserList,
  updateUser,
  updateCompany,
  deleteCompany,
  getCompanyListWithChildCompanies,
} from "../network/fetchLanding";
import Common from "./common";
import dataImport from "./dataImport";
import { tickStep } from "d3";

class Company {
  cookies = new Cookies();
  companyId = this.cookies.get("company");
  token = this.cookies.get("accessToken");
  level = this.cookies.get("level");
  companyList = undefined;
  companyListWithChildCompanies = [];
  isHover = false;
  hoverId = null;
  planData = this.token && jwtDecode(this.token).planData;
  step = 1;
  username = "";
  userData = {};
  userInfo = [];
  companyInfo = [];
  userList = [];
  selectedCompany = "";
  selectedCountry = "";
  editView = false;
  userId = "";
  companyId = "";
  dataImportAccess = "";

  constructor() {
    makeObservable(this, {
      companyList: observable,
      companyId: observable,
      token: observable,
      isHover: observable,
      hoverId: observable,
      step: observable,
      userData: observable,
      username: observable,
      userInfo: observable,
      companyInfo: observable,
      userList: observable,
      selectedCompany: observable,
      editView: observable,
      userId: observable,
      selectedCountry: observable,
      companyListWithChildCompanies: observable,
      dataImportAccess: observable,

      fetchCompany: action.bound,
      fetchUserList: action.bound,
      _handleInputChange: action.bound,
      _handleMouseHover: action.bound,
      setStep: action.bound,
      setSelectedCountry: action.bound,
      _handleUserRegister: action.bound,
      _handleCompanyRegister: action.bound,
      _handleDeleteUser: action.bound,
      _handleEditUser: action.bound,
      _handleUpdateUser: action.bound,
      _handleUpdateCompany: action.bound,
      _handleEditCompany: action.bound,
      fetchCompanyWithChildCompanies: action.bound,
    });
  }

  _handleUserRegister = (e) => {
    this.userInfo = this.userData;

    let data = {
      username: this.userInfo.username,
      password: this.userInfo.password,
      email: this.userInfo.email,
      role: this.userInfo.role,
      name: this.userInfo.name,
      companyId: this.userInfo.companyId,
    };

    CreateNewUser(this.token, data, (err, data) => {
      if (!err) {
        this.fetchUserList(Common.companyId);
      }
      if (data.success === true) {
        toast.success("User Created Successfully!");
      }
    });
  };

  _handleCompanyRegister = () => {
    this.companyInfo = this.userData;

    let data = {
      name: this.companyInfo.companyName,
      country: this.companyInfo.country,
      level: this.level,
    };

    CreateNewCompany(this.token, data, (err, data) => {
      if (!err) {
        this.fetchCompany(Common.role, Common.companyId);
      }
      if (data.success === true) {
        toast.success("Company Created Successfully");
      }
    });
  };

  _handleClick = (e, name, logo, data_import, company_type) => {
    const companyId = e.target.id;
    const companyName = name;

    let level = "";

    this.cookies.set("data_import_access", data_import);

    const loop = (arr) => {
      arr?.map((v) => {
        if (v.companyId === companyId) {
          level = v.level;
        }
        loop(v.childCompanies);
      });
    };
    loop(this.companyListWithChildCompanies);

    // const level = this.companyList.find(v => v.id === companyId).level
    // const level = this.companyListWithChildCompanies.find(v => v.companyId === companyId).level
    runInAction(() => {
      this.cookies.set("company", companyId);
      this.cookies.set("companyName", companyName);
      this.cookies.set("logo", logo);
      this.cookies.set("level", level);
      this.cookies.set("companyType", company_type);
    });

    window.location.pathname =
      this.planData?.enterprise_overview_access === 1
        ? "/organisation-level-carbon-emission-dashboard"
        : "/lite-dashboard";
  };

  _handleInputChange = (user) => {
    this.userData = { ...this.userData, ...user };
  };

  fetchCompany(role, companyId) {
    // role === 'super_admin' ? role = 'admin' : role = role
    getCompanyList(this.token, role, companyId, (err, data) => {
      if (!data?.message) {
        runInAction(() => {
          if (Common.userName === "co2x") {
            this.companyList = data.filter((value) => value.id === "24445513");
          } else {
            //this.companyList = data.filter((value) =>  value.id !=='7d021941-26cb-41a6-9b6a-e4ac6762e528');
            this.companyList = data;
          }
        });
      } else {
        if (data.message === "Token Expired!") window.location.pathname = "/";
        toast.error(data.message);
      }
    });
  }

  fetchCompanyWithChildCompanies(companyId) {
    // role === 'super_admin' ? role = 'admin' : role = role
    getCompanyListWithChildCompanies(this.token, companyId, (err, data) => {
      if (data) {
        runInAction(() => {
          if (Common.userName === "co2x") {
            this.companyListWithChildCompanies = data.filter(
              (value) => value.id === "24445513"
            );
          } else {
            //this.companyList = data.filter((value) =>  value.id !=='7d021941-26cb-41a6-9b6a-e4ac6762e528');

            this.companyListWithChildCompanies = data;
          }
        });
      } else {
        if (data?.message === "Token Expired!") window.location.pathname = "/";
        toast.error(data?.message);
      }
    });
  }

  fetchUserList(companyId) {
    getAllUserList(this.token, companyId, (err, data) => {
      if (!data?.message) {
        runInAction(() => {
          if (Common.userName === "co2x") {
            this.userList = data.filter((value) => value.id === "24445513");
          } else {
            //this.companyList = data.filter((value) =>  value.id !=='7d021941-26cb-41a6-9b6a-e4ac6762e528');
            this.userList = data;
          }
        });
      } else {
        if (data.message === "Token Expired!") window.location.pathname = "/";
        toast.error(data.message);
      }
    });
  }

  _handleUpdateUser(userId) {
    this.userInfo = this.userData;

    let data = {
      username: this.userInfo.username,
      email: this.userInfo.email,
      role: this.userInfo.role,
    };

    updateUser(this.token, userId, data, (err, data) => {
      if (!err) {
        this.fetchUserList(Common.companyId);
      }
    });

    this.editView = !this.editView;
  }

  _handleUpdateCompany(companyId) {
    this.companyInfo = this.userData;

    let data = {
      name: this.companyInfo.companyName,
      country: this.companyInfo.country,
      level: this.level,
    };

    updateCompany(this.token, companyId, data, (err, data) => {
      if (!err) {
        this.fetchCompany(Common.role, Common.companyId);
      }

      if (data.success === true) {
        toast.success("Company Info Updated Successfully");
      }
    });

    this.editView = !this.editView;
  }

  _handleDeleteUser(userId) {
    const companyId = Common.companyId;

    deleteUser(this.token, userId, (err, data) => {
      if (data.message === "User Deleted!") {
        this.fetchUserList(companyId);
        toast.success(data.message);
      }
    });
  }

  _handleDeleteCompany(companyId) {
    deleteCompany(Common.token, companyId, (err, data) => {
      if (data.success) {
        this.fetchCompany(Common.role, Common.companyId);
        toast.success(data.message);
      } else {
        console.log(err);
      }
    });
  }

  _handleEditUser(row) {
    const data = this.userList.find((v, i) => v.id === row.id);
    console.log("dataatt", toJS(data));

    runInAction(() => {
      this.userData = {
        username: data.username,
        email: data.email,
        password: data.password,
        role: data.role,
      };
      this.selectedCompany = data.name;
      this.editView = true;
      this.userId = data.id;
      console.log("USERDATA", toJS(this.userData));
    });
  }

  _handleEditCompany(row) {
    const data = this.companyList.find((v, i) => v.id === row.id);

    runInAction(() => {
      this.userData = {
        companyName: data.name,
        country: data.country,
      };

      this.setSelectedCountry(data.country);

      console.log("selected country", this.selectedCountry);
      this.editView = true;
      this.companyId = data.id;
    });
  }

  _handleMouseHover(k) {
    runInAction(() => (this.isHover = !this.isHover), (this.hoverId = k));
  }

  setStep(value) {
    this.step = value;
  }

  setSelectedCountry(value) {
    this.selectedCountry = value;
  }
}

export default new Company();
