/* eslint-disable jsx-a11y/img-redundant-alt */
import { fontSize } from "@mui/system";
import { inject, observer } from "mobx-react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import React, { useState, useEffect } from 'react';
import Swal from "sweetalert2";
import { toJS } from "mobx";
import Spinner from "../../Assets/Images/icons/spinner.gif";
import { baseIMGURL } from "../../network/URL";
import { Document, Page, pdfjs } from 'react-pdf';
import { useTranslation } from "react-i18next";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const ImageToTextModal = inject("store")(
    observer((props) => {
        const {t} = useTranslation("new-manuel-entry")
        const { theme } = props;
        const { canvasRef,newAudit,auditId,text, fileFuel, fileFuelName, fuelname, quantity, totalamount, pricePerL, invoiceNo, date, _handleImgViewModel, _handleShowAlert, showAlert,istimeout,isImgNotFound, manualRowIndex } = props.store.dataEntry;

        useEffect(() => {
            console.log('text', toJS(showAlert))


            if (showAlert && text) {
                
                Swal.fire({
                    icon: 'success',
                    timer: 2000,
                    text: 'Successfully completed!',
                    showConfirmButton: false
                }).then((result) => {
                    console.log("success",result);
                    // if (result.isConfirmed) {
                        // const ImgViewModal = document.getElementById("alert_close")
                        // if (!ImgViewModal) return
                        // ImgViewModal.setAttribute("data-bs-dismiss", "modal");
                        // let clickEvent = new MouseEvent("click", {
                        //     view: window,
                        //     bubbles: true,
                        //     cancelable: false,
                        // });
                        // ImgViewModal.dispatchEvent(clickEvent);
                        //_handleShowAlert(false)
                    // }
                })
            }

            else if (istimeout) {

                Swal.fire({
                    icon: 'error',
                    text: 'Try with better quality image',
                    showConfirmButton: true
                }).then((result) => {
                    console.log("timeout",result);
                })
            }

        }, [showAlert, text, istimeout]);

        console.log("manualrowidnex ==>>>", manualRowIndex)

        return (

            <div
                className="modal  fade"
                id="imageToText"
                tabIndex="-1"
                aria-labelledby="imageToText"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content d-flex align-items-center" style={{ background: Colors[theme].background, color: invertDark(theme) }}>
                        <div className="modal-header border-0 d-flex justify-content-between w-100">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {fileFuelName}
                            </h5>
                            <button
                                // id="alert_close"
                                type="button"
                                className={`btn-close ${theme === 'dark' && 'btn-close-white'}`}
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                // style={{ visibility: "hidden" }}
                            ></button>
                        </div>
                        <div className="modal-body" style={{ width: '100%' }}>
                            <div className="row">
                                <div className="col-7">
                                    <span style={{ fontSize: 21 }}><b>{t("Actual imagePath uploaded")}</b></span>
                                    <br />
                                    <br />

                                    {newAudit.find(v => v.id === manualRowIndex)?.uploadFileType !== 'application/pdf' ?
                                    <img src={newAudit.find((v,i) => i === manualRowIndex)?.fileFuel ? newAudit.find((v,i) => i === manualRowIndex)?.fileFuel: newAudit.find((v,i) => i === manualRowIndex)?.fuel_recordFilePath === "null" ? "" : baseIMGURL + newAudit.find((v,i) => i === manualRowIndex)?.fuel_recordFilePath} className="App-image" alt="logo" width={400} height={650} />
                                    :
                                    (<>
                                    <Document
                                        file={newAudit.find((v,i) => i === manualRowIndex)?.fileFuel ? newAudit.find((v,i) => i === manualRowIndex)?.fileFuel: newAudit.find((v,i) => i === manualRowIndex)?.fuel_recordFilePath === "null" ? "" : baseIMGURL + newAudit.find((v,i) => i === manualRowIndex)?.fuel_recordFilePath}
                                    >
                                        <Page pageNumber={1} scale={0.5}/>
                                    </Document>
                                    </>
                                    )}
                                    {/* <h3>Canvas</h3>
                                    <canvas ref={canvasRef} width={900} height={2000}></canvas> */}

                                </div>
                                <div className="col-5">
                                    {text ?
                                        (<><span style={{ fontSize: 21 }}><b>{t("Extracted key data")}</b></span>
                                            <br />

                                            <p> {fuelname} </p>
                                            <p> {quantity}</p>
                                            <p> {totalamount}</p>
                                            <p> {pricePerL}</p>
                                            <p> {invoiceNo}</p>
                                            <p> {date}</p>
                                            <br />
                                            <span style={{ fontSize: 21 }}><b>{t("Extracted full text")}</b></span>
                                            <br />

                                            <div style={{ whiteSpace: "pre-wrap" }}>
                                                <p> {text} </p>
                                            </div></>) :
                                        istimeout || isImgNotFound || !newAudit.find((v,i) => i === manualRowIndex)?.fileFuel || newAudit.find((v,i) => i === manualRowIndex)?.fuel_recordFilePath === "null"?null:(<><p style={{ marginLeft: "8.5%" }}>{t("Performing OCR audit")}</p><span><img src={Spinner} alt="loading" width={200} /></span></>)}
                                </div>


                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    })
)