import { useTranslation } from "react-i18next";
import { My_Button } from "../../Tools";
import { Selector } from "../../Tools";
import { useState } from "react";

export const AssetSettingForm = (props) => {
  const {
    deviceData,
    _handleSelectType,
    tableIndex,
    fetchUpdateDevice,
    algorithmParam,
    algorithmParamOptions,
    selectedSchedule,
    scheduleListOptions,
  } = props;
  const {t} = useTranslation("asset_profile_management")
  const [algorithmIndex, setAlgorithmIndex] = useState(deviceData.typeId);
  const _handleSelectAlgorithm = (e) => {
    setAlgorithmIndex(e.value);
  };
  return (
    <div className="row  align-items-end justify-content-between">
      <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mx-6 ">
        <Selector
          label={t("Algorithm")}
          options={algorithmParamOptions}
          defaultValue={
            deviceData.typeId
              ? algorithmParamOptions.filter(
                  (v) => v.value === deviceData.typeId
                )[0]
              : null
          }
          _handleSelect={(e) => {
            _handleSelectAlgorithm(e, tableIndex);
          }}
        />
      </div>
      <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mx-6">
        <Selector
          label={t("Schedule")}
          options={scheduleListOptions}
          defaultValue={
            selectedSchedule
              ? scheduleListOptions.filter(
                  (v) => v.value === selectedSchedule
                )[0]
              : null
          }
          _handleSelect={(e) => {
            _handleSelectType(e, tableIndex);
          }}
        />
      </div>

      <h4 className="pt-1">Algorithm Params</h4>
      {algorithmParam &&
        Object.entries(algorithmParam[algorithmIndex === 1 ? 0 : 1].params).map(
          ([key, value]) => {
            return (
              <div
                className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mx-6"
                key={key}
              >
                <Selector
                  label={value}
                  _handleSelect={(e) => {
                    _handleSelectType(e, tableIndex);
                  }}
                />
              </div>
            );
          }
        )}
      {/* </div> */}
      <div className="pt-3">
        <My_Button
          text={t("SAVE")}
          type={"submit"}
          id={"save"}
          onClick={(e) => fetchUpdateDevice(tableIndex)}
          style={{ width: "100%" }}
          rightIcon={<i className="fa fa-save ml-2"></i>}
        />
      </div>
    </div>
  );
};
