// /* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import { Colors } from "../../Assets/Config/color.config";
import { baseURL } from "../../network/URL";
import { My_Carousel } from "../../Tools/My_Carousel";
import { Document, Page, pdfjs } from 'react-pdf';
import html2canvas from "html2canvas";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const HistoryModal = ({ fuelHistoryData, index }) => {

  return (
    <div
      className="modal fade"
      id={"b" + index}
      tabIndex="-1"
      aria-labelledby="historyModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-sm rounded">
        <div
          className="modal-content"
          style={{ background: Colors.darkBlue }}
        // style={{ background: Colors[theme].background }}
        >
          <div
            className="modal-header d-flex justify-content-end"
            style={{ border: "none", color: "#ffff" }}
          >
            <i
              className="bi bi-x-lg"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></i>
          </div>
          <div className="modal-body text-center" style={{ border: "none" }}>
            {/* <img
              width="100%"
              src={baseURL + "/uploads/devices/" + fuelHistoryData[index].file}
            >

            </img> */}

            {(baseURL + '/uploads/devices/' + fuelHistoryData[index].file).includes('.pdf') ?
              <Document
                className="pdf-container w-100"
                style={{width: '800px'}}
                file={baseURL + '/uploads/devices/' + fuelHistoryData[index].file}
                
              >
                <Page width={700} pageNumber={1} scale={0.38} />
              </Document>
              :
              <img
                width="100%"
                src={baseURL + "/uploads/devices/" + fuelHistoryData[index].file}
              ></img>
            }

          </div>
        </div>
      </div>
    </div>
  );
};
export default HistoryModal;

// import React, { useEffect, useState } from 'react';
// import { Colors } from '../../Assets/Config/color.config';
// import { baseURL } from '../../network/URL';
// import { My_Carousel } from '../../Tools/My_Carousel';
// import { Document, Page, pdfjs } from '@react-pdf/renderer';

// const HistoryModal = ({ fuelHistoryData, index }) => {

//   const [pdfImage, setPdfImage] = useState(null);

//   useEffect(() => {
//     if (Array.isArray(fuelHistoryData) && fuelHistoryData.length > 0) {
//       const pdfImage = fuelHistoryData[0];
//       if (pdfImage.file && pdfImage.file.endsWith('.pdf')) {
//         convertPdfToImage(pdfImage.file);
//       } else {
//         setPdfImage(null);
//       }
//     }
//   }, [fuelHistoryData]);

//   const convertPdfToImage = async (pdfPath) => {
//     try {
//       const pdfBlob = await fetch(pdfPath).then(response => response.blob());
//       const pdfDataUrl = URL.createObjectURL(pdfBlob); // Create data URL for the PDF
//       setPdfImage(pdfDataUrl);
//     } catch (error) {
//       console.error('Error converting PDF to image:', error);
//     }
//   };

//   return (
//     <div className="modal fade" id={'b' + index} tabIndex="-1" aria-labelledby="historyModal" aria-hidden="true">
//       <div className="modal-dialog modal-sm rounded">
//         <div className="modal-content" style={{ background: Colors.darkBlue }}>
//           <div className="modal-header d-flex justify-content-end" style={{ border: 'none', color: '#ffff' }}>
//             <i className="bi bi-x-lg" data-bs-dismiss="modal" aria-label="Close"></i>
//           </div>
//           <div className="modal-body text-center" style={{ border: 'none' }}>
//             <img
//               src={baseURL + "/uploads/devices/" + pdfImage}
//               // src={pdfImage}
//               alt="PDF Image"
//               className="img-fluid py-1"
//               style={{ width: '100%', borderRadius: 5 }}
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default HistoryModal;
