import $ from 'jquery'
import { inject, observer } from "mobx-react";
import moment from 'moment';
import { useRef, useEffect, useState } from 'react';
import 'datatables.net-dt';
import 'datatables.net-dt/css/jquery.dataTables.css';
import { useTranslation } from 'react-i18next';
import { toJS } from 'mobx';

export const TrackingDataTable = inject("store")(
    observer((props) => {
        const { t } = useTranslation("new-tracking")
        const { theme, handleSelecteDetail } = props

        const { trackingDetail, 
                selectedIndex, 
                setSelectedIndex,
                trackingDetailVTwo
            } = props.store.trackingVTwo

       
        
        const rows = trackingDetailVTwo?.map((value) => {
            let trip = {
                id: value.uid,
                date: moment.utc(value.start_time).format("yyyy-MM-DD HH:mm:ss"),
                start_point: value.start,
                end_point: value.end,
                mileage:
                    value.trip_mileage === 0 ? "0.00" : Number(value.trip_mileage),
                co2_emission:
                    value.emission === 0 ? "0.00" : value.emission.toFixed(2),
                emission_intensity: value.emissionFactor ? value.emissionFactor : "0.00",
            };

            return trip;
        });

        const tableRef = useRef(null);

        useEffect(() => {
            // Initialize DataTable when the component mount

            const styleElement = document.createElement(`style`);
            styleElement.innerHTML = theme === 'dark' ? `
            .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
                color: black !important;
                border: 1px solid #E5E5E5!important;
                background-color: #E5E5E5 !important;
                /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #E5E5E5), color-stop(100%, #E5E5E5))!important; */
                background: -webkit-linear-gradient(top, #E5E5E5 0%, #E5E5E5 100%)!important;
                background: -moz-linear-gradient(top, #E5E5E5 0%, #E5E5E5 100%)!important;
                background: -ms-linear-gradient(top, #E5E5E5 0%, #E5E5E5 100%) !important;
                background: -o-linear-gradient(top, #E5E5E5 0%, #E5E5E5 100%)!important;
                background: linear-gradient(to bottom, #E5E5E5 0%, #E5E5E5 100%)!important;
              }
              .dataTables_wrapper .dataTables_paginate .paginate_button.current {
                color: black !important;
                border: 1px solid #C6C6C6!important;
                background-color: #C6C6C6 !important;
                /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FFFCFC), color-stop(100%, #969292))!important; */
                background: -webkit-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
                background: -moz-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
                background: -ms-linear-gradient(top, #FFFCFC 0%, #969292 100%) !important;
                background: -o-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
                background: linear-gradient(to bottom, #FFFCFC 0%, #969292 100%)!important;
              }
              .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
                color: black !important;
                border: 1px solid #C6C6C6!important;
                background-color: #C6C6C6 !important;
                /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FFFCFC), color-stop(100%, #969292))!important; */
                background: -webkit-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
                background: -moz-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
                background: -ms-linear-gradient(top, #FFFCFC 0%, #969292 100%) !important;
                background: -o-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
                background: linear-gradient(to bottom, #FFFCFC 0%, #969292 100%)!important;
              }
            ` :
                `
                .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
                    color: black !important;
                    border: 1px solid #E5E5E5!important;
                    background-color: #E5E5E5 !important;
                    /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #E5E5E5), color-stop(100%, #E5E5E5))!important; */
                    background: -webkit-linear-gradient(top, #E5E5E5 0%, #E5E5E5 100%)!important;
                    background: -moz-linear-gradient(top, #E5E5E5 0%, #E5E5E5 100%)!important;
                    background: -ms-linear-gradient(top, #E5E5E5 0%, #E5E5E5 100%) !important;
                    background: -o-linear-gradient(top, #E5E5E5 0%, #E5E5E5 100%)!important;
                    background: linear-gradient(to bottom, #E5E5E5 0%, #E5E5E5 100%)!important;
                  }
                  .dataTables_wrapper .dataTables_paginate .paginate_button.current {
                    color: black !important;
                    border: 1px solid #C6C6C6!important;
                    background-color: #C6C6C6 !important;
                    /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FFFCFC), color-stop(100%, #969292))!important; */
                    background: -webkit-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
                    background: -moz-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
                    background: -ms-linear-gradient(top, #FFFCFC 0%, #969292 100%) !important;
                    background: -o-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
                    background: linear-gradient(to bottom, #FFFCFC 0%, #969292 100%)!important;
                  }
            `
            document.head.appendChild(styleElement);

            const table = $(tableRef.current).DataTable({
                retrieve: true,
                scrollX: window.innerWidth < 701 ? true : false,
                order: [[0, 'desc']],

                lengthMenu: [8, 10, 15],
                data: rows,
                columns: [
                    { title: t('Date'), data: 'date' },
                    { title: t('Start Point'), data: 'start_point' },
                    { title: t('End Point'), data: 'end_point' },
                    { title: t('Mileage')+" (km)", data: 'mileage' },
                    { title: t('CO2e Emission')+" (kgCO₂e)", data: 'co2_emission' },
                    { title: t('Emission Intensity')+" (gCO₂e/km)", data: 'emission_intensity' },
                ],
            })

            table.column(0).nodes().to$().css('width', '10%');
            table.column(3).nodes().to$().css('width', '5%');

            table.cells().every(function () {
                $(this.node()).css('border-color', 'rgb(211,211,211, 0.3)');
            });

            const searchInput = $('.dataTables_filter input');
            searchInput.css('color', theme === 'light' ? 'black' : 'white');

            const entriesSelect = $('.dataTables_length select');
            entriesSelect.css('background-color', theme === 'light' ? '' : 'rgb(32, 43, 96)');
            entriesSelect.css('color', theme === 'light' ? 'black' : 'white');

            let tableRow = $('table.dataTable tbody')
            tableRow.css('cursor', 'pointer')

            $(tableRef.current).on('click', 'tbody tr', function () {
              
                const data = table.row(this).data()
                let rowIndex = table.row(this).index()

                let result = trackingDetailVTwo?.filter(
                    (value) => value.uid === data?.id
                )[0];
                handleSelecteDetail(result, data?.id);
                setSelectedIndex(data?.id)

                $('table.dataTable tbody tr').removeClass('selectedRow');

                $(this).addClass('selectedRow');

                console.log(toJS(result),'result=====>oftrackingdataatable')

            })

            $(tableRef.current).on('page.dt', function () {
                let previousPageRows = table.rows({ page: 'previous' }).nodes();
                $(previousPageRows).removeClass('selectedRow');
            });

            if (trackingDetailVTwo?.length > 0) {
                const firstRow = $('table.dataTable tbody tr:first');
                firstRow.trigger('click');
            }

        }, [theme]);

        return (
            <div>
                <table 
                    ref={tableRef} 
                    className='display cell-border hover stripe' 
                    style={{ width: '100%' }}
                >
                   
                </table>
            </div>
        )

    }))
