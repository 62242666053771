export const FuelRecordValidation = (data) => {
    const err={};
    const {  quantity, amount, } = data;

    if(quantity === ""){
        err.quantityErr = "Please enter filled Liter!";
    }else if(quantity.length > 10){
        err.quantityErr = "Invalid Liter!"
    }

    if(amount === ""){
        err.amountErr = "Please enter amount!";
    }else if(amount.length > 10){
        err.amountErr = "Invalid Amount!"
    }

    return err;
}