/* eslint-disable react-hooks/exhaustive-deps */
import { Colors, invertDark } from "../../Assets/Config/color.config";
import MonthlyMissingDataTable from "./MonthlyMissingDataTable";
import { CO2_Loading } from "../../Assets/CO2_Loading";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import Month from "../../Assets/month.json";
import { Selector } from "../../Tools";
import { toJS } from "mobx";
import { selector } from "d3";
import LogsNotiCard from "./LogsNotiCard";
import IssuesNotiCard from "./IssuesNotiCard";

export const Notification = inject("store")(
  observer((props) => {
    const {
      notiDropdownStyle,
      currentYear,
      _handleMonthSelect,
      theme,setOpen,
      handleOpen, open,newNotiCount,role
    } = props;
  
    const {
      selectedYearFromNav,
      _handleYearChange,
      dataForNavBar,
      _fetchDataEntryForNav,
    } = props.store.dataEntry;

    const {
        years,
        companyYearlyData
    } = props.store.dashboard

    const {showLogView,_handleViewChange} = props.store.adminPanel


    const handleClose = () => {
      setOpen(!open)
    }

    useEffect(() => {
      _fetchDataEntryForNav();
    }, []);

    

    const [entryData, setEntryData] = useState([]);

    const months = Month.map((v) => v.label).slice(1, 13);

    useEffect(() => {
      if (dataForNavBar) {
        const data = months.map((d, i) => ({
          month: d,
          data: dataForNavBar[i],
          index: i,
        }));

        const notNullData = data?.filter((d) => d.data);

        const filterData = notNullData.map((d) => {
          return {
            month: d.month,
            vehicles: d.data?.vehicles,
            index: d.index,
          };
        });

        const calculateData =
          filterData.length === 0
            ? []
            : filterData.filter((v) => v.vehicles?.length > 0)?.length > 0
            ? filterData.filter((v) => v.vehicles?.length > 0)
            : ["No Data"];

        setEntryData(calculateData);
      }
    }, [dataForNavBar]);

    return (
      <div
        className="px-2 mx-2"
        style={{ ...notiDropdownStyle}}
      >
        <span className="d-flex justify-content-between px-2 ">
          <h5>
            <i className="fa fa-exclamation-triangle pe-2 text-warning " />
             Issue Notifications
          </h5>
          <div  onClick={() => handleClose()}>
              <i
               
                style={{ cursor: "pointer" }}
                className="bi bi-x-lg"
              ></i>
          </div>
            {/* <div className="d-flex justify-content-between align-items-center">
        
                <Selector isSearchable={false}
                        // label={"Year"}
                        menuListColor='black'
                        options={years}
                        defaultValue={years.filter((v) => v.value === new Date().getFullYear())[0]}
                        
                        value={years.find((v) => v.value ===  selectedYearFromNav)}
                        _handleSelect={(e) => {
                            _handleYearChange(e.value)
                        }}
                        disabled={false} />
            </div> */}
            </span>
        
        {dataForNavBar.length > 0 ? (
          dataForNavBar[0] === "No Data" ? (
            <div
              className="text-center"
              style={{ color: invertDark(props.theme) }}
            >
              <i
                className="fa fa-exclamation-triangle me-2"
                style={{ marginTop: "5%", color: Colors.fuel }}
              />
              No Data
            </div>
          ) : (
            <div
              className="p-0 m-0"
              style={{
                overflowY: "scroll",
                overflowX: "hidden",
                maxHeight: 400,
              }}
            >
              {entryData &&
                entryData.map((d, index) => (
                  <div className="pb-2" key={index}>
                    <MonthlyMissingDataTable
                      section={index}
                      _handleMonthSelect={_handleMonthSelect}
                      theme={theme}
                      monthlyEntry={d.vehicles}
                      month={d.month}
                      index={d.index}
                      notiDropdownStyle={notiDropdownStyle}
                    />
                  </div>
                ))}
            </div>
          )
        ) : (<></>
          
      //   role === "super_admin" ?
      //   <span className="d-flex align-items-center mb-1 " >
      //     <div className="d-flex p-0 justify-content-start ">
      //       <div className="col-md-3">
      //         <div className="btn-group btn-group-sm" role="group">
      //           <div type="button" style={{ zIndex: 0 ,width:240}}className={`${showLogView === "logs" ? "btn btn-outline-primary btn-1 active" : "btn btn-outline-primary btn-1"}`} onClick={() => _handleViewChange("logs")} >Logs</div>
      //           <div type="button" style={{ zIndex: 0 ,width:240}} className={`${showLogView === "table" ? "btn btn-outline-primary btn-2 active" : "btn btn-outline-primary btn-2"}`} onClick={() => _handleViewChange("table")}>Issues</div> 
      //         </div>
      //       </div>                
      //     </div>
      // </span>
      // :""
        )
        }

        {
        <IssuesNotiCard theme={theme} handleClose={handleClose} _handleViewChange ={_handleViewChange}/>

            // showLogView === "logs" ?
            //  <> <LogsNotiCard theme={theme} handleClose={handleClose} /> </>  :
            //   <IssuesNotiCard theme={theme} handleClose={handleClose}/>
        }

      </div>
    );
  })
);
