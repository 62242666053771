/* eslint-disable jsx-a11y/img-redundant-alt */
import { fontSize } from "@mui/system";
import { inject, observer } from "mobx-react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import React, { useState, useEffect } from 'react';
import Swal from "sweetalert2";
import { toJS } from "mobx";
import Spinner from "../../Assets/Images/icons/spinner.gif";
import { Document, Page, pdfjs } from 'react-pdf';
import { useTranslation } from "react-i18next";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const ImageToTextMobileModal = inject("store")(
    observer((props) => {
        const {t} = useTranslation("mobile")
        const { theme } = props;
        const { text, fileFuelName, fileFuel, fuelname, quantity, totalamount, pricePerL, invoiceNo, date, showAlert,istimeout,uploadFileType } = props.store.driver;

        console.log("fileFUel ===>", fileFuel)
        console.log("uploadFileType", uploadFileType)

        useEffect(() => {
            console.log('text', toJS(showAlert))


            if (showAlert && text) {

                Swal.fire({
                    icon: 'success',
                    timer: 2000,
                    text: 'Successfully completed!',
                    showConfirmButton: false
                }).then((result) => {
                    console.log("success",result);
                })
            }
            else if (istimeout) {

                Swal.fire({
                    icon: 'error',
                    text: 'Try with better quality image',
                    showConfirmButton: true
                }).then((result) => {
                    console.log("timeout",result);
                })
            }

        }, [showAlert, text, istimeout]);


        return (

            <div
                className="modal  fade"
                id="imageToText"
                tabIndex="-1"
                aria-labelledby="imageToText"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content d-flex align-items-center my-3" style={{ background: Colors[theme].background, color: invertDark(theme), height: '550px', overflowY: 'scroll' }}>
                        <div className="modal-header border-0 d-flex justify-content-between w-100">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {fileFuelName}
                            </h5>
                            <button
                                // id="alert_close"
                                type="button"
                                className={`btn-close ${theme === 'dark' && 'btn-close-white'}`}
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            // style={{ visibility: "hidden" }}
                            ></button>
                        </div>
                        <div className="modal-body" style={{ width: '100%' }}>
                            <div className="row">
                                <div className="col-12">
                                    <span style={{ fontSize: 21 }}><b>{t("Actual imagePath uploaded")}</b></span>
                                    <br />
                                    <br />
                                    {uploadFileType !== 'application/pdf' ?
                                    <img src={fileFuel} className="App-image" alt="logo" width={"100%"} height={475} />
                                    :
                                    (<>
                                    <Document
                                        file={fileFuel}
                                    >
                                        <Page pageNumber={1} scale={0.38}/>
                                    </Document>
                                    </>
                                    )}
                                    {/* <h3>Canvas</h3>
                                    <canvas ref={canvasRef} width={200} height={450}></canvas> */}

                                </div>
                                <div className="col-12">
                                    {text ?
                                        (<><span style={{ fontSize: 21 }}><b>{t("Extracted key data")}</b></span>
                                            <br />

                                            <p> {fuelname} </p>
                                            <p> {quantity}</p>
                                            <p> {totalamount}</p>
                                            <p> {pricePerL}</p>
                                            <p> {invoiceNo}</p>
                                            <p> {date}</p>
                                            <br />
                                            <span style={{ fontSize: 21 }}><b>{t("Extracted full text")}</b></span>
                                            <br />

                                            <div style={{ whiteSpace: "pre-wrap" }}>
                                                <p> {text} </p>
                                            </div></>) :
                                        istimeout || !fileFuel? null : (<><p style={{ marginLeft: "8.5%" }}>{t("Performing OCR audit")}</p><span><img src={Spinner} alt="loading" width={50} style={{ marginLeft: "29%" }} /></span></>)}
                                </div>


                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    })
)