/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import { inject, observer } from "mobx-react";
import { useState, useEffect } from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { My_Button, My_DatePicker, My_Input } from "../../Tools";
import { Button } from "react-bootstrap";
import { ImageToTextMobileModal } from "./ImageToTextMobileModal";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { ImageUpload } from "../../Components/DataEntry/ImageUpload";
import { toJS } from "mobx";
import { useTranslation } from "react-i18next";

export const FuelForm = inject("store")(
  observer((props) => {
    const {t} = useTranslation("mobile")
    const {
      _handleSubmit,
      fuelData,
      selectedVehicleInfo,
      setFuelData,
      _handleTextChange,
      _handleImageToTextClick,
      filefueltype,
      quantity,
      odometerStartInfo,
      odometerEndInfo,
      fileStartOfMonth,
      fileEndOfMonth,
      fileStartName,
      fileEndName,
      _handleFileChange,
      fileFuel,
      fileFuelRecords,
      fileFuelName,
      typeStatus,
      fuelStatus,
      setImageStatus,
      fuelHistory,
      imageStatus,
      odometerStartEnd,
      _handleFetchOdometerStartEnd
    } = props.store.driver;
    const { fuelType, theme } = props;

    const { role } = props.store.common;

    const history = useHistory()

    console.log('odometerStart ===>', toJS(odometerStartEnd?.odometer_start))
    console.log("fueldata===>", toJS(fuelData))
    console.log("selectedinfo ====>", toJS(selectedVehicleInfo))

    useEffect(() => {
      setFuelData(selectedVehicleInfo);
    }, [selectedVehicleInfo]);

    useEffect(() => {
      _handleFetchOdometerStartEnd()
    }, [fuelData?.recordDateTime])

    const _handleFileChangeNew = (e, type) => {
      if (role === "viewer") {
        toast.error("Viewer cannot upload image");
      } else {
        _handleFileChange(e, type);
      }
    };


    return (
      <div>
        <div
          className="px-2"
          style={{ color: Colors.Dark[4], fontWeight: "bold", fontSize: 12 }}
        >
          <form onSubmit={(e) => 
            {_handleSubmit(e, history); 
            // setTimeout(() => {
            //   history.push('m_fuel_history')
            // }, 1000);
          } 
          }>
            <div className="d-flex flex-column flex-wrap justify-content-around">
              <div>
                <div className="mt-2">
                  <div className="pb-1">
                    <i className="bi bi-calendar2-date"></i> {t("Date")}
                  </div>
                  <My_DatePicker
                    id={"date"}
                    height={"43px"}
                    selected={fuelData?.recordDateTime}
                    onChange={(e) => _handleTextChange(e, "recordDateTime")}
                    customInput=""
                  />
                </div>
              </div>
              <div>
                <div className="mt-2">
                  <div className="pb-1">
                    <i className="bi bi-cash-coin"></i> {t("Pay Amount")} (SGD)
                  </div>
                  <My_Input
                    value={fuelData.amount}
                    onChange={(e) => _handleTextChange(e, "amount")}
                    id="amount"
                  // type="number"
                  />
                </div>
              </div>
            </div>

            <div className="d-flex flex-column flex-wrap justify-content-around">
              <div>
                {fuelType === "Battery" ? (
                  <div className="mt-2">
                    <div className="pb-1">
                      <i className="bi bi-lightning-charge"></i> {t("Kilowatt")} (kW)
                    </div>
                    <My_Input
                      value={fuelData.quantity}
                      onChange={(e) => _handleTextChange(e, "quantity")}
                      id="liter"
                    />
                  </div>
                ) : (
                  <div className="mt-2">
                    <div className="pb-1">
                      <i className="bi bi-droplet-fill"></i> {t("Liter")} (L)
                    </div>
                    <My_Input
                      value={fuelData.quantity}
                      onChange={(e) => _handleTextChange(e, "quantity")}
                      id="liter"
                    />
                  </div>
                )}
              </div>
              <div className="text-center pt-3">
                <div className="mt-2">
                  <div className="pb-1">
                    <i className="bi bi-droplet-fill"></i> {t("Extracted Fuel Quantity")}
                    <p
                      className="w-100 mt-2"
                      style={{
                        color: "rgb(47 191 47)",
                      }}
                    >
                      {quantity ? quantity : t("No data")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-row flex-wrap justify-content-around mt-2">
              {filefueltype.toLowerCase() === fuelType.toLowerCase() &&
                fuelData.quantity &&
                quantity?.match(/\d+\.\d+|\d+ltr+/g) &&
                quantity.match(/\d+\.\d+|\d+ltr+/g)[0] === fuelData.quantity ? (
                <span onChange={() => setImageStatus("Audited with OCR")}>
                  <i
                    className="bi bi-check-circle-fill"
                    style={{ color: "green" }}
                  ></i>
                  {t("Audited with OCR")}
                </span>
              ) : fuelData.quantity === "" ? (
                // onChange={() => setImageStatus("Please fill liter amount to perform OCR audit")}
                <span>
                  <i
                    className="bi bi-dash-circle-fill"
                    style={{ color: "rgb(126 66 31)" }}
                  ></i>
                  {t("Please fill liter amount to perform OCR audit")}
                </span>
              ) : filefueltype === "" && quantity === "" ? (
                <span>
                  <i
                    className="bi bi-dash-circle-fill"
                    style={{ color: "rgb(126 66 31)" }}
                  ></i>
                  {t("No file uploaded for audit")}
                </span>
              ) : filefueltype.toLowerCase() !== fuelType.toLowerCase() ? (
                // onChange={() => setImageStatus(`Fuel type of uploaded file does not match with expected (Expected ${fuelType})`)}
                <span>
                  <i
                    className="bi bi-x-circle-fill"
                    style={{ color: "red" }}
                  ></i>
                  {t("Fuel type of uploaded file does not match with expected")}
                  ({t("Expected")} {fuelType})
                </span>
              ) : quantity?.match(/\d+\.\d+|\d+ltr+/g) &&
                quantity.match(/\d+\.\d+|\d+ltr+/g)[0] !== fuelData.quantity ? (
                // onChange={() => setImageStatus(`Data does not match `)}
                <span>
                  <i
                    className="bi bi-x-circle-fill"
                    style={{ color: "red" }}
                  ></i>
                  {t("Data does not match (Manual Entry")}: {fuelData.quantity}, {t("File")}
                  OCR: {quantity.match(/\d+\.\d+|\d+ltr+/g)[0]})
                </span>
              ) : null}
            </div>

            <div className="d-flex flex-column justify-content-center">
              <div className="m-1">
                {t("Upload Image")}
                <label className="w-100" htmlFor={`image-upload-fuelRecoreds`}>
                  <div
                    className="input-group"
                    style={{ cursor: "pointer", width: "100%" }}
                  >
                    <input
                      type="text"
                      style={{
                        background: theme === "dark" && Colors.darkBlue,
                        color: invertDark(theme),
                      }}
                      aria-describedby="basic-addon2"
                      className="form-control"
                      value={
                        fileFuelRecords ? fileFuelRecords.name : fileFuelName
                      }
                      placeholder="Choose image"
                      disabled="false"
                    />
                    <span className="input-group-text" id="basic-addon2">
                      {t("Browse")}
                    </span>
                    <input
                      type="file"
                      style={{ display: "none" }}
                      id={`image-upload-fuelRecoreds`}
                      accept="application/pdf,image/x-png,image/gif,image/jpeg"
                      onClick={(e) => (e.target.value = null)}
                      onChange={(e) =>{
                        _handleFileChangeNew(e, "fuelRecords")
                        _handleImageToTextClick();
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#imageToText"
                    />
                  </div>
                </label>
              </div>

              {/* <div className="mt-2 mb-2">
                <Button
                  type="button"
                  customColor={Colors.low}
                  className="col-12"
                  style={{
                    backgroundColor: Colors.low,
                    width: "100%",
                    height: "43px",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    lineHeight: 1.05,
                  }}
                  onClick={_handleImageToTextClick}
                  data-bs-toggle={fileFuel === "" ? "" : "modal"}
                  data-bs-target={fileFuel === "" ? "" : "#imageToText"}
                >
                  {t("Perform OCR audit")}
                </Button>
              </div> */}
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex">
                  <p>{t("Type Status")} : </p>
                  <p style={{ color: "rgb(47 191 47)" }}>{typeStatus}</p>
                </div>
                <div className="d-flex">
                  <p>{t("Fuel Status")} : </p>
                  <p style={{ color: "rgb(47 191 47)" }}>{fuelStatus}</p>
                </div>
              </div>
            </div>

            {/* <div className="m-1" style={{ minWidth: '250px' }} htmlFor={`consumed`}>
              {currentType === "ev" ? "Electricity Consumed (kWh)" : "Fuel Consumed (Litres)"}
              <span className=" float-end mt-1" style={{ fontSize: 10 }}>{consumedInfo}</span>
              <My_Input
                id={`consumed`}
                type={"number"}
                value={fuelType === 'Battery'? !selectedVehicleInfo.data_form.battery_consumed ? selectedVehicleInfo.data_form.ascent_battery_consumed : selectedVehicleInfo.data_form.battery_consumed : !selectedVehicleInfo.data_form.fuel_consumed ? selectedVehicleInfo.data_form.ascent_fuel_consumed : selectedVehicleInfo.data_form.fuel_consumed}
                //disabled={updateData?.fuelConsumed?.includes("ascent")}
                customColor={Colors.dark.primaryColor}
                onChange={(e) => _handleTextChange(e, "consumed")}
              />

            </div> */}

            <div className="d-flex flex-row flex-wrap justify-content-around  mb-2">
              <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-12">
                {t("Odometer")}
                <div
                  className="col p-2 mt-2"
                  style={{
                    border: "1px solid #C6C6C6",
                  }}
                >
                  <div className="m-1 mb-2">
                    {t("Odometer Reading at Start of Month")} (km)
                    <span className=" float-end mt-1" style={{ fontSize: 10 }}>
                      {odometerStartInfo}
                    </span>
                    <My_Input
                      id={"odometerStart"}
                      // type={"number"}
                      value={
                        !fuelData.odometerStart ||
                          fuelData.odometerStart === null
                          ? selectedVehicleInfo.data_form.ascent_odometerStart
                          : fuelData.odometerStart
                      }
                      onChange={(e) => _handleTextChange(e, "odometerStart")}
                      disabled={
                        odometerStartEnd.odometer_start == 0 || odometerStartEnd.odometer_start === null
                          ? false
                          : true
                      }
                    />
                  </div>

                  <div className="m-1 mb-2">
                    {t("Odometer Reading at End of Month")} (km)
                    <span className=" float-end mt-1" style={{ fontSize: 10 }}>
                      {odometerEndInfo}
                    </span>
                    <My_Input
                      id={"odometerEnd"}
                      // type={"number"}
                      value={
                        !fuelData.odometerEnd || fuelData.odometerEnd === null
                          ? selectedVehicleInfo.data_form.ascent_odometerEnd
                          : fuelData.odometerEnd
                      }
                      onChange={(e) => _handleTextChange(e, "odometerEnd")}
                    />
                  </div>
                </div>
              </div>

              <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-12">
                {t("Upload")}
                <div
                  className="col p-2 mt-2"
                  style={{
                    border: "1px solid #C6C6C6",
                  }}
                >
                  <div className="m-1 mb-2">
                    {t("Upload Image of Odometer at Start of the Month")}
                    <label className="w-100" htmlFor={`image-upload-start`}>
                      <div
                        className="input-group"
                        style={{ cursor: "pointer", width: "100%" }}
                      >
                        <input
                          type="text"
                          style={{
                            background: theme === "dark" && Colors.darkBlue,
                            color: invertDark(theme),
                          }}
                          aria-describedby="basic-addon2"
                          className="form-control"
                          value={
                            fileStartOfMonth
                              ? fileStartOfMonth.name
                              : fileStartName
                          }
                          placeholder="Choose image"
                          disabled="false"
                        />
                        <span className="input-group-text" id="basic-addon2">
                          {t("Browse")}
                        </span>
                        <input
                          type="file"
                          style={{ display: "none" }}
                          id={`image-upload-start`}
                          accept="image/x-png,image/gif,image/jpeg"
                          onClick={(e) => (e.target.value = null)}
                          onChange={(e) => {
                            _handleFileChangeNew(e, "start");
                          }}
                        />
                      </div>
                    </label>
                  </div>

                  <div className="m-1 mb-3">
                    {t("Upload Image of Odometer at End of the Month")}
                    <label className="w-100" htmlFor={`image-upload-end`}>
                      <div
                        className="input-group"
                        style={{ cursor: "pointer" }}
                      >
                        <input
                          type="text"
                          style={{
                            background: theme === "dark" && Colors.darkBlue,
                            color: invertDark(theme),
                          }}
                          aria-describedby="basic-addon3"
                          className="form-control"
                          value={
                            fileEndOfMonth ? fileEndOfMonth.name : fileEndName
                          }
                          placeholder="Choose image"
                          disabled="false"
                        />
                        <span className="input-group-text" id="basic-addon3">
                          {t("Browse")}
                        </span>
                        <input
                          type="file"
                          style={{ display: "none" }}
                          id={`image-upload-end`}
                          accept="image/x-png,image/gif,image/jpeg"
                          onClick={(e) => (e.target.value = null)}
                          onChange={(e) => {
                            _handleFileChangeNew(e, "end");
                          }}
                        />
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <hr className="my-0" style={{ background: Colors.Dark[2] }} />

            <div className="d-flex flex-row flex-wrap justify-content-around">
              <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-12">
                <div className="m-1 mb-2">
                  {t("Total Mileage")} (km)
                  <My_Input
                    disabled={true}
                    id={"mileage"}
                    type={"number"}
                    value={fuelData.mileage}
                    onChange={(e) => _handleTextChange(e, "mileage")}
                  />
                </div>
              </div>

              {/* <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-12" >
                Image Preview
                <div className="col m-1 mb-2 p-2" style={{
                  border: "1px solid #C6C6C6"
                }}>
                  <div className="d-flex flex-row center">
                    <div className="row d-flex justify-content-center"
                      onClick={() => _handleImageViewClick(fileFuelName, fileFuel, currentType === "ev" ? "Charging Receipt" : "Fuel Receipt")}
                    >
                      <ImageUpload currentImage={fileFuel}
                        fileType={"fuel"}
                        _handleClearImage={_handleClearImage}
                        imgref={imageRef}
                      />
                      {currentType === "ev" ? "Charging Receipt" : "Fuel Receipt"}

                    </div>
                    <div className="row d-flex justify-content-center"
                      onClick={() => _handleImageViewClick(fileStartName, fileStart, "Odometer Start")}
                    >
                      <ImageUpload currentImage={fileStart}
                        fileType={"start"}
                        _handleClearImage={_handleClearImage}

                      />
                      Odometer Start

                    </div>
                    <div className="row d-flex justify-content-center"
                      onClick={() => _handleImageViewClick(fileEndName, fileEnd, "Odometer End")}
                    >
                      <ImageUpload currentImage={fileEnd}
                        fileType={"end"}
                        _handleClearImage={_handleClearImage}

                      />
                      Odometer End
                    </div>
                  </div>
                </div>
              </div> */}
            </div>

            <My_Button
              type="submit"
              text={t("Submit")}
              className="col-12 mb-2"
              customColor={Colors.lightBlue}
            />
          </form>
        </div>
        <ImageToTextMobileModal theme={theme} />
      </div>
    );
  })
);
