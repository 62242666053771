/* eslint-disable default-case */
import { action, makeObservable, observable, runInAction, toJS } from "mobx";
import moment from "moment";
import { Cookies } from "react-cookie";
import { toast } from "react-toastify";
import {
  getIssuanceReport,
  updateCertificates,
  getAllContractByCompanyNew,
  sendCertificates,
  getStacsHistory,
  updateCertificatesV2
} from "../network/trackingAPI";
import { fetchMonthlyData } from "../network/certificateAPI";
import { fetchMonthlyDataV2 } from "../network/certificateAPI"

class TrackAndTrace {
  cookies = new Cookies();
  token = this.cookies.get("accessToken");
  companyId = this.cookies.get("company");

  month = 0;
  year = 0;
  assetType = "all";
  fuelType = "all";
  startTime = new Date();
  endTime = new Date();
  units = [];
  unitId = [];
  reportData = null;
  assetTypeOptions = [
    { label: "All", value: "all" },
    { label: "EV", value: "EV" },
    { label: "Diesel", value: "Diesel" },
    { label: "Petrol", value: "Petrol" },
  ];

  fuelTypeOptions = [
    { label: "All", value: "all" },
    { label: "EV", value: "EV" },
    { label: "Diesel", value: "Diesel" },
    { label: "Petrol", value: "Petrol" },
  ];

  reportTypeOptions = [
    { label: "Monthly", value: "Monthly" },
    { label: "Yearly", value: "Yearly" },
  ];
  reportType = "Monthly";
  selectedYear = moment().year();
  contractData = [];
  monthlyData = [];
  selectedMonthlyDetail = null;

  view = false;
  certificateData = "";
  LoadingForMonthlyTable = false;
  stacsHistory = []
  loading = true
  stacsMonth = ""
  historicalStatus = 1
  unitsTab = "own"

  certId = null;

  constructor() {
    makeObservable(this, {
      historicalStatus: observable,
      monthlyData: observable,
      assetTypeOptions: observable,
      fuelTypeOptions: observable,
      assetType: observable,
      fuelType: observable,
      startTime: observable,
      endTime: observable,
      reportData: observable,
      units: observable,
      reportTypeOptions: observable,
      reportType: observable,
      selectedYear: observable,
      contractData: observable,
      selectedMonthlyDetail: observable,
      view: observable,
      certificateData: observable,
      LoadingForMonthlyTable: observable,
      loading: observable,
      stacsMonth: observable,
      stacsHistory: observable,
      unitsTab: observable,
      certId: observable,

      _handleChangeYear: action.bound,
      _handleFilter: action.bound,
      _handleCheck: action.bound,
      _handleCreateReport: action.bound,
      _handleUpdateCertificates: action.bound,
      _handleUpdateCertificatesV2: action.bound,
      _handlegetAllContractByCompanyNew: action.bound,
      setContractData: action.bound,
      _fetchMonthlyData: action.bound,
      _fetchMonthlyDataV2: action.bound,
      _handleSelectedMonthlyDetail: action.bound,
      _handleView: action.bound,
      _handleViewClose: action.bound,
      _handleSendCertificates: action.bound,
      _handleSetLoadingForMonthlyTable: action.bound,
      _handleGetStacsHistory: action.bound,
      _handleSetMonth: action.bound,
      _handleChangeUnitsTab: action.bound,
      _handleGetCertId: action.bound
    });
  }

  _handleGetCertId = (id) => {
    this.certId = id
  }

  _handleChangeUnitsTab = (unitsType) => {
    this.unitsTab = unitsType
  }

  _handleSetLoadingForMonthlyTable = (loading) => {
    runInAction(() => {
      this.LoadingForMonthlyTable = loading;
    });
  };

  _handleSelectedMonthlyDetail = (data, index) => {
    this.loading = true
    setTimeout(() => {
      this.selectedMonthlyDetail = this.monthlyData[index];
      this.loading = false
    }, 200);

    // console.log(this.monthlyData[index])
  };

  _fetchMonthlyData = async (year, unitsType) => {
    runInAction(() => {
      this._handleSetLoadingForMonthlyTable(true);
      this.monthlyData = [];
    });

    fetchMonthlyData(this.token, this.companyId, year, unitsType, (err, data) => {
      if (data?.message === "Token Expired!") {
        this._handleSetLoadingForMonthlyTable(false);
        toast.info(data?.message);
        window.location.pathname = "/";
      } else {
        runInAction(() => {
          this._handleSetLoadingForMonthlyTable(false);
          if (data?.error) {
            toast.error(data?.message);
          } else {
            this.monthlyData = data.payload;
          }
        });
      }
    });
  };

  _fetchMonthlyDataV2 = async (year, unitsType) => {
    runInAction(() => {
      this._handleSetLoadingForMonthlyTable(true);
      this.monthlyData = [];
    });

    fetchMonthlyDataV2(this.token, this.companyId, year, unitsType, (err, data) => {
      if (data?.message === "Token Expired!") {
        this._handleSetLoadingForMonthlyTable(false);
        toast.info(data?.message);
        window.location.pathname = "/";
      } else {
        runInAction(() => {
          this._handleSetLoadingForMonthlyTable(false);
          if (data?.error) {
            toast.error(data?.error);
          } else {
            this.monthlyData = data?.payload;
          }
        });
      }
    });
  };

  _handleChangeYear = async (year) => {
    this.selectedYear = year;
    this._fetchMonthlyDataV2(year, this.unitsTab);
  };

  _handleFilter = (e, label) => {
    switch (label) {
      case "assetType":
        this.assetType = e.value;
        break;
      case "fuelType":
        this.fuelType = e.value;
        break;
      // case 'startTime': this.startTime = e; break;
      // case 'endTime':
      // const temp = e
      // this.endTime = new Date(temp.getFullYear(), temp.getMonth() + 1, 0); break;
      case "month":
        this.startTime = e;
        let tempMonth = e;
        this.endTime = new Date(
          tempMonth.getFullYear(),
          tempMonth.getMonth() + 1,
          0
        );
        break;
      case "year":
        this.startTime = e;
        let tempYear = e;
        this.endTime = new Date(tempYear.getFullYear(), 11, 31);
        break;
      case "reportType":
        this.reportType = e.value;
        break;
    }
  };

  _handleCheck = (value, id) => {
    const isSelected = this.units.findIndex((v) => v === value);
    // const isIdSelected = this.units.findIndex((v) => v === id);
    if (isSelected === -1) {
      runInAction(() => {
        this.units = [...this.units, value];
        this.unitId = [...this.unitId, id];
      });
    } else {
      this.units.splice(isSelected, 1);
      this.unitId.splice(isSelected, 1);
    }
    console.log(this.units, ...this.unitId);
  };

  _handleCreateReport = () => {
    getIssuanceReport(
      this.companyId,
      moment(this.startTime).format("yyyy-MM-DD"),
      moment(this.endTime).format("yyyy-MM-DD"),
      this.units,
      this.unitId.toString(),
      this.token,
      (err, data) => {
        if (data?.success !== false) {
          this.reportData = data;
          console.log(this.reportData);
        } else {
          this.reportData = [];
          if (data.message === "Token Expired!") {
            window.location.pathname = "/";
            toast.info(data.message);
          }
          toast.error(data.message);
        }
      }
    );
  };

  _handleUpdateCertificates = (id) => {
    updateCertificates(id, this.token, (err, data) => {
      if (data.error) {
        toast.error(data.error);
      } else {
        toast.success(data.payload.message);
        // this._handlegetAllContractByCompanyNew(
        //   this.companyId,
        //   this.selectedYear,
        //   this.token
        // );
        this._fetchMonthlyData(this.selectedYear);
      }
    });
  };

  _handleUpdateCertificatesV2 = (id) => {
    updateCertificatesV2(id, this.token, (err, data) => {
      if (data.error) {
        toast.error(data.error);
      } else {
        toast.success(data.payload.message);
        // this._handlegetAllContractByCompanyNew(
        //   this.companyId,
        //   this.selectedYear,
        //   this.token
        // );
        this._fetchMonthlyDataV2(this.selectedYear, this.unitsTab);
      }
    });
  };

  setContractData = (value) => {
    this.contractData = value;
  };

  _handlegetAllContractByCompanyNew = () => {
    getAllContractByCompanyNew(
      this.companyId,
      this.selectedYear,
      this.token,
      (err, data) => {
        if (data) {
          if (data.message === "Token Expired!") {
            toast.info(data.message);
            window.location.pathname = "/";
          }
          this.setContractData(data);
          if (data.error) {
            toast.warning(data.error);
          }
        } else {
          toast.error(err.error);
        }
      }
    );
  };

  _handleSendCertificates = (month) => {
    sendCertificates(
      this.companyId,
      this.selectedYear,
      month,
      this.token,
      (err, data) => {
        if (data) {
          this.certificateData = data;
          toast.success(data.message);
          this._fetchMonthlyDataV2(this.selectedYear, this.unitsTab)
        } else {
          toast.error(err.message);
        }
        
      }
    );
  };

  _handleView = () => {
    this.view = !this.view;
  };

  _handleViewClose() {
    this.view = false;
  }



  _handleSetMonth(month) {

    this.stacsMonth = month
    this._handleGetStacsHistory(this.stacsMonth)
  }

  _handleGetStacsHistory = (month) => {
    this.loading = true
    setTimeout(() => {
      getStacsHistory(this.selectedYear, month, this.companyId, this.token, (err, data) => {
        if (data) {
          runInAction(() => {
            this.stacsHistory = data.payload?.map(v => {
              let data = {
                batch_id: v.batchId,
                status: v.status,
                date: v.date,
                mileage: Number(v.mileage).toFixed(4),
                trips: Number(v.trips).toFixed(4),
                baseline_emission: Number(v.baselineEmission).toFixed(4),
                offset: Number(v.offset).toFixed(4),
              }
              return data
            })
            this.loading = false
          })
        } else {
          toast.error(data?.error)
        }
      })
    }, 100);
  }
}

export default new TrackAndTrace();
