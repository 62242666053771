import React, { useState, useEffect, useRef, useCallback } from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { inject, observer } from "mobx-react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

export const getLastFourYears = () => {
  const currentYear = new Date().getFullYear();
  return Array.from({ length: 4 }, (_, i) => currentYear - i);
};

export const months = [
  "Sep",
  "Aug",
  "July",
  "June",
  "May",
  "April",
  "March",
  "February",
  "January",
];

export const News = inject("store")(
  observer((props) => {
    const { theme } = props;
    const [news, setNews] = useState([]);
    const currentDate = new Date();
    const [selectedYearNew, setSelectedYearNew] = useState(
      currentDate.getFullYear().toString()
    );
    const [selectedMonth, setSelectedMonth] = useState("");
    const [selectedYear, setSelectedYear] = useState(
      currentDate.getFullYear().toString()
    );
    const [currentMonthIndex, setCurrentMonthIndex] = useState(0);
    const [collapsable, setCollapsable] = useState(true);

    const getUniqueMonthsArrayForYear = (news, year) => {
      const monthsSet = new Set(
        news
          .filter((item) => new Date(item.date).getFullYear() === year)
          .map((item) => {
            const date = new Date(item.date);
            return date.toLocaleString("default", { month: "long" });
          })
      );
      return Array.from(monthsSet);
    };

    const [monthsByYear, setMonthsByYear] = useState({});
    const filterYear = [
      ...new Set(news.map((item) => new Date(item.date).getFullYear())),
    ];

    useEffect(() => {
      const years = [...new Set(filterYear)];
      const monthsData = {};
      years.forEach((year) => {
        monthsData[year] = getUniqueMonthsArrayForYear(news, year);
      });
      setMonthsByYear(monthsData);
    }, [news]);

    const newsRefs = useRef([]);

    const getMonthIndex = (customIndex) => {
      const actualOrder = [8, 7, 6, 5, 4, 3, 2, 1, 0];
      return actualOrder[customIndex];
    };

    const handleMonthClick = (month, year, index) => {
      console.log("Index....", index);
      console.log("currentMonthIndex....", currentMonthIndex);
      console.log(
        "month index --->",
        months.indexOf(months[months.length - 1])
      );
      setSelectedMonth(selectedMonth);
      setSelectedYear(selectedYear);
      setCurrentMonthIndex(currentMonthIndex);
      console.log("selectedMonth", selectedMonth);

      const newsRef = newsRefs.current.find(
        (ref) =>
          ref && new Date(ref.dataset.date).getMonth() === getMonthIndex(index)
      );

      if (newsRef) {
        newsRef.scrollIntoView({ behavior: "auto" });
      }
    };

    useEffect(() => {
      fetch("/what'sNew/news.json")
        .then((response) => response.json())
        .then((data) => setNews(data));
    }, []);

    const filteredNews = news.filter((item) => {
      const [year, month] = item.date.split("-");
      return (
        selectedYear === "" || year === selectedYear
        //  &&
        // (selectedMonth === "" ||
        //   months.indexOf(selectedMonth) + 1 === parseInt(month))
      );
    });

    console.log("filtered News --->", filteredNews);

    // const handleObserver = useCallback((entries) => {
    //   entries.forEach((entry) => {
    //     if (entry.isIntersecting) {
    //       const itemDate = new Date(entry.target.dataset.date);
    //       const month = months[itemDate.getMonth()];
    //       const year = itemDate.getFullYear()
    //       setSelectedMonth(month);
    //       setSelectedYear(year.toString())
    //       setCurrentMonthIndex(itemDate.getMonth());
    //       console.log("itemdata --- observer", itemDate.getMonth())
    //     }
    //   });
    // }, []);

    const handleObserver = useCallback((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const itemDate = new Date(entry.target.dataset.date);
          const month = months[itemDate.getMonth()];

          const year = itemDate.getFullYear();

          setSelectedMonth(month);
          setSelectedYear(year.toString());
          setCurrentMonthIndex(itemDate.getMonth());
          console.log("itemdate month -->", itemDate.getMonth());
        }
      });
    }, []);

    useEffect(() => {
      const observer = new IntersectionObserver(handleObserver, {
        root: null,
        rootMargin: "0px",
        threshold: 0.3,
      });

      newsRefs.current.forEach((ref) => {
        if (ref) observer.observe(ref);
      });

      return () => {
        newsRefs.current.forEach((ref) => {
          if (ref) observer.unobserve(ref);
        });
      };
    }, [filteredNews, handleObserver]);

    const lastFourYears = getLastFourYears();

    const openInNewTab = (itemId) => {
      window.open(`/what's_new/${itemId}`, "_blank");
    };
    const displayedMonths = new Set();
    const firstYear = lastFourYears.length > 0 ? lastFourYears[0] : "";

    useEffect(() => {
      setExpanded(`panel1${firstYear}`);
    }, [firstYear]);

    const Accordion = styled((props) => (
      <MuiAccordion disableGutters elevation={0} square {...props} />
    ))(({ theme }) => ({
      border: `0.1px solid ${theme.palette.divider}`,
      "&:not(:last-child)": {
        borderBottom: 0,
      },
      "&::before": {
        display: "none",
      },
    }));

    const AccordionSummary = styled((props) => (
      <MuiAccordionSummary
        expandIcon={
          <ArrowForwardIosSharpIcon
            sx={{
              fontSize: "1.0rem",
              color: invertDark(theme),
            }}
          />
        }
        {...props}
      />
    ))(({ theme }) => ({
      "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(90deg)",
      },
      "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(1),
      },
    }));

    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
      padding: theme.spacing(2),
      borderTop: "0.1px solid rgba(0, 0, 0, .125)",
    }));
    const [expanded, setExpanded] = React.useState("");

    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };
    return (
      <div className="row-12 d-flex">
        <div className="col-md-3" style={{ borderRadius: "10px" }}>
          <h4>Timeline</h4>
          {lastFourYears.map((year) => (
            <div>
              <Accordion
                expanded={expanded === `panel1${year}`}
                onChange={handleChange(`panel1${year}`)}
                style={{
                  color: invertDark(theme),
                  background: Colors[theme].background,
                  borderColor: Colors[theme].timelineBorder,
                  borderRadius: "3px",
                }}
                onClick={() => {
                  setSelectedYearNew(year.toString());
                  setSelectedMonth("");
                  setSelectedYear(year.toString());
                  setCollapsable(!collapsable);
                  // setCurrentMonthIndex("")
                }}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id={`panel1d-header${year}`}
                  style={{ borderColor: "red" }}
                >
                  <Typography>{year}</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ borderColor: "#F3F7EC" }}>
                  <Typography>
                    <div className="month-list">
                      {monthsByYear[year] && monthsByYear[year].length > 0 ? (
                        months.map((month, index) => {
                          return (
                            <div
                              key={index}
                              className={`month-item ${
                                index === months.indexOf(months[months.length - 1]) - currentMonthIndex &&
                                selectedYear === year.toString()
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() =>
                                handleMonthClick(month, year, index)
                              }
                            >
                              <span className="month-indicator"></span>
                              {month}
                            </div>
                          );
                        })
                      ) : (
                        <span>No months for {year}</span>
                      )}
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          ))}{" "}
        </div>
        <div className="col-md-9 px-4 news-list h-100 py-3">
          <h5 className="px-3">{selectedYearNew}</h5>
          {filteredNews.length > 0 ? (
            filteredNews.reverse().map((dayNews, index) => {
              const { date, news: items, version } = dayNews;
              const itemDate = new Date(date);
              const monthYear = `${itemDate.getMonth()}-${itemDate.getFullYear()}`;
              const shouldDisplayDate = !displayedMonths.has(monthYear);

              if (shouldDisplayDate) {
                displayedMonths.add(monthYear);
              }
              return (
                <div
                  ref={(el) => (newsRefs.current[index] = el)}
                  key={date}
                  data-date={date}
                >
                  <div className="row-12 d-flex align-items-start">
                    <div
                      className="d-flex col-12 col-md-11"
                      style={{ position: "relative" }}
                    >
                      {shouldDisplayDate && (
                        <h5
                          className=""
                          style={{ paddingTop: 31, position: "absolute" }}
                        >
                          {itemDate.toLocaleDateString("en-US", {
                            month: "short",
                          })}
                        </h5>
                      )}
                      <div className="timeline w-100">
                        <div className="timeline-item">
                          <div className="d-flex justify-content-between align-items-center">
                            <h5 className="timeline-content">
                              {items.length === 0
                                ? itemDate.toLocaleDateString("en-US", {
                                    month: "short",
                                    year: "numeric",
                                  })
                                : itemDate.toLocaleDateString("en-US", {
                                    month: "short",
                                    day: "numeric",
                                    year: "numeric",
                                  })}
                            </h5>
                            <h6>Version - {version}</h6>
                          </div>
                          {items.length === 0 ? (
                            <div style={{ padding: "150px 0px 75px 120px" }}>
                              <span
                                style={{ fontSize: "20px" }}
                                className="ms-3"
                              >
                                No new item for the selected month.
                              </span>
                            </div>
                          ) : (
                            items.map((item) => (
                              <div key={item.id} className="">
                                <div className="timeline-content">
                                  <div className="d-flex">
                                    <span>
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "20px",
                                        }}
                                      >
                                        {item.title}
                                      </span>
                                      <span
                                        className="badge ms-2 p-1"
                                        style={{
                                          backgroundColor:
                                            item.status === "UPDATE"
                                              ? "#304FFE"
                                              : item.status === "NEW"
                                              ? "#388E3C"
                                              : "#B71C1C",
                                          borderRadius: "4px",
                                        }}
                                      >
                                        {item.status}
                                      </span>
                                    </span>
                                  </div>
                                  <br />
                                  {item.img ? (
                                    <div className="text-start py-3">
                                      <img
                                        src={item.img}
                                        alt="detail image"
                                        width={550}
                                        height={280}
                                        style={{ borderRadius: 5 }}
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {item.text ? (
                                    <p>
                                      {item.text
                                        .split("\n")
                                        .map((line, index) => (
                                          <span key={index}>
                                            {line}
                                            <br />
                                          </span>
                                        ))}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            ))
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="row-12 d-flex align-items-start">
              <div className="col-md-1">
                <h5 className="px-3" style={{ paddingTop: 31 }}>
                  {selectedMonth.slice(0, 3)}
                </h5>
              </div>
              <div className="d-flex col-12 col-md-11">
                <div className="timeline w-100">
                  <div className="timeline-item">
                    <div className="timeline-content">
                      <span style={{ fontSize: "20px" }}>
                        No new item for the selected month and year.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  })
);
