import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { My_Button, My_DatePicker, My_Input, Selector } from "../../Tools";
import RD4 from './images/4RD.png'
import RD5 from './images/5RD.png'
import RD9 from './images/9RD.png'
import RD10 from './images/10RD.png'
import { toJS } from "mobx";
import { useTranslation } from "react-i18next";
import { useMediaPredicate } from "react-media-hook";

export const CreateNewAssetModal = inject("store")(
    observer((props) => {
        const {t} = useTranslation("lite-manual-entry")
        const { theme } = props;

        const {
            _handleTextChange,
            entryData,
            fuelTypeOption,
            reportTypeOption,
            createNewAsset,
            _handleCheckType,
            selectedType
        } = props.store.liteEntry;
        const isMobile = useMediaPredicate("(min-width: 250px) and (max-width: 500px)");

        const vehicleType = [
            {
                description: t('Light Goods Vehicle')+' (LGV) - MLW ≤ 3,500kg',
                subgroup: [
                    { type: '4UD', image: RD4, baselineEmission: 814.1, index: 0 },
                    { type: '4RD', image: RD4, baselineEmission: 814.1, index: 1 }
                ]
            },
            {
                description: t('Heavy Goods Vehicle') +'(HGV) - 3,500kg < MLW ≤ 16,000kg',
                subgroup: [
                    { type: '5RD', image: RD5, baselineEmission: 861.7, index: 2 },
                    { type: '9RD', image: RD9, baselineEmission: 873.3, index: 3 }
                ]
            },
            {
                description: t('Very Heavy Goods Vehicle')+' (VHGV) - MLW > 16,000kg',
                subgroup: [
                    { type: '10RD', image: RD10, baselineEmission: 854.1, index: 4 }
                ]
            },
        ]

        

        return (
            <div
                className="modal fade overflow-auto show"
                id="createNewAssetModal"
                tabIndex="-1"
                aria-labelledby="createNewAssetModal"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xl rounded overflow-auto" style={{height: isMobile && '1600px'}}>
                    <div
                        className="modal-content overflow-auto"
                        style={{
                            background: theme === 'dark' ? '#191F40' : '#ffffff',
                            color: invertDark(theme),
                        }}
                    >
                        <div
                            className="modal-header"
                            style={{ borderBottom: `1px solid rgb(255,255,255,0.2)` }}
                        >
                            <span style={{ fontSize: 20 }}>{t("Create Report")}</span>
                            <span data-bs-dismiss="modal" style={{ cursor: "pointer" }}>
                                <i className="bi bi-x-lg" />
                            </span>
                        </div>
                        <div className="modal-body row">
                            <div className="col-12 col-xl-6 col-md-12 col-xs-12 col-sm-12">
                                <div className="mb-4">
                                    {t("Report Type")}
                                    <Selector menuListColor='black'
                                        value={reportTypeOption.filter(v => v.value === entryData.report_type)}
                                        _handleSelect={(e) => _handleTextChange(e, 'report_type')} options={reportTypeOption} />
                                </div>
                                {entryData.report_type === 'Monthly' ? <div id='time' className="mb-4">{t("Month")}
                                    <My_DatePicker height={38} dateFormat='MMMM' selected={entryData.period_start}
                                        noTimeInput showMonthYearPicker onChange={(e) => _handleTextChange(e, 'month')} />
                                </div> :
                                    <div id='time' className="mb-4">{t("Year")}
                                        <My_DatePicker height={38} dateFormat='yyyy' selected={entryData.period_start}
                                            noTimeInput showYearPicker onChange={(e) => _handleTextChange(e, 'year')} />
                                    </div>}

                                <div className="mb-4">
                                    {t("Fuel Type")}
                                    <Selector
                                        menuListColor="black"
                                        value={fuelTypeOption.filter(
                                            (v) => v.value === entryData.type
                                        )}
                                        _handleSelect={(e) => _handleTextChange(e, "type")}
                                        options={fuelTypeOption}
                                    />
                                </div>

                                {entryData.categorization === 'individual' &&
                                    <div className="mb-4">
                                        {t("Vehicle Plate Number")}
                                        <My_Input
                                            height="38px"
                                            id="asset_id"
                                            value={entryData.asset_id}
                                            onChange={(e) => _handleTextChange(e, "asset_id_input")}
                                        />
                                    </div>}
                                <div className="mb-4 ">
                                    {entryData.type === 'Battery' ? t('Energy Consumption (kWh)') : t('Fuel Consumption (L)')}
                                    <My_Input height="38px" type='number' id='consumed' value={entryData.consumed} onChange={(e) => _handleTextChange(e, 'consumed')} />
                                </div>

                                <div className="mb-4">
                                    {t("Total Mileage")} (km)
                                    <My_Input height="38px" type='number' id='mileage' value={entryData.mileage} onChange={(e) => _handleTextChange(e, 'mileage')} />
                                </div>


                                <div>
                                    {t("Remark")}
                                    <div className="d-flex flex-col flex-grow-1">
                                        <textarea onChange={(e) => _handleTextChange(e, 'remark')} value={entryData.remark} type='textarea'
                                            rows="4" style={{ width: '100%', background: theme === 'dark' && Colors.darkBlue, borderRadius: 3, color: invertDark(theme) }} />
                                    </div>
                                </div>
                            </div>


                            <div className="col-12 col-xl-6 col-md-12 col-xs-12 col-sm-12">
                                <div className="mb-4 col-12">
                                    <span className="assetCategorization">{t("Asset Categorization")}</span>
                                    
                                    <div className="d-flex align-items-center px-1 col-12" style={{ height: 38, background: 'rgb(217,217,217,0.17)', borderRadius: 3 }}>
                                        <div className="col-5 col-md-6 col-xl-6 col-sm-6 col-xs-6">
                                            <label className="" style={{ cursor: 'pointer' }}>
                                                <input onChange={(e) => _handleTextChange(e, 'categorization')} value='aggregate' className="me-2" id='aggregate' name='categorization' style={{ cursor: 'pointer' }} type='radio' />
                                                <span className="fleetAggregate">{t("Fleet Aggregate")}</span>
                                                
                                            </label>
                                        </div>
                                        <div className="col-7 col-md-6 col-xl-6 col-sm-6 col-xs-6">
                                            <label className="" style={{ cursor: 'pointer' }}>
                                                <input onClick={(e) => _handleTextChange(e, 'categorization')} value='individual' defaultChecked className="me-2" id='individual' name='categorization' style={{ cursor: 'pointer' }} type='radio' />
                                                <span className="individualAsset">{t("Individual Asset")}</span>
                                                
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-3 col-12">
                                    {t("Vehicle Type")}<i style={{ color: 'yellow', cursor: 'pointer' }} title='MLW refers Maximum Laden Weight' className="bi bi-info-square ps-2 reduceOpacityOnhover"></i>
                                    <VehicleType selectedType={selectedType} _handleCheckType={_handleCheckType} vehicleType={vehicleType} entryData={entryData} theme={theme} />
                                </div>
                            </div>


                            <div className="text-center mt-4 mt-sm-0 ">
                                <My_Button
                                    onClick={() => createNewAsset()}
                                    customHoverColor="#0d6efde0"
                                    customColor="#0d6efd"
                                    style={{ height: 40, width: "20%" }}
                                    text={t("Create")}

                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    })
);

export const VehicleType = inject("store")(
    observer((props) => {
        const { vehicleType, selectedType } = props;
        const { theme } = props;
        const { entryData, _handleCheckType, _handleTextChange } = props.store.liteEntry;

        console.log(toJS(entryData.vehicle_type), toJS(selectedType));

        if (entryData.categorization === 'individual')
            return (
                <div className="rounded">
                    {vehicleType.map((v, k) => (
                        <div className="rounded" key={k}
                            style={{ background: theme === 'dark' ? 'rgb(255,255,255,0.05)' : 'rgb(251,251,251,0.75)', cursor: 'pointer', minHeight: 120, marginBottom: 10 }}
                        >
                            <div className="justify-content-center d-flex align-items-center"
                                style={{
                                    fontSize: 12,
                                    color: invertDark(theme),
                                    background: theme === 'dark' ? 'rgb(217,217,217,0.05)' : 'rgb(241 241 241)',
                                    borderTopLeftRadius: 5,
                                    borderTopRightRadius: 5
                                }}
                            >
                                <span className="p-2">{v.description}</span></div>
                            <div className="d-flex row justify-content-center gap-4 p-2">
                                {

                                    v.subgroup.map((v1, k1) => (
                                        <>
                                            <div key={k1} className="col-4 col-xl-5 col-lg-5 col-md-5 col-sm-6 col-xs-4">
                                                <label className="p-1 rounded w-100 w-sm-100 w-xs-100 h-100 position-relative rounded"
                                                    style={{ background: theme === 'dark' ? 'rgb(217,217,217,0.17)' : 'rgb(149,149,149,0.17)', cursor: 'pointer', minHeight: 120 }}>
                                                    <div className="d-flex m-1">
                                                        <input
                                                            onChange={() => _handleCheckType(v1.type, 'radio')}
                                                            className="me-1" type='radio' name='type' />
                                                        <div className="vTypeFont" style={{color: invertDark(theme) }}>
                                                            {v1.type}
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-center mt-3 position-relative">
                                                        <img style={{ height: '45px', transition: 'all 0.5s' }} src={v1.image} />
                                                    </div>
                                                </label>
                                            </div>
                                            {v1.type === '10RD' && <div className="col-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-4" style={{marginLeft: '20%'}} />}
                                        </>
                                    ))
                                }

                            </div>
                        </div>
                    ))}

                </div>
            )
        else
            return (
                <div className=" rounded">
                    {vehicleType.map((v, k) => (
                        <div className="rounded" key={k}
                            style={{
                                background: theme === 'dark' ? 'rgb(255,255,255,0.05)' : 'rgb(251,251,251,0.75)',
                                cursor: 'pointer',
                                minHeight: 120,
                                marginBottom: 10
                            }}
                        >
                            <div className="justify-content-center d-flex align-items-center"
                                style={{
                                    fontSize: 12,
                                    color: invertDark(theme),
                                    background: theme === 'dark' ? 'rgb(255,255,255,0.05)' : 'rgb(241 241 241)',
                                    borderTopLeftRadius: 5,
                                    borderTopRightRadius: 5
                                }}
                            >
                                <span className="p-2">{v.description}</span></div>
                            <div className="d-flex row justify-content-center gap-4 p-2">
                                {
                                    v.subgroup.map((v1, k1) => (<>
                                        <div key={k1} className="col-4 col-xl-5 col-lg-5 col-md-5 col-sm-6 col-xs-4">
                                            <label className="p-1 w-100 h-100 position-relative rounded"
                                                style={{ background: theme === 'dark' ? 'rgb(217,217,217,0.17)' : 'rgb(149,149,149,0.17)', cursor: 'pointer', minHeight: 120 }}
                                            >
                                                <div className="d-flex m-1" style={{width:''}}>
                                                    <input id='checkbox' checked={entryData.vehicle_type?.vehicleType === v1.type || v1.type === (selectedType.find(v2 => v1.type === v2))} onChange={() => _handleCheckType(v1.type, 'checkbox')} className="me-1" type='checkbox' name='type' />
                                                    <div className="vTypeFont" style={{color: invertDark(theme) }}>
                                                        {v1.type}
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-center mt-3">
                                                    <img style={{ height: selectedType.find(v2 => v1.type === v2) ? '35px' : '45px', transition: 'all 0.5s' }} src={v1.image} />
                                                </div>

                                                {v1.type === (selectedType.find(v2 => v1.type === v2)) ?
                                                    <div style={{
                                                        height: 28,
                                                        opacity: 1,
                                                        display: 'block',
                                                        transition: 'all 0.5s',
                                                    }} className="d-flex gap-1 align-items-center mt-2 px-2">
                                                        <span style={{ fontSize: 12 }}>Quantity</span>
                                                        <My_Input id={v1.index} value={entryData.vehicle_type[v1.index]?.noOfVehicle} onChange={(e) => _handleTextChange({ value: e.target.value, index: v1.index }, 'vehicleQuantity')} height="28px" type='number' />
                                                    </div>
                                                    :
                                                    <div style={{
                                                        height: 0,
                                                        transition: 'all 0.5s',
                                                        opacity: 0
                                                    }} className="d-flex gap-1 align-items-center mt-2 px-2">
                                                        <span style={{ fontSize: 12 }}>Quantity</span><div style={{ border: '1px solid gray', width: '100%' }} /></div>
                                                }
                                            </label>
                                        </div>{v1.type === '10RD' && <div className="col-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-6"  style={{marginLeft: '20%'}} />}</>
                                    ))
                                }
                            </div>
                        </div>
                    ))}

                </div >
            )
    }))
