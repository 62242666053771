/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { My_Button, My_Input, My_upload_image, Selector } from "../../Tools";
import Store from "../../Store";
import allCountry from "../../Assets/countries.json";
import { Checkbox } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { typeOptions } from "./TypeOptions";
import { UploadImage } from "./UploadImage";
import { useHistory } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import e from "cors";

export const NewCreateNewCompanyModal = inject("store")(
  observer((props) => {
    const { t } = useTranslation("admin-panel");
    const { theme, setShowModal } = props;
    const history = useHistory();
    const data = Store.adminPanel.userList;
    const { role, companyId } = props.store.common;
    const {
      level,
      _handleInputChange,
      _handleInputChangeNew,
      accountData,
      userData,
      companyInfo,
      _handleCompanyInputChange,
      _handleCloseModel,
      _handleCreateCompany,
      _handleUploadImage,
      photo,
      _handleChangeRegisterDate,
      _handleChangeExpireDate,
      _handleFormClear,
      _handleNewCreateNewCompany,
      _handleCheckBox,
      _handlegonextpage,
      _handleRequiredField,
      requiredFields,
      _handleTextCreateCompanyChange,
      validation,
      currentPage,
      handleNextPage,
      handlePrevPage,
      // handleClose,
    } = props.store.adminPanel;

    const [phoneNumber, setPhoneNumber] = useState("");

    const CountryOptions = allCountry?.map((country) => ({
      value: country.name,
      label: country.name,
      code: country.code.toLowerCase(),
    }));

    const levels = ["1", "2", "3", "4", "5"];

    const higherLevels = levels.filter((v) => v > level);

    const levelOptions = higherLevels.map((d, i) => ({
      value: d,
      label: d,
    }));

    const companySizeOptions = [
      {
        value: "1 - 9",
        label: "1 - 9",
      },
      {
        value: "10 - 49",
        label: "10 - 49",
      },
      {
        value: "50 - 249",
        label: "50 - 249",
      },
      {
        value: "250 - 499",
        label: "250 - 499",
      },
      {
        value: "500 and above",
        label: "500 and above",
      },
    ];

    const companyRevenueOptions = [
      {
        value: "Below 1 million",
        label: "Below 1 million",
      },
      {
        value: "1 - 3 million",
        label: "1 - 3 million",
      },
      {
        value: "3 - 5 million",
        label: "3 - 5 million",
      },
      {
        value: "5 - 50 million",
        label: "5 - 50 million",
      },
      {
        value: "Above 50 million",
        label: "Above 50 million",
      },
    ];

    const validateForm = () => {
      const requiredFields = [
        companyInfo.name,
        companyInfo.country,
        companyInfo.company_incorporation,
        companyInfo.level,
        companyInfo.address,
        companyInfo.website,
        companyInfo.company_size,
        companyInfo.revenue,
        companyInfo.sector,
        companyInfo.isRegisterToESGpedia,
        companyInfo.isDeclarationOfLegalBusiness,
        companyInfo.isCompact_signup,
        companyInfo.isWrittenEnv_policy,
      ];

      // console.log(requiredFields, 'requiedfields===>')

      return requiredFields.every((field) => !!field);
    };

    useEffect(() => {
      validateForm();
    }, [companyInfo]);

    const validateForm2 = () => {
      const requiredFieldsPage2 = [
        accountData.email,
        accountData.designation,
        accountData.first_name,
        accountData.last_name,
        accountData.password,
        accountData.confirmPassword,
      ];

      return requiredFieldsPage2.every((field) => !!field);
    };

    // const validateForm2 = () => {

    //     const requiredFieldsPage2 = [
    //         accountData.email,
    //         accountData.designation,
    //         accountData.first_name,
    //         accountData.last_name,
    //         accountData.password,
    //         accountData.confirmPassword,
    //     ];

    //     if (requiredFieldsPage2.every((field) => !field)) {
    //         alert("Please fill in all required fields.");
    //         return false;
    //     }

    //     const writtenemail = accountData.email.toLowerCase();
    //     if (writtenemail.includes('@gmail.com') || writtenemail.includes('@outlook.com')) {
    //         alert("Email addresses from Gmail and Outlook are not allowed.");
    //         return false;
    //     }

    //     return true;
    // };

    const handleClose = () => {
      setShowModal(false);
    };

    const [selectedCountryPage1, setSelectedCountryPage1] = useState("");
    const [selectedCountryPage2, setSelectedCountryPage2] = useState("");
    // ... other state variables for selectors on different pages

    console.log(props.show, "propshow=====>");

    console.log("blllla",companyInfo.isRegisterToESGpedia)
    return (
      <Modal
        className=""
        show={props.show}
        // onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="custom-modal-width"
      >
        <Modal.Header
          style={{
            color: theme === "dark" ? "#ffffff" : "#000",
            backgroundColor: theme === "dark" ? "#1B2350" : "#fff",
            border: "none",
          }}
        >
          <Modal.Title className="fs-12">
            {currentPage === 1 ? "Create New Company" : "Create Company Admin"}
          </Modal.Title>

          <span style={{ cursor: "pointer" }} onClick={() => handleClose()}>
            <i className="bi bi-x-lg" />
          </span>
        </Modal.Header>

        <Modal.Body
          style={{
            color: theme === "dark" ? "#fff" : "#000",
            backgroundColor: theme === "dark" ? "#1B2350" : "#fff",
            paddingTop: "none",
            marginTop: "none",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {currentPage === 1 ? (
            <div className="w-100">
              <div className="p-0 fs-5">{t("Company Information")}</div>

              <div className="row mx-2">
                <div
                  className="col-6"
                  style={{
                    borderRight:
                      theme === "dark"
                        ? "1.5px solid #676767"
                        : "1.5px solid rgb(219 217 217)",
                  }}
                >
                  <div className="w-100 d-flex justify-content-center">
                    <div
                      className="col-6 py-10 px-4 mt-4"
                      style={{ height: "240px" }}
                    >
                      <UploadImage
                        id="uploadImage"
                        photo={photo}
                        style={{ height: "" }}
                      />
                    </div>
                  </div>

                  <div className="d-flex align-items-center pb-3">
                    <span className="col-5">{t("Company Phone Number")}</span>
                    <span
                      className="col-7"
                      onChange={() =>
                        _handleCompanyInputChange({ phone: phoneNumber })
                      }
                    >
                      <PhoneInput
                        international
                        country={"sg"}
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                        inputStyle={{
                          background: theme === "dark" && Colors.darkBlue,
                          color: invertDark(theme),
                          width: "100%",
                          paddingTop: 10,
                          paddingBottom: 10,
                          fontSize: 14,
                          height: "40px",

                          boxShadow: "none",
                          shapeOutline: "none",
                          outline: "none",
                          border: `1px solid ${Colors.secondaryColor}`,
                          borderRadius: 5,
                        }}
                        buttonStyle={{
                          background: theme === "dark" && Colors.darkBlue,
                          color: invertDark(theme),
                        }}
                        dropdownStyle={{
                          width: "250px",
                          background: theme === "dark" && Colors.darkBlue,
                          color: invertDark(theme),
                        }}
                      />
                    </span>
                  </div>
                  <div className="d-flex align-items-center pb-3">
                    <span className="col-5">{t("Ascent Account ID")}</span>
                    <span className="col-7">
                      <My_Input
                        id={"companyName"}
                        value={companyInfo.ascentAccountId}
                        placeHolder={"Enter ascent account id..."}
                        onChange={(e) =>
                          _handleCompanyInputChange({
                            ascentAccountId: e.target.value,
                          })
                        }
                      />
                    </span>
                  </div>

                  <div className="d-flex align-items-center pb-3">
                    <span className="col-5">{t("Company LEI Number")}</span>
                    <span className="col-7">
                      <My_Input
                        // type={'number'}
                        id={"companyLeiNumber"}
                        value={companyInfo.leiNo}
                        placeHolder={"Enter company LEI number..."}
                        onChange={(e) =>
                          _handleCompanyInputChange({ leiNo: e.target.value })
                        }
                      />
                    </span>
                  </div>

                  <div className="d-flex align-items-center pb-3">
                    <span className="col-5">
                      <span style={{ color: "#ffc107" }}>*</span>{" "}
                      {t(
                        "Company Registration Number (Company in UEN context)"
                      )}
                    </span>
                    <span className="col-7">
                      <My_Input
                        // type={"number"}
                        id={"registration_number"}
                        value={companyInfo.registration_number}
                        placeHolder={"Enter company registration number..."}
                        onChange={(e) =>
                          _handleCompanyInputChange({
                            registration_number: e.target.value,
                          })
                        }
                      />
                    </span>
                  </div>

                  <div className="d-flex align-items-center pb-3">
                    <span style={{ cursor: "pointer" }} className="me-3">
                      <Checkbox
                        style={{ color: Colors.activeColor, padding: "0px" }}
                        onClick={(e) => {
                          _handleCompanyInputChange({
                            isDeclarationOfLegalBusiness: e.target.checked,
                          });
                          validateForm(true);
                        }}
                        checked={
                          companyInfo?.isDeclarationOfLegalBusiness === true
                            ? true
                            : false
                        }
                      />
                    </span>
                    <span style={{ cursor: "pointer" }}>
                      <span style={{ color: "#ffc107" }}>*</span>{" "}
                      {t("Company declares it is a legal business.")}
                    </span>
                  </div>
                </div>

                <div className="col-6" style={{ marginTop: "-0.7em" }}>
                  <div className="d-flex align-items-center pb-3">
                    <span className="col-5">
                      <span style={{ color: "#ffc107" }}>*</span> {t("Name")}
                    </span>
                    <span className="col-7">
                      <My_Input
                        id={"companyName"}
                        value={companyInfo.name}
                        placeHolder={"Enter company name..."}
                        onChange={(e) => {
                          _handleCompanyInputChange({ name: e.target.value });
                          validateForm(true);
                        }}
                      />
                    </span>
                  </div>

                  <div className="d-flex align-items-center pb-3">
                    <span className="col-5">
                      <span style={{ color: "#ffc107" }}>*</span> {t("Country")}
                    </span>
                    <span className="col-7">
                      <Selector
                        id="country"
                        options={CountryOptions}
                        value={CountryOptions.find(
                          (v) => v.value === companyInfo.country
                        )}
                        menuListColor="black"
                        _handleSelect={(e) => {
                          _handleCompanyInputChange({ country: e.value });
                          validateForm(true);
                        }}
                      />
                    </span>
                  </div>

                  <div className="d-flex align-items-center pb-3">
                    <span className="col-5">
                      <span style={{ color: "#ffc107" }}>*</span>{" "}
                      {t("Incorporation")}
                    </span>
                    <span className="col-7">
                      <Selector
                        id="company_incorporation"
                        options={CountryOptions}
                        value={CountryOptions.find(
                          (v) => v.value === companyInfo.company_incorporation
                        )}
                        menuListColor="black"
                        _handleSelect={(e) => {
                          _handleCompanyInputChange({
                            company_incorporation: e.value,
                          });
                          validateForm(true);
                        }}
                      />
                    </span>
                  </div>

                  <div className="d-flex align-items-center pb-3">
                    <span className="col-5">
                      <span style={{ color: "#ffc107" }}>*</span>{" "}
                      {t("Company Level")}
                    </span>
                    <span className="col-7">
                      <Selector
                        id="level"
                        options={levelOptions}
                        value={levelOptions.find(
                          (v) => v.value === companyInfo.level
                        )}
                        menuListColor="black"
                        _handleSelect={(e) => {
                          _handleCompanyInputChange({ level: e.value });
                          validateForm(true);
                        }}
                      />
                    </span>
                  </div>

                  <div className="d-flex align-items-center pb-3">
                    <span className="col-5">
                      <span style={{ color: "#ffc107" }}>*</span>{" "}
                      {t("Company Address")}
                    </span>
                    <span className="col-7">
                      <My_Input
                        // type={'number'}
                        id={"companyAddress"}
                        value={companyInfo.address}
                        placeHolder={"Enter company address..."}
                        onChange={(e) => {
                          _handleCompanyInputChange({
                            address: e.target.value,
                          });
                          validateForm(true);
                        }}
                      />
                    </span>
                  </div>

                  <div className="d-flex align-items-center pb-3">
                    <span className="col-5">
                      <span style={{ color: "#ffc107" }}>*</span>{" "}
                      {t("Company Website")}
                    </span>
                    <span className="col-7">
                      <My_Input
                        // type={'number'}
                        id={"companyWebsite"}
                        value={companyInfo.website}
                        placeHolder={"Enter company website..."}
                        onChange={(e) => {
                          _handleCompanyInputChange({
                            website: e.target.value,
                          });
                          validateForm(true);
                        }}
                      />
                    </span>
                  </div>

                  <div className="d-flex align-items-center pb-3">
                    <span className="col-5">
                      <span style={{ color: "#ffc107" }}>*</span>{" "}
                      {t("Company Size")}
                    </span>
                    <span className="col-7">
                      <Selector
                        id="company_size"
                        options={companySizeOptions}
                        value={companySizeOptions.find(
                          (v) => v.value === companyInfo.company_size
                        )}
                        menuListColor="black"
                        _handleSelect={(e) => {
                          _handleCompanyInputChange({ company_size: e.value });
                          validateForm(true);
                        }}
                      />
                    </span>
                  </div>

                  <div className="d-flex align-items-center pb-3">
                    <span className="col-5">
                      <span style={{ color: "#ffc107" }}>*</span>{" "}
                      {t("Company Revenue")}
                    </span>
                    <span className="col-7">
                      <Selector
                        id="revenue"
                        options={companyRevenueOptions}
                        value={companyRevenueOptions.find(
                          (v) => v.value === companyInfo.revenue
                        )}
                        menuListColor="black"
                        _handleSelect={(e) => {
                          _handleCompanyInputChange({ revenue: e.value });
                          validateForm(true);
                        }}
                      />
                    </span>
                  </div>

                  <div className="d-flex align-items-center pb-3">
                    <span className="col-5">
                      <span style={{ color: "#ffc107" }}>*</span>{" "}
                      {t("Industry Sector")}
                    </span>
                    <span className="col-7">
                      <Selector
                        id="sector"
                        options={typeOptions}
                        value={typeOptions.find(
                          (v) => v.value === companyInfo.sector
                        )}
                        menuListColor="black"
                        _handleSelect={(e) => {
                          _handleCompanyInputChange({ sector: e.value });
                          validateForm(true);
                        }}
                      />
                    </span>
                  </div>

                  <div className="row d-flex justify-content-between">
                    <div className="d-flex align-items-center pb-4 row col-6">
                      <span className="col-12">
                        <span style={{ color: "#ffc107" }}>*</span>{" "}
                        {t("UN Global Compact SignUp")}
                      </span>
                      <span className="col-12">
                        <span className="d-flex align-items-center">
                          <span
                            className="d-flex align-items-center pe-3"
                            onClick={() => {
                              _handleCompanyInputChange({
                                isCompact_signup: "YES",
                              });
                              validateForm(true);
                            }}
                          >
                            <Checkbox
                              style={{
                                color: Colors.activeColor,
                                padding: "0px",
                                marginLeft: "0.7em",
                              }}
                              checked={
                                companyInfo?.isCompact_signup === "YES"
                                  ? true
                                  : false
                              }
                            />
                            <span
                              className="ms-1"
                              style={{ cursor: "pointer" }}
                            >
                              {t("YES")}
                            </span>
                          </span>
                          <span
                            className="d-flex align-items-center"
                            onClick={() => {
                              _handleCompanyInputChange({
                                isCompact_signup: "NO",
                              });
                              validateForm(true);
                            }}
                          >
                            <Checkbox
                              style={{
                                color: Colors.activeColor,
                                padding: "0px",
                                marginLeft: "1em",
                              }}
                              checked={
                                companyInfo?.isCompact_signup === "NO"
                                  ? true
                                  : false
                              }
                            />
                            <span
                              className="ms-1"
                              style={{ cursor: "pointer" }}
                            >
                              {t("NO")}
                            </span>
                          </span>
                        </span>
                      </span>
                    </div>

                    <div className="d-flex align-items-center pb-3 row col-6">
                      <span className="col-12">
                        <span style={{ color: "#ffc107" }}>*</span>{" "}
                        {t("Environmental Policy")}
                      </span>
                      <span className="col-12">
                        <span className="d-flex align-items-center">
                          <span
                            className="d-flex align-items-center pe-3"
                            onClick={() => {
                              _handleCompanyInputChange({
                                isWrittenEnv_policy: "YES",
                              });
                              validateForm();
                            }}
                          >
                            <Checkbox
                              style={{
                                color: Colors.activeColor,
                                padding: "0px",
                                marginLeft: "0.7em",
                              }}
                              checked={
                                companyInfo?.isWrittenEnv_policy === "YES"
                                  ? true
                                  : false
                              }
                            />
                            <span
                              className="ms-1"
                              style={{ cursor: "pointer" }}
                            >
                              {t("YES")}
                            </span>
                          </span>
                          <span
                            className="d-flex align-items-center"
                            onClick={() => {
                              _handleCompanyInputChange({
                                isWrittenEnv_policy: "NO",
                              });
                              validateForm();
                            }}
                          >
                            <Checkbox
                              style={{
                                color: Colors.activeColor,
                                padding: "0px",
                                marginLeft: "1em",
                              }}
                              checked={
                                companyInfo?.isWrittenEnv_policy === "NO"
                                  ? true
                                  : false
                              }
                            />
                            <span
                              className="ms-1"
                              style={{ cursor: "pointer" }}
                            >
                              {t("NO")}
                            </span>
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center pb-3">
                    <span style={{ cursor: "pointer" }} className="ms-2 me-1">{" "}
                      <Checkbox
                      className="p-1"
                        style={{ color: Colors.activeColor, padding: "0px" }}
                        onClick={(e) => {
                          _handleCompanyInputChange({
                            isRegisterToESGpedia: e.target.checked,
                          });
                          validateForm(true);
                        }}
                        checked={
                          companyInfo?.isRegisterToESGpedia === true
                            ? true
                            : false
                        }
                      />
                    </span>
                    <span style={{ cursor: "pointer" }}>
                      {/* <span style={{ color: "#ffc107" }}>*</span>{" "} */}
                      {t("Register to ESGpedia.")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="d-flex align-content-center">
              {/* <div className="p-2">{t("Create Company Admin")}</div> */}

              <div className="py-5 px-5 row" style={{ maxWidth: "700px" }}>
                <div className="d-flex align-items-center pb-3">
                  <span className="col-4" style={{ fontSize: 20 }}>
                    <span style={{ color: "#ffc107" }}>*</span> {t("Email")}
                  </span>
                  <span className="col-8">
                    <My_Input
                      id={"email"}
                      required={false}
                      value={accountData.email}
                      height="50px"
                      placeHolder={"Enter email"}
                      // onChange={(e) => {
                      //     _handleInputChangeNew({ email: e.target.value });
                      //     validateForm2()
                      // }}

                      onChange={(e) => {
                        const inputEmail = e.target.value;
                        _handleInputChangeNew({ email: inputEmail });
                        validateForm2();

                        const gmailRegex = /@gmail\.com$/i;
                        const outlookRegex = /@outlook\.com$/i;

                        const feedbackElement = document.getElementById(
                          "email-validation-feedback"
                        );

                        if (inputEmail === "") {
                          feedbackElement.textContent = "";
                        } else if (
                          inputEmail.indexOf("@gmail.com") === -1 &&
                          inputEmail.indexOf("@outlook.com") === -1
                        ) {
                          feedbackElement.textContent = "";
                        } else if (
                          !gmailRegex.test(inputEmail) &&
                          !outlookRegex.test(inputEmail)
                        ) {
                        } else {
                          feedbackElement.textContent =
                            "This type of email address is not allowed.";
                        }
                      }}
                    />
                    <div
                      id="email-validation-feedback"
                      style={{ color: "red" }}
                    ></div>
                  </span>
                </div>
                <div className="d-flex align-items-center pb-3">
                  <span className="col-4" style={{ fontSize: 20 }}>
                    <span style={{ color: "#ffc107" }}>*</span>{" "}
                    {t("Designation")}
                  </span>
                  <span className="col-8">
                    <My_Input
                      required={false}
                      id={"designatoin"}
                      value={accountData.designation}
                      height="50px"
                      placeHolder={"Enter job title and designation"}
                      onChange={(e) => {
                        _handleInputChangeNew({ designation: e.target.value });
                        validateForm2();
                      }}
                    />
                  </span>
                </div>
                <div className="d-flex align-items-center pb-3">
                  <span className="col-4" style={{ fontSize: 20 }}>
                    <span style={{ color: "#ffc107" }}>*</span>{" "}
                    {t("First Name")}
                  </span>
                  <span className="col-8">
                    <My_Input
                      required={false}
                      value={accountData.first_name}
                      id={"firstName"}
                      // disabled={editView === true ? true : ''}
                      type="text"
                      height="50px"
                      placeHolder={"Enter first name..."}
                      onChange={(e) => {
                        _handleInputChangeNew({ first_name: e.target.value });
                        validateForm2();
                      }}
                    />
                  </span>
                </div>
                <div className="d-flex align-items-center pb-3">
                  <span className="col-4" style={{ fontSize: 20 }}>
                    <span style={{ color: "#ffc107" }}>*</span> {t("Last Name")}
                  </span>
                  <span className="col-8">
                    <My_Input
                      required={false}
                      id={"lastName"}
                      value={accountData.last_name}
                      type="text"
                      height="50px"
                      placeHolder={"Enter last name..."}
                      onChange={(e) => {
                        _handleInputChangeNew({ last_name: e.target.value });
                        validateForm2();
                      }}
                    />
                  </span>
                </div>

                <div className="d-flex align-items-center pb-3">
                  <span className="col-4" style={{ fontSize: 20 }}>
                    <span style={{ color: "#ffc107" }}>*</span> {t("Password")}
                  </span>
                  <span className="col-8">
                    <My_Input
                      required={false}
                      value={accountData.password}
                      id={"password"}
                      // disabled={editView === true ? true : ''}
                      type={props.store.login.visible ? "text" : "password"}
                      height="50px"
                      placeHolder={"Enter password..."}
                      onChange={(e) => {
                        _handleInputChangeNew({ password: e.target.value });
                        validateForm2();
                      }}
                    />
                    <span
                      style={{
                        float: "right",
                        position: "relative",
                        marginTop: "-56px",
                        marginBottom: "-10px",
                        fontSize: "18px",
                        marginRight: "8px",
                        cursor: "pointer",
                      }}
                      onClick={() => props.store.login._handleView()}
                    >
                      <i
                        className={`fa fa-eye${
                          props.store.login.visible ? "-slash" : ""
                        } py-4 text-secondary`}
                      />
                    </span>
                  </span>
                </div>
                <div className="d-flex align-items-center pb-3">
                  <span className="col-4" style={{ fontSize: 20 }}>
                    <span style={{ color: "#ffc107" }}>*</span>{" "}
                    {t("Confirm Password")}
                  </span>
                  <span className="col-8">
                    <My_Input
                      required={false}
                      id={"confirmPassword"}
                      value={accountData.confirmPassword}
                      type={props.store.login.visible ? "text" : "password"}
                      height="50px"
                      placeHolder={"Confirm password..."}
                      onChange={(e) => {
                        _handleInputChangeNew({
                          confirmPassword: e.target.value,
                        });
                        validateForm2();
                      }}
                    />
                    <span
                      style={{
                        float: "right",
                        position: "relative",
                        marginTop: "-56px",
                        marginBottom: "-10px",
                        fontSize: "18px",
                        marginRight: "8px",
                        cursor: "pointer",
                      }}
                      onClick={() => props.store.login._handleView()}
                    >
                      <i
                        className={`fa fa-eye${
                          props.store.login.visible ? "-slash" : ""
                        } py-4 text-secondary`}
                      />
                    </span>
                  </span>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer
          style={{
            color: theme === "dark" ? "#ffffff" : "#000",
            backgroundColor: theme === "dark" ? "#1B2350" : "#fff",
            border: "none",
          }}
        >
          {currentPage === 1 ? null : (
            <Button variant="secondary" onClick={handlePrevPage}>
              Previous
            </Button>
          )}
          {currentPage === 2 ? (
            <Button
              className={`${theme === "dark" ? "btn-primary" : "btn-success"}`}
              onClick={() => {
                _handleNewCreateNewCompany();
              }}
              disabled={!validateForm2()}
            >
              Create
            </Button>
          ) : (
            <Button
              className={`${theme === "dark" ? "btn-primary" : "btn-success"}`}
              onClick={() => {
                _handlegonextpage();
              }}
            >
              Next
            </Button>
          )}

          <Button
            className=""
            style={{ marginLeft: "1em" }}
            variant="secondary"
            onClick={() => {
              handleClose();
              // handlePrevPage()
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  })
);
