import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { My_Button as MyButton } from "../../Tools";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";
import { Cookies } from "react-cookie";
import moment from "moment";
import { ReportTable } from "./reportTable";
import { toJS } from "mobx";
import { useTranslation } from "react-i18next";

export const CreateReportModal = inject("store")(
    observer((props) => {
        const {t} = useTranslation("lite-manual-entry")
        const dailyRef = React.useRef(null);
        const cookie = new Cookies();
        const companyName = cookie.get("companyName");
    
        const { reportData, reportType, units, startTime, endTime } = props.store.liteEntry;

        const download = async () => {

            const element = dailyRef.current;
            var img = new Image();
            img.src = "/CO2_Logo.png";


            try {
                const canvas1 = await html2canvas(element);
                const dataURL = canvas1.toDataURL("image/png");

                const pdf = new jsPDF("p", undefined, "a4");
                let width = pdf.internal.pageSize.getWidth();
                let height = pdf.internal.pageSize.getHeight();

                pdf.addImage(dataURL, "png", 0, 0, width, height/2);
                pdf.setFontSize(18);
       
                pdf.save('report');
            } catch (e) {
                console.log(e);
            }

        };

        return (
            <div
                className="modal fade overflow-scroll"
                id="createReportModal"
                tabIndex="-1"
                aria-labelledby="createReportModal"
                aria-hidden="true"
            >
                <div className="w-100 d-flex align-items-center justify-content-end py-2 px-3">

                    <MyButton
                        leftIcon={<DownloadIcon className="me-2" fontSize="small" />}
                        small={false}
                        className="px-3 me-3"
                        text={t("Download")}
                        onClick={() => {
                            download();
                        }}
                    />
                    <MyButton
                        leftIcon={<CloseIcon />}
                        small={false}
                        className="close rounded-circle"
                        dataDismiss="modal"
                        onClick={() => { }}
                    />
                </div>
                {/* fixed width for printing pdf */}
                <div className="modal-dialog modal-xl rounded">
                    <div
                        className="modal-content p-3"
                        style={{
                            color: Colors.Dark[1],
                            fontSize: 20,
                        }}
                    >

                        <div className="p-5" ref={dailyRef} >
                            <div style={{ opacity: 0.5 }}><img src='/CO2_Logo.png' width={100} />
                                <div className="ps-2" style={{ fontSize: 40, fontWeight: 'bold' }}>{t("CO2 CONNECT")}</div>
                            </div>
                            <div className="text-center"><h3>{reportType} {t("Report")}</h3></div>
                            <div className="pt-5 d-flex justify-content-between">
                                <span className="fw-bold" style={{ fontSize: 22 }}>{t("Organization")} : {companyName}</span><span className="">Issuance Date : {reportData?.issuance_date || 'N/A'}</span>
                            </div>
                            <div className="row pt-3">
                                <span className="col-2">{t("Vehicle ID")}</span><span className="col-1 text-end">:</span><span className="col-9">{reportData?.data?.asset_id || 'N/A'}</span>
                                <span className="col-2">{t("Time Period")}</span><span className="col-1 text-end">:</span><span className="col-9">{(reportData?.data?.period_start || 'N/A') + ' to ' + (reportData?.data?.period_end || 'N/A')}</span>
                                <span className="col-2">{t("Fuel Type")}</span><span className="col-1 text-end">:</span>
                                <span className="col-9">
                                    {reportData?.data?.type || 'N/A'}
                                </span>
                            </div>
                            <ReportTable reportData={reportData} fuelType='Diesel' />
                        </div>



                    </div>
                </div>
            </div >
        );

    })
);
