import React, { useEffect, useState } from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { inject, observer } from "mobx-react";
import { Button, Modal } from "react-bootstrap";
import { My_Button } from "../../Tools";
import { toJS } from "mobx";
import { useTranslation } from "react-i18next";

export const VehicleExistModal = inject("store")(
  observer((props) => {
    const {t} = useTranslation("data_import")
    const { theme, showTable } = props;

    const { role } = props.store.common;

    const { vehicles, handleClearVehicles, companyName } = props.store.dataImport;

    const [showVehicleExistModal, setShowVehicleExistModal] = useState(false);
    let cardStyle = {
      color: invertDark(theme),
      background: Colors[theme].background,
      border: "none",
    };

    const closeModal = () => {
      setShowVehicleExistModal(false);
      handleClearVehicles();
    };

    useEffect(() => {
      if (
        vehicles?.vehicleExists?.length > 0 ||
        vehicles?.vehicleNotExists?.length > 0
      ) {
        setShowVehicleExistModal(true);
      }
    }, [vehicles]);

    // console.log(toJS(vehicles), 'vehiclesssssss==>')
    // console.log(toJS(vehicles?.vehicleNotExists), 'vehiclesssssssnotexistsss==>')


    return (
      <div>
        <Modal show={showVehicleExistModal} onHide={closeModal}>
          <Modal.Header style={cardStyle}>
            <Modal.Title
              className={
                vehicles?.success === false ? "text-danger" : "text-success"
              }
            >
              {vehicles?.message}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={cardStyle}>

            {role === 'super_admin' || companyName === "Volvo" ?

              <>
                {showTable === "table1" ?
                  <>
                    <p>{t("The following vehicles (or) vehicle already exist:")}</p>

                  </> :
                  <>
                    <p>
                      {t("The following vehicles (or) vehicle does not exist in the unit profile data. Please, create the following unit profile data and re-submit again.")}
                    </p>
                  </>
                }
              </>
              :
              <>
                <p>
                  The following vehicles (or) vehicle does not exist in the unit
                  profile data. Please, create the following unit profile data and re-submit again.
                </p>
              </>
            }

            <ul>

              {role === 'super_admin' || companyName === "Volvo" ?
                <>
                  {
                    showTable === "table1" ?
                      vehicles?.vehicleExists?.map((vehicle, index) => (
                        <li key={index}>{vehicle}</li>
                      ))
                      : vehicles?.vehicleNotExists?.map((vehicle, index) =>
                      (
                        <li key={index}>{vehicle}</li>
                      ))
                  }
                </>
                :
                <>
                  {vehicles?.vehicleNotExists?.map((vehicle, index) =>
                  (
                    <li key={index}>{vehicle}</li>
                  ))}
                </>
              }
            </ul>
          </Modal.Body>
          <Modal.Footer style={cardStyle}>
            <My_Button
              id="cancel"
              type="button"
              className="btn text-light me-2"
              style={{}}
              text="Close"
              onClick={closeModal}
            />
          </Modal.Footer>
        </Modal>
      </div>
    );
  })
);
