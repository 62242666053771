import { inject, observer } from "mobx-react";
import { My_Button } from "../../Tools";
import { invertDark, Colors} from "../../Assets/Config/color.config";
import { useTranslation } from "react-i18next";

export const ConfirmationModal = inject("store")(
  observer((props) => {
    const {t} = useTranslation("new-manuel-entry")
    const { theme, _handleSubmitEntryData } = props;
    const cardStyle = { color: invertDark(theme), background: Colors[theme].background }

    return (

      <div className="modal fade"
        id="confirmmodal"
        tabIndex="-1"
        aria-labelledby="confirmmodal"
        aria-hidden="true"
        >

        <div className="modal-dialog modal-lg">
          <div className="modal-content" style={{background: theme === "dark"? "#202859" : "#ffffff"}} >
            <div className="modal-header" style={{justifyContent:"start", borderBottom: "1px solid #454B73"}}>
                <i className="bi bi-exclamation-triangle-fill" style={{ color: theme === "dark"? "yellow" : "orange", marginRight: 4}}></i>
                <h1 className="modal-title fs-5 text-warning" id="resendcertificateconfirmmodal">{t("Warning")}</h1>
            </div>
            <div className="modal-body">
              <div style={{ fontSize: 18, color: theme === 'dark' ? 'white' : 'black'}}>
                {t(`Important Notice: Updating the "Odometer Reading at End of Month (km)"/ "Fuel Consumption" value will result in changes to your historical Monthly Total of Trip Mileage/ Monthly Total of Fuel Consumption data.  Any adjustments made to the values will impact your overall Carbon Emission data associated with these figures. Please review these updates carefully before proceeding. Do you wish to continue ?`)}
              </div>
            </div>
            <div className="modal-footer" style={{borderTop: "1px solid #454B73"}}>
              <div className="d-flex justify-content-end">
                <span className="me-2" data-bs-dismiss="modal">
                  <My_Button
                    customColor="#4c5d75"
                    // customHoverColor="#0d6efd"
                text={t("Cancel")}
                  />
                </span>
                <span data-bs-dismiss="modal">
                  <My_Button
                    customColor="#4c5d75"
                    // customHoverColor="#0d6efd"
                    text={t("Continue")}
                    onClick={() => {
                      _handleSubmitEntryData()
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>

      </div>
      

    );
  }));

