import React from "react";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import { styled, TableSortLabel } from "@mui/material";
import { Colors } from "../../Assets/Config/color.config";
import { toJS } from "mobx";

const MonthlyDetailTable = (props) => {
  const { theme, header, data, handleUpdateClick, selectedVehicle } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  console.log(toJS(data))
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const createData = (
    deviceId,
    deviceName,
    powerConsumed,
    odometer,
    action
  ) => {
    return {
      deviceId,
      deviceName,
      powerConsumed,
      odometer,
      action,
    };
  };

  const rows = data
    ? data.map((v, k) =>
        createData(
          v.deviceId,
          v.deviceName || "N/A",
          Number(v.adsPowerConsumed) > 0
            ? v.adsPowerConsumed + " ads"
            : v.manualpowerConsumed > 0
            ? String(v.manualpowerConsumed.toFixed(2))
            : null,
          v.odometer > 0 ? v.odometer.toFixed(2) : null,
          "Update"
        )
      )
    : [];

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "transparent",
      color: theme.palette.common.white,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, theme } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    const key = [
      "deviceId",
      "deviceName",
      "powerConsumed",
      "odometer",
      "action",
    ];
    return (
      <TableHead>
        <TableRow>
          {header.map((headCell, index) => (
            <TableCell
              key={index}
              align={index === 0||1 ? "left" : "center"}
              sortDirection={orderBy === headCell.id ? order : false}
              style={{
                border: "none",
                background: theme === "dark" ? "#101841" : "white",
                position: index === 0 && "sticky",
                zIndex: index === 0 && 100,
                left: 0,
              }}
            >
              {headCell.label === "" || (
                <TableSortLabel
                  style={{
                    whiteSpace: "nowrap",
                    color:
                      orderBy === headCell.id
                        ? Colors.activeColor
                        : Colors[theme].textColor,
                  }}
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(key[index])}
                >
                  <span className="fw-bold">{headCell.label}</span>
                  {(headCell.label === "Power Consumed (kWh)" ||
                    headCell.label === "Odometer (km)") && (
                    <i
                      className={`${
                        rows.findIndex((dt) =>
                          headCell.label === "Power Consumed (kWh)"
                            ? dt.powerConsumed === 0 || dt.powerConsumed === null
                            : dt.odometer === 0 || dt.odometer === null
                        ) > -1 && "fas fa-exclamation-circle"
                      } ms-1`}
                      style={{
                        color:
                          rows.findIndex((dt) =>
                            headCell.label === "Power Consumed (kWh)"
                              ? dt.powerConsumed === 0 ||
                                dt.powerConsumed === null
                              : dt.odometer === 0 || dt.odometer === null
                          ) > -1
                            ? Colors.medium
                            : Colors.low,
                      }}
                    ></i>
                  )}
                </TableSortLabel>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  function descendingComparator(a, b, orderBy) {
    const A =
      a[orderBy] === null
        ? 0
        : orderBy === "powerConsumed" ||
          orderBy === "odometer"
        ? parseFloat(a[orderBy])
        : a[orderBy]
        ? a[orderBy].trim()
        : undefined;
    const B =
      b[orderBy] === null
        ? 0
        : orderBy === "powerConsumed" ||
          orderBy === "odometer"
        ? parseFloat(b[orderBy])
        : b[orderBy]
        ? b[orderBy].trim()
        : undefined;
    if (B < A) {
      return -1;
    }
    if (B > A) {
      return 1;
    }
    return 0;
  }
  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  return (
    <div
      className="m-2 table-responsive"
      style={{
        boxShadow:
          theme === "dark" ? "none" : "0px 0px 10px rgba(75,171,237,0.35)",
      }}
    >
      <TableContainer
        className="pb-2"
        component={Paper}
        // style={{ background: "none", border: `1.5px solid ${theme === 'dark' ? Colors.lightBlue : Colors.low}` }}
        style={{
          background: Colors[theme].background,
          borderRadius: "7px",
        }}
        sx={{ height: 178 }}
      >
        <Table
          stickyHeader
          // sx={{ minWidth: 650 }}
          size="small"
          aria-label="a dense sticky table"
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            theme={theme}
          />

          <TableBody>
            {rows.length > 0 &&
              rows.sort(getComparator(order, orderBy)).map((row, index) => {
                const uid = data[index].deviceId;
                return (
                  <StyledTableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    style={{
                      backgroundColor:
                        selectedVehicle?.licensePlate === row.licensePlate &&
                        Colors[theme].active,
                    }}
                  >
                    <TableCell
                      sx={{
                        color: Colors[theme].textColor,
                        borderBottom: "none",
                        position: "sticky",
                        // left: 10,
                        background:
                          selectedVehicle?.licensePlate === row.licensePlate
                            ? Colors[theme].active
                            : theme === "dark"
                            ? "#131b47"
                            : "#ffffff",
                      }}
                    >
                      {row.deviceId}
                    </TableCell>
                    <TableCell
                      sx={{
                        paddingRight: 4,
                        color: Colors[theme].textColor,
                        borderBottom: "none",
                        // textAlign: "center",
                      }}
                    >
                      {row.deviceName}
                    </TableCell>
                    <TableCell
                      className=""
                      sx={{
                        // paddingRight: 4,
                        color: row.powerConsumed
                          ? Colors[theme].textColor
                          : Colors.medium,
                        borderBottom: "none",
                        // textAlign: "left",
                      }}
                    >
                      {/* {row.fuelConsumed ? (row.fuelConsumed)?.toFixed(2): 'No Data'} */}
                      <div className=" w-75 d-flex align-items-center flex-nowrap mx-auto">
                        {row.powerConsumed?.includes("ads") ? (
                          <StatusCircle color={Colors.low} title={"IOT Data"} />
                        ) : row.powerConsumed ? (
                          <StatusCircle
                            color={Colors.fuelLight}
                            title={"Manual Data"}
                          />
                        ) : null}
                        {row.powerConsumed ? (
                          <div className="">
                            {Number(Number(
                              row.powerConsumed.replace(" ads", "")
                            )?.toFixed(2)).toLocaleString('en-US')}
                          </div>
                        ) : (
                          <div className="ms-3">No Data</div>
                        )}
                      </div>
                    </TableCell>
                    <TableCell
                      sx={{
                        paddingLeft: 4,
                        color: row.odometer
                          ? Colors[theme].textColor
                          : Colors.medium,
                        borderBottom: "none",
                        // textAlign: "center",
                      }}
                    >
                      {row.odometer ? Number(row.odometer).toLocaleString('en-US') : "No Data"}
                    </TableCell>
                   
                    <TableCell
                      onClick={() => handleUpdateClick(row, uid)}
                      sx={{
                        paddingRight: 5,
                        color:
                          theme === "dark"
                            ? Colors.fuelLight
                            : Colors["light"].secondaryColor,
                        borderBottom: "none",
                        // textAlign: "center",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      {row.action}
                    </TableCell>
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default MonthlyDetailTable;

const StatusCircle = ({ color, title }) => {
  return (
    <div
      className="d-flex align-items-center justify-content-center "
      style={{ width: 30 }}
    >
      <div
        className=""
        style={{
          width: 8,
          height: 8,
          backgroundColor: color,
          borderRadius: "50%",
        }}
        title={title}
      />
    </div>
  );
};
