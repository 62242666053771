import clockImage from "../../Assets/Images/icons/clock.png";
import { Colors } from "../../Assets/Config/color.config";
import { Selector } from "../../Tools";
import { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Cookies } from "react-cookie";
import moment from "moment";
import { toJS } from "mobx";
import { baseURL } from "../../network/URL";
import HistoryModal from "../FuelHistory/HistoryModal";
import { CO2_Loading } from "../../Assets/CO2_Loading";
import { useTranslation } from "react-i18next";

export const FuelHistory = inject("store")(
  observer((props) => {
    const {t} = useTranslation("mobile")
    const theme = localStorage.getItem("theme");
    const { _handleFetchCompanyYears, companyyearlydata } =
      props.store.dashboard;
    const {
      monthOption,
      month,
      yearOption,
      year,
      _handleSelectMonth,
      _handleSelectYear,
      _getFuelHistoryData,
      _fetchVehicleLst,
      vehicleHistoryOption,
      selectedHistoryVehicles,
      filterFuelHistoryData,
      _handleSelectVehicleFromHistory,
      fileFuel
    } = props.store.driver;
    console.log(month);
    const cardStyle = {
      background: "rgb(217,217,217,0.1)",
      color: Colors.Dark[theme === "dark" ? 4 : 1],
      fontSize: 12,
      borderRadius: 10,
      boxShadow: "4px 4px 4px rgb(0,0,0,0.2)",
    };
    const titleColor = { color: theme === "dark" ? "#ffff" : Colors.Dark[1] };
    const cookies = new Cookies();
    const token = cookies.get("accessToken");

    useEffect(() => {
      _getFuelHistoryData();
    }, [month, year]);

    useEffect(() => {
      _fetchVehicleLst();
    }, []);

    console.log('historyData==>', toJS(filterFuelHistoryData))

    return (
      <>
        {vehicleHistoryOption ? (
          <>
            <div className="col-12 col-sm-12 col-lg-6 col-xl-6 col-xs-6">
              <div>
                <div
                  className="d-flex align-items-center"
                  style={{ color: Colors.Dark[5], fontSize: 20 , marginTop : '1.5em'}}
                >
                  <img className="pe-2" width="35px" src={clockImage} />
                  <span className="col-6 pb-1" style={titleColor}>
                    {t("Fuel History")}
                  </span>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <span className="col-4 pe-2">
                  <Selector
                    label={t("Year")}
                    defaultValue={yearOption.filter((v) => v.value === year)}
                    _handleSelect={(e) => _handleSelectYear(e)}
                    options={yearOption}
                  />
                </span>
                <span className="col-4 pe-2">
                  <Selector
                    label={t("Month")}
                    defaultValue={monthOption.filter((v) => v.value === month)}
                    _handleSelect={(e) => _handleSelectMonth(e)}
                    options={monthOption}
                  />
                </span>
                <span className="col-4">
                  <Selector
                    label={t("Vehicles")}
                    defaultValue={selectedHistoryVehicles}
                    _handleSelect={(e) => _handleSelectVehicleFromHistory(e)}
                    options={vehicleHistoryOption}
                  />
                </span>
              </div>
              <div className="px-2 pt-2">
                {filterFuelHistoryData?.map((v, k) => (
                  <div key={k} style={cardStyle}>
                    <div className="my-2 row m-0 py-2 d-flex justify-content-between">
                      <span className="col-8 row">
                        <span className="col-6 pb-1" style={titleColor}>
                          {t("Fuel Consumed")}
                        </span>
                        <span className="col-6">
                          : {v.consumed}{" "}
                          {v.fuel_type === "Battery" ? " kWh" : " L"}
                        </span>

                        <span className="col-6 pb-1" style={titleColor}>
                          {t("Pay Amount")}
                        </span>
                        <span className="col-6">
                          :{" "}
                          {Number(v.amount).toLocaleString(undefined, {
                            maximumFractionDigits: 4,
                          }) + " SGD"}
                        </span>

                        <span className="col-6" style={titleColor}>
                          {t("Fuel Type")}
                        </span>
                        <span className="col-6">: {v.fuel_type}</span>

                        <span className="col-6">
                          <div style={titleColor}>{t("Type Status")}</div>
                          <div>{v.type_status}</div>
                        </span>

                        <span className="col-6">
                          <div style={titleColor}>{t("Fuel Status")}</div>
                          <div>{v.fuel_status}</div>
                        </span>
                      </span>
                      <div className="col-4 text-end">
                        <div
                          className="p-1"
                          style={{ borderRadius: 5, background: "#ffffff2b" }}
                        >
                          <div
                            className="text-end"
                            style={{ textAlign: "end" }}
                          >
                            <span>
                              {moment(v.record_date).format("YYYY/MM/DD")}
                            </span>
                            <i
                              style={titleColor}
                              className="bi bi-calendar2-week mx-1"
                            ></i>
                          </div>
                          <div className="text-end">
                            <span>
                              {moment(v.record_date).format("HH:mm A")}
                              
                            </span>
                            <i
                              style={titleColor}
                              className="bi bi-clock-history mx-1"
                            ></i>
                          </div>
                        </div>

                        {v.file && (
                          <div
                            data-bs-toggle="modal"
                            data-bs-target={`#b${k}`}
                            data-toggle="modal"
                            className="p-1 mt-2 d-flex justify-content-center"
                            style={{
                              background: "#0d6efd",
                              borderRadius: 5,
                              ...titleColor,
                              cursor: 'pointer'
                            }}
                          >
                            <i className="bi bi-image me-2"></i>{t("Image")}
                          </div>
                        )}
                      </div>
                    </div>
                    {v.file && (
                      <HistoryModal
                        fuelHistoryData={filterFuelHistoryData}
                        index={k}
                        fileFuel={fileFuel}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : (
          <CO2_Loading />
        )}
      </>
    );
  })
);
