import { inject, observer } from "mobx-react"
import { useEffect } from "react"
import { invertDark } from "../../Assets/Config/color.config"
import { FirstStep } from "../../Components/CompanySetup/FirstStep"
import { Navigator } from "../../Components/CompanySetup/Navigator"
import { SecondStep } from "../../Components/CompanySetup/SecondStep"
import { ThirdStep } from "../../Components/CompanySetup/Thirdstep"

export const CompanySetUpContainer = inject("store")(
    observer((props) => {
        
        const { theme } = props.store.login
        const { step } = props.store.landing
       
        return (
            <div className="mx-5">

                <div className="p-3" style={{ background:  theme==='dark'?'rgb(255,255,255,0.1)':'rgb(0,0,0,0.05)', borderRadius: 5, color: invertDark(theme), minHeight: "700px"}}>
                    <h5 style={{color:theme==='dark'?'white':'black'}} className="pb-2">Company Setup</h5>
                    {
                        step === 1 ? <FirstStep /> : step === 2 ? <SecondStep theme={theme} /> : <ThirdStep />
                    }

                </div>
                <div className="position-relative">
                    <Navigator />
                </div>
            </div>
        )
    }))

