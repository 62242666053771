import * as API from "./URL";

export const createCo2xLiteData = async (token, data, callback) => {
  console.log(data)
  return fetch(`${API.createCo2xLiteData}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const getCo2xLiteData = async (token, companyId, callback) => { 
  return fetch(`${API.getCo2xLiteData}?companyId=${companyId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const getCo2xLiteDataNew = async (token, companyId, callback) => { 
  return fetch(`${API.getCo2xLiteDataNew}?companyId=${companyId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};


export const getAllAsset = async (token, companyId, callback) => { 
  return fetch(`${API.getAllLiteAsset}?companyId=${companyId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const deleteCo2xLiteData = async (token, id, callback) => {
  return fetch(`${API.deleteCo2xLiteData}?id=${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const updateCo2xLiteData = async (token, data, callback) => {
  return fetch(`${API.updateCo2xLiteData}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const requestReport = async (token,id,status, callback) => {
  return fetch(`${API.requestReport}?id=${id}&status=${status}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const downloadReport = async (token,id, callback) => {
  return fetch(`${API.downloadReport}?id=${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const fetchDashboardData = async (token, companyId, callback) => {
  return fetch(`${API.liteDashboardData}?companyId=${companyId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const createNewAssetAPI = async (token, data, callback) => {
  return fetch(`${API.assetAPI}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};