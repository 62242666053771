import { toJS } from "mobx";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { My_Button, My_Input, My_upload_image, Selector } from "../../Tools";
import { inject, observer } from "mobx-react";
import { Checkbox, useForkRef } from "@mui/material";
import { baseURL } from "../../network/URL";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CO2_Loading } from "../../Assets/CO2_Loading";

export const AssignUnitView = inject("store")(
  observer((props) => {
    const { t } = useTranslation("admin-panel");
    const { theme, user } = props;
    const { _setAssignNewVehicleUser } = props.store.adminPanel;
    const {
      selectetCompany,
      selectedUser,
      checkedVehicles,
      selectedVehicle,
      availableVehicle,
      _handleAssgningVehicle,
      isLoading,
      _handleLoading,
      setCheckedValue,
      checkedValues,
    } = props.store.dataEntry;
    const default_img = "/cars/default_vehicle.png";

    return (
      <div id="assignView">
        <div className="row g-0" style={{ backgroundColor: "" }}>
          <h5
            className=""
            style={{
              color: theme === "dark" ? "rgb(198, 198, 198)" : "#303030",
              marginTop: "1em",
              marginBottom: "1em",
              marginLeft: "0.3em",
            }}
          >
            {t("Assign Units For ")}
            {user?.username}
          </h5>
          {isLoading === true ? (
            <CO2_Loading />
          ) : (
            <>
              <div className="col-lg-6 col-md-6 col-sm-6 px-1">
                <div className="d-flex flex-column justify-content-between">
                  <span className="pe-1">
                    {t("Available Units")}
                    {"-"}
                    {availableVehicle ? availableVehicle.length : 0}
                  </span>
                  <div
                    className="d-flex align-items-center"
                    onClick={() => {
                      _handleLoading(true);
                      _handleAssgningVehicle(
                        availableVehicle,
                        selectedUser,
                        "assignAll"
                      );
                      setCheckedValue(true, "assignAll");
                    }}
                  >
                    <span className="pe-2">Assign All</span>
                    <Checkbox
                      checked={checkedValues.assignChecked}
                      style={{
                        color: Colors.activeColor,
                        width: 10,
                      }}
                    />
                  </div>
                  {/* <button className="btn btn-primary" onClick={() => _handleAssgningVehicle(availableVehicle, selectedUser, 'assignAll')}>Assign All</button> */}
                </div>

                <div
                  style={{
                    height: "500px",
                    overflowX: "hidden",
                    overflowY: "scroll",
                  }}
                >
                  {availableVehicle?.map((vehicle, index) => {
                    return (
                      <div
                        className="py-2 mt-1 col-12"
                        style={{
                          background: theme === "dark" ? "#384067" : "#C4C4C4",
                          borderRadius: 6,
                          cursor: "pointer",
                          // minWidth: 300,
                        }}
                        onClick={(e) => {
                          _handleLoading(true);
                          _handleAssgningVehicle(
                            vehicle.id,
                            selectedUser,
                            "false"
                          );
                        }}
                      >
                        <div className="d-flex align-items-center  col-12">
                          <div className="col-2 d-flex justify-content-center">
                            <Checkbox
                              checked={false}
                              value={vehicle.plate_number}
                              style={{
                                color: Colors.activeColor,
                                width: 10,
                              }}
                            />
                          </div>
                          <div
                            className="col-5 d-flex justify-content-center"
                            style={{ objectFit: "cover" }}
                          >
                            <img
                              className=""
                              style={{
                                height: 60,
                                width: "100%",
                                objectFit: "cover",
                              }}
                              src={
                                vehicle.image
                                  ? `${baseURL}/uploads/devices/${vehicle.image}`
                                  : default_img
                                // baseURL +
                                // "/uploads/devices/" +
                                // vehicle.image
                              }
                            />
                          </div>
                          <div className="col col-5 mx-2">
                            <div>
                              <span
                                className="col"
                                style={{ fontSize: 14, width: "70px" }}
                              >
                                {vehicle.plate_number}
                              </span>
                            </div>
                            <div className="d-flex">
                              <span style={{ fontSize: 12 }}>
                                {vehicle.fuel_type}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="col-6 col-md-6 col-sm-6 px-1">
                <div className="d-flex justify-content-between flex-column">
                  <span className="pe-1">
                    {t("Selected Units")}
                    {"-"}
                    {selectedVehicle ? selectedVehicle.length : 0}
                  </span>
                  <div
                    className="d-flex align-items-center"
                    onClick={() => {
                      _handleLoading(true);
                      _handleAssgningVehicle(
                        selectedVehicle,
                        selectedUser,
                        "unassignAll"
                      );
                      setCheckedValue(true, "unassignAll");
                    }}
                  >
                    <span className="pe-2">Unassign All</span>
                    <Checkbox
                      checked={
                        selectedVehicle && selectedVehicle.length > 0
                          ? true
                          : false
                      }
                      // value={vehicle.plate_number}
                      style={{
                        color: Colors.activeColor,
                        width: 10,
                      }}
                    />
                  </div>
                  {/* <button className="btn btn-primary" onClick={() => _handleAssgningVehicle(selectedVehicle, selectedUser, "unassignAll")}>Unassign All</button> */}
                </div>
                <div
                  style={{
                    height: "500px",
                    overflowX: "hidden",
                    overflowY: "scroll",
                  }}
                >
                  {selectedVehicle?.map((vehicle, index) => {
                    return (
                      <div
                        className="py-2 mt-1 col-12"
                        style={{
                          background: theme === "dark" ? "#384067" : "#C4C4C4",
                          borderRadius: 6,
                          cursor: "pointer",
                          // minWidth: 300,
                        }}
                        onClick={(e) => {
                          _handleLoading(true);
                          _handleAssgningVehicle(
                            vehicle.id,
                            selectedUser,
                            "false"
                          );
                        }}
                      >
                        <div className="d-flex align-items-center col-12">
                          <div className="col-2 d-flex justify-content-center">
                            <Checkbox
                              checked={true}
                              value={vehicle.plate_number}
                              style={{
                                color: Colors.activeColor,
                                width: 10,
                              }}
                            />
                          </div>
                          <div
                            className="col-5 d-flex justify-content-center"
                            style={{ objectFit: "cover" }}
                          >
                            <img
                              className=""
                              style={{
                                height: 60,
                                width: "100%",
                                objectFit: "cover",
                              }}
                              src={
                                vehicle.image
                                  ? `${baseURL}/uploads/devices/${vehicle.image}`
                                  : default_img
                                // baseURL +
                                // "/uploads/devices/" +
                                // vehicle.image
                              }
                            />
                          </div>

                          <div className="col col-5 mx-2">
                            <div>
                              <span
                                className="col"
                                style={{ fontSize: 14, width: "70px" }}
                              >
                                {vehicle.plate_number}
                              </span>
                            </div>
                            <div className="d-flex">
                              <span style={{ fontSize: 12 }}>
                                {vehicle.fuel_type}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          )}
        </div>
        <div className="row g-0 mx-2 mt-2">
          <div className="col-9"></div>
          <My_Button
            customColor={ theme === 'dark' ? "#4c5d75" : '#198754'}
            customHoverColor={ theme === 'dark' ? "#70839e" : '#1dad6b'}
            onClick={() => _setAssignNewVehicleUser(false)}
            className="col-3"
            text={t("Close")}
          />
        </div>
        {/* <My_Button
          customColor={theme === "light" && "rgb(10, 88, 202)"}
          customHoverColor={theme === "light" && "rgb(10 109 255)"}
          onClick={() => _setAssignNewVehicleUser(false)}
          className="w-100"
          text={t("Close")}
        /> */}
      </div>
    );
  })
);
