import React from 'react'
import { useState } from 'react';
import { inject, observer } from "mobx-react";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import autoTable from "jspdf-autotable";
import { invertDark } from '../../Assets/Config/color.config';
import { Colors } from '../../Assets/Config/color.config'
import * as XLSX from 'xlsx';
import * as FileSaver from "file-saver";
import { Button } from 'react-bootstrap';
import { CompanySelector } from '../Dashboard/companySelector';
import { toJS } from 'mobx';
import { Cookies } from 'react-cookie';


export const UnitsReportPreviewModal = inject("store")(
    observer((props) => {
        const cookies = new Cookies();
        const { theme, deviceList} = props;

        const { companyId } = props.store.assetProfile;

        const companyName = cookies.get("companyName")

        const rows = deviceList?.map((data, index) => {
            let unit = {
              company: data.name,
              grouping: data.grouping || "N/A",
              plate_number: data.plate_number || "N/A",
              model: data.model || "N/A",
              cargo_type: data.cargo_type || "N/A",
              fuel_type: data.fuel_type || "N/A",
              brand: data.brand || "N/A",
              VIN_number: data.vin_number || "N/A",
              // oem: data.manual_baseline_emission_km || "N/A",
              baseline_emission_intensity: data.baselineEmission + ` ${data.emissionTypeUnit}` || "N/A",
              // historicalEmission: data.oem_history || "N/A",
              baseline_type: data.baselineEmissionType || "N/A",
              GPS: "" + data.unitSetting.enableGPS,
              CANbus: "" + data.unitSetting.enableCANbus,
             
            };
            return unit;
          });

        var wsTableData = rows && XLSX.utils.json_to_sheet(rows);
        var csvTableData = XLSX.utils.sheet_to_csv(wsTableData);

        function s2ab(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
            return buf;
        }

        const generatePDF = () => {

            const doc = new jsPDF("l", undefined, "a4");

            if (!rows || rows.length === 0) {
                console.error('No data available for PDF generation');
                return;
            }

            
            doc.text(
                15,
                30,
                `Units Report for - ${companyName} `,
              );

            autoTable(doc, {
                styles: { fontSize: 10 },
                startY: 50,
                body: rows,
                columns: [
                    { header: "Company", dataKey: "company" },
                    { header: "Grouping", dataKey: "grouping" },
                    { header: "Plate Number", dataKey: "plate_number" },
                    { header: "Model", dataKey: "model" },
                    { header: "Cargo Type", dataKey: "cargo_type" },
                    { header: "Fuel Type", dataKey: "fuel_type" },
                    { header: "Brand", dataKey: "brand" },
                    { header: "VIN Number", dataKey: "VIN_number" },
                    { header: "Baseline Emission Intensity", dataKey: "baseline_emission_intensity" },
                    { header: "Baseline Type", dataKey: "baseline_type" },
                    { header: "GPS", dataKey: "GPS" },
                    { header: "CANbus", dataKey: "CANbus" },
                ],
            });
            doc.save(`${companyName}_Units_Table_Report.pdf`);

        };

        const downloadXLSX = () => {
            FileSaver.saveAs(
                new Blob(
                    [
                        s2ab(csvTableData),
                    ],
                    { type: "application/octet-stream" }
                ),
                `${companyName}_Units_Table_Report.csv`
            );
        };
        return (
            <div
                className="modal fade"
                id="unitsreport"
                tabIndex="-1"
                aria-labelledby="unitsreport"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content" style={{
                        width: '1200px'
                    }}>
                        <div className="modal-header">
                            <h5 className="modal-title d-flex justify-content-center align-items-center" style={{ color: 'black'}}><span>Units Table Report - </span><CompanySelector /></h5>
                            <button type="button" className="btn-close" aria-label="Close" data-bs-dismiss="modal"></button>
                        </div>
                        <div className="modal-body" style={{ overflowY: 'scroll', height: '550px', color: 'black' }}>
                            {/* {showPreview && ( */}
                            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                <thead>
                                    <tr style={{ border: '1px solid gray' }}>
                                        {rows && rows[0] && Object.keys(rows[0]).map((key, index) => (
                                            <th style={{ border: '1px solid silver', padding: '30px 8px', textAlign: 'left' }} key={index}>
                                                { key.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase())}
                                                </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {rows?.map((row, rowIndex) => (
                                        <tr key={rowIndex} style={{ border: '1px solid silver' }}>
                                            {Object.values(row).map((value, cellIndex) => (
                                                <td style={{ border: '1px solid silver', padding: '8px' }} key={cellIndex}>{value}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            {/* )} */}
                        </div>
                        <div className="modal-footer">
                            <Button
                                type="button"
                                onClick={() => generatePDF()}
                                style={{
                                    background: "#4c5d75",
                                    color: "white",
                                    border: "none",
                                    padding: "8px"
                                }}
                                className="btn btn-sm detail-report-guide"
                            >
                                Download PDF Report
                            </Button>

                            <Button
                                type="button"
                                onClick={() => downloadXLSX()}
                                style={{
                                    background: "#4c5d75",
                                    color: "white",
                                    border: "none",
                                    padding: "8px"
                                }}
                                className="btn btn-sm detail-report-guide"
                            >
                                Download XLSX Report
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }))

