import { Colors } from "../../Assets/Config/color.config";
export const ReportTable = (props) => {
    const { reportData, fuelType } = props;
    return (
        <>
            <table className="mt-4 text-center" style={{
                width: "100%",
                // border: `1px solid ${Colors.Dark[4]}`,
            }}>
                <tr style={{ height: 100, background: Colors.darkBlue, color: '#ffff' }}>
                    <th className="text-start ps-3" style={{ borderRight: '4px solid white' }}>Description</th>
                    <th>Amount</th>
                    <th>Unit</th>
                </tr>
                <tr style={{ height: 50 }}>
                    <td className="text-start ps-3" >Baseline Co2 Emission(kgCO2e/km)</td>
                    <td>{fuelType === 'Petrol' ? reportData?.consumptionsByFuelType?.Petrol?.baselineCo2Emission.toFixed(2) :
                        fuelType === 'Diesel' ? reportData?.consumptionsByFuelType?.Diesel?.baselineCo2Emission.toFixed(2) :
                            reportData?.consumptionsByFuelType?.Battery?.baselineCo2Emission.toFixed(2) || '--'}</td>
                    <td>kgCO2e/km</td>
                </tr>
                <tr style={{ height: 50, background: Colors.Dark[5] }}>
                    <td className="text-start ps-3">Total Emission(kg)</td>
                    <td>{fuelType === 'Petrol' ? reportData?.consumptionsByFuelType?.Petrol?.totalEmission.toFixed(2) :
                        fuelType === 'Diesel' ? reportData?.consumptionsByFuelType?.Diesel?.totalEmission.toFixed(2) :
                            reportData?.consumptionsByFuelType?.Battery?.totalEmission.toFixed(2) || '--'}</td>
                    <td>kg</td>
                </tr>
                <tr style={{ height: 50 }}>
                    <td className="text-start ps-3">Total Mileage(km)</td>
                    <td>{fuelType === 'Petrol' ? reportData?.consumptionsByFuelType?.Petrol?.totalMileage.toFixed(2) :
                        fuelType === 'Diesel' ? reportData?.consumptionsByFuelType?.Diesel?.totalMileage.toFixed(2) :
                            reportData?.consumptionsByFuelType?.Battery?.totalMileage.toFixed(2) || '--'}</td>
                    <td>km</td>
                </tr>
                <tr style={{ height: 50, background: Colors.Dark[5] }}>
                    <td className="text-start ps-3">Total Consumption</td>
                    <td>{fuelType === 'Petrol' ? reportData?.consumptionsByFuelType?.Petrol?.totalConsumed.toFixed(2) :
                        fuelType === 'Diesel' ? reportData?.consumptionsByFuelType?.Diesel?.totalConsumed.toFixed(2) :
                            reportData?.consumptionsByFuelType?.Battery?.totalConsumed.toFixed(2) || '--'}</td>
                    <td>{fuelType === 'Battery' ? 'kWh' : 'L'}</td>
                </tr>
            </table>
        </>
    )
}