import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { Selector } from "../../Tools";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import Select, { components } from 'react-select';
import makeAnimated from 'react-select/animated';
import moment from "moment";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import { DatePickerAntD } from "./DatePickerAntD";
import { useTranslation } from "react-i18next";
import { toJS } from "mobx";

export const FilterBarForComparison = inject("store")(
    observer((props) => {
        const { t } = useTranslation("new-tracking")
        const {
            month,
            vehicleType,
            vehicleOptions,
            handleGetVehicleType,
            handleSelectedVehicleType,
            handleYearSelect,
            handleMonthSelect,
            handleVehicleSelected,
            selectedVehicle,
            number_plates,
            vehicleIds,
            dateFormat,
            setSelectDateFormat,
            _handleSelectYear,
            _handleSelectCustom,
            yearSelected,
            fmsVehicleLst,
            _handleSelectDay,
            endDateFormat,
            startDateFormat,
            unitSetting
        } = props.store.trackingVTwo;

        const { companyyearlydata } = props.store.dashboard;

        const { theme } = props

        const types = [
            { value: "Battery", label: "EV", },
            { value: "Petrol", label: "Petrol", },
            { value: "Diesel", label: "Diesel", },
        ];

        // let selectedUnitSetting = selectedVehicle?.length == undefined ? unitSetting : fmsVehicleLst?.find(v => v.id === selectedVehicle[selectedVehicle?.length - 1]?.value)?.unitSetting
        // console.log("selectedUnitsetting- -->", toJS(selectedUnitSetting))

        let selectedUnitSetting = fmsVehicleLst?.map(v => v.unitSetting)?.filter(v => v.enableCANbus && !v?.enableGPS || !v?.enableCANbus && v?.enableGPS)
        let newUnitSetting = selectedUnitSetting?.filter(unit => vehicleIds.includes(unit.unitId));
        let filteredUnitSetting = newUnitSetting?.filter(v => v.enableCANbus && !v?.enableGPS || !v?.enableCANbus && v?.enableGPS)
      

        const dateOptions = filteredUnitSetting?.length > 0 ? 
            [
                { value: "Year", label: "Yearly", },
            ]
            :
            [
                { value: "7 Days", label: "Weekly", },
                { value: "Month", label: "Monthly", },
                { value: "Year", label: "Yearly", },
                { value: "Custom", label: "Custom", },
            ]

        if(filteredUnitSetting?.length > 0) {
            setSelectDateFormat("Year")
        }
        
        console.log("unitSetting --->", toJS(unitSetting))
        console.log("selectedVehicle --->", toJS(selectedVehicle))

        useEffect(() => {
            handleGetVehicleType();
            if(filteredUnitSetting?.length > 0) {
                setSelectDateFormat("Year")
            }
        }, [])

        const months = [
            { value: 1, label: "January" },
            { value: 2, label: "February" },
            { value: 3, label: "March" },
            { value: 4, label: "April" },
            { value: 5, label: "May" },
            { value: 6, label: "June" },
            { value: 7, label: "July" },
            { value: 8, label: "August" },
            { value: 9, label: "September" },
            { value: 10, label: "October" },
            { value: 11, label: "November" },
            { value: 12, label: "December" },
        ];

        const { RangePicker } = DatePicker;

        const disabledDate = (current) => {
            return current && (current > currentDate.endOf('day'));
        };


        const defaultDate = new Date()
        const currentDate = dayjs();

        // Example usage:
        const start = new Date('2023-01-01');
        const end = new Date('2023-01-11');
        const cDate = new Date()


        const animatedComponents = makeAnimated();

        return (
            <div className={`filter-bar-${theme} d-flex flex-column flex-wrap pb-3 px-0 mx-0`}>
                <div
                    className={`${window.innerWidth > 540 && "d-flex"
                        }  col-xl-4 col-lg-4 col-sm-4 mb-4 w-100`}
                    style={{ width: window.innerWidth < 701 ? '100%' : '' }}
                >
                    <div className="row w-100">
                        <div className="col-3" style={{ color: invertDark(theme), paddingTop: '5.5px' }}>
                            <Selector
                                isSearchable={false}
                                label={t("Type")}
                                options={types}
                                value={vehicleType ? vehicleType : types[0]}

                                // value={(vehicleType && primaryFuelTypeForCompany === vehicleType) ? primaryFuelTypeForCompany : (vehicleType ? vehicleType : types[0])}
                                _handleSelect={(value) => {
                                    handleSelectedVehicleType(value)
                                }}
                                disabled={false}
                            />
                        </div>



                        <div className="col-6">
                            <label
                                style={{
                                    color: theme === "dark" ? "rgb(198, 198, 198)" : "rgb(48, 48, 48)",
                                    fontWeight: "bold", marginTop: 5.5
                                }}
                            >
                                {t("Unit(s)")}:
                            </label>

                            {/* don't delete this code */}

                            <Select
                                className=""
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                defaultValue={
                                    vehicleOptions.filter((v) => v.value === selectedVehicle?.value)
                                }
                                // value={
                                //     vehicleOptions.filter((v) => number_plates.filter((plate) => v.label === plate)[0])
                                // }
                                value={
                                    vehicleOptions.filter((v) => vehicleIds.filter((id) => v.value === id)[0])
                                }
                                isMulti
                                options={vehicleOptions}
                                onChange={(vehicle) => {
                                    handleVehicleSelected(
                                        vehicle
                                    );
                                }}

                                disabled={false}
                                isSearchable={true}

                                styles={{

                                    container: base => ({
                                        ...base,
                                        backgroundColor: theme === "dark" ? '#222B5D' : "#fff",
                                        color: 'black',
                                        borderRadius: 2,
                                        border: theme === "dark" ? '0px solid #222B5D' : '0px solid #fff',

                                    }),

                                    control: base => ({
                                        ...base,
                                        backgroundColor: theme === "dark" ? '#222B5D' : "#F6F6F6",
                                        color: 'red',
                                        border: '1px solid #5E5E5E',

                                    }),

                                    input: base => ({
                                        ...base,
                                        color: invertDark(theme),
                                    })

                                }}

                                classNamePrefix="react-select"

                            />

                            {/* <MultiSelect 
                                className={`filter-bar-shared`}
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                defaultValue={
                                    vehicleOptions.filter((v) => v.value === selectedVehicle?.value)
                                }
                                value={
                                    vehicleOptions.filter((v) => vehicleIds.filter((id) => v.value === id)[0])
                                }
                                isMulti
                                options={vehicleOptions}
                                onChange={(vehicle) => {
                                    handleVehicleSelected(
                                        vehicle
                                    );
                                }}

                                disabled={false}
                                isSearchable={true}
                            /> */}

                        </div>
                    </div>
                </div>
                <div
                    className={`${window.innerWidth > 540 && "d-flex"
                        }  col-xl-8 col-lg-8 col-sm-8 mb-8 w-100`}
                    style={{ width: window.innerWidth < 701 ? '100%' : '' }}
                >
                    <div className="row w-100">
                        <div className="col-3">
                            <Selector
                                isSearchable={false}
                                label={t("Timeline")}
                                // label={t("Year")}
                                options={dateOptions}
                                value={
                                    dateOptions.find(v => v.value === dateFormat)
                                }
                                _handleSelect={({ value, label }) => {
                                    // const year = value;
                                    // handleYearSelect(year);
                                    setSelectDateFormat(value)
                                }}
                            />
                        </div>


                        {
                            dateFormat === "Month" ?
                                <>

                                    <div className="col-3">
                                        <Selector
                                            isSearchable={false}
                                            label={t("Month")}
                                            options={months}
                                            value={months.find((m) => m.value === month)}
                                            _handleSelect={({ value, label }) => {
                                                const month = value;
                                                handleMonthSelect(month);
                                            }}
                                        />
                                    </div>


                                    <div className="col-3">
                                    <p className=" mb-0 font-weight-bold"style={{fontWeight:"bold",color: invertDark(theme)}} >Year:</p>
                                        <DatePickerAntD
                                            picker={"year"}
                                            format="YYYY"
                                            theme={theme}
                                            defaultValue={dayjs(defaultDate)}
                                            disabledDate={(current) => {
                                                return current && (current < moment('2000', 'YYYY') || current > moment(new Date, 'YYYY'));
                                            }}
                                        // onChange={(date, dateString) => _handleSelectYear(dateString)}
                                        />
                                    </div>

                                </> :
                                dateFormat === "Year" ?

                                    <div className="col-3">
                                        <p className=" mb-0 font-weight-bold"style={{fontWeight:"bold",color: invertDark(theme)}} >Year:</p>
                                        <DatePickerAntD
                                            picker={"year"}
                                            format="YYYY"
                                            theme={theme}
                                            defaultValue={dayjs(defaultDate)}
                                            disabledDate={(current) => {
                                                return current && (current < moment('2000', 'YYYY') || current > moment(new Date, 'YYYY'));
                                            }}
                                        // onChange={(date, dateString) => _handleSelectYear(dateString)}
                                        />
                                    </div>

                                    :
                                    dateFormat === "Custom" ?

                                        <div className="col-3">
                                         <p className=" mb-0" style={{ color: invertDark(theme),fontWeight:"bold" }}>Start Date - End Date:</p>

                                            <RangePicker
                                                disabledDate={disabledDate}
                                                format="YYYY-MM-DD"
                                                style={{
                                                    background: theme === "dark" ? Colors.darkBlue : Colors.Dark[5],
                                                    // color: theme === "dark" ? Colors.Dark[4] : Colors.Dark[1],
                                                    width: '100%',
                                                    height: '38px',
                                                    borderRadius: 3,
                                                    borderColor: theme === "dark" ? Colors.Dark[2] : Colors.Dark[4],
                                                }}
                                                className={`${theme === 'dark' ? 'dark-theme' : 'light-theme'}`}
                                                onChange={(value, dateString) => _handleSelectCustom(value, dateString)}
                                            />
                                        </div>

                                        :
                                        dateFormat === "7 Days"?
                                        <>
                                         <div className={`col-3 ${theme === 'dark' ? 'dark-theme' : 'light-theme'}`}>
                                            <p className=" mb-0" style={{ color: invertDark(theme),fontWeight:"bold" }}>Date:</p>
                                            <DatePicker
                                                    disabledDate={disabledDate}
                                                    value={dayjs(endDateFormat)}
                                                    picker="week"
                                                    format="YYYY-MM-DD"
                                                    style={{
                                                        background: theme === "dark" ? Colors.darkBlue : Colors.Dark[5],
                                                        width: '100%',
                                                        height: '38px',
                                                        borderRadius: '3px',
                                                        borderColor: theme === "dark" ? Colors.Dark[2] : Colors.Dark[4],
                                                    }}
                                                    className={`${theme === 'dark' ? 'dark-theme' : 'light-theme'}`}
                                                    onChange={(dates, dateStrings) => _handleSelectDay(dates, dateStrings)}
                                                    />
                                         </div>

                                        </>:
                                        <></>
                        }
                    </div>
                </div>
            </div>
        );
    })
)