/* eslint-disable jsx-a11y/alt-text */
import { toJS } from "mobx";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { My_Button, My_Input, My_upload_image, Selector } from "../../Tools";
import { inject, observer } from "mobx-react";
import { Checkbox, useForkRef } from "@mui/material";
import { baseIMGURL, baseURL } from "../../network/URL";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const AssignVehiclesJQ = inject("store")(
    observer((props) => {
        const {t} = useTranslation("admin-panel")
        const { theme, user } = props;

        const {
            checkedVehicles,
            selectedVehicle,
            availableVehicle,
            _handleAssignUnitToCompany,
            companyId
        } = props.store.dataEntry;
        const { selectedCompany } = props.store.adminPanel;
        const default_img = '/cars/default_vehicle.png';
        return (
            <div
                className="modal fade show"
                id="AssignVehiclesJQ"
                tabIndex="-1"
                aria-labelledby="AssignVehiclesJQ"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg rounded">
                    <div
                        className="modal-content"
                        style={{
                            background: Colors[theme].background,
                            color: invertDark(theme),
                        }}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title">{t("Assign Units For")}{" "}
                                {
                                    user.find((v) => v.id === selectedCompany)?.name}
                            </h5>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-6">
                                    {t("Available Units")}
                                    {availableVehicle?.map((vehicle, index) => {
                                        return (
                                            <div
                                                className="py-2 mt-1"
                                                style={{
                                                    background:
                                                        theme === "dark"
                                                            ? "#384067"
                                                            : "#C4C4C4",
                                                    borderRadius: 6,
                                                    // minWidth: 300,
                                                }}
                                            >

                                                <div className="d-flex align-items-center px-1">
                                                    <span className="p-2" style={{ cursor: "pointer" }}>
                                                        <Checkbox
                                                            checked={false}
                                                            value={vehicle.plate_number}
                                                            onClick={() => {
                                                                _handleAssignUnitToCompany(vehicle.id, selectedCompany);
                                                            }
                                                            }
                                                            style={{
                                                                color: Colors.activeColor,
                                                            }}
                                                        />
                                                    </span>

                                                    <img
                                                        className="bg-white rounded"
                                                        style={{ width: 100, height: 46, objectFit: 'cover' }}
                                                        src={
                                                            vehicle.image ? baseURL +"/uploads/devices/" +vehicle.image : default_img
                                                        }
                                                    />


                                                    <div
                                                        className="col mx-2"
                                                    >
                                                        <div>
                                                            <span className="col" style={{ fontSize: 16 }}>
                                                                {vehicle.plate_number ? vehicle.plate_number : vehicle.grouping}

                                                            </span></div>
                                                        <div className="d-flex">
                                                            <span style={{ fontSize: 12 }}>
                                                                {vehicle.fuel_type}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="col-6">
                                    {t("Selected Units")}
                                    {selectedVehicle?.map((vehicle, index) => {
                                        return (
                                            <div
                                                className="py-2 mt-1"
                                                style={{
                                                    background:
                                                        theme === "dark"
                                                            ? "#384067"
                                                            : "#C4C4C4",
                                                    borderRadius: 6,
                                                    // minWidth: 300,
                                                }}
                                            >

                                                <div className="d-flex align-items-center px-1">
                                                    {vehicle.isLock === 1 ? 
                                                        <span className="p-2" style={{ cursor: "pointer" }}>
                                                            <i className="fas fa-lock py-2"></i>
                                                        </span> 
                                                    : <span className="p-2" style={{ cursor: "pointer" }}>
                                                        <Checkbox
                                                            checked={true}
                                                            value={vehicle.plate_number}
                                                            onClick={(e) => {
                                                                _handleAssignUnitToCompany(vehicle.id, selectedCompany);
                                                            }
                                                            }
                                                            style={{
                                                                color: Colors.activeColor,
                                                            }}
                                                        />
                                                    </span>}


                                                    <img
                                                        className="bg-white rounded"
                                                        style={{ width: 100, height: 50, objectFit: 'cover' }}
                                                        src={ 
                                                            vehicle.image ? baseURL +"/uploads/devices/" +vehicle.image : default_img
                                                        }
                                                    />
                                                    <div
                                                        className="col mx-2"
                                                    >
                                                        <div>
                                                            <span className="col" style={{ fontSize: 16 }}>
                                                                {vehicle.plate_number}

                                                            </span></div>
                                                        <div className="d-flex">
                                                            <span style={{ fontSize: 12 }}>
                                                                {vehicle.fuel_type}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" aria-label="Close">{t("Save changes")}</button>
                        </div>

                    </div>
                </div>



            </div>
        );
    }));



