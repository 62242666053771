/* eslint-disable react/jsx-pascal-case */
import { inject, observer } from "mobx-react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import month from "../../Assets/month.json";
import { My_Button, My_Input, My_DatePicker } from "../../Tools";
import { Checkbox, useForkRef } from "@mui/material";
import { ImageUpload } from "./ImageUpload";
import { ImageViewModel } from "./ImageViewModel";
import { ImageToTextModal } from "./ImageToTextModal";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import plusIcon from "../../Assets/Images/icons/plusIcon.png";
import { useEffect, useState } from "react";
import { toJS } from "mobx";
import Carousel from "react-bootstrap/Carousel";
import { baseIMGURL, baseURL } from "../../network/URL";
import { Cookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { ConfirmationModal } from "./ConfirmationModal";
import { FaInfoCircle } from "react-icons/fa";
import { Tooltip } from "react-tooltip";

export const DataEntryEditForm = inject("store")(
  observer((props) => {
    const { t } = useTranslation("new-manuel-entry")
    const { theme, newAudit } = props;
    const {
      currentMonth,
      currentYear,
      currentType,
      consumedStr,
      _handleValueChange,
      fileFuelName,
      _handleChangeCaptureDate,
      captureOdometer,
      fileStartOfMonth,
      fileEndOfMonth,
      _handleFileChange,
      _handleImageToTextClick,
      isCheck,
      _handleCheckBox,
      _handleSubmitEntryData,
      odometerStartStr,
      odometerStartInputValue,
      odometerEndStr,
      totalMileageStr,
      vehicleData,
      _handleClearForm,
      fileStartName,
      fileEndName,
      fileFuel,
      fileFuelRecords,
      imageRef,
      convertText,
      fileStart,
      fileEnd,
      _handleClearImage,
      _handleImageViewClick,
      consumedInfo,
      odometerStartInfo,
      odometerEndInfo,
      tripMilageInfo,
      imagePreviewNames,
      fuelType,
      filefueltype,
      quantity,
      handleAddAudit,
      _handleDeleteAudit,
      setImageText,
      auditImages,
      imageTextView,
      _handleImageTextView,
      _handleImageTextValue,
      consumptionByMonthRowData,
      odometerFiles,
      _handleURLtoFile,
      odometerStartRecord,
      odometerEndRecord,
      _handleAudittoFile,
      _handleImageText,

    } = props.store.dataEntry;

    const { _fetchMonthlyData } = props.store.trackAndTrace
    const { role } = props.store.common;
    const cookies = new Cookies();

    const _handleFileChangeNew = (e, type, index) => {
      if (role === "viewer") {
        toast.error("Viewer cannot upload image");
      } else {
        _handleFileChange(e, type, index);
      }
    };

    const cardStyle = {
      background: Colors[theme]?.background,
      boxShadow: "2px 4px 11px rgba(0,0,0,0.25)",
      padding: "10px",
      width: "420px",
      borderRadius: "15px",
      fontSize: "12px",
      color: Colors.Dark[theme === "dark" ? 4 : 1],
      height: "145px",
      transitionDuration: "0.1s",
    };

    

    const openConfirmModal = () => {
      let modal = new window.bootstrap.Modal(document.getElementById('confirmmodal'))
      modal.show()
    }

    const openOCRModal = () => {
      let modal = new window.bootstrap.Modal(document.getElementById('imageToText'))
      modal.show()
    }

    console.log("NEWAUDIT -__- _--_", toJS(newAudit))

    return (
      <div
        className="mx-1 p-3"
        style={{
          borderRadius: "7px",
          color: invertDark(theme),
          boxShadow:
            theme === "dark" ? "none" : "0px 0px 10px rgba(75,171,237,0.35)",
        }}
      >
        <div className="d-flex justify-content-between pb-1">
          <div style={{ fontSize: 16 }}>
            {t("Editing data for")} <b></b>
            {`${vehicleData[0].plate_no}`} (
            {month[currentMonth + 1].label + " " + currentYear})
          </div>
          <div style={{ fontSize: 16 }}>
            {/* {month.find((v) => v.index === currentMonth).month}-{currentYear} */}

            <i
              onClick={() => _handleClearForm()}
              style={{ cursor: "pointer" }}
              className="bi bi-x-lg"
            ></i>
          </div>
        </div>
        <div
          className="m-2 p-2"
          style={{ borderRadius: "4px", border: `1px solid ${Colors.Dark[2]}` }}
        >
          {/* <div className="d-flex flex-row flex-wrap justify-content-around"> */}
          <div className="row justify-content-between align-items-center">
            {!vehicleData[0]?.CAMBus && vehicleData[0]?.GPS || !vehicleData[0]?.CAMBus && !vehicleData[0]?.GPS ?
              newAudit?.map((v, i) => {
                return (
                  <div key={i} className="d-flex justify-content-around pb-3">
                    <div className="d-flex justify-content-center">
                      {i == 0 ? (
                        <div className="d-flex justify-content-center align-items-center ps-1 pb-5 pt-2">
                          <div
                            onClick={handleAddAudit}
                            style={{
                              border: "1px solid gray",
                              width: "40px",
                              height: "40px",
                              borderRadius: 5,
                              cursor: "pointer",
                            }}
                            className="d-flex align-items-center justify-content-center"
                          >
                            <img width="20px" src={plusIcon} />
                          </div>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-center align-items-center ps-1 pb-5 pt-2">
                          <div
                            onClick={() => _handleDeleteAudit(v.id, i)}
                            style={{
                              border: "1px solid gray",
                              width: "40px",
                              height: "40px",
                              borderRadius: 5,
                              cursor: "pointer",
                            }}
                            className="d-flex align-items-center justify-content-center"
                          >
                            <i width="20px" className="fa fa-trash text-danger" />
                          </div>
                        </div>
                      )}
                      <div
                        className="m-1 ms-5"
                        htmlFor={`consumed` + i}
                        style={{ width: "350px", height: "130px" }}
                      >
                        {currentType === "ev"
                          ? t("Electricity Consumed (kWh)")
                          : t("Fuel Consumed (L)")}
                        <span className="float-end mt-1" style={{ fontSize: 10 }}>
                          {consumedInfo}
                        </span>
                        <My_Input
                          id={`consumed` + i}
                          value={v.consumedStr}
                          //disabled={updateData?.fuelConsumed?.includes("ascent")}
                          customColor={Colors.dark.primaryColor}
                          onChange={(e) => { _handleValueChange(e, `consumed`, i); _handleImageText(i) }
                          }
                        />

                        {/* {v.image_text ? (
                        !imageTextView ? (
                          v.image_text === "Audited with OCR" ? (
                            <span>
                              <i
                                className="bi bi-check-circle-fill"
                                style={{ color: "green" }}
                              ></i>
                              {v.image_text}
                            </span>
                          ) : v.image_text ===
                            `Fuel type of uploaded file does not match with expected (Expected ${fuelType})` ? (
                            <span>
                              <i
                                className="bi bi-x-circle-fill"
                                style={{ color: "red" }}
                              ></i>
                              {v.image_text}
                            </span>
                          ) : v.image_text ? (
                            <span>
                              <i
                                className="bi bi-dash-circle-fill"
                                style={{ color: "rgb(126 66 31)" }}
                              ></i>
                              No file uploaded for audit
                            </span>
                          ) : (
                            <span>
                              <i
                                className="bi bi-x-circle-fill"
                                style={{ color: "red" }}
                              ></i>
                              {v.image_text}
                            </span>
                          )
                        ) : (
                          ""
                        )
                      ) : (
                        <span>
                          <i
                            className="bi bi-dash-circle-fill"
                            style={{ color: "rgb(126 66 31)" }}
                          ></i>
                          No file uploaded for audit
                        </span>
                      )} */}

                        {
                          // !v.fuel_recordFilePath || v.fileFuel ?
                          //     <span>
                          //         <i className="bi bi-dash-circle-fill" style={{ color: "rgb(126 66 31)" }}></i>
                          //         No file uploaded for audit
                          //     </span>
                          //     :
                          v.image_text ?
                            v.image_text === "Audited with OCR" ?
                              <span>
                                <i className="bi bi-check-circle-fill" style={{ color: "green" }}></i>
                                {v.image_text}
                              </span>
                              : v.image_text === `Fuel type of uploaded file does not match with expected (Expected ${fuelType})` ?
                                <span>
                                  <i className="bi bi-x-circle-fill" style={{ color: "red" }}></i>
                                  {v.image_text}
                                </span>
                                : v.image_text ?
                                  <span>
                                    <i className="bi bi-x-circle-fill" style={{ color: "red" }}></i>
                                    {v.image_text}
                                  </span>

                                  : ''

                            : <span>
                              <i className="bi bi-dash-circle-fill" style={{ color: "rgb(126 66 31)" }}></i>{t("Perform OCR for results")}
                            </span>
                        }

                        {/* {imageTextView ?
                                                v.filefueltype.toLowerCase() === fuelType.toLowerCase() && v.quantity?.match(/\d+\.\d+|\d+ltr+/g) && v.quantity.match(/\d+\.\d+|\d+ltr+/g)[0] === v.consumedStr ? (
                                                    <>
                                                        {setImageText("Audited with OCR", i)}
                                                        <span>
                                                            <i className="bi bi-check-circle-fill" style={{ color: "green" }}></i>
                                                            Audited with OCR
                                                        </span>
                                                    </>
                                                    // ) : v.filefueltype === "" && v.quantity === "" ? (

                                            //     <span>
                                            //         <i className="bi bi-dash-circle-fill" style={{ color: "rgb(126 66 31)" }}></i>
                                            //         No file uploaded for audit
                                            //     </span>

                                                ) :
                                                    v.filefueltype.toLowerCase() !== fuelType.toLowerCase() ? (
                                                        <>
                                                            {setImageText(`Fuel type of uploaded file does not match with expected (Expected ${fuelType})`, i)}
                                                            <span>
                                                                <i className="bi bi-x-circle-fill" style={{ color: "red" }}></i>
                                                                Fuel type of uploaded file does not match with expected (Expected {fuelType})
                                                            </span>
                                                        </>
                                                    ) :
                                                        v.quantity?.match(/\d+\.\d+|\d+ltr+/g) && v.quantity?.match(/\d+\.\d+|\d+ltr+/g)[0] !== v.consumedStr ? (
                                                            <>
                                                                {setImageText(`Data does not match (Manual Entry: ${v.consumedStr}, File OCR: ${v.quantity?.match(/\d+\.\d+|\d+ltr+/g)[0]})`, i)}
                                                                <span>
                                                                    <i className="bi bi-x-circle-fill" style={{ color: "red" }}></i>
                                                                    Data does not match (Manual Entry: {v.consumedStr}, File OCR: {v.quantity?.match(/\d+\.\d+|\d+ltr+/g)[0]})
                                                                </span>
                                                            </>
                                                        ) : null
                                                : ''

                                            } */}
                      </div>

                    </div>

                    <div className="m-1">
                      <div className="d-flex flex-column justify-content-center">
                        {t("Upload Image of")}{" "}
                        {currentType === "ev" ? t("Charging") : t("Fuel")} {t("Receipts")}
                        {/* <label className="w-100" htmlFor={`image-upload-fuel`}> */}
                        <div
                          className="d-flex align-items-center justify-content-between w-100"
                          style={{ cursor: "pointer" }}
                        >
                          <label
                            className="w-100"
                            htmlFor={`image-upload-fuel` + i}
                          >
                            <div
                              className="d-flex input-group"
                              style={{ cursor: "pointer", width: "100%" }}
                            >
                              <input
                                type="text"
                                style={{
                                  background: theme === "dark" && Colors.darkBlue,
                                  color: invertDark(theme),
                                }}
                                aria-describedby="basic-addon1"
                                className="form-control"
                                value={
                                  v.fileFuelName === "null" ? "" : v.fileFuelName
                                }
                                placeholder="Choose image"
                                disabled="false"
                              />
                              <span
                                className="input-group-text"
                                id="basic-addon1"
                              >
                                {t("Browse")}
                              </span>
                              <input
                                type="file"
                                style={{ display: "none" }}
                                id={`image-upload-fuel` + i}
                                accept="application/pdf,image/x-png,image/gif,image/jpeg"
                                onClick={(e) => (e.target.value = null)}
                                onChange={(e) => {
                                  _handleFileChangeNew(e, "fuel", i)
                                  _handleImageToTextClick(i, v.id);
                                  _handleImageTextView();
                                  openOCRModal()
                                }}
                              // data-bs-toggle="modal"
                              // data-bs-target="#imageToText"
                              />
                            </div>
                          </label>
                          {/* <div>
                          <Button
                            type="button"
                            customColor={Colors.low}
                            style={{
                              backgroundColor: Colors.low,
                              marginLeft: "10px",
                              width: "100px",
                              height: "40px",
                              alignItems: "center",
                              display: "flex",
                              // justifyContent: "center",
                              fontSize: "13px",
                            }}
                            onClick={() => {
                              _handleImageToTextClick(i, v.id);
                              _handleImageTextView();
                            }}
                            data-bs-toggle={
                              !v.fuel_recordFilePath && !v.fileFuel
                                ? ""
                                : "modal"
                            }
                            data-bs-target={
                              !v.fuel_recordFilePath && !v.fileFuel
                                ? ""
                                : "#imageToText"
                            }
                          >
                            {t("Perform OCR audit")}
                          </Button>
                        </div> */}
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex">
                            <p>{t("Type Status")} : </p>
                            <p style={{ color: "rgb(47 191 47)" }}>
                              {v.type_status === "null"
                                ? "N/A"
                                : v.type_status === undefined
                                  ? "N/A"
                                  : v.type_status === ""
                                    ? "N/A"
                                    : v.type_status}
                            </p>
                          </div>
                          <div className="d-flex">
                            <p>{t("Fuel Status")} : </p>
                            <p style={{ color: "rgb(47 191 47)" }}>
                              {v.fuel_status === "null"
                                ? "N/A"
                                : v.fuel_status === undefined
                                  ? "N/A"
                                  : v.fuel_status === ""
                                    ? "N/A"
                                    : v.fuel_status}
                            </p>
                          </div>
                        </div>
                        {/* <div style={{ border: '1px solid grey'}}>
                          {newAudit.find((v) => v.fuel_recordFilePath?.length > 0)
                            ?.fuel_recordFilePath ||
                            newAudit.find((v) => v.fileFuel?.length > 0)?.fileFuel ? (
                            <Carousel
                              style={{ width: "150px" }}
                              variant={theme === "dark" ? "" : "dark"}
                            >
                              {newAudit.map((v, i) => (
                                <Carousel.Item>
                                  <div
                                    key={v.id}
                                    className="d-flex justify-content-center flex-column align-items-center"
                                  >
                                    <span
                                      onClick={() =>
                                        _handleImageViewClick(
                                          v.fileFuelName,
                                          !v.image
                                            ? ""
                                            : !v.fileFuel
                                              ? baseIMGURL + v.fuel_recordFilePath
                                              : v.fileFuel,
                                          currentType === "ev"
                                            ? "Charging Receipt"
                                            : "Fuel Receipt"
                                        )
                                      }
                                    >
                                      <ImageUpload
                                        currentImage={
                                          !v.fuel_recordFilePath && !v.fileFuel
                                            ? ""
                                            : v.fileFuel
                                              ? v.fileFuel
                                              : v.fuel_recordFilePath === "null"
                                                ? ""
                                                : baseIMGURL + v.fuel_recordFilePath
                                        }
                                        fuelType={"fuel"}
                                        index={i}
                                        _handleClearImage={_handleClearImage}
                                        imgref={imageRef}
                                        uploadFileType={v.uploadFileType}
                                      />
                                    </span>
                                    <span className="text-center">
                                      {currentType === "ev"
                                        ? t("Charging Receipt")
                                        : t("Fuel Receipt")}
                                    </span>
                                  </div>
                                </Carousel.Item>
                              ))}
                            </Carousel>
                          ) : (
                            <div className="d-flex justify-content-center flex-column align-items-center">
                              <span
                                onClick={() =>
                                  _handleImageViewClick(
                                    fileFuelName,
                                    "",
                                    currentType === "ev"
                                      ? t("Charging Receipt")
                                      : t("Fuel Receipt")
                                  )
                                }
                              >
                                <ImageUpload
                                  currentImage={""}
                                  fuelType={"fuel"}
                                  // index={i}
                                  _handleClearImage={_handleClearImage}
                                  imgref={imageRef}
                                />
                              </span>
                              <span className="text-center">
                                {currentType === "ev"
                                  ? t("Charging Receipt")
                                  : t("Fuel Receipt")}
                              </span>
                            </div>
                          )}
                        </div> */}
                      </div>
                    </div>
                    <div style={{ width: "180px" }} className={`${vehicleData[0]?.GPS && !vehicleData[0]?.CAMBus ? 'ps-3' : ''}`}>
                      <div className="m-1">
                        {t("Extracted Quantity")}
                        <p
                          className="w-100 mt-2"
                          style={{
                            color: "rgb(47 191 47)",
                          }}
                        >
                          {v.quantity ? v.quantity : t("No data")}
                        </p>
                      </div>
                    </div>
                    {!vehicleData[0].GPS && !vehicleData[0].CAMBus ?
                      <div className="m-1">
                        Verification
                        <p className={`text-center mt-2 ${v.status == 0 ? 'text-warning' : v.status == 1 ? 'text-success' : 'text-danger'}`}>
                          {v.status == 2 ? <span className="fas fa-exclamation-circle pe-2" style={{ cursor: "pointer" }} title={`${v.status_info}`}></span> : ""}
                          {v.status == 0 ? <span className="fas fa-exclamation-circle pe-2" style={{ cursor: "pointer" }} title="Need to validate by super admin."></span> : ""}
                          {v.status == 0 ? "PENDING" : v.status == 1 ? "VALIDATED" : "INVALIDATED"}
                        </p>
                      </div>
                      : 
                      <></>
                    }
                    {vehicleData[0].GPS && !vehicleData[0].CAMBus ?
                      <div className="m-1">
                        Verification
                        <p className={`text-center me-3 mt-2 ${v.status == 0 ? 'text-warning' : v.status == 1 ? 'text-success' : 'text-danger'}`}>
                          {v.status == 2 ? <span className="fas fa-exclamation-circle pe-2" style={{ cursor: "pointer" }} title={`${v.status_info}`}></span> : ""}
                          {v.status == 0 ? <span className="fas fa-exclamation-circle pe-2" style={{ cursor: "pointer" }} title="Need to validate by super admin."></span> : ""}
                           {v.status == 0 ? "PENDING" : v.status == 1 ? "VALIDATED" : "INVALIDATED"}

                        </p>
                      </div>
                      :
                      <></>
                    }
                    {vehicleData[0]?.GPS && !vehicleData[0]?.CAMBus || !vehicleData[0].GPS && !vehicleData[0].CAMBus ?
                      <div>
                        <div
                          className="d-flex justify-content-center flex-column align-items-center"
                        >

                          <span
                            onClick={() =>
                              _handleImageViewClick(
                                v.fileFuelName,
                                !v.image
                                  ? ""
                                  : !v.fileFuel
                                    ? baseIMGURL + v.fuel_recordFilePath
                                    : v.fileFuel,
                                currentType === "ev"
                                  ? "Charging Receipt"
                                  : "Fuel Receipt",
                                v.uploadFileType,
                                i
                              )
                            }
                          >
                            <ImageUpload
                              currentImage={
                                !v.fuel_recordFilePath && !v.fileFuel
                                  ? ""
                                  : v.fileFuel
                                    ? v.fileFuel
                                    : v.fuel_recordFilePath === "null" || v.fuel_recordFilePath === ""
                                      ? ""
                                      : baseIMGURL + v.fuel_recordFilePath
                              }
                              fuelType={"fuel"}
                              index={i}
                              _handleClearImage={_handleClearImage}
                              imgref={imageRef}
                              uploadFileType={v.uploadFileType}
                              imageName={v.fileFuelName}
                              _handleImageViewClick={_handleImageViewClick}
                              currentType={currentType}
                            />
                          </span>
                        </div>
                      </div>
                      : ""
                    }
                  </div>
                );
              }) : ""}
          </div>

          <hr className="my-0" style={{ background: Colors.Dark[2] }} />

          {!vehicleData[0]?.GPS && vehicleData?.CAMBus || !vehicleData[0]?.CAMBus && !vehicleData[0]?.GPS ?
            <>
              <div className="d-flex flex-row flex-wrap justify-content-around">
                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-12">
                  <div className="m-1 mb-2">
                    {t("Date captured for Odometer")}
                    <My_DatePicker
                      id={"date"}
                      height={"43px"}
                      selected={captureOdometer}
                      onChange={_handleChangeCaptureDate}
                    />
                  </div>
                </div>

                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-12"></div>
              </div>

              <div className="d-flex flex-row flex-wrap justify-content-around  mb-2">
                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-12">
                  {t("Odometer")}
                  <div
                    className="col p-2 mt-2"
                    style={{
                      border: "1px solid #C6C6C6",
                    }}
                  >
                    <div className="m-1 mb-2">
                      {t('Odometer Reading at Start of Month')} (km)
                      <span className=" float-end mt-1" style={{ fontSize: 10 }}>
                        {odometerStartInfo}
                      </span>
                      <My_Input
                        id={"odometerStart"}
                        type={"number"}
                        value={odometerStartInputValue}
                        onChange={(e) => _handleValueChange(e, "odometerStart")}
                        disabled={
                          odometerStartStr == 0 || odometerStartStr === null
                            ? false
                            : true
                        }
                      />
                    </div>

                    <div className="m-1 mb-2">
                      {t("Odometer Reading at End of Month")} (km)
                      <span className=" float-end mt-1" style={{ fontSize: 10 }}>
                        {odometerEndInfo}
                      </span>
                      <My_Input
                        id={"odometerEnd"}
                        type={"number"}
                        value={odometerEndStr}
                        onChange={(e) => _handleValueChange(e, "odometerEnd")}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-12">
                  {t("Upload")}
                  <div
                    className="col p-2 mt-2"
                    style={{
                      border: "1px solid #C6C6C6",
                    }}
                  >
                    <div className="m-1 mb-2">
                      {t("Upload Image of Odometer at Start of the Month")}
                      <label className="w-100" htmlFor={`image-upload-start`}>
                        <div
                          className="input-group"
                          style={{ cursor: "pointer", width: "100%" }}
                        >
                          <input
                            type="text"
                            style={{
                              background: theme === "dark" && Colors.darkBlue,
                              color: invertDark(theme),
                            }}
                            aria-describedby="basic-addon2"
                            className="form-control"
                            value={
                              // fileStartOfMonth
                              //   ? fileStartOfMonth.name
                              //   : fileStartName

                              odometerFiles?.find(v => v.id === consumptionByMonthRowData.id)?.fileStartName

                            }
                            placeholder="Choose image"
                            disabled="false"
                          />
                          <span className="input-group-text" id="basic-addon2">
                            {t("Browse")}
                          </span>
                          <input
                            type="file"
                            style={{ display: "none" }}
                            id={`image-upload-start`}
                            accept="image/x-png,image/gif,image/jpeg"
                            onClick={(e) => (e.target.value = null)}
                            onChange={(e) => {
                              _handleFileChangeNew(e, "start");
                            }}
                          />
                        </div>
                      </label>
                    </div>

                    <div className="m-1 mb-3">
                      {t("Upload Image of Odometer at End of the Month")}
                      <label className="w-100" htmlFor={`image-upload-end`}>
                        <div className="input-group" style={{ cursor: "pointer" }}>
                          <input
                            type="text"
                            style={{
                              background: theme === "dark" && Colors.darkBlue,
                              color: invertDark(theme),
                            }}
                            aria-describedby="basic-addon3"
                            className="form-control"
                            value={
                              // fileEndOfMonth ? fileEndOfMonth.name : fileEndName?.length > 0 ? fileEndName : ""
                              odometerFiles?.find(v => v.id === consumptionByMonthRowData.id)?.fileEndName
                            }
                            placeholder="Choose image"
                            disabled="false"
                          />
                          <span className="input-group-text" id="basic-addon3">
                            {t("Browse")}
                          </span>
                          <input
                            type="file"
                            style={{ display: "none" }}
                            id={`image-upload-end`}
                            accept="image/x-png,image/gif,image/jpeg"
                            onClick={(e) => (e.target.value = null)}
                            onChange={(e) => {
                              _handleFileChangeNew(e, "end");
                            }}
                          />
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <hr className="my-0" style={{ background: Colors.Dark[2] }} />

              <div className="d-flex flex-row flex-wrap justify-content-around">
                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-12">
                  <div className="m-1 mb-2">
                    {t("Total Mileage")}  (km)
                    <span className=" float-end mt-1" style={{ fontSize: 10 }}>
                      {tripMilageInfo}
                    </span>
                    {/* <span className=" float-end mt-1" style={{ fontSize: 12 }}>
                                    <i
                                        className="fa fa-check-circle"
                                        style={{ color: Colors.low, fontSize: 13 }}
                                    />{" "}
                                    Data from Ascent
                                </span> */}
                    <My_Input
                      disabled={true}
                      id={"odometerStart"}
                      type={"number"}
                      value={totalMileageStr}
                      onChange={(e) => _handleValueChange(e, "mileage")}
                    />
                  </div>
                </div>

                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-12">
                  {t("Image Preview")}
                  <div 
                    className="col-6 m-1 mb-2 p-2"
                    style={{
                      border: "1px solid #C6C6C6",
                    }}
                  >
                    <div className="d-flex flex-row align-items-center gap-4">
                      {/* {newAudit.find(v => v.fileFuel !== null)?.fileFuel?.length > 0 ?
                                        <div id="fuelcarousel" className="carousel slide" data-bs-ride="carousel" style={{ width: '150px' }}>
                                            <div className="carousel-inner">
                                                {newAudit.map((v, i) => 
                                                    
                                                    <div key={v.id} className={`carousel-item active`}>
                                                        <div className="d-flex justify-content-center flex-column align-items-center">
                                                            <span onClick={() => _handleImageViewClick(v.fileFuelName, v.fileFuel, currentType === "ev" ? "Charging Receipt" : "Fuel Receipt")}>
                                                                <ImageUpload currentImage={v.fileFuel}
                                                                    fileType={"fuel"}
                                                                    _handleClearImage={_handleClearImage}
                                                                    imgref={imageRef}
                                                                />
                                                            </span>
                                                            <span className="text-center">{currentType === "ev" ? "Charging Receipt" : "Fuel Receipt"}</span>
                                                        </div>
                                                    </div>
                                                    
                                                )}
                                            </div>
                                            {newAudit.filter(v => v.fileFuel !== null).length > 1  ?
                                                <>
                                                    <button className="carousel-control-prev" type="button" data-bs-target="#fuelcarousel" data-bs-slide="prev">
                                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                        <span className="visually-hidden">Previous</span>
                                                    </button>
                                                    <button className="carousel-control-next" type="button" data-bs-target="#fuelcarousel" data-bs-slide="next">
                                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                        <span className="visually-hidden">Next</span>
                                                    </button>
                                                </>
                                                : ""
                                            }
                                                
                                        </div>
                                        :
                                        <div className="d-flex justify-content-center flex-column align-items-center">
                                            <span onClick={() => _handleImageViewClick(fileFuelName, fileFuel, currentType === "ev" ? "Charging Receipt" : "Fuel Receipt")}>
                                                <ImageUpload currentImage={newAudit.find(v => v.fileFuel !== null)?.fileFuel}
                                                    fileType={"fuel"}
                                                    _handleClearImage={_handleClearImage}
                                                    imgref={imageRef}
                                                />
                                            </span>
                                            <span className="text-center">{currentType === "ev" ? "Charging Receipt" : "Fuel Receipt"}</span>
                                        </div>
                                    } */}
                      {/* {newAudit.find((v) => v.fuel_recordFilePath?.length > 0)
                        ?.fuel_recordFilePath ||
                        newAudit.find((v) => v.fileFuel?.length > 0)?.fileFuel ? (
                        <Carousel
                          style={{ width: "150px" }}
                          variant={theme === "dark" ? "" : "dark"}
                        >
                          {newAudit.map((v, i) => (
                            <Carousel.Item>
                              <div
                                key={v.id}
                                className="d-flex justify-content-center flex-column align-items-center"
                              >
                                <span
                                  onClick={() =>
                                    _handleImageViewClick(
                                      v.fileFuelName,
                                      !v.image
                                        ? ""
                                        : !v.fileFuel
                                          ? baseIMGURL + v.fuel_recordFilePath
                                          : v.fileFuel,
                                      currentType === "ev"
                                        ? "Charging Receipt"
                                        : "Fuel Receipt"
                                    )
                                  }
                                >
                                  <ImageUpload
                                    currentImage={
                                      !v.fuel_recordFilePath && !v.fileFuel
                                        ? ""
                                        : v.fileFuel
                                          ? v.fileFuel
                                          : v.fuel_recordFilePath === "null"
                                            ? ""
                                            : baseIMGURL + v.fuel_recordFilePath
                                    }
                                    fuelType={"fuel"}
                                    index={i}
                                    _handleClearImage={_handleClearImage}
                                    imgref={imageRef}
                                    uploadFileType={v.uploadFileType}
                                  />
                                </span>
                                <span className="text-center">
                                  {currentType === "ev"
                                    ? t("Charging Receipt")
                                    : t("Fuel Receipt")}
                                </span>
                              </div>
                            </Carousel.Item>
                          ))}
                        </Carousel>
                      ) : (
                        <div className="d-flex justify-content-center flex-column align-items-center">
                          <span
                            onClick={() =>
                              _handleImageViewClick(
                                fileFuelName,
                                "",
                                currentType === "ev"
                                  ? t("Charging Receipt")
                                  : t("Fuel Receipt")
                              )
                            }
                          >
                            <ImageUpload
                              currentImage={""}
                              fuelType={"fuel"}
                              // index={i}
                              _handleClearImage={_handleClearImage}
                              imgref={imageRef}
                            />
                          </span>
                          <span className="text-center">
                            {currentType === "ev"
                              ? t("Charging Receipt")
                              : t("Fuel Receipt")}
                          </span>
                        </div>
                      )} */}

                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <span
                          onClick={() =>
                            _handleImageViewClick(
                              odometerFiles?.find(v => v.id === consumptionByMonthRowData.id)?.fileStartName,
                              odometerFiles?.find(v => v.id === consumptionByMonthRowData.id)?.fileStart,
                              "Odometer Start"
                            )
                          }
                        >
                          <ImageUpload
                            currentImage={odometerFiles?.find(v => v.id === consumptionByMonthRowData.id)?.fileStart}
                            fileType={"start"}
                            _handleClearImage={_handleClearImage}
                          />
                        </span>
                        <span>{t("Odometer Start")}</span>
                      </div>

                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <span
                          onClick={() =>
                            _handleImageViewClick(
                              odometerFiles?.find(v => v.id === consumptionByMonthRowData.id)?.fileEndName,
                              odometerFiles?.find(v => v.id === consumptionByMonthRowData.id)?.fileEnd,
                              "Odometer End"
                            )
                          }
                        >
                          <ImageUpload
                            currentImage={odometerFiles?.find(v => v.id === consumptionByMonthRowData.id)?.fileEnd}
                            fileType={"end"}
                            _handleClearImage={_handleClearImage}
                          />
                        </span>
                        <span>{t("Odometer End")}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
            : ""
          }
        </div>

        <div style={{ fontSize: 16 }}>
          <i
            className={`fas fa-exclamation-circle px-2`}
            style={{
              color: Colors.medium,
            }}
          />
          {t("Please note that editing these fields will affect carbon emissions calculation of the vehicle.")}
        </div>

        <div className="d-flex flex-row flex-wrap justify-content-between mx-2">
          <span style={{ cursor: "pointer" }} onClick={() => _handleCheckBox()}>
            <Checkbox checked={isCheck} style={{ color: Colors.activeColor }} />
            {t("I confirm that the above data is accurate to the best of my knowledge.")}<br></br>
          </span>

          <div className="mx-1">
            <My_Button
              text={t("Submit")}
              type={"submit"}
              disabled={
                role === "viewer"
                  ? true
                  : isCheck === true
                    ? false
                    : consumedStr === "" &&
                      odometerStartStr === "" &&
                      odometerEndStr === "" &&
                      totalMileageStr === ""
                      ? false
                      : true
              }
              customColor={Colors.low}
              style={{
                backgroundColor: Colors.low,
                width: "100px",
                height: "35px",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={() => {
                // _handleSubmitEntryData();
                _handleURLtoFile(odometerFiles?.find(v => v.id === consumptionByMonthRowData.id)?.fileStart, odometerFiles?.find(v => v.id === consumptionByMonthRowData.id)?.fileStartName, "start")
                _handleURLtoFile(odometerFiles?.find(v => v.id === consumptionByMonthRowData.id)?.fileEnd, odometerFiles?.find(v => v.id === consumptionByMonthRowData.id)?.fileEndName, "end")
                // _handleAudittoFile()

                openConfirmModal()
                _handleImageTextValue();
                _fetchMonthlyData(currentYear)
              }}
            />
          </div>
        </div>
        <ImageViewModel theme={theme} newAudit={newAudit} />
        <ImageToTextModal theme={theme} />
        <ConfirmationModal theme={theme} _handleSubmitEntryData={_handleSubmitEntryData} />
      </div>
    );
  })
);
