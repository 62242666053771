import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { CO2_Loading } from "../../Assets/CO2_Loading";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { CompanySelector } from "../../Components/Dashboard/companySelector";
import { FilterBarWithMonth } from "../../Components/Tracking2/filterBarWithMonth";
import { Profile } from "../../Components/Tracking2/Profile";
import { TrackingFileExportModal } from "../../Components/Tracking2/trackingFileExportModal";
import { TrackingTableView } from "../../Components/Tracking2/TrackingTableView";
import AccessDeniedPage from "../../Pages/accessdenied";
import { My_Button, Selector } from "../../Tools";
import { NotificationCard } from "../../Tools/lastUpdate";
import Store from "../../Store";
import { FilterBarForComparison } from "../../Components/Tracking2/filterBarForComparison";
import { CarbonComparisonView } from "../../Components/Tracking2/CarbonComparisonView";
import { useTranslation } from "react-i18next";
import { UpgradeInProgress } from "../../Components/Tracking2/UpgradeInProgress";
import Tour from "./unitLevelTour";

export const NewTrackingContainer = inject("store")(
  observer((props) => {
    const { t } = useTranslation("new-tracking");
    const { theme, width } = props;

    const { _handleFetchCompanyYears } = props.store.dashboard;

    const { lastUpdate, userType, role, companyType } = props.store.common;
    const {
      fmsVehicleLst,
      fetchFMSVehicles,
      handleViewChange,
      showView,
      unitSetting,
      handleComparisonSelectChange,
      showComparisonSelect,
      showChartViewVTwo,
      _handleGetDaysRange,
      selectedAsset,
      handleRowSelect,
      assetProfile,
      handleSelecteDetail,
    } = props.store.trackingVTwo;

    const [setPreviewVehicle] = useState("");

    const isLoading = !assetProfile;

    useEffect(() => {
      _handleFetchCompanyYears();
      if (selectedAsset) {
        handleRowSelect(selectedAsset);
      } else {
        fetchFMSVehicles();
        //fetchGetAllCompanyVehicles();
      }
      _handleGetDaysRange();
      handleViewChange("chart");
    }, []);

    const cardStyle1 = {
      // background: Colors[theme].background,
      boxShadow: "0px 5px 10px rgba(0,0,0,0.25)",
      padding: "10px",
      borderRadius: "5px",
      marginBottom: 8,
      color: invertDark(theme),
      height: width < 701 ? "100%" : "",
      paddingLeft: width < 701 ? "10px" : "",
    };

    const rowStyle = {
      background: theme === "dark" ? Colors.darkBlue : Colors.Dark[5],
      borderRadius: "5px",
      fontSize: "12px",
      flex: 1,
    };

    const _handlePreviewVehicle = (asset_name) => {
      setPreviewVehicle(asset_name);
    };

    console.log("assetProfile ---> ", toJS(assetProfile));

    if (width < 701) {
      return fmsVehicleLst ? (
        <div>
          <NotificationCard theme={theme} lastUpdate={lastUpdate} />

          <div
            className="p-0 m-0 d-flex justify-content-between align-items-center"
            style={{
              fontSize: 23,
              color:
                theme === "dark" ? Colors["light"].background : Colors.Dark[2],
            }}
          >
            <div className="d-flex">
              <div className="title-unitLevel-guide" style={{ width: 122 }}>
                {t("Unit-level")}
              </div>
              {/* <Tour/> */}
            </div>
            <div className="col-md-3">
              <div className="btn-group btn-group-sm" role="group">
                <div
                  type="button"
                  style={{ zIndex: 0 }}
                  className={`${
                    showComparisonSelect === "individual"
                      ? theme === "dark"
                        ? "btn-custom-individual"
                        : "btn-custom-individual-light"
                      : theme === "dark"
                      ? "btn-custom-individual-inactive"
                      : "btn-custom-individual-light-inactive"
                  }`}
                  onClick={() => handleComparisonSelectChange("individual")}
                >
                  {t("Individual")}
                </div>
                <div
                  type="button"
                  style={{ zIndex: 0 }}
                  className={`fleet-unitLevel-guide ${
                    showComparisonSelect === "comparison"
                      ? theme === "dark"
                        ? "btn-custom-comparison"
                        : "btn-custom-comparison-light"
                      : theme === "dark"
                      ? "btn-custom-comparison-inactive"
                      : "btn-custom-comparison-light-inactive"
                  }`}
                  onClick={() => handleComparisonSelectChange("comparison")}
                >
                  {t("Fleet Aggregate")}
                </div>
              </div>
            </div>
          </div>

          <CompanySelector
            disabled={true}
            theme={theme}
            label={t("Organisation") + ": "}
          />

          {userType === "co2xlite" ? (
            <AccessDeniedPage
              message={
                "If you are interested in gaining access to Unit-level, please approach your CO2X account manager for details."
              }
            />
          ) : (
            <>
              {showComparisonSelect === "individual" ? (
                <div className="d-flex flex-column">
                  <FilterBarWithMonth
                    {...props}
                    cardStyle1={cardStyle1}
                    _handlePreviewVehicle={_handlePreviewVehicle}
                    width={props.width}
                    theme={theme}
                  />

                  <div className="d-flex justify-content-between">
                    <div className="col-md-3">
                      <div
                        className="btn-group btn-group-sm profileDetails-unitLevel-guide"
                        role="group"
                      >
                        <div
                          type="button"
                          style={{ zIndex: 0 }}
                          className={`${
                            showView === "chart"
                              ? unitSetting?.enableCANbus === false
                                ? theme === "dark"
                                  ? "btn-custom-profileNOCanBus"
                                  : "btn-custom-profileNOCanBus-light"
                                : theme === "dark"
                                ? "btn-custom-profile"
                                : "btn-custom-profile-light"
                              : theme === "dark"
                              ? "btn-custom-profile-inactive"
                              : "btn-custom-profile-light-inactive"
                          }`}
                          onClick={() => {
                            handleViewChange("chart");
                            handleSelecteDetail(null);
                          }}
                        >
                          {t("Profile")}
                        </div>

                        {companyType === "1" ? (
                          assetProfile?.emission_type_unit === "gCO2e/km" ? (
                            unitSetting?.enableCANbus === true ? (
                              <div
                                type="button"
                                style={{ zIndex: 0 }}
                                className={`${
                                  showView === "table"
                                    ? theme === "dark"
                                      ? "btn-custom-details"
                                      : "btn-custom-details-light"
                                    : theme === "dark"
                                    ? "btn-custom-details-inactive"
                                    : "btn-custom-details-light-inactive"
                                }`}
                                onClick={() => handleViewChange("table")}
                              >
                                {t("Details")}
                              </div>
                            ) : (
                              <></>
                            )
                          ) : (
                            <></>
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    {isLoading === true ? (
                      <></>
                    ) : role === "user" ? (
                      ""
                    ) : (
                      <span
                        data-bs-toggle="modal"
                        data-bs-target="#newtrackingPdfModal"
                        data-toggle="modal"
                        data-target=".bd-example-modal-lg"
                      >
                        <My_Button text={t("Create Report")} small />
                      </span>
                    )}
                  </div>
                  {showView === "chart" ? (
                    <div
                      style={{
                        height: window.innerWidth < 701 ? "1200px" : "",
                        paddingBottom: window.innerWidth < 701 ? "10px" : "",
                      }}
                    >
                      <Profile
                        cardStyle1={cardStyle1}
                        rowStyle={rowStyle}
                        theme={theme}
                        companyType={companyType}
                      />
                    </div>
                  ) : (
                    <TrackingTableView cardStyle1={cardStyle1} theme={theme} />
                    // <UpgradeInProgress theme={theme} />
                  )}
                  <TrackingFileExportModal
                    showView={showView}
                    showChartViewVTwo={showChartViewVTwo}
                  />
                </div>
              ) : (
                <>
                  <FilterBarForComparison theme={theme} />
                  <div
                    style={cardStyle1}
                    className="d-flex flex-row flex-wrap justify-content-center"
                  >
                    <CarbonComparisonView theme={theme} />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      ) : (
        <CO2_Loading />
      );
    } else {
      return fmsVehicleLst ? (
        <div>
          <NotificationCard theme={theme} lastUpdate={lastUpdate} />

          <span
            className="row p-0 m-0 d-flex"
            style={{
              fontSize: 23,
              color:
                theme === "dark" ? Colors["light"].background : Colors.Dark[2],
            }}
          >
            <div className="title-unitLevel-guide p-0" style={{ width: 100 }}>
              {t("Unit-level")}
            </div>
            {/* <Tour/> */}
            <div className="col-md-3">
              <div className="btn-group btn-group-sm" role="group">
                <div
                  type="button"
                  style={{ zIndex: 0 }}
                  className={`${
                    showComparisonSelect === "individual"
                      ? theme === "dark"
                        ? "btn-custom-individual"
                        : "btn-custom-individual-light"
                      : theme === "dark"
                      ? "btn-custom-individual-inactive"
                      : "btn-custom-individual-light-inactive"
                  }`}
                  onClick={() => handleComparisonSelectChange("individual")}
                >
                  {t("Individual")}
                </div>
                <div
                  type="button"
                  style={{ zIndex: 0 }}
                  className={`fleet-unitLevel-guide ${
                    showComparisonSelect === "comparison"
                      ? theme === "dark"
                        ? "btn-custom-comparison"
                        : "btn-custom-comparison-light"
                      : theme === "dark"
                      ? "btn-custom-comparison-inactive"
                      : "btn-custom-comparison-light-inactive"
                  }`}
                  onClick={() => handleComparisonSelectChange("comparison")}
                >
                  {t("Fleet Aggregate")}
                </div>
              </div>
            </div>
          </span>

          <CompanySelector
            disabled={true}
            theme={theme}
            label={t("Organisation") + ": "}
          />

          {userType === "co2xlite" ? (
            <AccessDeniedPage
              message={
                "If you are interested in gaining access to Unit-level, please approach your CO2X account manager for details."
              }
            />
          ) : (
            <>
              {showComparisonSelect === "individual" ? (
                <>
                  <FilterBarWithMonth
                    {...props}
                    cardStyle1={cardStyle1}
                    _handlePreviewVehicle={_handlePreviewVehicle}
                    width={props.width}
                    theme={theme}
                  />

                  <div className="d-flex justify-content-between">
                    <div className="col-md-3">
                      <div
                        className="btn-group btn-group-sm profileDetails-unitLevel-guide"
                        role="group"
                      >
                        <div
                          type="button"
                          style={{
                            zIndex: 0,
                            borderTopRightRadius: (assetProfile?.emission_type_unit)?.toLowerCase() === "gco2e/h" ? "5px" : "",
                              // assetProfile?.emission_type_unit === "gCO2e/h"
                              //   ? (unitSetting?.enableCANbus &&
                              //       !unitSetting?.enableGPS) ||
                              //     (!unitSetting?.enableCANbus &&
                              //       unitSetting?.enableGPS)
                              //     ? "5px"
                              //     : ""
                              //   : "5px",
                            borderBottomRightRadius: (assetProfile?.emission_type_unit)?.toLowerCase() === "gco2e/h" ? "5px" : "",
                              // assetProfile?.emission_type_unit === "gCO2e/h"
                              //   ? (unitSetting?.enableCANbus &&
                              //       !unitSetting?.enableGPS) ||
                              //     (!unitSetting?.enableCANbus &&
                              //       unitSetting?.enableGPS)
                              //     ? "5px"
                              //     : ""
                              //   : "5px",

                          }}
                          className={`${
                            showView === "chart"
                              ? unitSetting?.enableCANbus === false
                                ? theme === "dark"
                                  ? "btn-custom-profileNOCanBus"
                                  : "btn-custom-profileNOCanBus-light"
                                : theme === "dark"
                                ? "btn-custom-profile"
                                : "btn-custom-profile-light"
                              : theme === "dark"
                              ? "btn-custom-profile-inactive"
                              : "btn-custom-profile-light-inactive"
                          }`}
                          onClick={() => {
                            handleViewChange("chart");
                            handleSelecteDetail(null);
                          }}
                        >
                          {t("Profile")}
                        </div>
                        {/* {(unitSetting?.enableCANbus &&
                          !unitSetting?.enableGPS) ||
                        (!unitSetting?.enableCANbus &&
                          unitSetting?.enableGPS) ? (
                          <></>
                        ) : companyType === "1" ? (
                          <div
                            type="button"
                            style={{ zIndex: 0 }}
                            className={`${
                              showView === "table"
                                ? theme === "dark"
                                  ? "btn-custom-details"
                                  : "btn-custom-details-light"
                                : theme === "dark"
                                ? "btn-custom-details-inactive"
                                : "btn-custom-details-light-inactive"
                            }`}
                            onClick={() => handleViewChange("table")}
                          >
                            {t("Details")}
                          </div>
                        ) : (
                          <></>
                        )} */}

                        {(assetProfile?.emission_type_unit)?.toLowerCase() == "gco2e/km" ? (
                          (unitSetting?.enableCANbus &&
                            !unitSetting?.enableGPS) ||
                          (!unitSetting?.enableCANbus &&
                            unitSetting?.enableGPS) ? (
                            <></>
                          ) : (
                            <div
                              type="button"
                              style={{ zIndex: 0 }}
                              className={`${
                                showView === "table"
                                  ? theme === "dark"
                                    ? "btn-custom-details"
                                    : "btn-custom-details-light"
                                  : theme === "dark"
                                  ? "btn-custom-details-inactive"
                                  : "btn-custom-details-light-inactive"
                              }`}
                              onClick={() => handleViewChange("table")}
                            >
                              {t("Details")}
                            </div>
                          )
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                  {showView === "chart" ? (
                    <Profile
                      cardStyle1={cardStyle1}
                      rowStyle={rowStyle}
                      theme={theme}
                    />
                  ) : (
                    <TrackingTableView cardStyle1={cardStyle1} theme={theme} />
                    // <div className="d-flex justify-content-center align-items-center"
                    //   style={{
                    //     boxShadow: "0px 5px 10px rgba(0,0,0,0.25)",
                    //     padding: "10px",
                    //     borderRadius: "5px",
                    //     marginBottom: 8,
                    //     marginTop: 6,
                    //     color: invertDark(theme),
                    //     height: "550px",
                    //     // paddingLeft: width < 701 ? "10px" : "",
                    //   }}
                    // >
                    //   <UpgradeInProgress theme={theme} />
                    // </div>
                  )}
                  <TrackingFileExportModal
                    showView={showView}
                    showChartViewVTwo={showChartViewVTwo}
                  />
                </>
              ) : (
                <>
                  <FilterBarForComparison theme={theme} />
                  <div
                    style={cardStyle1}
                    className="d-flex flex-row flex-wrap justify-content-center"
                  >
                    <CarbonComparisonView theme={theme} />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      ) : (
        <CO2_Loading />
      );
    }
  })
);
