import * as React from "react";
import { useEffect } from "react";
import { Tooltip, tooltipClasses } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import {
    Pagination,
    PaginationItem,
    styled,
    TableSortLabel,
} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { inject, observer } from "mobx-react";
import requestImage from '../../Assets/Images/icons/request.png'
import requestLightImage from '../../Assets/Images/icons/requestLight.png'
import { CO2_Loading } from "../../Assets/CO2_Loading";
import { toJS } from "mobx";
import moment from "moment";
import { useTranslation } from "react-i18next";

export const UserDetailReportTable = inject("store")(
    observer((props) => {
        const {t} = useTranslation("admin-panel")
        const {
            header,
            theme,
            Key,
            createData,
            data,
            dataType,
            trackTraceData,
            _handleViewContract,
            noPagination,
            lightWebform,
            manageMode,
            reportType
        } = props;

        const { _handleApprove } = props.store.adminPanel;

        const [page, setPage] = React.useState(1);
        const [order, setOrder] = React.useState("asc");
        const [orderBy, setOrderBy] = React.useState("");
        const handleRequestSort = (event, property) => {
            const isAsc = orderBy === property && order === "asc";
            setOrder(isAsc ? "desc" : "asc");
            setOrderBy(property);
        };

        console.log("data is ====", toJS(data));

        const VehicleTooltip = styled(({ className, ...props }) => (
            <Tooltip
              {...props}
              classes={{ popper: className }}
              placement="right-start"
            />
          ))(() => ({
            [`& .${tooltipClasses.tooltip}`]: {
              backgroundColor: theme === "dark" ? Colors.darkBlue : Colors.Dark[5],
              color: Colors.fuelLight,
              fontSize: "13px",
              width: "280px",
              boxShadow: "0px 10px 10px rgba(0,0,0,0.1)",
              padding: "10px 5px",
            },
          }));


        const rows = data?.map((v, k) => createData(
            v.id,
            moment(v.createdAt).format("DD-MM-yyyy"),
            v.company_id,
            v.name,
            v.username,
            
            
            
            v.is_individual === 1 ? "Individual Asset" : "Fleet Aggregate",
    
            v.is_individual === 1 ? (
            v.vehicleTypes.map((v) => v.vehicle_type)
            ) : (
            <VehicleTooltip
                title={<div className="rounded " style={{ border: "1px solid #746541" }}>
                    <div className="d-flex justify-content-between p-2">
                        <div>{t("Vehicle Type")}</div>
                        <div>{t("Number of Vehicles")}</div>
                    </div>
                    <div>
                        {v.vehicleTypes.map((d, i) => {
                        return (
                            <div className="d-flex justify-content-between px-2" style={{ borderTop: "1px solid #746541", fontSize: "12px" }}>
                                
                                
                                <span
                                    className="col-6 text-start"
                                    style={{ borderRight: "1px solid #746541" }}
                                >
                                    <div>{d.vehicle_type}</div>
                                </span>
                                <div>{d.no_of_vehicle}</div>

                                
                                {/* {v.vehicleTypes.length - 1 == i ? null : <hr />} */}
                            </div>
                        );
                        })}
                    </div>
                </div>
                }
                key={k}
            >
                <span>{t("Aggregate")}</span>
            </VehicleTooltip>
            ),
            v.plate_number || "N/A",
            v.fuel_type || "N/A",
            v.total_consumption || "N/A",
            v.total_mileage,
            v.report_type,
            v.period_start,
            v.period_end,
            v.remark || "N/A",
            v.status,
            "action"
        ))

        const rowStatusZero = rows?.filter(v => v.status == 0)
        const rowStatusOne = rows?.filter(v => v.status != 0)

        const StyledTableRow = styled(TableRow)(({ theme }) => ({
            cursor: "pointer",
            "&:nth-of-type(odd)": {
                backgroundColor:
                    props.theme === "dark" ? Colors.darkBlue : Colors.Dark[5],
                color: theme.palette.common.white,
            },
            // hide last border
            "&:last-child td, &:last-child th": {
                border: 0,
            },
        }));
        const rowCount = 6

        const handleChange = (event, value) => {
            setPage(value);
        };

        function EnhancedTableHead(props) {
            const {
                order, 
                orderBy,
                onRequestSort,
                theme,
                Key,
                _handleViewContract,
            } = props;
            const createSortHandler = (property) => (event) => {
                onRequestSort(event, property);
            };

            return (
                <TableHead>
                    <TableRow>
                        {header.map((headCell, index) => (
                            <TableCell
                                key={index}
                                align={"center"}
                                padding={headCell.disablePadding ? "none" : "normal"}
                                sortDirection={orderBy === headCell.id ? order : false}
                                style={{ background: 'rgb(0,0,0,0.4)' }}
                            >
                                {headCell.label === "" || (
                                    <TableSortLabel
                                        style={{
                                            color:
                                                orderBy === headCell.id
                                                    ? Colors.activeColor
                                                    : invertDark(theme),
                                        }}
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : "asc"}
                                        onClick={createSortHandler(Key[index])}
                                    >
                                        <span className="fw-bold">{headCell.label}</span>
                                    </TableSortLabel>
                                )}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
            );
        }
        function descendingComparator(a, b, orderBy) {
            const A = a[orderBy] ? a[orderBy] : undefined;
            const B = b[orderBy] ? b[orderBy] : undefined;
            if (B < A) {
                return -1;
            }
            if (B > A) {
                return 1;
            }
            return 0;
        }
        function getComparator(order, orderBy) {
            return order === "desc"
                ? (a, b) => descendingComparator(a, b, orderBy)
                : (a, b) => -descendingComparator(a, b, orderBy);
        }
        const useStyles = makeStyles({
            foo: {
                "& .MuiPagination-ul": { justifyContent: "end" },
                "& .MuiPaginationItem-text": {
                    color: Colors.Dark[theme === "dark" ? 4 : 1],
                },
                "& .Mui-selected": {
                    backgroundColor:
                        theme === "dark" ? Colors.lightBlue : Colors[theme].primaryColor,
                    color: Colors.Dark[4],
                },
            },
        });
        const classes = useStyles();


        if (data === null)
            return (<CO2_Loading />)
        else if (data?.length === 0)
            return ('No Report!')
        else
            return (
                <React.Fragment>
                    {reportType === 'pending' ? <> <TableContainer
                        component={Paper}
                        style={{ background: "none" }}
                        sx={{ maxHeight: 450 }}
                    >
                        <Table
                            stickyHeader
                            sx={{ minWidth: 650 }}
                            size="small"
                            aria-label="a dense sticky table"
                        >
                            <EnhancedTableHead
                                _handleViewContract={_handleViewContract}
                                Key={Key}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                theme={theme}
                            />
                            <TableBody>
                                {
                                    rows
                                        ?.sort(getComparator(order, orderBy))
                                        ?.filter(v => v.status == 0)
                                        .slice((page - 1) * rowCount, page * rowCount)
                                        ?.map((row, index) => (
                                            <StyledTableRow
                                                key={index}
                                                sx={{
                                                    "&:last-child td, &:last-child th": { border: 0 },
                                                }}
                                            >
                                                {Object.values(row)
                                                    .map((d, x) => 
                                                    {
                                                        
                                                        return(
                                                        x !== 0 && x !== 2 && d !== "action" && x!==15   //don't show id and status
                                                        && <TableCell
                                                            sx={{
                                                                color: invertDark(theme),
                                                                borderBottom: "none",
                                                                
                                                                textAlign: x === 9 ? "center" : ""
                                                            }}
                                                            key={x}
                                                        >

                                                            {d}
                                                        </TableCell>
                                                    )})
                                                }
                                                <TableCell
                                                    sx={{
                                                        color: invertDark(theme),
                                                        borderBottom: "none",
                                                    }}
                                                // key={index}
                                                >
                                                    {row.status == 0 ? <div className="d-flex">
                                                        <span onClick={()=>_handleApprove(row.id,1)} className="reduceOpacityOnhover text-nowrap me-3" style={{ color: '#08b500' }}><i className="bi bi-check2-circle pe-1" />
                                                            <span>{t("Approve")}</span>
                                                        </span>
                                                        {/* <span className="reduceOpacityOnhover text-nowrap" style={{ color: '#ff0000' }}><i className="bi bi-x-circle pe-1"></i>
                                                            <span>Reject</span>
                                                        </span> */}
                                                    </div> :
                                                        <span className="reduceOpacityOnhover text-nowrap me-3" style={{ color: '#08b500' }}><i className="bi bi-check2-circle pe-1" />
                                                            <span>{t("Preview Report")}</span>
                                                        </span>}
                                                </TableCell>
                                            </StyledTableRow>
                                        ))}
                            </TableBody>
                            </Table>
                            </TableContainer>
                            {noPagination || (
                                <div className="py-2">
                                    <Pagination
                                    size="small"
                                    renderItem={(item) => (
                                        <PaginationItem
                                        {...item}
                                        sx={{ color: invertDark(theme) }}
                                        classes={{ selected: classes.selected }}
                                        />
                                    )}
                                    className={classes.foo}
                                    count={
                                        rowStatusZero?.length <= rowCount
                                        ? 1
                                        : Math.ceil(rowStatusZero?.length / rowCount)
                                    }
                                    defaultPage={1}
                                    page={page}
                                    siblingCount={1}
                                    boundaryCount={1}
                                    onChange={handleChange}
                                    />
                                </div>
                                )}
                            </>
                            
                            : 
                            <>
                            <TableContainer
                                component={Paper}
                                style={{ background: "none" }}
                                sx={{ maxHeight: 450 }}
                            >
                                <Table
                                    stickyHeader
                                    sx={{ minWidth: 650 }}
                                    size="small"
                                    aria-label="a dense sticky table"
                                >
                                    <EnhancedTableHead
                                        _handleViewContract={_handleViewContract}
                                        Key={Key}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        theme={theme}
                                    />
                                <TableBody>
                                    {
                                        rows
                                            ?.sort(getComparator(order, orderBy))
                                            ?.filter(v => v.status != 0)
                                            ?.slice((page - 1) * rowCount, page * rowCount)
                                            ?.map((row, index) => (
                                                
                                                <StyledTableRow
                                                    key={index}
                                                    sx={{
                                                        "&:last-child td, &:last-child th": { border: 0 },
                                                    }}
                                                >
                                                    {Object.values(row)
                                                        .map((d, x) => (
                                                            x !== 0 && x !== 2 && d !== "action" && x !== 15 //don't show id and status
                                                            && <TableCell
                                                                sx={{
                                                                    color: invertDark(theme),
                                                                    borderBottom: "none",
                                                                    textAlign: x === 9 ? 'center' : ''
                                                                }}
                                                                key={x}
                                                            >

                                                                {d}
                                                            </TableCell>
                                                        ))
                                                    }

                                                    <TableCell
                                                        sx={{
                                                            color: invertDark(theme),
                                                            borderBottom: "none",
                                                        }}
                                                    // key={index}
                                                    >
                                                        <div className="d-flex">
                                                            <span onClick={()=>_handleApprove(row.id,0)} className="reduceOpacityOnhover text-nowrap me-3" style={{ color: '#ff0000' }}><i className="bi bi-x-circle pe-1" />
                                                                <span>{t("Cancel Approve")}</span>
                                                            </span>

                                                        </div>
                                                    </TableCell>

                                                </StyledTableRow>
                                                
                                            ))}
                                            
                                </TableBody>
                                </Table>
                            </TableContainer>
                            {noPagination || (
                                <div className="py-2">
                                    <Pagination
                                    size="small"
                                    renderItem={(item) => (
                                        <PaginationItem
                                        {...item}
                                        sx={{ color: invertDark(theme) }}
                                        classes={{ selected: classes.selected }}
                                        />
                                    )}
                                    className={classes.foo}
                                    count={
                                        rowStatusOne?.length <= rowCount
                                        ? 1
                                        : Math.ceil(rowStatusOne?.length / rowCount)
                                    }
                                    defaultPage={1}
                                    page={page}
                                    siblingCount={1}
                                    boundaryCount={1}
                                    onChange={handleChange}
                                    />
                                </div>
                                )}
                            </>
                            }
                        
                    

                </React.Fragment>
            );
    })
);
