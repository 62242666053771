import * as React from "react";
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import userImage from "../../Assets/Images/icons/user.png";
import { getUserStatusAPI } from "../../network/URL";
import { My_Button, My_Input, Selector } from "../../Tools";
import { ConfirmDeleteModal } from "./ConfirmDeleteModal";
import { UserUnitModal } from "./UserUnitModal";
import common from "../../Store/common";
import { Modal } from "bootstrap";
import { useTranslation } from "react-i18next";
import { EditUserView } from "./EditUserView";
import { AssignUnitView } from "./AssignUnitView";

import Store from "../../Store";

export const EditUser = inject("store")(
  observer((props) => {
    const { t } = useTranslation("admin-panel");
    const {
      getNewUserStatus,
      setEditUser,
      companies,
      editUserId,
      userList,
      getUserPlan,
      userPlanOptions,
      userStatusOptions,
      setUserPlanId,
      setUserStatusId,
      userStatusId,
      userPlanId,
      _handleEditTextChange,
      _handleSubmitEdit,
      _handleUpdatePlan,
      _handleUpdateUserStatus,
      _handleDeleteUser,
      editTempData,
      getUserStatus,
      _fetchCompanyList,
      leaveUserUnit,
      updateCompany,
      userUnit,
      _handleConfirmBeforeSubmitEdit,
      _setAssignNewVehicleUser,
    } = props.store.adminPanel;

    const { theme } = props;
    const { role, companyId } = props.store.common;
    const { userId } = props.store.common;
    const data = Store.adminPanel.userList;

    const {
      _handleFetchCompanyUnitsForAssign,
      selectetCompany,
      selectedUser,
      checkedVehicles,
      selectedVehicle,
      availableVehicle,
      _handleAssgningVehicle,
    } = props.store.dataEntry;

    const default_img = "/cars/default_vehicle.png";

    const user = userList.find((v) => v.id === editUserId);

    useEffect(() => {
      _fetchCompanyList(role, companyId);
    }, []);

    let key = "companyId";
    const filteredCompanyList = [
      ...new Map(data.map((item) => [item[key], item])).values(),
    ];

    const CompanyOptions = filteredCompanyList.map((v) => ({
      value: v?.companyId,
      label: v?.name,
    }));

    CompanyOptions.unshift({ value: "All", label: "All" });

    const [comId, setComId] = useState("All");
    const tempData = [];

    const d = data.map((v) => v.companyId === comId && tempData.push(v));
    const filteredData = tempData.length === 0 ? data : tempData;

    // console.log(toJS(filteredData), 'filtereddata====>')

    const [showView, setShowView] = useState("editUser");
    useEffect(() => {
      // Set the default view to "editUser" when the component mounts
      handleViewChange("editUser");
    }, []);

    const handleViewChange = (view) => {
      setShowView(view);
    };

    useEffect(() => {
      getUserPlan();
      setSelectedCompany(user.companyId);
      setUserPlanId(user.userPlanId);
    }, [editUserId]);

    useEffect(() => {
      getUserStatus();
      _fetchCompanyList(role, companyId);
    }, []);

    useEffect(() => {
      _handleFetchCompanyUnitsForAssign(user.companyId, user.id, 0);
      handleViewChange("editUser");
    }, [user.companyId, user.id, editUserId]);

    // const CompanyOptions = companies.map((v) => ({
    //     value: v?.id,
    //     label: v?.name,
    // }));

    const [selectedCompany, setSelectedCompany] = React.useState(user.name);
    const [currentCompany, setUpdateCompany] = React.useState(user.name);

    // CompanyOptions.unshift({ value: 'All', label: 'All' })

    const [open, setOpen] = React.useState(false);

    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      p: 4,
    };

    if (user) {
      return (
        <div
          style={{
            color: invertDark(theme),
            // height: "750px",
            backgroundColor: "",
          }}
        >
          <div className="d-flex justify-content-between">
            <div className="btn-group btn" role="group">
              <div
                type="button"
                className={` ${showView === "editUser" ? 
                user.role === 'admin' || user.role === 'super_admin' || user.role === 'viewer' ?
                (theme === 'dark' ? 'btn-custom-editUserAdmin' : 'btn-custom-editUserAdmin-light')
                :
                (theme === 'dark' ? "btn-custom-editUser" : 'btn-custom-editUser-light') : (theme === 'dark' ? "btn-custom-editUser-inactive" : 'btn-custom-editUser-light-inactive')
                  }`}
                style={{}}
                onClick={() => handleViewChange("editUser")}
              >
                {t("Edit User")}
              </div>
              {user.role === "driver" || user.role === "user" ? (
                <div
                  type="button"
                  className={`${showView === "assignUnits"
                      ? 
                        (theme === 'dark' ? "btn-custom-assignUnits" : 'btn-custom-assignUnits-light')
                        : (theme === 'dark' ? "btn-custom-assignUnits-inactive" : 'btn-custom-assignUnits-light-inactive')
                      
                    }`}
                  style={{}}
                  onClick={() => handleViewChange("assignUnits")}
                >
                  {t("Assign Units")}
                </div>
              ) : (
                <></>
              )}
            </div>

            <i className="bi bi-x fa-2x" onClick={() => setEditUser(null)} />
          </div>

          {showView == "editUser" ? (
            <EditUserView
              theme={theme}
              user={user}
              _handleFetchCompanyUnitsForAssign={
                _handleFetchCompanyUnitsForAssign
              }
              selectedVehicle={selectedVehicle}
              userId={userId}
              role={role}
              companyId={companyId}
              setEditUser={setEditUser}
              companies={companies}
              editUserId={editUserId}
              userList={userList}
              getUserPlan={getUserPlan}
              userPlanOptions={userPlanOptions}
              userStatusOptions={userStatusOptions}
              setUserPlanId={setUserPlanId}
              setUserStatusId={setUserStatusId}
              _handleEditTextChange={_handleEditTextChange}
              _handleSubmitEdit={_handleSubmitEdit}
              _handleUpdatePlan={_handleUpdatePlan}
              _handleUpdateUserStatus={_handleUpdateUserStatus}
              editTempData={editTempData}
              getUserStatus={getUserStatus}
              _fetchCompanyList={_fetchCompanyList}
              userUnit={userUnit}
              _handleConfirmBeforeSubmitEdit={_handleConfirmBeforeSubmitEdit}
            />
          ) : (
            <AssignUnitView
              user={user}
              theme={theme}
              default_img={default_img}
              selectedUser={selectedUser}
              selectedVehicle={selectedVehicle}
              availableVehicle={availableVehicle}
              _handleAssgningVehicle={_handleAssgningVehicle}
            />
          )}
        </div>
      );
    } else return "hi";
  })
);
