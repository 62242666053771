import { toJS } from "mobx";
import * as API from "./URL";

export const getCompanyList = async (token,role, companyId, callback) => {
  return fetch(`${API.getCompany}?companyId=${companyId}&role=${role}` , {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const getCompanyListWithChildCompanies = async (token, companyId, callback) => {
  return fetch(`${API.getCompanyNewAPI}?companyId=${companyId}` , {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const getCompanyYears = async (token, companyId, callback) => {
  return fetch(`${API.companyYearlyData}?companyId=` + companyId, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const fetchgetCompanyName = async (token, data, callback) => {
  const param = new URLSearchParams(data);
  return fetch(`${API.getCompanyName}?${param.toString()}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const CreateNewUser = async (token, data, callback) => {
  return fetch(API.createNewUserAPI, {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          "x-access-token": token,
      },
      body: JSON.stringify(data),
  })
      .then((res) => res.json())
      .then((data) => callback(null, data))
      .catch((err) => callback(err, null));
};


export const updateUser = async (token, userId, data, callback) => {
  return fetch(`${API.updateUserListAPI}/${userId}`, {
      method: "PUT",
      headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          "x-access-token": token,
      },
      body: JSON.stringify(data),
  })
      .then((res) => res.json())
      .then((data) => callback(null, data))
      .catch((err) => callback(err, null));
};


export const updateCompany = async (token, companyId, data, callback) => {
  return fetch(`${API.updateCompanyNewAPI}?companyId=${companyId}`, {
      method: "PUT",
      headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          "x-access-token": token,
      },
      body: JSON.stringify(data),
  })
      .then((res) => res.json())
      .then((data) => callback(null, data))
      .catch((err) => callback(err, null));
};



export const CreateNewCompany = async (token, data, callback) => {
  return fetch(API.createNewCompanyAPI, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null))
}

export const getAllUserList = async (token, companyId, callback) => {
  return fetch(`${API.getAllUserListAPI}?companyId=${companyId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    }
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null))
}

export const deleteUser = async (token, userId, callback) => {
  return fetch(`${API.deleteUserAPI}?id=${userId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const deleteCompany = async (token, companyId, callback) => {
  return fetch(`${API.deleteCompanyAPI}?companyId=${companyId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};