import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import {
  Pagination,
  PaginationItem,
  styled,
  TableSortLabel,
} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { Colors, invertDark } from "../Assets/Config/color.config";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import moment from 'moment';

export const MyTable = inject("store")(
  observer(

    withRouter((props) => {
      const {
        header,
        theme,
        Key,
        createData,
        data,
        dataType,
        trackTraceData,
        _handleViewContract,
        noPagination
      } = props;
      const { handleRowSelect, 
              fetchVehicleList, 
              companyId, 
              handleSelectedRowForFMS, 
              fetchFMSVehicles 
            } = props.store.trackingVTwo;
      const { certificates, setSelectedCertificateId } = props.store.dashboard;
      const { deleteMode, _setToDelete, _handleEdit } = props.store.assetProfile
      const [page, setPage] = React.useState(1);
      const [order, setOrder] = React.useState("asc");
      const [orderBy, setOrderBy] = React.useState("");
      const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
      };
      const rows =
        dataType === "vehicle"
          ? data?.map((v, k) =>
            companyId === "88883333" ?
              createData(
                v.uid,
                v.license_plate,
                v.model || "N/A",
                v.cargo_type || "N/A",
                v.primary_fuel_type || "N/A",
                v.brand || "N/A",
                v.vin_number || "N/A",
                v.ome_baseline_emission || "N/A",
                deleteMode && 'removeIcon',
                v.type,
                v.image
              )
              : createData(
                v.uid,
                v.license_plate,
                v.model || "N/A",
                v.cargo_type || "N/A",
                v.primary_fuel_type || "N/A",
                v.brand || "N/A",
                v.vin_number || "N/A",
                v.ome_baseline_emission || "N/A",
                deleteMode && 'removeIcon',
                v.type,
                v.image
              )
          )
          : dataType === "equipments"
            ? data.map((v, k) =>
              createData(v.deviceId, v.deviceName, v.dataType, v.unit || "N/A")
            )
            : dataType === "certificate" ?
              data.map(
                (v, k) =>
                  createData(
                    v?.id,
                    moment(v.month).format('MMM').toUpperCase(),
                    v?.consumptions_by_fuel_type?.find(v => v.fuelType === 'Diesel')?.totalFuelConsumed.toFixed(2).toString() || "N/A",
                    // v?.consumptions_by_fuel_type[0]?.totalFuelConsumed?.toFixed(2).toString()|| "N/A",
                    v?.consumptions_by_fuel_type?.find(v => v.fuelType === 'Petrol')?.totalFuelConsumed?.toFixed(2).toString() || "N/A",
                    // v?.consumptions_by_fuel_type[1]?.totalFuelConsumed?.toFixed(2).toString() || "N/A",
                    v.total_distance.toFixed(2).toString() || "N/A",
                    v.current_emission.toFixed(2).toString() || "N/A",
                    v.baseline_emission.toFixed(2).toString() || "N/A",
                    v.offset.toFixed(2).toString() || "N/A",
                    "detail",
                    // "detail"
                  )
              )
              : trackTraceData.map((v, k) =>
                createData(
                  v.id,
                  v.trackingName,
                  v.submitterAddress || "N/A",
                  v.contractAddress || "N/A",
                  v.type || "N/A",
                  "detail"
                )
              );
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        cursor: "pointer",
        "&:nth-of-type(odd)": {
          backgroundColor:
            props.theme === "dark" ? Colors.darkBlue : Colors.Dark[5],
          color: theme.palette.common.white,
        },
        // hide last border
        "&:last-child td, &:last-child th": {
          border: 0,
        },
      }));
      const rowCount = dataType === "certificate" ? 12 : 10;
      const handleChange = (event, value) => {
        setPage(value);
      };

      function EnhancedTableHead(props) {
        const {
          order,
          orderBy,
          onRequestSort,
          theme,
          Key,
          _handleViewContract,
        } = props;
        const createSortHandler = (property) => (event) => {
          onRequestSort(event, property);
        };

        return (
          <TableHead>
            <TableRow>
              {header.map((headCell, index) => (
                <TableCell
                  key={index}
                  align={"left"}
                  padding={headCell.disablePadding ? "none" : "normal"}
                  sortDirection={orderBy === headCell.id ? order : false}
                  style={{ background: invertDark(theme) }}
                >
                  {headCell.label === "" || (
                    <TableSortLabel
                      style={{
                        color:
                          orderBy === headCell.id
                            ? Colors.activeColor
                            : theme === "light"
                              ? "#F6F6F6"
                              : Colors.darkBlue,
                      }}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={createSortHandler(Key[index])}
                    >
                      <span className="fw-bold">{headCell.label}</span>
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        );
      }
      function descendingComparator(a, b, orderBy) {
        const A = a[orderBy] ? a[orderBy] : undefined;
        const B = b[orderBy] ? b[orderBy] : undefined;
        if (B < A) {
          return -1;
        }
        if (B > A) {
          return 1;
        }
        return 0;
      }
      function getComparator(order, orderBy) {
        return order === "desc"
          ? (a, b) => descendingComparator(a, b, orderBy)
          : (a, b) => -descendingComparator(a, b, orderBy);
      }
      const useStyles = makeStyles({
        foo: {
          "& .MuiPagination-ul": { justifyContent: "end" },
          "& .MuiPaginationItem-text": {
            color: Colors.Dark[theme === "dark" ? 4 : 1],
          },
          "& .Mui-selected": {
            backgroundColor:
              theme === "dark" ? Colors.lightBlue : Colors[theme].primaryColor,
            color: Colors.Dark[4],
          },
        },
      });
      const classes = useStyles();

      return (
        <React.Fragment>
          <TableContainer
            component={Paper}
            style={{ background: "none" }}
            sx={{ maxHeight: 450 }}
          >
            <Table
              stickyHeader
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense sticky table"
            >
              <EnhancedTableHead
                _handleViewContract={_handleViewContract}
                Key={Key}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                theme={theme}
              />
              <TableBody>
                {rows.length > 0 &&
                  rows
                    .sort(getComparator(order, orderBy))
                    .slice((page - 1) * rowCount, page * rowCount)
                    .map((row, index) => (
                      <StyledTableRow
                        key={index}
                        onClick={async () => {
                          if (!trackTraceData && dataType !== "certificate" && !deleteMode) {
                            if (companyId === "88883333") {
                              let vehicle = data?.filter((value) => value.license_plate === row.license_plate)[0];
                              await handleSelectedRowForFMS(vehicle)
                              await fetchFMSVehicles();
                              await props.history.push("/new-tracking")
                            } else {
                              let vehicle = data?.filter((value) => value.license_plate === row.license_plate)[0];

                              await handleSelectedRowForFMS(vehicle)
                              //await handleRowSelect(row.uid ? row.uid : row.deviceId);
                              await fetchFMSVehicles();
                              await props.history.push("/new-tracking");
                            }
                          }
                        }}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {!trackTraceData
                          ? Object.values(row)
                            // .filter((f) => f.length<35)
                            .map((d, x) => (
                              x !== 0 && x !== 9 && x !== 10 &&<TableCell
                                sx={{
                                  color: invertDark(theme),
                                  borderBottom: "none",
                                }}
                                key={x}
                              >
                                {d === "View" ? (
                                  <span
                                    // className="btn text-light w-100"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"
                                    data-toggle="modal"
                                    data-target=".bd-example-modal-lg"
                                  >
                                    <Link to='#' onClick={() => {
                                      setSelectedCertificateId(row.id);
                                    }} >{d}</Link>
                                  </span>
                                ) :
                                  d === 'removeIcon' ?
                                    <div className="d-flex">
                                      <span onClick={() => _handleEdit(row)} style={{ color: theme === 'dark' ? Colors.Dark[4] : Colors.Dark[1] }} 
                                      data-bs-toggle="modal"
                                        data-bs-target="#addVehicleModal"
                                        data-toggle="modal"
                                        data-target=".bd-example-modal-lg" className="deleteIcon me-3">
                                        <i className="bi bi-pencil-square pe-1"></i>Edit
                                      </span>
                                      <span style={{ color: theme === 'dark' ? '#ffc107' : 'red' }} 
                                      data-bs-toggle="modal"
                                        data-bs-target="#confirmationModal"
                                        data-toggle="modal"
                                        data-target=".bd-example-modal-lg" className="deleteIcon" onClick={() => _setToDelete(row.uid, row.license_plate)}>
                                        <i className="bi bi-trash pe-1"></i>Delete
                                      </span>
                                    </div>
                                    : (
                                      d
                                    )}
                              </TableCell>
                            ))
                          : Object.values(row).map((d, x) => (
                            <TableCell
                              key={XMLHttpRequestEventTarget}
                              sx={{
                                color: invertDark(theme),
                                borderBottom: "none",
                              }}
                            >
                              {d === "detail" ? (
                                <Link
                                  to="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal2"
                                  data-toggle="modal"
                                  data-target=".bd-example-modal-lg"
                                  onClick={() =>
                                    _handleViewContract(
                                      row.id,
                                      row.tracking_name
                                    )
                                  }
                                >
                                  Detail
                                </Link>
                              ) : (
                                d
                              )}
                            </TableCell>
                          ))}
                      </StyledTableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          {noPagination || <div className="py-2">
            <Pagination
              size="small"
              renderItem={(item) => (
                <PaginationItem
                  {...item}
                  sx={{ color: invertDark(theme) }}
                  classes={{ selected: classes.selected }}
                />
              )}
              className={classes.root}
              count={
                rows.length <= rowCount ? 1 : Math.ceil(rows.length / rowCount)
              }
              defaultPage={1}
              page={page}
              siblingCount={1}
              boundaryCount={1}
              onChange={handleChange}
            />
          </div>}
        </React.Fragment>
      );
    })
  )
);
