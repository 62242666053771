
import { useTranslation } from "react-i18next";
import { Colors, invertDark } from "../../Assets/Config/color.config";

export const AddNewOrganizationModal = (props) => {
    const {t} = useTranslation("admin-panel")

    const { theme } = props;
    return (
        <div
            className="modal fade show"
            id="addneworganization"
            tabIndex="-1"
            aria-labelledby="addneworganization"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-xl rounded">
                <div
                    className="modal-content"
                    style={{ background: Colors[theme].background, color: invertDark(theme) }}
                >
                    <div className="modal-header" style={{ border: "none" }}>
                        <span style={{ fontSize: 24 }}>
                            {t("Add New Organization")}
                        </span>

                        <span data-bs-dismiss="modal" style={{ cursor: 'pointer' }}>
                            <i className="bi bi-x-lg" />
                        </span>
                    </div>
                    <div className="modal-body">
                        ...
                    </div>

                </div>
            </div>
        </div>
    )
}
