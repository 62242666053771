import React from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { inject, observer } from "mobx-react";
import { News } from "./News";

export const NewsModal = inject("store")(
  observer((props) => {
    const { theme, closeModal, show } = props;

    const cardStyle = {
      color: invertDark(theme),
      background: Colors[theme].background,
    };

    
    const openInNewTab = (itemId) => {
      window.open("/what's_new", "_blank");
    };

    // const openInNewTab = () => {
    //   const newTab = window.open("", "_blank");
    //   newTab.document.write(`
    //     <html>
    //       <head>
    //         <title>What's New</title>
    //         <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css">
    //         <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css">
    //         <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH" crossorigin="anonymous">
    //         <style>
    //           body { background: ${
    //             Colors[theme].background
    //           }; color: ${invertDark(theme)};
    //           .accordion-button {
    //             font-weight: bold;
    //             font-size: 1rem;
    //           }
              
    //           /* Month list with scroll */
    //           .month-list {
    //             max-height: 200px;
    //             overflow-y: auto;
    //             display: flex;
    //             flex-direction: column;
    //           }
              
    //           /* Month items styling */
    //           .month-item {
    //             cursor: pointer;
    //             padding: 0.5rem 1rem;
    //             display: flex;
    //             align-items: center;
    //             transition: background-color 0.3s;
    //           }
              
    //           .month-item:hover {
    //             background-color: #f0f0f0;
    //           }
              
    //           .month-item.active {
    //             background-color: #e9ecef;
    //             font-weight: bold;
    //           }
              
    //           /* Month indicator (circle) */
    //           .month-indicator {
    //             width: 10px;
    //             height: 10px;
    //             border-radius: 50%;
    //             background-color: transparent;
    //             margin-right: 0.5rem;
    //             transition: background-color 0.3s;
    //           }
              
    //           .month-item.active .month-indicator {
    //             background-color: #007bff;
    //           }
              
    //           /* Timeline styling */
    //           .timeline {
    //             list-style: none;
    //             padding: 0;
    //             position: relative;
    //           }
              
    //           .timeline::before {
    //             content: '';
    //             position: absolute;
    //             top: 0;
    //             bottom: 0;
    //             width: 2px;
    //             background: #ccc;
    //             left: 20px;
    //             margin-left: -1.5px;
    //           }
              
    //           /* Timeline item styling */
    //           .timeline-item {
    //             position: relative;
    //             margin: 2rem 0;
    //             padding-left: 40px; /* Adjust padding to align content correctly */
    //           }
              
    //           .timeline-item::before {
    //             content: '';
    //             position: absolute;
    //             left: 12px;
    //             top: 5px;
    //             width: 16px;
    //             height: 16px;
    //             border-radius: 50%;
    //             background: #0A66C2;
    //             border: 2px solid #fff;
    //           }
              
    //           .timeline-item.active::before {
    //             background-color: #ff5733; /* Color for active circle */
    //           }
              
    //           .timeline-content {
    //             padding-left: 1rem;
    //             border: 1px solid #ddd;
    //             border-radius: 4px;
    //             cursor: pointer;
    //           }
              
    //           .timeline-item-new{
    //             position: relative;
    //             margin: 2rem 0;
    //             padding-left: 40px;
    //           }
              
    //           .news-list {
    //             max-height: 570px;
    //             overflow-y: auto;
    //             display: flex;
    //             flex-direction: column;
    //           }
    //         }
    //         </style>
    //       </head>
    //       <body>
    //         <div class="container">
    //           <div class="row">
    //             <div class="col-md-12">
    //               <h3 class="mt-5">What's New</h3>
    //               <div>${document.querySelector(".modal-body").innerHTML}</div>
    //             </div>
    //           </div>
    //         </div>
    //       </body>
    //     </html>
    //   `);
    //   newTab.document.close();
    // };

    return (
      <>
        <div
          className="modal fade"
          id="newModal"
          tabIndex="-1"
          aria-labelledby="newModal"
          aria-hidden={!show}
        >
          <div className="modal-dialog modal-xl">
            <div className="modal-content" style={cardStyle}>
              <div className="row-12 d-flex justify-content-center align-items-center py-3">
                <div className="col-md-8 px-5">
                  <h3 className="d-flex" id="passwordresetmodal">
                    What's New
                  </h3>
                </div>
                <div
                  className="col-md-4 d-flex justify-content-between"
                  style={{ paddingRight: 30 }}
                >
                  <div
                    className="justify-content-center"
                    style={{ paddingLeft: 100, paddingTop: "5px" }}
                  >
                    <button
                      type="button"
                      className="btn"
                      style={{border:"2px solid #0A66C2", background: Colors[theme].background,color: invertDark(theme),                    }}
                      onClick={openInNewTab}
                    >
                      <span>Open in new tab</span>{" "}
                      <i className="bi bi-box-arrow-up-right p-1"></i>
                    </button>
                  </div>
                  <div className="justify-content-end p-2">
                    <button
                      type="button"
                      className={`btn-close p-2 align-items-center ${
                        theme === "dark" ? "btn-close-white" : ""
                      }`}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => closeModal()}
                    ></button>
                  </div>
                </div>
              </div>
              <div className="modal-body px-5" style={{height:"600px"}}>
                <News  theme ={theme}/>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  })
);
