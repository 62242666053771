import { inject, observer } from "mobx-react";
import React from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";

export const UnitsCard = inject("store")(
  observer((props) => {
    const { theme } = props;
    const {unitCount} = props.store.dashboard
    const cardStyle = {
      background: Colors[theme]?.background,
      boxShadow: "2px 4px 11px rgba(0,0,0,0.25)",
      padding: "15px",
      borderRadius: "15px",
      color: invertDark(theme),
      width: "100%",
      height: 85
    };

    return (
      <div className="row">
        <div className="row-12 col-lg-6 text-center d-flex justify-content-center mb-2">
          <div style={cardStyle}>
            <span>Active Unit</span>
            <h6 style={{fontSize:"30px",color:"green"}}> {unitCount?.is_active_unit} Units</h6>
          </div>
        </div>
        <div className="row-12 col-lg-6 text-center d-flex justify-content-center mb-2 ">
          <div style={cardStyle}>
            <span >Inactive Unit</span>
            <h6 style={{fontSize:"30px",color:"red"}}>{unitCount?.is_un_active_unit} Units</h6>
          </div>{" "}
        </div>
      </div>
    );
  })
);
