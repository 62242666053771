import { inject, observer } from "mobx-react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { DriverList } from "./DriverList";
import { FuelRecord } from "./FuelRecord";

export const DriverView = inject("store")(
  observer((props) => {
    const { theme, cardStyle, rowStyle } = props;

    return (
      <div className="row d-flex flex-row flex-wrap justify-content-center">
        <div className="col-xl-5 col-lg-8 col-md-12 col-sm-12 col-xs-12 mb-5">
          <div>
            <DriverList
              theme={theme}
              cardStyle={cardStyle}
              rowStyle={rowStyle}
            />
          </div>
        </div>
        <div className="col-xl-7 col-lg-4 col-md-12 col-sm-12 col-xs-12 mb-2 ">
          <div>
            <FuelRecord
              theme={theme}
              cardStyle={cardStyle}
              rowStyle={rowStyle}
            />
          </div>
        </div>
      </div>
    );
  })
);
