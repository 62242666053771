import * as API from "./URL";

export const FetchEntryDataOther = async (
  year,
  type,
  companyId,
  token,
  callback
) => {
  return fetch(
    `${API.getManualEntryAPI}?year=${year}&companyId=${companyId}&type=${type}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        "x-access-token": token,
      },
    }
  )
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const FetchEntryData = async (
  year,
  type,
  token,
  companyId,
  callback,
  page
) => {
  return fetch(
    `${API.getFMSDataEntryAPI}?year=${year}&type=${type}&companyId=${companyId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        "x-access-token": token,
      },
    }
  )
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const FetchManualEntry = async (
  year,
  type,
  token,
  companyId,
  callback
) => {
  return fetch(
    `${API.getManualEntryDataAPI}?year=${year}&type=${type}&companyId=${companyId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        "x-access-token": token,
      },
    }
  )
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const UploadFile = async (data, token, callback) => {
  return fetch(API.newUploadEntryFileAPI, {
    method: "POST",
    headers: {
      Accept: "*/*",
      "x-access-token": token,
    },
    body: data,
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const UploadDataEntry = async (data, token, callback) => {
  
  return fetch(API.uploadFMSEntryDataAPI, {
    method: "POST",
    headers: {
      // "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
    body: data,
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const UploadDataEntryV2 = async (data, token, callback) => {
  
  return fetch(API.uploadFMSEntryDataV2API, {
    method: "POST",
    headers: {
      // "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
    body: data,
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};


export const UploadDataForOther = async (data, token, callback) => {
  return fetch(API.uploadEntryDataAPI, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
    body: data,
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const UploadFileForOther = async (data, token, callback) => {
  return fetch(API.uploadEntryFileAPI, {
    method: "POST",
    headers: {
      Accept: "*/*",
      "x-access-token": token,
    },
    body: data,
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const FetchUnitsForCompany = async (
  ownCompanyId,
  forCompanyId,
  token,
  callback
) => {
  return fetch(
    `${API.assignUnitList}?ownCompanyId=${ownCompanyId}&forCompanyId=${forCompanyId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        "x-access-token": token,
      },
    }
  )
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const FetchCompanyVehiclesForAddNewUser = async (
  companyId,
  userId,
  token,
  userCompanyId,
  callback
) => {
  return fetch(
    `${API.companyVehiclesForAddNewUser}?companyId=${companyId}&userId=${userId}&userCompanyId=${userCompanyId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        "x-access-token": token,
      },
    }
  )
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const AssigningVehicle = async (vehicleId, userId, token, callback) => {
  return fetch(`${API.assigningVehicle}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
    body: JSON.stringify({ vehicleIds: vehicleId, userId: userId }),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const AssigningUnitsToCompany = async (
  vehicleIds,
  companyId,
  token,
  callback
) => {
  return fetch(`${API.assigningUnit}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
    body: JSON.stringify({ vehicleIds: vehicleIds, companyId: companyId }),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const FetchSelectedVehicleList = async (token, companyId, callback) => {
  return fetch(`${API.handleSelectVehicleAPI}?companyId=${companyId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const DeleteFMSEntryFile = async (token, Id, callback) => {
  return fetch(`${API.deleteFMSEntryFileAPI}?id=${Id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const CreateManualEntryNew = async (token, vId, date, callback) => {
  return fetch(`${API.createManualEntryNewAPI}?v_id=${vId}&date=${date}`, {
    method: "POST",
    headers: {
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const GetUnitMonthByMonth = async (
  year,
  companyId,
  type,
  token,
  callback
) => {
  return fetch(
    `${API.getUnitMonthByMonthAPI}?year=${year}&companyId=${companyId}&type=${type}`,
    {
      method: "GET",
      headers: {
        Accept: "*/*",
        "x-access-token": token,
      },
    }
  )
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const GetConsumptionByMonth = async (
  year,
  companyId,
  type,
  month,
  userId,
  token,
  callback
) => {
  return fetch(
    `${API.getConsumptionByMonthAPI}?year=${year}&companyId=${companyId}&type=${type}&month=${month}&userId=${userId}`,
    {
      method: "GET",
      headers: {
        Accept: "*/*",
        "x-access-token": token,
      },
    }
  )
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const GetNewConsumptionByMonth = async (
  year,
  companyId,
  type,
  month,
  userId,
  token,
  callback
) => {
  return fetch(
    `${API.getNewConsumptionByMonthAPI}?year=${year}&companyId=${companyId}&type=${type}&month=${month}&userId=${userId}`,
    {
      method: "GET",
      headers: {
        Accept: "*/*",
        "x-access-token": token,
      },
    }
  )
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const GetConsumptionByMonthPerUnit = async (
  year,
  companyId,
  type,
  month,
  userId,
  grouping,
  token,
  callback
) => {
  return fetch(
    `${API.getNewConsumptionByMonthAPI}?year=${year}&companyId=${companyId}&type=${type}&month=${month}&userId=${userId}&grouping=${grouping}`,
    {
      method: "GET",
      headers: {
        Accept: "*/*",
        "x-access-token": token,
      },
    }
  )
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const FetchActionStatus = async (token, data, callback) => {
  return fetch(`${API.actionStatusAPI}`, {
      method: "PUT",
      headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
      },
      body: JSON.stringify(data),
  })
      .then((res) => res.json())
      .then((data) => callback(null, data))
      .catch((err) => callback(err, null));
  };


export const GetConsumptionByMonthV2 = async (
  year,
  companyId,
  type,
  month,
  userId,
  token,
  callback
) => {
  return fetch(
    `${API.getConsumptionByMonthV2API}?year=${year}&companyId=${companyId}&type=${type}&month=${month}&userId=${userId}`,
    {
      method: "GET",
      headers: {
        Accept: "*/*",
        "x-access-token": token,
      },
    }
  )
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};