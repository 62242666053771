import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { My_Button as MyButton } from "../../Tools";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";
import { Cookies } from "react-cookie";
import moment from "moment";
import { ReportTable } from "./reportTable";

export const CreateReportModal = inject("store")(
    observer((props) => {

        const dailyRef = React.useRef(null);
        const monthlyRef = React.useRef(null);
        const cookie = new Cookies();
        const companyName = cookie.get("companyName");
        const {
            selectedVehicle,
            assetProfile,
            month,
            year,
        } = props.store.tracking;
        const { reportData, reportType, units, startTime, endTime } = props.store.trackAndTrace;
        var fuelTypeArray = []
        reportData?.consumptionsByFuelType?.Diesel && fuelTypeArray.push('Diesel')
        reportData?.consumptionsByFuelType?.Petrol && fuelTypeArray.push('Petrol')
        reportData?.consumptionsByFuelType?.Battery && fuelTypeArray.push('Battery')

        let dieselVehicles = []
        let petrolVehicles = []
        let batteryVehicles = []
        if (reportData) {
            dieselVehicles = reportData?.consumptionsByFuelType?.Diesel && Object.keys(reportData?.consumptionsByFuelType?.Diesel?.licensePlates).join(", ")
            petrolVehicles = reportData?.consumptionsByFuelType?.Petrol && Object.keys(reportData?.consumptionsByFuelType?.Petrol?.licensePlates).join(", ")
            batteryVehicles = reportData?.consumptionsByFuelType?.Battery && Object.keys(reportData?.consumptionsByFuelType?.Battery?.licensePlates).join(", ")
        }

        // const diesalVehicles =Object.keys([reportData?.consumptionsByFuelType.Diesel.licensePlates])
        const dieselRef = React.useRef(null);
        const petrolRef = React.useRef(null);
        const download = async () => {
            const dieselElement = dieselRef.current;
            const petrolElement = petrolRef.current;
            var img = new Image();
            img.src = "/CO2_Logo.png";

            if (dieselElement) {
                try {
                    const canvas1 = await html2canvas(dieselElement);
                    const canvas2 = await html2canvas(petrolElement);
                    const dieselData = canvas1.toDataURL("image/png");
                    const petrolData = canvas2.toDataURL("image/png");
                    const pdf = new jsPDF("p", undefined, "a4");
                    let width = pdf.internal.pageSize.getWidth();
                    let height = pdf.internal.pageSize.getHeight();

                    pdf.addImage(img, "png", 18, 20, 25, 15);
                    pdf.setFontSize(18);
                    // pdf.setTextColor(13, 110, 253);
                    // pdf.text(20, 45, "CO2 Emission Tracking & Computation Dashboard");
                    // pdf.text(
                    //     `Report for ${year}, ${months[month - 1]}\n${companyName}`,
                    //     width / 2,
                    //     145,
                    //     "center"
                    // );
                    pdf.text(
                        reportType==='Monthly'?`Report for ${moment(startTime).format("YYYY,MMMM")}\n${companyName}`:
                        `Report for ${moment(startTime).format("YYYY")}\n${companyName}`,
                        width / 2,
                        145,
                        "center"
                    );
                    pdf.addPage();
                    pdf.addImage(dieselData, "PNG", 0, 0, width, height / 2);
                    pdf.addPage();
                    pdf.addImage(petrolData, "PNG", 0, 0, width, height / 2);
                    pdf.save('report');
                } catch (e) {
                    console.log(e);
                }
            }
        };

        return (
            <div
                className="modal fade overflow-scroll"
                id="createReportModal"
                tabIndex="-1"
                aria-labelledby="createReportModal"
                aria-hidden="true"
            >
                <div className="w-100 d-flex align-items-center justify-content-end py-2 px-3">

                    <MyButton
                        leftIcon={<DownloadIcon className="me-2" fontSize="small" />}
                        small={false}
                        className="px-3 me-3"
                        text="Download"
                        onClick={() => {
                            download();
                        }}
                    />
                    <MyButton
                        leftIcon={<CloseIcon />}
                        small={false}
                        className="close rounded-circle"
                        dataDismiss="modal"
                        onClick={() => { }}
                    />
                </div>
                {/* fixed width for printing certificate */}
                <div className="modal-dialog modal-xl rounded">
                    <div
                        className="modal-content p-3"
                        style={{
                            color: Colors.Dark[1],
                            fontSize: 20,

                            // border: `6px solid ${Colors.lightBlue}`,
                            // background:
                            //   theme === "dark" ? Colors.dark.background : Colors.Dark[5],
                        }}
                    >
                        {dieselVehicles &&
                            < div className="p-5" ref={dieselRef} >
                                <div style={{ opacity: 0.5 }}><img src='/CO2_Logo.png' width={100} />
                                    <div className="ps-2" style={{ fontSize: 40, fontWeight: 'bold' }}>CO2 CONNECT</div>
                                </div>
                                <div className="text-center"><h3>{reportType} Report</h3></div>
                                <div className="pt-5 d-flex justify-content-between">
                                    <span className="fw-bold" style={{ fontSize: 22 }}>Organization : {companyName}</span><span className="">Issuance Date : {reportData?.issuance_date}</span>
                                </div>
                                <div className="row pt-3">
                                    <span className="col-2">Vehicle ID</span><span className="col-1 text-end">:</span><span className="col-9">{dieselVehicles}</span>
                                    <span className="col-2">Time Period</span><span className="col-1 text-end">:</span><span className="col-9">{moment(startTime).format("MMM YYYY") + ' - ' + moment(endTime).format("MMM YYYY")}</span>
                                    <span className="col-2">Fuel Type</span><span className="col-1 text-end">:</span>
                                    <span className="col-9">
                                        {/* {fuelTypeArray.length === 1 ? `Fully ${fuelTypeArray[0]}` : fuelTypeArray.toString()} */}
                                        Fully Diesel
                                    </span>
                                </div>
                                <ReportTable reportData={reportData} fuelType='Diesel' />
                            </div>}
                        {petrolVehicles &&
                            < div className="p-5" ref={petrolRef}>
                                <div style={{ opacity: 0.5 }}><img src='/CO2_Logo.png' width={100} />
                                    <div className="ps-2" style={{ fontSize: 40, fontWeight: 'bold' }}>CO2 CONNECT</div>
                                </div>
                                <div className="text-center"><h3>{reportType} Report</h3></div>
                                <div className="pt-5 d-flex justify-content-between">
                                    <span className="fw-bold" style={{ fontSize: 22 }}>Organization : {companyName}</span><span className="">Issuance Date : {reportData?.issuance_date}</span>
                                </div>
                                <div className="row pt-3">
                                    <span className="col-2">Vehicle ID</span><span className="col-1 text-end">:</span><span className="col-9">
                                        {/* {units.toString().replaceAll(',', ', ')} */}
                                        {petrolVehicles}
                                    </span>
                                    <span className="col-2">Time Period</span><span className="col-1 text-end">:</span><span className="col-9">{moment(startTime).format("MMM YYYY") + ' - ' + moment(endTime).format("MMM YYYY")}</span>
                                    <span className="col-2">Fuel Type</span><span className="col-1 text-end">:</span>
                                    <span className="col-9">
                                        Fully Petrol
                                    </span>
                                </div>
                                <ReportTable reportData={reportData} fuelType='Petrol' />
                            </div>
                        }

                        {batteryVehicles &&
                            < div className="p-5" ref={monthlyRef}>
                                <div style={{ opacity: 0.5 }}><img src='/CO2_Logo.png' width={100} />
                                    <div className="ps-2" style={{ fontSize: 40, fontWeight: 'bold' }}>CO2 CONNECT</div>
                                </div>
                                <div className="text-center"><h3>{reportType} Report</h3></div>
                                <div className="pt-5 d-flex justify-content-between">
                                    <span className="fw-bold" style={{ fontSize: 22 }}>Organization : {companyName}</span><span className="">Issuance Date : {reportData?.issuance_date}</span>
                                </div>
                                <div className="row pt-3">
                                    <span className="col-2">Vehicle ID</span><span className="col-1 text-end">:</span><span className="col-9">{batteryVehicles}</span>
                                    <span className="col-2">Time Period</span><span className="col-1 text-end">:</span><span className="col-9">{moment(startTime).format("MMM YYYY") + ' - ' + moment(endTime).format("MMM YYYY")}</span>
                                    <span className="col-2">Fuel Type</span><span className="col-1 text-end">:</span>
                                    <span className="col-9">
                                        Fully Petrol
                                    </span>
                                </div>
                                <ReportTable reportData={reportData} />
                            </div>
                        }

                    </div>
                </div>
            </div >
        );

    })
);
