import * as React from "react";
import { SliderThumb } from "@mui/material/Slider";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import PropTypes from "prop-types";
import clsx from "clsx";
import month from "../../Assets/month.json";
import Store from "../../Store";
import { useTranslation } from "react-i18next";

function ThumbComponent(props) {
    const { children, className, ...other } = props;
    const extraClassName =
        other["ownerState"].currentMonth === 2022 ||
            other["ownerState"].id === other["ownerState"].currentMonth
            ? "second-thumb"
            : "first-thumb";
    return (
        <SliderThumb {...other} className={clsx(className, extraClassName)}>
            {children}
        </SliderThumb>
    );
}
ThumbComponent.propTypes = {
    children: PropTypes.node,
};

export default function EntryTimeline(props) {
    const {t} = useTranslation("new-manuel-entry")
    const { isAds } = Store.common
    const { data, handleIconClick, currentMonth, theme } = props;
    const _MouseEnter = (id) => {
        document.getElementById(id).style["opacity"] = ``;
    };
    const _MouseLeave = (id) => {
        document.getElementById(id).style["opacity"] = `0.7`;
    };
    return (
        data &&
        data.map((v, k) => (
            <div
                className="d-flex flex-row justify-content-start mx-auto w-100"
                onMouseEnter={() => _MouseEnter(k)}
                onMouseLeave={() => _MouseLeave(k)}
                key={k}
                style={{ color: invertDark(theme) }}
            >
                <div className="col-lg-3 col-md-4 col-3">
                    <div
                        className="p-2 mb-2 justify-content-end"
                        id={k}
                        style={{
                            borderRadius: "7px",
                            cursor: "pointer",
                            textAlign: "right",
                            color: k === currentMonth && Colors[theme].textColor,
                        }}
                        onClick={() => v && handleIconClick(v, k)}
                    >
                        {month[k + 1].label}
                    </div>
                </div>
                <div
                    id={"parent" + k}
                    className="col-lg-2 col-md-1 col-2 d-flex justify-content-center"
                >
                    <div
                        style={{
                            background:
                                theme === "dark" ? "rgba(255,255,255,0.1)" : "#E7E8E8",
                            width: 3,
                            height: document.getElementById(`parent${k}`)?.offsetHeight,
                            position: "absolute",
                            marginLeft: 0,
                        }}
                    ></div>
                    <div
                        style={{
                            background:
                                k === currentMonth
                                    ? Colors[theme].activeTimeline
                                    : Colors[theme].noActiveTimeline,
                            width: 10,
                            height: 10,
                            marginTop: 16,
                            position: "absolute",
                            borderRadius: 5,
                        }}
                    ></div>
                </div>
                <div className="col-5 mx-3 ">
                    <div
                        className="p-2 mb-2 d-flex justify-content-between "
                        id={k}
                        style={{
                            boxShadow:
                                k === currentMonth
                                    ? "0px 0px 20px rgba(75,171,237,0.35)"
                                    : "none",
                            borderRadius: "7px",
                            cursor: v ? "pointer" : "not-allowed",
                        }}
                        onClick={() => v && handleIconClick(v, k)}
                    >
                        {isAds ?
                            <div
                                style={{ color: k === currentMonth && Colors[theme].textColor }}
                            >
                                {v === null
                                    ? "0 equipment"
                                    : v.equipments?.length > 1
                                        ? v.equipments?.length + " equipments"
                                        : v.equipments?.length + " equipment"}
                            </div>
                            : <div
                                style={{ color: k === currentMonth && Colors[theme].textColor }}
                            >
                                {v === null
                                    ? "0 vehicle"
                                    : v.vehicles?.length > 1
                                        ? v.vehicles?.length + t(" vehicles")
                                        : v.vehicles?.length + t(" vehicle")}
                            </div>}
                        <div>
                            {isAds ? v && (
                                <i
                                    className={`${v.equipments.findIndex(
                                        (dt) =>
                                            (dt.manualPowerConsumed || dt.adsPowerConsumed) ===
                                            0 ||
                                            (dt.manualPowerConsumed || dt.adsPowerConsumed) === 0
                                    ) > -1
                                        ? "fas fa-exclamation-circle"
                                        : "fas fa-check-circle"
                                        } `}
                                    style={{
                                        color:
                                            v.equipments.findIndex(
                                                (dt) =>
                                                    (dt.manualPowerConsumed || dt.adsPowerConsumed) ===
                                                    0 ||
                                                    (dt.manualPowerConsumed || dt.adsPowerConsumed) === 0
                                            ) > -1
                                                ? Colors.medium
                                                : Colors.low,
                                    }}
                                />
                            ) :
                                v && (
                                    <i
                                        className={`${v.vehicles.findIndex(
                                            (dt) =>
                                                (dt.manualFuelConsumed || dt.ascentFuelConsumed) ===
                                                0 ||
                                                (dt.manualTripMileage || dt.ascentTripMileage) === 0
                                        ) > -1
                                            ? "fas fa-exclamation-circle"
                                            : "fas fa-check-circle"
                                            } `}
                                        style={{
                                            color:
                                                v.vehicles.findIndex(
                                                    (dt) =>
                                                        (dt.manualFuelConsumed || dt.ascentFuelConsumed) ===
                                                        0 ||
                                                        (dt.manualTripMileage || dt.ascentTripMileage) === 0
                                                ) > -1
                                                    ? Colors.medium
                                                    : Colors.low,
                                        }}
                                    />
                                )}
                        </div>
                    </div>
                </div>
            </div>
        ))
    );
}
