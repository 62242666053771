import { useEffect } from "react";
import { Selector } from "../../Tools";
// import { Colors, invertDark } from "../../Assets/Config/color.config";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
// import Store from "../../Store";
// import { useEffect, useState } from "react";
// import { toJS } from "mobx";

export const TimeSelectorForDataImport = inject("store")(
  observer((props) => {
    const {t} = useTranslation("data_import")
    const {companyyearlydata} = props;
    const { year, month, handleSelectYear, handleSelectMonth } = props.store.dataImport;

    //
    // const theme = localStorage.getItem("theme");
    // const { isAds } = props.store.common;

    const months = [
      {value:0, label:t("All")},
      { value: 1, label: t("January") },
      { value: 2, label: t("February") },
      { value: 3, label: t("March") },
      { value: 4, label: t("April") },
      { value: 5, label: t("May") },
      { value: 6, label: t("June") },
      { value: 7, label: t("July") },
      { value: 8, label: t("August") },
      { value: 9, label: t("September") },
      { value: 10, label: t("October") },
      { value: 11, label: t("November") },
      { value: 12, label: t("December") },
    ];


    return (
      <>
        <div>
          <div className="row m-0 d-flex flex-nowrap">
            <div className="ps-0" style={{ width: 120 }}>
              <Selector
                isSearchable={false}
                label={t("Year")}
                options={companyyearlydata}
                defaultValue={companyyearlydata.find(
                  (y) => y.value === new Date().getFullYear()
                )}
                _handleSelect={({ value, label }) => {
                  handleSelectYear(value);
                  
                }}
              />
            </div>

            <div className="pe-0" style={{ width: 180 }}>
              <Selector
                isSearchable={false}
                label={t("Month")}
                options={months}
                defaultValue={months.find(
                  (m) => m.value === month
                )}
                _handleSelect={({ value, label }) => {
                  handleSelectMonth(value)
                }}
              />
              
            </div>
          </div>
        </div>
      </>
    );
  })
);
