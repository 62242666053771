/* eslint-disable react/jsx-pascal-case */
import { inject, observer } from "mobx-react";
import { useState } from "react";
import { useEffect } from "react";
import { Colors } from "../../Assets/Config/color.config";
import { Selector } from "../../Tools";
import { CO2_Loading } from "../../Assets/CO2_Loading";
import { toJS } from "mobx";
import { My_Button } from "../../Tools";
import { Cookies } from "react-cookie";
import { CompanySelector } from "../../Components/Dashboard/companySelector";
import { baseIMGURL } from "../../network/URL";
import { useTranslation } from "react-i18next";

export const CompanyVehiclesContainer = inject("store")(

    observer((props) => {
        const {t} = useTranslation("mobile")
        const theme = localStorage.getItem("theme");

        const { _fetchVehicleLst, filterVehicles, vehicleType, _handleSelectedVehicleType,
            vehicleTypeOption, vehicleOption, selectedVehicle, _handleSelectedVehicle } = props.store.driver;

        const { _handleFetchCompanyYears } = props.store.dashboard;

        useEffect(() => {
            _fetchVehicleLst();
            _handleFetchCompanyYears();
        }, [])

        return (
            <>
                {filterVehicles ?
                    <>
                        <div style={{marginTop : '1em'}}>
                            <CompanySelector className="" disabled={true} theme={theme} />
                            <div className="row d-flex flex-row flex-wrap justify-content-center">

                                <div className="row px-2">
                                    <div className="col-6">
                                        <Selector
                                            isSearchable={false}
                                            label={t("Vehicle Type")}
                                            options={vehicleTypeOption}
                                            value={vehicleType ? vehicleType : vehicleTypeOption[0]}
                                            _handleSelect={(value) => {
                                                _handleSelectedVehicleType(value)
                                            }}
                                            disabled={false}
                                        />
                                    </div>

                                    <div className="col-6">
                                        <Selector
                                            isSearchable={false}
                                            label={t("Vehicle")}
                                            options={vehicleOption}
                                            value={selectedVehicle ? selectedVehicle : vehicleOption[0]}
                                            _handleSelect={(value) => {
                                                _handleSelectedVehicle(value)
                                            }}
                                            disabled={false}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row d-flex flex-row flex-wrap justify-content-center m-1">
                                {filterVehicles?.map((vehicle) => <VehicleInfo vehicle={vehicle} theme={theme} />)}
                            </div>
                        </div>
                    </> :
                    <CO2_Loading />}
            </>
        )
    })
)

export const VehicleInfo = inject("store")(
    observer((props) => {
        const {t} = useTranslation("mobile")
        const { vehicle, theme } = props;
        console.log('vehicle', toJS(vehicle))
        const cardStyle = {
            background: Colors[theme].background,
            boxShadow: "2px 4px 11px rgba(0,0,0,0.25)",
            padding: "10px",
            width: "420px",
            borderRadius: "15px",
            fontSize: "12px",
            color: Colors.Dark[theme === "dark" ? 4 : 1],
            transitionDuration: "0.1s",
        };
        const cookies = new Cookies();

        const [imgUrl, setImgUrl] = useState(
            vehicle.image ? `${baseIMGURL}uploads/devices/${vehicle.image}` : "/cars/default_vehicle.png"
        );

        return (
            <div
                id={vehicle.id}
                style={cardStyle}
                className="row d-flex flex-row flex-wrap text-center justify-content-center m-2"
                onClick={() => {

                }}
            >
                <h5 className="mb-3">{vehicle.plate_number}</h5>
                <div className="d-flex justify-content-between align-items-center px-2 mx-0 mb-3">

                    <span className="col-4 col-lg-3 col-xl-3 col-sm-6">
                        <img
                            className="col-12"
                            style={{ borderRadius: 8, width: "100%" }}
                            src={imgUrl}
                            alt={vehicle.plate_number}
                            onError={(e) => {
                                setImgUrl(`/cars/default_vehicle.png`);
                            }}
                        />
                    </span>
                    <div className="col-8 row">
                        <span style={{ textAlign: "left" }}><i className="fas fa-truck px-2" />{vehicle.model === "undefined" ? "N/A" : vehicle.model === null ? '-' : vehicle.model}</span>
                        <span style={{ textAlign: "left" }}><i className="fas fa-gas-pump px-2" />{vehicle.primary_fuel_type === "undefined" ? "N/A" : vehicle.primary_fuel_type === null ? '-' : vehicle.primary_fuel_type}</span>
                        <span style={{ textAlign: "left" }}><i className="fas fa-circle px-2" />{vehicle.brand === "undefined" ? "N/A" : vehicle.brand === null ? '-' : vehicle.brand?.trim()}</span>
                        <span style={{ textAlign: "left" }}><i className="fas fa-circle px-2" />{vehicle.cargo_type === "undefined" ? "N/A" : vehicle.cargo_type === null ? '-' : vehicle.cargo_type?.trim()}</span>
                        <span style={{ textAlign: "left" }}><i className="fas fa-circle px-2" />{vehicle.vehicle_sub_type === "undefined" ? "N/A" : vehicle.vehicle_sub_type === null ? '-' : vehicle.vehicle_sub_type?.trim()}</span>
                        <span style={{ textAlign: "left" }}><i className="fas fa-circle px-2" />{vehicle.vehicle_type === "undefined" ? "N/A" : vehicle.vehicle_type === null ? '-' : vehicle.vehicle_type?.trim()}</span>

                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <My_Button
                        text={t("Fuel Record")}
                        type={"submit"}
                        customColor={Colors.low}
                        style={{
                            backgroundColor: Colors.low,
                            width: "100%",
                            height: "35px",
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                        }}
                        onClick={async () => {
                            cookies.set("vehicleId", vehicle.id);
                            window.location.pathname = "/m_fuel_record"
                        }}
                    />
                </div>
            </div>
        );
    })
)