import { inject, observer } from "mobx-react";
import { Colors } from "../../Assets/Config/color.config";
import {
  VEHICLE_EMISSION,
  emission_chiller,
} from "../../Assets/Config/constant.config";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import moment from "moment";
import Month from "../../Assets/month.json";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { My_Button, Selector } from "../../Tools";
import { TrackingFileExportModal } from "./trackingFileExportModal";
import { toJS } from "mobx";
import dayjs from "dayjs";

export const CarbonEmissionManualChart = inject("store")(
  observer((props) => {
    const { t } = useTranslation("new-tracking");

    const { theme, pdf, chartView } = props;

    const carbonEmissionColor = "#413ea0";

    const {
      assetProfile,
      year,
      month,
      yearSelected,
      vehicleType,
      handleShowChartView,
      handleShowChartViewVTwo,
      unitSetting,
      showView,
      dateFormat,
      startDateFormat,
      endDateFormat,
      showChartViewVTwo,
    } = props.store.trackingVTwo;

    const showChartView = chartView
      ? chartView
      : props.store.trackingVTwo.showChartView;
    let data1 = [];
    let emission = 0;

    let labelFormat;
    let maxLeftY;
    let leftYTicks;
    let maxRightY;
    let rightYTicks;
    let CustomTooltip;

    if (showChartViewVTwo === "manual") {
      const emissionByManual = assetProfile?.manual?.carbonEmission;
      console.log("Emission by manual =>", toJS(emissionByManual))
      if (dateFormat === "Year") {
        const Months = Month?.filter((v) => v.value !== 0);
        let baseline = assetProfile?.manualBaselineEmissionPerKm;
        Months?.map((v) => {
          if (
            emissionByManual?.filter((em) => em.days_months === v.value)
              .length > 0
          ) {
            let result = emissionByManual?.filter(
              (em) => em.days_months === v.value
            )[0];

            data1.push({
              name: `${v.label.substring(0, 3)}-${yearSelected % 100}`,
              baselineEmissionFactor: result?.baselineEmissionFactor || "0",
              emissionFactor: result?.emissionFactor || "0", //(gCo2/km)
              carbonEmission: result?.totalEmission || "0", //(kgCo2)
              baselineEmission: result?.totalBaselineEmission || "0", // kgCo2
            });
          } else {
            data1.push({
              name: `${v.label.substring(0, 3)}-${yearSelected % 100}`,
              baselineEmissionFactor: baseline || "0",
              carbonEmission: "0",
              emissionFactor: "0",
              baselineEmission: "0",
            });
          }
        });
        console.log("data1", data1);
      } else {
        const firstDay = new Date(startDateFormat);
        const lastDay = new Date(endDateFormat);

        let dateArr = [];
        while (firstDay <= lastDay) {
          dateArr.push(new Date(firstDay));
          firstDay.setDate(firstDay.getDate() + 1);
        }

        dateArr?.map((value) => {
          let strDate = moment(value).format("DD/MM/yyyy");
          let showDateStr = moment(value).format("DD-MM");
          let baseline = assetProfile?.manualBaselineEmissionPerKm;
          const result = emissionByManual?.filter((v) => {
            return v.days_months === strDate;
          });

          if (
            emissionByManual?.filter((v) => v.days_months === strDate).length >
            0
          ) {
            let result = emissionByManual?.filter(
              (v) => v.days_months === strDate
            )[0];
            data1.push({
              name: showDateStr,
              baselineEmissionFactor: result.baselineEmissionFactor || "0",
              emissionFactor: result.emissionFactor || "0", //(gCo2/km)
              carbonEmission: result.totalEmission || "0", //(kgCo2)
              baselineEmission: result.totalBaselineEmission || "0", // kgCo2
            });
          } else {
            data1.push({
              name: showDateStr,
              baselineEmissionFactor: baseline || "0",
              carbonEmission: "0",
              emissionFactor: "0",
              baselineEmission: "0",
            });
          }
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      labelFormat = (text) => {
        if (text) {
          return (
            text
              .replace(/([A-Z])/g, " $1")
              .trim()
              .charAt(0)
              .toUpperCase() +
            text
              .replace(/([A-Z])/g, " $1")
              .trim()
              .slice(1)
          );
        }
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
      maxLeftY = Math.ceil(
        Math.max(
          ...data1.map((d) => d.carbonEmission),
          ...data1.map((d) => d.baselineEmission)
        )
      );

      // eslint-disable-next-line react-hooks/exhaustive-deps
      maxRightY = Math.ceil(
        Math.max(
          ...data1.map((d) => d.emissionFactor),
          ...data1.map((d) => d.baselineEmissionFactor)
        )
      );

      if (vehicleType.value === "Battery") {
        // eslint-disable-next-line react-hooks/exhaustive-deps

        try {
          if (maxLeftY > 1000000) {
            leftYTicks = Array(Math.ceil(maxLeftY / 1000000) + 1)
              .fill({})
              .map((_, i) => i * 1000000);
          } else if (maxLeftY > 100000) {
            leftYTicks = Array(Math.ceil(maxLeftY / 50000) + 1)
              .fill({})
              .map((_, i) => i * 50000);
          } else if (maxLeftY > 10000) {
            leftYTicks = Array(Math.ceil(maxLeftY / 5000) + 1)
              .fill({})
              .map((_, i) => i * 5000);
          } else if (maxLeftY > 1000) {
            leftYTicks = Array(Math.ceil(maxLeftY / 500) + 1)
              .fill({})
              .map((_, i) => i * 500);
          } else if (maxLeftY > 300) {
            leftYTicks = Array(Math.ceil(maxLeftY / 100) + 1)
              .fill({})
              .map((_, i) => i * 100);
          } else if (maxLeftY > 100) {
            leftYTicks = Array(Math.ceil(maxLeftY / 50) + 1)
              .fill({})
              .map((_, i) => i * 50);
          } else {
            leftYTicks = Array(Math.ceil(maxLeftY / 6) + 1)
              .fill({})
              .map((_, i) => i * 5);
          }
        } catch (e) {
          console.log("err>>", e.message);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
        rightYTicks = Array(Math.ceil(maxRightY / 50) + 1)
          .fill({})
          .map((_, i) => i * 50);
      } else {
        if (maxLeftY < 100) {
          leftYTicks = Array(Math.ceil(maxLeftY / 5) + 1)
            .fill({})
            .map((_, i) => i * 5);
        } else {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          leftYTicks = Array(Math.ceil(maxLeftY / 100) + 1)
            .fill({})
            .map((_, i) => i * 100);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        rightYTicks = Array(Math.ceil(maxRightY / 1000) + 1)
          .fill({})
          .map((_, i) => i * 1000);
      }

      CustomTooltip = ({ active, payload, label }) => {
        if (active) {
          return (
            <div
              className="custom-tooltip p-2 rounded"
              style={{
                background: theme === "dark" ? Colors.darkBlue : Colors.Dark[5],
                boxShadow: "0px 10px 10px rgba(0,0,0,0.1)",
              }}
            >
              <div className="label">{`${t("Date")}: ${label}-${year}`}</div>
              <hr className="my-0" />
              <div className="label" style={{ color: Colors.chartColor[0] }}>
                {`${t(labelFormat(payload[0]?.name))}: ${Number(
                  payload[0].value
                ).toLocaleString("en-US")} kgCO₂`}
              </div>
              <div className="label" style={{ color: Colors.chartColor[1] }}>
                {`${t(labelFormat(payload[1]?.name))}: ${Number(
                  payload[1].value
                ).toLocaleString("en-US")} kgCO₂`}
              </div>
              <div className="label" style={{ color: Colors.fuelLight }}>
                {`${t(labelFormat(payload[2]?.name))}: ${Number(
                  payload[2].value
                ).toLocaleString("en-US")} gCO₂/km`}
              </div>
              <div className="label" style={{ color: Colors.yellow }}>
                {`${t(labelFormat(payload[3]?.name))}: ${Number(
                  payload[3].value
                ).toLocaleString("en-US")} gCO₂/km`}
              </div>
              {/* <p className="intro">{getIntroOfPage(label)}</p> */}
            </div>
          );
        }

        return null;
      };
    } else {
      const emissionByMonth = assetProfile.manual?.carbonEmission_manual;
      // const Months = Month.filter((v) => v.value !== 0);
      let baseline = assetProfile.manualBaselineEmissionPerKm;

      if (dateFormat === "Year") {
        const Months = Month.filter((v) => v.value !== 0);
        let baseline = assetProfile.manualBaselineEmissionPerKm;
        Months.map((v) => {
          if (
            emissionByMonth?.filter((em) => em.days_months === v.value).length >
            0
          ) {
            let result = emissionByMonth?.filter(
              (em) => em.days_months === v.value
            )[0];

            data1.push({
              name: `${v.label.substring(0, 3)}-${yearSelected % 100}`,
              baselineEmissionFactor: result.baselineEmissionFactor || "0",
              emissionFactor: result.emissionFactor || "0", //(gCo2/km)
              carbonEmission: result.totalEmission || "0", //(kgCo2)
              baselineEmission: result.totalBaselineEmission || "0", // kgCo2
            });
          } else {
            data1.push({
              name: `${v.label.substring(0, 3)}-${yearSelected % 100}`,
              baselineEmissionFactor: baseline || "0",
              carbonEmission: "0",
              emissionFactor: "0",
              baselineEmission: "0",
            });
          }
        });
      } else {
        const firstDay = new Date(startDateFormat);
        const lastDay = new Date(endDateFormat);

        let dateArr = [];
        while (firstDay <= lastDay) {
          dateArr?.push(new Date(firstDay));
          firstDay.setDate(firstDay.getDate() + 1);
        }

        dateArr?.map((v) => {
          let strDate = moment(v).format("DD/MM/yyyy");
          let showDateStr = moment(v).format("DD-MM");
          let baseline = assetProfile.manualBaselineEmissionPerKm;

          if (
            emissionByMonth?.filter((v) => v.days_months === strDate).length > 0
          ) {
            let result = emissionByMonth?.filter(
              (v) => v.days_months === strDate
            )[0];

            data1.push({
              // name: `${v.label.substring(0, 3)}-${year % 100}`,
              name: showDateStr,
              baselineEmissionFactor: result.baselineEmissionFactor || "0",
              emissionFactor: result.emissionFactor || "0", //(gCo2/km)
              carbonEmission: result.totalEmission || "0", //(kgCo2)
              baselineEmission: result.totalBaselineEmission || "0", // kgCo2
            });
          } else {
            data1.push({
              // name: `${v.label.substring(0, 3)}-${year % 100}`,
              name: showDateStr,
              baselineEmissionFactor: baseline || "0",
              carbonEmission: "0",
              emissionFactor: "0",
              baselineEmission: "0",
            });
          }
        });
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      labelFormat = (text) => {
        return (
          text
            .replace(/([A-Z])/g, " $1")
            .trim()
            .charAt(0)
            .toUpperCase() +
          text
            .replace(/([A-Z])/g, " $1")
            .trim()
            .slice(1)
        );
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
      maxLeftY = Math.ceil(
        Math.max(
          ...data1.map((d) => d.carbonEmission),
          ...data1.map((d) => d.baselineEmission)
        )
      );

      // eslint-disable-next-line react-hooks/exhaustive-deps
      maxRightY = Math.ceil(
        Math.max(
          ...data1.map((d) => d.emissionFactor),
          ...data1.map((d) => d.baselineEmissionFactor)
        )
      );

      if (vehicleType.value === "Battery") {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        leftYTicks = Array(Math.ceil(maxLeftY / 100) + 1)
          .fill({})
          .map((_, i) => i * 100);

        // eslint-disable-next-line react-hooks/exhaustive-deps
        rightYTicks = Array(Math.ceil(maxRightY / 5) + 1)
          .fill({})
          .map((_, i) => i * 5);
      } else {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        if (maxLeftY > 1000000) {
          leftYTicks = Array(Math.ceil(maxLeftY / 1000000) + 1)
            .fill({})
            .map((_, i) => i * 1000000);
        } else if (maxLeftY < 1000) {
          leftYTicks = Array(Math.ceil(maxLeftY / 100) + 1)
            .fill({})
            .map((_, i) => i * 100);
        } else if (maxLeftY < 100) {
          leftYTicks = Array(Math.ceil(maxLeftY / 5) + 1)
            .fill({})
            .map((_, i) => i * 5);
        } else {
          leftYTicks = Array(Math.ceil(maxLeftY / 1000) + 1)
            .fill({})
            .map((_, i) => i * 1000);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
        rightYTicks = Array(Math.ceil(maxRightY / 100) + 1)
          .fill({})
          .map((_, i) => i * 100);
      }

      CustomTooltip = ({ active, payload, label }) => {
        if (active) {
          return (
            <div
              className="custom-tooltip p-2 rounded"
              style={{
                background: theme === "dark" ? Colors.darkBlue : Colors.Dark[5],
                boxShadow: "0px 10px 10px rgba(0,0,0,0.1)",
              }}
            >
              <div className="label">{`${t("Month")}: ${label}`}</div>
              <hr className="my-0" />
              <div
                className="label"
                style={{
                  color: Colors.chartColor[0],
                  textShadow: "0.5px 0.5px black",
                }}
              >
                {`${t(labelFormat(payload[0].name))}: ${Number(
                  payload[0].value
                ).toLocaleString("en-US")} kgCO₂`}
              </div>
              <div
                className="label"
                style={{
                  color: Colors.chartColor[1],
                  textShadow: "0.5px 0.5px black",
                }}
              >
                {`${t(labelFormat(payload[1].name))}: ${Number(
                  payload[1].value
                ).toLocaleString("en-US")} kgCO₂`}
              </div>
              <div
                className="label"
                style={{
                  color: Colors.fuelLight,
                  textShadow: "0.5px 0.5px black",
                }}
              >
                {`${t(labelFormat(payload[2].name))}: ${Number(
                  payload[2].value
                ).toLocaleString("en-US")} gCO₂/km`}
              </div>
              <div
                className="label"
                style={{
                  color: Colors.yellow,
                  textShadow: "0.5px 0.5px black",
                }}
              >
                {`${t(labelFormat(payload[3].name))} : ${Number(
                  payload[3].value
                ).toLocaleString("en-US")} gCO₂/km`}
              </div>
              {/* <p className="intro">{getIntroOfPage(label)}</p> */}
            </div>
          );
        }

        return null;
      };
    }

    return (
      <div
        className={`${window.innerWidth > 552 && "w-150"}`}
        style={{
          height: window.innerWidth > 600 ? 340 : window.innerHeight / 2,
          width: window.innerWidth > 552 ? "100%" : "350px",
        }}
      >
        {!pdf && (
          <>
            <div
              className={
                window.innerWidth < 701
                  ? "d-flex flex-column px-2 pt-3 mb-3"
                  : "d-flex justify-content-center align-items-center mb-3"
              }
              style={{ width: window.innerWidth < 701 ? "100%" : "" }}
            >
              <div
                className={`d-flex ${
                  window.innerWidth < 701
                    ? "flex-column align-items-center"
                    : ""
                } justify-content-between`}
                style={{ width: "100%" }}
              >
                <div className="d-flex flex-row align-items-center">
                  {dateFormat === "Month" ? (
                    <h5 className="mb-0">
                      {t(
                        `Emission Profile for ${dayjs()
                          .month(month - 1)
                          .format("MMMM")}-${yearSelected}`
                      )}
                    </h5>
                  ) : dateFormat === "Year" ? (
                    <h5 className="mb-0">
                      {t(`Emission Profile for ${yearSelected}`)}
                    </h5>
                  ) : dateFormat === "7 Days" || dateFormat === "Custom" ? (
                    <h5 className="mb-0">
                      {t(
                        `Emission Profile from ${startDateFormat} to ${endDateFormat}`
                      )}
                    </h5>
                  ) : (
                    ""
                  )}

                  <span
                    className=""
                    data-bs-toggle="modal"
                    data-bs-target="#newtrackingPdfModal"
                    data-toggle="modal"
                    data-target=".bd-example-modal-lg"
                  >
                    <button
                      type="button"
                      className={`${
                        theme === "dark"
                          ? "btn  btn-outline-info btn-sm m-2"
                          : "btn btn-outline-success btn-sm m-2"
                      }`}
                      style={{
                        fontSize: 12,
                      }}
                    >
                      {t("Create Report")}
                    </button>
                  </span>

                  <TrackingFileExportModal
                    showView={showView}
                    showChartView={showChartView}
                    showChartViewVTwo={showChartViewVTwo}
                  />
                </div>

                <div className="d-flex align-items-center">
                  <div
                    className="btn-group btn-group-sm"
                    role="group"
                    style={{ width: window.innerWidth < 701 ? "100%" : "" }}
                  >
                    {/* {
                                            unitSetting?.enableCANbus === true
                                                ? */}
                    <div
                      type="button"
                      className={`${
                        showChartViewVTwo === "CANBus"
                          ? theme === "dark"
                            ? "btn-custom-canbus"
                            : "btn-custom-canbus-light"
                          : theme === "dark"
                          ? "btn-custom-canbus-inactive"
                          : "btn-custom-canbus-light-inactive"
                      }`}
                      onClick={() => handleShowChartViewVTwo("CANBus")}
                      style={{ zIndex: 0 }}
                    >
                      {t("CANBus")}
                    </div>
                    {/* :
                                                <></> */}
                    {/* } */}
                    <div
                      type="button"
                      className={`${
                        showChartViewVTwo === "manual"
                          ? theme === "dark"
                            ? "btn-custom-manual"
                            : "btn-custom-manual-light"
                          : theme === "dark"
                          ? "btn-custom-manual-inactive"
                          : "btn-custom-manual-light-inactive"
                      }`}
                      onClick={() => handleShowChartViewVTwo("manual")}
                      style={{ zIndex: 0 }}
                    >
                      {t("Manual")}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="d-flex justify-content-between align-items-center"
              style={{ fontSize: window.innerWidth < 701 ? ".8rem" : "" }}
            >
              <p
                className="text-center"
                style={{
                  maxWidth: 150,
                  color:
                    theme === "dark" ? Colors.lightBlue : carbonEmissionColor,
                  marginLeft: "30px",
                }}
              >
                {/* Carbon Emission(kgCO₂) */} kgCO₂
              </p>
              <h2
                style={{
                  fontSize: window.innerWidth < 701 ? "1.3rem" : "1.5rem",
                }}
              >
                {assetProfile.vehicle_number_plate
                  ? assetProfile.vehicle_number_plate
                  : "No Data for that vehicle"}
              </h2>
              <p
                className="text-center"
                style={{
                  maxWidth: 150,
                  color: Colors.fuelLight,
                  marginRight: "25px",
                }}
              >
                {/* Emission Factor(gCO₂/km) */} gCO₂/km
              </p>
            </div>
          </>
        )}
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            width={500}
            height={500}
            data={data1}
            margin={{ left: 30, right: 30, top: 10 }}
          >
            <CartesianGrid
              stroke={
                theme === "dark" ? "rgba(255,255,255,0.1)" : Colors.light.active
              }
              // strokeDasharray="3 3"
            />
            <XAxis
              dataKey="name"
              scale="band"
              tick={{ fill: "currentColor" }}
            />
            <YAxis
              yAxisId="left"
              orientation="left"
              tick={{
                fill: theme === "dark" ? Colors.lightBlue : carbonEmissionColor,
              }}
              interval={"auto"}
              ticks={leftYTicks}
              tickCount={0}
            ></YAxis>
            <YAxis
              yAxisId="right"
              orientation="right"
              tick={{
                fill: theme === "dark" ? Colors.fuelLight : carbonEmissionColor,
              }}
              interval={
                // window.innerWidth > 700 &&
                // (rightYTicks.length > 50
                //     ? parseInt(rightYTicks.length / 10) - 1
                //     : 0)
                "auto"
              }
              ticks={rightYTicks}
              tickCount={0}
            ></YAxis>
            <Bar
              yAxisId="left"
              dataKey="carbonEmission"
              barSize={10}
              //fill={theme === "dark" ? Colors.lightBlue : carbonEmissionColor}
              fill={Colors.chartColor[0]}
            />
            <Bar
              yAxisId="left"
              dataKey="baselineEmission"
              barSize={10}
              //fill={theme === "dark" ? Colors.fuelBaseline : Colors.fuelBaseline}
              fill={Colors.chartColor[1]}
            />
            <Line
              yAxisId="right"
              // type="monotone"
              dataKey="emissionFactor"
              stroke={Colors.fuelLight}
              //stroke="#0000FF"
            />

            <Line
              yAxisId="right"
              // type="monotone"
              dataKey="baselineEmissionFactor"
              stroke={Colors.yellow}
              //stroke="#000000"
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend
              wrapperStyle={{
                bottom: window.innerWidth > 460 ? 2 : -25,
                textShadow: "0.5px 0.5px black",
                fontSize: window.innerWidth < 701 ? ".8rem" : "1rem",
              }}
              formatter={(value, entry, index) => {
                if (value === "carbonEmission") {
                  value = t("CO₂ Emission") + " (kgCO₂)";
                } else if (value === "emissionFactor") {
                  value = t("Actual Emission Factor") + " (gCO₂/km)";
                } else if (value === "baselineEmission") {
                  value = t("Baseline Emission") + " (kgCO₂)";
                } else {
                  value = t("Baseline Emission Factor") + " (gCO₂/km)";
                }
                return value;
              }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    );
  })
);
