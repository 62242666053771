import { Colors, invertDark } from "../../Assets/Config/color.config";
import { My_Button, My_Input, My_upload_image, Selector } from "../../Tools";
import { useHistory } from "react-router-dom";
import { inject, observer } from "mobx-react";
import Store from "../../Store";
import { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";

import { useTranslation } from "react-i18next";

export const IssueDetailModal = inject("store")(
  observer((props) => {
    const { theme, filteredIssues, selectedIssueId } = props;

    const selectedIssueInfo = filteredIssues?.find((issue) => issue.id === selectedIssueId);
    const {
      selectedIssue,
      setSelectedIssue,
      _handleEditCompany,
      infoModalView,
      setInfoModalView,
      issueDetail,
    } = props.store.adminPanel;

    // console.log("issue ======>",issue)

    const { isLoading } = props.store.dataEntry;

    const cardStyle = {
      color: invertDark(theme),
      background: Colors[theme].background,
    };

    const { t } = useTranslation("admin-panel");
    // let issueDetail = filteredIssues.find(v => v.id === selectedIssue)
    // console.log("issueDetail====>",issueDetail)

    return (
      <div
        className="modal fade"
        id="issuedetailmodal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className={`modal-dialog ${!infoModalView ? "modal-xl" : "modal-lg"}`}
        >
          <div className="modal-content" style={cardStyle}>
            {infoModalView ? (
              <>
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Issue Details for {selectedIssueInfo && selectedIssueInfo.issue}
                  </h5>
                  <button
                    type="button"
                    //  onClick={() => _handleLoading(false)}
                    className={`btn-close ${
                      theme === "dark" ? "btn-close-white" : ""
                    }`}
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div>
                    {issueDetail?.grouping ? (
                      <div className="row mb-1">
                        <span className="col-5">Grouping</span>
                        <span className="col-1">:</span>
                        <span className="col-6 text-start">
                          {issueDetail?.grouping || (
                            <div className="col-6 text-start ">N/A</div>
                          )}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    {issueDetail?.duration_seconds ||
                    issueDetail?.duration_seconds === "" ? (
                      <div className="row mb-1">
                        <span className="col-5">Duration</span>
                        <span className="col-1">:</span>
                        <span className="col-6 text-start">
                          {issueDetail?.duration_seconds || (
                            <div className="col-6 text-start ">N/A</div>
                          )}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    {issueDetail?.idling || issueDetail?.idling === "" ? (
                      <div className="row mb-1">
                        <span className="col-5">Idling</span>
                        <span className="col-1">:</span>
                        <span className="col-6 text-start">
                          {issueDetail?.idling || (
                            <div className="col-6 text-start ">N/A</div>
                          )}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    {issueDetail?.in_motion || issueDetail?.in_motion === "" ? (
                      <div className="row mb-1">
                        <span className="col-5">In Motion</span>
                        <span className="col-1">:</span>
                        <span className="col-6 text-start">
                          {issueDetail?.in_motion || (
                            <div className="col-6 text-start ">N/A</div>
                          )}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* {issueDetail?.start_link ||
                    issueDetail?.start_link === "" ? (
                      <div className="row mb-1">
                        <span className="col-5">Start Link</span>
                        <span className="col-1">:</span>
                        <span className="col-6 text-start justify-content-end">
                          {issueDetail?.start_link || (
                            <div className="col-6 text-start ">N/A</div>
                          )}
                        </span>
                      </div>
                    ) : (
                      ""
                    )} */}
                    {issueDetail?.start_point ||
                    issueDetail?.start_point === "" ? (
                      <div className="row mb-1">
                        <span className="col-5">Start Point</span>
                        <span className="col-1">:</span>
                        <span className="col-6 text-start">
                          {issueDetail?.start_point || (
                            <div className="col-6 text-start ">N/A</div>
                          )}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    {issueDetail?.start_time ||
                    issueDetail?.start_time === "" ? (
                      <div className="row mb-1">
                        <span className="col-5">Start Time</span>
                        <span className="col-1">:</span>
                        <span className="col-6 text-start">
                          {issueDetail?.start_time || (
                            <div className="col-6 text-start ">N/A</div>
                          )}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* {issueDetail?.to_link || issueDetail?.to_link === "" ? (
                      <div className="row mb-1">
                        <span className="col-5">To Link</span>
                        <span className="col-1">:</span>
                        <span className="col-6 text-start">
                          {issueDetail?.to_link || (
                            <div className="col-6 text-start ">N/A</div>
                          )}
                        </span>
                      </div>
                    ) : (
                      ""
                    )} */}
                    {issueDetail?.to_point || issueDetail?.to_point === "" ? (
                      <div className="row mb-1">
                        <span className="col-5">To Point</span>
                        <span className="col-1">:</span>
                        <span className="col-6 text-start">
                          {issueDetail?.to_point || (
                            <div className="col-6 text-start ">N/A</div>
                          )}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    {issueDetail?.to_time || issueDetail?.to_time === "" ? (
                      <div className="row mb-1">
                        <span className="col-5">To Time</span>
                        <span className="col-1">:</span>
                        <span className="col-6 text-start">
                          {issueDetail?.to_time || (
                            <div className="col-6 text-start ">N/A</div>
                          )}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    {issueDetail?.total_engine_hours ||
                    issueDetail?.total_engine_hours === "" ? (
                      <div className="row mb-1">
                        <span className="col-5">Total Engine Hours</span>
                        <span className="col-1">:</span>
                        <span className="col-6 text-start">
                          {issueDetail?.total_engine_hours || (
                            <div className="col-6 text-start ">N/A</div>
                          )}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    {issueDetail?.driver || issueDetail?.driver === "" ? (
                      <div className="row mb-1">
                        <span className="col-5">Driver</span>
                        <span className="col-1">:</span>
                        <span className="col-6 text-start">
                          {issueDetail?.driver || (
                            <div className="col-6 text-start ">N/A</div>
                          )}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* {issueDetail?.adjusted_avg_speed ||
                    issueDetail?.adjusted_avg_speed === "" ? (
                      <div className="row mb-1">
                        <span className="col-5">Adjusted Average Speed</span>
                        <span className="col-1">:</span>
                        <span className="col-6 text-start">
                          {issueDetail?.adjusted_avg_speed || (
                            <div className="col-6 text-start ">N/A</div>
                          )}
                        </span>
                      </div>
                    ) : (
                      ""
                    )} */}
                    {/* {issueDetail?.adjusted_max_speed ||
                    issueDetail?.adjusted_max_speed === "" ? (
                      <div className="row mb-1">
                        <span className="col-5">Adjusted Maximum Speed</span>
                        <span className="col-1">:</span>
                        <span className="col-6 text-start">
                          {issueDetail?.adjusted_max_speed || (
                            <div className="col-6 text-start ">N/A</div>
                          )}
                        </span>
                      </div>
                    ) : (
                      ""
                    )} */}
                    {/* {issueDetail?.adjusted_trip_milage ||
                    issueDetail?.adjusted_trip_milage === "" ? (
                      <div className="row mb-1">
                        <span className="col-5">Adjusted Trip Milage</span>
                        <span className="col-1">:</span>
                        <span className="col-6 text-start">
                          {issueDetail?.adjusted_trip_milage || (
                            <div className="col-6 text-start ">N/A</div>
                          )}
                        </span>
                      </div>
                    ) : (
                      ""
                    )} */}
                    {/* {issueDetail?.avg_speed_daily_total ||
                    issueDetail?.avg_speed_daily_total === "" ? (
                      <div className="row mb-1">
                        <span className="col-5">Average Speed Daily Total</span>
                        <span className="col-1">:</span>
                        <span className="col-6 text-start">
                          {issueDetail?.avg_speed_daily_total || (
                            <div className="col-6 text-start ">N/A</div>
                          )}
                        </span>
                      </div>
                    ) : (
                      ""
                    )} */}
                    {/* {issueDetail?.avg_speed_kmh ||
                    issueDetail?.avg_speed_kmh === "" ? (
                      <div className="row mb-1">
                        <span className="col-5">Average Speed Kmh</span>
                        <span className="col-1">:</span>
                        <span className="col-6 text-start">
                          {issueDetail?.avg_speed_kmh || (
                            <div className="col-6 text-start ">N/A</div>
                          )}
                        </span>
                      </div>
                    ) : (
                      ""
                    )} */}
                    {/* {issueDetail?.derived_battery_consumed_kwh ||
                    issueDetail?.derived_battery_consumed_kwh === "" ? (
                      <div className="row mb-1">
                        <span className="col-5">
                          Derived Battery Consumed 
                        </span>
                        <span className="col-1">:</span>
                        <span className="col-6 text-start">
                          {issueDetail?.derived_battery_consumed_kwh || (
                            <div className="col-6 text-start ">N/A</div>
                          )}
                        </span>
                      </div>
                    ) : (
                      ""
                    )} */}
                    {issueDetail?.final_battery_consumption ||
                    issueDetail?.final_battery_consumption === "" ? (
                      <div className="row mb-1">
                        <span className="col-5">Final Battery Consumption</span>
                        <span className="col-1">:</span>
                        <span className="col-6 text-start">
                          {issueDetail?.final_battery_consumption || (
                            <div className="col-6 text-start ">N/A</div>
                          )}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    {issueDetail?.final_battery_lvl ||
                    issueDetail?.final_battery_lvl === "" ? (
                      <div className="row mb-1">
                        <span className="col-5">Final Battery Level</span>
                        <span className="col-1">:</span>
                        <span className="col-6 text-start">
                          {issueDetail?.final_battery_lvl || (
                            <div className="col-6 text-start ">N/A</div>
                          )}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* {issueDetail?.final_fuel_consumed ||
                    issueDetail?.final_fuel_consumed === "" ? (
                      <div className="row mb-1">
                        <span className="col-5">Final Fuel Consumed</span>
                        <span className="col-1">:</span>
                        <span className="col-6 text-start">
                          {issueDetail?.final_fuel_consumed || (
                            <div className="col-6 text-start ">N/A</div>
                          )}
                        </span>
                      </div>
                    ) : (
                      ""
                    )} */}
                    {issueDetail?.final_fuel_lvl ||
                    issueDetail?.final_fuel_lvl === "" ? (
                      <div className="row mb-1">
                        <span className="col-5">Final Fuel Level</span>
                        <span className="col-1">:</span>
                        <span className="col-6 text-start">
                          {issueDetail?.final_fuel_lvl || (
                            <div className="col-6 text-start ">N/A</div>
                          )}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    {issueDetail?.final_mileage ||
                    issueDetail?.final_mileage === "" ? (
                      <div className="row mb-1">
                        <span className="col-5">Final Mileage</span>
                        <span className="col-1">:</span>
                        <span className="col-6 text-start">
                          {issueDetail?.final_mileage || (
                            <div className="col-6 text-start ">N/A</div>
                          )}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    {issueDetail?.fuel_consumed ||
                    issueDetail?.fuel_consumed === "" ? (
                      <div className="row mb-1">
                        <span className="col-5">Fuel Consumed</span>
                        <span className="col-1">:</span>
                        <span className="col-6 text-start">
                          {issueDetail?.fuel_consumed || (
                            <div className="col-6 text-start ">N/A</div>
                          )}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    {issueDetail?.initial_battery_consumption ||
                    issueDetail?.initial_battery_consumption === "" ? (
                      <div className="row mb-1">
                        <span className="col-5">
                          Initial Battery Consumption
                        </span>
                        <span className="col-1">:</span>
                        <span className="col-6 text-start">
                          {issueDetail?.initial_battery_consumption || (
                            <div className="col-6 text-start ">N/A</div>
                          )}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    {issueDetail?.initial_battery_lvl ||
                    issueDetail?.initial_battery_lvl === "" ? (
                      <div className="row mb-1">
                        <span className="col-5">Initial Battery Level </span>
                        <span className="col-1">:</span>
                        <span className="col-6 text-start">
                          {issueDetail?.initial_battery_lvl || (
                            <div className="col-6 text-start ">N/A</div>
                          )}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* {issueDetail?.initial_fuel_consumed ||
                    issueDetail?.initial_fuel_consumed === "" ? (
                      <div className="row mb-1">
                        <span className="col-5">Initial Fuel Consumed</span>
                        <span className="col-1">:</span>
                        <span className="col-6 text-start">
                          {issueDetail?.initial_fuel_consumed || (
                            <div className="col-6 text-start ">N/A</div>
                          )}
                        </span>
                      </div>
                    ) : (
                      ""
                    )} */}
                    {issueDetail?.initial_fuel_lvl ||
                    issueDetail?.initial_fuel_lvl === "" ? (
                      <div className="row mb-1">
                        <span className="col-5">Initial Fuel Level</span>
                        <span className="col-1">:</span>
                        <span className="col-6 text-start">
                          {issueDetail?.initial_fuel_lvl || (
                            <div className="col-6 text-start ">N/A</div>
                          )}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* {issueDetail?.max_speed_daily_total ||
                    issueDetail?.max_speed_daily_total === "" ? (
                      <div className="row mb-1">
                        <span className="col-5">Max Speed Daily Total</span>
                        <span className="col-1">:</span>
                        <span className="col-6 text-start">
                          {issueDetail?.max_speed_daily_total || (
                            <div className="col-6 text-start ">N/A</div>
                          )}
                        </span>
                      </div>
                    ) : (
                      ""
                    )} */}
                    {/* {issueDetail?.max_speed_kmh ||
                    issueDetail?.max_speed_kmh === "" ? (
                      <div className="row mb-1">
                        <span className="col-5">Max Speed Kmh</span>
                        <span className="col-1">:</span>
                        <span className="col-6 text-start">
                          {issueDetail?.max_speed_kmh || (
                            <div className="col-6 text-start ">N/A</div>
                          )}
                        </span>
                      </div>
                    ) : (
                      ""
                    )} */}
                    {issueDetail?.trip_duration_seconds ||
                    issueDetail?.trip_duration_seconds === "" ? (
                      <div className="row mb-1">
                        <span className="col-5">Trip Duration</span>
                        <span className="col-1">:</span>
                        <span className="col-6 text-start">
                          {issueDetail?.trip_duration_seconds || (
                            <div className="col-6 text-start ">N/A</div>
                          )}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    {issueDetail?.trip_mileage ||
                    issueDetail?.trip_mileage === "" ? (
                      <div className="row mb-1">
                        <span className="col-5">Trip Mileage</span>
                        <span className="col-1">:</span>
                        <span className="col-6 text-start">
                          {issueDetail?.trip_mileage || (
                            <div className="col-6 text-start ">N/A</div>
                          )}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* {issueDetail?.trip_mileage_daily_total ||
                    issueDetail?.trip_mileage_daily_total === "" ? (
                      <div className="row mb-1">
                        <span className="col-5">Trip Mileage Daily Total</span>
                        <span className="col-1">:</span>
                        <span className="col-6 text-start">
                          {issueDetail?.trip_mileage_daily_total || (
                            <div className="col-6 text-start ">N/A</div>
                          )}
                        </span>
                      </div>
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    );
  })
);
