export const typeOptions = [
    { label: "Crop and animal production, hunting and related service activities", value: "Crop and animal production, hunting and related service activities" },
    { label: "Forestry and logging", value: "Forestry and logging" },
    { label: "Fishing and aquaculture", value: "Fishing and aquaculture" },
    { label: "Mining of coal and lignite", value: "Mining of coal and lignite" },
    { label: "Extraction of crude petroleum and natural gas", value: "Extraction of crude petroleum and natural gas" },
    { label: "Mining of metal ores", value: "Mining of metal ores" },
    { label: "Other mining and quarrying", value: "Other mining and quarrying" },
    { label: "Mining support service activities", value: "Mining support service activities" },
    { label: "Manufacture of food products", value: "Manufacture of food products" },
    { label: "Manufacture of beverages", value: "Manufacture of beverages" },
    { label: "Manufacture of tobacco products", value: "Manufacture of tobacco products" },
    { label: "Manufacture of textiles", value: "Manufacture of textiles" },
    { label: "Manufacture of wearing apparel", value: "Manufacture of wearing apparel" },
    { label: "Manufacture of leather and related products", value: "Manufacture of leather and related products" },
    { label: "Manufacture of wood and of products of wood and cork, except furniture; manufacture of articles of straw and plaiting materials", value: "Manufacture of wood and of products of wood and cork, except furniture; manufacture of articles of straw and plaiting materials" },
    { label: "Manufacture of paper and paper products", value: "Manufacture of paper and paper products" },
    { label: "Printing and reproduction of recorded media", value: "Printing and reproduction of recorded media" },
    { label: "Manufacture of coke and refined petroleum products", value: "Manufacture of coke and refined petroleum products" },
    { label: "Manufacture of chemicals and chemical products", value: "Manufacture of chemicals and chemical products" },
    { label: "Manufacture of basic pharmaceutical products and pharmaceutical preparations", value: "Manufacture of basic pharmaceutical products and pharmaceutical preparations" },
    { label: "Manufacture of rubber and plastics products", value: "Manufacture of rubber and plastics products" },
    { label: "Manufacture of other non-metallic mineral products", value: "Manufacture of other non-metallic mineral products" },
    { label: "Manufacture of basic metals", value: "Manufacture of basic metals" },
    { label: "Manufacture of fabricated metal products, except machinery and equipment", value: "Manufacture of fabricated metal products, except machinery and equipment" },
    { label: "Manufacture of computer, electronic and optical products", value: "Manufacture of computer, electronic and optical products" },
    { label: "Manufacture of electrical equipment", value: "Manufacture of electrical equipment" },
    { label: "Manufacture of machinery and equipment n.e.c.", value: "Manufacture of machinery and equipment n.e.c." },
    { label: "Manufacture of motor vehicles, trailers and semi-trailers", value: "Manufacture of motor vehicles, trailers and semi-trailers" },
    { label: "Manufacture of other transport equipment", value: "Manufacture of other transport equipment" },
    { label: "Manufacture of furniture", value: "Manufacture of furniture" },
    { label: "Other manufacturing", value: "Other manufacturing" },
    { label: "Repair and installation of machinery and equipment", value: "Repair and installation of machinery and equipment" },
    { label: "Electricity, gas, steam and air conditioning supply", value: "Electricity, gas, steam and air conditioning supply" },
    { label: "Water collection, treatment and supply", value: "Water collection, treatment and supply" },
    { label: "Sewerage", value: "Sewerage" },
    { label: "Waste collection, treatment and disposal activities; materials recovery", value: "Waste collection, treatment and disposal activities; materials recovery" },
    { label: "Remediation activities and other waste management services", value: "Remediation activities and other waste management services" },
    { label: "Construction of buildings", value: "Construction of buildings" },
    { label: "Civil engineering", value: "Civil engineering" },
    { label: "Specialized construction activities", value: "Specialized construction activities" },
    { label: "Wholesale and retail trade and repair of motor vehicles and motorcycles", value: "Wholesale and retail trade and repair of motor vehicles and motorcycles" },
    { label: "Wholesale trade, except of motor vehicles and motorcycles", value: "Wholesale trade, except of motor vehicles and motorcycles" },
    { label: "Retail trade, except of motor vehicles and motorcycles", value: "Retail trade, except of motor vehicles and motorcycles" },
    { label: "Land transport and transport via pipelines", value: "Land transport and transport via pipelines" },
    { label: "Water transport", value: "Water transport" },
    { label: "Air transport", value: "Air transport" },
    { label: "Warehousing and support activities for transportation", value: "Warehousing and support activities for transportation" },
    { label: "Postal and courier activities", value: "Postal and courier activities" },
    { label: "Accommodation", value: "Accommodation" },
    { label: "Food and beverage service activities", value: "Food and beverage service activities" },
    { label: "Publishing activities", value: "Publishing activities" },
    { label: "Motion picture, video and television programme production, sound recording and music publishing activities", value: "Motion picture, video and television programme production, sound recording and music publishing activities" },
    { label: "Programming and broadcasting activities", value: "Programming and broadcasting activities" },
    { label: "Telecommunications", value: "Telecommunications" },
    { label: "Computer programming, consultancy and related activities", value: "Computer programming, consultancy and related activities" },
    { label: "Information service activities", value: "Information service activities" },
    { label: "Financial service activities, except insurance and pension funding", value: "Financial service activities, except insurance and pension funding" },
    { label: "Insurance, reinsurance and pension funding, except compulsory social security", value: "Insurance, reinsurance and pension funding, except compulsory social security" },
    { label: "Activities auxiliary to financial service and insurance activities", value: "Activities auxiliary to financial service and insurance activities" },
    { label: "Real estate activities", value: "Real estate activities" },
    { label: "Legal and accounting activities", value: "Legal and accounting activities" },
    { label: "Activities of head offices; management consultancy activities", value: "Activities of head offices; management consultancy activities" },
    { label: "Architectural and engineering activities; technical testing and analysis", value: "Architectural and engineering activities; technical testing and analysis" },
    { label: "Scientific research and development", value: "Scientific research and development" },
    { label: "Advertising and market research", value: "Advertising and market research" },
    { label: "Other professional, scientific and technical activities", value: "Other professional, scientific and technical activities" },
    { label: "Veterinary activities", value: "Veterinary activities" },
    { label: "Rental and leasing activities", value: "Rental and leasing activities" },
    { label: "Employment activities", value: "Employment activities" },
    { label: "Travel agency, tour operator, reservation service and related activities", value: "Travel agency, tour operator, reservation service and related activities" },
    { label: "Security and investigation activities", value: "Security and investigation activities" },
    { label: "Services to buildings and landscape activities", value: "Services to buildings and landscape activities" },
    { label: "Office administrative, office support and other business support activities", value: "Office administrative, office support and other business support activities" },
    { label: "Public administration and defence; compulsory social security", value: "Public administration and defence; compulsory social security" },
    { label: "Education", value: "Education" },
    { label: "Human health activities", value: "Human health activities" },
    { label: "Residential care activities", value: "Residential care activities" },
    { label: "Social work activities without accommodation", value: "Social work activities without accommodation" },
    { label: "Creative, arts and entertainment activities", value: "Creative, arts and entertainment activities" },
    { label: "Libraries, archives, museums and other cultural activities", value: "Libraries, archives, museums and other cultural activities" },
    { label: "Gambling and betting activities", value: "Gambling and betting activities" },
    { label: "Sports activities and amusement and recreation activities", value: "Sports activities and amusement and recreation activities" },
    { label: "Activities of membership organizations", value: "Activities of membership organizations" },
    { label: "Repair of computers and personal and household goods", value: "Repair of computers and personal and household goods" },
    { label: "Other personal service activities", value: "Other personal service activities" },
    { label: "Activities of households as employers of domestic personnel", value: "Activities of households as employers of domestic personnel" },
    { label: "Undifferentiated goods- and services-producing activities of private households for own use", value: "Undifferentiated goods- and services-producing activities of private households for own use" },
    { label: "Activities of extraterritorial organizations and bodies", value: "Activities of extraterritorial organizations and bodies" }
];
