import React, { useState } from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";

export const ViewScopeImage = ({ imgUrl, theme }) => {
  return (
    <div
      className="modal  fade"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div
          className="modal-content"
          style={{
            // background: Colors[theme].background,
            color: invertDark(theme),
          }}
        >
          {/* <div className="modal-header border-0 "> */}
          {/* <h5 className="modal-title text-center" id="exampleModalLabel"> */}
          {/* {asset_name ? asset_name : "no image"} Hello */}
          {/* </h5> */}
          {/* <button
              type="button"
              className={`btn-close ${theme === "dark" && "btn-close-white"}`}
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button> */}

          {/* </div> */}
          <div className="text-end me-3 mt-2 mb-0">
            <button
              type="button"
              className={`btn-close`}
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="mt-0">
            <img
              src={imgUrl}
              alt="scope_image"
              className="img-fluid py-0"
              style={{ width: "100%", borderRadius: 0 }}
              //   onError={(e) => {
              //     setImgUrl(`/cars/default_vehicle.png`);
              //   }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
