import React, { useEffect, useState } from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { inject, observer } from "mobx-react";
import { Button, Modal } from "react-bootstrap";
import { My_Button } from "../../Tools";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";

export const OverWriteConfirmModal = inject("store")(
  observer((props) => {
    const {t} = useTranslation("data_import")
    const { theme, showTable, id } = props;
    const history = useHistory();

    const {
      handleCreateManualEntryForOverWrite,
      dataAlreadyExist,
      handleClearManualEntryAlreadyExist,
      manualEntryExistVehicle,
    } = props.store.dataImport;

    const [showOverWriteConfirmModal, setShowOverWriteConfirmModal] =
      useState(false);

    let cardStyle = {
      color: invertDark(theme),
      background: Colors[theme].background,
      border: "none",
    };

    const closeModal = () => {
      setShowOverWriteConfirmModal(false);
      handleClearManualEntryAlreadyExist();
    };

    const openModal = () => {
      setShowOverWriteConfirmModal(true);
    };

    useEffect(() => {
      if (dataAlreadyExist === true) {
        openModal();
      }
    }, [dataAlreadyExist === true]);

    // console.log(id, "idprlr")
    let licenseNo = [];
    manualEntryExistVehicle?.map((v) => {
      if (!licenseNo.includes(v.license_plate)) {
        licenseNo.push(v.license_plate);
      }
    });

    return (
      <div>
        <Modal show={showOverWriteConfirmModal} onHide={closeModal}>
          <Modal.Header style={cardStyle}>
            <Modal.Title>
              <span>{t("Manual Entry Already Exist.")}</span>
            </Modal.Title>

            {/* <button
                            type="button"
                            style={{ background: 'none', border: 'none', color: invertDark(theme) }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={closeModal}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button> */}

            <span style={{ cursor: "pointer" }} onClick={closeModal}>
              <i className="bi bi-x-lg" />
            </span>
          </Modal.Header>
          <Modal.Body style={cardStyle}>
            <div>{t("Do you want to overwrite the following manual entry data?")}</div>

            <ul>
              {licenseNo.map((v, i) => (
                <li key={i}>{v}</li>
              ))}
            </ul>
            
          </Modal.Body>
          <Modal.Footer style={cardStyle}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "2em",
              }}
            >
              <button
                className={`${ theme === 'dark' ? 'btn btn-primary btn-lg' : 'btn btn-success btn-lg'}`}
                style={{ display: "flex", marginRight: "1em" }}
                onClick={() =>{
                  handleCreateManualEntryForOverWrite(id, true, history);
                  closeModal()
                }
                }
              >
                {t("Yes")}
              </button>

              <button
                className="btn btn-danger btn-lg"
                onClick={() =>{
                  handleCreateManualEntryForOverWrite(id, false, history);
                  closeModal();
                }
                }
              >
                {t("No")}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  })
);
