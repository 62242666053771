import { inject, observer } from "mobx-react";
import { useRef, useEffect, useState } from "react";
import "datatables.net-dt";
import "datatables.net-dt/css/jquery.dataTables.css";
import * as React from "react";
import $ from "jquery";
import { useTranslation } from "react-i18next";
import "jspdf-autotable";

export const MonthlyDetailDataTable = inject("store")(
  observer((props) => {
    const { t } = useTranslation("track_and_trace");
    const { theme, companyType } = props;
    const { selectedMonthlyDetail } = props.store.trackAndTrace;

    const rows = selectedMonthlyDetail?.units?.map((data) => {
      let selectedmonthlydetailUnits =
        companyType === "1"
          ? {
              plate_number: data.plate_number ? data.plate_number : "N/A",
              consumption:
                data.type === "battery"
                  ? Number(data.battery_consumed).toFixed(2) + " kWh"
                  : Number(data.fuel_consumed).toFixed(2) + " L",
              mileage:
                data.trip_mileage === null
                  ? "0 km"
                  : Number(data.trip_mileage).toFixed(2) + " km",
              engine_hour: data?.engine_hr,
              baseline_emission:
                Number(data.baseline_emission).toFixed(2) + " kgCO₂e",
              baselineEmissionType: data.baselineEmissionType,
              co2_emission: Number(data.carbon_emission).toFixed(2) + " kgCO₂e",
            }
          : {
              plate_number: data.plate_number ? data.plate_number : "N/A",
              consumption:
                data.type === "battery"
                  ? Number(data.battery_consumed).toFixed(2) + " kWh"
                  : Number(data.fuel_consumed).toFixed(2) + " L",
              engine_hr:
                data.trip_mileage === null
                  ? "0 km"
                  : Number(data.trip_mileage).toFixed(2) + " km",
              baseline_emission:
                Number(data.baseline_emission).toFixed(2) + " kgCO₂e",
              baselineEmissionType: data.baselineEmissionType,
              co2_emission: Number(data.carbon_emission).toFixed(2) + " kgCO₂e",
            };

      return selectedmonthlydetailUnits;
    });

    const tableRef = useRef(null);

    // const handleExportPDF = () => {
    //   const doc = new jsPDF();
    //   doc.autoTable({
    //     head: [Object.keys(rows[0])],
    //     body: rows.map(row => Object.values(row)),
    //     startY: 20, // Adjust the starting Y position of the table
    //     styles: {
    //       cellPadding: 5,
    //       fontSize: 10,
    //       lineHeight: 5,
    //     },
    //     margin: { top: 30 }, // Adjust the top margin
    //   })
    //   doc.save('table.pdf');
    // };

    useEffect(() => {
      const styleElement = document.createElement("style");
      styleElement.innerHTML =
        theme === "dark"
          ? `
           
           
            .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
                color: black !important;
                border: 1px solid #e5e5e5!important;
                
                background-color: #e5e5e5 !important;
                /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #e5e5e5), color-stop(100%, #e5e5e5))!important; */
                background: -webkit-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
                background: -moz-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
                background: -ms-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%) !important;
                background: -o-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
                background: linear-gradient(to bottom, #e5e5e5 0%, #e5e5e5 100%)!important;
              }

              .dataTables_wrapper .dataTables_paginate .paginate_button.current {
                color: black !important;
                border: 1px solid #C6C6C6!important;
                
                background-color: #C6C6C6 !important;
                /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fffcfc), color-stop(100%, #969292))!important; */
                background: -webkit-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                background: -moz-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                background: -ms-linear-gradient(top, #fffcfc 0%, #969292 100%) !important;
                background: -o-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                background: linear-gradient(to bottom, #fffcfc 0%, #969292 100%)!important;
              }

              .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
                color: black !important;
                border: 1px solid #C6C6C6!important;
                
                background-color: #C6C6C6 !important;
                /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fffcfc), color-stop(100%, #969292))!important; */
                background: -webkit-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                background: -moz-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                background: -ms-linear-gradient(top, #fffcfc 0%, #969292 100%) !important;
                background: -o-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                background: linear-gradient(to bottom, #fffcfc 0%, #969292 100%)!important;
              }


            `
          : `
             
                .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
                    color: black !important;
                    border: 1px solid #e5e5e5!important;
                    
                    background-color: #e5e5e5 !important;
                    /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #e5e5e5), color-stop(100%, #e5e5e5))!important; */
                    background: -webkit-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
                    background: -moz-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
                    background: -ms-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%) !important;
                    background: -o-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
                    background: linear-gradient(to bottom, #e5e5e5 0%, #e5e5e5 100%)!important;
                  }

                  .dataTables_wrapper .dataTables_paginate .paginate_button.current {
                    color: black !important;
                    border: 1px solid #C6C6C6!important;
                    
                    background-color: #C6C6C6 !important;
                    /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fffcfc), color-stop(100%, #969292))!important; */
                    background: -webkit-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                    background: -moz-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                    background: -ms-linear-gradient(top, #fffcfc 0%, #969292 100%) !important;
                    background: -o-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                    background: linear-gradient(to bottom, #fffcfc 0%, #969292 100%)!important;
                  }
       
            `;
      document.head.appendChild(styleElement);

      function updateOrderAndDisplayStart(columnIndex, direction) {
        orderColumn = columnIndex;
        orderDirection = direction;

        table.order([[orderColumn, orderDirection]]).draw();
      }

      const storedOrderColumn = localStorage.getItem("orderColumn");
      const storedOrderDirection = localStorage.getItem("orderDirection");

      let orderColumn = storedOrderColumn ? parseInt(storedOrderColumn) : 0;
      let orderDirection = storedOrderDirection || "asc";

      const table = $("#monthlydetailDatatable").DataTable({
        autoWidth: true,
        responsive: true,
        retrieve: true,
        layout: {
          topStart: {
            buttons: ["pdfHtml5"],
          },
        },
        scrollX: true,
        order: [[1, "asc"]],
        // language: {
        //     searchPlaceholder: 'Search',
        // },

        lengthMenu: [8, 10, 15],

        data: rows,
        columns:
          companyType === "1"
            ? [
                {
                  title: t("Plate No."),
                  data: "plate_number",
                },
                {
                  title: t("Consumption"),
                  data: "consumption",
                },
                {
                  title: t("Mileage"),
                  data: "mileage",
                },
                {
                  title: t("Engine Hour"),
                  data: "engine_hour",
                },
                {
                  title: t("CO₂e Emission"),
                  data: "co2_emission",
                },
                {
                  title: t("Baseline CO₂e Emission"),
                  data: "baseline_emission",
                },
                {
                  title: t("Baseline Type"),
                  data: "baselineEmissionType",
                },
              ]
            : [
                {
                  title: t("Plate No."),
                  data: "plate_number",
                },
                {
                  title: t("Consumption"),
                  data: "consumption",
                },
                {
                  title: t("Engine Hour"),
                  data: "engine_hr",
                },
                {
                  title: t("CO₂e Emission"),
                  data: "co2_emission",
                },
                {
                  title: t("Baseline CO₂e Emission"),
                  data: "baseline_emission",
                },
                {
                  title: t("Baseline Type"),
                  data: "baselineEmissionType",
                },
              ],

        // width: rows.length <= 0 || rows.length > 0;

        paging: rows.length > 0,

        drawCallback: function (settings) {
          console.log($("#monthlydetailDatatable").DataTable().page.info());
          console.log(settings.oSavedState);
        },
      });

      table.column(0).nodes().to$().css("width", "100px");
      table.column(1).nodes().to$().css("width", "100px");
      table.column(2).nodes().to$().css("width", "100px");
      table.column(3).nodes().to$().css("width", "100px");
      table.column(4).nodes().to$().css("width", "100px");
      table.column(5).nodes().to$().css("width", "100px");

      table.cells().every(function () {
        $(this.node()).css("border-color", "rgb(211, 211, 211, 0.3)");
      });

      const searchInput = $(".dataTables_filter input");
      searchInput.css("color", theme === "light" ? "black" : "white");

      const searchlabel = $(".dataTables_filter label");
      searchlabel.css("color", theme === "light" ? "black" : "white");

      const entriesSelect = $(".dataTables_length select");
      entriesSelect.css(
        "background-color",
        theme === "light" ? "#fff" : "#202b60"
      );
      entriesSelect.css("color", theme === "light" ? "black" : "white");

      const entriesLabel = $(".dataTables_length label");
      entriesLabel.css("color", theme === "light" ? "black" : "white");

      const tableInfo = $(".dataTables_info");
      tableInfo.css("color", theme === "light" ? "#000" : "#fff");

      const paginate = $(".dataTables_paginate paginate_button");
      paginate.css("color", "#000 !important");

      $("#monthlydetailDatatable").on("click", "tbody tr", function () {
        const data = table.row(this).data();
      });

      const storedCurrentPage = localStorage.getItem("currentPage");
      const storedSearchKeyword = localStorage.getItem("searchKeyword");

      const currentPage = storedCurrentPage ? parseInt(storedCurrentPage) : 0;
      table.page(currentPage).draw(false);

      if (storedSearchKeyword) {
        table.search(storedSearchKeyword).draw();
      }

      table.on("page.dt", function () {
        localStorage.setItem("currentPage", table.page.info().page);
      });

      table.on("search.dt", function () {
        const searchKeyword = table.search();
        localStorage.setItem("searchKeyword", searchKeyword);
      });

      window.addEventListener("beforeunload", function () {
        localStorage.removeItem("orderColumn");
        localStorage.removeItem("orderDirection");
        localStorage.removeItem("currentPage");
      });

      window.addEventListener("load", function () {
        localStorage.removeItem("orderColumn");
        localStorage.removeItem("orderDirection");
        localStorage.removeItem("currentPage");
      });

      const storedPage = localStorage.getItem("currentPage");

      if (storedPage) {
        const currentPage = parseInt(storedPage);
        table.page(currentPage).draw("page");
      }

      return () => {
        if ($.fn.DataTable.isDataTable("#monthlydetailDatatable")) {
          table.destroy();
        }
      };
    }, [selectedMonthlyDetail, theme, rows]);

    return (
      <div>
        <table
          ref={tableRef}
          id="monthlydetailDatatable"
          className="display cell-border hover stripe"
          style={{ width: "100%" }}
        ></table>
        {/* <button onClick={() => handleExportPDF()}>save</button> */}
      </div>
    );
  })
);
