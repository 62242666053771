import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import carImage from './images/car.png'
import { useTranslation } from 'react-i18next';
export const DonutChart = (props) => {
    const {t} = useTranslation("lite-dashboard")
    const { valueData, labels, bgColor, bdColor } = props;
    ChartJS.register(ArcElement, Tooltip, Legend);
    const image = new Image(15, 15);
    image.src = carImage;
    const data = {
        labels: labels,
        datasets: [
            {
                label: t('Number of Vehicles'),
                data: valueData,
                backgroundColor: bgColor,
                borderColor: bdColor,
                borderWidth: 1,
            },
        ],
    };

    return (
        <div style={{maxHeight:400}}><Doughnut data={data} options={{
            plugins: {
                legend: {
                    display: false,
                    position: "bottom"
                },
                tooltip: {
                    usePointStyle: true,
                    callbacks: {
                        labelPointStyle: (context) => {
                            return {
                                pointStyle: image,
                            }
                        }
                    }

                }
            }
        }} /></div>);

}
