import { inject, observer } from "mobx-react";
import React from "react";
import { CO2_Loading } from "../../Assets/CO2_Loading";
import CarbonEmissionChart from "./CarbonEmissionChart";
import "../../App.css";
import { emission_vehicle } from "../../Assets/Config/constant.config";
import { useTranslation } from "react-i18next";
const VehicleProfile = (props) => {
    const { cardStyle1 } = props;
    const {t} = useTranslation("new-tracking")

    const { assetProfile } = props.store.tracking;

    const isLoading = !assetProfile;
    const totalCarbonEmission = assetProfile
        ? (Number(assetProfile.fuelConsumed) * emission_vehicle) / 1000
        : 0;

    const totalTripMileage = assetProfile
        ? Number(assetProfile.vehicleMileage)
        : 0;

    const emissionFactor = (totalCarbonEmission / totalTripMileage) * 1000000;

    const rowStyle = {
        marginRight: 5,
        minHeight: 28,
        borderBottom: `1px solid rgba(100,100,100,0.2)`,
    };
    return (
        <div
            style={cardStyle1}
            className="d-flex flex-row flex-wrap justify-content-center"
        >
            {isLoading && <CO2_Loading text={t("Loading...")} />}
            {!isLoading && (
                <>
                    <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12 col-xs-12 mb-2 ">
                        <h5 className="mb-3">{t("Vehicle Profile")}</h5>

                        <div
                            className="d-flex justify-content-between align-items-center px-2 mx-0"
                            style={rowStyle}
                        >
                            <strong style={{ textAlign: "left" }}>{t("Vehicle Type")}</strong>
                            <span style={{ textAlign: "right" }}>
                                {assetProfile.vehicleType || "--"}
                            </span>
                        </div>
                        <div
                            className="d-flex justify-content-between align-items-center px-2 mx-0"
                            style={rowStyle}
                        // data-title={`Vehicle Sub Type : ${assetProfile.vehicleSubType || '--'}`}
                        >
                            <strong style={{ textAlign: "left" }}>{t("Vehicle Sub Type")}</strong>
                            <span style={{ textAlign: "right" }}>
                                {assetProfile.vehicleSubType || "--"}
                            </span>
                        </div>
                        <div
                            className="d-flex justify-content-between align-items-center px-2 mx-0"
                            style={rowStyle}
                        >
                            <strong style={{ textAlign: "left" }}>{t("Brand")}</strong>
                            <span style={{ textAlign: "right" }}>
                                {assetProfile.brand || "--"}
                            </span>
                        </div>
                        <div
                            className="d-flex justify-content-between align-items-center px-2 mx-0"
                            style={rowStyle}
                        >
                            <strong style={{ textAlign: "left" }}>{t("Model")}</strong>
                            <span style={{ textAlign: "right" }}>
                                {assetProfile.model || "--"}
                            </span>
                        </div>
                        <div
                            className="d-flex justify-content-between align-items-center px-2 mx-0"
                            style={rowStyle}
                        >
                            <strong style={{ textAlign: "left" }}>
                                {t("Carrying Capacity (tonnes)")}
                            </strong>
                            <span style={{ textAlign: "right" }}>
                                {assetProfile.carryingCapicity == null
                                    ? "--"
                                    : Number(assetProfile.carryingCapicity).toLocaleString('en-US')}
                            </span>
                        </div>
                        <div
                            className="d-flex justify-content-between align-items-center px-2 mx-0"
                            style={rowStyle}
                        >
                            <strong style={{ textAlign: "left" }}>
                                {t("Gross Vehicle Weight (tonnes)")}
                            </strong>
                            <span style={{ textAlign: "right" }}>
                                {assetProfile.grossVehicleWeight == null
                                    ? "--"
                                    : Number(assetProfile.grossVehicleWeight).toLocaleString('en-US')}
                            </span>
                        </div>
                        <div
                            className="d-flex justify-content-between align-items-center px-2 mx-0"
                            style={rowStyle}
                        >
                            <strong style={{ textAlign: "left" }}>{t("Primary Fuel Type")}</strong>
                            <span style={{ textAlign: "right" }}>
                                {assetProfile.primaryFuelType || "--"}
                            </span>
                        </div>
                        <div
                            className="d-flex justify-content-between align-items-center px-2 mx-0"
                            style={rowStyle}
                        >
                            <strong style={{ textAlign: "left" }}>{t("Secondary Fuel Type")}</strong>
                            <span style={{ textAlign: "right" }}>
                                {assetProfile.secondaryFuelType || "--"}
                            </span>
                        </div>
                        <div
                            className="d-flex justify-content-between align-items-center px-2 mx-0"
                            style={rowStyle}
                        >
                            <strong style={{ textAlign: "left" }}>
                                {t("Vehicle Mileage")} (km)
                            </strong>
                            <span style={{ textAlign: "right" }}>
                                {assetProfile.vehicleMileage == null
                                    ? "--"
                                    : Number(assetProfile.vehicleMileage).toLocaleString('en-US')}
                            </span>
                        </div>
                        <div
                            className="d-flex justify-content-between align-items-center px-2 mx-0"
                            style={rowStyle}
                        >
                            <strong style={{ textAlign: "left" }}>
                                {t("Fuel Consumption")} (L)
                            </strong>
                            <span style={{ textAlign: "right" }}>
                                {assetProfile.fuelConsumed == null
                                    ? "--"
                                    : Number(assetProfile.fuelConsumed).toLocaleString('en-US')}
                            </span>
                        </div>
                        <div
                            className="d-flex justify-content-between align-items-center px-2 mx-0"
                            style={rowStyle}
                        >
                            <strong style={{ textAlign: "left" }}>
                                {t("Total Idling Duration (Hours)")}
                            </strong>
                            <span style={{ textAlign: "right" }}>
                                {assetProfile.totalIdlingDuration == null
                                    ? "--"
                                    : Number(assetProfile.totalIdlingDuration).toLocaleString('en-US')}
                            </span>
                        </div>
                        <div
                            className="d-flex justify-content-between align-items-center px-2 mx-0"
                            style={rowStyle}
                        >
                            <strong style={{ textAlign: "left" }}>
                                {t("Total In-Motion Duration (Hours)")}
                            </strong>
                            <span style={{ textAlign: "right" }}>
                                {assetProfile.totalInMotionDuration == null
                                    ? "--"
                                    : Number(assetProfile.totalInMotionDuration).toLocaleString('en-US')}
                            </span>
                        </div>
                        <div
                            className="d-flex justify-content-between align-items-center px-2 mx-0"
                            style={rowStyle}
                        >
                            <strong style={{ textAlign: "left" }}>
                                {t("Harsh-Braking Instances")}
                            </strong>
                            <span style={{ textAlign: "right" }}>
                                {assetProfile.harshBreakingInstances || "--"}
                            </span>
                        </div>
                        <div
                            className="d-flex justify-content-between align-items-center px-2 mx-0"
                            style={rowStyle}
                        >
                            <strong style={{ textAlign: "left" }}>
                                {t("Harsh-Acceleration Instances")}
                            </strong>
                            <span style={{ textAlign: "right" }}>
                                {assetProfile.harshAccelerationInstances || "--"}
                            </span>
                        </div>
                        <div
                            className="d-flex justify-content-between align-items-center px-2 mx-0"
                            style={rowStyle}
                        >
                            <strong style={{ textAlign: "left" }}>
                                {t("Total Carbon Emission(tonnes)")}
                            </strong>
                            <span style={{ textAlign: "right" }}>
                                {assetProfile.totalCarbonEmission != null ? Number(assetProfile.totalCarbonEmission).toLocaleString('en-US') :
                                    (totalCarbonEmission != null ? Number(totalCarbonEmission.toFixed(2)).toLocaleString('en-US') :
                                        "--")}
                            </span>
                        </div>
                        <div
                            className="d-flex justify-content-between align-items-center px-2 mx-0"
                            style={rowStyle}
                        >
                            <strong style={{ textAlign: "left" }}>
                                {t("Emission Factor")} (gCO₂/km)
                            </strong>
                            <span style={{ textAlign: "right" }}>
                                {assetProfile.emissionFactor != null ? Number(assetProfile.emissionFactor).toLocaleString('en-US') :
                                    (emissionFactor != null ? Number(emissionFactor.toFixed(2)).toLocaleString('en-US') :
                                        "--")}
                            </span>
                        </div>
                    </div>
                    <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 col-xs-12 mb-5">
                        <CarbonEmissionChart />
                    </div>
                </>
            )}
        </div>
    );
};

export default inject("store")(observer(VehicleProfile));
