import { Colors, invertDark } from "../../Assets/Config/color.config";
import { AssetProfileForm } from "./AssetProfileForm";
import AddVehicleIcon from "../../Assets/Images/icons/car1.png";
import EditVehicleIcon from "../../Assets/Images/icons/editVehicle.png";
import { My_Button, My_Input, My_upload_image, Selector } from "../../Tools";
import { useState } from "react";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import { useTranslation } from "react-i18next";

export const AddVehicleModal = inject("store")(
    observer((props) => {
        const {t} = useTranslation("asset_profile_management")
        const { theme, currentAssets} = props;
        const { deviceData, 
                _handleTextChange, 
                _handleAddNewDevice, 
                validateError, 
                disableAddVehicle, 
                editMode , 
                _handleCloseModel} = props.store.assetProfile
        const [vehicleType, setVehicleType] = useState(vehicleTypeOption[0].value)
        const { role } = props.store.common

     
        console.log('deviceData',toJS(deviceData));
        return (
            <div
                className="modal fade show"
                id="addVehicleModal"
                tabIndex="-1"
                aria-labelledby="addVehicleModal"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xl rounded">
                    <div
                        className="modal-content"
                        style={{ background: Colors[theme].background, color: invertDark(theme) }}
                    >
                        <div className="modal-header" style={{ border: "none" }}>
                            {editMode ? <span style={{ fontSize: 24 }}>
                                <img height="30px" src={EditVehicleIcon} /> {t("Edit Vehicle")}
                            </span>
                                : <span style={{ fontSize: 24 }}>
                                    <img height="30px" src={AddVehicleIcon} /> {currentAssets === "equipment" ? t("Add New Equipment") : currentAssets === "infrastructure"? t("Add New Infrastructure") : t("Add New Vehicle") }
                                </span>}
                            <span data-bs-dismiss="modal"  onClick={()=> _handleCloseModel()} style={{ cursor: 'pointer' }}>
                                <i className="bi bi-x-lg" />
                            </span>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={(e) => _handleAddNewDevice(e, props)} className="row" style={{ fontSize: 14 }}>
                                <div className="col-4 col-sm-12 col-lg-4 col-md-4 col-xl-4 col-xs-4">
                                    <div className="pb-3">
                                        <div className="mb-1">{t("Vehicle Image")}</div>
                                        <UploadImage theme={theme} />
                                    </div>

                                    {/* <div className="pb-3">
                                    <div className="mb-1">Vehicle Subtype</div>
                                    <My_Input
                                        height='38px'
                                        required={true}
                                        id={"subType"}
                                        placeHolder={"Vehicle Subtype"}
                                    value={deviceData.vehicle_sub_type}
                                    onChange={(e) => _handleTextChange(e,'vehicle_sub_type')}
                                    />
                                </div> */}
                                    <div className="pb-3">
                                    
                                        <div className="mb-1">{t("Cargo Type")}</div>

                                        <My_Input
                                            height='38px'
                                            required={false}
                                            id={"cargo_type"}
                                            placeHolder={"Cargo Type"}
                                            value={deviceData.cargo_type}
                                            onChange={(e) => _handleTextChange(e, 'cargo_type')}
                                        />
                                    </div>

                                    <div className="pb-3">
                                    
                                        <div className="mb-1">{t("Carrying Capacity")} (tonnes)</div>
                                        <My_Input
                                            type='number'
                                            height='38px'
                                            required={false}
                                            id={"carryingCapacity"}
                                            placeHolder={"Carrying Capacity (tonnes)"}
                                            value={deviceData.carrying_capacity}
                                            onChange={(e) => _handleTextChange(e, 'carrying_capacity')}
                                        />
                                    </div>

                                    <div className="pb-3">
                                    
                                        <div className="mb-1">{t("Vehicle Width")} (mm)</div>
                                        <My_Input
                                            type='number'
                                            height='38px'
                                            required={false}
                                            id={"vehicleWidth"}
                                            placeHolder={"Vehicle Width (mm)"}
                                            value={deviceData.width}
                                            onChange={(e) => _handleTextChange(e, 'width')}
                                        />
                                    </div>

                                </div>

                                <div className="col-4 col-sm-12 col-lg-4 col-md-4 col-xl-4 col-xs-4">

                                    <div className="pb-3">
                                    <div className="mb-1"><span style={{ color: '#ffc107' }}>*</span> {t("Asset Type")}</div>
                                        <Selector value={
                                            assetTypeOption.filter((v) =>
                                                (v.value === deviceData.asset_type)
                                            )
                                            
                                        } _handleSelect={(e) => _handleTextChange(e, 'asset_type')} menuListColor={Colors.Dark[0]} options={assetTypeOption} />

                                        {validateError?.asset_type && <div className="mb-1" style={{  color: '#E00000', fontSize: '13px'  }}>{validateError.asset_type}</div> }

                                    </div>

                                    <div className="pb-3">
                                        <div className="mb-1"><span style={{ color: '#ffc107' }}>*</span> {t("Brand")}</div>
                                        <My_Input
                                            height='38px'
                                            required={false}
                                            id={"brand"}
                                            placeHolder={"Brand"}
                                            value={deviceData.brand}
                                            onChange={(e) => _handleTextChange(e, 'brand')}
                                        />

                                        {validateError?.brand && <div className="mb-1" style={{  color: '#E00000', fontSize: '13px'  }}>{validateError.brand}</div> }

                                    </div>

                                    <div className="pb-3">
                                    
                                        <div className="mb-1"><span style={{ color: '#ffc107' }}>*</span> {t("Vehicle Plate Number")}</div>
                                        <My_Input
                                            height='38px'
                                            required={false}
                                            text="Plate Number"
                                            id={"plateNumber"}
                                            placeHolder={"Plate Number"}
                                            value={deviceData.vehicle_number_plate}
                                            onChange={(e) => _handleTextChange(e, 'vehicle_number_plate')}
                                        />
                                        {validateError?.vehicle_number_plate && <div className="mb-1" style={{ color: '#E00000', fontSize: '13px' }}>{validateError.vehicle_number_plate}</div> }
                                    </div>

                                    {deviceData.fuel_type !== 'Battery' ? null :

                                        <div className="pb-3">
                                            
                                            <div className="mb-1"><span style={{ color: '#ffc107' }}>*</span> {t("Battery Type")}</div>
                                            <My_Input
                                                height='38px' 
                                                required={false}
                                                id={"battery_type"}
                                                placeHolder={"Battery Type"}
                                                value={deviceData.battery_type}
                                                onChange={(e) => _handleTextChange(e, 'battery_type')}
                                            />
                                            {validateError?.battery_type && <div className="mb-1" style={{ color: '#ffc107' }}>{validateError.battery_type}</div> }
                                        </div>}
                                    {/* <div className="pb-3">
                                        <div className="mb-1">Cargo Type</div>
                                        <My_Input
                                            height='38px'
                                            required={true}
                                            id={"vehicleWeight"}
                                            placeHolder={"Cargo Type"}
                                            value={deviceData.cargo_type}
                                            onChange={(e) => _handleTextChange(e, 'cargo_type')}
                                        />
                                    </div> */}

                                    <div className="pb-3">
                                    
                                        <div className="mb-1"><span style={{ color: '#ffc107' }}>*</span> {t("Baseline Emission Factor")} (gCO₂e/km)
                                            <i className="fas fa-info-circle" title="For the first [year] of data collection, the baseline emission factor would be based on the OEM’s technical specification of the vehicle, expressed in gCO2e/km." style={{
                                                cursor: "pointer",
                                            }} />
                                        </div>
                                        <My_Input
                                            type='number'
                                            height='38px'
                                            required={false}
                                            id={"oem_baseline_emission"}
                                            placeHolder={"Baseline Emission Factor (kgCO₂/km)"}
                                            value={deviceData.oem_baseline_emission}
                                            onChange={(e) => _handleTextChange(e, 'oem_baseline_emission')}
                                        />
                                        {validateError?.oem_baseline_emission && <div className="mb-1" style={{  color: '#E00000', fontSize: '13px'}}>{validateError.oem_baseline_emission}</div> }
                                    </div>

                                    <div className="pb-3">
                                   
                                        <div className="mb-1">{t("Vehicle Height")} (mm)</div>
                                        <My_Input
                                            type='number'
                                            height='38px'
                                            required={false}
                                            id={"vehicleHeight"}
                                            placeHolder={"Vehicle Height (mm)"}
                                            value={deviceData.height}
                                            onChange={(e) => _handleTextChange(e, 'height')}
                                        />
                                    </div>

                                    <div className="pb-3">
                                    
                                        <div className="mb-1">{t("Vehicle Weight")} (tonnes)</div>
                                        <My_Input
                                            type='number'
                                            height='38px'
                                            required={false}
                                            id={"gross_weight"}
                                            placeHolder={"Gross Weight (tonnes)"}
                                            value={deviceData.gross_weight}
                                            onChange={(e) => _handleTextChange(e, 'gross_weight')}
                                        />
                                    </div>



                                </div>

                                <div className="col-4 col-sm-12 col-lg-4 col-md-4 col-xl-4 col-xs-4">

                                    <div className="pb-3">
                                    <div className="mb-1"><span style={{ color: '#ffc107' }}>*</span> {t("Fuel Type")}</div>
                                        <Selector value={
                                            vehicleTypeOption.filter((v) =>
                                                (v.value === deviceData.fuel_type)
                                            )
                                        } _handleSelect={(e) => _handleTextChange(e, 'primary_fuel_type')} menuListColor={Colors.Dark[0]} options={vehicleTypeOption} />
                                        {validateError?.fuel_type && <div className="mb-1" style={{ color: '#E00000', fontSize: '13px'  }}>{validateError.fuel_type}</div> }
                                    </div>

                                    <div className="pb-3">
                                    
                                        <div className="mb-1"><span style={{ color: '#ffc107' }}>*</span> {t("Model")}</div>
                                        <My_Input
                                            height='38px'
                                            required={false}
                                            id={"model"}
                                            placeHolder={"Model"}
                                            value={deviceData.model}
                                            onChange={(e) => _handleTextChange(e, 'model')}
                                        />
                                        {validateError?.model && <div className="mb-1" style={{ color: '#E00000', fontSize: '13px'  }}>{validateError.model}</div> }
                                    </div>

                                    <div className="pb-3">
                                    
                                        <div className="mb-1"><span style={{ color: '#ffc107' }}>*</span> {t("VIN Number")}</div>
                                        <My_Input
                                            height='38px'
                                            required={false}
                                            id={"vin_number"}
                                            placeHolder={"VIN Number"}
                                            value={deviceData.vin_number}
                                            onChange={(e) => _handleTextChange(e, 'vin_number')}
                                        />
                                        {validateError?.vin_number && <div className="mb-1" style={{  color: '#E00000', fontSize: '13px'  }}>{validateError.vin_number}</div> }
                                    </div>

                                    {/* <div className="pb-3">
                                    
                                        <div className="mb-1">Color</div>
                                        <My_Input
                                            height='38px'
                                            required={false}
                                            id={"color"}
                                            placeHolder={"Color"}
                                            value={deviceData.color}
                                            onChange={(e) => _handleTextChange(e, 'color')}
                                        />
                                    </div> */}

                                    {deviceData.primary_fuel_type !== 'Battery' ? <div className="pb-3">  
                                        <div className="mb-1">{t("Fuel Tank Capacity")} (Litres)</div>
                                        
                                        <My_Input
                                            type='number'
                                            height='38px'
                                            required={false}
                                            id={"fuelTankCapacity"}
                                            placeHolder={"Fuel Tank Capacity (Litres)"}
                                            value={deviceData.fuel_tank_capacity}
                                            onChange={(e) => _handleTextChange(e, 'fuel_tank_capacity')}
                                        />
                                    </div> :

                                        <><div className="pb-3">
                                            <div className="mb-1">{t("Battery Capacity")} (kWh)</div>
                                            <My_Input
                                                // type='number'
                                                height='38px'
                                                required={false}
                                                id={"batteryCapacity"}
                                                placeHolder={"Battery Capacity (kWh)"}
                                                value={deviceData.battery_capacity}
                                                onChange={(e) => _handleTextChange(e, 'battery_capacity')}
                                            />
                                            
                                        </div>

                                            <div className="mb-1">{t("Secondary Fuel Type")}</div>
                                            <My_Input
                                                height='38px'
                                                required={false}
                                                id={"secondaryFuelType"}
                                                placeHolder={"Secondary Fuel Type"}
                                                value={deviceData.secondary_fuel_type}
                                                onChange={(e) => _handleTextChange(e, 'secondary_fuel_type')}
                                            />
                                        </>
                                    }

                                </div>

                                <div className="text-end">
                                    <span style={{ cursor: 'pointer' }}>
                                        <My_Button
                                            disabled={role === "viewer" ? disableAddVehicle : "" }
                                            type="submit"
                                            customColor="green"
                                            text={editMode ? t("Update") : t("Add")}
                                            // data-bs-dismiss="modal"
                                            style={{ width: "30%" }}
                                           
                                        />
                                    </span>

                                </div> 
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }));

const vehicleTypeOption = [
    { value: 'Battery', label: 'Battery' },
    { value: 'Diesel', label: 'Diesel' },
    { value: 'Petrol', label: 'Petrol' },
]

const assetTypeOption = [
    { value: 'Vehicle', label: 'Vehicle' },
    { value: 'Equipment', label: 'Equipment' }
]

const UploadImage = inject("store")(
    observer((props) => {
        const {t} = useTranslation("asset_profile_management")
        const theme = props.theme;
        const { _handleTextChange, deviceData, deviceImage,_handleUploadImage } = props.store.assetProfile;

        console.log("deviceImage", deviceImage)
        
        return (
            <div
                className="d-flex flex-column justify-content-center align-items-center"
                style={{ background: "rgb(0,0,0,0.2)" }}
            >
                <label
                    style={{
                        width: "100%",
                        borderRadius: 5,
                        cursor: "pointer",
                    }}
                    htmlFor="image-upload"
                >
                    <input
                        type="file"
                        style={{ display: "none" }}
                        id="image-upload"
                        accept="image/x-png,image/gif,image/jpeg"
                        // value={deviceData}
                        onChange={(e) => _handleUploadImage(e)}
                    />
                    {!deviceImage ? (
                        <span className="d-flex flex-column justify-content-center align-items-center h-100 p-0 m-3">
                            <i
                                style={{ fontSize: 30 }}
                                className="bi bi-cloud-arrow-up-fill p-5"
                            ></i>
                            <div>{t("Add an Image")}</div>
                        </span>
                    ) : (
                        <span className="d-flex justify-content-center m-3">
                            <img src={deviceImage} height={'84px'} />
                        </span>
                    )}
                </label>
            </div>
        );
    }));
