import dashboard from "./dashboard";
import login from "./login";
import tracking2 from './tracking2';
import landing from "./landing";
import assetProfile from "./assetProfile";
import common from "./common";
import adminPanel from "./adminPanel";
import manualEntry from "./manualEntry";
import driver from "./driver";
import register from "./register";
import dataEntry from "./dataEntry";
import trackAndTrace from "./trackAndTrace";
import liteEntry from "./liteEntry";
import dataImport from "./dataImport";
import trackingVTwo from "./trackingVTwo";

class Store {
  dashboard = null;
  login = null;
  tracking = null;
  landing = null;
  assetProfile = null;
  common = null;
  adminPanel = null;
  manualEntry = null;
  register = null;
  dataEntry = null;
  liteEntry = null;
  dataImport = null;
  trackingVTwo = null;
  // tracking2 = null;
  
  constructor(
    dashboard,
    login,
    tracking,
    landing,
    assetProfile,
    common,
    adminPanel,
    manualEntry,
    driver,
    register,
    dataEntry,
    trackAndTrace,
    liteEntry,
    dataImport,
    // trackingVTwo,
    tracking2,
  ) {
    this.dashboard = dashboard;
    this.login = login;
    this.tracking = tracking;
    this.landing = landing;
    this.assetProfile = assetProfile;
    this.common = common;
    this.adminPanel = adminPanel;
    this.manualEntry = manualEntry
    this.driver = driver;
    this.register = register;
    this.dataEntry = dataEntry;
    this.trackAndTrace = trackAndTrace;
    this.liteEntry = liteEntry;
    this.dataImport = dataImport;
    this.trackingVTwo = trackingVTwo;
    // this.tracking2 = tracking2
  }
}

export default new Store(
  dashboard,
  login,
  tracking2,
  landing,
  assetProfile,
  common,
  adminPanel,
  manualEntry,
  driver,
  register,
  dataEntry,
  trackAndTrace,
  liteEntry,
  dataImport,
  trackingVTwo
  // tracking2
);
