import plusIcon from '../../Assets/Images/icons/plusIcon.png'
import ascentLogo from '../../Assets/Images/icons/ascent-logo.png'
import { Colors } from '../../Assets/Config/color.config'
import Store from '../../Store'
import { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import e from 'cors'
import { toJS } from 'mobx'
import { CreateNewUser } from './CreateNewUser'
// import { UserList } from './UserList'
import { UserListTable } from './UserListTable'
import { Selector } from '../../Tools'


export const SecondStep = inject("store")(
    observer((props) => {
        const { theme } =props

        const { role, companyId,level } = props.store.common
        const { userList, fetchUserList, companyList, fetchCompany } = props.store.landing

        console.log("Company level", level);

        useEffect(() => {
            fetchUserList(companyId)
            fetchCompany(role, companyId)
        }, [role, companyId])

        const CompanyOptions = companyList?.map((v) => ({
            value: v?.id,
            label: v?.name,
        }));

        CompanyOptions?.unshift({ value: 'All', label: 'All' })

        const [comId, setComId] = useState('All')
        const tempData = [];
        
        const d = userList.map(v => (
            (v.companyId === comId) &&
            tempData.push(v)
        ))
        const filteredData = tempData.length === 0 ? userList : tempData

        return (

        <div className="row">
            <CreateNewUser  companyList={companyList} CompanyOptions={CompanyOptions} />
            <div className='col-6' style={{ minHeight: 480 }}>
                <div className='d-flex justify-content-center align-items-center' style={{ paddingBottom: '20px'}}>
                    <div className='w-50'>Filter</div>
                    <div className='w-50'>
                        <Selector _handleSelect={(e) => setComId(e.value)} value={CompanyOptions?.find(v => v.value === comId)} menuListColor={Colors.darkBlue} options={CompanyOptions} />
                    </div>
                </div>
                <UserListTable theme={theme} filteredData={filteredData} />
            </div>
            {/* <UserList /> */}
        </div>

    )
}))