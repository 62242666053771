import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Suspense } from "react";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "mobx-react";
import store from "./Store";
import { CookiesProvider } from "react-cookie";
import './i18n';

// ReactDOM.render(
//   <React.StrictMode>
//     <CookiesProvider>
//       <BrowserRouter>
//         <Provider store={store}>
//           <App />
//         </Provider>
//       </BrowserRouter>
//     </CookiesProvider>
//   </React.StrictMode>,
//   document.getElementById("root")
// );
const cdnList = [
  {
    tag: "link",
    href: "https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css",
    rel: "stylesheet",
    integrity: "sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC",
    crossOrigin: "anonymous",
    id: 'co2-link-1'

  }, {
    tag: "link",
    rel: "stylesheet",
    href: "https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css",
    id: 'co2-link-2'
  }, {
    tag: "link",
    rel: "stylesheet",
    href: "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css",
    integrity: "sha512-1ycn6IcaQQ40/MKBW2W4Rhis/DbILU74C1vSrLJxCq57o941Ym01SwNsOMqvEBFlcgUa6xLiPY/NS5R+E6ztJQ=",
    crossOrigin: "anonymous",
    referrerpolicy: "no-referrer",
    id: 'co2-link-3'
  },

  {
    tag: "script",
    src: "https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js",
    integrity: "sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM",
    crossOrigin: "anonymous"
  },
  {
    tag: "script",
    src: "https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js",
    integrity: "sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p",
    crossOrigin: "anonymous"
  },
  {
    tag: "script",
    src: "https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.min.js",
    integrity: "sha384-cVKIPhGWiC2Al4u+LWgxfKTRIcfu0JTxR+EQDz/bgldoEyl4H0zUF0QKbrJ0EcQF",
    crossOrigin: "anonymous"
  },
  {
    tag: "script",
    src: "https://unpkg.com/react/umd/react.production.min.js",
    crossOrigin: ""
  },

  {
    tag: "script",
    src: "https://unpkg.com/react-dom/umd/react-dom.production.min.js",
    crossOrigin: ""

  },

  {
    tag: "script",
    src: "https://unpkg.com/react-bootstrap@next/dist/react-bootstrap.min.js",
    crossOrigin: ""
  }

]

window.renderCo2 = (containerId, history) => {

  cdnList.forEach((cdn,idx)=>{
    window.addCustomDom({...cdn,id:`co2-link-${idx}`})
  })

  // var obj = {name: 'co2-link'};
  // window.addCustomDom.bind(obj);
  // cdnList.forEach(window.addCustomDom);

  // window.addCustomDom({

  // })
  ReactDOM.render(
    <React.StrictMode>
      <CookiesProvider>
        <BrowserRouter basename="co2">
          <Provider store={store}>
            <Suspense fallback={<div>Loading... </div>}>
              <App />
            </Suspense>
          </Provider>
        </BrowserRouter>
      </CookiesProvider>
    </React.StrictMode>,
    document.getElementById(containerId),
  );
  // reportWebVitals.unregister();
};

window.unmountCo2 = containerId => {
  cdnList.forEach((cdn,idx)=>{
    window.removeCustomDom(`co2-link-${idx}`);
  })
  ReactDOM.unmountComponentAtNode(document.getElementById(containerId));
};

if (!document.getElementById('Co2-container')) {
  ReactDOM.render(
    <React.StrictMode>
      <CookiesProvider>
        <BrowserRouter basename="co2">
          <Provider store={store}>
            <Suspense fallback={<div>Loading... </div>}>
              <App />
            </Suspense>
          </Provider>
        </BrowserRouter>
      </CookiesProvider>
    </React.StrictMode>, document.getElementById('root'));
  // serviceWorker.unregister();
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
