import { inject, observer } from "mobx-react";

export const Navigator = inject("store")(
    observer((props) => {
        const { setStep, step } = props.store.landing;
        const { theme } = props.store.login;
        const tabStyle = { background: theme==='dark'?'#000831f7':'#04c600', borderRadius: 50, cursor: 'pointer', border: '1px solid rgb(255,255,255,0.05)', transition: 'all 0.1s' }
        return (
            <div className="text-white" style={{ position: 'absolute', bottom: 24, left: '45%', width: 200 }}>
                <div className="d-flex justify-content-between align-items-end gap-3">
                    <span onClick={() => setStep(1)} className="d-flex justify-content-center align-items-center"
                        style={{ ...tabStyle, width: step === 1 ? 50 : 30, height: step === 1 ? 50 : 30, boxShadow: step === 1 && `-7px -7px 7px ${theme === 'dark' ? '#556feb17' : '#04c6001c'} `, opacity: step === 1 ? 1 : step === 2 ? 0.9 : 0.8 }}>1
                    </span>
                    <span onClick={() => setStep(2)} className="d-flex justify-content-center align-items-center"
                        style={{ ...tabStyle, width: step === 2 ? 50 : 40, height: step === 2 ? 50 : 40, boxShadow: step === 2 && `0 -7px 7px ${theme === 'dark' ? '#556feb17' : '#04c6001c'}`, opacity: step === 2 ? 1 : 0.8 }}>2
                    </span>
                    <span onClick={() => setStep(3)} className="d-flex justify-content-center align-items-center"
                        style={{ ...tabStyle, width: step === 3 ? 50 : 30, height: step === 3 ? 50 : 30, boxShadow: step === 3 && `7px -7px 7px ${theme === 'dark' ? '#556feb17' : '#04c6001c'}`, opacity: step === 3 ? 1 : step === 2 ? 0.9 : 0.8 }}>3
                    </span>
                </div>
            </div>
        )
    }))