import { useTranslation } from "react-i18next";
import { Colors } from "../../Assets/Config/color.config";
import { My_Input } from "../../Tools";
import {Selector} from "../../Tools";
export const CreateUserModal = (props) => {
  const {t} = useTranslation("admin-panel")
  const {_handleTextChange, userData} =props
  const option = props.companyList.map((d) => {
    return {
      value: d.id,
      label: d.name,
    };
  });
  return (
    <div
      className={`modal fade`}
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md rounded">
        <div className="modal-content" style={props.cardStyle}>
          <div className="modal-header" style={{ border: "none" }}>
            <h3>{t("Create User")}</h3>
            <button
              type="button"
              className={`btn-close ${props.theme === "dark" ? "btn-close-white" : null
                } `}
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="m-2" style={props.rowStyle}>
              <div className="row align-items-center">
                <span className="col-sm-4 col-12">{t("User Name")}</span>
                <div className="col-12 col-sm-8" style={{ minWidth: 246.22 }}>
                  <My_Input
                    onChange={(e) =>
                      _handleTextChange({ username: e.target.value })
                    }
                    value={userData.username}
                    id="userName"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div className="m-2" style={props.rowStyle}>
              <div className="row align-items-center">
                <span className="col-sm-4 col-12">{t("Email")}</span>
                <div className="col-12 col-sm-8" style={{ minWidth: 246.22 }}>
                  <My_Input
                    onChange={(e) =>
                      _handleTextChange({ email: e.target.value })
                    }
                    value={userData.email}
                    id="email"
                    type="email"
                  />
                </div>
              </div>
            </div>
            <div className="m-2" style={props.rowStyle}>
              <div className="row align-items-center">
                <span className="col-sm-4 col-12">{t("Password")}</span>
                <div className="col-12 col-sm-8" style={{ minWidth: 246.22 }}>
                  <My_Input
                    onChange={(e) =>
                      props._handleTextChange({ password: e.target.value })
                    }
                    value={userData.password}
                    id="password"
                    type="password"
                  />
                </div>
              </div>
            </div>
            <div className="m-2 pt-2" style={props.rowStyle}>
              <div className="row align-items-center">
                <span className="col-sm-4 col-12">{t("Company")}</span>
                <div className="col-12 col-sm-8" style={{ minWidth: 246.22 }}>
                  <Selector
                    options={option}
                    menuListColor="black"
                    _handleSelect={(e) =>
                      props._handleTextChange({ companyId: e.value })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="m-2 pt-2" style={props.rowStyle}>
              <div className="row align-items-center">
                <span className="col-sm-4 col-12">{t("Role")}</span>
                <div className="col-12 col-sm-8" style={{ minWidth: 246.22 }}>
                  <label className="col-6" style={{ cursor: "pointer" }}>
                    <input
                      onChange={(e) =>
                        props._handleTextChange({ role: "user" })
                      }
                      type="radio"
                      name="role"
                      checked={userData.role==='user'}
                    />
                    <span className="ps-2">{t("User")}</span>
                  </label>
                  <label className="col-6" style={{ cursor: "pointer" }}>
                    <input
                      onChange={(e) =>
                        props._handleTextChange({ role: "admin" })
                      }
                      type="radio"
                      name="role"
                      checked={userData.role==='admin'}
                    />
                    <span className="ps-2">{t("Admin")}</span>
                  </label>
                </div>
              </div>
            </div>

          </div>
          <div className="modal-footer">
            <button
              id="create_user_btn"
              onClick={props._handleCreateUser}
              type="button"
              className="btn text-light mt-2"
              style={{
                background: Colors.lightBlue,
                // fontSize: 12,
                // padding: 5,
                width: "100%",
              }}
            // data-bs-dismiss="modal"
            >
              {t("Create User")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
