import $, { data } from "jquery"; // Import jQuery without data alias
import { inject, observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import "datatables.net-dt";
import "datatables.net-dt/css/jquery.dataTables.css";
import { baseIMGURL, baseURL } from "../../network/URL";
import { FuelReceiptView } from "./FuelReceiptView";
import { toJS } from "mobx";
import { action } from "mobx";
import { runInAction } from "mobx";
import { ManualEntryHistoryViewModal } from "./ManualEntryHistoryViewModal";

export const ManualEntryHistoryViewJQ = inject("store")(
  observer((props) => {
    const { theme, fuelRecordFilePath } = props;

    const {
      currentTableData,
      manualsRowData,
      manuals,
      _handleUpdateActionStatus,
      actionStatus,
      consumptionDataPerUnit
    } = props.store.dataEntry;

    // const [tableData, setCurrentTableData] = useState(currentTableData);
    const [tableData, setCurrentTableData] = useState(consumptionDataPerUnit);

    let filteredManuals = tableData?.vehicles
      ?.flatMap((v) => v?.manual_data)
      ?.filter((manual) => manual && manual.id === manualsRowData.id);
    // console.log("filteredManuals ===", toJS(filteredManuals))

    console.log("filteredManuals --->", toJS(filteredManuals))

    let manual_rows = (
      filteredManuals?.flatMap((manual) => manual.manuals) || []
    )
      .filter((data) => data && data.id !== undefined && data.id !== 0)
      .map((data) => ({
        id: data.id || "N/A",
        vUid: data.vUid || "N/A",
        amount: data.amount || "N/A",
        consumed: data.consumed !== "" ? data.consumed : "N/A",
        createdAt: data.createdAt || "N/A",
        fuel_quantity: data.fuel_quantity || "N/A",
        fuel_recordFileName: data.fuel_recordFileName || "N/A",
        fuel_recordFilePath: data.fuel_recordFilePath || "N/A",
        fuel_status: data.fuel_status || "N/A",
        fuel_type: data.fuel_type || "N/A",
        image_text: data.image_text || "N/A",
        invoice_no: data.invoice_no || "N/A",
        price_per_liter: data.price_per_liter || "N/A",
        type_status: data.type_status || "N/A",
        created_by: data.created_by || "N/A",
        status: data.status || "N/A",
      }));

    console.log("manual_ rows --->", toJS(manual_rows))

    // console.log("manual_rows =======>", toJS(manual_rows))

    const [manualRowsData, setManualRowsData] = useState(manual_rows);

    const modalRef = useRef(null);
    const [receiptData, setReceiptData] = useState([])
    const openFuelReceiptModal = (data) => {
      setReceiptData(data);
      // console.log("data for receiptFuelView ======>",toJS(data))
      const modal = new window.bootstrap.Modal(
        document.getElementById("fuelReceiptView")
      );
      modal.show();
    };

    const tableRef = useRef(null);
    let table = null;

    const updateStatus = (rowId, updatedStatus) => {
      const updatedManualRowsData = manualRowsData.map((data) => {
        if (data.id === rowId) {
          return { ...data, status: updatedStatus };
        }
        return data;
      });

      setManualRowsData(updatedManualRowsData);

      const updateObj = { ...tableData };
      updateObj?.vehicles?.forEach((v) => {
        v?.manual_data?.manuals?.forEach((m) => {
          m.status = m.id === rowId ? updatedStatus : m.status;
        });
      });
      setCurrentTableData(updateObj);
    };

    useEffect(() => {
      setManualRowsData(manual_rows);
    }, [manualsRowData]);


    console.log("manualRowsData=====>", toJS(manualRowsData))

    useEffect(() => {
      const styleElement = document.createElement("style");
      styleElement.innerHTML =
        theme === "dark"
          ? `


.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: black !important;
  border: 1px solid #e5e5e5!important;  
  background-color: #e5e5e5 !important;
  /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #e5e5e5), color-stop(100%, #e5e5e5))!important; */
  background: -webkit-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
  background: -moz-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
  background: -ms-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%) !important;
  background: -o-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
  background: linear-gradient(to bottom, #e5e5e5 0%, #e5e5e5 100%)!important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: black !important;
  border: 1px solid #C6C6C6!important;
  
  background-color: #C6C6C6 !important;
  /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fffcfc), color-stop(100%, #969292))!important; */
  background: -webkit-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
  background: -moz-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
  background: -ms-linear-gradient(top, #fffcfc 0%, #969292 100%) !important;
  background: -o-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
  background: linear-gradient(to bottom, #fffcfc 0%, #969292 100%)!important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: black !important;
  border: 1px solid #C6C6C6!important;
  
  background-color: #C6C6C6 !important;
  /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fffcfc), color-stop(100%, #969292))!important; */
  background: -webkit-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
  background: -moz-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
  background: -ms-linear-gradient(top, #fffcfc 0%, #969292 100%) !important;
  background: -o-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
  background: linear-gradient(to bottom, #fffcfc 0%, #969292 100%)!important;
}


`
          : `
  .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
      color: black !important;
      border: 1px solid #e5e5e5!important;
      
      background-color: #e5e5e5 !important;
      /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #e5e5e5), color-stop(100%, #e5e5e5))!important; */
      background: -webkit-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
      background: -moz-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
      background: -ms-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%) !important;
      background: -o-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
      background: linear-gradient(to bottom, #e5e5e5 0%, #e5e5e5 100%)!important;
    }

    .dataTables_wrapper .dataTables_paginate .paginate_button.current {
      color: black !important;
      border: 1px solid #C6C6C6!important;
      
      background-color: #C6C6C6 !important;
      /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fffcfc), color-stop(100%, #969292))!important; */
      background: -webkit-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
      background: -moz-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
      background: -ms-linear-gradient(top, #fffcfc 0%, #969292 100%) !important;
      background: -o-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
      background: linear-gradient(to bottom, #fffcfc 0%, #969292 100%)!important;
    }

`;
      document.head.appendChild(styleElement);

      function updateOrderAndDisplayStart(columnIndex, direction) {
        orderColumn = columnIndex;
        orderDirection = direction;

        table.order([[orderColumn, orderDirection]]).draw();
      }

      const storedOrderColumn = localStorage.getItem("orderColumn");
      const storedOrderDirection = localStorage.getItem("orderDirection");

      let orderColumn = storedOrderColumn ? parseInt(storedOrderColumn) : 0;
      let orderDirection = storedOrderDirection || "asc";

      const dataArray = [];
      for (var i = 0; i < manualRowsData.length; i++) {
        var obj = {};
      }

      const tableElement = $(tableRef.current);

      table = tableElement.DataTable({
        retrieve: true,
        scrollX: true,
        retrieve: true,
        scrollX: true,
        order: [[1, "asc"]],
        // searching: false,

        lengthMenu: [3, 5],
        data: manualRowsData || [],
        columns: [
          // {
          //   title: "Fuel Receipt",
          //   data: "fuel_recordFilePath",
          //   render: function (data, type, row) {
          //     const imgURL = baseIMGURL + data;
          //     return `
          //         <img
          //           src="${imgURL}"
          //           width="50"
          //           height="50"
          //           class="fuel-receipt-image"
          //           data-toggle="modal"
          //           data-target="#fuelReceiptView"
          //           data-rowData=${JSON.stringify(row.id)}
          //           onClick="handleFuelReceiptView(event)"
          //         />`;
          //   },
          // },

          { title: "Fuel Type", data: "fuel_type" },
          { title: "Fuel Quantity", data: "fuel_quantity" },
          // { title: "Invoice No", data: "invoice_no" },
          { title: "Image Text", data: "image_text" },
          { title: "Consumed", data: "consumed" },
          // { title: "Price Per Liter", data: "price_per_liter" },
          // { title: "Amount", data: "amount" },
          { title: "Fuel Status", data: "fuel_status" },
          { title: "Created By", data: "created_by" },
          {
            title: "Status",
            data: "status",
            render: function (data, type, row) {
              return data === "0"
                ? "Pending"
                : data === "1"
                  ? "Validated"
                  : data === "2"
                    ? "Invalidated"
                    : data;
            },
          },
          {
            title: "Action",
            render: function (data, type, row, meta) {
              //   console.log("row ====>", row);
              const approvalStatus = row.status;

              let buttonHtml = "";

              if (approvalStatus === "0") {
                buttonHtml = `
                          <button type="button" class="approve btn btn-sm btn-success me-1"
                              data-rowData=${JSON.stringify(row.id)}
                              onclick="handleApproveVehicle(event)">
                              Validate
                          </button>
                          <button type="button" class="decline btn btn-sm btn-danger"
                              data-rowData=${JSON.stringify(row.id)}
                              onclick="handleDeclineVehicle(event)">
                              Invalidate
                          </button>
                         
                          <button type="button" class="approve btn btn-sm btn-success"
                            data-rowData=${JSON.stringify(row.id)}
                            onclick="handleViewDetail(event)">
                            View
                          </button>`;
              } else if (approvalStatus === "1") {
                // <i class="fa fa-check pe-1" style="color:green"></i>
                buttonHtml = `
                          <button type="button" class="decline btn btn-sm btn-danger"
                              data-rowData=${JSON.stringify(row.id)}
                              onclick="handleDeclineVehicle(event)">
                              Invalidate
                          </button>
                          <button type="button" class="approve btn btn-sm btn-success"
                              data-rowData=${JSON.stringify(row.id)}
                              onclick="handleViewDetail(event)">
                              View
                          </button>`;
              } else if (approvalStatus === "2") {
                // <i class="fas fa-times pe-1" style="color:red"></i>
                buttonHtml = `
                          <button type="button" class="approve btn btn-sm btn-success"
                              data-rowData=${JSON.stringify(row.id)}
                              onclick="handleApproveVehicle(event)">
                              Validate
                          </button>
                          <button type="button" class="approve btn btn-sm btn-success"
                              data-rowData=${JSON.stringify(row.id)}
                              onclick="handleViewDetail(event)">
                              View
                          </button>
                          `;
              } else {
                buttonHtml = `
                          <button type="button" class="approve btn btn-sm btn-success"
                              data-rowData=${JSON.stringify(row.id)}
                              onclick="handleViewDetail(event)">
                              View
                          </button>
                          `;
              }

              return `
                      <div class="d-flex justify-content-between align-items-center">
                          ${buttonHtml}
                      </div>
                  `;
            },
          },
        ],
        paging: true,

        drawCallback: function (settings) {
          // console.log($('#manualEntryHistoryView').DataTable().page.info());
          // console.log(settings.oSavedState);
        },
      });

      table.column(0).nodes().to$().css("width", "8%");
      table.column(1).nodes().to$().css("width", "8%");
      table.column(2).nodes().to$().css("width", "8%");
      table.column(3).nodes().to$().css("width", "8%");
      table.column(4).nodes().to$().css("width", "10%");
      table.column(5).nodes().to$().css("width", "8%");
      table.column(6).nodes().to$().css("width", "8%");
      table.column(7).nodes().to$().css("width", "8%");
      table.column(8).nodes().to$().css("width", "8%");
      table.column(9).nodes().to$().css("width", "8%");
      table.column(10).nodes().to$().css("width", "8%");
      table.column(11).nodes().to$().css("width", "15%");
      // table.search('').draw();

      table.cells().every(function () {
        $(this.node()).css("border-color", "");
      });

      const searchInput = $(".dataTables_filter input");
      searchInput.css("color", theme === "light" ? "black" : "white");

      const searchlabel = $(".dataTables_filter label");
      searchlabel.css("color", theme === "light" ? "black" : "white");

      const entriesSelect = $(".dataTables_length select");
      entriesSelect.css(
        "background-color",
        theme === "light" ? "#fff" : "#202b60"
      );
      entriesSelect.css("color", theme === "light" ? "black" : "white");

      const entriesLabel = $(".dataTables_length label");
      entriesLabel.css("color", theme === "light" ? "black" : "white");

      const tableInfo = $(".dataTables_info");
      tableInfo.css("color", theme === "light" ? "#000" : "#fff");

      const paginate = $(".dataTables_paginate paginate_button");
      paginate.css("color", "#000 !important");

      $("#manualEntryHistoryView").on("click", "tbody tr", function () {
        const data = table.row(this).data();
      });

      $("#manualEntryHistoryView").on("page.dt", function () {
        localStorage.setItem("currentPage", table.page.info().page);
      });

      const storedCurrentPage = localStorage.getItem("currentPage");
      // const storedSearch = localStorage.getItem("search");

      const currentPage = storedCurrentPage ? parseInt(storedCurrentPage) : 0;
      table.page(currentPage).draw(false);

      // if (storedSearch) {
      //   table.search(storedSearch).draw();
      // }

      table.on("page.dt", function () {
        localStorage.setItem("currentPage", table.page.info().page);
      });


      table.cells().every(function () {
        $(this.node()).css(
          "border-color",
          theme === "dark" ? "rgb(211, 211, 211, 0.3)" : ""
        );
      });

      table.on("search.dt", function () {
        const search = table.search();
        localStorage.setItem("search", search);
      });

      // $("#manualEntryHistoryView").on('click', '.fuel-receipt-image', function () {
      //   const imgUrl = $(this).data('img-url');
      //   openFuelReceiptModal(imgUrl);
      // });

      // window.addEventListener('click', (event) => {
      //   const modal = document.getElementById('fuelReceiptView');
      //   if (event.target === modal) {

      //   }
      // });

      // $('#openModalButton').click(() => {
      //   const imgUrl = 'path_to_image.jpg';
      //   openFuelReceiptModal(imgUrl);
      // });

      // $("#manualEntryHistoryView").on('click', '.fuel-receipt-image', function () {
      //   const imgUrl = $(this).data('img-url');
      //   openFuelReceiptModal(imgUrl);
      // });

      const reloadTableData = (actionStatus) => {
        if (table) {
          table.clear();
          if (actionStatus) {
            table.rows.add(actionStatus).draw();
          }
        }
      };
      //     const index = table.row($(this).parents('tr')).index()
      //     const rowId = event.target.dataset.rowdata;

      //     const rowdata = manual_rows.find(v => v.id === rowId)
      //     _handleUpdateActionStatus(rowdata?.id, 1);

      //      updateDataAsync(rowdata);

      //     // reloadTableData(manual_rows);
      // };

      // window.handleDeclineVehicle = function (event) {
      //     event.preventDefault();

      //     const index = table.row($(this).parents('tr')).index()
      //     const rowId = event.target.dataset.rowdata;
      //     const rowdata = manual_rows.find(v => v.id === rowId)
      //     _handleUpdateActionStatus(rowdata?.id, 2);

      //      updateDataAsync(rowdata);

      //     // reloadTableData(manual_rows);
      // };

      // ...

      window.handleApproveVehicle = async function (event) {
        const rowId = event.target.dataset.rowdata;
        const updatedStatus = "1";

        try {
          await _handleUpdateActionStatus(rowId, updatedStatus);
          updateStatus(rowId, updatedStatus);
        } catch (error) {
          console.error("Error updating status:", error);
        }
      };

      window.handleViewDetail = async function (event) {
        const rowId = event.target.dataset.rowdata;
        let myModal = new window.bootstrap.Modal(
          document.getElementById("receiptviewmodal")
        );
        myModal.show();
      }

      window.handleDeclineVehicle = async function (event) {
        const rowId = event.target.dataset.rowdata;
        const updatedStatus = "2";

        try {
          await _handleUpdateActionStatus(rowId, updatedStatus);
          updateStatus(rowId, updatedStatus);
        } catch (error) {
          console.error("Error updating status:", error);
        }
      };

      window.handleFuelReceiptView = async function (event) {
        event.preventDefault();
        const rowId = event.currentTarget.dataset.rowdata;
        const data = manualRowsData.find((v) => v.id === rowId);

        const imgURL = $(this).data("img-url");
        openFuelReceiptModal(data);
      };

      window.addEventListener("beforeunload", function () {
        localStorage.removeItem("orderColumn");
        localStorage.removeItem("orderDirection");
        localStorage.removeItem("currentPage");
      });

      window.addEventListener("load", function () {
        localStorage.removeItem("orderColumn");
        localStorage.removeItem("orderDirection");
        localStorage.removeItem("currentPage");
      });

      const storedPage = localStorage.getItem("currentPage");

      if (storedPage !== null) {
        const currentPage = parseInt(storedPage);
        if (!isNaN(currentPage)) {
          table.page(currentPage).draw("page");
        } else {
          table.page(0).draw("page");
        }
      } else {
        table.page(0).draw("page");
      }

      return () => {
        if ($.fn.DataTable.isDataTable("#manualEntryHistoryView")) {
          table.destroy();
        }
      };
    }, [manualRowsData, theme]);

    useEffect(() => {
      if (table) {
        table.clear();
        table.rows.add(manualRowsData).draw();
      }
    }, [manualRowsData]);

    return (
      <div>
        <table
          ref={tableRef}
          id="manualEntryHistoryView"
          className="display cell-border hover stripe"
          style={{ width: "100%" }}
        ></table>
        <FuelReceiptView
          ref={modalRef}
          theme={theme}
          manual_rows={receiptData}
        />
        <ManualEntryHistoryViewModal theme={theme} manualRowsData={manualRowsData} />
      </div>
    );
  })
);
