import React, { useEffect, useRef } from 'react';
import { observer, inject } from 'mobx-react';
import { Colors, invertDark } from '../../Assets/Config/color.config';
import 'bootstrap/dist/css/bootstrap.min.css';
import { baseIMGURL, baseURL } from '../../network/URL';
import { toJS } from 'mobx';

export const FuelReceiptView = inject('store')(
  observer((props) => {
    const { theme, manual_rows } = props;
    console.log("manual_rows =====>",manual_rows)

 
    const image = manual_rows?.fuel_recordFilePath;
    
    const fuelRecordFilePath = baseIMGURL + image;
    // console.log("fuelRecordFilePath =====>",fuelRecordFilePath)

    const modalRef = useRef(null);
   
    return (
      <div>
        <div
          className="modal fade"
          id="fuelReceiptView"
          tabIndex="-1"
          aria-labelledby="fuelReceiptView"
          aria-hidden="true"
          ref={modalRef}
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content d-flex align-items-center" 
                  style={{ background: theme === 'dark' ? 'rgba(23, 30, 72, 0.9)' : 'rgba(255, 255, 255, 0.9)' }}
            >
              <div className="modal-header border-0 d-flex justify-content-between w-100">
                <h5 className="modal-title">
                  Fuel Receipt
                </h5>
                <button
                  type="button"
                  className={`btn-close ${theme === 'dark' && 'btn-close-white'}`}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="closeModal"
                ></button>
              </div>

              <div className="modal-body">
                <div className="d-flex align-items-center justify-content-center">
                  <div>
                    <img
                      id="modalImage" 
                      src={`${fuelRecordFilePath}`}
                      className="img-fluid py-1"
                      style={{ height: '1000px', borderRadius: 5 }}
                      crossOrigin="anonymous"
                      onError={(e) => {
                        // Handle image load error
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  })
);

