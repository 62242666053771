/* eslint-disable jsx-a11y/img-redundant-alt */
import { inject, observer } from "mobx-react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import React, { useState } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Button } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-image-crop/dist/ReactCrop.css';
import { My_Button } from "../../Tools";
import { baseIMGURL } from "../../network/URL";
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


export const ImageViewModel = inject("store")(
    observer((props) => {
        const { theme, newAudit, currentImage, currentType, } = props;
        const { currentTitle, setCroppedImage, uploadFileType, imageIndex } = props.store.dataEntry;
        const [crop, setCrop] = useState({ unit: '%', x: 0, y: 0, width: 0, height: 0 });
        const handleCropComplete = (crop) => {
            setCrop(crop);
        };

        const handleFileSelect = (event) => {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
        };



        const handleCropClick = () => {
            const canvas = document.createElement('canvas');
            const img = document.getElementById("image-view")
            const scaleX = img.naturalWidth / img.width;
            const scaleY = img.naturalHeight / img.height;
            canvas.width = crop.width * scaleX;
            canvas.height = crop.height * scaleY;
            const ctx = canvas.getContext('2d');
            console.log({
                ctx,
                img,
                crop,
                currentImage
            });

            ctx.drawImage(
                img,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width * scaleX,
                crop.height * scaleY
            );
            const croppedImageUrl = canvas.toDataURL('image/jpeg');
            setCroppedImage(croppedImageUrl, currentTitle)
        };

        console.log("currentImage ===>", currentImage)
        console.log("uploadFIleType ====>", uploadFileType)

        return (
            <div
                className="modal  fade"
                id="imageViewDetail"
                tabIndex="-1"
                aria-labelledby="imageViewDetail"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content d-flex align-items-center" style={{ background: Colors[theme].background, color: invertDark(theme) }}>
                        <div className="modal-header border-0 d-flex justify-content-between w-100">
                            <h5 className="modal-title" id="imageViewDetail">
                                {currentTitle}
                            </h5>
                            <button
                                type="button"
                                className={`btn-close ${theme === 'dark' && 'btn-close-white'}`}
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        {/* <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                            <div class="carousel-inner">
                                <div className="modal-body">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div>
                                            <ReactCrop
                                                src={currentImage}
                                                crop={crop}
                                                onChange={setCrop}
                                                onCropComplete={handleCropComplete}
                                            >
                                                {currentTitle === "Odometer Start" || currentTitle === "Odometer End" ?
                                                    <div className="active">
                                                        <img
                                                            id="image-view"
                                                            src={currentImage}
                                                            onChange={handleFileSelect}
                                                            alt="image"
                                                            className="img-fluid py-1"
                                                            style={{ height: '500px', borderRadius: 5 }}
                                                            crossOrigin="anonymous"
                                                            onError={(e) => {
                                                                //setImgUrl(isAds ? `/equipments/default_equipment.png` : `/cars/default_vehicle.png`);
                                                            }}
                                                        />
                                                    </div>
                                                    :
                                                    newAudit.map((v, i) =>
                                                        <div className={`carousel-item ${i === 0 ? `active` : ''}`}>
                                                            {v.uploadFileType !== 'application/pdf' ?
                                                                <img
                                                                    id="image-view"
                                                                    src={v.fileFuel ? v.fileFuel : baseIMGURL + v.fuel_recordFilePath}
                                                                    onChange={handleFileSelect}
                                                                    alt="image"
                                                                    className="img-fluid py-1"
                                                                    style={{ height: '500px', borderRadius: 5 }}
                                                                    crossOrigin="anonymous"
                                                                    onError={(e) => {
                                                                        //setImgUrl(isAds ? `/equipments/default_equipment.png` : `/cars/default_vehicle.png`);
                                                                    }}
                                                                />
                                                                :
                                                                (<>
                                                                    <Document
                                                                        file={v.fileFuel ? v.fileFuel : baseIMGURL + v.fuel_recordFilePath}
                                                                    >
                                                                        <Page pageNumber={1} scale={0.8} />
                                                                    </Document>
                                                                </>
                                                                )}
                                                        </div>
                                                    )
                                                }

                                            </ReactCrop>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {currentTitle === "Odometer Start" || currentTitle === "Odometer End" ?
                                ""
                                :
                                <>
                                    <button className="carousel-control-prev d-flex align-items-center justify-content-center" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev" style={{ position: 'fixed', left: '10%' }}>
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="visually-hidden">Previous</span>
                                    </button>
                                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next" style={{ position: 'fixed', right: '10%' }}>
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="visually-hidden">Next</span>
                                    </button>
                                </>
                            }
                        </div> */}
                        <div className="modal-body">
                            <div className="d-flex align-items-center justify-content-center">
                                <div>
                                    <ReactCrop
                                        src={currentImage}
                                        crop={crop}
                                        onChange={setCrop}
                                        onCropComplete={handleCropComplete}
                                    >
                                        {currentTitle === "Odometer Start" || currentTitle === "Odometer End" ?
                                            <div className="active">
                                                <img
                                                    id="image-view"
                                                    src={currentImage}
                                                    onChange={handleFileSelect}
                                                    alt="image"
                                                    className="img-fluid py-1"
                                                    style={{ height: '500px', borderRadius: 5 }}
                                                    crossOrigin="anonymous"
                                                    onError={(e) => {
                                                        //setImgUrl(isAds ? `/equipments/default_equipment.png` : `/cars/default_vehicle.png`);
                                                    }}
                                                />
                                            </div>
                                            :

                                            uploadFileType !== 'application/pdf' ?
                                                <img
                                                    id="image-view"
                                                    src={newAudit[imageIndex]?.fileFuel ? newAudit[imageIndex]?.fileFuel : baseIMGURL + newAudit[imageIndex]?.fuel_recordFilePath}
                                                    onChange={handleFileSelect}
                                                    alt="image"
                                                    className="img-fluid py-1"
                                                    style={{ height: '500px', borderRadius: 5 }}
                                                    crossOrigin="anonymous"
                                                    onError={(e) => {
                                                        //setImgUrl(isAds ? `/equipments/default_equipment.png` : `/cars/default_vehicle.png`);
                                                    }}
                                                />
                                                :
                                                (<>
                                                    <Document
                                                        file={newAudit[imageIndex].fileFuel ? newAudit[imageIndex].fileFuel : baseIMGURL + newAudit[imageIndex].fuel_recordFilePath}
                                                    >
                                                        <Page pageNumber={1} scale={0.8} />
                                                    </Document>
                                                </>
                                                )}




                                    </ReactCrop>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    })
)