import React, { useEffect } from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import Store from "../../Store";
import { useState } from "react";
import { toJS } from "mobx";
import { useTranslation } from "react-i18next";
import ta from "date-fns/esm/locale/ta";
export const FuelType = (props) => {
  const { t } = useTranslation("dashboard");
  const { outputs, _handleExpand, isExpand, _companyType } = props;
  const { isAds } = Store.common;
  const theme = localStorage.getItem("theme");
  const { totalFuelData, vehicleCount, deviceCount, companyId } =
    Store.dashboard;
  const title = outputs[0].data.name;
  const noOfVehicles = vehicleCount;
  const noOfDevice = deviceCount;
  const [width, setWidth] = useState(window.innerWidth);

  const WindowSize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", WindowSize);
    return () => {
      window.removeEventListener("resize", WindowSize);
    };
  }, []);
  const cardStyle = {
    background: Colors[theme].background,
    boxShadow: "2px 4px 11px rgba(0,0,0,0.25)",
    padding: "10px",
    width: width > 869 ? "390px" : "",
    borderRadius: "15px",
    fontSize: "12px",
    color: Colors.Dark[theme === "dark" ? 4 : 1],
    height: isExpand[title] ? (width > 869 ? "175px" : "173px") : "40px",
    transitionDuration: "0.1s",
  };
  const _handleMouseEnter = (id) => {
    document.getElementById(id).style[
      "boxShadow"
    ] = `2px 4px 11px ${Colors.fuelLight}`;
  };
  const _handleMouseLeave = (id) => {
    document.getElementById(id).style[
      "boxShadow"
    ] = `2px 4px 11px rgba(0,0,0,0.25)`;
  };
  const rotate = isExpand[title] ? "rotate(180deg)" : "rotate(0deg)";

  return (
    <div
      id={title}
      style={cardStyle}
      onMouseEnter={(e) => _handleMouseEnter(title)}
      onMouseLeave={(e) => _handleMouseLeave(title)}
      // className="over-flow"
    >
      <div
        className="row"
        onClick={() => _handleExpand(title)}
        style={{ cursor: "pointer" }}
      >
        <span className="col-6" style={{ fontWeight: 800, color: Colors.fuel }}>
          {outputs && t(title)}
        </span>
        <span
          style={{
            cursor: "pointer",
          }}
          className="row col-5"
        >
          <div
            className="col-6 text-center"
            style={{ color: theme === "dark" ? Colors.medium : Colors.Dark[0] }}
          >
            {isExpand[title] && t("Base Line")}
          </div>
          <div className="col-6 text-center pe-0 ps-4">
            <span
              style={{
                color: theme === "dark" ? Colors.medium : Colors.Dark[0],
              }}
            >
              {isExpand[title] && t("Current")}{" "}
            </span>
          </div>
        </span>
        <span
          style={{ color: Colors.lightBlue }}
          className="col-1 text-end pe-0"
        >
          <i
            className="fas fa-angle-down"
            style={{ transform: rotate, transition: "all 0.2s linear" }}
          />
        </span>
      </div>

      <div
        className="my-1"
        style={{
          background: "linear-gradient(to right, orange, rgba(255,255,255,0)",
          height: "1px",
        }}
      />
      {isExpand[title]
        ? totalFuelData && (
            <FuelDetails
              companyType={_companyType}
              companyId={companyId}
              theme={theme}
              fuelData={
                isAds
                  ? totalFuelData?.consumptionsByFuelType[title]
                  : totalFuelData?.consumptionsByFuelType[title]?.licensePlates
              }
              cardStyle={cardStyle}
              _handleMouseLeave={_handleMouseLeave}
              baseLineEmissionEHr={
                isAds
                  ? totalFuelData?.consumptionsByFuelType[title]
                    ? totalFuelData?.consumptionsByFuelType[title]
                        ?.baselineCo2EmissionEHr
                    : null
                  : totalFuelData?.consumptionsByFuelType[title]
                  ? parseFloat(
                      totalFuelData?.consumptionsByFuelType[title]
                        ?.baselineCo2EmissionEHr
                    ).toFixed(2)
                  : null
              }
              baseLineEmission={
                isAds
                  ? title === "Electricity"
                    ? totalFuelData.baselineCo2Emission
                    : 0
                  : totalFuelData?.consumptionsByFuelType[
                      title
                    ]?.baselineCo2Emission?.toFixed(2)
              }
              title={title}
              emission={
                isAds
                  ? totalFuelData?.consumptionsByFuelType[title]
                    ? totalFuelData?.consumptionsByFuelType[title]
                        ?.totalEmission
                    : null
                  : totalFuelData?.consumptionsByFuelType[title]
                  ? parseFloat(
                      totalFuelData?.consumptionsByFuelType[title]
                        ?.totalEmission
                    ).toFixed(2)
                  : null
              }
              emissionEhr={
                isAds
                  ? totalFuelData?.consumptionsByFuelType[title]
                    ? totalFuelData?.consumptionsByFuelType[title]
                        ?.totalEmissionEHr
                    : null
                  : totalFuelData?.consumptionsByFuelType[title]
                  ? parseFloat(
                      totalFuelData?.consumptionsByFuelType[title]
                        ?.totalEmissionEHr
                    ).toFixed(2)
                  : null
              }
              totalConsumptionEhr={
                isAds
                  ? totalFuelData?.consumptionsByFuelType[title]
                    ? totalFuelData?.consumptionsByFuelType[title]
                        ?.totalFuelConsumedEHr
                    : null
                  : totalFuelData?.consumptionsByFuelType[title]
                  ? parseFloat(
                      totalFuelData?.consumptionsByFuelType[title]
                        ?.totalFuelConsumedEHr
                    ).toFixed(2)
                  : null
              }
              totalConsumption={
                isAds
                  ? totalFuelData?.consumptionsByFuelType[title]
                    ? totalFuelData?.consumptionsByFuelType[title]
                        ?.totalFuelConsumed
                    : null
                  : totalFuelData?.consumptionsByFuelType[title]
                  ? parseFloat(
                      totalFuelData?.consumptionsByFuelType[title]
                        ?.totalFuelConsumed
                    ).toFixed(2)
                  : null
              }
              totalDistance={
                isAds
                  ? totalFuelData?.totalRunningHour === 0
                    ? "-"
                    : totalFuelData?.totalRunningHour
                  : totalFuelData?.consumptionsByFuelType[title]
                  ? parseFloat(
                      totalFuelData?.consumptionsByFuelType[title]?.totalMileage
                    ).toFixed(2)
                  : undefined
              }
              avgEmissionPerKm={
                totalFuelData.averageCo2Emission
                  ? parseFloat(totalFuelData.averageCo2Emission).toFixed(2) +
                    " kg"
                  : null
              }
              noOfTrips={
                totalFuelData.tripCount ? totalFuelData.tripCount : null
              }
              noOfVehicles={
                !isAds &&
                totalFuelData?.consumptionsByFuelType[title]?.unit_count &&
                totalFuelData?.consumptionsByFuelType[title]?.unit_count
              }
              noOfDevice={
                totalFuelData.consumptionsByFuelType[title] ? noOfDevice : 0
              }
            />
          )
        : null}
    </div>
  );
};

const FuelDetails = (props) => {
  const { t } = useTranslation("dashboard");
  const {
    totalConsumptionEhr,
    emissionEhr,
    emission,
    totalConsumption,
    totalDistance,
    noOfVehicles,
    noOfDevice,
    title,
    baseLineEmission,
    baseLineEmissionEHr,
    cardStyle,
    _handleMouseLeave,
    fuelData,
    theme,
    companyId,
    companyType,
  } = props;
  const { isAds } = Store.common;
  const [showInfo, setShowInfo] = useState("");
  const _handleClose = () => {
    setShowInfo("");
    _handleMouseLeave(title);
  };
  return (
    <>
      <div className="row mx-0">
        <span className="col-5 ps-1">
          <div>
            {t("Total Consumption")}{" "}
            {`${
              title === "Electricity"
                ? "(kWh)"
                : title === "Compressed Natural Gas"
                ? "(m3)"
                : "(litres)"
            }`}
          </div>
          <div>{t("Total Carbon Emission ")}(tonnes)</div>
        </span>
        <div className="col-7 row px-0 mx-0">
          <span className="col-5 text-center">
            <div>-</div>
            <div className="pb-2">
              {companyType === "2"
                ? baseLineEmissionEHr
                  ? Number(baseLineEmissionEHr).toLocaleString("en-US")
                  : "-"
                : baseLineEmission
                ? Number(baseLineEmission).toLocaleString("en-US")
                : "-"}
            </div>
          </span>
          <span
            className="col-1 ms-2 px-0"
            style={{ width: "0.1px", background: "#746541", height: "100%" }}
          ></span>
          <span className="col-5 text-center">
            <div>
              {companyType === "2"
                ? totalConsumptionEhr
                  ? Number(totalConsumptionEhr).toLocaleString("en-US")
                  : "-"
                : totalConsumption
                ? Number(totalConsumption).toLocaleString("en-US")
                : "-"}
            </div>
            <div>
              {emission ? Number(emission).toLocaleString("en-US") : "-"}
            </div>
          </span>
          {noOfVehicles || noOfDevice ? (
            <span className="col-1 mx-0" style={{ color: Colors.fuel }}>
              <i
                style={{ cursor: "pointer" }}
                onClickCapture={() => setShowInfo(title)}
                className="bi bi-info-circle"
                onClick={() => setShowInfo(title)}
              />
              <div
                className="pb-2"
                style={{ borderBottom: "1px solid #746541" }}
              >
                <br />
              </div>
            </span>
          ) : null}
        </div>
      </div>
      <div
        className="my-1"
        style={{
          background: "#746541",
          height: "1px",
        }}
      />
      <div className="row ps-1">
        <span className="col-5">
          <div>
            {isAds ? t("Number of equipments") : t("Number of units")}
          </div>
          {/* <div>
            {companyType === "1" ? (
              isAds ? (
                t("Running Hour") + "(hr)"
              ) : (
                t("Mileage") + "(km)"
              )
            ) : (
              <></>
            )}
          </div> */}
        </span>
        <span className="col-7 text-center px-0">
          <div className="">
            {isAds
              ? noOfDevice
                ? Number(noOfDevice).toLocaleString("en-US")
                : "-"
              : noOfVehicles
              ? Number(noOfVehicles).toLocaleString("en-US")
              : "-"}
          </div>
          {/* <div className="">
            {companyType === "1" ? (
              isAds ? (
                totalDistance
              ) : totalDistance ? (
                Number(totalDistance).toLocaleString("en-US")
              ) : (
                "-"
              )
            ) : (
              <></>
            )}
          </div> */}
        </span>
      </div>
      <div
        style={{
          ...cardStyle,
          position: "relative",
          zIndex: 10,
          top: -125,
          right: -420,
          width: 620,
          maxHeight: 230,
          height: null,
          display: showInfo !== title && "none",
          // background: "white",
          // opacity:"0.8",
          borderRadius: 15,
          color: Colors.Dark[4],
          // transitionDuration: "0.2s",
        }}
      >
        {showInfo === title ? (
          <div className="mx-1">
            <span className="d-flex justify-content-end">
              <i
                style={{ cursor: "pointer", fontSize: 20 }}
                onClick={_handleClose}
                className="bi bi-x"
              ></i>
            </span>
            <div className="rounded" style={{ border: "1px solid #746541" }}>
              <span
                className="row my-2 d-flex justify-content-between"
                style={{
                  color: theme === "dark" ? Colors.medium : Colors.Dark[2],
                }}
              >
                <strong className="col-4 ps-4 text-center">{t("Owner")}</strong>
                <strong className="col-5 ps-0 text-center">{t("Unit")}</strong>
                <strong className="col-3 text-center ps-0">
                  {t("Consumption")}
                </strong>
              </span>
              <div
                style={{
                  maxHeight: 140,
                  overflow: "scroll",
                }}
              >
                {isAds
                  ? Object.keys(fuelData.deviceName)
                      .sort((a, b) => {
                        if (
                          fuelData.deviceName[a].totalConsumption >
                          fuelData.deviceName[b].totalConsumption
                        )
                          return -1;
                        if (
                          fuelData.deviceName[a].totalConsumption <
                          fuelData.deviceName[b].totalConsumption
                        )
                          return 1;
                        return 0;
                      })
                      .map((d, i) => {
                        return (
                          <span
                            key={i}
                            className="row mx-1"
                            style={{
                              borderTop: "1px solid #746541",
                              color: invertDark(theme),
                            }}
                          >
                            <span
                              className="col-4 text-center"
                              style={{ borderRight: "1px solid #746541" }}
                            >
                              <div>{d}</div>
                            </span>
                            <span className="col-4 text-end">
                              <div>
                                {fuelData.deviceName[d].totalConsumption
                                  ? (
                                      fuelData.deviceName[d].totalConsumption /
                                      1000
                                    ).toFixed(2) + " MWh"
                                  : "-"}
                              </div>
                            </span>
                          </span>
                        );
                      })
                  : Object.keys(fuelData)
                      .sort((a, b) => {
                        if (
                          fuelData[a].fuelConsumption >
                          fuelData[b].fuelConsumption
                        )
                          return -1;
                        if (
                          fuelData[a].fuelConsumption <
                          fuelData[b].fuelConsumption
                        )
                          return 1;
                        return 0;
                      })
                      .map((d, i) => {
                        return (
                          <span
                            key={i}
                            className="row mx-1"
                            style={{
                              borderTop: "1px solid #746541",
                              color: invertDark(theme),
                            }}
                          >
                            <span
                              className="col-4"
                              style={{ borderRight: "1px solid #746541" }}
                            >
                              <div>{fuelData[d].owner}</div>
                            </span>
                            <span
                              className="col-5 text-center"
                              style={{ borderRight: "1px solid #746541" }}
                            >
                              <div>{d}</div>
                            </span>
                            <span className="col-3 text-center">
                              <div>
                                {fuelData[d].type === "EV"
                                  ? fuelData[d].fuelConsumption.toFixed(2) +
                                    " kWh"
                                  : fuelData[d].fuelConsumption.toFixed(2) +
                                    " L"}
                              </div>
                            </span>
                          </span>
                        );
                      })}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};
