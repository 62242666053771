import { inject, observer } from "mobx-react";
import React from "react";
import CanvasJSReact from "@canvasjs/react-charts";

export const DashboardChart = inject("store")(
  observer((props) => {
    const { theme, scope, companyType } = props;
    const CanvasJS = CanvasJSReact.CanvasJS;
    const CanvasJSChart = CanvasJSReact.CanvasJSChart;

    console.log("companyType", companyType);

    const scope1_baseline_emission =
      companyType === "1"
        ? scope.scope_1.baseline_emission || 0
        : scope.scope_1.baseline_emission_E_Hr || 0;

    const scope2_baseline_emission =
      companyType === "1"
        ? scope.scope_2.baseline_emission || 0
        : scope.scope_2.baseline_emission_E_Hr || 0;

    const scope3_baseline_emission =
      companyType === "1"
        ? scope.scope_3.baseline_emission || 0
        : scope.scope_3.baseline_emission_E_Hr || 0;

    const scope1_carbon_emission =
      companyType === "1"
        ? scope.scope_1.carbon_emission || 0
        : scope.scope_1.carbon_emission_E_Hr || 0;

    const scope2_carbon_emission =
      companyType === "1"
        ? scope.scope_2.carbon_emission || 0
        : scope.scope_2.carbon_emission_E_Hr || 0;

    const scope3_carbon_emission =
      companyType === "1"
        ? scope.scope_3.carbon_emission || 0
        : scope.scope_3.carbon_emission_E_Hr || 0;

    const options = {
      animationEnabled: true,
      exportEnabled: false,
      backgroundColor: "",
      dataPointWidth: 80,
      height: 450,
      title: {
        // text: "Operating Expenses of ACME",
        // fontFamily: "verdana",
      },
      axisY: {
        title: "",
        includeZero: true,
        prefix: "",
        suffix: " ton",
        gridThickness: 0,
        labelFontColor: theme === "dark" ? "white" : "black",
        viewportMinimum: 0,
      },
      axisX: {
        labelFontColor: theme === "dark" ? "white" : "black",
      },
      toolTip: {
        shared: true,
        reversed: true,
      },
      legend: {
        verticalAlign: "center",
        horizontalAlign: "right",
        reversed: true,
        cursor: "pointer",
      },

      data: [
        {
          type: "stackedColumn",
          name: "Scope 3",
          showInLegend: false,
          yValueFormatString: "#,##0.## ton",
          color: "#EFA05D",
          dataPoints: [
            {
              label: "Baseline CO₂e Emission",
              y: scope3_baseline_emission,
              color: "#37518B",
              indexLabel:
                scope3_baseline_emission === 0
                  ? ""
                  : `${scope3_baseline_emission || 0} ton`,
              indexLabelFontColor: "white",
            },
            {
              label: "Actual CO₂e Emission",
              y: scope3_carbon_emission || 0,
              color: "#D77103",
              indexLabel:
                scope3_carbon_emission === 0
                  ? ""
                  : `${scope3_carbon_emission || 0} ton`,
              indexLabelFontColor: "white",
            },
          ],
        },
        {
          type: "stackedColumn",
          name: "Scope 2",
          showInLegend: false,
          color: "#FCC990 ",
          yValueFormatString: "#,##0.## ton",
          dataPoints: [
            {
              label: "Baseline CO₂e Emission",
              y: scope2_baseline_emission || 0,
              color: "#5084C3",
              indexLabel:
                scope2_baseline_emission === 0
                  ? ""
                  : `${scope2_baseline_emission || 0} ton`,
              indexLabelFontColor: "white",
            },
            {
              label: "Actual CO₂e Emission",
              y: scope2_carbon_emission || 0,
              color: "#ED9106",
              indexLabel:
                scope2_carbon_emission === 0
                  ? ""
                  : `${scope2_carbon_emission || 0} ton`,
              indexLabelFontColor: "white",
            },
          ],
        },
        {
          type: "stackedColumn",
          name: "Scope 1",
          showInLegend: false,
          yValueFormatString: "#,##0.## ton",
          dataPoints: [
            {
              label: "Baseline CO₂e Emission",
              y: scope1_baseline_emission || 0,
              color: "#6CA6FF",
              indexLabel:
                scope1_baseline_emission === 0
                  ? ""
                  : `${scope1_baseline_emission || 0} ton`,
              indexLabelFontColor: "white",
            },
            {
              label: "Actual CO₂e Emission",
              y: scope1_carbon_emission || 0,
              color: "#F1B04C",
              indexLabel:
                scope1_carbon_emission === 0
                  ? ""
                  : `${scope1_carbon_emission || 0} ton`,
              indexLabelFontColor: "white",
            },
          ],
        },
      ],
    };
    return (
      <div>
        <CanvasJSChart className="canvasjs-chart-credit" options={options} />
        {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
      </div>
    );
  })
);
