import { inject, observer } from "mobx-react";
import moment from "moment";
import { Colors } from "../../Assets/Config/color.config";
import { My_Input } from "../../Tools";
import { useTranslation } from "react-i18next";

export const CreateInviteLinkModel = inject("store")(
    observer((props) => {
        const {t} = useTranslation("admin-panel")
        const { inviteLinkData, _handleMailChange, sendMail } = props.store.adminPanel;
        return (
            <div
                className={`modal fade`}
                id="createInviteLinkModel"
                tabIndex="-1"
                aria-labelledby="createInviteLinkModel"
                aria-hidden="true">

                <div className="modal-dialog modal-md rounded">
                    <div className="modal-content" style={props.cardStyle}>
                        <div className="modal-header" style={{ border: "none" }}>
                            <h3>{t("Invite People")}</h3>
                            <button
                                type="button"
                                className={`btn-close ${props.theme === "dark" ? "btn-close-white" : null
                                    } `}
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>

                        <div className="modal-body">

                            <div className="m-2" style={props.rowStyle}>
                                <span className="col-12 py-2">{t("Invite Link")}</span>
                                <div className="row align-items-center">
                                    <div className="col-12">
                                        <My_Input
                                            disable={false}
                                            value={inviteLinkData?.invite_link}
                                            id="link"
                                            type="text"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="m-2" style={props.rowStyle}>
                                <span className="col-12 py-2">{`Your link will be expire at ${moment(inviteLinkData?.expire_at).format("yyyy-MM-DD HH:mm:ss")}`}</span>
                            </div>

                            <div className="m-2" style={props.rowStyle}>
                                <span className="col-12 py-2">{t("Email")}</span>
                                <div className="row align-items-center">
                                    <div className="col-12">
                                        <My_Input
                                            onChange={(e) => {
                                                _handleMailChange(e.target.value)
                                            }
                                            }
                                            value={sendMail}
                                            id="email"
                                            type="text"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button
                                id="create_user_btn"
                                onClick={props.handleSendMail}
                                type="button"
                                className="btn text-light mt-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                style={{
                                    background: Colors.lightBlue,
                                    width: "40%",
                                }}
                            // data-bs-dismiss="modal"
                            >
                                {t("Send Email")}
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        )
    })
);