import { inject, observer } from "mobx-react";
import { useState } from "react";
import { useEffect } from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { CompanySelector } from "../../Components/Dashboard/companySelector";
import { ConfirmDeleteModal } from "../../Components/LightManualEntry/ConfirmDeleteModal";
import { CreateNewAssetModal } from "../../Components/LightManualEntry/CreateNewAssetModal";
import { CreateReportModal } from "../../Components/LightManualEntry/createReportModal";
// import { LightWebformTable } from "../../Components/LightManualEntry/LightWebformTable";
import { LightWebformTableNew } from "../../Components/LightManualEntry/LiteWebformTableNew";

import { WebFormModal } from "../../Components/LightManualEntry/WebformModal";
import { My_Button } from "../../Tools";
import { useTranslation } from "react-i18next";


export const LightManualEntry = inject("store")(
    observer((props) => {
        const {t} = useTranslation("lite-manual-entry")
        const { theme } = props.store.login
        const { fetchCo2xLiteData, co2xLiteData, fetchAllAsset } = props.store.liteEntry
        const [manageMode, setManageMode] = useState(false)
        useEffect(() => {
            fetchCo2xLiteData()
        }, [])

        const createNewAssetModal = () => {
            let myModal = new window.bootstrap.Modal(document.getElementById('createNewAssetModal'))
            myModal.show()
        }
        return (
            <>
                <span
                    style={{
                        fontSize: 23,
                        color: theme === "dark" ? Colors["light"].background : Colors.Dark[2],
                    }}
                >
                    {t("Co2X Lite Manual Entry")}
                </span>
                <CompanySelector theme={theme} label={t("Organisation")+": "} />

                <div className="d-flex justify-content-between">
                    <div className="mb-2 mt-2">
                        <span 
                            // data-bs-toggle="modal"
                            // data-bs-target="#createNewAssetModal"
                            // data-toggle="modal"
                            // data-target=".bd-example-modal-lg"
                            onClick={createNewAssetModal}
                        ><My_Button customHoverColor='#0d6efde0' customColor='#0d6efd' leftIcon={<i className="bi bi-journal-plus pe-2"></i>} text={t('Create Manual Entry')} style={{ height: 40 }} />
                        </span>

                    </div>
                    {/* <My_Button customHoverColor={manageMode ? '#ff2d2dd1' : '#0d6efde0'} customColor={manageMode ? '#ff2d2d' : '#0d6efd'} onClick={() => setManageMode(!manageMode)} text={manageMode ? 'Cancel' : 'Manage List'} style={{ height: 40 }} /> */}
                </div>

                <LightWebformTableNew manageMode={manageMode} theme={theme} lightWebform createData={(
                    id,
                    status,
                    asset_categorization,
                    vehicle_type,
                    plate_number,
                    fuel_type,
                    total_consumption,
                    fuel_unit,
                    total_mileage,
                    report_type,
                    period_start,
                    period_end,
                    remark,
                    action
                ) => {
                    return {
                        id,
                        status,
                        asset_categorization,
                        vehicle_type,
                        plate_number,
                        fuel_type,
                        total_consumption,
                        fuel_unit,
                        total_mileage,
                        report_type,
                        period_start,
                        period_end,
                        remark,
                        action
                    };
                }}
                    Key={[
                        "id",
                        "status",
                        "asset_categorization",
                        "vehicle_type",
                        "plate_number",
                        "fuel_type",
                        "total_consumption",
                        "fuel_unit",
                        "total_mileage",
                        "report_type",
                        "period_start",
                        "period_end",
                        "remark",
                        "action"
                    ]}
                    data={co2xLiteData || data}
                    header={[
                        { id: "asset_categorization", label: t("Asset Categorization") },
                        { id: "vehicle_type", label: t("Vehicle Type") },
                        { id: "plate_number", label: t("Plate Number") },
                        { id: "fuel_type", label: t("Fuel Type") },
                        { id: "total_consumption", label: t("Total Consumption") },
                        { id: "fuel_unit", label: t("Fuel Unit") },
                        { id: "total_mileage", label: t("Total Mileage(km)") },
                        { id: "report_type", label: t("Report Type") },
                        { id: "period_start", label: t("Start Period" )},
                        { id: "period_end", label: t("End Period") },
                        { id: "remark", label: t("Remark") },
                        { id: "action", label: "" },
                    ]}
                />

                <WebFormModal />
                <ConfirmDeleteModal />
                <CreateReportModal />
                <CreateNewAssetModal  theme = {theme}/>
            </>
        )
    }))
const data = [
    {
        plate_no: 'GBH213X',
        vehicle_type: '4-RD',
        fuel_type: 'Diesel',
        total_consumption: '1200',
        total_mileage: '5000',
        mileage_unit: 'km',
        report_type: 'Yearly',
        start_period: '1/1/2022',
        end_period: '12/31/2022',
    }
]