import { useTranslation } from "react-i18next";
import { invertDark } from "../../Assets/Config/color.config";
import userImage from "../../Assets/Images/icons/user.png";
import { UserUnitModal } from "./UserUnitModal";
import { inject, observer } from "mobx-react";

export const UserCard =inject("store")( observer((props) => {
    const { theme, user, editUserId } = props;
    const { setEditUser,handleReminderSwitch } = props.store.adminPanel;
    const {t} = useTranslation("admin-panel")

    return (
        <div className="userCard" style={{
            color: invertDark(theme), background: 'rgb(255,255,255,0.05)',
             minHeight: 200, borderTopRightRadius: 5, borderTopLeftRadius: 5, border: theme === 'light' && '1px solid rgb(0,0,0,0.1)'
        }}>
            <div className="text-end pe-2" onClick={() => setEditUser(user.id, user.plan_name)}><i className={`bi bi-pencil ${editUserId === user.id ? '' : 'editShow'}`} style={{cursor: 'pointer'}}></i></div>
            <div className="p-3">
                <div className="text-center  pb-3 ">
                    <img height='60px' src={userImage} />
                    
                    
                    <div style={{ fontSize: 18, color: theme === 'dark' ? 'white' : 'black' }} className="text-truncate pt-1">
                        {user.username}
                    </div>
                    <div style={{ fontSize: 14 }}>{user.status == 0 ? t("PENDING") : user.status == 1 ? t("ACTIVE") : user.status == 2 ? t("DEACTIVATED") : t("ACTIVE")}</div>
                    <div style={{ fontSize: 14 }}>{user.plan_name}</div>
                </div>
                <div className="px-3">
                    <div className="row "><i className="col-2 bi bi-envelope"></i><span className="col text-truncate">{user.email}</span></div>
                    <div className="row"><i className="col-2 bi bi-building"></i><span className="col text-truncate">{user.name}</span></div>
                    <div className="row"><i className="col-2 bi bi-key"></i><span className="col text-truncate">{user.role}</span></div>
                    {user.role==='admin'?
                        <div className="row"><label className="d-flex align-items-center" style={{cursor:'pointer'}}>
                        <div className="col-2 d-flex"><input style={{width: '16px',height: '16px'}} type="checkbox" 
                        role="switch" id={user.id+" mailReminder"} checked={user.remind_email} 
                        onChange={(e) => handleReminderSwitch(e,user.id)} /></div>
                        <span className="col text-truncate"  style={{paddingLeft: '4px'}}>Email Notification</span></label></div>:<br/>}
                </div>
            </div>

           
        </div>
    )
}));