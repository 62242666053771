import { Colors } from "../Assets/Config/color.config";

export const My_upload_image = (props) => {
  const { _handleUploadImage, uploadLogoImage, photo, _resetImage, companyId, fetchCompany } = props;
  return (
    <span
      className="position-relative d-flex align-items-center m-2"
      style={{       
        height: 155,
        width: '150%',
        border: "1px dashed #C6C6C6"
      }}
    >
      <div className="d-flex align-items-center" style={{ background: Colors.darkBlue, borderRadius: 5 }}>
        <label
          style={{
            width: "100%",
            borderRadius: 5,
            cursor: "pointer",
          }}
        htmlFor="image-upload"
        >
        <input
          type="file"
          style={{ display: "none" }}
          id="image-upload"
          accept="image/x-png,image/gif,image/jpeg"
          onChange={(e) => {_handleUploadImage(e, companyId); fetchCompany();}}
        />
        {uploadLogoImage === "" ? (
          <span className="d-flex flex-column justify-content-center align-items-center h-100 p-0 m-2">
            <i style={{ fontSize: 30 }} className="bi bi-plus-lg" />
            <div>Add an Image</div>
          </span>
        ) : (
          <span className="d-flex justify-content-center m-3">
            <img src={uploadLogoImage} width={'80px'} />
          </span>
        )}
      </label>
      {/* {photo && (
          <span className="col-3">
            {photo?.name}
            <div style={{ color: Colors.greenMaterial }}>
              {photo?.size / 1000}Kb
            </div>
          </span>
        )} */}
    </div>
      {
    uploadLogoImage !== "" && (
      <span
        onClick={() => _resetImage(companyId)}
        style={{
          position: "absolute",
          top: -7,
          right: -7,
          color: Colors.Dark[5],
          cursor: "pointer",
          background: "red",
          borderRadius: "50%",
          width: 15,
          height: 15,
        }}
        className="d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-x m-1"></i>
      </span>
    )
  }
    </span >
  );
};
