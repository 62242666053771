import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { invertDark, Colors } from "../../Assets/Config/color.config";
import { useTranslation } from "react-i18next";

import { CO2_Loading } from "../../Assets/CO2_Loading";
import Player from "../../Components/UserGuide/Player";
import Playlist from "../../Components/UserGuide/Playlist";
import axios from "axios";

const UserGuideContainer = inject("store")(
  observer((props) => {
    const { role } = props.store.common;
    const { theme } = props;
    const [selectedVideo, setSelectedVideo] = useState({
      snippet: {
        channelId: "UC5X_qM8FgsJ_rmAD0gqOwEw",
        channelTitle: "Ascent Solution Training Videos",
        description:
          "Navigating the CO2X Enterprise Application Episode 1: Introduction",
        playlistId: "PL2DbVjPhEBvOTsmUxldoLz4g52qb3rKqI",
        position: 3,
        publishedAt: "2023-05-02T04:37:51Z",
        resourceId: {
          kind: "youtube#video",
          videoId: "vywVKngZwqI",
        },
      },
    });

    // console.log("selectedVideo ===>", selectedVideo);
    const handleVideoSelect = (video) => {
      setSelectedVideo(video);
    };
    const [videos, setVideos] = useState([]);

    // console.log("video===>",videos)

    useEffect(() => {
      const fetchPlaylistItems = async () => {
        try {
          const response = await axios.get(
            `https://www.googleapis.com/youtube/v3/playlistItems`,
            {
              params: {
                part: "snippet",
                playlistId: "PL2DbVjPhEBvOTsmUxldoLz4g52qb3rKqI",
                maxResults: 10,
                key: "AIzaSyBhM2Fm5cEWpbMr-nOaJYbeMCP1mmyGugY",
              },
            }
          );

          setVideos(response.data.items);
        } catch (error) {
          console.error("Error fetching data: ", error);
        }
      };

      fetchPlaylistItems();
    }, []);

    const cardStyle = {
      boxShadow: "2px 4px 11px rgba(0,0,0,0.25)",
      padding: "10px",
      fontSize: "12px",
      borderRadius: "8px",
      height: "90% ",
      color: invertDark(theme),
      // background: Colors[theme].background,
      // background: 'pink',
      marginLeft: 3,
    };

    return (
      <>
        {/* <div style={{ color: invertDark(theme), padding: "0px" }}>
          <h4>{"User Guide"}</h4>
        </div> */}
        <div className="row">
        <div className="col-lg-7 col-md-12 col-sm-12 order-lg-last  ">
            <Player selectedVideo={selectedVideo} theme ={theme} />
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 order-lg-first ">
            <Playlist
              onVideoSelect={handleVideoSelect}
              videos={videos}
              theme={theme}
              selectedVideo={selectedVideo}
            />
          </div>
         
        </div>
        {/* </div> */}
      </>
    );
  })
);

export default UserGuideContainer;
