import React, { Component, useContext } from "react";
import { inject, observer } from "mobx-react";
import { Colors } from "../../Assets/Config/color.config";
import co2_ribbon from "../../Assets/Images/co2_ribbon.png";
import low_carbon_sg from "../../Assets/Images/icons/low_carbon_sg.png";
import co2_sign from "../../Assets/Images/co2_sign.png";
import co2_up from "../../Assets/Images/Up.png";
import co2_down from "../../Assets/Images/Down.png";
import { toJS } from "mobx";
import moment from "moment";
import { CompanySelector } from "./companySelector";
import { My_Button as MyButton } from "../../Tools";
import jsPDF from "jspdf";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";
import { Cookies } from "react-cookie";
import Store from "../../Store";
import co2_ribbon_removebg from "../../Assets/Images/co2_ribbon_removebg.png";
import co2_sign_blue from "../../Assets/Images/co2_sign_blue.png";
import GreenDealLogo from "../../Assets/Images/GreenDealLogo.png";
import SilverBadge from "../../Assets/Images/SilverBadge.png";
import GoldenBadge from "../../Assets/Images/GoldenBadge.png";
import { useTranslation } from "react-i18next";
import Tour from "./orgTour";
// import Tour from '../components/tour';

export const CompanyCertificateModal = inject("store")(
  observer((props) => {
    const theme = Store.login.theme;
    const { t } = useTranslation("dashboard");
    // const theme = 'dark' // for consistent printing
    const {
      certificates: _certificates,
      certificateYear,
      selectedCertificateId,
      certificatesV2,
    } = props.store.dashboard;
    const StyleBorderTop = { borderTop: "0.1px solid #FF5F1F" };
    const certificates = toJS(certificatesV2);
    const certificate = certificates
      ? certificates.find((c) => c.id === selectedCertificateId)
      : null;

    const changeInDieselConsumption =
      certificate && certificate?.gas_diesel_by_fuel?.gas_diesel_consumption;
    const changeInPetrolConsumption =
      certificate && certificate?.gas_petrol_by_fuel?.gas_petrol_consumption;
    const changeInElectricityConsumption =
      certificate && certificate?.energy?.energy_consumption;

    const current_emission = certificate && certificate.currentEmission;

    const ECandCECurrentMonthTextColor = { color: "#ffffff" };

    const certificateRef = React.useRef(null);
    const cookie = new Cookies();
    const c_name = cookie.get("companyName");
    const { companyName } = props.store.common;

    const download = async () => {
      const certificateElement = certificateRef.current;

      if (certificateElement) {
        try {
          const doc = new jsPDF("p", "px", "a4", true);

          doc.html(certificateElement, {
            callback: function (doc) {
              doc.save(
                `GHG_Emission_Reduction_Statement_of_${
                  c_name ? c_name : companyName
                }_${moment(certificate?.certificate_date).format(
                  "DD-MM-YYYY"
                )}.pdf`
              );
            },
            html2canvas: {
              scale: 0.69,
            },
            x: 0,
            y: 0,
            width: doc.internal.pageSize.getWidth(),
            height: doc.internal.pageSize.getHeight(),
            windowWidth: 647,

            // margin: 50,
          });
        } catch (e) {
          console.log(e);
        }
      }
    };

    return (
      <div
        className="modal fade overflow-scroll"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ maxHeight: "750px" }}
      >
        <div className="w-100 d-flex align-items-center justify-content-end py-2 pt-4 px-3">
          {/* <Tour/> */}
          <MyButton
            leftIcon={<DownloadIcon className="me-2" fontSize="small" />}
            small={false}
            className="px-3 me-3 downloadCerti-org-guide"
            text={t("Download")}
            onClick={() => {
              download();
            }}
          />
          <MyButton
            leftIcon={<CloseIcon />}
            small={false}
            className="close rounded-circle"
            dataDismiss="modal"
            onClick={() => {}}
          />
        </div>
        {/* fixed width for printing certificate */}
        {/* <div className="modal-dialog modal-xl rounded" style={{ width: '600px' }}> */}
        <div
          className="modal-dialog modal-xl rounded"
          style={{ width: "100%", maxWidth: "600px" }}
        >
          <div
            ref={certificateRef}
            className="modal-content text-center certificate-org-guide"
            style={{
              // color:
              //   theme === "dark" ? Colors['dark'].background : Colors[theme].Dark,
              color: theme === "dark" ? "#fff" : "#000",
              border: `6px solid ${Colors.lightBlue}`,
              background: theme === "dark" ? "#101841" : "#F6F6F6",
              height: "915px",
            }}
          >
            {certificate && (
              <>
                <div
                  className="modal-body m-1 p-3"
                  style={{ border: "6px solid #98C355", height: "500px" }}
                >
                  {/* {showCertiTour && <Tour/>} */}
                  <h4 className="pt-5 mb-0">
                    {t("GHG Emission Reduction Statement")}
                  </h4>
                  <div
                    className="py-4 d-flex justify-content-center"
                    style={{ fontSize: 14, fontStyle: "italic" }}
                  >
                    <span style={{ wordSpacing: 3 }}>{t("Presented to")}</span>
                  </div>

                  <div className="">
                    <div
                      className="row col-md-12 d-flex justify-content-center align-items-center m-0 p-0"
                      style={{ fontSize: 14 }}
                    >
                      <div className="d-flex justify-content-center">
                        <strong>{t("Company")}:</strong>
                      </div>
                      <div className="d-flex justify-content-center">
                        <CompanySelector
                          theme={theme}
                          certificateCompanycol={true}
                          className="col-md-6"
                          style={{ letterSpacing: ".04rem" }}
                        />
                      </div>
                    </div>
                  </div>

                  <table
                    className="w-100 my-5 mb-4 text-start"
                    style={{
                      color:
                        theme === "dark"
                          ? Colors["light"].background
                          : Colors[theme].Dark,
                    }}
                  >
                    <tbody
                      style={{
                        ...StyleBorderTop,
                        // ...StyleBorderBottom,
                        fontSize: 14,
                      }}
                    >
                      {/* {changeInElectricityConsumption == 0 ? (
                        ""
                      ) : ( */}
                      <tr>
                        <td className="py-2">
                          {t("Total Electricity Consumption for Current Month")}{" "}
                          :
                        </td>
                        <td className="text-end pe-2 py-2">
                          <span
                            style={{
                              color: ECandCECurrentMonthTextColor,
                              paddingRight: 15,
                            }}
                          >
                            {Number(
                              Math.abs(changeInElectricityConsumption).toFixed(
                                2
                              )
                            ).toLocaleString("en-US")}{" "}
                            kWh
                          </span>
                        </td>
                      </tr>
                      {/* )} */}

                      {/* {changeInCngConsumption && (
                        <tr>
                          <td className="py-2">
                            {t("Total CNG Consumption for Current Month")} :
                          </td>
                          <td className="text-end pe-2  py-2">
                            <span
                              style={{
                                color: ECandCECurrentMonthTextColor,
                                paddingRight: 15,
                              }}
                            >
                              {Number(
                                Math.abs(
                                  changeInElectricityConsumption.totalEmission
                                ).toFixed(2)
                              ).toLocaleString("en-US")}{" "}
                              kgCO2e
                            </span>
                          </td>
                        </tr>
                      )} */}

                      {changeInDieselConsumption == 0 ? (
                        ""
                      ) : (
                        <tr>
                          <td className="py-2">
                            {t("Total Diesel Consumption for Current Month")} :
                          </td>
                          <td className="text-end pe-2 py-2">
                            <span
                              style={{
                                color: ECandCECurrentMonthTextColor,
                                paddingRight: 15,
                              }}
                            >
                              {Number(
                                Math.abs(changeInDieselConsumption).toFixed(2)
                              ).toLocaleString("en-US")}{" "}
                              L
                            </span>
                          </td>
                        </tr>
                      )}

                      {changeInPetrolConsumption == 0 ? (
                        ""
                      ) : (
                        <tr>
                          <td className="py-2">
                            {t("Total Petrol Consumption for Current Month")} :
                          </td>
                          <td className="text-end pe-2  py-2">
                            <span
                              style={{
                                color: ECandCECurrentMonthTextColor,
                                paddingRight: 15,
                              }}
                            >
                              {Number(
                                Math.abs(changeInPetrolConsumption).toFixed(2)
                              ).toLocaleString("en-US")}{" "}
                              L
                            </span>
                          </td>
                        </tr>
                      )}

                      {current_emission && (
                        <tr
                          style={{
                            ...StyleBorderTop,
                            borderBottom: "1px solid #FF5F1F",
                          }}
                        >
                          <td className="py-2">
                            Total CO<sub>2</sub>e Emitted for Current Month :
                          </td>
                          <td className="text-end pe-2  py-2">
                            <span
                              style={{
                                color: ECandCECurrentMonthTextColor,
                                paddingRight: 15,
                              }}
                            >
                              {Number(
                                Math.abs(current_emission).toFixed(2)
                              ).toLocaleString("en-US")}{" "}
                              kgCO<sub>2</sub>e
                            </span>
                          </td>
                        </tr>
                      )}

                      <tr
                        style={{
                          ...StyleBorderTop,
                          borderBottom: "1px solid #FF5F1F",
                        }}
                      >
                        <td className="py-2">
                          CO<sub>2</sub>e Emission Reduction :<br></br>
                          {t(
                            "Actual Emission vs Baseline Emission for Current Month"
                          )}
                        </td>
                        <td
                          className="text-end pe-2 py-2"
                          style={{ verticalAlign: "baseline" }}
                        >
                          <div className="">
                            <span
                              style={{
                                color:
                                  certificate.offset <= 0
                                    ? Colors.low
                                    : Colors.high,
                              }}
                            >
                              {Number(
                                Math.abs(certificate.offset).toFixed(2)
                              ).toLocaleString("en-US")}{" "}
                              kgCO<sub>2</sub>e{" "}
                              {certificate.offset <= 0 ? (
                                <img
                                  src={co2_down}
                                  style={{ width: 10 }}
                                  alt="down"
                                />
                              ) : (
                                <img
                                  src={co2_up}
                                  style={{ width: 10 }}
                                  alt="up"
                                />
                              )}
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div
                    className="d-flex flex-column justify-content-center mb-3"
                    style={{ height: "200px" }}
                  >
                    <div
                      className="text-start"
                      style={{
                        fontStyle: "italic",
                        fontSize: 14,
                      }}
                    >
                      {t(
                        // "This certificate is awarded for carbon emissions reduction of"
                        "You have reduced carbon emissions by"
                      )}{" "}
                      {Number(
                        Math.abs(certificate.offset).toFixed(2)
                      ).toLocaleString("en-US")}{" "}
                      kgCO<sub>2</sub>e {t("from")}{" "}
                      {certificate.certificate_start_date} {t("to")}{" "}
                      {certificate.certificate_end_date}
                    </div>
                    <div
                      className="d-flex justify-content-between align-items-center mt-5"
                      style={{ fontSize: 12 }}
                    >
                      <div className="d-flex flex-column">
                        <div className="d-flex flex-column ms-2">
                          <div className="w-100">
                            {/* <div className="text-center">
                            {theme === "dark" ? (
                              <img
                                src={co2_sign}
                                alt="sign"
                                style={{ width: 130 }}
                              />
                            ) : (
                              <img
                                src={co2_sign_blue}
                                alt="sign"
                                style={{ width: 130 }}
                              />
                            )}
                          </div>
                          <span>{t("Director")}</span> */}
                          </div>
                          <div
                            className=" d-flex"
                            style={{
                              whiteSpace: "nowrap",
                              marginRight: "30px",
                            }}
                          >
                            <div className="pe-1">{t("Date")}: </div>
                            <div>
                              <span className="pe-1">
                                {certificate.certificate_date_str}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <img
                          src={co2_ribbon_removebg}
                          // style={{ width: 160 }}
                          style={{ width: "100%", maxWidth: "160px" }}
                          alt="ribbon"
                        />
                      </div>
                      {certificate?.badge_status == 0 ? (
                        ""
                      ) : certificate?.badge_status == 1 ? (
                        <div style={{ width: "130px" }}>
                          <img
                            src={GreenDealLogo}
                            style={{ width: "80px" }}
                            alt="green deal"
                          />
                        </div>
                      ) : certificate?.badge_status == 2 ? (
                        <div style={{ width: "130px" }}>
                          <img
                            src={SilverBadge}
                            style={{ width: "120px", paddingTop: "23px" }}
                            alt="green deal"
                          />
                        </div>
                      ) : certificate?.badge_status == 3 ? (
                        <div style={{ width: "130px" }}>
                          <img
                            src={GoldenBadge}
                            style={{ width: "120px", paddingTop: "23px" }}
                            alt="green deal"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div
                    className="modal-footer   justify-content-center text-start p-0 py-2"
                    style={{
                      borderTop:
                        theme === "dark"
                          ? "0.5px solid #fff"
                          : "0.5px solid #000",
                      fontStyle: "italic",
                      fontSize: 11,
                    }}
                  >
                    {/* {t(
                      "The CO2e emissions calculation methodology employed to determine the emissions reductions represented by this certificate adheres to globally recognized standards, ensuring accuracy, completeness, and alignment with widely accepted frameworks for measuring environmental impact."
                    )} */}
                    {/* {t("")}                        */}
                    The above GHG emissions statement was derived in accordance
                    with the principles and guidelines from ISO 14064-1:2018 and
                    GHG Protocols, as well as Emissions Factor Databases such as
                    IPCC Guidelines. In addition, the calculation of GHG
                    emissions in this statement is based on serviceable IoT
                    sensors installed by the organisation and/ or information
                    provided by the declarant with the relevant evidential
                    artifacts. This statement serves as a report by the user for
                    self-disclosures to stakeholders and should not be used as a
                    certificate of assurance for public communications or press
                    releases.
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  })
);
