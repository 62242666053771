import { inject, observer } from "mobx-react";
import { useRef, useEffect, useState } from "react";
import "datatables.net-dt";
import "datatables.net-dt/css/jquery.dataTables.css";
import * as React from "react";
import $ from "jquery";
import { toJS } from "mobx";
import { t } from "i18next";
import { CO2_Loading } from "../../Assets/CO2_Loading";

export const MonthlyHistoryDataTable = inject("store")(
  observer((props) => {
    const { theme, certificates, companyType } = props;
    const { stacsHistory, loading } = props.store.trackAndTrace;

    let cert_history =
      certificates &&
      certificates?.certificates?.map((v) => {
        let data = {
          date: v.date,
          batch_id: v.batchId,
          status: v.is_create_or_update,
          mileage: Number(v.totalDistance).toFixed(4),
          trips: v.totalTrip,
          co2_emission: Number(v.currentEmission).toFixed(4),
          baseline_emission: Number(v.baselineEmission).toFixed(4),
          offset: Number(v.offset).toFixed(4),
        };
        return data;
      });

    const tableRef = useRef(null);

    let table = null;

    useEffect(() => {
      const styleElement = document.createElement(`style`);
      styleElement.innerHTML =
        theme === "dark"
          ? `
          .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
              color: black !important;
              border: 1px solid #E5E5E5!important;
              background-color: #E5E5E5 !important;
              /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #E5E5E5), color-stop(100%, #E5E5E5))!important; */
              background: -webkit-linear-gradient(top, #E5E5E5 0%, #E5E5E5 100%)!important;
              background: -moz-linear-gradient(top, #E5E5E5 0%, #E5E5E5 100%)!important;
              background: -ms-linear-gradient(top, #E5E5E5 0%, #E5E5E5 100%) !important;
              background: -o-linear-gradient(top, #E5E5E5 0%, #E5E5E5 100%)!important;
              background: linear-gradient(to bottom, #E5E5E5 0%, #E5E5E5 100%)!important;
            }
            .dataTables_wrapper .dataTables_paginate .paginate_button.current {
              color: black !important;
              border: 1px solid #C6C6C6!important;
              background-color: #C6C6C6 !important;
              /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FFFCFC), color-stop(100%, #969292))!important; */
              background: -webkit-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
              background: -moz-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
              background: -ms-linear-gradient(top, #FFFCFC 0%, #969292 100%) !important;
              background: -o-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
              background: linear-gradient(to bottom, #FFFCFC 0%, #969292 100%)!important;
            }
            .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
              color: black !important;
              border: 1px solid #C6C6C6!important;
              background-color: #C6C6C6 !important;
              /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FFFCFC), color-stop(100%, #969292))!important; */
              background: -webkit-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
              background: -moz-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
              background: -ms-linear-gradient(top, #FFFCFC 0%, #969292 100%) !important;
              background: -o-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
              background: linear-gradient(to bottom, #FFFCFC 0%, #969292 100%)!important;
            }

            .dataTables_wrapper .dataTables_paginate .paginate_button {
              color: white !important; 
            }

            .dataTables_wrapper .dataTables_paginate .ellipsis {
              color: white !important; 
            }

            .dataTables_wrapper .dataTables_length{
              color: white !important;
            }

            .dataTables_wrapper .dataTables_filter {
              color: white !important;
            }

            .dataTables_wrapper .dataTables_info {
              color: white !important;
            }
          `
          : `
              .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
                  color: black !important;
                  border: 1px solid #E5E5E5!important;
                  background-color: #E5E5E5 !important;
                  /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #E5E5E5), color-stop(100%, #E5E5E5))!important; */
                  background: -webkit-linear-gradient(top, #E5E5E5 0%, #E5E5E5 100%)!important;
                  background: -moz-linear-gradient(top, #E5E5E5 0%, #E5E5E5 100%)!important;
                  background: -ms-linear-gradient(top, #E5E5E5 0%, #E5E5E5 100%) !important;
                  background: -o-linear-gradient(top, #E5E5E5 0%, #E5E5E5 100%)!important;
                  background: linear-gradient(to bottom, #E5E5E5 0%, #E5E5E5 100%)!important;
                }
                .dataTables_wrapper .dataTables_paginate .paginate_button.current {
                  color: black !important;
                  border: 1px solid #C6C6C6!important;
                  background-color: #C6C6C6 !important;
                  /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FFFCFC), color-stop(100%, #969292))!important; */
                  background: -webkit-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
                  background: -moz-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
                  background: -ms-linear-gradient(top, #FFFCFC 0%, #969292 100%) !important;
                  background: -o-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
                  background: linear-gradient(to bottom, #FFFCFC 0%, #969292 100%)!important;
                }

                .dataTables_wrapper .dataTables_paginate .paginate_button {
                  color: black !important; 
                }  

                .dataTables_wrapper .dataTables_paginate .ellipsis {
                  color: black !important; 
                }

                .dataTables_wrapper .dataTables_length{
                  color: black !important;
                }

                .dataTables_wrapper .dataTables_filter {
                  color: black !important;
                }

                .dataTables_wrapper .dataTables_info {
                  color: black !important;
                }
          `;
      document.head.appendChild(styleElement);

      const tableElement = $(tableRef.current);

      if ($.fn.DataTable.isDataTable(tableElement)) {
        tableElement.DataTable().destroy();
      }

      table = tableElement.DataTable({
        autoWidth: true,
        responsive: true,
        retrieve: true,
        scrollX: true,
        lengthMenu: [8, 10, 15],
        order: [[0, "desc"]],
        data: cert_history,
        // columns: [
        //   { title: t('Batch ID'), data: 'batch_id' },
        //   { title: t('Creation Date'), data: 'date' },
        //   { title: t('Trip Mileage'), data: 'mileage' },
        //   { title: t('Total Trips'), data: 'trips' },
        //   { title: t('Baseline Emission'), data: 'baseline_emission' },
        //   { title: t('Offset'), data: 'offset' },
        // ],
        // columnDefs: [
        //   { width: '250px', targets: 0 },
        //   { width: '100px', targets: [1, 2, 3, 4, 5] },
        // ]
        columns: [
          { title: t("Creation Date"), data: "date", width: "150px" },
          { title: t("Batch ID"), data: "batch_id", width: "265px" },
          { title: t("Status"), data: "status", width: "100px" },
          { title: t("Trip Mileage"), data: "mileage", width: "100px" },
          { title: t("Total Trips"), data: "trips", width: "100px" },
          {
            title: t("CO2 Emission(kgCO₂e)"),
            data: "co2_emission",
            width: "100px",
          },
          {
            title: t("Baseline Emission(kgCO₂e)"),
            data: "baseline_emission",
            width: "100px",
          },
          { title: t("Offset(kgCO₂e)"), data: "offset", width: "100px" },
        ],
      });

      const searchInput = $(".dataTables_filter input");
      searchInput.css("color", theme === "light" ? "black" : "white");

      const entriesSelect = $(".dataTables_length select");
      entriesSelect.css(
        "background-color",
        theme === "light" ? "" : "rgb(32, 43, 96)"
      );
      entriesSelect.css("color", theme === "light" ? "black" : "white");

      let tableRow = $("table.dataTable tbody");
      tableRow.css("cursor", "pointer");
    }, [theme, cert_history]);

    useEffect(() => {
      if (table) {
        table.clear();
        table.draw();
        table.rows.add(cert_history || []).draw();
      }
    }, [theme, cert_history]);

    return (
      <div>
        <table
          ref={tableRef}
          id="monthlyhistorytable"
          className="display cell-border hover stripe"
          style={{ width: "100%" }}
        ></table>
      </div>
    );
  })
);
