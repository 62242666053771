import { inject, observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import "datatables.net-dt";
import "datatables.net-dt/css/jquery.dataTables.css";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import $, { data } from "jquery";
import {
  Pagination,
  PaginationItem,
  styled,
  TableSortLabel,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { action, toJS } from "mobx";
import { ViewForManualEntry } from "./ViewForManualEntry";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { My_Button } from "../../Tools";
import { Badge, Button } from "react-bootstrap";
import { ConfirmDeleteCSVfileModal } from "./ConfirmDeleteCSVfileModal";
import { Cookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { CO2_Loading } from "../../Assets/CO2_Loading";

export const DataImportHistoryTable = inject("store")(
  observer((props) => {
    const { t } = useTranslation("data_import");
    const {
      theme,
      _handleViewContract,
      handleDownloadCSV,
      noPagination,
      importUnitProfileHistory,
      importTripHistory,
      importEngineHours,
      importManualEntry,
    } = props;

    const {
      disable,
      showTable,
      filenotexist,
      handleTableChange,
      decode,
      companyName,
      SubmitLoading,
      loading,
      handleDownloadRefFile
    } = props.store.dataImport;

    const { role } = props.store.common;
    const cookies = new Cookies();
    const companyId = cookies.get("company");


    const [open, setOpen] = React.useState(false);
    const handleCloseDeleteCSVfileModal = () => setOpen(false);
    const handleOpenSubmitCSVfileModal = () => setOpen(!open);

    const Key = [
      "id",
      "date",
      "company_name",
      "file_name",
      "createdBy",
      "status",
      "action",
    ];

    const [page, setPage] = React.useState(1);
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("");
    const history = useHistory();
    const modalRef = useRef(null);

    // const cookies = new Cookies();
    // const companyName = cookies.get("companyName");

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
      // console.log("property", property);
    };

    const headers = [
      // { label: "Id", key: "id" },
      { label: t("Date"), key: "date" },
      { label: t("Company Name"), key: "company_name" },
      { label: t("File Name"), key: "file_name" },
      { label: t("Repository File Name"), key: "reFfile_name" },
      { label: t("Created By"), key: "createdBy" },
      { label: t("Status "), key: "status" },
      {},
    ];

    let rows;
    if (role === "super_admin" || companyName === "Volvo") {
      if (showTable === "table1") {
        rows = importUnitProfileHistory?.map((v, i) => {
          // console.log(v)
          const historyData = {
            id: v.id,
            date: v.date,
            company_name: v.name,
            file_name: v.fileName,
            uploadedFileName: v.uploadedFileName,
            reFfile_name: v.refFileName || 'N/A',
            createdBy: v.createdBy,
            status: v.status,
            isSubmitted: v.isSubmitted,
            category: v.category,
          };
          return historyData;
        });
      } else if (showTable === "table2") {
        rows = importTripHistory?.map((v, i) => {
          const historyData = {
            id: v.id,
            date: v.date,
            company_name: v.name,
            file_name: v.fileName,
            uploadedFileName: v.uploadedFileName,
            reFfile_name: v.refFileName || 'N/A',
            createdBy: v.createdBy,
            status: v.status,
            isSubmitted: v.isSubmitted,
            category: v.category,
          };
          return historyData;
        });
      } else if (showTable === "table3") {
        rows = importEngineHours?.map((v, i) => {
          const historyData = {
            id: v.id,
            date: v.date,
            company_name: v.name,
            file_name: v.fileName,
            uploadedFileName: v.uploadedFileName,
            reFfile_name: v.refFileName || 'N/A',
            createdBy: v.createdBy,
            status: v.status,
            isSubmitted: v.isSubmitted,
            category: v.category,
          };
          return historyData;
        });
      } else {
        rows = importManualEntry?.map((v, i) => {
          const historyData = {
            id: v.id,
            date: v.date,
            company_name: v.name,
            file_name: v.fileName,
            uploadedFileName: v.uploadedFileName,
            reFfile_name: v.refFileName || 'N/A',
            createdBy: v.createdBy,
            status: v.status,
            isSubmitted: v.isSubmitted,
            category: v.category,
          };
          return historyData;
        });
      }
    } else {
      rows = importManualEntry?.map((v, i) => {
        const historyData = {
          id: v.id,
          date: v.date,
          company_name: v.name,
          file_name: v.fileName,
          uploadedFileName: v.uploadedFileName,
          reFfile_name: v.refFileName || 'N/A',
          createdBy: v.createdBy,
          status: v.status,
          isSubmitted: v.isSubmitted,
          category: v.category,
        };
        return historyData;
      });
    }

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      cursor: "pointer",
      "&:nth-of-type(odd)": {
        backgroundColor:
          props.theme === "dark" ? Colors.darkBlue : Colors.Dark[5],
        color: theme.palette.common.white,
      },
      // hide last border
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    }));

    const rowCount = 10;
    const handleChange = (event, value) => {
      setPage(value);
    };

    const confirmDeleteCSVfileModalOpen = () => {
      let myModal = new window.bootstrap.Modal(
        document.getElementById("confirmDeleteCSVfileModal")
      );
      myModal.show();
    };

    const [data, setData] = useState({});

    function descendingComparator(a, b, orderBy) {
      const A = a[orderBy] ? a[orderBy].trim() : undefined;
      const B = b[orderBy] ? b[orderBy].trim() : undefined;
      if (B < A) {
        return -1;
      }
      if (B > A) {
        return 1;
      }
      return 0;
    }

    function getComparator(order, orderBy) {
      return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const useStyles = makeStyles({
      foo: {
        "& .MuiPagination-ul": { justifyContent: "end" },
        "& .MuiPaginationItem-text": {
          color: Colors.Dark[theme === "dark" ? 4 : 1],
        },
        "& .Mui-selected": {
          backgroundColor: Colors.Dark[4],
          color: Colors.darkBlue,
        },
      },
    });

    const titleColor = { color: theme === "dark" ? "#ffff" : Colors.Dark[1] };

    const classes = useStyles();
    const tableRef = React.useRef(null);

    useEffect(() => {
      const styleElement = document.createElement("style");
      styleElement.innerHTML =
        theme === "dark"
          ? `
       

        .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
            color: black !important;
            border: 1px solid #e5e5e5!important;
            
            background-color: #e5e5e5 !important;
            /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #e5e5e5), color-stop(100%, #e5e5e5))!important; */
            background: -webkit-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
            background: -moz-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
            background: -ms-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%) !important;
            background: -o-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
            background: linear-gradient(to bottom, #e5e5e5 0%, #e5e5e5 100%)!important;
          }

          .dataTables_wrapper .dataTables_paginate .paginate_button.current {
            color: black !important;
            border: 1px solid #C6C6C6!important;
            
            background-color: #C6C6C6 !important;
            /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fffcfc), color-stop(100%, #969292))!important; */
            background: -webkit-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
            background: -moz-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
            background: -ms-linear-gradient(top, #fffcfc 0%, #969292 100%) !important;
            background: -o-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
            background: linear-gradient(to bottom, #fffcfc 0%, #969292 100%)!important;
          }

          .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
            color: black !important;
            border: 1px solid #C6C6C6!important;
            
            background-color: #C6C6C6 !important;
            /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fffcfc), color-stop(100%, #969292))!important; */
            background: -webkit-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
            background: -moz-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
            background: -ms-linear-gradient(top, #fffcfc 0%, #969292 100%) !important;
            background: -o-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
            background: linear-gradient(to bottom, #fffcfc 0%, #969292 100%)!important;
          }


        `
          : `
            .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
                color: black !important;
                border: 1px solid #e5e5e5!important;
                
                background-color: #e5e5e5 !important;
                /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #e5e5e5), color-stop(100%, #e5e5e5))!important; */
                background: -webkit-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
                background: -moz-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
                background: -ms-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%) !important;
                background: -o-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
                background: linear-gradient(to bottom, #e5e5e5 0%, #e5e5e5 100%)!important;
              }

              .dataTables_wrapper .dataTables_paginate .paginate_button.current {
                color: black !important;
                border: 1px solid #C6C6C6!important;
                
                background-color: #C6C6C6 !important;
                /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fffcfc), color-stop(100%, #969292))!important; */
                background: -webkit-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                background: -moz-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                background: -ms-linear-gradient(top, #fffcfc 0%, #969292 100%) !important;
                background: -o-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                background: linear-gradient(to bottom, #fffcfc 0%, #969292 100%)!important;
              }
   
        `;
      document.head.appendChild(styleElement);

      function updateOrderAndDisplayStart(columnIndex, direction) {
        orderColumn = columnIndex;
        orderDirection = direction;

        table.order([[orderColumn, orderDirection]]).draw();
      }

      const storedOrderColumn = localStorage.getItem("orderColumn");
      const storedOrderDirection = localStorage.getItem("orderDirection");

      let orderColumn = storedOrderColumn ? parseInt(storedOrderColumn) : 0;
      let orderDirection = storedOrderDirection || "asc";
      const tableElement = $(tableRef.current);

      const getStatusColor = (status) => {
        switch (status) {
          case "1":
            return "success";
          case "2":
            return "secondary";
          case "3":
            return "danger";
          default:
            return "";
        }
      };

      const getStatusText = (status) => {
        switch (status) {
          case "1":
            return "SUCCESS";
          case "2":
            return "PENDING";
          case "3":
            return "REJECTED";
          default:
            return "";
        }
      };

      const table = tableElement.DataTable({
        autoWidth: true,
        responsive: true,
        retrieve: true,
        scrollX: true,
        order: [[1, "asc"]],

        lengthMenu: [8, 10, 15],

        data: rows,
        columns: [
          { title: t("Date"), data: "date" },
          { title: t("Company Name"), data: "company_name" },
          {
            title: t("File Name"),
            data: function (row) {
              return row.uploadedFileName === null ||
                row.uploadedFileName === ""
                ? row.file_name
                : row.uploadedFileName;
            },
          },
          {
            title: t("Repository File Name"),
            data: function (row) {
              // console.log(row);
              return `
                <div class='d-flex justify-content-between'>
                  ${row.reFfile_name} 
                  ${row.reFfile_name !== 'N/A' ? `
                    <div class='d-flex justify-content-end'>
                      <button class="btn btn-primary btn-sm" style="background-color: #4c5d75; border-color: #4c5d75;" 
                              data-rowId="${row.id}" 
                              onclick="handleRefDownload('${row.reFfile_name}')">
                        <i class="fa fa-download" title="Download repository file."></i>
                      </button>
                    </div>
                  ` : ''}
                </div>
              `;
            },
          },
                 
          
          { title: t("Created By"), data: "createdBy" },
          {
            title: t("Status"),
            data: "status",
            createdCell: function (td, cellData, rowData, row, col) {
              const badgeBackgroundColor = getStatusColor(cellData);
              $(td).html(
                `<span class="badge bg-${badgeBackgroundColor}">${getStatusText(
                  cellData
                )}</span>`
              );
            },
          },
          {
            title: t("Action"),
            // flex: 1,
            render: function (data, type, row) {
              const isStatus13 = row.status === "1" || row.status === "3";
              let btns = "";

              if (isStatus13) {
                btns += `
                      <div>
                        <button type="button" class="btn btn-primary btn-sm" style="background-color: #4c5d75; border-color: #4c5d75;" 
                        data-rowData=${JSON.stringify(row.id)}
                        onClick="handleDownload(event)">
                          ${t("Download")}
                        </button>
                        <button  
                         type="button" class="btn btn-danger btn-sm m-1 delete"
                          style="border: 1px solid #5E5E5E; cursor: pointer;"
                         data-rowData=${JSON.stringify(row.id)}
                         onclick="handleDelete(event)">
                          <i class="bi bi-trash-fill delete"  data-toggle="modal" data-target="#dataImportHistoryTable" title="Delete"></i>
                        </button>
                      </div>`;
              } else {
                btns += `
                      <div>
                        <button type="button" class="btn btn-sm m-0" style="color: #fff; background-color: #4c5d75; border-color: #4c5d75; width: 82px; height: 30px;" data-rowData=${JSON.stringify(
                          row.id
                        )}
                        onclick="handleClick(event)" >
                          ${t("View")}
                        </button>
                        <button type="button" class="btn btn-danger btn-sm m-1" 
                        style="border: 1px solid #5E5E5E; cursor: pointer;" data-rowData=${JSON.stringify(
                          row.id
                        )}
                        onclick="handleDelete(event)">
                          <i class="bi bi-trash-fill delete" 
                          data-toggle="modal"
                          data-target="#dataImportHistoryTable"title="Delete"></i>
                        </button>
                      </div>`;
              }

              return btns;
            },
          },
        ],
        paging: true,

        drawCallback: function (settings) {
          // console.log($('# ').DataTable().page.info());
          // console.log(settings.oSavedState);
        },
        language: {
          emptyTable:rows.length === 0 && !loading &&` 
                  <div class="no-data-found " style="color: ${invertDark(
                    theme
                  )};  align-items: center; justify-content: center">
                  <i class="fa fa-exclamation-triangle me-2" style="color: orange;"></i>
                  ${t("No Data Found")}
                </div>
              `,
          // info: t("Showing _START_ to _END_ of _TOTAL_ entries"),
          // infoEmpty: t("Showing 0 to 0 of 0 entries"),
        },
      });

      table.column(0).nodes()?.to$().css("width", "14%");
      table.column(1).nodes()?.to$().css("width", "14%");
      table.column(2).nodes()?.to$().css("width", "14%");
      table.column(3).nodes()?.to$().css("width", "20%");
      table.column(4).nodes()?.to$().css("width", "14%");
      table.column(5).nodes()?.to$().css("width", "14%");
      table.column(6).nodes()?.to$().css("width", "14%");


      table.cells().every(function () {
        $(this.node()).css("border-color", "");
      });

      const searchInput = $(".dataTables_filter input");
      searchInput.css("color", theme === "light" ? "black" : "white");

      const tableRows = $(".dataTables_wrapper tr");
      const tableColumns = $(".dataTables_wrapper td");

      tableRows.css("color", theme === "light" ? "black" : "#fff");
      tableColumns.css("color", theme === "light" ? "black" : "#fff");

      const searchlabel = $(".dataTables_filter label");
      searchlabel.css("color", theme === "light" ? "black" : "white");

      const entriesSelect = $(".dataTables_length select");
      entriesSelect.css(
        "background-color",
        theme === "light" ? "#fff" : "#202b60"
      );
      entriesSelect.css("color", theme === "light" ? "black" : "white");

      const entriesLabel = $(".dataTables_length label");
      entriesLabel.css("color", theme === "light" ? "black" : "white");

      const tableInfo = $(".dataTables_info");
      tableInfo.css("color", theme === "light" ? "#000" : "#fff");

      const paginate = $("#dataImportHistoryTable_paginate");
      paginate.css("color", theme === "light" ? "#000" : "#fff");

      $("#dataImportHistoryTable").on("draw.dt", function () {
        const currentPage = table.page.info().page;
        const rows = table.rows({ page: "current" }).nodes();
        const cells = $(rows).find("td");

        cells.css("color", theme === "light" ? "#000" : "#fff");
      });

      $("#dataImportHistoryTable").on("click", "tbody tr", function () {
        const data = table.row(this).data();
      });

      $("#dataImportHistoryTable").on("click", "tbody tr", function () {
        const data = table.row(this).data();
      });

      $("#dataImportHistoryTable").on("page.dt", function () {
        localStorage.setItem("currentPage", table.page.info().page);
      });

      table.cells().every(function () {
        $(this.node()).css(
          "border-color",
          theme === "dark" ? "rgb(211, 211, 211, 0.3)" : ""
        );
      });

      const storedCurrentPage = localStorage.getItem("currentPage");
      const storedSearch = localStorage.getItem("search");

      const currentPage = storedCurrentPage ? parseInt(storedCurrentPage) : 0;
      table.page(currentPage).draw(false);

      if (storedSearch) {
        table.search(storedSearch).draw();
      }

      table.on("page.dt", function () {
        localStorage.setItem("currentPage", table.page.info().page);
      });

      table.on("search.dt", function () {
        const search = table.search();
        localStorage.setItem("search", search);
      });

      const handlClick = (id, showTable, status, isSubmitted, rowData) => {
        history.push({
          pathname: `view_imports`,
          state: { id, showTable, status, isSubmitted, rowData },
        });
      };

      window.handleClick = function (event) {
        event.preventDefault();
        
        const rowId = event.currentTarget.dataset.rowdata;
        const rowData = rows.find((v) => v.id === rowId);
        const status = rowData.status;
        const isSubmitted = rowData.isSubmitted;
        handlClick(rowId, showTable, status, isSubmitted, rowData);
      };

      const handleDelete = (id, fileName,status) => {
        setData({ id, fileName,status });
      };

      window.handleDownload = function (event) {
        event.preventDefault();
        const rowId = event.target.dataset.rowdata;
        const rowdata = rows.find((v) => v.id === rowId);
        // console.log("rowData=====", rowdata);
        handleDownloadCSV(rowdata.file_name,rowdata.uploadedFileName);
      };

      window.handleRefDownload = function (refFileName) {
        // event.preventDefault();
        console.log("event=====", refFileName);
        // const rowId = event.target.dataset.rowdata;
        // const rowdata = rows.find((v) => v.id === rowId);
        // console.log("rowData=====", rowdata);
        handleDownloadRefFile(refFileName);
      };

      window.handleDelete = function (event) {
        event.preventDefault();
        const rowId = event.currentTarget.dataset.rowdata;
        const rowdata = rows.find((v) => v.id === rowId);
        console.log("rowData ======>",rowdata)
        const fileName = rowdata.file_name;
        const status = rowdata.status
        if(showTable === 'table4'){}
        handleDelete(rowId, fileName,status);
        confirmDeleteCSVfileModalOpen();
      };

      window.addEventListener("beforeunload", function () {
        localStorage.removeItem("orderColumn");
        localStorage.removeItem("orderDirection");
        localStorage.removeItem("currentPage");
      });

      window.addEventListener("load", function () {
        localStorage.removeItem("orderColumn");
        localStorage.removeItem("orderDirection");
        localStorage.removeItem("currentPage");
      });

      const storedPage = localStorage.getItem("currentPage");

      if (storedPage !== null) {
        const currentPage = parseInt(storedPage);
        if (!isNaN(currentPage)) {
          table.page(currentPage).draw("page");
        } else {
          table.page(0).draw("page");
        }
      } else {
        table.page(0).draw("page");
      }

      return () => {
        if ($.fn.DataTable.isDataTable("#dataImportHistoryTable")) {
          table.destroy();
        }
      };
    }, [theme, rows]);
    
    // if (rows.length === 0 ) {
    //   return <CO2_Loading />;
    // }

    return (
      <>
        {
          loading
           ? (
            <CO2_Loading />
          ) : 
            <div>
              <table
                ref={tableRef}
                id="dataImportHistoryTable"
                className="display cell-border hover stripe"
                style={{ width: "100%" }}
              ></table>
            </div>
          
        } 

        <ConfirmDeleteCSVfileModal
          id={data.id}
          companyId={companyId}
          // ref={modalRef}
          showTable={showTable}
          fileName={data.fileName}
          status={data.status}
        />
      </>
    );
  })
);
