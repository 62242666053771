import { inject, observer } from "mobx-react";
import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import {
    Pagination,
    PaginationItem,
    styled,
    TableSortLabel,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import { makeStyles } from "@mui/styles";
import { baseURL } from "../../network/URL";

export const CompanyListTable = inject("store")(
    observer(
        withRouter((props) => {
            const { theme, companyList } = props;
            const { _handleDeleteCompany, _handleEditCompany } = props.store.landing      

            const Key = [
                // "id",
                "name",
                "country",
                "edit",
                "action"
            ];
            
            const rows = companyList?.map((v) => {
                let data = {
                    id: v.id,
                    name: <span>
                        <img src={baseURL + v.logo} style={{paddingRight: '10px', width: '30px'}}/>
                        {v.name}
                    </span>,
                    country: v.country,
                    action: "delete",
                    edit: "edit"
                }
    
                return data;
            })

            const [page, setPage] = React.useState(1);
            const [order, setOrder] = React.useState("asc");
            const [orderBy, setOrderBy] = React.useState("");
            const [selectedIndex, setSelectedIndex] = React.useState("");

            const handleRequestSort = (event, property) => {
                const isAsc = orderBy === property && order === "asc";
                setOrder(isAsc ? "desc" : "asc");
                setOrderBy(property);
                console.log('property', property)
            };

            const headers = [
                { label: "Company Name", key: "name" },
                { label: "Country", key: "country" },
                { label: "", key: "edit"},
                { label: "", key: "action" }
                
            ];

            const StyledTableRow = styled(TableRow)(({ theme }) => ({
                cursor: "pointer",
                "&:nth-of-type(odd)": {
                    backgroundColor:
                        props.theme === "dark" ? Colors.darkBlue : Colors.Dark[5],
                    color: theme.palette.common.white,
                },
                // hide last border
                "&:last-child td, &:last-child th": {
                    border: 0,
                },
            }));

            const rowCount = 12;
            const handleChange = (event, value) => {
                setPage(value);
            };

            function EnhancedTableHead(props) {
                const {
                    order,
                    orderBy,
                    onRequestSort,
                    theme,
                    Key,
                    // _handleViewContract,
                } = props;
                const createSortHandler = (property) => (event) => {
                    onRequestSort(event, property);
                };
                return (
                    <TableHead>
                        <TableRow>
                            {headers.map((headCell, index) => (
                                <TableCell
                                    key={index}
                                    align={"left"}
                                    padding={headCell.disablePadding ? "none" : "normal"}
                                    sortDirection={orderBy === headCell.id ? order : false}
                                    style={{ background: theme === "dark" ? Colors.darkBlue : invertDark(theme) }}
                                >
                                    {headCell.label === "" || (
                                        <TableSortLabel
                                            style={{
                                                minWidth: 80,
                                                color:
                                                    orderBy === headCell.id
                                                        ? Colors.activeColor
                                                        : theme === "light"
                                                            ? "#F6F6F6"
                                                            : Colors.Dark[4],
                                            }}
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : "asc"}
                                            onClick={createSortHandler(Key[index])}
                                        >
                                            <span className="fw-bold">{headCell.label}</span>
                                        </TableSortLabel>
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                );
            }

            function descendingComparator(a, b, orderBy) {
                
                const A = a[orderBy] ? a[orderBy].trim() : undefined;
                const B = b[orderBy] ? b[orderBy].trim() : undefined;

                
                if (B < A) {
                    return -1;
                }
                if (B > A) {
                    return 1;
                }
                return 0;
            }

            function getComparator(order, orderBy) {
                return order === "desc"
                    ? (a, b) => descendingComparator(a, b, orderBy)
                    : (a, b) => -descendingComparator(a, b, orderBy);
            }

            const useStyles = makeStyles({
                foo: {
                    "& .MuiPagination-ul": { justifyContent: "end" },
                    "& .MuiPaginationItem-text": {
                        color: Colors.Dark[theme === "dark" ? 4 : 1],
                    },
                    "& .Mui-selected": {
                        backgroundColor: Colors.Dark[4],
                        color: Colors.darkBlue,
                    },
                },
            });

            const titleColor = { color: theme === "dark" ? "#ffff" : Colors.Dark[1] };

            const classes = useStyles();

            return (
                <React.Fragment>
                    <TableContainer
                        component={Paper}
                        style={{ background: "none" }}
                        sx={{ maxHeight: 450, }}
                    >
                        <Table
                            stickyHeader
                            size="small"
                            aria-label="a dense sticky table"
                        >
                            <EnhancedTableHead
                                // _handleViewContract={_handleViewContract}
                                Key={Key}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                theme={theme}
                            />
                            <TableBody>
                                {rows?.length > 0 &&
                                    rows
                                        ?.sort(getComparator(order, orderBy))
                                        ?.slice((page - 1) * rowCount, page * rowCount)
                                        ?.map((row, index) => (
                                            <StyledTableRow
                                            style={{ background : selectedIndex === row.email ? Colors.Dark[3] : ""}}
                                                key={index}
                                                
                                                sx={{
                                                    "&:last-child td, &:last-child th": { border: 0 },
                                                }}
                                            >
                                                {Object.values(row)
                                                    .filter((f) => typeof f != "number")
                                                    .map((d, x) => (
                                                        x != 0 && <TableCell
                                                            sx={{
                                                                color: invertDark(theme),
                                                                borderBottom: "none",
                                                            }}
                                                            key={x}
                                                        >   
                                                        
                                                            { d === 'edit' ? <>
                                                                <i className="bi bi-pencil-square" style={{ color: "#8EBBFF", cursor: 'pointer' }} onClick={() => _handleEditCompany(row)}></i>
                                                            </>
                                                            : d === 'delete' ? <>
                                                                <i className="bi bi-trash-fill" style={{ color: "#8EBBFF", cursor: 'pointer' }} onClick={() => _handleDeleteCompany(row.id)}></i>
                                                            </> : d}
                                                        </TableCell>
                                                    ))}
                                            </StyledTableRow>
                                        ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {
                        <div className="py-2">
                            <Pagination
                                size="small"
                                renderItem={(item) => (
                                    <PaginationItem
                                        {...item}
                                        sx={{ color: invertDark(theme) }}
                                        classes={{ selected: classes.selected }}
                                    />
                                )}
                                className={classes.foo}
                                count={
                                    rows?.length <= rowCount
                                        ? 1
                                        : Math.ceil(rows?.length / rowCount)
                                }
                                defaultPage={1}
                                page={page}
                                siblingCount={1}
                                boundaryCount={1}
                                onChange={handleChange}
                            />
                        </div>
                    }
                </React.Fragment>
            );
        })
    )
)