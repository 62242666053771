import { inject, observer } from "mobx-react";
import { Cookies } from "react-cookie";
import { invertDark } from "../../Assets/Config/color.config";
import Store from "../../Store";
import Site from "../../Assets/adsSites.json";
import jwt_decode from "jwt-decode";
import { baseIMGURL, baseURL } from "../../network/URL";
import { useState } from "react";
import user from "../../helper/localStorage";
import jwtDecode from "jwt-decode";

export const CompanySelector = inject("store")(

  observer((props) => {
    
    const { theme, label , certificateCompanycol} = props;

    const cookies = new Cookies();
    const token = cookies.get("accessToken");
    const site = user.getSelectedSite();
    let imgUrl = cookies.get("logo");

    const companyName = cookies.get("companyName");

    const companyId = Store.common.companyId;

    return ( console.log('companyName',companyName),
      <span
        className="py-2 d-flex align-items-center"
        style={{
          fontSize: 20,
          fontWeight: "lighter",
          color: invertDark(theme),
        }}
      >
        <strong>{label && label}</strong>

        {certificateCompanycol ? <span> {
          <div className="row" >
            <div>
              <span className="bg-white rounded mx-2"><img className="rounded" src={`${baseIMGURL + imgUrl}`} onError={(e) => {
                imgUrl = 'no_image.png';
              }} alt="icon" height={40} /></span>
            </div>
            <div className="fw-bold">
              {companyName.replace(/\s+/g, ' ').trim()}
            </div>
          </div>} </span> 
          :
          <div>
            <span className="bg-white rounded mx-2"><img className="rounded" src={`${baseURL + imgUrl}`} onError={(e) => {
              imgUrl = 'no_image.png';
            }} alt="icon" height={40} /></span>
            <span>
              {companyName.replace(/\s+/g, ' ').trim()}
            </span>
          </div>}
      </span>
    );
  })
);

