import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Colors, invertDark } from "../Assets/Config/color.config";

export const My_DatePicker = (props) => {
  const { id,
    height,
    selected,
    onChange,
    showMonthYearPicker,
    noTimeInput,
    dateFormat,
    disabled,
    showYearPicker,
    newDateFormat,
    maxDate,
    showTimeSelect } = props;
  return (
    <DatePicker
      disabled={disabled}
      id={id}
      showMonthYearPicker={showMonthYearPicker}
      showYearPicker={showYearPicker}
      selected={selected}
      maxDate={maxDate}
      onChange={onChange}
      customInput={<CustomInput height={height} />}
      showTimeInput={!noTimeInput}

      // dateFormat={dateFormat?dateFormat:"MMM/dd/yyyy  h:mm aa"}
      dateFormat={dateFormat ? dateFormat : "MMM/dd/yyyy"}

    />
  );
};

const CustomInput = forwardRef(({ value, onClick, height }, ref) => {
  const theme = localStorage.getItem("theme");
  return (
    <div
      className="d-flex justify-content-between align-items-center px-2 text-secondary border"
      style={{
        borderRadius: "4px",
        cursor: "pointer",
        // width: 150,
        height: height || 30,
        backgroundColor: theme === 'dark' && Colors.darkBlue,
      }}
      onClick={onClick}
    >
      <span style={{ color: invertDark(theme) }}>{value}</span>
      <i
        className="fas fa-calendar-alt"
        style={{
          color: theme === 'light' ? Colors.lightBlue : Colors.Dark[4],
        }}
      />
    </div>
  );
});

