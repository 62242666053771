import React from "react";
import { My_Button, My_Input } from "../../Tools";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router";
import { useHistory } from "react-router";
import Logo from "../../Assets/Images/pw_reset.png";
import { Cookies } from "react-cookie";
import jwt_decode from "jwt-decode";
import Store from "../../Store";

const PasswordReset = (props) => {
  const { errStyle, t, theme,userId } = props;
  console.log("userId =====>",userId)

  const {
    oldPassword,
    newPassword,
    confirmNewPassword,
    oldPasswordView,
    newPasswordView,
    _handleOldPasswordChange,
    _handleConfirmNewPasswordChange,
    _handleNewPasswordChange,
    _handleOldPasswordView,
    _handleNewPasswordView,
    _handleSavePasswordReset
  } = props.store.register;
  const { token, role } = Store.common;

  // const cookies = new Cookies();
  // const accessToken = cookies?.get("accessToken");

  const resetPassword = async () => {
    try {
      if ( userId) {
        // const token = jwt_decode(accessToken);
        // console.log("token ==============>",token)
        await _handleSavePasswordReset(userId);
      } else {
        console.error("Access token or userId is missing");
      }
    } catch (error) {
      console.error("Error while resetting password:", error);
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <form
          className="col-lg-4 col-md-8 col-sm-10 shadow p-4"
          style={{
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            borderRadius: "15px",
          }}
        >
          <div className="form-group text-center m-0">
            {/* <img src='https://www.google.com/imgres?q=reset%20password%20icon%20free&imgurl=https%3A%2F%2Fcdn-icons-png.flaticon.com%2F512%2F4992%2F4992489.png&imgrefurl=https%3A%2F%2Fwww.flaticon.com%2Ffree-icon%2Freset-password_4992489&docid=kWqBxJRqKbyseM&tbnid=FQ1izIE_gJJ3bM&vet=12ahUKEwiDzMDJqKiFAxW8SGwGHVUkAJkQM3oECFUQAA..i&w=512&h=512&hcb=2&ved=2ahUKEwiDzMDJqKiFAxW8SGwGHVUkAJkQM3oECFUQAA' alt="logo" /> */}
            <img src={Logo} alt="logo" />
            <p
              style={{
                fontSize: 28,
                fontWeight: "lighter",
                color: Colors.Dark[4],
              }}
            >
              Reset Your Password
            </p>

            <div className="py-3 text-left">
              <div className="pt-3">
                <My_Input
                  className="justify-content-center"
                  required={true}
                  id={"oldPassword"}
                  type={oldPasswordView ? "text" : "password"}
                  placeHolder={"Current Password"}
                  value={oldPassword}
                  onChange={(e) => {
                    _handleOldPasswordChange(e.target.value);
                  }}
                />
                <span
                  style={{
                    float: "right",
                    position: "relative",
                    marginTop: "-55px",
                    fontSize: "18px",
                    marginRight: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => _handleOldPasswordView()}
                >
                  <i
                    className={`fa fa-eye${
                      oldPasswordView ? "-slash" : ""
                    } py-4 text-secondary`}
                  />
                </span>
              </div>
              <div className="pt-3">
                <My_Input
                  required={true}
                  id={"newPassword"}
                  type={newPasswordView ? "text" : "password"}
                  placeHolder={"New Password"}
                  value={newPassword}
                //   disabled={!oldPassword}
                  onChange={(e) => {
                    _handleNewPasswordChange(e.target.value);
                  }}
                />
                <span
                  style={{
                    float: "right",
                    position: "relative",
                    marginTop: "-55px",
                    fontSize: "18px",
                    marginRight: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => _handleNewPasswordView()}
                >
                  <i
                    className={`fa fa-eye${
                      newPasswordView ? "-slash" : ""
                    } py-4 text-secondary`}
                  />
                </span>
              </div>

              <div className="pt-3">
                <My_Input
                  required={true}
                  id={"confirmNewPassword"}
                  type={newPasswordView ? "text" : "password"}
                  placeHolder={"Confirm New Password"}
                  value={confirmNewPassword}
                  onChange={(e) => {
                    _handleConfirmNewPasswordChange(e.target.value);
                  }}
                />
                <span
                  style={{
                    float: "right",
                    position: "relative",
                    marginTop: "-55px",
                    fontSize: "18px",
                    marginRight: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => _handleNewPasswordView()}
                >
                  <i
                    className={`fa fa-eye${
                      newPasswordView ? "-slash" : ""
                    } py-4 text-secondary`}
                  />
                </span>
              </div>
            </div>
            <hr
              style={{
                color: "#dadde1",
                backgroundColor: Colors.Dark[3],
                height: 0.5,
                borderColor: "#dadde1",
              }}
            />

            <My_Button
              text={"Save"}
              type={"button"}
              id={"save"}
              //   onClick={(e) => {
              //     _fetchRegister(history);
              //   }}
              style={{ width: "100%" }}
              disabled={!newPassword}
                onClick={resetPassword}
              //   rightIcon={
              //     show ? (
              //       <i className="fas fa-spinner fa-spin ml-2"></i>
              //     ) : (
              //       <i className="fa fa-sign-in-alt pl-2" />
              //     )
              //   }
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default inject("store")(observer(PasswordReset));
