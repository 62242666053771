import * as API from "./URL";
import Store from "../Store";

export const fetchCalculatedDashboardData = async (
  token,
  year,
  month,
  assets,
  companyId,
  userId,
  c_id,
  callback
) => {
  const data = { companyId, year, month, assets, userId, c_id };

  return fetch(API.CalculatedDashboardData, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const fetchFMSDashboardData = async (
  token,
  year,
  month,
  assets,
  callback
) => {
  const data = {
    year,
    month,
    assets,
  };

  return fetch(API.FMSDashboardData, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const fetchMonthlyCarbonData = async (
  token,
  companyId,
  userId,
  year,
  c_id,
  currentAssets,
  callback
) => {
  return fetch(
    `${API.MonthlyCarbonDataAPI}?companyId=${companyId}&userId=${userId}&year=${year}&c_id=${c_id}&assets=${currentAssets}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        "x-access-token": token,
      },
    }
  )
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const fetchDashboardData = async (
  token,
  companyId,
  year,
  month,
  assets,
  callback
) => {
  const { isAds } = Store.common;
  const data = isAds
    ? { siteId: 14, year, month }
    : { companyId, year, month, assets };
  return fetch(API.DashboardData, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const fetchCertificateLst = async (companyId, year, token, callback) => {
  return fetch(`${API.CertificateList}?companyId=${companyId}&year=${year}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      callback(null, data);
      console.log("data==>", data);
    })
    .catch((err) => callback(err, null));
};

export const fetchCertificateLstV2 = async (companyId, year, token, callback) => {
  return fetch(`${API.CertificateListV2}?companyId=${companyId}&year=${year}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      callback(null, data);
      console.log("data==>", data);
    })
    .catch((err) => callback(err, null));
};


export const fetchCertificateData = async (
  token,
  tntId,
  year,
  month,
  callback
) => {
  const data = { tntId, year, month };
  return fetch(`${API.CertificateData}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const fetchCompanyYears = async (companyId, token, callback) => {
  return fetch(`${API.companyYearlyData}?companyId=${companyId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const fetchStacsCertificates = async (companyId, token, callback) => {
  return fetch(`${API.stacsCertificatesAPI}?companyId=${companyId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      callback(null, data);
      console.log("data==>", data);
    })
    .catch((err) => callback(err, null));
};

export const fetchDataImportAccess = async (companyId, token, callback) => {
  return fetch(`${API.getDataImportAccess}?companyId=${companyId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      callback(null, data);
      console.log("data==>", data);
    })
    .catch((err) => callback(err, null));
};
