import { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";

export const PasswordValidationModal = inject("store")(
  observer((props) => {
    const {t} = useTranslation("admin-panel")
    const { theme, showModal, setShowModal } = props;

    const handleClose = () => {
      setShowModal(false);
    };

    return (
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Password Validation Error")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("Password must have at least 8 characters, including alphabet, number, and special characters.")}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("Close")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  })
);
