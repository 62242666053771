// import { Colors, invertDark } from "../../Assets/Config/color.config";
// import { baseURL } from "../../network/URL";
// import { inject, observer } from "mobx-react";
// import { CO2_Loading } from "../../Assets/CO2_Loading";
// import Store from "../../Store";
// import { useState } from "react";

// export const CompanyListWithChildCompanies = (props) => {
//   const {
//     _handleClick,
//     theme,
//     _handleMouseHover,
//     hoverId,
//     level,
//     filteredList,
//     searchTerm,
//   } = props;
//   const { role } = Store.common;

//   return (
//     <>
//       <div className="clistwithchilcompany">
//         {searchTerm ? (
//           filteredList.map((v, k) => (
//             <div key={k} id={"card" + k} className="col-12 mt-2 me-5">
//               <div
//                 className="rounded py-2 px-2 d-flex row-12 companyCard"
//                 onMouseEnter={() => _handleMouseHover(v.id)}
//                 onMouseLeave={() => _handleMouseHover(v.id)}
//                 style={{
//                   background: theme === "light" ? "#5c5959" : Colors.darkBlue,
//                   cursor: "pointer",
//                   color:
//                     (theme === "dark" || theme === "light") &&
//                     v.id === hoverId &&
//                     v.company_status === 1
//                       ? "#fff"
//                       : hoverId === null
//                       ? "#fff"
//                       : "#fff",
//                   opacity: v.id === hoverId ? 1 : hoverId === null ? 1 : 0.55,
//                   fontSize: "large",
//                   width: "100%",
//                   marginLeft: level
//                     ? 0
//                     : v.level
//                     ? `${Number(v.level) * 3}0px`
//                     : 0,
//                   border:
//                     role === "super_admin"
//                       ? hoverId === v.id
//                         ? v.company_status === "1"
//                           ? "1px solid green"
//                           : "1px solid red"
//                         : "none"
//                       : "",
//                   // borderRadius: '8px'
//                 }}
//                 onClick={() =>
//                   _handleClick(
//                     { target: { id: v.companyId } },
//                     v.name,
//                     v.logo,
//                     v.data_import
//                   )
//                 }
//                 id={v.id}
//               >
//                 {role === "super_admin" && (
//                   <div
//                     className="col-md-1 mt-1"
//                     style={{ paddingLeft: "13px" }}
//                   >
//                     <i
//                       class="bi bi-circle-fill text-center"
//                       style={{
//                         color: v.company_status === "1" ? "green" : "red",
//                         fontSize: 12,
//                       }}
//                     ></i>
//                   </div>
//                 )}
//                 <div
//                   id={v.id}
//                   className="d-flex justify-content-between align-items-center pe-2 col-md-11"
//                 >
//                   {v.name}
//                   {/* <img
//                       className="p-2"
//                       style={{ background: "#fff", borderRadius: 5 }}
//                       height="40px"
//                       src={`${baseURL + v.logo}`}
//                     /> */}
//                 </div>
//               </div>
//             </div>
//           ))
//         ) : props.companyList ? (
//           props.companyList?.map((v, k) => (
//             <div key={k} id={"card" + k} className="col-12 mt-2 me-5">
//               <div
//                 className="row-12  rounded py-2 px-2 d-flex companyCard align-items-center justify-content-between"
//                 onMouseEnter={() => _handleMouseHover(v.id)}
//                 onMouseLeave={() => _handleMouseHover(v.id)}
//                 style={{
//                   background: theme === "light" ? "#5c5959" : Colors.darkBlue,
//                   cursor: "pointer",
//                   color:
//                     (theme === "dark" || theme === "light") && v.id === hoverId
//                       ? "#fff"
//                       : hoverId === null
//                       ? "#fff"
//                       : "#fff",
//                   opacity: v.id === hoverId ? 1 : hoverId === null ? 1 : 0.55,
//                   fontSize: "large",
// marginLeft: level
//   ? 0
//   : v.level
//   ? `${Number(v.level) * 3}0px`
//   : 0,
//                   border:
//                     role === "super_admin"
//                       ? hoverId === v.id
//                         ? v.company_status === "1"
//                           ? "1px solid green"
//                           : "1px solid red"
//                         : "none"
//                       : "",
//                   // borderRadius: '8px'
//                 }}
//               >
//                 <div
//                   className="d-flex col-md-11 "
//                   onClick={() =>
//                     _handleClick(
//                       { target: { id: v.companyId } },
//                       v.name,
//                       v.logo,
//                       v.data_import
//                     )
//                   }
//                   id={v.id}
//                 >
//                   {role === "super_admin" && (
//                     <div className="col-md-1 mt-1 text-center">
//                       <i
//                         class="bi bi-circle-fill "
//                         style={{
//                           color: v.company_status === "1" ? "green" : "red",
//                           fontSize: 12,
//                         }}
//                       ></i>
//                     </div>
//                   )}
// <div
//   id={v.id}
//   className="text-start align-items-center  col-md-11"
//   // style={{paddingLeft:30}}
// >
//   <img
//     className="p-2 me-3 "
//     style={{ background: "#fff", borderRadius: 5 }}
//     width="40px"
//     height="40px"
//     src={`${baseURL + v.logo}`}
//   />
//   {v.name}
//   {/* <img
//     className="p-2"
//     style={{ background: "#fff", borderRadius: 5 }}
//     width="40px"
//     height="40px"
//     src={`${baseURL + v.logo}`}
//   /> */}
// </div>
//                 </div>
//                 <div className="col-md-1 align-items-center justify-content-center ">
//                   <DropdownToggle v={v} />
//                   {/* <span><i class="bi bi-plus-lg"></i></span> */}
//                 </div>
//               </div>

//               {v.childCompanies && v.childCompanies.length ? (
//                   <CompanyListWithChildCompanies
//                     companyList={v.childCompanies}
//                     _handleMouseHover={_handleMouseHover}
//                     hoverId={hoverId}
//                     theme={theme}
//                     _handleClick={_handleClick}
//                   />
//                 ) : null}
//             </div>
//           ))
//         ) : (
//           <CO2_Loading />
//         )}
//       </div>
//     </>
//   );
// };

// const DropdownToggle = (props) => {
//   const {
//     _handleClick,
//     theme,
//     _handleMouseHover,
//     hoverId,
//     level,
//     filteredList,
//     searchTerm,
//   } = props;
//   const { role } = Store.common;
//   const { v } = props;

//   const [isOpen, setIsOpen] = useState(false);

//   const toggleDropdown = () => {
//     setIsOpen(!isOpen);
//   };

//   return (
//     <>
//       <span onClick={toggleDropdown} style={{ cursor: "pointer" }}>
//         <i className={`bi ${isOpen ? "bi-dash-lg" : "bi-plus-lg"}`}></i>
//       </span>
//       {/* {isOpen ? (
//         v.childCompanies && v.childCompanies.length ? (
//           <CompanyListWithChildCompanies
//             companyList={v.childCompanies}
//             _handleMouseHover={_handleMouseHover}
//             hoverId={hoverId}
//             theme={theme}
//             _handleClick={_handleClick}
//           />
//         ) : (
//           ""
//         )
//       ) : null} */}
//     </>
//   );
// };

// export default DropdownToggle;

//new
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { baseURL } from "../../network/URL";
import { inject, observer } from "mobx-react";
import { CO2_Loading } from "../../Assets/CO2_Loading";
import Store from "../../Store";
import { useState } from "react";

export const CompanyListWithChildCompanies = (props) => {
  const {
    _handleClick,
    theme,
    _handleMouseHover,
    hoverId,
    level,
    filteredList,
    searchTerm,
    companyList,
  } = props;
  const { role } = Store.common;
  console.log("level ======>", level);

  const [expandedCompanies, setExpandedCompanies] = useState({});

  const toggleCompanyExpansion = (companyId) => {
    setExpandedCompanies((prevState) => ({
      ...prevState,
      [companyId]: !prevState[companyId],
    }));
  };

  const renderCompany = (v, k) => {
    const isExpanded = expandedCompanies[v.id];
    return (
      <div key={k} id={"card" + k} className="col-12 mt-2 me-5">
        <div
          className="rounded py-2 px-2 d-flex row-12 companyCard"
          onMouseEnter={() => _handleMouseHover(v.id)}
          onMouseLeave={() => _handleMouseHover(v.id)}
          style={{
            background: theme === "light" ? "#E0E0E0" : Colors.darkBlue,
            cursor: "pointer",
            color:
              (theme === "dark" || theme === "light") &&
              v.id === hoverId &&
              v.company_status === 1
                ? "#fff"
                : hoverId === null
                ? "#fff"
                : "#fff",
            opacity: v.id === hoverId ? 1 : hoverId === null ? 1 : 0.55,
            fontSize: "large",
            width: "100%",
            // marginLeft:
            marginLeft: level ? 0 : v.level ? `${Number(v.level) * 3}0px` : 0,

            // marginLeft: `${level * 3}px`,
            border:
              role === "super_admin"
                ? hoverId === v.id
                  ? v.company_status === "1"
                    ? "1px solid green"
                    : "1px solid red"
                  : "none"
                : "",
          }}
          onClick={() =>
            _handleClick(
              { target: { id: v.companyId } },
              v.name,
              v.logo,
              v.data_import,
              v.company_type
            )
          }
          id={v.id}
        >
          {role === "super_admin" ? (
            <div className="col-md-1 mt-1" style={{ paddingLeft: "13px" }}>
              <i
                className="bi bi-circle-fill text-center"
                style={{
                  color: v.company_status === "1" ? "green" : "red",
                  fontSize: 12,
                }}
              ></i>
            </div>
          ) : (
            <div className="col-md-1 mt-1" style={{ paddingLeft: "10px" }}>
              <i
                className="bi bi-circled-fill text-center"
                style={{
                  color: v.company_status === "1" ? "green" : "red",
                  fontSize: 12,
                }}
              ></i>
            </div>
          )}
          <div
            id={v.id}
            className="d-flex justify-content-between align-items-center pe-2 col-md-11"
          >
            <div
              className="d-flex align-items-center "
              style={{ color: theme === "dark" ? "" : "black" }}
            >
              <img
                className="p-2 me-3"
                style={{ background: "#fff", borderRadius: 5 }}
                height="40px"
                width="40px"
                src={`${baseURL + v.logo}`}
                alt={`${v.name} logo`}
              />
              {v.name}
            </div>
            {role === "super_admin" || role === "admin" ? (
              <div
                className=""
                onClick={(e) => {
                  e.stopPropagation();
                  toggleCompanyExpansion(v.id);
                }}
              >
                {v.childCompanies && v.childCompanies.length > 0 && (
                  <span
                    style={{
                      cursor: "pointer",
                      paddingLeft: "30px",
                      paddingRight: "0px",
                      marginRight: level
                        ? 0
                        : v.level
                        ? `${Number(v.level) * 3}0px`
                        : 0,
                    }}
                  >
                    <i
                      style={{ color: "#2962FF" }}
                      // className={`bi ${
                      //   isExpanded ? "bi-dash-lg " : "bi-plus-lg"
                      // }`}
                      className={`bi ${
                        isExpanded ? "bi-caret-up-fill " : "bi-caret-down-fill"
                      }`}
                    ></i>
                  </span>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {isExpanded && v.childCompanies && v.childCompanies.length > 0 && (
          <CompanyListWithChildCompanies
            companyList={v.childCompanies}
            _handleMouseHover={_handleMouseHover}
            hoverId={hoverId}
            theme={theme}
            _handleClick={_handleClick}
            // level={Number(level) + 1}
          />
        )}
      </div>
    );
  };

  return (
    <div className="clistwithchilcompany">
      {searchTerm ? (
        filteredList.map(renderCompany)
      ) : companyList ? (
        companyList.map(renderCompany)
      ) : (
        <CO2_Loading />
      )}
    </div>
  );
};

export default CompanyListWithChildCompanies;
