/* eslint-disable react/jsx-pascal-case */
import * as React from "react";
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import userImage from "../../Assets/Images/icons/user.png";
import { getUserStatusAPI } from "../../network/URL";
import { My_Button, My_Input, Selector } from "../../Tools";
import { ConfirmDeleteModal } from "./ConfirmDeleteModal";
import { UserUnitModal } from "./UserUnitModal";
import common from "../../Store/common";
import { Modal } from "bootstrap";
import { useTranslation } from "react-i18next";
import { Cookies } from "react-cookie";

export const EditUserView = inject("store")(
  observer((props) => {
    const { t } = useTranslation("admin-panel");

    const {
      setEditUser,
      companies,
      editUserId,
      userList,
      getUserPlan,
      userPlanOptions,
      userStatusOptions,
      setUserPlanId,
      setUserStatusId,
      _handleEditTextChange,
      _handleSubmitEdit,
      _handleUpdatePlan,
      _handleUpdateUserStatus,
      editTempData,
      getUserStatus,
      _fetchCompanyList,
      userUnit,
      _handleConfirmBeforeSubmitEdit,
      companyId,
      _handleResetPasswordSendEmail,
      _fetchUserList
    } = props.store.adminPanel;
    // console.log("companyId ------>",companyId)

    const { fetchLogout } = props.store.login;

    const { theme } = props;
    const { role } = props.store.common;
    const { userId } = props.store.common;
    const cookies = new Cookies();
    const token = cookies.get("accessToken");
    const {
      password,
      newPassword,
      confirmNewPassword,
      newPasswordView,
      oldPasswordView,
      oldPassword,
      _handleNewPasswordChange,
      _handleOldPasswordChange,
      _handleOldPasswordView,
      _handleNewPasswordView,
      _handleConfirmNewPasswordChange,
      _handleAdminPasswordReset,
      _handleResetUserPassword
    } = props.store.register;

    const { _handleFetchCompanyUnitsForAssign, selectedVehicle } =
      props.store.dataEntry;

    const user = userList.find((v) => v.id === editUserId);
    // console.log("in the user id ===>",user.id)
    // console.log(" user  ===>",user)


    useEffect(() => {
      getUserPlan();
      setSelectedCompany(user.companyId);
      setUserPlanId(user.userPlanId);
    }, [editUserId]);

    useEffect(() => {
      getUserStatus();
      _fetchCompanyList("admin", companyId);
    }, []);

    useEffect(() => {
      _handleFetchCompanyUnitsForAssign(user.companyId, user.id, 0);
    }, [user.companyId, user.id, editUserId]);

    const CompanyOptions = companies.map((v) => ({
      value: v?.id,
      label: v?.name,
    }));

    const [selectedCompany, setSelectedCompany] = React.useState(user.name);
    const [currentCompany, setUpdateCompany] = React.useState(user.name);

    // CompanyOptions.unshift({ value: 'All', label: 'All' })

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(!open);
    const handleClose = () => setOpen(false);

    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      p: 4,
    };

    const existingCompany = CompanyOptions.find(
      (v) => v.label === selectedCompany
    );

    const updatedCompany = companies.find(
      (v) => v.id === editTempData.companyId
    );

    const checkUserUnit = userUnit.find((v) => v.id === editUserId);

    const resetPassword = async () => {
      try {
        await _handleAdminPasswordReset(token, user.id);
        setEditUser(null);
      } catch (error) {
        console.error(
          "An error occurred while resetting the password:",
          error.message
        );
      }
    };

    if (user) {
      return (
        <div id="editView" style={{ height: "67vh", overflowY: "auto" }}>
          <div className="container py-3">
            <div style={{ fontSize: 18, fontWeight: "bold" }} className="pb-3">
              {t("User Info")}
            </div>
            <div className="row pb-2">
              <span className="col-3">{t("Company")}</span>
              <span className="col-1">:</span>
              <span className="col">
                <Selector
                  options={CompanyOptions}
                  menuListColor={Colors.darkBlue}
                  value={CompanyOptions.find(
                    (v) => v.value === selectedCompany
                  )}
                  _handleSelect={(e) => {
                    _handleConfirmBeforeSubmitEdit(user.id, (data) => {
                      // const oldcompanyunit = data.find(v=> v.company === selectedCompany, userId)
                      // const companyunit = data.find((v)=> v.company === e.label)

                      const myModal = new Modal("#userunitconfirmModal");

                      if (data?.length) myModal.show();
                      setSelectedCompany(e.value);

                      _handleEditTextChange(e, user.id, "companyId");
                    });
                  }}
                />
              </span>{" "}
              <UserUnitModal theme={theme} selectedVehicle={selectedVehicle} />
            </div>
            <div className="row pb-2">
              <span className="col-3">{t("Name")}</span>
              <span className="col-1">:</span>
              <span className="col">
                <My_Input
                  id="username"
                  onChange={(e) =>
                    _handleEditTextChange(e, user.id, "username")
                  }
                  height="38px"
                  value={editTempData?.username}
                ></My_Input>
              </span>
            </div>
            <div className="row pb-2">
              <span className="col-3">{t("Email")}</span>
              <span className="col-1">:</span>
              <span className="col">
                <My_Input
                  id="email"
                  onChange={(e) => _handleEditTextChange(e, user.id, "email")}
                  height="38px"
                  value={editTempData?.email}
                ></My_Input>
              </span>
            </div>
            {/* <div className="row pb-2">
                        <span className="col-3">Company</span>
                        <span className="col-1">:</span>
                        <span className="col"><My_Input height='38px' value={user.companyName}></My_Input></span>

                    </div> */}
            <div className="row pb-2">
              <span className="col-3">{t("Role")}</span>
              <span className="col-1">:</span>
              <span className="col">
                <label className="pe-3" htmlFor="admin">
                  <input
                    className="me-1"
                    id="admin"
                    type="radio"
                    onClick={(e) => _handleEditTextChange(e, user.id, "role")}
                    value="admin"
                    name="role"
                    checked={editTempData.role === "admin" ? "checked" : ""}
                    height="38px"
                  ></input>
                  {t("Admin")}
                </label>
                <label className="pe-3" htmlFor="user">
                  <input
                    className="me-1"
                    id="user"
                    type="radio"
                    onClick={(e) => _handleEditTextChange(e, user.id, "role")}
                    value="user"
                    name="role"
                    checked={editTempData.role === "user" ? "checked" : ""}
                    height="38px"
                  ></input>
                  {t("User")}
                </label>
                {role === "super_admin" && (
                  <label className="pe-3" htmlFor="operator">
                    <input
                      className="me-1"
                      id="operator"
                      type="radio"
                      onClick={(e) => _handleEditTextChange(e, user.id, "role")}
                      value="operator"
                      name="role"
                      checked={
                        editTempData.role === "operator" ? "checked" : ""
                      }
                      height="38px"
                    ></input>
                    {t("Ops Admin")}
                  </label>
                )}
                <label htmlFor="driver">
                  <input
                    className="me-1"
                    id="driver"
                    type="radio"
                    onClick={(e) => _handleEditTextChange(e, user.id, "role")}
                    value="driver"
                    name="role"
                    checked={editTempData.role === "driver" ? "checked" : ""}
                    height="38px"
                  ></input>
                  {t("Driver")}
                </label>
              </span>
            </div>
            <My_Button
              customColor={theme === "dark" ? "#4c5d75" : "#198754"}
              customHoverColor={theme === "dark" ? "#70839e" : "#1dad6b"}
              onClick={() =>
                _handleSubmitEdit(
                  {
                    companyId: editTempData.companyId,
                    username: editTempData.username,
                    email: editTempData.email,
                    role: editTempData.role,
                    id: editTempData.id,
                  },
                  user.id
                )
              }
              className="w-100 mt-2"
              text={t("Save")}
            />

            {/* <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#userunitconfirmModal">
                            User Unit Confirm Box
                        </button> */}

            {/* <UserUnitModal theme={theme}/> */}
          </div>

          <div className="container">
            {userId === editUserId ? (
              <>
                <div
                  className="py-3"
                  style={{
                    borderTop: "1px solid rgb(255,255,255,0.1)",
                    fontSize: 18,
                    fontWeight: "bold",
                  }}
                >
                  {t("Reset Password")}
                </div>
                <div className="row pb-2">
                  <span className="col-3">{t("Current Password")}</span>
                  <span className="col-1">:</span>
                  <span className="col">
                    <div className="w-100 ">
                      <My_Input
                        required={true}
                        id={"oldPassword"}
                        type={oldPasswordView ? "text" : "password"}
                        placeHolder={"Current Password"}
                        value={oldPassword}
                        onChange={(e) => {
                          _handleOldPasswordChange(e.target.value);
                        }}
                      />
                      <span
                        style={{
                          float: "right",
                          position: "relative",
                          marginTop: "-55px",
                          fontSize: "18px",
                          marginRight: "20px",
                          cursor: "pointer",
                        }}
                        onClick={() => _handleOldPasswordView()}
                      >
                        <i
                          className={`fa fa-eye${oldPasswordView ? "-slash" : ""
                            } py-4 text-secondary`}
                        />
                      </span>
                    </div>
                  </span>
                </div>
                <div className="row pb-1">
                  <span className="col-3">{t("New Password")}</span>
                  <span className="col-1">:</span>
                  <span className="col">
                    <div className="w-100 ">
                      <My_Input
                        required={true}
                        id={"newPassword"}
                        type={newPasswordView ? "text" : "password"}
                        placeHolder={"New Password"}
                        value={newPassword}
                        onChange={(e) => {
                          _handleNewPasswordChange(e.target.value);
                        }}
                      />
                      <span
                        style={{
                          float: "right",
                          position: "relative",
                          marginTop: "-55px",
                          fontSize: "18px",
                          marginRight: "20px",
                          cursor: "pointer",
                        }}
                        onClick={() => _handleNewPasswordView()}
                      >
                        <i
                          className={`fa fa-eye${newPasswordView ? "-slash" : ""
                            } py-4 text-secondary`}
                        />
                      </span>
                    </div>
                  </span>
                </div>

                <My_Button
                  customColor={theme === "dark" ? "#4c5d75" : "#198754"}
                  customHoverColor={theme === "dark" ? "#70839e" : "#1dad6b"}
                  className="w-100 my-3 "
                  text={t("Reset Password")}
                  onClick={() => _handleResetUserPassword(editUserId)}
                />
              </>
            ) : null}
            {role === 'super_admin' && userId !== editUserId ?
              <My_Button
                customColor={theme === "dark" ? "#4c5d75" : "#198754"}
                customHoverColor={theme === "dark" ? "#70839e" : "#1dad6b"}
                className="w-100"
                text={t("Reset Password")}
                onClick={() => _handleResetPasswordSendEmail(editUserId)}
              />
              :
              null
            }
            {/* <div className="mt-3" style={{ background: 'rgb(255,255,255,0.1)', height: 1 }}></div> */}
          </div>

          <div className="container">
            {role === "super_admin" ? (
              <>
                <div
                  className="py-3"
                  style={{
                    borderTop: "1px solid rgb(255,255,255,0.1)",
                    fontSize: 18,
                    fontWeight: "bold",
                  }}
                >
                  {t("User Plan")}
                </div>
                <div>
                  {t("Plan Name")} :
                  {userPlanOptions.length > 0 && (
                    <Selector
                      menuListColor="black"
                      value={userPlanOptions.find(
                        (v) => v.value === user.userPlanId
                      )}
                      _handleSelect={(e) => {
                        setUserPlanId(e.value);
                        _handleUpdatePlan(user.id);
                      }}
                      options={userPlanOptions}
                    />
                  )}
                </div>

                {/* <div className="py-2">
                                User Status:<Selector menuListColor='black' value={userStatusOptions?.find(v => v.value === userStatusId)} _handleSelect={(e) => {setUserStatusId(e.value) 
                                _handleUpdateUserStatus(user.id)}} 
                                options={userStatusOptions} />
                            </div> */}
                <div>
                  {t("User Status")} :
                  <Selector
                    menuListColor="black"
                    options={userStatusOptions}
                    value={userStatusOptions.find(
                      (v) => v.value == user.status
                    )}
                    _handleSelect={(e) => {
                      setUserStatusId(e.value);
                      _handleUpdateUserStatus(user.id);
                    }}
                  />
                </div>
              </>
            ) : null}

            <span data-bs-toggle="modal" data-bs-target="#deleteModal">
              {user.id === userId ? (
                ""
              ) : (
                <My_Button
                  customColor={"#610303"}
                  style={{ backgroundColor: "#610303" }}
                  customHoverColor={theme === "light" && "#610303"}
                  className="w-100 my-3 "
                  text={t("Delete")}
                  onClick={() => {
                    handleOpen();
                  }}
                />
              )}
            </span>

            <ConfirmDeleteModal
              theme={theme}
              user={user}
              setEditUser={setEditUser}
            />

            {/* <div className="mt-3" style={{ background: 'rgb(255,255,255,0.1)', height: 1 }}></div> */}
          </div>
        </div>
      );
    } else return "hi";
  })
);
