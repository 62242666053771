/* eslint-disable react/jsx-pascal-case */
import { inject, observer } from "mobx-react";
import { UnitCountChart } from "./UnitCountChart";
import React, { useEffect, useState } from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { My_Button, Selector } from "../../Tools";
import { useHistory } from "react-router-dom";
import { ResponsiveContainer } from "recharts";

export const UnitCount = inject("store")(
  observer((props) => {
    const { theme ,seletedAssets} = props;
    // console.log("selectedassets ====>",seletedAssets)
    const cardStyle = {
      height:400,
      background: Colors[theme].background,
      boxShadow: "2px 4px 11px rgba(0,0,0,0.25)",
      padding: "10px",
      borderRadius: "15px",
      marginBottom: 8,
      color: invertDark(theme),
      fontSize: 12,
    };

    const history = useHistory();
    const [width, setWidth] = useState(window.innerWidth);

    const WindowSize = () => {
      setWidth(window.innerWidth);
    };
  
    useEffect(() => {
      window.addEventListener("resize", WindowSize);
      return () => {
        window.removeEventListener("resize", WindowSize);
      };
    }, []);

    const { _handleSelectAssets } = props.store.assetProfile;

    const filterOptions = [
      {
        value: "all",
        label: "All",
      },
      {
        value: "ice",
        label: "ICEs",
      },
      {
        value: "ev",
        label: "EVs",
      },
    ];

    const {
      selectedUnitType,
      canbusAndGps,
      gps,
      noCanbusAndGps,
      _handleUnitTypeSelect,
      allUnits,
      iceUnits,
      evUnits,
    } = props.store.dashboard;



    return (
      <div style={cardStyle}>

        <div
          className="d-flex flex-row justify-content-between"
          style={{ alignItems: "center" }}
        >
          <div className="d-flex" style={{ alignItems: "center" }}>
            <Selector
              isSearchable={false}
              options={filterOptions}
              value={
                filterOptions.filter((a) => {
                  return a.value === selectedUnitType;
                })[0]
              }
              _handleSelect={(e) => {
                _handleUnitTypeSelect(e.value);
              }}
            />

            {/* <span className="ps-3 " style={{fontSize:"14px"}}>
              
              {seletedAssets == 0 ? "All":seletedAssets =="vehicle"?"Vehicles":seletedAssets =="equipment"? "Equipment" :"Infrastructure"}
              </span> */}
          </div>
          <div className="d-flex flex-row justify-content-between">
          <div className="btn-group btn-group-lg p-1">
            <div
            className={theme ==="dark" ?'btn-unit':'btn-unit-light'}
            type='button'
              style={{ fontSize: 12,backgroundColor: theme !=='dark'? '#c6c6c6':'' }}
              onClick={() => {
                history.push("/asset_profile_management");
                _handleSelectAssets("vehicle");
              }}
            >
              <span className="">View Details</span>
              
              </div>
              </div>
          </div>
        </div>
        <div 
        className={width < 960 ?"unitcountResponsive":"unitcount"}
        // "d-flex unitcountResponsive"
        style={{height:240}}>
        {/* <ResponsiveContainer> */}
        <UnitCountChart selectedUnitType={selectedUnitType} theme={theme} />
        {/* </ResponsiveContainer> */}
        </div>

        <div className="d-flex flex-row justify-content-between py-1">
          <div className="d-flex justify-content-center">
            <canvas
              style={{
                width: "18px",
                height: "18px",
                background: "#94E7A9",
              }}
            />
            <span className="ps-2" style={{ color: "#94E7A9" }}>
              CANBus & GPS
            </span>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between py-1">
          <div className="d-flex justify-content-center">
            <canvas
              style={{
                width: "18px",
                height: "18px",
                background: "#24AAAF",
              }}
            />
            <span className="ps-2" style={{ color: "#24AAAF" }}>
              Only GPS
            </span>
          </div>
        </div>

        <div className="d-flex flex-row justify-content-between py-1">
          <div className="d-flex justify-content-center">
            <canvas
              style={{
                width: "18px",
                height: "18px",
                background: "#687A98",
              }}
            />
            <span className="ps-2" style={{ color: "#687A98" }}>
              No CANBus & GPS
            </span>
          </div>
        </div>
      </div>
    );
  })
);
