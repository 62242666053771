import { useState } from "react";
import { Colors } from "../../Assets/Config/color.config";
import { createContract } from "../../network/fetchAdmin";
import { Selector } from "../../Tools";
import { Cookies } from "react-cookie";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export const CreateContractModal = (props) => {
  const {t} = useTranslation("admin-panel")
  const cookies = new Cookies();
  const token = cookies.get("accessToken");
  // const {token} = props;
  const [type, setType] = useState();

  const { selectedRow } = props;

  const _handleCreateContract = () => {
    const data = {
      name: selectedRow.name,
      registrationNumber: selectedRow.id,
      countryOfIncorporation: selectedRow.country,
      companySector: [type.value],
      leiNumber: selectedRow.leiNumber,
      leiValidDate: selectedRow.leiValidDate,
    };
    createContract(token, data, (err, data) => {
      if (data.errorMessage) {
        toast.error(data.errorMessage);
      } else {
        toast.success(data.message);
      }
      setType("");
    });
  };
  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md rounded">
        <div className="modal-content" style={props.cardStyle}>
          <div className="modal-header" style={{ border: "none" }}>
            <h3>{t("Create Contract")}</h3>
            <button
              type="button"
              className={`btn-close ${
                props.theme === "dark" ? "btn-close-white" : null
              } `}
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="m-2" style={props.rowStyle}>
              <div className="d-flex justify-content-between">
                <span>{t("Project Name")}</span>
                <span>{selectedRow.name}</span>
              </div>
            </div>
            <div className="m-2" style={props.rowStyle}>
              <div className="d-flex justify-content-between align-items-center">
                <span>{t("Type")}</span>
                <span style={{ width: 186 }}>
                  <Selector
                    menuListColor="black"
                    _handleSelect={(e) => setType(e)}
                    options={option}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              onClick={_handleCreateContract}
              data-bs-dismiss="modal"
              type="button"
              className="btn text-light"
              style={{
                background: Colors.lightBlue,
                // fontSize: 12,
                // padding: 5,
                width: "100%",
              }}
            >
              {t("Create Contract")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
const option = [
  {
    value: "Agriculture",
    label: "Agriculture",
  },
  {
    value: "Aquaculture",
    label: "Aquaculture",
  },
  {
    value: "Building and Construction",
    label: "Building and Construction",
  },
  {
    value: "Energy",
    label: "Energy",
  },
  {
    value: "Finance",
    label: "Finance",
  },
  {
    value: "Manufacturing",
    label: "Manufacturing",
  },
  {
    value: "Transportation and Logistics",
    label: "Transportation and Logistics",
  },
  {
    value: "Others",
    label: "Others",
  },
];
