import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import months from "../../Assets/month.json";
import { toJS } from "mobx";
import { useTranslation } from "react-i18next";


export const TimelineView = inject("store")(
    observer((props) => {
        const { t } = useTranslation("new-manuel-entry")
        const { theme, currentMonth } = props;
        const { _handleSelectMonth, dataEntryData, currentTableData, dataEntryVehicle } = props.store.dataEntry;
        const { role } = props.store.common

        const _MouseEnter = (id) => {
            document.getElementById(id).style["opacity"] = ``;
        };
        const _MouseLeave = (id) => {
            document.getElementById(id).style["opacity"] = `0.7`;
        };

        useEffect(() => {
            _handleSelectMonth(dataEntryData.payload[currentMonth], currentMonth);
        }, [dataEntryData.payload])


        // let filteredUnits = dataEntryData?.payload?.map(v => v?.vehicles?.filter(d => d.GPS != 1 || d.CANbus != 1))

        return (
            dataEntryData?.payload && dataEntryData?.payload.map((v, k) => (
                <div
                    className="d-flex flex-row justify-content-start mx-auto w-100"
                    onMouseEnter={() => _MouseEnter(k)}
                    onMouseLeave={() => _MouseLeave(k)}
                    key={k}
                    style={{ color: invertDark(theme) }}
                >
                    <div className="col-lg-3 col-md-4 col-3">
                        <div
                            className="p-2 mb-1 justify-content-end"
                            id={k}
                            style={{
                                borderRadius: "7px",
                                cursor: "pointer",
                                textAlign: "right",
                                color: k === currentMonth && Colors[theme].textColor,
                            }}
                            onClick={() => v && _handleSelectMonth(v, k)}
                        >
                            {t(months[k + 1].label)}
                        </div>
                    </div>

                    <div
                        id={"parent" + k}
                        className="col-lg-2 col-md-1 col-2 d-flex justify-content-center"
                    >
                        <div
                            style={{
                                background:
                                    theme === "dark" ? "rgba(255,255,255,0.1)" : "#E7E8E8",
                                width: 3,
                                height: document.getElementById(`parent${k}`)?.offsetHeight,
                                position: "absolute",
                                marginLeft: 0,
                            }}
                        ></div>
                        <div
                            style={{
                                background:
                                    k === currentMonth
                                        ? Colors[theme].activeTimeline
                                        : Colors[theme].noActiveTimeline,
                                width: 10,
                                height: 10,
                                marginTop: 16,
                                position: "absolute",
                                borderRadius: 5,
                            }}
                        ></div>
                    </div>

                    <div className="col-5 mx-3">
                        <div
                            className="p-2 mb-1 d-flex justify-content-between  align-items-center "
                            id={k}
                            style={{
                                boxShadow:
                                    k === currentMonth
                                        ? "0px 0px 20px rgba(75,171,237,0.35)"
                                        : "none",
                                borderRadius: "7px",
                                cursor: v && v.vehicles.length > 0 ? "pointer" : "not-allowed",
                            }}
                            onClick={() => v && _handleSelectMonth(v, k)}
                        >
                            {
                                dataEntryVehicle === true ?
                                    <div
                                        style={{ color: k === currentMonth && Colors[theme].textColor }}
                                    >
                                        {"0" + t(" vehicle")}
                                    </div>
                                    :
                                    <div
                                        style={{ color: k === currentMonth && Colors[theme].textColor }}
                                    >
                                        {role === 'user' ?
                                            currentTableData === null
                                                ? "0" + t(" vehicle")
                                                : currentTableData?.vehicles?.length > 1
                                                    ? v?.month === currentTableData?.month ? currentTableData?.vehicles?.length + t(" vehicles") : v === null ? "0" + t(" vehicle") : v?.vehicles?.length + t(" vehicles")
                                                    : v?.month === currentTableData?.month ? currentTableData?.vehicles?.length + t(" vehicle") : v === null ? "0" + t(" vehicle") : v?.vehicles?.length + t(" vehicle")
                                            :
                                            v === null
                                                ? "0" + t(" vehicle")
                                                : v.vehicles?.length > 1
                                                    ? v.month === currentTableData?.month ? currentTableData?.vehicles?.length + t(" vehicles") : v.vehicles?.length + t(" vehicles") 
                                                    : v.month === currentTableData?.month ? currentTableData?.vehicles?.length + t(" vehicle") : v.vehicles?.length + t(" vehicle")
                                        }
                                    </div>
                            }


                            {   dataEntryVehicle === true ? "" :
                                v && v.vehicles.length > 0 && (
                                    <i
                                        className={`${v.vehicles.findIndex(
                                            (dt) =>
                                                (dt.manualFuelConsumed || dt.ascentFuelConsumed) ===
                                                0 ||
                                                (dt.manualTripMileage || dt.ascentTripMileage) === 0
                                        ) > -1
                                            ? "fas fa-exclamation-circle"
                                            : "fas fa-check-circle"
                                            } `}
                                        style={{
                                            color:
                                                v.vehicles.findIndex(
                                                    (dt) =>
                                                        (dt.manualFuelConsumed || dt.ascentFuelConsumed) ===
                                                        0 ||
                                                        (dt.manualTripMileage || dt.ascentTripMileage) === 0
                                                ) > -1
                                                    ? Colors.medium
                                                    : Colors.low,
                                        }}
                                    />
                                )
                            }
                        </div>
                    </div>
                </div>
            ))
        );
    })
)

