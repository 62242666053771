import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { invertDark } from "../../Assets/Config/color.config";
import { CertificateTable } from "../../Components/Certificate/certificateTable";
import { CompanyCertificateModal } from "../../Components/Dashboard";
import Store from "../../Store";
import { useEffect, Component, useContext } from "react";
import { CompanySelector } from "../../Components/Dashboard/companySelector";
import { CO2_Loading } from "../../Assets/CO2_Loading";
import { Selector } from "../../Tools";
import { useTranslation } from "react-i18next";
import "shepherd.js/dist/css/shepherd.css";
import { Colors } from "../../Assets/Config/color.config";

const CertificateContainer = (props) => {
  // const theme = localStorage.getItem("theme");
  const { t } = useTranslation("dashboard");
  const theme = Store.login.theme;
  const { companyId, companyType } = props.store.common;
  const {
    setNoCertificatesData,
    _handleFetchCertificatesV2,
    certificateYear,
    certificatesV2,
    years,
    setCertificateYear,
    loading,
    setLoading,
  } = props.store.dashboard;

  useEffect(() => {
    setLoading(true);
    _handleFetchCertificatesV2();
    setNoCertificatesData(false);
  }, [certificateYear]);

  const tourOptions = {
    defaultStepOptions: {
      cancelIcon: {
        enabled: true,
      },
      classes: "class-1 class-2",
      scrollTo: { behavior: "smooth", block: "center" },
    },

    useModalOverlay: true,
  };

  // function Button() {
  //    const tour = useContext(ShepherdTourContext);

  //   return (
  //     <button className="button dark" onClick={tour.start}>
  //       Start Tour
  //     </button>
  //   );
  // }

  return (
    <div>
      <span
        style={{
          fontSize: 23,
          color: theme === "dark" ? Colors["light"].background : Colors.Dark[2],
        }}
        className="previous-org-guide"
      >
        {t("GHG Emission Reduction Statement")}
      </span>
      <div className="mb-2">
        <div className=" d-flex justify-content-between">
          <div className="pt-2">
            <CompanySelector theme={theme} label={t("Organisation") + ": "} />
          </div>
          <div className="d-flex align-items-center">
            <div className="col-md-auto text-white me-1">
              <label
                style={{
                  fontWeight: "bold",
                  color: invertDark(theme),
                }}
              >
                {t("Year")}
              </label>
            </div>
            <div className="col col-lg-2">
              <div className="ps-0" style={{ width: 160 }}>
                <Selector
                  isSearchable={false}
                  // label={"Year"}
                  options={years}
                  defaultValue={
                    years.filter((v) => v.value === new Date().getFullYear())[0]
                  }
                  // this.years = data.response.map(v => v.value)
                  value={years.find((year) => year.value === certificateYear)}
                  _handleSelect={(value) => {
                    setCertificateYear(value.value);
                  }}
                  disabled={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <CertificateList /> */}
      {!loading ? (
        <>
          {/* <div> */}
          {/* <ShepherdTour steps={steps} tourOptions={tourOptions}>
                    <TourMethods>
                        {tourContext => <Start startTour={tourContext} />}
                    </TourMethods>
                </ShepherdTour> */}
          {/* <ShepherdTour steps={steps} tourOptions={tourOptions}>
                    <Button />
                </ShepherdTour> */}
          {/* </div> */}
          {certificatesV2 ? (
            <CertificateTable
              theme={theme}
              certificatesV2={certificatesV2}
              companyType={companyType}
            />
          ) : (
            <div>No Data Found!</div>
          )}
        </>
      ) : (
        <CO2_Loading />
      )}
      <CompanyCertificateModal theme={theme} />
    </div>
  );
};

export default inject("store")(observer(CertificateContainer));
