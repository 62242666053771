import { useTranslation } from "react-i18next";

export const  Chartdata = (props) => {
    const {t} = useTranslation("lite-dashboard")
    const { icon, label, value, theme, percentage, totalConsumed, totalMileage } = props;
    return (
        <div style={{ background: theme === 'dark' ? 'rgba(0, 0, 0, 0.31)' : 'rgba(0,0,0,0.1)', borderRadius: 10 }}>
            <div className="p-3">
                <div className="d-flex justify-content-between align-items-center">
                    {icon} <span>{label}</span> <span>{percentage}%</span>
                </div>
                <div className="py-2 text-center">
                    <h5 style={{ textShadow: '3px 3px 4px rgba(0, 0, 0, 0.66)' }}>{value}</h5>
                </div>
                <div className="row pt-2" style={{ fontSize: 11, borderTop: '1px solid rgb(255,255,255,0.1)' }}>
                    <span className="col-6">{t("Consumption")}</span>
                    <span className="col-5">: {totalConsumed}</span></div>
                <div className="row mt-2" style={{ fontSize: 11 }}>
                    <span className="col-6">{t("Mileage")}</span>
                    <span className="col-5">: {totalMileage} km</span></div>
            </div>
        </div>
    )
}