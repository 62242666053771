import { inject, observer } from "mobx-react";
import React from "react";
import { Switch, Route, Link } from 'react-router-dom';
import { DataImportDetailView } from "./DataImportDetailView";
import { invertDark } from "../../Assets/Config/color.config";
import { useHistory, useLocation } from "react-router-dom";
import { Cookies } from "react-cookie";
import { OverWriteConfirmModal } from "./OverWriteConfirmModal";
import { CO2_Loading } from "../../Assets/CO2_Loading";
import { Submit_Loading } from "../../Assets/Submit_Loading";
import { useTranslation } from "react-i18next";

export const ViewForManualEntry = inject("store")(
    observer((props) => {
        const {t} = useTranslation("data_import")
        const { theme,
            companyName,
        } = props;

        const {
            dataAlreadyExist,
            SubmitLoading
        } = props.store.dataImport;

        const { role } = props.store.common;
        const location = useLocation();
        console.log("location ===>",location)
        
        const showTable = location.state.showTable;
        const id = location.state.id;
        const status = location.state.status;
        const rowData = location.state.rowData


        // const cookies = new Cookies();
        // const companyName = cookies.get("companyName");


        // console.log(showTable === "table1", "showtable11111")
        // console.log(showTable === "table2", "showtable22222")
        // console.log(showTable === "table3", "showtable33333")

        return (
            <>
                <div>
                    <span style={{ color: invertDark(theme) }}>
                        <Link to="/data_import">{t("Data Import")}</Link> / {showTable === "table1" && (role === "super_admin" || companyName === "Volvo")
                            ? t("Unit Profile")
                            : showTable === "table2" && (role === "super_admin" || companyName === "Volvo")
                                ? t("Trips")
                                : showTable === "table3" && (role === "super_admin" || companyName === "Volvo")
                                    ? t("Engine Hours")
                                    : t("Manual Entry")}
                        {""} {t("Detail View")}
                    </span>
                    <Switch>
                        <Route path="/view_imports">
                                <DataImportDetailView theme={theme} id={id} showTable={showTable} role={role} status={status} rowData={rowData} />
                         
                            {/* <OverWriteConfirmModal theme={theme} dataAlreadyExist={dataAlreadyExist}/>  */}
                        </Route>
                    </Switch>
                </div>
            </>
        );
    })
);
