import { Colors } from "../../Assets/Config/color.config";
import { inject, observer } from "mobx-react";
import { VehicleDetailImageFMS } from "./vehicleDetailImage";
import { toJS } from "mobx";
import moment from "moment";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

export const DetailModalSection = inject("store")(
  observer((props) => {
    const { t } = useTranslation("new-tracking");
    const { detailRef, companyName, months, emission } = props;
    const { theme } = props.store.common;
    const {
      fmsVehicleLst,
      fetchFMSVehicles,
      handleViewChange,
      selectedVehicle,
      vehicleOptions,
      assetProfile,
      month,
      year,
      vehicleType,
      trackingDetailVTwo,
      dateFormat,
      startDateFormat,
      endDateFormat,
      yearSelected,
    } = props.store.trackingVTwo;
    const { isAds } = props.store.common;

    return (
      <div id="content" className="p-5">
        <span style={{ fontSize: 20, fontWeight: "bold" }}>{companyName}</span>
        {/* <span style={{ fontSize: 20, fontWeight:'bold' }}>
              Emission Report for 2022 {months[month - 1]}
            </span> */}
        <div
          ref={detailRef}
          className="d-flex justify-content-between pt-5 pb-2"
        >
          <div style={{ color: Colors.Dark[0], fontSize: 14 }} className="row">
            <span style={{ fontWeight: "bold" }} className="col-6 text-start">
              {t("Vehicle Plate Number")}
            </span>
            <span className="col-6 text-start">: {selectedVehicle?.label}</span>
            <span style={{ fontWeight: "bold" }} className="col-6 text-start">
              {t("Vehicle Type")}
            </span>
            <span className="col-6 text-start">
              : {vehicleType?.label || "--"}
            </span>
            <span style={{ fontWeight: "bold" }} className="col-6 text-start">
              {t("Brand")}
            </span>
            <span className="col-6 text-start">
              : {assetProfile?.brand || "--"}
            </span>
            <span style={{ fontWeight: "bold" }} className="col-6 text-start">
              {t("Model")}
            </span>
            <span className="col-6 text-start">
              : {assetProfile?.model || "--"}
            </span>
            <span style={{ fontWeight: "bold" }} className="col-6 text-start">
              {t("Total Baseline Emission")}
            </span>
            <span className="col-6 text-start">
              :{" "}
              {(`${assetProfile?.live?.totalBaselineEmission.toFixed(4)}` ||
                "--") + " kgCO₂e"}
            </span>
            {/* <span style={{ fontWeight: "bold" }} className="col-6 text-start">
              {t("Total Historical Baseline Emission")}
            </span>
            <span className="col-6 text-start">
              :{" "}
              {(`${assetProfile?.live?.totalHistoricalBaselineEmission.toFixed(
                4
              )}` || "--") + " kgCO₂e"}
            </span> */}
            <span style={{ fontWeight: "bold" }} className="col-6 text-start">
              {t("Total Trip Mileage")}
            </span>
            <span className="col-6 text-start">
              :{" "}
              {assetProfile?.live?.vehicleMileage
                ? assetProfile?.live.vehicleMileage + " km" || "--"
                : "--"}
            </span>

            <span style={{ fontWeight: "bold" }} className="col-6 text-start">
              {t("Total Consumption")}
            </span>
            <span className="col-6 text-start">
              : {""}
              {assetProfile?.live?.totalConsumed
                ? Number(assetProfile?.live?.totalConsumed)?.toLocaleString(
                    "en-US"
                  )
                : null
                ? "--"
                : 0}
              {vehicleType?.label === "EV" ? " kWh" : " L"}
            </span>
            {/* <span style={{ fontWeight: "bold" }} className="col-6 text-start">
              {t("OEM Baseline Emission Factor")}
            </span>
            <span className="col-6 text-start">
              : {assetProfile?.manualBaselineEmissionPerKm + " gCO₂e/km" || "--"}
            </span> */}
            <span style={{ fontWeight: "bold" }} className="col-6 text-start">
              {t("Baseline Emission Intensity")}
            </span>
            <span className="col-6 text-start">
              : {assetProfile?.baseline_emission_factor + ` ${assetProfile?.emission_type_unit === "gCO2e/km" ? "gCO₂e/km" : "gCO₂e/h"}` || "--"}
            </span>
            <span className="col-6 text-start" style={{fontWeight: "bold"}}>
                {t("Baseline Type")}
            </span>
            <span className="col-6 text-start">
              : {assetProfile?.emission_type}
            </span>
          </div>
          <div className="col-6 mb-3 text-end">
            <div className="p-2">
              <VehicleDetailImageFMS
                text={
                  selectedVehicle
                    ? selectedVehicle?.label
                    : vehicleOptions[0]?.label
                }
                isAds={isAds}
                asset_name={
                  selectedVehicle
                    ? selectedVehicle?.label
                    : vehicleOptions[0]?.label
                }
                image={
                  selectedVehicle
                    ? selectedVehicle?.image
                    : selectedVehicle?.[0]?.image
                }
              />
              <div>{selectedVehicle?.label}</div>
            </div>
          </div>
        </div>
        <div
          className="fw-bold pt-2 text-black"
          style={{ borderTop: `1px solid ${Colors.Dark[4]}` }}
        >
          {t("Detail Report")}{" "}
          {dateFormat === "Month"
            ? `${dayjs()
                .month(month - 1)
                .format("MMMM")}-${yearSelected}`
            : dateFormat === "Year"
            ? ` ${yearSelected}`
            : dateFormat === "7 Days" || dateFormat === "Custom"
            ? `from  ${startDateFormat} to ${endDateFormat}`
            : ""}
        </div>
        <div className="my-3">
          <div className="p-2">
            <table
              style={{
                width: "100%",
                border: `1px solid ${Colors.Dark[4]}`,
              }}
            >
              <tr>
                <th
                  align="left"
                  className="ps-2"
                  style={{
                    borderRight: `1px solid ${Colors.Dark[4]}`,
                    width: "10%",
                  }}
                >
                  {t("Driver")}
                </th>
                <th
                  className="ps-2"
                  style={{
                    borderRight: `1px solid ${Colors.Dark[4]}`,
                    width: "12%",
                  }}
                >
                  {t("Date")}
                </th>
                <th
                  className="ps-2"
                  style={{
                    borderRight: `1px solid ${Colors.Dark[4]}`,
                    textAlign: "left",
                  }}
                >
                  {t("Start Point")}
                </th>
                <th
                  className="ps-2"
                  style={{
                    borderRight: `1px solid ${Colors.Dark[4]}`,
                    textAlign: "left",
                  }}
                >
                  {t("End Point")}
                </th>
                <th
                  style={{
                    borderRight: `1px solid ${Colors.Dark[4]}`,
                  }}
                >
                  {t("Mileage")} (km)
                </th>
                <th
                  style={{
                    borderRight: `1px solid ${Colors.Dark[4]}`,
                  }}
                >
                  {t("CO2e Emission")} (kgCO₂e)
                </th>
                <th>{t("Emission Intensity")} ({assetProfile?.emission_type_unit === "gCO2e/km" ? "gCO₂e/km" : "gCO₂e/h"})</th>
              </tr>
              {trackingDetailVTwo?.map((v) => (
                <tr style={{ borderTop: `1px solid ${Colors.Dark[4]}` }}>
                  <td
                    className="ps-2"
                    style={{
                      borderRight: `1px solid ${Colors.Dark[4]}`,
                    }}
                  >
                    {v.driver || "N/A"}
                  </td>
                  <td
                    className="ps-2"
                    style={{
                      borderRight: `1px solid ${Colors.Dark[4]}`,
                    }}
                  >
                    {moment.utc(v.start_time).format("yyyy-MM-DD")}
                  </td>
                  <td
                    align="left"
                    className="ps-2"
                    style={{
                      borderRight: `1px solid ${Colors.Dark[4]}`,
                    }}
                  >
                    {v.start}
                  </td>
                  <td
                    align="left"
                    className="ps-2"
                    style={{
                      borderRight: `1px solid ${Colors.Dark[4]}`,
                    }}
                  >
                    {v.end}
                  </td>
                  <td
                    style={{
                      borderRight: `1px solid ${Colors.Dark[4]}`,
                    }}
                  >
                    {Number(v.trip_mileage)?.toFixed(2)}
                  </td>
                  <td
                    style={{
                      borderRight: `1px solid ${Colors.Dark[4]}`,
                    }}
                  >
                    {v.emission.toFixed(2)}
                  </td>
                  <td
                    style={{
                      borderRight: `1px solid ${Colors.Dark[4]}`,
                    }}
                  >
                    {v.emissionFactor ? v.emissionFactor : "0.00"}
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>
        <div className="mt-3" style={{ background: "rgb(0,0,0,0.04)" }}>
          {/* <div className="p-2">
            {t("Detail Emission Chart for")} {year}
            <div
              style={{ fontSize: 12 }}
              className="d-flex justify-content-between px-4 pb-2"
            >
              <span>(kgCO₂)</span>
              <span>(gCO₂/km)</span>
            </div>
            {assetProfile?.carbonEmission_live?.length > 0 ? (
              <CarbonEmissionLiveChart
                showChartView = "CANBus"
                pdf={true}
                theme={theme}
              />
            ) : (
              t("No Data")
            )}
          </div> */}
        </div>
      </div>
    );
  })
);
