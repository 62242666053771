import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";

import { Colors, invertDark } from "../../Assets/Config/color.config";
import month from "../../Assets/month.json";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import * as React from "react";

import {
  Pagination,
  PaginationItem,
  styled,
  TableSortLabel,
} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import moment from "moment";
import { Button } from "react-bootstrap";
import { toJS } from "mobx";
import { CO2_Loading } from "../../Assets/CO2_Loading";
import { useTranslation } from "react-i18next";

export const MonthlyTable = inject("store")(
  observer(
    withRouter((props) => {
      const { t } = useTranslation("track_and_trace");
      const {
        theme,
        _handleViewContract,
        noPagination,
        rows,
        monthlyData,
        companyType,
      } = props;
      const {
        _handleSelectedMonthlyDetail,
        LoadingForMonthlyTable,
        _handleGetStacsHistory,
        selectedYear,
        _handleGetCertId,
      } = props.store.trackAndTrace;
      const [certId, setCertId] = React.useState(null);

      const currentMonth = moment().month() + 1;
      const currentYear = moment().year();

      const Key =
        companyType === "1"
          ? [
              "waning",
              "month",
              "fuel_consumed",
              "battery_consumed",
              // "mileage",
              // "trip_count",
              "baseline_emission",
              "co2_emission",
              "offset",
              "action",
            ]
          : [
              "waning",
              "month",
              "fuel_consumed",
              "battery_consumed",
              // "total_engine_hr",
              // "trip_count",
              "baseline_emission",
              "co2_emission",
              "offset",
              "action",
            ];

      const [page, setPage] = React.useState(1);
      const [order, setOrder] = React.useState("asc");
      const [orderBy, setOrderBy] = React.useState("");
      const [selectedIndex, setSelectedIndex] = React.useState("");

      const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
        console.log("property", property);
      };

      const headers =
        companyType === "1"
          ? [
              { label: "", key: "warning" },
              // { label: "Selected Month", key: "selected_month"},
              { label: t("Month"), key: "month" },
              { label: t("Petrol Consumption"), key: "fuel_consumed" },
              { label: t("Diesel Consumption"), key: "diesel_consumed" },
              { label: t("Battery Consumption"), key: "battery_consumed" },
              // { label: t("Mileage"), key: "mileage" },
              // { label: t("Trips"), key: "trip_count" },
              { label: t("CO₂e Emission"), key: "co2_emission" },
              { label: t("Baseline CO₂e Emission"), key: "baseline_emission" },
              // historicalStatus === 0
              // ? ''
              // :
              // { label: t("Historical Baseline CO₂e Emission"), key: "history_baseline_emission" },
              { label: t("CO₂e Offset"), key: "offset" },
              { label: t("Action"), key: "action" },
            ]
          : [
              { label: "", key: "warning" },
              // { label: "Selected Month", key: "selected_month"},
              { label: t("Month"), key: "month" },
              { label: t("Petrol Consumption"), key: "fuel_consumed" },
              { label: t("Diesel Consumption"), key: "diesel_consumed" },
              { label: t("Battery Consumption"), key: "battery_consumed" },
              { label: t("Total Engine Hour"), key: "total_engine_hr" },
              { label: t("Trips"), key: "trip_count" },
              { label: t("CO₂e Emission"), key: "co2_emission" },
              { label: t("Baseline CO₂e Emission"), key: "baseline_emission" },
              { label: t("CO₂e Offset"), key: "offset" },
              { label: t("Action"), key: "action" },
            ];

      const tableReportModal = () => {
        let myModal = new window.bootstrap.Modal(
          document.getElementById("tablereport")
        );
        myModal.show();
      };

      console.log("monthly table rows --->", toJS(rows));

      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        cursor: "pointer",
        "&:nth-of-type(odd)": {
          backgroundColor:
            props.theme === "dark" ? Colors.darkBlue : Colors.Dark[5],
          color: theme.palette.common.white,
        },
        // hide last border
        "&:last-child td, &:last-child th": {
          border: 0,
        },
      }));

      const rowCount = 12;
      const handleChange = (event, value) => {
        setPage(value);
      };

      function EnhancedTableHead(props) {
        const { order, orderBy, onRequestSort, theme, Key } = props;
        const createSortHandler = (property) => (event) => {
          onRequestSort(event, property);
        };
        return (
          <TableHead>
            <TableRow>
              {headers.map((headCell, index) => (
                <TableCell
                  key={index}
                  align={headCell.label === "Action" ? "center" : "left"}
                  padding={headCell.disablePadding ? "none" : "normal"}
                  sortDirection={orderBy === headCell.id ? order : false}
                  style={{
                    background:
                      theme === "dark" ? Colors.darkBlue : invertDark(theme),
                  }}
                >
                  {headCell.label === "" || (
                    <TableSortLabel
                      style={{
                        minWidth: headCell.label === "Month" ? 40 : 80,
                        color:
                          orderBy === headCell.id
                            ? Colors.activeColor
                            : theme === "light"
                            ? "#F6F6F6"
                            : Colors.Dark[4],
                      }}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={createSortHandler(Key[index])}
                    >
                      <span className="fw-bold">{headCell.label}</span>
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        );
      }

      function descendingComparator(a, b, orderBy) {
        const A = a[orderBy] ? a[orderBy].toString().trim() : undefined;
        const B = b[orderBy] ? b[orderBy].toString().trim() : undefined;
        if (B < A) {
          return -1;
        }
        if (B > A) {
          return 1;
        }
        return 0;
      }

      function getComparator(order, orderBy) {
        return order === "desc"
          ? (a, b) => descendingComparator(a, b, orderBy)
          : (a, b) => -descendingComparator(a, b, orderBy);
      }

      const useStyles = makeStyles({
        foo: {
          "& .MuiPagination-ul": { justifyContent: "end" },
          "& .MuiPaginationItem-text": {
            color: Colors.Dark[theme === "dark" ? 4 : 1],
          },
          "& .Mui-selected": {
            backgroundColor: Colors.Dark[4],
            color: Colors.darkBlue,
          },
        },
      });

      const classes = useStyles();

      const isLoading = monthlyData === null;

      return (
        <React.Fragment>
          <TableContainer
            component={Paper}
            style={{ background: "none" }}
            // sx={{ maxHeight: 450 }}
          >
            <Table
              stickyHeader
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense sticky table"
            >
              <EnhancedTableHead
                _handleViewContract={_handleViewContract}
                Key={Key}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                theme={theme}
              />

              {LoadingForMonthlyTable == false ? (
                <TableBody>
                  {/* {isLoading ? (
                      <TableRow>
                        <TableCell colSpan={headers.length} align="center">
                          <CO2_Loading />
                        </TableCell>
                      </TableRow>
                    ) : ( */}
                  <>
                    {rows?.length > 0 &&
                      rows
                        ?.sort(getComparator(order, orderBy))
                        ?.slice((page - 1) * rowCount, page * rowCount)
                        ?.map((row, index) => (
                          <StyledTableRow
                            style={{
                              background:
                                selectedIndex === row.id ? Colors.Dark[3] : "",
                            }}
                            key={index}
                            onClick={() => {}}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            {Object.values(row)
                              .filter((f) => {
                                console.log("f", f);
                                return f && !f.toString().includes("_");
                              })
                              .map(
                                (d, x) =>
                                  x != 0 &&
                                  x != 2 && (
                                    <TableCell
                                      sx={{
                                        color: invertDark(theme),
                                        borderBottom: "none",
                                      }}
                                      key={x}
                                      width={d === "update" ? "290px" : ""}
                                    >
                                      {d === "update" ? (
                                        <div className="d-flex align-items-center">
                                          <Button
                                            type="button"
                                            data-bs-toggle="modal"
                                            data-bs-target="#monthlyDataDetail"
                                            data-toggle="modal"
                                            data-target=".bd-example-modal-lg"
                                            onClick={() => {
                                              _handleSelectedMonthlyDetail(
                                                row,
                                                index
                                              );
                                            }}
                                            style={{
                                              background: "#4c5d75",
                                              color: "white",
                                              border: "none",
                                              marginRight: "10px",
                                            }}
                                            className="btn btn-sm detail-report-guide"
                                          >
                                            {t("Detail")}
                                          </Button>

                                          {currentYear === selectedYear &&
                                          currentMonth ===
                                            row.selected_month ? (
                                            <></>
                                          ) : (
                                            <span
                                              data-bs-toggle="modal"
                                              data-bs-target="#monthlyhistorymodal"
                                              data-toggle="modal"
                                              data-target=".bd-example-modal-lg"
                                              className="pe-2"
                                            >
                                              <Button
                                                type="button"
                                                onClick={() => {
                                                  _handleGetStacsHistory(
                                                    row.selected_month
                                                  );
                                                  _handleGetCertId(
                                                    row.selected_month
                                                  );
                                                }}
                                                style={{
                                                  background: "#4c5d75",
                                                  color: "white",
                                                  border: "none",
                                                }}
                                                className="btn btn-sm detail-report-guide"
                                              >
                                                {t("View History")}
                                              </Button>
                                            </span>
                                          )}

                                          {row.units && row.units.length > 0 ? (
                                            <span
                                              onClick={() => tableReportModal()}
                                            >
                                              <Button
                                                type="button"
                                                onClick={() =>
                                                  _handleSelectedMonthlyDetail(
                                                    row,
                                                    index
                                                  )
                                                }
                                                style={{
                                                  background: "#4c5d75",
                                                  color: "white",
                                                  border: "none",
                                                  marginRight: "10px",
                                                }}
                                                className="btn btn-sm detail-report-guide"
                                              >
                                                {t("Download")}
                                              </Button>
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      ) : d === "no-update" ? (
                                        ""
                                      ) : d === "warning" ? (
                                        <>
                                          <i
                                            className="fas fa-exclamation-triangle"
                                            title="You need to re-send certificate data."
                                            style={{ color: "#ffcc00" }}
                                          ></i>
                                        </>
                                      ) : (
                                        d
                                      )}
                                    </TableCell>
                                  )
                              )}
                          </StyledTableRow>
                        ))}
                  </>
                  {/* )} */}
                </TableBody>
              ) : (
                <>
                  <TableBody>
                    <TableCell colSpan={headers.length} align="center">
                      <CO2_Loading />
                    </TableCell>
                  </TableBody>
                </>
              )}
            </Table>
          </TableContainer>

          {noPagination || (
            <div className="py-2">
              <Pagination
                size="small"
                renderItem={(item) => (
                  <PaginationItem
                    {...item}
                    sx={{ color: invertDark(theme) }}
                    classes={{ selected: classes.selected }}
                  />
                )}
                className={classes.foo}
                count={
                  rows.length <= rowCount
                    ? 1
                    : Math.ceil(rows.length / rowCount)
                }
                defaultPage={1}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                onChange={handleChange}
              />
            </div>
          )}
        </React.Fragment>
      );
    })
  )
);
