import Store from "../../Store";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import React, { useState } from "react";
import { My_Button } from "../../Tools";
import { Cookies } from "react-cookie";
import { fetchUpdateCompany } from "../../network/fetchAdmin";
import { toast } from "react-toastify";
import { baseIMGURL, baseURL } from "../../network/URL";
import { CompanyTable } from "../../Components/AdminPanel/CompanyLstTable";
import plusIcon from '../../Assets/Images/icons/plusIcon.png'

// import { CreateCompanyModal } from "./CreateCompanyModal";
import { CreateCompanyModalNew } from "./CreateCompanyModalNew";
import { UpdateCompanyModal } from "../../Components/AdminPanel/UpdateCompanyModal";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { CompanyTableJQ } from "../../Components/AdminPanel/CompanyTableJQ";
import {NewCreateNewCompanyModal} from "./NewCreateNewCompanyModal";
import Tour from './companyListTour';


export const EditCompany = inject("store")(
  observer((props) => {
    const {t} = useTranslation("admin-panel")
    const theme = Store.login.theme;
    const [text, setText] = useState("");
    const [id, setId] = useState();
    const cookies = new Cookies();
    const token = cookies.get("accessToken");
    // const rows = props;

    const { userList } = props
    const { role } = props.store.common

    const { selectedCountry, 
            setSelectedCountry, 
            _handleUpdateCompany, 
            _handleEditCompany, 
            _handleDeleteCompany, 
            _fetchdeleteCompanyInfo
          } = props.store.adminPanel;

    const cardStyle1 = {
            background: Colors[theme].background,
            boxShadow: "0px 5px 10px rgba(0,0,0,0.25)",
            padding: "15px",
            borderRadius: "5px",
            marginBottom: 8,
            color: invertDark(theme),
            // cursor: "pointer",
        };
  // const _handleUpdateCompany = () => {
  //   const payload = {
  //     companyId: id,
  //     companyName: text,
  //   };
  //   fetchUpdateCompany(token, payload, (err, data) => {
  //     if (data.message === "Saved!") {
  //       toast.success("Company name updated successfully!");
  //       props.setEditMode("");
  //       props.fetchCompany();

  //       if(payload.companyId === cookies.get('company')) {
  //         cookies.set('companyName', payload.companyName);
  //       }
  //     } else console.log(err);
  //   });
  // };


  const rows = props.companyList
    ? props.companyList?.map((company) => {
        const imageURL = baseURL + company.logo;

        let row = {
          id: company.id,
          // logo: company.logo,
          // name: company.name,
          name: (
            <span>
              <img
                src={imageURL}
                style={{ paddingRight: "10px", width: "30px" }}
                alt={`${company.name} Logo`}
              />
              {company.name}
            </span>
          ),
          level: company.level,
          country: company.country,
          type: company.type || "N/A",
          ascent_id: company.ascentAccountId || "N/A",
          action: "view",
        };
        return row;
      })
    : [];

  const openCompanyModal = () => {
    let myModal = new window.bootstrap.Modal(document.getElementById('createcompanymodalnew'))
    myModal.show()
  }

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  
  return (
    <div style={{ color: invertDark(theme) }} className="">
      <div className="d-flex align-items-center pb-2">
        <h3 className="pe-4 ps-3 companyList-admin-guide" >{t("Company List")}</h3>

          {/* don't delete */}
          {/* scrollable old company registeration modal */}

        {/* <div onClick={openCompanyModal}
          style={{ border: '1px solid gray', width: '40px', height: '40px', borderRadius: 5, cursor: 'pointer' }} 
          className='d-flex align-items-center justify-content-center'>
          <img width='20px' src={plusIcon} />
        </div> */}
        
        {role === "super_admin" &&
        <div onClick={() => openModal()}
          style={{ border: '1px solid gray', width: '40px', height: '40px', borderRadius: 5, cursor: 'pointer' }} 
          className='d-flex align-items-center justify-content-center'>
          <img width='20px' src={plusIcon} />
        </div>
        }
        {/* <Tour/> */}
      </div>

      {/* <CompanyTable 
        role={role}
        rows={rows} 
        theme={theme} 
        userList={userList}
        companyList={props.companyList} 
        _handleUpdateCompany={_handleUpdateCompany} 
        selectedCountry={selectedCountry} 
        setSelectedCountry={setSelectedCountry} 
        _handleEditCompany={_handleEditCompany} 
        _handleDeleteCompany={_handleDeleteCompany} 
        _fetchdeleteCompanyInfo={_fetchdeleteCompanyInfo}
      /> */}

      <CompanyTableJQ
        role={role}
        rows={rows} 
        theme={theme} 
        userList={userList}
        companyList={props.companyList} 
        _handleUpdateCompany={_handleUpdateCompany} 
        selectedCountry={selectedCountry} 
        setSelectedCountry={setSelectedCountry} 
        _handleEditCompany={_handleEditCompany} 
        _handleDeleteCompany={_handleDeleteCompany} 
        _fetchdeleteCompanyInfo={_fetchdeleteCompanyInfo}
      
      />
          
      {/* <CreateCompanyModal theme={theme}  />  */}

      {/* scrollable registration new company old modal */}
      {/* <CreateCompanyModalNew theme={theme} /> */}

      {/* registration new company new modal*/}
      <NewCreateNewCompanyModal setShowModal={setShowModal} theme={theme} show={showModal} onHide={closeModal} size="xl"/>
      
    </div>
  );
}));

// const CompanyListTable = inject("store")(
//   observer((props) => {
//     const {  _handleTextChange, text, setText, setId, editMode, setEditMode, _handleRowClick, selectedRow,theme } = props
//     const _handleEdit = (name, id) => {
//         setEditMode(name)
//         setText(name)
//         setId(id)
//     }
//     const { selectedCountry, setSelectedCountry } = props.store.adminPanel

//   return (
//     <React.Fragment>
//       {/* <div className="row  p-2"></div> */}
//       {props.companies.map((v, k) => (
//         <div
//           onClick={() => _handleRowClick(v)}
//           className=" row m-2 p-1 rounded"
//           style={{
//             background:
//               theme === 'light'
//                 ? selectedRow === v.name
//                   ? Colors.Dark[2]
//                   : Colors.Dark[3]
//                 : theme === 'dark' &&
//                   (selectedRow === v.name ? Colors.Dark[2] : Colors.darkBlue),
//             cursor: 'pointer',
//           }}
//           key={k}
//         >
//           {editMode === v.name ? (
//             <>
//               <input
//                 className="col-7 rounded"
//                 type="text"
//                 value={text}
//                 onChange={(e) => _handleTextChange(e)}
//               />
//               <div className="col-5 text-end">
//                 {/* <My_Button
//                   style={{ marginRight: 10 }}
//                   onClick={_handleUpdateCompany}
//                   text={'Save'}
//                   small
//                 /> */}
//                 <My_Button
//                   onClick={() => setEditMode('')}
//                   text={'Cancel'}
//                   small
//                 />
//               </div>
//             </>
//           ) : (
//             <>
//               <div
//                 className="col-8"
//                 style={{
//                   // color:
//                   //   selectedRow === v.name
//                   //     ? theme === 'dark'
//                   //       ? 'red'
//                   //       : 'white'
//                   //     : theme === 'dark'
//                   //     ? '#C6C6C6 !important'
//                   //     : 'white',
//                   fontWeight: selectedRow === v.name ? 'bold' : null,
//                 }}
//               >
//                 <img className="bg-white rounded me-2" height={30} src={baseURL+v.logo}/>{v.name}
//                 {v.trackId == null && <i
//                   // className={`fas fa-exclamation-circle ms-2`}
//                   style={{
//                     color: Colors.medium,
//                   }}
//                 />}
//               </div>
                 
//               <div className="col-4 text-end">
//                 <My_Button
//                   leftIcon={<i style={{fontSize:15}} className="bi bi-pencil-square"></i>}
//                   small
//                   onClick={() => setSelectedCountry(v.country)
//                   }
//                 />
//               </div>
              
//             </>
//           )}
//         </div>
//       ))}
//     </React.Fragment>
//   );
// }));
