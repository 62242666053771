import React, { useEffect, useState, useRef } from "react";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import { invertDark, Colors } from "../../Assets/Config/color.config";
import { CO2_Loading } from "../../Assets/CO2_Loading";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const IssuesNotiCard = inject("store")(
  observer((props) => {
    const iconStyle = {
      fontSize: "16px",
      color: Colors.Dark[props.theme === "light" ? 2 : 4],
      cursor: "pointer",
    };
    const { handleClose, _handleViewChange } = props;
    const history = useHistory();
    const { theme } = props;
    const { issueNoti, isLoading, _handleGetIssuesNoti, _handleGetLogIssues } =
      props.store.adminPanel;

    const [displayedIssues, setDisplayedIssues] = useState([]);
    const containerRef = useRef(null);

    useEffect(() => {
      _handleGetIssuesNoti();
    }, []);

    useEffect(() => {
      setDisplayedIssues(issueNoti.slice(0, 10));
    }, [issueNoti]);

    const latestNewNoti = issueNoti[0];
    const newNotiCount = issueNoti.indexOf(latestNewNoti) + 1;

    const handleMoreViewClick = () => {
      history.push("/logs");
      handleClose();
      _handleViewChange("table");
      // _handleGetLogIssues()
    };

    return (
      <>
        {!isLoading || displayedIssues.length == [] ? (
          <div>
            <CO2_Loading />
          </div>
        ) : (
          <div
            className=""
            ref={containerRef}
            style={{ overflowY: "auto", overflowX: "hidden", height: 400 }}
            onClick={handleMoreViewClick}
          >
            {displayedIssues?.map((issue, index) => (
              <div
                className="logsNotiCard mb-1"
                style={{
                  color: invertDark(theme),
                  background: "rgb(255,255,255,0.05)",
                  cursor: "pointer",
                  minHeight: 100,
                  maxWidth: 490,
                  border: theme === "light" && "1px solid rgb(0,0,0,0.3)",
                }}
                key={index}
              >
                {/* Render your log data */}
                <div className="p-2">
                  <div className="px-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="row" style={{ fontSize: "18px" }}>
                        {/* <i class="col-md-2 bi bi-exclamation-circle-fill d-flex"/> */}
                        Issue:
                        <span className="col text-truncate">{issue.issue}</span>
                      </div>
                      <div>{issue.issue_company}</div>
                    </div>
                    <div className="row">{issue.issue_detail}</div>
                    {/* <div className="row"></div> */}
                  </div>
                </div>
                <div className="row px-3">
                  <div className="col-md-6">
                    Issue Value: {issue.issue_value || "N/A"}
                  </div>
                  <div className="col-md-6">
                    <span style={iconStyle}>
                      <i className="bi bi-clock-fill pe-2" /> {issue.issue_time}
                    </span>
                  </div>
                </div>
              </div>
            ))}

            {displayedIssues.length < 10 ? (
              <div className="text-center mt-2">
                All issues data has been loaded.
              </div>
            ) : (
              <button
                onClick={handleMoreViewClick}
                type="button"
                className={`${
                 theme === "dark"
                   ? "btn  btn-outline-info btn-sm mb-2 mt-2 w-100"
                   : "btn btn-outline-success btn-sm mb-2 mt-2 w-100"
               }`}               >
                View More<i class="bi bi-arrow-right-circle-fill ps-2 "></i>
              </button>
            )}
          </div>
        )}
      </>
    );
  })
);

export default IssuesNotiCard;
