import { My_Button } from "../../Tools";
import { inject, observer } from "mobx-react";
import { Colors } from "../../Assets/Config/color.config";
import { invertDark } from "../../Assets/Config/color.config";
import { useState } from "react";
import Spinner from "../../Assets/Images/icons/spinner.gif";
import { Link } from "react-router-dom";

import { useMediaPredicate } from "react-media-hook";
import { useTranslation } from "react-i18next";

export const ISPOTcomponent = inject("store")(
    observer((props) => {
        const {t} = useTranslation("dashboard")
        const { ascentTokenKey, ascentAccountId, role } = props.store.common;

        console.log("ascentAccountId>>", ascentAccountId)

        const { theme } = props;
        const { _handleMouseHover, hoverId, isHover } = props.store.landing;
        const ascentURL =
            role === "super_admin"
                ? `https://ext.logistics.myascents.net/login2?key=${ascentTokenKey}`
                : `https://ext.logistics.myascents.net/login2?key=${ascentTokenKey}&sact=${ascentAccountId}`;
        const [loading, setLoading] = useState(false)

        const openInNewTab = url => {
            window.open(url, '_blank', 'noopener,noreferrer');
          };

          const isMobile = useMediaPredicate("(min-width: 250px) and (max-width: 500px)");
          const isTablet = useMediaPredicate("(min-width: 500px) and (max-width: 1000px)");
          const isDestop = useMediaPredicate("(min-width: 1000px) and (max-width: 2000px)");
          const surfaceDuo = useMediaPredicate("(min-width : 500 px) and (max-width : 600px)");

        return (
            <div
                className="text-center text-light col-12"
               
                style={{
                    background: theme === "dark" ? "rgb(0,0,0,0.3)" : "rgb(0,0,0,0.05)",
                    borderRadius: 10,
                    boxShadow: theme === "light" && "0 0 2px rgb(0,0,0,0.4)",
                    height: role !== 'super_admin' ? 225 : 170,
                    maxWidth: isMobile ? '' : isTablet ? '' : '400px', 
                }}
            >
                <img src={role === 'super_admin' ? "ascent-logo1.png" : "ascent-logo.png"} alt="icon" className="mt-3 mb-2 bg-white p-2 rounded" height={role !== 'super_admin' ? 100 : 45} />
                <h4 style={{ color: theme === 'dark' ? Colors.Dark[3] : Colors.Dark[0] }}>{t("Ascent Platform")}</h4>
                <div
                    className="col-12 py-2"
                    // style={{position:'absolute', bottom:20}}
                    onMouseEnter={() => (role === 'super_admin' || ascentAccountId) && _handleMouseHover("ascent")}
                    onMouseLeave={() => (role === 'super_admin' || ascentAccountId) && _handleMouseHover("ascent")}
                >
                    <div
                        onClick={() =>
                            (role === 'super_admin' || ascentAccountId) && (openInNewTab(ascentURL))
                        }
                        className="p-2 rounded mx-3 d-flex justify-content-between"
                        title={ascentAccountId ? "" : "Sorry! We noted that you are not a subscriber to our iSPOT Fleet Management System (FMS) as part of the CO2X Enterprise subscription plan. If you are interested in gaining access to iSPOT FMS for the vehicle telematics data, please approach your CO2X account manager for details."}
                        style={{
                            background: theme === "light" ? 'rgb(224, 224, 224)' : Colors.darkBlue,
                            cursor: (role === 'super_admin' || ascentAccountId) ? "pointer" : "not-allowed",
                            color:
                                hoverId === "ascent" && isHover
                                    ? '#5c5959'
                                    : invertDark(theme),
                            color : theme === 'light' ? hoverId === "ascent" && isHover ? "black" : "black" : "#fff",
                            fontSize : "large",
                            // background:
                            //         theme === "light" ? "#9F9E9E" : Colors.darkBlue,
                            //     cursor:  (role === 'super_admin' || ascentAccountId) ? "pointer" : "not-allowed",
                            //     color: hoverId === "ascent" && isHover ? "#fff" : "#fff",
                            //     opacity:  hoverId ? 1 : hoverId === null ? 1 : 0.55,
                            //     fontSize: "large",
                        }}
                    >
                        {t("Go to iSPOT FMS")} {loading?<img src={Spinner} alt="loading" width={20} />:<i className="bi bi-arrow-right" />}
                    </div>
                </div>
            </div>
        );
    })
);
