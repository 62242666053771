
import { inject, observer } from "mobx-react";
import moment from 'moment';
import { useRef, useEffect, useState } from 'react';
import 'datatables.net-dt';
import 'datatables.net-dt/css/jquery.dataTables.css';
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { toJS } from 'mobx';
import { Button } from "react-bootstrap";
import * as React from "react";
import $ from 'jquery';
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";
import { CO2_Loading } from "../../Assets/CO2_Loading";
import { useHistory } from "react-router-dom";
import { baseIMGURL, baseURL } from "../../network/URL";
import { UpdateCompanyModalJQ } from "./UpdateCompanyModalJQ"
import { AssignVehiclesJQ } from "./AssignVehiclesJQ"
import { CompanyInfoDetailsModal } from "../../Containers/AdminPanel/CompanyInfoDetailsModal";
import { useTranslation } from "react-i18next";
import { AssignVehiclesCompanyModal } from "../../Containers/AdminPanel/AssignVehicleCompanyModal";

export const CompanyTableJQ = inject("store")(
    observer((props) => {
        const { t } = useTranslation("admin-panel")

        const { theme,
            _handleViewContract,
            _handleEditCompany,
            selectedCountry,
            setSelectedCountry,
            companyList,
            _handleDeleteCompany,
            _fetchdeleteCompanyInfo } = props;
    

        // console.log(toJS(companyList), "companylistbrtwyll===>")

        const { _FetchUnitsForCompanyAssign,
            companyId } = props.store.dataEntry;
        // console.log("companyId ======>",companyId)

        const { setSelectedCompany, setInfoModalView,
            setSelectedLevel, setCompanyLevel } = props.store.adminPanel;

        const rows = props.companyList ? props.companyList.map(company => {

            let companylistrow = {
                id: company.id,
                name:
                    <span>
                        <img src={baseURL + company.logo} style={{ paddingRight: '10px', width: '30px' }} />
                        {company.name}
                    </span>
                ,
                level: company.level,
                country: company.country,
                type: company.type || "N/A",
                stacs_register_date: company.stacs_register_date || "N/A",
                badge_status: company.badge_status || "N/A",
                ascent_id: company.ascentAccountId ? company.ascentAccountId : "N/A",
                action: '',
            };

            return companylistrow
        }) : []
        console.log("companyList =>",toJS(companyList))


        const tableRef = useRef(null);

        useEffect(() => {

            const styleElement = document.createElement('style');
            styleElement.innerHTML = theme === 'dark' ? `
           
            .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
                color: black !important;
                border: 1px solid #e5e5e5!important;
                
                background-color: #e5e5e5 !important;
                /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #e5e5e5), color-stop(100%, #e5e5e5))!important; */
                background: -webkit-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
                background: -moz-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
                background: -ms-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%) !important;
                background: -o-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
                background: linear-gradient(to bottom, #e5e5e5 0%, #e5e5e5 100%)!important;
              }

              .dataTables_wrapper .dataTables_paginate .paginate_button.current {
                color: black !important;
                border: 1px solid #C6C6C6!important;
                
                background-color: #C6C6C6 !important;
                /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fffcfc), color-stop(100%, #969292))!important; */
                background: -webkit-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                background: -moz-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                background: -ms-linear-gradient(top, #fffcfc 0%, #969292 100%) !important;
                background: -o-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                background: linear-gradient(to bottom, #fffcfc 0%, #969292 100%)!important;
              }

              .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
                color: black !important;
                border: 1px solid #C6C6C6!important;
                
                background-color: #C6C6C6 !important;
                /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fffcfc), color-stop(100%, #969292))!important; */
                background: -webkit-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                background: -moz-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                background: -ms-linear-gradient(top, #fffcfc 0%, #969292 100%) !important;
                background: -o-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                background: linear-gradient(to bottom, #fffcfc 0%, #969292 100%)!important;
              }

            ` :
                `
                .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
                    color: black !important;
                    border: 1px solid #e5e5e5!important;
                    
                    background-color: #e5e5e5 !important;
                    /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #e5e5e5), color-stop(100%, #e5e5e5))!important; */
                    background: -webkit-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
                    background: -moz-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
                    background: -ms-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%) !important;
                    background: -o-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
                    background: linear-gradient(to bottom, #e5e5e5 0%, #e5e5e5 100%)!important;
                  }

                  .dataTables_wrapper .dataTables_paginate .paginate_button.current {
                    color: black !important;
                    border: 1px solid #C6C6C6!important;
                    
                    background-color: #C6C6C6 !important;
                    /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fffcfc), color-stop(100%, #969292))!important; */
                    background: -webkit-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                    background: -moz-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                    background: -ms-linear-gradient(top, #fffcfc 0%, #969292 100%) !important;
                    background: -o-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                    background: linear-gradient(to bottom, #fffcfc 0%, #969292 100%)!important;
                  }
       
            `
            document.head.appendChild(styleElement);

            function updateOrderAndDisplayStart(columnIndex, direction) {
                orderColumn = columnIndex;
                orderDirection = direction;

                table.order([[orderColumn, orderDirection]]).draw();
            }

            const storedOrderColumn = localStorage.getItem('orderColumn');
            const storedOrderDirection = localStorage.getItem('orderDirection');

            let orderColumn = storedOrderColumn ? parseInt(storedOrderColumn) : 0;
            let orderDirection = storedOrderDirection || 'asc';

            const table = $('#companyTable').DataTable({
                autoWidth: true,
                responsive: true,
                retrieve: true,
                order: [[0, 'asc']],
                lengthMenu: [8, 10, 15],
                data: companyList,

                columns: [

                    {
                        title: t("Name"),
                        data: 'name',
                        render: function (data, type, row, logo) {
                            const sizes = {
                                ten: '10px',
                                thirty: '30px'
                            };
                            if (data) {
                                return '<span><img src="' + baseURL + row.logo + '" style="padding-right:' + sizes.ten + '; width:' + sizes.thirty + '"/>' + row.name + '</span>';
                            }
                        }
                    },

                    { title: t("Level"), data: 'level' },
                    {
                        title: t("Country"),
                        render: function (data, type, row) {
                            return row.country === "" ? "N/A" : row.country
                        }
                    },
                    {
                        title: t("Company Type"), data: 'type',
                        render: function (data, type, row) {
                            if (data) {
                                return `${row.type}`
                            } else {
                                return "N/A"
                            }
                        }
                    },
                    {
                        title: t("Registered on ESGpedia"), data: 'stacs_register_date',
                        render: function (data, type, row) {
                            if (data) {
                                return `${row.stacs_register_date}`
                            } else {
                                return "N/A"
                            }
                        }
                    },
                    {
                        title: t("Badge Status"), data: 'badge_status',
                        render: function (data, type, row) {
                            if (data) {
                                return `${row.badge_status}`
                            } else {
                                return "N/A"
                            }
                        }
                    },

                    // {
                    //     title: t("Ascent ID"), data: 'ascentAccountId',
                    //     render: function (data, type, row) {
                    //         if (data) {
                    //             return `${row.ascentAccountId}`
                    //         } else {
                    //             return "N/A"
                    //         }
                    //     }
                    // },
                    // {
                    //     title: t("Stacs ID"), data: 'stacs_id',
                    //     render: function (data, type, row) {
                    //         if (data == null) {
                    //             return "N/A"
                    //         } else {
                    //             return `${row.stacs_id}`
                    //         }
                    //     }
                    // },

                    {
                        title: t("Action"),
                        render: function (data, type, row) {
                            const isVisible = row?.id === companyId || companyId === "24445513"
                            return`
                            ${isVisible ?

                            `<button type="button" class="btn btn-sm m-1"
                                style="background: #4c5d75; color: white;"
                                data-companylistId=${JSON.stringify(row.id)}
                                data-toggle="modal" data-target="#companyinfomodal"
                                onclick="openCompanyInfoModal(event)"
                            >
                                View Details
                            </button>`
                            : ''
                            }
                            ${row.id === companyId || companyId === "24445513" ? '' :
                                `<button type="button" class="btn btn-sm m-1"
                                    style="background: #4c5d75; color: white;"
                                    data-companylistId=${JSON.stringify(row.id)}
                                    onclick="assignVechiclesButtonClick(event)">
                                    Assign Units
                                </button>`
                            }
                                
                            ${isVisible ?
                                `<button type="button" class="btn btn-sm m-1"
                                    data-companylistId=${JSON.stringify(row.id)}
                                    style="background: #4c5d75; color: white;"
                                    onclick="updatecompanyButtonClick(event)">
                                    Edit
                                </button>`
                                : ''
                            }
                            `;

                        },
                        width: '250px'
                    },

                ],
                "columnDefs": [
                    {
                        "target": [0],
                        "width": '400px'
                    },
                    {
                        "target": [1],
                        "width": '60px'
                    },

                ],
                paging: companyList.length > 0,

                drawCallback: function (settings) {
                    console.log($('#companyTable').DataTable().page.info());
                    console.log(settings.oSavedState);
                }

            });

            table.cells().every(function () {
                $(this.node()).css('border-color', 'rgb(211, 211, 211, 0.3)');
            });

            table.column(4).nodes().to$().addClass(" details-admin-guide");

            const searchInput = $('.dataTables_filter input');
            searchInput.css('color', theme === 'light' ? 'black' : 'white');

            const searchlabel = $('.dataTables_filter label');
            searchlabel.css('color', theme === 'light' ? 'black' : 'white');

            const entriesSelect = $('.dataTables_length select');
            entriesSelect.css('background-color', theme === 'light' ? '#fff' : '#202b60');
            entriesSelect.css('color', theme === 'light' ? 'black' : 'white');

            const entriesLabel = $('.dataTables_length label');
            entriesLabel.css('color', theme === 'light' ? 'black' : 'white');

            const tableInfo = $('.dataTables_info');
            tableInfo.css('color', theme === 'light' ? "#000" : "#fff")

            const paginate = $('.dataTables_paginate paginate_button');
            paginate.css('color', '#000 !important')

            $("#companyTable").on('click', 'tbody tr', function () {
                const data = table.rows(this).data()
                // _handleSetUnitData(data)
            });

            const storedCurrentPage = localStorage.getItem('currentPage');
            const storedSearchKeyword = localStorage.getItem('searchKeyword');

            const currentPage = storedCurrentPage ? parseInt(storedCurrentPage) : 0;
            table.page(currentPage).draw(false);

            if (storedSearchKeyword) {
                table.search(storedSearchKeyword).draw();
            }

            table.on('page.dt', function () {
                localStorage.setItem('currentPage', table.page.info().page);
            });

            table.on('search.dt', function () {
                const searchKeyword = table.search();
                localStorage.setItem('searchKeyword', searchKeyword);
            });

            window.updatecompanyButtonClick = function (event) {
                event.preventDefault();

                let myModal = new window.bootstrap.Modal(document.getElementById('updatecompanymodalJQ'))
                myModal.show()

                const companylistId = event.target.dataset.companylistid;

                const companylistrow = companyList.find(r => r.id === companylistId);


                _handleEditCompany(companylistrow);
                setSelectedCountry(companylistrow.country);
                setCompanyLevel(companylistrow.level)
                setSelectedLevel(companylistrow.level)
                // updateOrderAndDisplayStart('asc');
            };

            window.openCompanyInfoModal = function (event) {
                setInfoModalView(true)
                let myModal = new window.bootstrap.Modal(document.getElementById('companyinfomodal'))
                myModal.show()
                const companylistId = event.target.dataset.companylistid;

                const companylistrow = rows.find(r => r.id === companylistId);
                setSelectedCompany(companylistrow.id)
                _FetchUnitsForCompanyAssign(companylistrow.id)
            }

            window.assignVechiclesButtonClick = function (event) {
                event.preventDefault();

                let myModal = new window.bootstrap.Modal(document.getElementById('assignCompanyVehicles'))
                myModal.show()

                const assignvehicleId = event.target.dataset.companylistid;
                const companylistrow = rows.find(r => r.id === assignvehicleId);
                setSelectedCompany(companylistrow.id)
                _FetchUnitsForCompanyAssign(companylistrow.id);
                // updateOrderAndDisplayStart('asc');

            };

            window.addEventListener('beforeunload', function () {
                localStorage.removeItem('orderColumn');
                localStorage.removeItem('orderDirection');
                localStorage.removeItem('currentPage');
            });

            window.addEventListener('load', function () {
                localStorage.removeItem('orderColumn');
                localStorage.removeItem('orderDirection');
                localStorage.removeItem('currentPage');
            });

            const storedPage = localStorage.getItem('currentPage');

            if (storedPage) {
                const currentPage = parseInt(storedPage);
                table.page(currentPage).draw('page');
            }

            return () => {
                if ($.fn.DataTable.isDataTable('#companyTable')) {
                    table.destroy();
                }
            };

        }, [companyList, theme, rows, rows.companylistrow]);


        return (
            <>
                <div>
                    <table
                        ref={tableRef}
                        id="companyTable"
                        className='display cell-border hover stripe'
                        style={{ width: '100%' }}
                    >

                    </table>
                </div>

                <UpdateCompanyModalJQ theme={theme} selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} />
                {/* <AssignVehiclesJQ theme={theme} user={companyList} /> */}
                <AssignVehiclesCompanyModal theme={theme} user={companyList} />
                <CompanyInfoDetailsModal theme={theme} companyList={companyList} userList={props.userList} />
            </>
        )
    })
);

