import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { CreateContractModal } from "../../Components/Tracking";
import { baseURL } from "../../network/URL";
import { My_upload_image } from "../../Tools";
import { My_Button } from "../../Tools";
import { useTranslation } from "react-i18next";

export const ContractInfo = inject("store")(
  observer((props) => {
    const {t} = useTranslation('admin-panel')
    const { selectedRow, fetchCompany } = props;

    const { _handleUploadImage, uploadLogoImage, photo, _resetImage, _setPhotoImage, _handleEditAccountId, isEditAccountId, _handleAccountIdOnChange, _handleAccountIdSave, editAccountId } = props.store.adminPanel;

    useEffect(() => {
      _setPhotoImage(baseURL + selectedRow.logo);
    }, [selectedRow])

    return (
      <div className="col-lg-6 col-md-6 col-sm-12">
        <h3 className="pb-3" style={{ color: invertDark(props.theme) }}>
          {t("Contract Info")}
        </h3>
        <div className="m-2" style={props.rowStyle}>
          <div className="col-lg-2 col-sm-6 d-flex align-items-center">
            <My_upload_image _handleUploadImage={_handleUploadImage} photo={photo} _resetImage={_resetImage} uploadLogoImage={uploadLogoImage} companyId={selectedRow.id} fetchCompany={fetchCompany} />
          </div>
          <div className="d-flex justify-content-between my-2">
            <span>{t("Project Name")}</span>
            <span>{selectedRow.name}</span>
          </div>
          <div style={{ background: Colors.Dark[4], height: "1px", opacity: 0.1 }} className="my-1"></div>
          <div className="d-flex justify-content-between my-2">
            <span>{t("Country")}</span>
            <span>{selectedRow.country}</span>
          </div>
          <div style={{ background: Colors.Dark[4], height: "1px", opacity: 0.1 }} className="my-1"></div>
          <div className="d-flex justify-content-between my-2">
            <span>{t("Registration Number")}</span>
            <span>{selectedRow.id}</span>
          </div>
          <div style={{ background: Colors.Dark[4], height: "1px", opacity: 0.1 }} className="my-1"></div>
          <div className="d-flex justify-content-between my-2">
            <span>{t("Type")}</span>
            <span>{selectedRow.type || "N/A"}</span>
          </div>
          {/* <div style={{ background: Colors.Dark[4], height: "1px", opacity: 0.1 }} className="my-1"></div>
          <div className="d-flex justify-content-between my-2">
            <span>License Number</span>
            <span>{selectedRow.leiNumber || "N/A"}</span>
          </div>
          <div style={{ background: Colors.Dark[4], height: "1px", opacity: 0.1 }} className="my-1"></div>
          <div className="d-flex justify-content-between my-2">
            <span>License Valid Date</span>
            <span>{selectedRow.leiValidDate || "N/A"}</span>
          </div> */}
          <div style={{ background: Colors.Dark[4], height: "1px", opacity: 0.1 }} className="my-1"></div>
          <div className="d-flex justify-content-between my-2 align-items-center">
            <div className="d-flex align-items-center">
              <span>{t("Ascent Account ID")}</span>
              {/* {isEditAccountId ? null : (<My_Button
                className="mx-1"
                onClick={(e) => _handleEditAccountId(selectedRow.ascentAccountId)}
                text={<i className="bi bi-pencil-fill" />}
                small
              />)} */}
            </div>

            {isEditAccountId ? (
              <div className="col-9 d-flex align-items-center jusitfy-content-end"><input
                className="rounded w-100 text-end"
                type="text"
                value={editAccountId || ""}
                onChange={(e) => _handleAccountIdOnChange(e)}
              />
                <My_Button
                  // disabled={editMode !== '' && true}
                  onClick={(e) => {_handleAccountIdSave(selectedRow.id); fetchCompany()}}
                  text={<i className="bi bi-check" />}
                  small
                />
                <My_Button
                  // disabled={editMode !== '' && true}
                  onClick={(e) => _handleEditAccountId(selectedRow.ascentAccountId)}
                  text={<i className="bi bi-x" />}
                  small
                />
              </div>) : (<span>{selectedRow.ascentAccountId || "N/A"}</span>)}


          </div>
          {/* {selectedRow.trackId == null && (
            <div className="d-flex justify-content-end pt-3">
              <span
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                data-toggle="modal"
                data-target=".bd-example-modal-lg"
              >
                <button
                  type="button"
                  className="btn text-light"
                  style={{
                    background: Colors.lightBlue,
                    // fontSize: 12,
                    // padding: 5,
                    // width: "100%",
                  }}
                >
                  Create Contract
                </button>
              </span>
            </div>
          )} */}
        </div>
        <CreateContractModal
          theme={props.theme}
          cardStyle={props.cardStyle}
          rowStyle={props.rowStyle}
          selectedRow={selectedRow}
          projectName={props.projectName.name}
        />
      </div>
    );
  })
);
