import { Colors } from "../../Assets/Config/color.config";
import React from "react";
import { baseIMGURL } from "../../network/URL";

export const VehicleDetailImage = ({ text, model }) => {
  const imgUrl = (model) => "/cars/" + model + ".jpg";
  return (
    <div className="col-4 col-sm-2 col-lg-2 col-md-2 col-xl-2 pt-2">
      <img
        src={model ? imgUrl(model.replace(/ /g, "_")) : null}
        className="img-fluid"
        alt="car_image"
        style={{ height: "auto", borderRadius: 5 }}
      />

      <div className="text-center d-flex pt-2">
        <button
          type="button"
          className="btn text-light"
          style={{
            background: Colors.lightBlue,
            fontSize: 12,
            padding: 2,
            width: "100%",
          }}
        >
          {text}
        </button>
      </div>
    </div>
  );
};

export const VehicleDetailImageFMS = ({ text, isAds, asset_name, image }) => {
    console.log(image)
    const default_img = '/cars/default_vehicle.png';
  const [imgUrl, setImgUrl] = React.useState( image ? `${baseIMGURL}uploads/devices/${image}` : default_img);

  console.log("imagelrr===>", image)
  console.log("imageURLlrr===>", imgUrl)

  React.useEffect(() => {
    setImgUrl(image ? `${baseIMGURL}uploads/devices/${image}` : default_img);
  }, [asset_name]);

  return (
    <img
      src={imgUrl}
      alt={text}
      className="img-fluid py-1"
      style={{ height: 150, borderRadius: 5 }}
      onError={(e) => {
        setImgUrl(`/cars/default_vehicle.png`);
      }}
    />
  );
};

export const VehicleDetailImage2 = ({ text, isAds, asset_name }) => {
  console.log(text,isAds,asset_name)
  const [imgUrl, setImgUrl] = React.useState(
    `/cars/${
      asset_name
        ? asset_name.split("-")[0].split("_")[0]
        : isAds
        ? "default_equipment.png"
        : "default_vehicle.png"
    }`
  );

  React.useEffect(() => {
    setImgUrl(
      `/cars/${
        asset_name
          ? asset_name.split("-")[0].split("_")[0] + ".jpg"
          : "default_vehicle.png"
      }`
    );
  }, [asset_name]);

  return (
    <img
      src={imgUrl}
      alt={text}
      className="img-fluid py-1"
      style={{ height: 150, borderRadius: 5 }}
      onError={(e) => {
        setImgUrl(
          isAds
            ? `/equipments/default_equipment.png`
            : `/cars/default_vehicle.png`
        );
      }}
    />
  );
};
