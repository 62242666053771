import React from 'react'
import { inject, observer } from "mobx-react";
import { Colors, invertDark } from "../../Assets/Config/color.config";

export const UpgradeInProgress = inject("store")(
    observer((props) => {

    const { theme } = props

    return (
        <div
            style={{
                background: theme === "dark" ? Colors.darkBlue : Colors.Dark[4],
                width: "850px",
                height: '60px'
            }}
            className="py-2 rounded d-flex justify-content-center align-items-center" 
            
        >
            <i
                className="fa fa-exclamation-triangle pe-2 ps-2 text-warning"
                // style={{ color: Colors.fuel }}
            />
            <span style={{ fontWeight: 'bold', color: theme === "dark" ? "white" : ""}}>Upgrade-In-Progress. We are working as fast as possible to bring you more accurate data here. Stay tuned.</span>
        </div>
    )
}))
