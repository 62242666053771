import { inject, observer } from "mobx-react";
import { Colors, invertDark } from "../../Assets/Config/color.config";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import * as React from "react";

import {
  Pagination,
  PaginationItem,
  styled,
  TableSortLabel,
} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { withRouter } from "react-router-dom";
import { VEHICLE_EMISSION } from "../../Assets/Config/constant.config";
import moment from "moment";
import { useEffect } from "react";
import { toJS } from "mobx";
import { useTranslation } from "react-i18next";

export const TrackingTable = inject("store")(
  observer(
    withRouter((props) => {
      const {t} = useTranslation("new-tracking")
      const { theme, _handleViewContract, noPagination } = props;
      const { trackingDetail, handleSelecteDetail, vehicleType , trackingDetailVTwo} =
        props.store.trackingVTwo;
      const Key = [
        "date",
        "start_point",
        "end_point",
        "mileage",
        "co2_emission",
        "emission_factor",
      ];

      const [page, setPage] = React.useState(1);
      const [order, setOrder] = React.useState("asc");
      const [orderBy, setOrderBy] = React.useState("");
      const [selectedIndex, setSelectedIndex] = React.useState("");

      const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
        console.log("property", property);
      };

      const headers = [
        { label: t("Date"), key: "date" },
        { label: t("Start Point"), key: "start_point" },
        { label: t("End Point"), key: "end_point" },
        { label: t("Mileage (km)"), key: "mileage" },
        { label: t("CO2e Emission (kgCO₂)"), key: "co2_emission" },
        { label: t("Emission Factor (gCO₂/km)"), key: "emission_factor" },
      ];

      const rows = trackingDetailVTwo?.map((value) => {
        let trip = {
          id: value.uid,
          date: moment.utc(value.start_time).format("yyyy-MM-DD"),
          start_point: value.start,
          end_point: value.end,
          mileage:
            value.trip_mileage === 0 ? "0.00" : Number(value.trip_mileage),
          co2_emission:
            value.emission === 0 ? "0.00" : value.emission.toFixed(2),
          emission_factor: value.emissionFactor ? value.emissionFactor : "0.00",
        };

        return trip;
      });

      useEffect(() => {
        setSelectedIndex(rows[0]?.id);
      }, []);

      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        cursor: "pointer",
        "&:nth-of-type(odd)": {
          backgroundColor:
            props.theme === "dark" ? Colors.darkBlue : Colors.Dark[5],
          color: theme.palette.common.white,
        },
        // hide last border
        "&:last-child td, &:last-child th": {
          border: 0,
        },
      }));

      const rowCount = 10;
      const handleChange = (event, value) => {
        setPage(value);
      };

      function EnhancedTableHead(props) {
        const {
          order,
          orderBy,
          onRequestSort,
          theme,
          Key,
          _handleViewContract,
        } = props;
        const createSortHandler = (property) => (event) => {
          onRequestSort(event, property);
        };
        return (
          <TableHead>
            <TableRow>
              {headers.map((headCell, index) => (
                <TableCell
                  key={index}
                  align={"left"}
                  padding={headCell.disablePadding ? "none" : "normal"}
                  sortDirection={orderBy === headCell.id ? order : false}
                  style={{
                    background:
                      theme === "dark" ? Colors.darkBlue : invertDark(theme),
                  }}
                >
                  {headCell.label === "" || (
                    <TableSortLabel
                      style={{
                        minWidth: 80,
                        color:
                          orderBy === headCell.id
                            ? Colors.activeColor
                            : theme === "light"
                            ? "#F6F6F6"
                            : Colors.Dark[4],
                      }}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={createSortHandler(Key[index])}
                    >
                      <span className="fw-bold">{headCell.label}</span>
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        );
      }

      function descendingComparator(a, b, orderBy) {
        const A = a[orderBy] ? a[orderBy].trim() : undefined;
        const B = b[orderBy] ? b[orderBy].trim() : undefined;
        if (B < A) {
          return -1;
        }
        if (B > A) {
          return 1;
        }
        return 0;
      }

      function getComparator(order, orderBy) {
        return order === "desc"
          ? (a, b) => descendingComparator(a, b, orderBy)
          : (a, b) => -descendingComparator(a, b, orderBy);
      }

      const useStyles = makeStyles({
        foo: {
          "& .MuiPagination-ul": { justifyContent: "end" },
          "& .MuiPaginationItem-text": {
            color: Colors.Dark[theme === "dark" ? 4 : 1],
          },
          "& .Mui-selected": {
            backgroundColor: Colors.Dark[4],
            color: Colors.darkBlue,
          },
        },
      });

      const classes = useStyles();

      return (
        <React.Fragment>
          <TableContainer
            component={Paper}
            style={{ background: "none" ,maxHeight: 600, height: '100%'}}
            // sx={{ maxHeight: 600 }}
          >
            <Table
              stickyHeader
              sx={{ minWidth: 650 ,maxHeight: 600, height: '100%' }}
              size="small"
              aria-label="a dense sticky table"
            >
              <EnhancedTableHead
                _handleViewContract={_handleViewContract}
                Key={Key}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                theme={theme}
              />
              <TableBody>
                {rows.length > 0 &&
                  rows
                    .sort(getComparator(order, orderBy))
                    .slice((page - 1) * rowCount, page * rowCount)
                    .map((row, index) => (
                      <StyledTableRow
                        style={{
                          background:
                            selectedIndex === row.id ? Colors.Dark[3] : "",
                        }}
                        key={index}
                        onClick={async () => {
                          let result = trackingDetailVTwo?.filter(
                            (value) => value.uid === row.id
                          )[0];
                          handleSelecteDetail(result, index);
                          setSelectedIndex(row.id);
                        }}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {Object.values(row)
                          .filter((f) => {
                            console.log("f", f);
                            return f && !f.toString().includes("_");
                          })
                          .map((d, x) => (
                            <TableCell
                              sx={{
                                color: invertDark(theme),
                                borderBottom: "none",
                              }}
                              key={x}
                            >
                              {d === "View" ? (
                                <span
                                  // className="btn text-light w-100"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                  data-toggle="modal"
                                  data-target=".bd-example-modal-lg"
                                >
                                  {d}
                                </span>
                              ) : (
                                d
                              )}
                            </TableCell>
                          ))}
                      </StyledTableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          {noPagination || (
            <div className="py-2">
              <Pagination
                size="small"
                renderItem={(item) => (
                  <PaginationItem
                    {...item}
                    sx={{ color: invertDark(theme) }}
                    classes={{ selected: classes.selected }}
                  />
                )}
                className={classes.foo}
                count={
                  rows.length <= rowCount
                    ? 1
                    : Math.ceil(rows.length / rowCount)
                }
                defaultPage={1}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                onChange={handleChange}
              />
            </div>
          )}
        </React.Fragment>
      );
    })
  )
);
