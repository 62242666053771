import React from 'react'
import { useState } from 'react';
import { inject, observer } from "mobx-react";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import autoTable from "jspdf-autotable";
import { invertDark } from '../../Assets/Config/color.config';
import { Colors } from '../../Assets/Config/color.config'
import * as XLSX from 'xlsx';
import * as FileSaver from "file-saver";
import { Button } from 'react-bootstrap';
import { CompanySelector } from '../Dashboard/companySelector';
import moment from "moment";
import { Cookies } from 'react-cookie';
import { toJS } from 'mobx';


export const LogsReport = inject("store")(
    observer((props) => {
        const cookies = new Cookies();
        const { theme, showLogView } = props;
        const { companyId } = props.store.common;
        const { issuesData, logsData, startDate, endDate } = props.store.adminPanel;
        const companyName = cookies.get("companyName")

        const logRows = logsData.filter((data) => {
            var dataTime = moment(data.time).format("YYYY-MM-DD HH:mm:ss");
            return (
                moment(dataTime).isSameOrAfter(
                    moment(startDate).add(0, "day").format("YYYY-MM-DD HH:mm:ss")
                ) &&
                moment(dataTime).isSameOrBefore(
                    moment(endDate).add(+1, "day").format("YYYY-MM-DD HH:mm:ss")
                )
            );
        });

        const issueRows = issuesData?.filter((data) => {
            var dataTime = moment(data.issue_time).format("YYYY-MM-DD HH:mm:ss");
            return (
                moment(dataTime).isSameOrAfter(
                    moment(startDate).add(0, "day").format("YYYY-MM-DD HH:mm:ss")
                ) &&
                moment(dataTime).isSameOrBefore(
                    moment(endDate).add(+1, "day").format("YYYY-MM-DD HH:mm:ss")
                )
            );
        });

        const modifiedIssueRows = issueRows.map(v => {
            let rows = {
                issue_time: v.issue_time,
                issue_company: v.issue_company,
                issue: v.issue,
                issue_detail: v.issue_detail,
                issue_value: v.issue_value.length > 0 ? v.issue_value : "N/A"
            }
            return rows
        })

        const rows = showLogView === "logs" ? logRows : modifiedIssueRows;


        var wsTableData = rows && XLSX.utils.json_to_sheet(rows);
        var csvTableData = XLSX.utils.sheet_to_csv(wsTableData);

        function s2ab(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
            return buf;
        }

        const generatePDF = () => {

            const doc = new jsPDF("l", undefined, "a4");

            if (!rows || rows.length === 0) {
                console.error('No data available for PDF generation');
                return;
            }


            doc.text(
                15,
                30,
                `Units Report for - ${companyName} `,
            );

            autoTable(doc, {
                styles: { fontSize: 10 },
                startY: 50,
                body: rows,
                columns: showLogView === "logs" ? [
                    { header: "Time", dataKey: "time" },
                    { header: "Name", dataKey: "name" },
                    { header: "Action", dataKey: "action" },
                    { header: "Action Detail", dataKey: "action_detail" },
                ]
                    :
                    [
                        { header: "Issue Time", dataKey: "issue_time" },
                        { header: "Company", dataKey: "issue_company" },
                        { header: "Issue", dataKey: "issue" },
                        { header: "Issue Detail", dataKey: "issue_detail" },
                        { header: "Issue Value", dataKey: "issue_value" },
                    ]
            });
            doc.save(showLogView === "logs" ? `${companyName}_Logs_Table_Report.pdf` : `${companyName}_Issues_Table_Report.pdf`);

        };

        const downloadXLSX = () => {
            FileSaver.saveAs(
                new Blob(
                    [
                        s2ab(csvTableData),
                    ],
                    { type: "application/octet-stream" }
                ),
                showLogView === "logs" ? `${companyName}_Logs_Table_Report.csv` : `${companyName}_Issues_Table_Report.csv`
            );
        };
        return (
            <div
                className="modal fade"
                id="logsreport"
                tabIndex="-1"
                aria-labelledby="logsreport"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content" style={{
                        width: '1200px'
                    }}>
                        <div className="modal-header">
                            <h5 className="modal-title d-flex justify-content-center align-items-center" style={{ color: 'black' }}><span>Units Table Report - </span><CompanySelector /></h5>
                            <button type="button" className="btn-close" aria-label="Close" data-bs-dismiss="modal"></button>
                        </div>
                        <div className="modal-body" style={{ overflowY: 'scroll', height: '550px', color: 'black' }}>
                            {/* {showPreview && ( */}
                            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                <thead>
                                    <tr style={{ border: '1px solid gray' }}>
                                        {rows && rows[0] && Object.keys(rows[0]).map((key, index) => (
                                            <th style={{ border: '1px solid silver', padding: '30px 8px', textAlign: 'left' }} key={index}>
                                                {key.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase())}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {rows?.map((row, rowIndex) => (
                                        <tr key={rowIndex} style={{ border: '1px solid silver' }}>
                                            {Object.values(row).map((value, cellIndex) => (
                                                <td style={{ border: '1px solid silver', padding: '8px' }} key={cellIndex}>{value}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            {/* )} */}
                        </div>
                        <div className="modal-footer">
                            <Button
                                type="button"
                                onClick={() => generatePDF()}
                                style={{
                                    background: "#4c5d75",
                                    color: "white",
                                    border: "none",
                                    padding: "8px"
                                }}
                                className="btn btn-sm detail-report-guide"
                            >
                                Download PDF Report
                            </Button>

                            <Button
                                type="button"
                                onClick={() => downloadXLSX()}
                                style={{
                                    background: "#4c5d75",
                                    color: "white",
                                    border: "none",
                                    padding: "8px"
                                }}
                                className="btn btn-sm detail-report-guide"
                            >
                                Download XLSX Report
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }))

