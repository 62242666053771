import { useTranslation } from "react-i18next";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { My_Button } from "../../Tools";
import { inject, observer } from "mobx-react";

export const ConfirmDeleteModal = inject("store")(
    observer((props) => {
        const {t} = useTranslation("admin-panel")
        const { _handleDeleteUser } = props.store.adminPanel;
        const { theme } = props
        const cardStyle = { color: invertDark(theme), background: Colors[theme].background }
        return (
            <div
                className={`modal fade`}
                id="deleteModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-md rounded">
                    <div className="modal-content" style={cardStyle}>
                        <div className="modal-header" style={{ border: "none" }}>
                            <h3>{t("Warning")} !</h3>
                            <button
                                type="button"
                                className={`btn-close ${theme === "dark" ? "btn-close-white" : null
                                    } `}
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            {t("Are You Sure do you want to delete user?")}
                        </div>
                        <div className="text-end p-2">
                            <span data-bs-dismiss="modal"
                                aria-label="Close">
                                <My_Button
                                    id="delete"
                                    //   onClick={props._handleCreateUser}
                                    type="button"
                                    className="btn text-light me-2"
                                    style={{
                                        // background: Colors.lightBlue,
                                        // fontSize: 12,
                                        // padding: 5,
                                    }}

                                    text={t('Cancel')}
                                // data-bs-dismiss="modal"
                                /></span>
                            <span data-bs-dismiss="modal"
                                aria-label="Close" style={{opacity:0.7}}>
                                <My_Button
                                    id="cancel"
                                    onClick={() => {_handleDeleteUser(props.user.id)
                                    props.setEditUser(null)}}
                                    type="button"
                                    className="btn text-light"
                                   customColor='red'
                                    text={t('Delete')}
                                // data-bs-dismiss="modal"
                                />
                            </span>

                        </div>
                    </div>
                </div>
            </div>
        );
    }));
