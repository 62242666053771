import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { toJS } from "mobx";

export const UnitProfilesInfoModal = inject("store")(
    observer((props) => {
        const { theme, unitProfile } = props
        const cardStyle = {
            color: invertDark(theme),
            background: Colors[theme].background,
        };

        
        return (
            <div class="modal fade" id="unitprofilesmodal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content" style={cardStyle}>
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">More Informations - {unitProfile?.grouping || "N/A"}</h5>
                            <span data-bs-dismiss="modal" style={{ cursor: 'pointer', marginRight: 5 }}>
                                <i className="bi bi-x-lg" />
                            </span>
                        </div>
                        <div class="modal-body">
                            <div>
                                <div className="row pb-2">
                                    <span className="col-5">Vin Number</span>
                                    <span className="col-1">:</span>
                                    <span className="col-6 text-end">{unitProfile?.vin_number || "N/A"}</span>
                                </div>
                                <div className="row pb-2">
                                    <span className="col-5">Vehicle Type</span>
                                    <span className="col-1">:</span>
                                    <span className="col-6 text-end">{unitProfile?.vehicle_type || "N/A"}</span>
                                </div>
                                <div className="row pb-2">
                                    <span className="col-5">Vehicle Sub Type</span>
                                    <span className="col-1">:</span>
                                    <span className="col-6 text-end">{unitProfile?.vehicle_sub_type || "N/A"}</span>
                                </div>
                                <div className="row pb-2">
                                    <span className="col-5">Vehicle Width</span>
                                    <span className="col-1">:</span>
                                    <span className="col-6 text-end">{unitProfile?.vehicle_width || "N/A"}</span>
                                </div>
                                <div className="row pb-2">
                                    <span className="col-5">Vehicle Length</span>
                                    <span className="col-1">:</span>
                                    <span className="col-6 text-end">{unitProfile?.vehicle_length || "N/A"}</span>
                                </div>
                                <div className="row">
                                    <span className="col-5">Vehicle Height</span>
                                    <span className="col-1">:</span>
                                    <span className="col-6 text-end">{unitProfile?.vehicle_high || "N/A"}</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }))

