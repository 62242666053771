import { observable, action, makeObservable, runInAction } from "mobx";
import { Cookies } from "react-cookie";
import { getCompanyList } from "../network/fetchLanding";
import { toast } from "react-toastify";
import { io } from "socket.io-client";
import jwt_decode from "jwt-decode";
import { getCompanyYears } from "../network/fetchLanding";
const moment = require("moment");

class Common {
  width = window.innerWidth;
  cookies = new Cookies();
  companyType = this.cookies.get("companyType") || "1";
  token = this.cookies.get("accessToken");
  userId = this.token && jwt_decode(this.token).userId;
  role = this.token && jwt_decode(this.token).role;
  userType = this.cookies.get("userType");
  companyName = this.token && jwt_decode(this.token).companyName;
  landingAscent = this.token && jwt_decode(this.token).setting;
  companyId = this.token && jwt_decode(this.token).companyId;
  logo = this.token && jwt_decode(this.token).logo;
  showStacs = this.token && jwt_decode(this.token).isShow_stacs;
  // level = this.token && jwt_decode(this.token).level;
  level = this.cookies.get("level");
  userName = this.token && jwt_decode(this.token).username;
  ascentTokenKey = this.token ? jwt_decode(this.token).ascentTokenKey : "";
  ascentAccountId = this.token ? jwt_decode(this.token).ascentAccountId : "";
  loginTime = this.token
    ? jwt_decode(this.token).loginTime
    : moment().format("DD/MM/yyyy hh:mm A");
  theme = localStorage.getItem("theme");
  companyList = [];
  lastUpdate = null;
  isAds = false;
  companyYears = [];

  constructor() {
    makeObservable(this, {
      loginTime: observable,
      showStacs: observable,
      userType: observable,
      userId: observable,
      userName: observable,
      ascentTokenKey: observable,
      ascentAccountId: observable,
      width: observable,
      lastUpdate: observable,
      isAds: observable,
      companyList: observable,
      role: observable,
      token: observable,
      theme: observable,
      companyId: observable,
      landingAscent: observable,
      companyName: observable,
      logo: observable,
      companyYears: observable,
      companyType: observable,

      windowResize: action.bound,
      fetchCompanyList: action.bound,
      _handleLastUpdateTime: action.bound,
      fetchCompanyYears: action.bound,
    });
  }

  fetchCompanyList() {
    getCompanyList(this.token, this.companyId, (err, data) => {
      if (data) {
        runInAction(() => {
          if (data.message === "Token Expired!") window.location.pathname = "/";
          else this.companyList = data;
        });
      } else {
        toast.error(err.message);
      }
    });
  }
  fetchCompanyYears() {
    getCompanyYears(this.token, this.companyId, (err, data) => {
      console.log("=============>", data);
      if (data) {
        runInAction(() => {
          if (data.message === "Token Expired!") window.location.pathname = "/";
          else this.companyYears = data;
        });
      } else {
        toast.error(err.message);
      }
      console.log("+++++++++++++", this.companyYears);
    });
  }

  windowResize = (e) => {
    window.addEventListener("resize", () => {
      runInAction(() => {
        this.width = window.innerWidth;
      });
    });
    return () => {
      window.removeEventListener("resize", () => {
        runInAction(() => {
          this.width = window.innerWidth;
        });
      });
    };
  };

  _handleLastUpdateTime = () => {
    const socket = io("https://localhost:8000/", {
      path: "/update",
      transports: ["websocket"],
    });
    runInAction(() => {
      this.lastUpdate = null;
    });
    try {
      socket.on("FromAPI", (data) => {
        runInAction(() => {
          this.lastUpdate = data;
        });
      });
    } catch (e) {
      window.alert(e);
    }
  };
}

export default new Common();
