import { toJS } from "mobx";
import React from "react";
import { Colors } from "../../Assets/Config/color.config";

const Player = (props) => {
  const { selectedVideo,theme } = props;
  // console.log("selected Video ===>",toJS(selectedVideo))

  const videoSrc = `https://www.youtube.com/embed/${selectedVideo?.snippet?.resourceId?.videoId}?autoplay=1`;
  // console.log("videoSrc ====>",videoSrc)

  return (
    <div className="video-player w-100 ">
      {selectedVideo && (
        <div className=" card" style={{
                  background: Colors[theme].background

        }}>
        <iframe
        className="hvideo"
        style={{border:"1px solid linear-gradient(65deg,#101841,#202b60)"}}
          // width="80%"
          height="580px"
          src={videoSrc}
          frameborder="0"
          allowfullscreen="true"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
        </div>
      )}
    </div>
  );
};

export default Player;
