import React, { useState } from 'react'
import { inject, observer } from "mobx-react";
import { Colors } from "../../Assets/Config/color.config";

export const UnitTab = inject("store")(
    observer((props) => {

        const { _handleChangeUnitsTab, theme, unitsTab } = props
        const [hoverTab, setHoverTab] = useState(null)

        const AllUnitsTab = {
            fontSize: "14px!important",
            width: "110px",
            height: "38px",
            borderTopRightRadius: "5px",
            borderBottomRightRadius: "5px",
            border: theme === "dark" ? "1px solid #76A7DC" : "1px solid #198754",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: theme === "dark" ? unitsTab === "all" ? "#222B5D" : hoverTab === "all" ? "#222B5D" : "#76A7DC" : unitsTab === "all" ? "white" : hoverTab === "all" ? "white" : "#198754",
            backgroundColor: theme === "dark" ? unitsTab === "all" ? "#76A7DC" : hoverTab === "all" ? "#76A7DC" : "transparent" : unitsTab === "all" ? "#198754" : hoverTab === "all" ? "#198754" : "transparent",
            transition: "backgroundColor 0.3s, color 0.3s, borderColor 0.3s"
        }

        const OwnUnitsTab = {
            fontSize: "14px!important",
            width: "110px",
            height: "38px",
            borderTopLeftRadius: "5px",
            borderBottomLeftRadius: "5px",
            border: theme === "dark" ? "1px solid #76A7DC" : "1px solid #198754",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: theme === "dark" ? unitsTab === "own" ? "#222B5D" : hoverTab === "own" ? "#222B5D" : "#76A7DC" : unitsTab === "own" ? "white" : hoverTab === "own" ? "white" : "#198754",
            backgroundColor: theme === "dark" ? unitsTab === "own" ? "#76A7DC" : hoverTab === "own" ? "#76A7DC" : "transparent" : unitsTab === "own" ? "#198754" : hoverTab === "own" ? "#198754" : "transparent",
            transition: "backgroundColor 0.3s, color 0.3s, borderColor 0.3s",
        }


        return (
            <div className="btn-group btn-group-sm profileDetails-unitLevel-guide" role="group">

                <div
                    type="button"
                    style={
                        OwnUnitsTab
                    }
                    onMouseEnter={() => setHoverTab("own")}
                    onMouseLeave={() => setHoverTab(null)}
                    onClick={() => _handleChangeUnitsTab("own")}
                >
                    Own Units
                    <i className="fas fa-info-circle" title='Only records pertaining to Scope 1 and 2 carbon emissions are calculated and reflected here. This is reflects the carbon emissions for the vehicular fleet that your organisation directly owns.'
                        style={{
                            cursor: "pointer",
                            paddingLeft: "5px"
                        }}
                    />
                </div>
                <div
                    type="button"
                    style={AllUnitsTab}
                    onMouseEnter={() => setHoverTab("all")}
                    onMouseLeave={() => setHoverTab(null)}
                    onClick={() => _handleChangeUnitsTab("all")}
                >
                    All Units
                    <i className="fas fa-info-circle" title='Scope 1, 2, and 3 carbon emissions are calculated and reflected here. This is reflects the carbon emissions for the vehicular fleet that your organisation directly owns as well as those that are owned by your vendors or sub-contractors.'
                        style={{
                            cursor: "pointer",
                            paddingLeft: "5px"
                        }}
                    />
                </div>

            </div>

        )
    }))