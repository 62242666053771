import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Selector } from "../../Tools";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import userImage from "../../Assets/Images/icons/user.png";
import { UserDetailReportTable } from "./UserDetailReportTable";
import { useTranslation } from "react-i18next";

export const ReportList = inject("store")(
  observer((props) => {
    const { t } = useTranslation("admin-panel");
    const { theme } = props;
    const history = useHistory();
    const {
      _routeToUserlist,
      userDetail,
      reportData,
      companies,
      _fetchCompanyList,
    } = props.store.adminPanel;
    const [reportType, setReportType] = useState("pending");
    // useEffect(()=> {
    //     _fetchReportList({userId:109})
    // })

    const CompanyOptions = companies.map((v) => ({
      value: v.id,
      label: v.name,
    }));

    CompanyOptions.unshift({ value: "All", label: "All" });

    const { role, companyId } = props.store.common;

    useEffect(() => {
      _fetchCompanyList(role, companyId);
    }, []);

    const [comId, setComId] = useState("All");

    const filteredData = reportData?.response?.filter(
      (v) => v.company_id === comId
    );

    const allFilteredData = reportData?.response?.map((v) => v);

    return (
      <div className="px-3" style={{ color: invertDark(theme) }}>
        <div className="d-flex justify-content-between">
          {userDetail ? (
            <>
              <div className="d-flex">
                <img className="pe-2" height={30} src={userImage} />
                <h4>{userDetail.username}</h4>
              </div>
              <h6
                style={{ cursor: "pointer" }}
                onClick={() => _routeToUserlist(history)}
              >
                <i className="bi bi-arrow-left-circle pe-2"></i>
                {t("Back to User List")}
              </h6>
            </>
          ) : (
            ""
          )}
        </div>
        {/* <div className="d-flex justify-content-between"> */}
        <div className="d-flex flex-column flex-md-row justify-content-between">
          <div className="d-flex text-center pb-2">
            <div
              // className="col-lg-8 col-md-6 col-sm-6 col-xs-6 p-2 d-flex"
              className="col-lg-8 col-md-6 col-sm-6 col-6 p-2 d-flex"
              style={{
                // background: v === selectedTab ? Colors.Dark[4] : null,
                borderBottom:
                  "pending" === reportType
                    ? `3px solid ${Colors.lightBlue}`
                    : "none",
                cursor: "pointer",
                fontWeight: "pending" === reportType ? "bold" : "normal",
                color: invertDark(props.theme),
              }}
              onClick={() => setReportType("pending")}
            >
              <i
                style={{ color: "yellow" }}
                className="bi bi-info-circle px-1"
              />{" "}
              {t("Pending Reports")}
            </div>
            <div
              // className="col-lg-8 col-md-8 col-sm-6 col-xs-6 p-2"
              className="col-lg-8 col-md-8 col-sm-6 col-6 p-2"
              style={{
                // background: v === selectedTab ? Colors.Dark[4] : null,
                borderBottom:
                  "approved" === reportType
                    ? `3px solid ${Colors.lightBlue}`
                    : "none",
                cursor: "pointer",
                fontWeight: "approved" === reportType ? "bold" : "normal",
                color: invertDark(props.theme),
              }}
              onClick={() => setReportType("approved")}
            >
              <i style={{ color: "#06ff06" }} className="bi bi-check2-circle" />{" "}
              {t("Approved Reports")}
            </div>
          </div>

          {/* <div className="d-flex justify-content-center  col-lg-4 col-md-6"> */}
          {/* <div className="d-flex justify-content-center col-lg-4 col-md-6 mt-2 mt-md-0">
            {!userDetail ? (
              <FilterBar
                theme={theme}
                role={role}
                comId={comId}
                setComId={setComId}
                CompanyOptions={CompanyOptions}
              />
            ) : (
              ""
            )}
          </div> */}
        </div>
        <UserDetailReportTable
          reportType={reportType}
          // noPagination
          theme={theme}
          lightWebform
          createData={(
            id,

            createdAt,
            company_id,
            name,
            username,

            asset_categorization,
            vehicle_type,
            plate_number,
            fuel_type,
            total_consumption,

            total_mileage,
            report_type,
            period_start,
            period_end,
            remark,
            status,
            action
          ) => {
            return {
              id,

              createdAt,
              company_id,
              name,
              username,

              asset_categorization,
              vehicle_type,
              plate_number,
              fuel_type,
              total_consumption,

              total_mileage,
              report_type,
              period_start,
              period_end,
              remark,
              status,
              action,
            };
          }}
          Key={[
            "id",

            "createdAt",
            "company_id",
            "name",
            "username",

            "status",
            "asset_categorization",
            "vehicle_type",
            "plate_number",
            "fuel_type",
            "total_consumption",

            "total_mileage",
            "report_type",
            "period_start",
            "period_end",
            "remark",
            "status",
            "action",
          ]}
          data={comId === "All" ? allFilteredData : filteredData}
          header={[
            { id: "createdAt", label: t("Created At") },
            { id: "name", label: t("Company Name") },
            { id: "username", label: t("Requested User") },
            { id: "asset_categorization", label: t("Asset Categorization") },
            { id: "vehicle_type", label: t("Vehicle Type") },
            { id: "plate_number", label: t("Plate Number") },
            { id: "fuel_type", label: t("Fuel Type") },
            { id: "total_consumption", label: t("Total Consumption(Litres)") },

            { id: "total_mileage", label: t("Total Mileage(km)") },
            { id: "report_type", label: t("Report Type") },
            { id: "period_start", label: t("Start Period") },
            { id: "period_end", label: t("End Period") },
            { id: "remark", label: t("Remark") },
            { id: "action", label: "" },
          ]}
        />
      </div>
    );
  })
);

export const FilterBar = (props) => {
  const { t } = useTranslation("admin-panel");
  const { CompanyOptions, comId, setComId, role, theme } = props;

  return (
    <div
      style={{ marginLeft: "150px" }}
      className="d-flex justify-content-center align-items-center"
    >
      {role === "super_admin" ? (
        <div
          className="row d-flex align-items-center pe-3"
          style={{ fontSize: 14 }}
        >
          <span className="col text-end p-0 me-2">
            <i className="bi bi-building pe-2"></i>
            {t("Company")} :
          </span>
          <span className="col m-0" style={{ width: 300 }}>
            <Selector
              _handleSelect={(e) => setComId(e.value)}
              value={CompanyOptions.find((v) => v.value === comId)}
              menuListColor="black"
              options={CompanyOptions}
            />
          </span>
        </div>
      ) : (
        <div
          style={{ fontSize: 18, color: theme === "dark" ? "white" : "black" }}
        >
          <span className="p-2">
            {" "}
            <i className="bi bi-building pe-2"></i>
            {CompanyOptions[0].label}
          </span>
        </div>
      )}
    </div>
  );
};

// const data = [
//     {
//         asset_id: "T09232",
//         companyId: "88883333",
//         consumed: "555",
//         createdAt: "2022-12-07T05:12:31.000Z",
//         id: "35f4076b-ded0-4403-94d4-d5910977d8e4",
//         mileage: "555",
//         mileage_unit: "km",
//         period_end: "2021-12-30",
//         period_start: "2021-01-01",
//         report_type: "Yearly",
//         status: "0",
//         type: "Diesel",
//         type_unit: "Liters(L)",
//         uid: "73037328",
//         updatedAt: "2022-12-07T05:12:31.000Z",
//         vehicle_type: "4-RD",
//     },
//     {
//         asset_id: "T04721",
//         companyId: "88883333",
//         consumed: "123",
//         createdAt: "2022-12-07T05:12:31.000Z",
//         id: "35f4076b-ded0-4403-94d4-d5910977d8e4",
//         mileage: "632",
//         mileage_unit: "km",
//         period_end: "2021-12-30",
//         period_start: "2021-01-01",
//         report_type: "Yearly",
//         status: "0",
//         type: "Diesel",
//         type_unit: "Liters(L)",
//         uid: "73037328",
//         updatedAt: "2022-12-07T05:12:31.000Z",
//         vehicle_type: "4-RD",
//     },
// ];
