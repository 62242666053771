import { inject, observer } from "mobx-react";
import { Colors } from "../../Assets/Config/color.config";
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const ImageUpload = inject("store")(
    observer((props) => {

        const { currentImage, label, _handleImageChange, fileType, _handleClearImage, onClick,imgref, index,uploadFileType} = props;
        
        console.log("currentImage==>", currentImage)

        return (
            <span
                className="position-relative d-flex align-items-center justify-content-center m-2"
                style={{
                    height: 100,
                    width: 90,
                    border: "1px dashed #C6C6C6"
                }}
            >
                <div className="d-flex align-items-center" style={{ borderRadius: 5 }}
                    data-bs-toggle={currentImage === "" ? "" : "modal"}
                    data-bs-target={currentImage === "" ? "" : "#imageViewDetail"}>
                    <label
                        style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: 5,
                            cursor: "pointer",
                        }}
                        htmlFor={`image-upload${fileType}`}
                    >

                        {/* <input
                            type="file"
                            style={{ display: "none" }}
                            id={`image-upload${fileType}`}
                            accept="image/x-png,image/gif,image/jpeg"
                            onChange={(e) => { _handleImageChange(e, fileType) }}
                        /> */}

                        {!currentImage || currentImage === "" ?
                            <span className="d-flex flex-column justify-content-center align-items-center h-100 p-0 m-2">
                                <i style={{ fontSize: 16 }} className="fas fa-image" />
                                <div className="mt-2" style={{ fontSize: 12, textAlign: "center" }}>{label}</div>
                            </span>
                            : <span className="d-flex justify-content-center m-3"
                                onChange={onClick}>
                                {uploadFileType !== 'application/pdf' ?
                                    <img src={currentImage} height={'90px'} width={fileType === "fuel" ? '120px' : '80px'} ref={imgref} />
                                    :
                                    (<>
                                    <Document
                                        file={currentImage}
                                    >
                                        <Page pageNumber={1} scale={0.095} className="preview"/>
                                    </Document>
                                    </>
                                    )}
                            </span>}
                    </label>
                </div>
                {!currentImage || currentImage === "" ? null :
                    <span
                        onClick={() =>  _handleClearImage(fileType, index) }
                        style={{
                            position: "absolute",
                            top: -7,
                            right: -7,
                            color: Colors.Dark[5],
                            cursor: "pointer",
                            background: "red",
                            borderRadius: "50%",
                            width: 15,
                            height: 15,
                        }}
                        className="d-flex align-items-center justify-content-center"
                    >
                        <i className="bi bi-x m-1"></i>
                    </span>}
            </span>
        );
    })
)