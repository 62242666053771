import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import EntryTimeline from "./EntryTimeline";
import { Selector } from "../../Tools";
import {
  fetchEditManualEntry,
  fetchGetManualEntry,
  fetchUpdateFuelRecords,
} from "../../network/manualEntryAPI";
import { Cookies } from "react-cookie";
import format from "date-fns/format";
import { toast } from "react-toastify";
import VehicleMonthlyDetailTable from "../../Components/ManualEntry/vehicleMonthlyDetailTable";
import EquipmentMonthlyDetailTable from "../../Components/ManualEntry/equipmentMonthlyDetailTable";
import VehicleEditForm from "../../Components/ManualEntry/vehicleEditForm";
import EquipmentEditForm from "../../Components/ManualEntry/equipmentEditForm"
import { CO2_Loading } from "../../Assets/CO2_Loading";
import { getYear } from "date-fns";
import { CompanySelector } from "../../Components/Dashboard/companySelector";
import { withRouter } from "react-router-dom";
import { NotificationCard } from "../../Tools/lastUpdate";
import { toJS } from "mobx";

const ManualEntryContainer = (props) => {
  const { windowResize, lastUpdate, _handleLastUpdateTime, token } =
    props.store.common;
  const { isAds } = props.store.common
  const {
    currentYear,
    setCurrentYear,
    currentMonth,
    setCurrentMonth,
    selectTable,
    setSelectTable,
  } = props.store.manualEntry;
  const cookie = new Cookies();
  const companyId = cookie.get('company') || props.store.common.companyId
  const theme = localStorage.getItem("theme");
  const [monthlyEntry, setMonthlyEntry] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [open, setOpen] = useState(false);
  const [fuelRecords, setFuelRecords] = useState([]);
  const [hasChangedFuelRecords, setHasChangedFuelRecords] = useState(false);
  const [hasChangedStartOfMonthImage, setHasChangedStartOfMonthImage] =
    useState(false);
  const [hasChangedEndOfMonthImage, setHasChangedEndOfMonthImage] =
    useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingFuelRecords, setIsSubmittingFuelRecords] = useState(false);
  const [vehicleUpdateData, setVehicleUpdateData] = useState({
    uid: null,
    fuelType: null,
    fuelConsumed: null,
    odometer: null,
    tripMileage: null,
    date: new Date(),
  });
  const [equipmentUpdateData, setEquipmentUpdateData] = useState({
    deviceId: null,
    powerConsumed: null,
    odometer: null,
    date: new Date(),
  });
  useEffect(() => {
    windowResize();
  }, [windowResize,theme]);

  useEffect(() => {
    setCurrentYear(getYear(new Date()));
  }, []);

  useEffect(() => {
    fetchEntry();
    handleIconClick();
    // _handleLastUpdateTime();
  }, [currentYear]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleIconClick = (value, index) => {
    setCurrentMonth(index);
    setSelectedVehicle(null);
    setSelectTable(value);
  };

  const fetchEntry = () => {
    const data_ = isAds ? { companyId: companyId, year: currentYear, siteId: 14 } : { companyId: companyId, year: currentYear };
    fetchGetManualEntry(token, data_, (err, data) => {
      if (data) {
        if (data.message === "Token Expired!") {
          toast.info(data.message);
          window.location.pathname='/';
        }
        setMonthlyEntry(data.consumptionsByMonth);
        const tdyDate = new Date();
        if (getYear(tdyDate) === Number(currentYear)) {
          // const index = getMonth(tdyDate);
          const selectTable = data.consumptionsByMonth[currentMonth];
          if (selectTable) {
            setSelectTable(data.consumptionsByMonth[currentMonth]);
            setCurrentMonth(currentMonth);
          } else {
            const lastIndex = data.consumptionsByMonth.length - 1;
            setSelectTable(data.consumptionsByMonth[lastIndex]);
            setCurrentMonth(lastIndex);
          }
        } else {
          // const index = getMonth(tdyDate);
          const selectTable = data.consumptionsByMonth[currentMonth];

          if (selectTable) {
            // const index = data.consumptionsByMonth.findIndex(v=> v);
            setSelectTable(data.consumptionsByMonth[currentMonth]);
            setCurrentMonth(currentMonth);
          } else {
            const index = data.consumptionsByMonth.findIndex((v) => v);
            setSelectTable(data.consumptionsByMonth[index]);
            setCurrentMonth(index);
          }
        }
      } else {
        console.log(err);
      }
    });
  };

  const handleClear = () => {
    setSelectedVehicle(null);
    const newObj = {
      uid: "",
      licensePlate: "",
      fuelConsumed: "",
      fuelType: "",
      odometer: "",
      tripMileage: "",
      date: "",
    };
    setVehicleUpdateData(newObj);
  };

  const handleUpdateFuelRecords = (uid, year, month) => {
    setIsSubmittingFuelRecords(true);
    const data_ = {
      companyId: companyId,
      uid: uid,
      year: year,
      month: month + 1,
      fuelRecords: fuelRecords,
    };
    const fData = new FormData();
    Object.entries(data_).forEach(([key, value]) => {
      if (key === "fuelRecords") {
        for (let i = 0; i < value.length; i++) {
          fData.append("fuelRecords", value[i]);
        }
      } else {
        fData.append(key, value);
      }
    });

    fetchUpdateFuelRecords(token, fData, (err, data) => {
      if (data) {
        if (data.error) {
          toast.error(data.error);
        } else if (data.message) {
          toast.error(data.message);
        } else {
          const index = selectTable.vehicles.findIndex(
            (v) => v.uid === data_.uid
          );
          const newObj = { ...selectTable };
          newObj.vehicles.splice(index, 1, {
            ...newObj.vehicles[index],
            fuelRecordsFiles: data.fuelRecordsFiles,
          });
          setFuelRecords(data.fuelRecordsFiles);
          setHasChangedFuelRecords(false);
          setSelectTable(newObj);
          setMonthlyEntry((monthlyEntry) => {
            const newMonthlyEntry = [...monthlyEntry];
            newMonthlyEntry[month] = newObj;
            return newMonthlyEntry;
          });
          toast.success("Fuel Records Updated Successfully!");
        }
      } else console.log(err);
      setIsSubmittingFuelRecords(false);
    });
  };

  const handleSubmit = () => {
    // console.log(updateData.image[0])
    if (isAds) {
      setIsSubmitting(true);
      const data_ = {
        companyId: companyId,
        uid: parseInt(vehicleUpdateData.uid),
        fuelConsumed: Number(vehicleUpdateData.fuelConsumed?.replace(" ascent", "")),
        date: format(vehicleUpdateData.date, "yyyy-MM-dd"),
        year: currentYear,
        month: currentMonth + 1,
        keepStartOfMonthImage: !hasChangedStartOfMonthImage,
        keepEndOfMonthImage: !hasChangedEndOfMonthImage,
      };
      if (selectedVehicle.fuelType !== "N/A") {
        data_.fuelType = selectedVehicle.fuelType;
      }

      if (vehicleUpdateData.tripMileage) {
        data_.tripMileage = Number(
          vehicleUpdateData.tripMileage?.replace(" ascent", "")
        );
      }

      if (vehicleUpdateData.odometer) {
        data_.odometer = Number(vehicleUpdateData.odometer);
      }

      if (vehicleUpdateData.endOfMonthImage) {
        data_.endOfMonthImage = vehicleUpdateData.endOfMonthImage[0];
      }
      if (vehicleUpdateData.startOfMonthImage) {
        data_.startOfMonthImage = vehicleUpdateData.startOfMonthImage[0];
      }

      const fData = new FormData();
      Object.entries(data_).map(([key, value]) => fData.append(key, value));

      fetchEditManualEntry(token, fData, (err, data) => {
        if (data) {
          if (data.error) {
            toast.error(data.error);
          } else if (data.message) {
            toast.error(data.message);
          } else {
            setOpen(false);

            hasChangedFuelRecords &&
              handleUpdateFuelRecords(
                parseInt(vehicleUpdateData.uid),
                currentYear,
                currentMonth
              );

            const index = selectTable.vehicles.findIndex(
              (v) => v.licensePlate === data.licensePlate
            );
            const newObj = { ...selectTable };
            const data_ = {
              ...selectTable.vehicles[index],
              uid: Number(data.uid),
              licensePlate: data.licensePlate,
              // fuelConsumed: data.fuelConsumed ? Number(data.fuelConsumed) : data.fuelConsumed,
              ascentFuelConsumed: vehicleUpdateData?.fuelConsumed?.includes("ascent")
                ? Number(vehicleUpdateData.fuelConsumed?.replace(" ascent", ""))
                : null,
              manualFuelConsumed: !vehicleUpdateData?.fuelConsumed?.includes("ascent")
                ? Number(vehicleUpdateData.fuelConsumed?.replace(" ascent", ""))
                : null,
              imagePath: data.imagePath,
              fuelType: data.fuelType,
              odometer: data.odometer ? Number(data.odometer) : data.odometer,
              // tripMileage: data.tripMileage ? Number(data.tripMileage) : data.tripMileage,
              ascentTripMileage: vehicleUpdateData?.tripMileage?.includes("ascent")
                ? Number(vehicleUpdateData.tripMileage?.replace(" ascent", ""))
                : null,
              manualTripMileage: !vehicleUpdateData?.tripMileage?.includes("ascent")
                ? Number(vehicleUpdateData.tripMileage?.replace(" ascent", ""))
                : null,
              startOfMonthImageName: data.startOfMonthImageName,
              endOfMonthImageName: data.endOfMonthImageName,
              date: new Date(data.date),
            };
            const data2_ = {
              ...selectTable.vehicles[index],
              uid: Number(data.uid),
              licensePlate: data.licensePlate,
              fuelConsumed: vehicleUpdateData?.fuelConsumed?.includes("ascent")
                ? data.fuelConsumed + " ascent"
                : data.fuelConsumed,
              ascentFuelConsumed: vehicleUpdateData?.fuelConsumed?.includes("ascent")
                ? Number(vehicleUpdateData.fuelConsumed?.replace(" ascent", ""))
                : null,
              manualFuelConsumed: !vehicleUpdateData?.fuelConsumed?.includes("ascent")
                ? Number(vehicleUpdateData.fuelConsumed?.replace(" ascent", ""))
                : null,
              imagePath: data.imagePath,
              fuelType: data.fuelType,
              odometer: data.odometer ? Number(data.odometer) : data.odometer,
              tripMileage: vehicleUpdateData?.tripMileage?.includes("ascent")
                ? data.tripMileage + " ascent"
                : data.tripMileage,
              ascentTripMileage: vehicleUpdateData?.tripMileage?.includes("ascent")
                ? Number(vehicleUpdateData.tripMileage?.replace(" ascent", ""))
                : null,
              manualTripMileage: !vehicleUpdateData?.tripMileage?.includes("ascent")
                ? Number(vehicleUpdateData.tripMileage?.replace(" ascent", ""))
                : null,
              startOfMonthImage: [{ name: data.startOfMonthImageName }],
              endOfMonthImage: [{ name: data.endOfMonthImageName }],
              date: vehicleUpdateData.date,
            };
            newObj.vehicles.splice(index, 1, data_);
            setSelectTable(newObj);
            setMonthlyEntry((monthlyEntry) => {
              const newMonthlyEntry = [...monthlyEntry];
              newMonthlyEntry[currentMonth] = newObj;
              return newMonthlyEntry;
            });
            setVehicleUpdateData(data2_);
            setHasChangedStartOfMonthImage(false);
            setHasChangedEndOfMonthImage(false);
            toast.success("Updated Successfully!");
          }
        } else console.log(err);
        setIsSubmitting(false);
      });
    }
    else {
      setIsSubmitting(true);
      const data_ = {
        companyId: companyId,
        uid: parseInt(vehicleUpdateData.uid),
        fuelConsumed: Number(vehicleUpdateData.fuelConsumed?.replace(" ascent", "")),
        date: format(vehicleUpdateData.date, "yyyy-MM-dd"),
        year: currentYear,
        month: currentMonth + 1,
        keepStartOfMonthImage: !hasChangedStartOfMonthImage,
        keepEndOfMonthImage: !hasChangedEndOfMonthImage,
      };
      if (selectedVehicle.fuelType !== "N/A") {
        data_.fuelType = selectedVehicle.fuelType;
      }

      if (vehicleUpdateData.tripMileage) {
        data_.tripMileage = Number(
          vehicleUpdateData.tripMileage?.replace(" ascent", "")
        );
      }

      if (vehicleUpdateData.odometer) {
        data_.odometer = Number(vehicleUpdateData.odometer);
      }

      if (vehicleUpdateData.endOfMonthImage) {
        data_.endOfMonthImage = vehicleUpdateData.endOfMonthImage[0];
      }
      if (vehicleUpdateData.startOfMonthImage) {
        data_.startOfMonthImage = vehicleUpdateData.startOfMonthImage[0];
      }

      const fData = new FormData();
      Object.entries(data_).map(([key, value]) => fData.append(key, value));

      fetchEditManualEntry(token, fData, (err, data) => {
        if (data) {
          if (data.error) {
            toast.error(data.error);
          } else if (data.message) {
            toast.error(data.message);
          } else {
            setOpen(false);

            hasChangedFuelRecords &&
              handleUpdateFuelRecords(
                parseInt(vehicleUpdateData.uid),
                currentYear,
                currentMonth
              );

            const index = selectTable.vehicles.findIndex(
              (v) => v.licensePlate === data.licensePlate
            );
            const newObj = { ...selectTable };
            const data_ = {
              ...selectTable.vehicles[index],
              uid: Number(data.uid),
              licensePlate: data.licensePlate,
              // fuelConsumed: data.fuelConsumed ? Number(data.fuelConsumed) : data.fuelConsumed,
              ascentFuelConsumed: vehicleUpdateData?.fuelConsumed?.includes("ascent")
                ? Number(vehicleUpdateData.fuelConsumed?.replace(" ascent", ""))
                : null,
              manualFuelConsumed: !vehicleUpdateData?.fuelConsumed?.includes("ascent")
                ? Number(vehicleUpdateData.fuelConsumed?.replace(" ascent", ""))
                : null,
              imagePath: data.imagePath,
              fuelType: data.fuelType,
              odometer: data.odometer ? Number(data.odometer) : data.odometer,
              // tripMileage: data.tripMileage ? Number(data.tripMileage) : data.tripMileage,
              ascentTripMileage: vehicleUpdateData?.tripMileage?.includes("ascent")
                ? Number(vehicleUpdateData.tripMileage?.replace(" ascent", ""))
                : null,
              manualTripMileage: !vehicleUpdateData?.tripMileage?.includes("ascent")
                ? Number(vehicleUpdateData.tripMileage?.replace(" ascent", ""))
                : null,
              startOfMonthImageName: data.startOfMonthImageName,
              endOfMonthImageName: data.endOfMonthImageName,
              date: new Date(data.date),
            };
            const data2_ = {
              ...selectTable.vehicles[index],
              uid: Number(data.uid),
              licensePlate: data.licensePlate,
              fuelConsumed: vehicleUpdateData?.fuelConsumed?.includes("ascent")
                ? data.fuelConsumed + " ascent"
                : data.fuelConsumed,
              ascentFuelConsumed: vehicleUpdateData?.fuelConsumed?.includes("ascent")
                ? Number(vehicleUpdateData.fuelConsumed?.replace(" ascent", ""))
                : null,
              manualFuelConsumed: !vehicleUpdateData?.fuelConsumed?.includes("ascent")
                ? Number(vehicleUpdateData.fuelConsumed?.replace(" ascent", ""))
                : null,
              imagePath: data.imagePath,
              fuelType: data.fuelType,
              odometer: data.odometer ? Number(data.odometer) : data.odometer,
              tripMileage: vehicleUpdateData?.tripMileage?.includes("ascent")
                ? data.tripMileage + " ascent"
                : data.tripMileage,
              ascentTripMileage: vehicleUpdateData?.tripMileage?.includes("ascent")
                ? Number(vehicleUpdateData.tripMileage?.replace(" ascent", ""))
                : null,
              manualTripMileage: !vehicleUpdateData?.tripMileage?.includes("ascent")
                ? Number(vehicleUpdateData.tripMileage?.replace(" ascent", ""))
                : null,
              startOfMonthImage: [{ name: data.startOfMonthImageName }],
              endOfMonthImage: [{ name: data.endOfMonthImageName }],
              date: vehicleUpdateData.date,
            };
            newObj.vehicles.splice(index, 1, data_);
            setSelectTable(newObj);
            setMonthlyEntry((monthlyEntry) => {
              const newMonthlyEntry = [...monthlyEntry];
              newMonthlyEntry[currentMonth] = newObj;
              return newMonthlyEntry;
            });
            setVehicleUpdateData(data2_);
            setHasChangedStartOfMonthImage(false);
            setHasChangedEndOfMonthImage(false);
            toast.success("Updated Successfully!");
          }
        } else console.log(err);
        setIsSubmitting(false);
      });
    }
  };
  const handleUpdateClick = (data, id) => {
    if (!isAds) {
      setOpen(false);
      //const tripMileage = (selectTable.vehicles.find(v=> v.uid === id).tripMileage)?.toFixed(2)
      const startOfMonthImage = selectTable.vehicles.find(
        (v) => v.uid === id
      ).startOfMonthImageName;
      const endOfMonthImage = selectTable.vehicles.find(
        (v) => v.uid === id
      ).endOfMonthImageName;
      const newObj = { ...equipmentUpdateData };
      newObj.uid = id;
      newObj.licensePlate = data.licensePlate;
      newObj.fuelType = data.fuelType;
      newObj.fuelConsumed = data.fuelConsumed;
      newObj.odometer = data.odometer ? Number(data.odometer).toFixed(2) : null;
      newObj.tripMileage = data.tripMileage;
      newObj.startOfMonthImage = [{ name: startOfMonthImage }];
      newObj.endOfMonthImage = [{ name: endOfMonthImage }];
      newObj.date = selectTable.vehicles.find((v) => v.uid === id).date
        ? new Date(selectTable.vehicles.find((v) => v.uid === id).date)
        : new Date();
      setFuelRecords(
        selectTable.vehicles.find((v) => v.uid === id).fuelRecordsFiles
      );
      setHasChangedFuelRecords(false);
      setVehicleUpdateData(newObj);
      setSelectedVehicle(newObj);
    }
    else {
      setOpen(false);
      //const tripMileage = (selectTable.vehicles.find(v=> v.uid === id).tripMileage)?.toFixed(2)
      const startOfMonthImage = selectTable.equipments.find(
        (v) => v.deviceId === id
      ).startOfMonthImageName;
      const endOfMonthImage = selectTable.equipments.find(
        (v) => v.deviceId === id
      ).endOfMonthImageName;
      const newObj = { ...equipmentUpdateData };
      newObj.deviceId = id;
      newObj.powerConsumed = data.powerConsumed;
      newObj.odometer = data.odometer ? Number(data.odometer).toFixed(2) : null;
      newObj.startOfMonthImage = [{ name: startOfMonthImage }];
      newObj.endOfMonthImage = [{ name: endOfMonthImage }];
      newObj.date = selectTable.equipments.find((v) => v.deviceId === id).date
        ? new Date(selectTable.equipments.find((v) => v.deviceId === id).date)
        : new Date();
      setFuelRecords(
        selectTable.equipments.find((v) => v.deviceId === id).fuelRecordsFiles
      );
      setEquipmentUpdateData(newObj);
      setSelectedVehicle(newObj);
    }
  };

  const handleInputChange = (e) => {
    if (isAds) {
      const newObj = { ...equipmentUpdateData };
      if (e.target.id === "startOfMonthImage") {
        setHasChangedStartOfMonthImage(true);
        newObj[e.target.id] = e.target.files;
      } else if (e.target.id === "endOfMonthImage") {
        setHasChangedEndOfMonthImage(true);
        newObj[e.target.id] = e.target.files;
      } else if (e.target.id === "fuelRecords") {
        setHasChangedFuelRecords(true);
        setFuelRecords(e.target.files);
      } else {
        newObj[e.target.id] = e.target.value;
      }
      setEquipmentUpdateData(newObj);
    }

    else {
      const newObj = { ...vehicleUpdateData };
      if (e.target.id === "startOfMonthImage") {
        setHasChangedStartOfMonthImage(true);
        newObj[e.target.id] = e.target.files;
      } else if (e.target.id === "endOfMonthImage") {
        setHasChangedEndOfMonthImage(true);
        newObj[e.target.id] = e.target.files;
      } else if (e.target.id === "fuelRecords") {
        setHasChangedFuelRecords(true);
        setFuelRecords(e.target.files);
      } else {
        newObj[e.target.id] = e.target.value;
      }
      setVehicleUpdateData(newObj);
    }
  };
  const handlePickDate = (date) => {
    const newObj = { ...vehicleUpdateData };
    newObj.date = date;
    setVehicleUpdateData(newObj);
  };
  const _handleYearSelect = (year) => {
    setCurrentYear(year);
  };
  //console.log("vehicleUpdateData",vehicleUpdateData);
  return monthlyEntry ? (
    <>
      <div style={{ fontSize: 14 }}>
        <div className=" d-flex flex-row flex-wrap mx-2">
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 justify-content-center">
            <div className="d-flex flex-row">
              <CompanySelector theme={theme} />
              <div className="col-6 align-items-start">
                <Selector
                  isSearchable={false}
                  options={[
                    {
                      value: "2022",
                      label: "2022",
                    },
                    {
                      value: "2021",
                      label: "2021",
                    },
                  ]}
                  id="manualSector"
                  value={
                    Number(currentYear) === 2022
                      ? { label: "2022", value: "2022" }
                      : { label: "2021", value: "2021" }
                  }
                  defaultValue={{ label: "2022", value: "2022" }}
                  _handleSelect={(e) => _handleYearSelect(e.value)}
                />
              </div>
            </div>
            {selectTable && (
              <EntryTimeline
                theme={theme}
                data={monthlyEntry}
                handleIconClick={handleIconClick}
                currentYear={currentYear}
                currentMonth={currentMonth}
              />
            )}
          </div>
          {selectTable ? (
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
              {!isAds ? <VehicleMonthlyDetailTable
                theme={theme}
                header={[
                  { id: "licensePlate", label: "License No" },
                  { id: "fuelType", label: "Fuel Type" },
                  { id: "fuelConsumed", label: "Fuel Consumed (L)" },
                  { id: "odometer", label: "Odometer (km)" },
                  { id: "tripMileage", label: "Trip Mileage (km)" },
                  { id: "action", label: "Action" },
                ]}
                data={isAds ? selectTable.equipments : selectTable?.vehicles}
                selectedVehicle={selectedVehicle}
                handleUpdateClick={handleUpdateClick}
              /> : <EquipmentMonthlyDetailTable
                theme={theme}
                header={[
                  { id: "deviceId", label: "Device Id" },
                  { id: "deviceName", label: "Device Name" },
                  { id: "PowerConsumed", label: "Power Consumed (kWh)" },
                  { id: "odometer", label: "Odometer (km)" },
                  { id: "action", label: "Action" },
                ]}
                data={isAds ? selectTable.equipments : selectTable?.vehicles}
                selectedVehicle={selectedVehicle}
                handleUpdateClick={handleUpdateClick}
              />}

              {isAds ?
                selectedVehicle && (
                  <EquipmentEditForm
                    theme={theme}
                    selectedVehicle={selectedVehicle}
                    updateData={equipmentUpdateData}
                    fuelRecords={fuelRecords}
                    handleInputChange={handleInputChange}
                    handlePickDate={handlePickDate}
                    handleClear={handleClear}
                    handleSubmit={handleSubmit}
                    currentMonth={currentMonth}
                    currentYear={currentYear}
                    open={open}
                    setOpen={setOpen}
                    isSubmitting={isSubmitting || isSubmittingFuelRecords}
                  />
                ) :
                selectedVehicle && (
                  <VehicleEditForm
                    theme={theme}
                    selectedVehicle={selectedVehicle}
                    updateData={vehicleUpdateData}
                    fuelRecords={fuelRecords}
                    handleInputChange={handleInputChange}
                    handlePickDate={handlePickDate}
                    handleClear={handleClear}
                    handleSubmit={handleSubmit}
                    currentMonth={currentMonth}
                    currentYear={currentYear}
                    open={open}
                    setOpen={setOpen}
                    isSubmitting={isSubmitting || isSubmittingFuelRecords}
                  />
                )}
            </div>
          ) : (
            <CO2_Loading />
          )}
        </div>
        <NotificationCard theme={theme} lastUpdate={lastUpdate} />
      </div>
    </>
  ) : (
    <CO2_Loading />
  );
};

export default inject("store")(withRouter(observer(ManualEntryContainer)));
