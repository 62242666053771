import { Colors, invertDark } from "../../Assets/Config/color.config";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import { useEffect, useState } from "react";
import { baseURL } from "../../network/URL";
import { My_Button, My_Input, My_upload_image, Selector } from "../../Tools";
import { Checkbox, useForkRef } from "@mui/material";
import { useTranslation } from "react-i18next";

export const UserUnitModal = inject("store")(
  observer((props) => {
    const {t} = useTranslation('admin-panel')
    const { theme, selectedVehicle } = props;
    const { selectedUser, _handleAssgningVehicle, _handleFetchCompanyUnitsForAssign } = props.store.dataEntry;


    return (

      <div className="modal fade"
        id="userunitconfirmModal"
        tabindex="-1"
        aria-labelledby="userunitconfirmModal"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
        aria-hidden="true">

        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content" style={{background: theme === "dark"? "#202859" : "#ffffff"}} >
            <div className="modal-header" style={{justifyContent:"start", borderBottom: "1px solid #454B73"}}>
                <i className="bi bi-exclamation-triangle-fill" style={{ color: theme === "dark"? "yellow" : "orange", marginRight: 4}}></i>
                <h1 className="modal-title fs-5" id="userunitconfirmModal">Warning</h1>
            </div>
            <div className="modal-body">
              <div style={{ fontSize: 18 }}>
                {t("You have to unassign this unit when you leave from this company. Are you sure you want to update this?")}
              </div>
              <div className="mt-3">
                {selectedVehicle?.map((vehicle) => {
                  return (
                    <div className="py-2 mt-1" style={{ background: theme === "dark" ? "#384067" : "#C4C4C4", borderRadius: 6, minWidth: 50 }}>
                      <div className="d-flex align-items-center">
                        <img className="bg-white rounded" style={{ width: 100, height: 46, objectFit: 'cover', marginRight:4 , marginLeft:4}} src={baseURL + "/uploads/devices/" + vehicle.image} />
                        <div className="d-flex row" >
                          <div className="col-12">
                            <span style={{ fontSize: 16 }}>
                              {vehicle.plate_number}
                            </span>
                          </div>
                          <div className="col-12">
                            <span style={{ fontSize: 12 }}>
                              {vehicle.fuel_type}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="modal-footer" style={{borderTop: "1px solid #454B73"}}>
              <div className="d-flex justify-content-end">
                <span className="me-2" data-bs-dismiss="modal">
                  <My_Button
                    customColor="#1976d2"
                    customHoverColor="#0d6efd"
                    text={t("Cancel")}
                  />
                </span>
                <span data-bs-dismiss="modal">
                  <My_Button
                    customColor="#1976d2"
                    customHoverColor="#0d6efd"
                    text={t("Confirm")}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>

      </div>

    );
  }));

