import React from "react";
import AccessDenied from "../Assets/Images/access_denied.png"

const AccessDeniedPage = (props) => {
    const { message } = props;
  return (
    <div className="container justify-content-center text-center mt-5">
      <img src={AccessDenied} width="100" alt="Robot" />
      <h3 className="text-danger mt-5">Access Denied</h3>
      <div className="row mt-3">
      <span className="text-info">You don't have permissions to access this page.</span>
      <span className="text-info">{message}</span>
      </div>
    </div>
  );
};

export default AccessDeniedPage;
