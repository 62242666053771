/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/alt-text */
import { toJS } from "mobx";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { inject, observer } from "mobx-react";
import { Checkbox } from "@mui/material";
import { baseURL } from "../../network/URL";
import { useTranslation } from "react-i18next";
import { CO2_Loading } from "../../Assets/CO2_Loading";
import Tour from './companyAssignTour';

export const AssignVehiclesCompanyModal = inject("store")(
  observer((props) => {
    const { t } = useTranslation("admin-panel");
    const { theme, user } = props;

    const {
      selectedVehicle,
      availableVehicle,
      _handleAssignUnitToCompany,
      loading,
      _handleAssignLoading,
    } = props.store.dataEntry;
    const { selectedCompany, company_name } = props.store.adminPanel;
    const default_img = "/cars/default_vehicle.png";

    return (
      <div
        className="modal fade show"
        id="assignCompanyVehicles"
        tabIndex="-1"
        aria-labelledby="assignCompanyVehicles"
        aria-hidden="true"
      >
        <div className="w-100 d-flex align-items-center justify-content-end py-2 px-3">
            {/* {<Tour/>} */}
        </div>
        <div className="modal-dialog modal-lg rounded">
          <div
            className="assign-admin-guide modal-content"
            style={{
              background: Colors[theme].background,
              color: invertDark(theme),
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title">
                {t("Assign Units For")}{" "}
                {user.find((v) => v.id === selectedCompany)?.name}
              </h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                {loading === true ? (
                  <CO2_Loading />
                ) : (
                  <>
                    <div className="col-6">
                      <div className="d-flex flex-column justify-content-between">
                        {t("Available Units") +
                          ` of ${company_name} - (${
                            availableVehicle && availableVehicle.length > 0
                              ? availableVehicle.length
                              : "0"
                          })`}

                        <div
                          className="d-flex align-items-center"
                          onClick={() => {
                            let unitIdsArr = [];
                            availableVehicle.map(async (unit) => {
                              unitIdsArr.push(unit.id);
                            });
                            _handleAssignLoading(true);
                            _handleAssignUnitToCompany(
                              unitIdsArr,
                              selectedCompany
                            );
                          }}
                        >
                          <span className="pe-2">Assign All</span>
                          <Checkbox
                            //   checked={checkedValues.assignChecked}
                            style={{
                              color: Colors.activeColor,
                              width: 10,
                            }}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          height: "500px",
                          overflowX: "hidden",
                          overflowY: "scroll",
                        }}
                      >
                        {availableVehicle?.map((vehicle, index) => {
                          return (
                            <div
                              className="py-2 mt-1"
                              style={{
                                background:
                                  theme === "dark" ? "#384067" : "#C4C4C4",
                                borderRadius: 6,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                let unitIds = [];
                                unitIds.push(vehicle.id);
                                _handleAssignLoading(true);
                                _handleAssignUnitToCompany(
                                  unitIds,
                                  selectedCompany
                                );
                              }}
                            >
                              <div className="d-flex align-items-center px-1">
                                <span
                                  className="p-2"
                                  style={{ cursor: "pointer" }}
                                >
                                  <Checkbox
                                    checked={false}
                                    value={vehicle.plate_number}
                                    style={{
                                      color: Colors.activeColor,
                                    }}
                                  />
                                </span>

                                <img
                                  className="bg-white rounded"
                                  style={{
                                    width: 100,
                                    height: 46,
                                    objectFit: "cover",
                                  }}
                                  src={
                                    vehicle.image
                                      ? baseURL +
                                        "/uploads/devices/" +
                                        vehicle.image
                                      : default_img
                                  }
                                />

                                <div className="col mx-2">
                                  <div>
                                    <span
                                      className="col"
                                      style={{ fontSize: 16 }}
                                    >
                                      {vehicle.plate_number
                                        ? vehicle.plate_number
                                        : vehicle.grouping}
                                    </span>
                                  </div>
                                  <div className="d-flex">
                                    <span style={{ fontSize: 12 }}>
                                      {vehicle.fuel_type}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="d-flex justify-content-between flex-column">
                        {t("Selected Units") +
                          ` - (${
                            selectedVehicle && selectedVehicle.length > 0
                              ? selectedVehicle.length
                              : "0"
                          })`}
                        <div
                          className="d-flex align-items-center"
                          onClick={async () => {
                            let unitIdsArr = [];
                            await selectedVehicle.map(async (unit) => {
                              if (unit.isLock === 0) {
                                unitIdsArr.push(unit.id);
                              }
                            });
                            _handleAssignLoading(true);
                            _handleAssignUnitToCompany(
                              unitIdsArr,
                              selectedCompany
                            );
                          }}
                        >
                          <span className="pe-2">Unassign All</span>
                          <Checkbox
                            checked={
                              selectedVehicle && selectedVehicle.length > 0
                                ? true
                                : false
                            }
                            // value={vehicle.plate_number}
                            style={{
                              color: Colors.activeColor,
                              width: 10,
                            }}
                          />
                        </div>
                      </div>

                      <div
                        style={{
                          height: "500px",
                          overflowX: "hidden",
                          overflowY: "scroll",
                        }}
                      >
                        {selectedVehicle?.map((vehicle, index) => {
                          return (
                            <div
                              className="py-2 mt-1"
                              style={{
                                background:
                                  theme === "dark" ? "#384067" : "#C4C4C4",
                                borderRadius: 6,
                                cursor: "pointer",
                              }}
                              onClick={(e) => {
                                let unitIds = [];
                                unitIds.push(vehicle.id);
                                _handleAssignLoading(true);
                                _handleAssignUnitToCompany(
                                  unitIds,
                                  selectedCompany
                                );
                              }}
                            >
                              <div className="d-flex align-items-center px-1">
                                {vehicle.isLock === 1 ? (
                                  <span
                                    className="p-2"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <i className="fas fa-lock py-2"></i>
                                  </span>
                                ) : (
                                  <span
                                    className="p-2"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <Checkbox
                                      checked={true}
                                      value={vehicle.plate_number}
                                      style={{
                                        color: Colors.activeColor,
                                      }}
                                    />
                                  </span>
                                )}

                                <img
                                  className="bg-white rounded"
                                  style={{
                                    width: 100,
                                    height: 50,
                                    objectFit: "cover",
                                  }}
                                  src={
                                    vehicle.image
                                      ? baseURL +
                                        "/uploads/devices/" +
                                        vehicle.image
                                      : default_img
                                  }
                                />
                                <div className="col mx-2">
                                  <div>
                                    <span
                                      className="col"
                                      style={{ fontSize: 16 }}
                                    >
                                      {vehicle.plate_number}
                                    </span>
                                  </div>
                                  <div className="d-flex">
                                    <span style={{ fontSize: 12 }}>
                                      {vehicle.fuel_type}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn"
                style={{ background: '#4c5d75', color: 'white'}}
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                {t("Save")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  })
);
