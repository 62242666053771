import { useTranslation } from "react-i18next";
import { Chartdata } from "./ChartData";
import { DonutChart } from "./DonutChart";

export const ComparisonChart = (props) => {
    const {t} = useTranslation("lite-dashboard")
    const { label, labels, valueData, bgColor, bdColor, theme, dashboardData } =
        props;
    
    const batteryConsumed = parseInt(dashboardData?.Battery?.total_consumption) || 0;
    const dieselConsumed = parseInt(dashboardData?.Diesel?.total_consumption) || 0;
    const petrolConsumed = parseInt(dashboardData?.Petrol?.total_consumption) || 0;

    const batteryMileage = parseInt(dashboardData?.Battery?.total_mileage) || 0;
    const dieselMileage = parseInt(dashboardData?.Diesel?.total_mileage) || 0;
    const petrolMileage = parseInt(dashboardData?.Petrol?.total_mileage) || 0;

    const dieselPercentage = dashboardData?.Diesel?  Number((100 * dashboardData.Diesel.total_consumption)/dashboardData.totalConsumed).toFixed() : 0 ;
    const petrolPercentage = dashboardData?.Petrol? Number((100 * dashboardData.Petrol.total_consumption)/dashboardData.totalConsumed).toFixed() : 0;
    const batteryPercentage = dashboardData?.Electricity? Number((100 * dashboardData.Electricity.total_consumption)/dashboardData.totalConsumed).toFixed() : 0;


    return (
        <div
            className="m-1 "
            style={{
                background: theme==='dark'?"rgba(115, 115, 115, 0.09)":"rgba(0,0,0,0.1)",
                borderRadius: 10,
                boxShadow: theme==='dark'?"4px 4px 10px 5px rgba(0, 0, 0, 0.1)":"4px 4px 10px 5px rgba(0, 0, 0, 0.3)",
            }}
        >
            <div className="p-3">
                <h5 className="pb-2">{label}</h5>
                <div
                    className="row pt-3 pb-2 ps-2"
                    style={{ borderTop: `1px solid rgb(255,255,255,0.1)` }}
                >
                    <div className="col-8 p-5">
                        {dashboardData?.vehicleCount === 0 ? (
                            <div
                                className="text-center"
                                style={{ height: 400, fontSize: 20 }}
                            >
                                <span
                                    className="p-3"
                                    style={{
                                        background: "rgba(255,255,255,0.1)",
                                        borderRadius: 10,
                                    }}
                                >
                                    {t("No Unit added!")}
                                </span>
                            </div>
                        ) : (
                            <DonutChart
                                valueData={valueData}
                                labels={labels}
                                bgColor={bgColor}
                                bdColor={bdColor}
                            />
                        )}
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xs-4 col-12">
                        {label === "Proportion of ICE vs EV Units" ? (
                            <span className="row">
                                <div className="col-sm-4 col-md-4 col-lg-12 col-xs-12 col-xl-12 col-12 pb-2">
                                    <Chartdata
                                        theme={theme}
                                        icon={
                                            <i
                                                style={{ color: "rgba(54, 162, 235, 1)" }}
                                                className="bi bi-droplet-fill"
                                            />
                                        }
                                        percentage={Number(dieselPercentage) + Number(petrolPercentage)}
                                        label={t("ICE Units")}
                                        value={valueData[0]}
                                        totalConsumed={Number(petrolConsumed) + Number(dieselConsumed) + " L"}
                                        totalMileage={Number(petrolMileage) + Number(dieselMileage)}
                                    />
                                </div>
                                <div className="col-sm-4 col-md-4 col-lg-12 col-xs-12 col-xl-12 col-12">
                                    <Chartdata
                                        theme={theme}
                                        icon={
                                            <i
                                                style={{ color: "rgba(255, 159, 64, 1)" }}
                                                className="fas fa-bolt"
                                            />
                                        }
                                        percentage={batteryPercentage}
                                        label={t("EV Units")}
                                        value={valueData[1]}
                                        totalConsumed={batteryConsumed + " kWh"}
                                        totalMileage={batteryMileage}
                                    />
                                </div>
                            </span>
                        ) : (
                            <span className="row">
                                <div className="col-sm-4 col-md-4 col-lg-12 col-xs-12 col-xl-12 col-12 pb-2">
                                    <Chartdata
                                        theme={theme}
                                        icon={
                                            <i
                                                style={{ color: "rgba(54, 162, 235, 1)" }}
                                                percentage={1}
                                                className="bi bi-droplet-fill"
                                            />
                                        }
                                        percentage={petrolPercentage}
                                        label={t("Petrol Units")}
                                        value={valueData[0]}
                                        totalConsumed={petrolConsumed + " L"}
                                        totalMileage={petrolMileage}
                                    />
                                </div>
                                <div className="col-sm-4 col-md-4 col-lg-12 col-xs-12 col-xl-12 col-12 pb-2">
                                    <Chartdata
                                        theme={theme}
                                        icon={
                                            <i
                                                style={{ color: "rgba(116, 252, 68,1)" }}
                                                className="bi bi-droplet-fill"
                                            />
                                        }
                                        percentage={dieselPercentage}
                                        label={t("Diesel Units")}
                                        value={valueData[1]}
                                        totalConsumed={dieselConsumed + " L"}
                                        totalMileage={dieselMileage}
                                    />
                                </div>
                                <div className="col-sm-4 col-md-4 col-lg-12 col-xs-12 col-xl-12 col-12 pb-2">
                                    <Chartdata
                                        theme={theme}
                                        icon={
                                            <i
                                                style={{ color: "rgba(255, 159, 64, 1)" }}
                                                className="fas fa-bolt"
                                            />
                                        }
                                        percentage={batteryPercentage}
                                        label={t("EV Units")}
                                        value={valueData[2]}
                                        totalConsumed={batteryConsumed + " kWh"}
                                        totalMileage={batteryMileage}
                                    />
                                </div>
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
