import React, { useEffect, useState } from 'react'
import { useRef } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Colors, invertDark } from "../Assets/Config/color.config";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';

function CustomToggle({ children, eventKey, theme }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
        console.log('totally custom!'),
    );

    return (
        <div className='d-flex justify-content-center align-items-center' onClick={decoratedOnClick}>
            <div
                type="button"
                style={{
                    backgroundColor: theme === "dark" ? Colors.darkBlue : Colors.Dark[5],
                    color: theme === "dark" ? Colors.Dark[4] : Colors.Dark[1],
                    outline: 'none',
                    border: 'none',
                    width: '100%',
                    textAlign: 'left',
                }}

            >
                {children}
            </div>
            <i className='fa fa-angle-down' style={{ color: theme === "light" ? Colors.Dark[4] : 'silver', fontSize: '18px', cursor: 'pointer' }} />
        </div>
    );
}

export const Custom_Select = (props) => {
    const { options, value, theme, label, selectedValue, menuListColor, selectedVehicle, handleVehicleSelected, placeholder, setPlaceHolder, _handleClick } = props
    const [searchTerm, setSearchTerm] = useState('')

    const [activeKey, setActiveKey] = useState('1');

    const [hoveredItemIndex, setHoveredItemIndex] = useState(null);
    const accordionRef = useRef(null);

    // Step 3: Implement event handlers for onmouseover and onmouseout for each item
    const handleMouseOver = (index) => {
        setHoveredItemIndex(index);
    };

    const handleMouseOut = () => {
        setHoveredItemIndex(null);
    };

    // Function to toggle the active key on click
    const toggleAccordion = (eventKey) => {
        setActiveKey(activeKey === eventKey ? null : eventKey);
    };

    useEffect(() => {
        toggleAccordion('1')
    }, [])

    const filteredList = options?.filter((option) =>
        option?.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const _handleOnClick = () => {
        toggleAccordion('0');
        setSearchTerm("")
    }

    // function useOutsideAlerter(ref) {
    //     useEffect(() => {


    //         function handleClickOutside(event) {
    //             if (ref.current && !ref.current.contains(event.target)) {
    //                 toggleAccordion('0')
    //             }
    //         }

    //         document.addEventListener("click", handleClickOutside);
    //         return () => {
    //             document.removeEventListener("click", handleClickOutside);
    //         }

    //     }, [activeKey]);
    // }

    // useOutsideAlerter(accordionRef)

    return (
        <div ref={accordionRef}>
            <Accordion defaultActiveKey="0" style={{ width: '100%' }}>
                {label && <span style={{ color: invertDark(theme) }}>{label}: </span>}
                <Card style={{ background: theme === "dark" ? Colors.darkBlue : Colors.Dark[5], borderColor: theme === "dark" ? Colors.Dark[2] : Colors.Dark[4], height: '38px', width: '100%', borderRadius: 3 }}>

                    <Card.Header style={{
                        background: "inherit",
                        height: '100%',
                        borderRadius: 'inherit',
                        width: '100%'
                    }}
                    >
                        <CustomToggle theme={theme} eventKey="0"><span className='mb-2'>{value ? value : 'Select...'}</span></CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={activeKey}>
                        <Card.Body style={{ width: '100%', maxHeight: filteredList?.length > 0 ? filteredList?.length == 1 ? `${filteredList?.length * 130}px` : `${filteredList?.length * 100}px` : '150px', position: 'absolute', zIndex: '100', background: 'white', marginTop: '10px', borderRadius: '5px', border: theme === 'dark' ? 'none' : '1px solid silver' }}>
                            <div style={{ width: '100%', height: '250px' }}>
                                <input
                                    type="text"
                                    className="form-control mb-3"
                                    placeholder="Search..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                <div style={{ height: '200px', overflowY: 'scroll' }}>
                                    <ul className="list-group" style={{ borderRadius: '0px' }}>
                                        {filteredList?.length > 0 ? filteredList?.map((item, index) => (
                                            <li key={index}
                                                className="list-group-item"
                                                onMouseOver={() => handleMouseOver(index)}
                                                onMouseOut={handleMouseOut}
                                                style={{
                                                    cursor: 'pointer',
                                                    background: item.label === options.find((v) => v.value === selectedValue)?.label ? 'blue' : hoveredItemIndex === index ? 'lightgrey' : '',
                                                    color: item.label === options.find((v) => v.value === selectedValue)?.label ? 'white' : hoveredItemIndex === index ? 'black' : '',
                                                    border: 'none',
                                                }}

                                                onClick={() => {
                                                    _handleOnClick()
                                                    _handleClick(filteredList?.find((v, i) => i === index))
                                                }}
                                            >
                                                {item.label}
                                            </li>
                                        )) :
                                            <li>
                                                No Options...
                                            </li>}
                                    </ul>
                                </div>
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    );
};

