import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";

export const UploadImage = inject("store")(
    observer((props) => {
        const {t} = useTranslation("admin-panel")
        const theme = props.theme;
        const { _handleUploadImage, photo, uploadLogoImage } = props.store.adminPanel;
        return (
            <div
                className="d-flex flex-column justify-content-center align-items-center"
                style={{ background: "rgb(0,0,0,0.2)" }}
            >
                <label
                    style={{
                        width: "100%",
                        borderRadius: 5,
                        cursor: "pointer",
                        height: '100%'
                    }}
                    htmlFor="image-upload"
                >
                    <input
                        type="file"
                        style={{ display: "none" }}
                        id="image-upload"
                        accept="image/x-png,image/gif,image/jpeg"
                        onChange={(e) => _handleUploadImage(e)}
                    />
                    {!uploadLogoImage ? (
                        <span className="d-flex flex-column justify-content-center align-items-center h-100 p-0 m-3">
                            <i
                                style={{ fontSize: 30 }}
                                className="bi bi-cloud-arrow-up-fill p-5"
                            ></i>
                            <div>{t("Add an Image")}</div>
                        </span>
                    ) : (
                        <span className="d-flex justify-content-center m-1">
                            <img src={uploadLogoImage} high={'50%'} width={'50%'} />
                        </span>
                    )}
                </label>
            </div>
        );
    }));
