import { inject, observer } from "mobx-react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import common from "../../Store/common";
import moment from "moment";
import month from "../../Assets/month.json";
import { CO2_Loading } from "../../Assets/CO2_Loading";
import { useTranslation } from "react-i18next";
import { MonthlyHistoryDataTable } from "./MonthlyHistoryDataTable";
import { useEffect, useState } from "react";
import { toJS } from "mobx";

export const MonthlyHistoryModal = inject("store")(
  observer((props) => {
    const { t } = useTranslation("track_and_trace");
    const { theme, stacsHistory, monthlyData, companyType } = props;
    const { selectedMonthlyDetail, _handleViewClose, loading, certId } =
      props.store.trackAndTrace;

    const certificates = monthlyData?.find((v) => v.month == certId);

    const currentMonth = moment().month() + 1;
    const year = moment().year();

    const rows = selectedMonthlyDetail?.units?.map((data) => {
      const row = {
        plate_number: data.plate_number ? data.plate_number : "N/A",
        consumption:
          data.type === "battery"
            ? Number(data.battery_consumed).toFixed(2) + " kWh"
            : Number(data.fuel_consumed).toFixed(2) + " L",
        mileage:
          data.trip_mileage === null
            ? "0 km"
            : Number(data.trip_mileage).toFixed(2) + " km",
        baseline_emission:
          Number(data.baseline_emission).toFixed(2) + " kgCO₂e",
        co2_emission: Number(data.carbon_emission).toFixed(2) + " kgCO₂e",
      };

      return row;
    });

    return (
      <div
        className="modal fade"
        id="monthlyhistorymodal"
        tabIndex="-1"
        aria-labelledby="monthlyhistorymodal"
        aria-hidden="true"
      >
        <div className="w-100 d-flex align-items-center justify-content-end py-2 pt-4 px-3">
          {/* <Tour/> */}
        </div>
        <div className="modal-dialog modal-xl rounded">
          <div
            className="modal-content detailView-report-guide"
            style={{
              background: Colors[theme].background,
              color: invertDark(theme),
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title">
                {" "}
                {t("History for")}{" "}
                {selectedMonthlyDetail &&
                  month[selectedMonthlyDetail.month].label}
              </h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => _handleViewClose()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              {loading ? (
                <CO2_Loading theme={theme} />
              ) : (
                <MonthlyHistoryDataTable
                  companyType={companyType}
                  theme={theme}
                  stacsHistory={stacsHistory}
                  certificates={certificates}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  })
);
