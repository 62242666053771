import { inject, observer } from "mobx-react";
import { TrackingTable } from "./TrackingTable";

import moment from "moment";
import { TrackingDataTable } from "./TrackingDataTable";
import { toJS } from "mobx";
import { useTranslation } from "react-i18next";
import { TripDetailAndCreateReportBtn } from "./TripDetailAndCreateReportBtn";

export const TrackingTableView = inject("store")(
  observer((props) => {
    const { t } = useTranslation("new-tracking");
    const { cardStyle1, theme } = props;
    const {
      trackingDetailVTwo,
      selectedDetail,
      handleSelecteDetail,
    } = props.store.trackingVTwo;

    const rowStyle = {
      marginRight: 5,
      minHeight: 45,
      borderBottom: `1px solid rgba(100,100,100,0.2)`,
    };

    return (
      <div
        style={cardStyle1}
        className="row flex-col"
      >
        <div className="col-xl-10 col-lg-8 col-md-12 col-sm-12 col-xs-12 mb-0">

          <div style={{ height: "90%" }}>
            <TrackingDataTable
              trackingDetailVTwo={trackingDetailVTwo}
              theme={theme}
              handleSelecteDetail={handleSelecteDetail}
            />
            
          </div>

        </div>
        <div className="col-xl-2 col-lg-4 col-md-12 col-sm-12 col-xs-12 mb-2 ">
          <TripDetailAndCreateReportBtn 
            cardStyle1={cardStyle1} 
            theme={theme} 
            selectedDetail={selectedDetail} 
          />
        </div>

      </div>
    );
  })
);
