import { action, makeObservable, observable, ObservableMap, runInAction, toJS } from "mobx";
import moment from "moment";
import Cookies from "universal-cookie";
import { baseURL } from "../network/URL";
import { toast } from "react-toastify";
import { liteCreateReportValidation, liteEntryFormValidation } from "../helper/formValidation";
import { createCo2xLiteData, createNewAssetAPI, deleteCo2xLiteData, downloadReport, fetchDashboardData, getAllAsset, getCo2xLiteData, requestReport, updateCo2xLiteData, getCo2xLiteDataNew } from "../network/co2xLiteAPPI";
import jwt_decode from "jwt-decode";

class LiteEntry {
  cookies = new Cookies();
  token = this.cookies.get("accessToken");
  companyId = this.cookies.get("company");
  date = new Date();
  userId = this.token && jwt_decode(this.token).userId;
  entryData = {
    asset_id: "-",
    report_type: "Monthly",
    userId: this.userId,
    period_start: new Date(this.date.getFullYear(), this.date.getMonth(), 1),
    period_end: new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0),
    companyId: this.companyId,
    categorization: "individual",
    vehicle_type: [
      {
        keyValue: "4UD",
        vehicleType: "4UD",
        noOfVehicle: "",
        OEMEmission: 814.1,
        isSelected: 0,
      },
      {
        keyValue: "4RD",
        vehicleType: "4RD",
        noOfVehicle: "",
        OEMEmission: 814.1,
        isSelected: 0,
      },
      {
        keyValue: "5RD",
        vehicleType: "5RD",
        noOfVehicle: "",
        OEMEmission: 861.7,
        isSelected: 0,
      },
      {
        keyValue: "9RD",
        vehicleType: "9RD",
        noOfVehicle: "",
        OEMEmission: 873.3,
        isSelected: 0,
      },
      {
        keyValue: "10RD",
        vehicleType: "10RD",
        noOfVehicle: "",
        OEMEmission: 854.1,
        isSelected: 0,
      },
    ],
    isIndividual: true,
  };
  co2xLiteData = [];
  editMode = false;
  toDeleteAssetId = null;
  toDeleteId = null;
  reportData = [];
  dashboardData = [];
  assetList = [];
  assetListOption = [];

  vehicleTypeOption = [
    { value: "4-UD", label: "4-UD" },
    { value: "4-RD", label: "4-RD" },
    { value: "5-RD", label: "5-RD" },
    { value: "9-RD", label: "9-RD" },
    { value: "10-RD", label: "10-RD" },
  ];
  fuelTypeOption = [
    { value: "Diesel", label: "Diesel" },
    { value: "Petrol", label: "Petrol" },
    { value: "Battery", label: "Battery" },
  ];
  fuelUnitOption = [
    { value: "Liters(L)", label: "Liters(L)" },
    { value: "gallon", label: "gallon" },
    { value: "kWh", label: "kWh" },
  ];
  mileageUnitOption = [
    { value: "km", label: "km" },
    { value: "mile", label: "mile" },
    { value: "m", label: "m" },
  ];
  reportTypeOption = [
    { value: "Monthly", label: "Monthly" },
    { value: "Yearly", label: "Yearly" },
  ];
  selectedVehicle = null;
  view = "table";
  selectedType = [];

  constructor() {
    makeObservable(this, {
      entryData: observable,
      vehicleTypeOption: observable,
      co2xLiteData: observable,
      editMode: observable,
      toDeleteId: observable,
      toDeleteAssetId: observable,
      reportData: observable,
      dashboardData: observable,
      assetList: observable,
      assetListOption: observable,
      selectedVehicle: observable,
      view: observable,
      selectedType: observable,

      _handleTextChange: action.bound,
      _handleSubmit: action.bound,
      fetchCo2xLiteData: action.bound,
      setEditMode: action.bound,
      fetchRequestReport: action.bound,
      _setToDelete: action.bound,
      fetchDownloadReport: action.bound,
      fetchDashboardData: action.bound,
      _setClickedVehicle: action.bound,
      setView: action.bound,
      _handleCheckType: action.bound,
    });
  }

  _handleTextChange = (e, field) => {
    switch (field) {
      case "asset_id":
        this.entryData = {
          ...this.entryData,
          asset_id: e.value,
          uid: e.uid,
          type: e.primary_fuel_type,
          type_unit: e.type_unit,
          vehicle_type: e.vehicle_type,
          mileage_unit: e.mileage_unit,
        };
        break;
      case "asset_id_input":
        this.entryData.asset_id = e.target.value;
        break;
      case "categorization":
        this.entryData.categorization = e.target.value;
        this.selectedType = [];

        this.entryData.isIndividual =
          e.target.value === "individual" ? true : false;
        break;

      case "remark":
        this.entryData.remark = e.target.value;
        break;
      case "vehicle_type":
        this.entryData.vehicle_type = [e];
        break;
      case "type":
        this.entryData.type = e.value;
        break;
      case "consumed":
        this.entryData.consumed = e.target.value;
        break;
      case "type_unit":
        this.entryData.type_unit = e.value;
        break;
      case "mileage":
        this.entryData.mileage = e.target.value;
        break;
      case "mileage_unit":
        this.entryData.mileage_unit = e.value;
        break;
      case "report_type":
        this.entryData.report_type = e.value;
        break;
      case "month":
        this.entryData.period_start = e;
        let tempMonth = e;
        this.entryData.period_end = new Date(
          tempMonth.getFullYear(),
          tempMonth.getMonth() + 1,
          0
        );
        break;
      case "year":
        this.entryData.period_start = e;
        let tempYear = e;
        this.entryData.period_end = new Date(tempYear.getFullYear(), 11, 31);
        break;
      case "vehicleQuantity":
        console.log(e);
        this.entryData.vehicle_type[e.index].noOfVehicle = e.value;
        break;
    }
  };

  _handleSubmit = () => {
    const err = liteCreateReportValidation(this.entryData);
    // liteEntryFormValidation(this.entryData);
    console.log(err);
    if (err.asset_id) {
      document.getElementById("asset_id").focus();
      toast.error(err.asset_id);
    } else if (err.consumed) {
      document.getElementById("consumed").focus();
      toast.error(err.consumed);
    } else if (err.mileage) {
      document.getElementById("mileage").focus();
      toast.error(err.mileage);
    } else if (err.report_type) {
      document.getElementById("report_type").focus();
      toast.error(err.report_type);
    } else if (err.period_start) {
      document.getElementById("period_start").focus();
      toast.error(err.period_start);
    } else {
      createCo2xLiteData(this.token, this.entryData, (err, data) => {
        if (!data?.errors) {
          window.location.reload();
        } else {
          toast.error(data.name);
        }
      });
    }
  };

  fetchCo2xLiteData = () => {
    console.log("here");
    getCo2xLiteDataNew(this.token, this.companyId, (err, data) => {
      if (data) {
        if (data.message === "Token Expired!") {
          toast.info(data.message);
          window.location.pathname = "/";
        } else this.co2xLiteData = data;
      } else {
        toast.error(data?.message);
      }
    });
  };

  fetchAllAsset = () => {
    getAllAsset(this.token, this.companyId, (err, data) => {
      if (data) {
        if (data.message === "Token Expired!") {
          toast.info(data.message);
          window.location.pathname = "/";
        } else this.assetList = data;
        // this.entryData = { ...this.entryData, type: data[2].primary_fuel_type, type_unit: data[2].type_unit, vehicle_type: data[2].vehicle_type, mileage_unit: 'km', uid: data[2].uid }
        this.assetListOption = data.map((v) => ({
          label: v.license_plate,
          value: v.license_plate,
          uid: v.uid,
          primary_fuel_type: v.primary_fuel_type,
          type_unit: v.type_unit,
          vehicle_type: v.vehicle_type,
          mileage_unit: "km",
        }));
      } else {
        toast.error(data?.message);
      }
    });
  };

  _setToDelete = (id, asset_id) => {
    console.log(id, asset_id);
    this.toDeleteId = id;
    this.toDeleteAssetId = asset_id;
  };

  _handleDelete = () => {
    deleteCo2xLiteData(this.token, this.toDeleteId, (err, data) => {
      if (data) {
        if (data.message === "Remove Successful.") this.fetchCo2xLiteData();
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
    });
  };

  setEditMode = (id, data) => {
    console.log(data);
    this.editMode = id;
    this.entryData.id = data.id;
    this.entryData.asset_id = data.license_plate;
    this.entryData.vehicle_type = data.vehicle_type;
    this.entryData.type = data.fuel_type;
    this.entryData.consumed = data.fuel_consume;
    this.entryData.type_unit = data.fuel_unit;
    this.entryData.mileage = data.mileage;
    this.entryData.mileage_unit = data.mileage_unit;
    this.entryData.report_type = data.report_type;
    this.entryData.period_start = new Date(data.start_period);
    this.entryData.period_end =
      this.entryData.report_type === "Yearly"
        ? new Date(this.entryData.period_start.getFullYear(), 11, 31)
        : new Date(
            this.entryData.period_start.getFullYear(),
            this.entryData.period_start.getMonth() + 1,
            0
          );
  };

  _handleEdit = () => {
    updateCo2xLiteData(this.token, this.entryData, (err, data) => {
      if (data) {
        if (data.message === "Token Expired!") {
          window.location.pathname = "/";
          toast.info(data.message);
        } else window.location.reload();
      } else {
        toast.error(data.message);
      }
    });
  };

  fetchRequestReport = (id, status) => {
    requestReport(this.token, id, status, (err, data) => {
    //   if (data) {
        if (data.message === "Token Expired!") {
          window.location.pathname = "/";
          toast.info(data.message);
        } else if (err) {
          toast.error(err.message);
        } else {
          toast.success(data.message);
          this.fetchCo2xLiteData()
        }
    //   } else {toast.error(data.message);}
    });
  };

  fetchDownloadReport = (id) => {
    downloadReport(this.token, id, (err, data) => {
      if (data) {
        if (data.message === "Token Expired!") {
          window.location.pathname = "/";
          toast.info(data.message);
        } else this.reportData = data;
      } else toast.error("No responsed data");
    });
  };

  fetchDashboardData = () => {
    fetchDashboardData(this.token, this.companyId, (err, data) => {
      if (data) {
        if (data.message === "Token Expired!") window.location.pathname = "/";
        else this.dashboardData = data.response;
      } else toast.error(data.message);
    });
  };

  createNewAsset = () => {
    const data = {
      co2xEntry: {
        userId: this.userId,
        companyId: this.companyId,
        reportType: this.entryData.report_type,
        startPeriod: this.entryData.period_start,
        endPeriod: this.entryData.period_end,
        fuelType: this.entryData.type,
        totalConsumption: this.entryData.consumed,
        totalMileage: this.entryData.mileage,
        plateNumber: this.entryData.asset_id,
        isIndividual: this.entryData.isIndividual,
        remark: this.entryData.remark,
        vehicleTypes: this.entryData.vehicle_type.filter(
          (v) => v.noOfVehicle != ""
        ),
      },
    };
    console.log(toJS(data));
    const err = liteEntryFormValidation(this.entryData);
    if (err.asset_id) {
      document.getElementById("asset_id").focus();
      toast.error(err.asset_id);
    } else if (err.vehicle_type) {
      document.getElementById("vehicle_type").focus();
      toast.error(err.vehicle_type);
    } else if (err.type) {
      // document.getElementById("type").focus();
      toast.error(err.type);
    } else if (err.consumed) {
      document.getElementById("consumed").focus();
      toast.error(err.consumed);
    }
    // else if (err.type_unit) {
    //     // document.getElementById("type_unit").focus();
    //     toast.error(err.type_unit);
    // }
    else if (err.mileage) {
      document.getElementById("mileage").focus();
      toast.error(err.mileage);
    } else if (err.mileage_unit) {
      document.getElementById("mileage_unit").focus();
      toast.error(err.mileage_unit);
    } else if (err.report_type) {
      document.getElementById("report_type").focus();
      toast.error(err.report_type);
    } else if (err.period_start) {
      document.getElementById("period_start").focus();
      toast.error(err.period_start);
    } else {
      createNewAssetAPI(this.token, data, (err, data) => {
        if (data) {
          if (data.message === "Token Expired!") window.location.pathname = "/";
          else if (data.response === "Create report successful.") {
            toast.success(data.response);
            window.location.reload();
          } else {
            toast.error(data.response);
          }
        } else toast.error(data.response);
      });
    }
  };

  _setClickedVehicle = (uid) => {
    if (uid === null) this.selectedVehicle = null;
    else this.selectedVehicle = this.assetList.find((v) => v.uid === uid);
  };

  setView = (view) => {
    this.view = view;
  };

  _handleCheck = (keyValue) => {
    console.log(keyValue);
    const selectedIndex = this.entryData.vehicle_type.findIndex(
      (value) => value.keyValue === keyValue
    );
    if (selectedIndex !== -1) {
      if (this.entryData.vehicle_type[selectedIndex].isSelected === 0) {
        this.entryData.vehicle_type[selectedIndex].isSelected = 1;
      } else {
        this.entryData.vehicle_type[selectedIndex].isSelected = 0;
      }
    }
  };

  _handleCheckType = (value, type) => {
    const isSelected = this.selectedType.findIndex((v) => v === value);
    // const isIdSelected = this.units.findIndex((v) => v === id);
    console.log(value);
    if (type === "radio") {
      this.selectedType = [value];
      const selectedTypeIndex = this.entryData?.vehicle_type?.findIndex(
        (v) => v.vehicleType === value
      );
      console.log(selectedTypeIndex);
      this.entryData.vehicle_type.map((v, i) =>
        i === selectedTypeIndex ? (v.noOfVehicle = 1) : (v.noOfVehicle = "")
      );
      // this.entryData.vehicle_type[selectedTypeIndex].noOfVehicle = 1
    } else {
      if (isSelected === -1) {
        runInAction(() => {
          this.selectedType.push(value);
        });
      } else {
        this.selectedType.splice(isSelected, 1);
      }
    }

    console.log(toJS(this.selectedType));
  };
}
export default new LiteEntry();
