import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { CO2_Loading } from "../../Assets/CO2_Loading";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { CreateInviteLinkModel } from "./CreateInviteLinkModel";
import { DriverTable } from "./DriverTable";
import { Selector } from "../../Tools";
import { useTranslation } from "react-i18next";
import { toJS } from "mobx";

export const DriverList = inject("store")(
  observer((props) => {
    const { t } = useTranslation("admin-panel");
    const { theme } = props;
    const {
      selectedDriver,
      drivers,
      _fetchDriverLst,
      _fetchInviteLink,
      _fetchInviteLinkSend,
      driverOption,
      filterDriver,
      _handleSelectedDriver,
    } = props.store.adminPanel;

    const cardStyle1 = {
      background: Colors[theme].background,
      boxShadow: "0px 5px 10px rgba(0,0,0,0.25)",
      padding: "15px",
      borderRadius: "5px",
      marginBottom: 8,
      color: invertDark(theme),
      cursor: "pointer",
    };

    const rows = filterDriver?.map((driver) => {
      let data = {
        id: driver.id,
        name: driver.username,
        email: driver.email,
        company: driver.name,
        action: "view",
      };

      return data;
    });

    const { role } = props.store.common;

    useEffect(() => {
      _fetchDriverLst();
    }, []);

    return (
      <>
        <div className="col-12 p-1">
          <div className="d-flex align-items-center">
            <h4 style={{ color: invertDark(theme), paddingRight: 30 }}>
              {t("Driver List")}
            </h4>
            {role === "super_admin" ? <></> : <></>}
            <span
              data-bs-toggle="modal"
              data-bs-target="#createInviteLinkModel"
              data-toggle="modal"
              data-target=".bd-example-modal-lg"
            >
              <button
                onClick={() => _fetchInviteLink()}
                type="button"
                className="btn text-light"
                style={{
                  background: Colors.low,
                  fontSize: 12,
                  padding: 5,
                  height: 30,
                }}
              >
                {t("Invite Link")}
                <i className="ps-2 bi bi-plus-circle"></i>
              </button>
            </span>
          </div>

          <div style={cardStyle1}>
            {drivers ? (
              <>
                {" "}
                <div>
                  <div
                    className="col-12 d-flex align-items-center mb-2"
                    style={{ width: "100%" }}
                  >
                    <span className="col-12 pe-2">
                      <Selector
                        label={t("Drivers")}
                        defaultValue={selectedDriver}
                        _handleSelect={(e) => {
                          _handleSelectedDriver(e);
                        }}
                        options={driverOption}
                      />
                    </span>
                  </div>
                  <DriverTable theme={theme} rows={rows} data={filterDriver} />
                </div>{" "}
              </>
            ) : (
              <>
                <CO2_Loading />
              </>
            )}
          </div>
        </div>

        <CreateInviteLinkModel
          cardStyle={props.cardStyle}
          rowStyle={props.rowStyle}
          handleSendMail={_fetchInviteLinkSend}
        />
      </>
    );
  })
);
