import jwtDecode from "jwt-decode";
import { Cookies } from "react-cookie";
import * as API from "./URL";

export const fetchInviteLinkInfo = async (id, callback) => {
  return fetch(`${API.inviteLinkInfoApi}?id=${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const fetchRegister = async (data, callback) => {
  return fetch(API.registerAPI, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const LoginFetch = async (data, callback) => {
  return fetch(API.LoginAPI, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      email: data.eMail,
      password: data.password,
    }),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const CreateUser = async (token, data, callback) => {
  return fetch(API.createNewUserAPI, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const fetchLogout = async (callback) => {
  const cookie = new Cookies();
  const token = cookie.get("accessToken");
  const userTokenId = token && jwtDecode(token).userTokenId;
  return fetch(`${API.logoutAPI}?userTokenId=${userTokenId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const SendEmail = async (token, userId, callback) => {
  return fetch(`${API.sendEmailAPI}?u_id=${userId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "x-access-token": token,
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};
