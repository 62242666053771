import { Colors } from "../../Assets/Config/color.config";
export const InfoCard = (props) => {
    const { theme, label, smallFont, value, image } = props;
    return (
        <div
            className="m-1 p-3"
            style={{
                background: Colors[theme].liteSmallCard,
                boxShadow: "4px 4px 11px 5px rgba(0, 0, 0, 0.1)",
                borderRadius: 10,
                color: 'white'
            }}
        >
            <div className="text-center text-nowrap">{label}</div>
            <div className="text-center text-nowrap" style={{ height: 50 }}>
                {smallFont ? <h6 className="pt-3" style={{ textShadow: '3px 3px 4px rgba(0, 0, 0, 0.2)' }}>{value}</h6>
                    : <h5 className="pt-3" style={{ textShadow: '3px 3px 4px rgba(0, 0, 0, 0.66)' }}>{value}</h5>}
            </div>
            <span style={{position:'relative', top:-55,right:0}}><img height={40} className="position-absolute" src={image}/></span>
        </div>
    );
};
