import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Colors } from "../../Assets/Config/color.config";
import { CompanySelector } from "../../Components/Dashboard/companySelector";
import { Selector } from "../../Tools";
import month from "../../Assets/month.json";
import { MonthlyTable } from "../../Components/TrackAndTrace/MonthlyTable";
import { MonthlyDataDetailModel } from "../../Components/TrackAndTrace/MonthlyDataDetailModel";
import { ResendCertificateConfirmModal } from "../../Components/TrackAndTrace/ResendCertificateConfirmModal";
import { SendCertificateConfirmModal } from "../../Components/TrackAndTrace/SendCertificateConfirmModal";
import { toJS } from "mobx";
import { useTranslation } from "react-i18next";
import { MonthlyHistoryModal } from "../../Components/TrackAndTrace/MonthlyHistoryModal";
import { TableReport } from "../../Components/TrackAndTrace/TableReport";
import { UnitTab } from "../../Components/TrackAndTrace/UnitTab";

export const NewTrackAndTraceContainer = inject("store")(
  observer((props) => {
    const { t } = useTranslation("track_and_trace");
    const theme = localStorage.getItem("theme");
    const { role, companyYears, fetchCompanyYears, companyType } =
      props.store.common;

    const sortedYear = companyYears?.response?.slice().reverse();

    const {
      selectedYear,
      _handleChangeYear,
      monthlyData,
      _fetchMonthlyDataV2,
      _handleUpdateCertificates,
      _handleUpdateCertificatesV2,
      _handleSendCertificates,
      selectedMonthlyDetail,
      LoadingForMonthlyTable,
      _handleGetStacsHistory,
      stacsHistory,
      unitsTab,
      _handleChangeUnitsTab,
    } = props.store.trackAndTrace;

    useEffect(() => {
      fetchCompanyYears();
      _fetchMonthlyDataV2(selectedYear, unitsTab);
    }, [unitsTab]);

    const rows = monthlyData?.map((data, index) => {
      if (data !== null) {
        let month_data = null;
        if (companyType === "1") {
          month_data = {
            units: data.units,
            warning: data.showWarning === 0 ? " " : "warning",
            selected_month: data.month,
            month: month[data.month].label,
            fuel_consumed: Number(data.fuel_consumed || 0).toFixed(2) + " L",
            diesel_consumed:
              Number(data?.diesel_consumed || 0).toFixed(2) + " L",
            battery_consumed:
              Number(data.battery_consumed || 0).toFixed(2) + " kWh",
            // mileage: Number(data.mileage).toFixed(2) + " km",
            // trip_count:
            //   data.trip_count == 0
            //     ? data.trip_count.toString()
            //     : data.trip_count,
            co2_emission:
              Number(data.carbon_emission || 0).toFixed(2) + " kgCO₂e",
            baseline_emission:
              Number(data.baseline_emission || 0).toFixed(2) + " kgCO₂e",
            offset:
              data.history_baseline_emission === 0
                ? Number(data.carbon_emission - data.baseline_emission).toFixed(
                    2
                  ) + " kgCO₂e"
                : Number(
                    data.carbon_emission - data.history_baseline_emission
                  ).toFixed(2) + " kgCO₂e",

            action: "update",
          };
        } else {
          month_data = {
            units: data.units,
            warning: data.showWarning === 0 ? " " : "warning",
            selected_month: data.month,
            month: month[data.month].label,
            fuel_consumed: Number(data.fuel_consumed || 0).toFixed(2) + " L",
            diesel_consumed:
              Number(data?.diesel_consumed || 0).toFixed(2) + " L",
            battery_consumed:
              Number(data.battery_consumed || 0).toFixed(2) + " kWh",
            // total_engine_hr: Number(data.mileage).toFixed(2) + " km",
            // trip_count:
            //   data.trip_count == 0
            //     ? data.trip_count.toString()
            //     : data.trip_count,
            co2_emission:
              Number(data.carbon_emission || 0).toFixed(2) + " kgCO₂e",
            baseline_emission:
              Number(data.baseline_emission || 0).toFixed(2) + " kgCO₂e",
            offset:
              data.history_baseline_emission === 0
                ? Number(data.carbon_emission - data.baseline_emission).toFixed(
                    2
                  ) + " kgCO₂e"
                : Number(
                    data.carbon_emission - data.history_baseline_emission
                  ).toFixed(2) + " kgCO₂e",

            action: "update",
          };
        }

        return month_data;
      } else {
        let month_data = null;
        if (companyType === "1") {
          month_data = {
            month: month[index + 1].label,
            fuel_consumed: "0.00",
            diesel_consumed: "0.00",
            battery_consumed: "0.00",
            // mileage: "0.00",
            // trip_count: "0",
            baseline_emission: "0.00",
            history_baseline_emission: "0.00",
            co2_emission: "0.00",
            offset: "0.00",
            action: "no-update",
          };
        } else {
          month_data = {
            month: month[index + 1].label,
            fuel_consumed: "0.00",
            diesel_consumed: "0.00",
            battery_consumed: "0.00",
            // total_engine_hr: "0.00",
            // trip_count: "0",
            baseline_emission: "0.00",
            history_baseline_emission: "0.00",
            co2_emission: "0.00",
            offset: "0.00",
            action: "no-update",
          };
        }

        return month_data;
      }
    });

    const units = monthlyData?.map((v) => toJS(v.units));

    return (
      <>
        <div
          className="pt-2"
          style={{ color: Colors.Dark[theme === "light" ? 2 : 4] }}
        >
          <span
            style={{
              fontSize: 23,
              color:
                theme === "dark" ? Colors["light"].background : Colors.Dark[2],
            }}
            className="title-report-guide"
          >
            {t(" GHG Emissions Report")}
            {/* <Tour/> */}
          </span>
          <div className="align-self-center pt-2 d-flex justify-content-between">
            <CompanySelector theme={theme} label={t("Organisation") + ": "} />

            {/* <My_Button onClick={() => setCreateMode(!createMode)} text={!createMode ?'Create Report':'Cancel'} small /> */}
            <div className="d-flex justify-content-center align-items-center">
              <div className="pe-2">
                <UnitTab
                  _handleChangeUnitsTab={_handleChangeUnitsTab}
                  theme={theme}
                  unitsTab={unitsTab}
                />
              </div>
              <Selector
                menuListColor="black"
                options={sortedYear}
                value={sortedYear?.find((v) => v.value === selectedYear)}
                _handleSelect={(e) => _handleChangeYear(e.value)}
              />
            </div>
          </div>

          {/* {monthlyData?.length > 0 ? (
            <>
              <MonthlyTable theme={theme} rows={rows} noPagination={true} />
            </>
          ) : (
            <>
              <CO2_Loading />
            </>
          )
          } */}

          <MonthlyTable
            companyType={companyType}
            theme={theme}
            rows={rows}
            noPagination={true}
            LoadingForMonthlyTable={LoadingForMonthlyTable}
            _handleGetStacsHistory={_handleGetStacsHistory}
          />
        </div>

        <MonthlyDataDetailModel
          companyType={companyType}
          theme={theme}
          role={role}
          rows={rows}
          unitsTab={unitsTab}
        />
        <MonthlyHistoryModal
          companyType={companyType}
          stacsHistory={stacsHistory}
          theme={theme}
          monthlyData={monthlyData}
        />
        <ResendCertificateConfirmModal
          theme={theme}
          _handleUpdateCertificates={_handleUpdateCertificates}
          _handleUpdateCertificatesV2={_handleUpdateCertificatesV2}
          id={selectedMonthlyDetail?.certificate?.id}
        />
        <SendCertificateConfirmModal
          theme={theme}
          _handleSendCertificates={_handleSendCertificates}
        />
        <TableReport
          theme={theme}
          selectedMonthlyDetail={selectedMonthlyDetail}
          selectedYear={selectedYear}
        />
      </>
    );
  })
);
