import * as API from "./URL";

export const fetchMonthlyData = async (token, companyId, year, unitsType, callback) => {
    return fetch(`${API.monthlyDataAPI}?companyId=${companyId}&year=${year}&unitsType=${unitsType}`, {
        method: "GET",
        headers: {
            //"Content-Type": "application/json",
            Accept: "*/*",
            "x-access-token": token,
        },
    }).then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err.toString(), null));
}


export const fetchMonthlyDataV2 = async (token, companyId, year, unitsType, callback) => {
    return fetch(`${API.monthlyDataV2API}?companyId=${companyId}&year=${year}&unitsType=${unitsType}`, {
        method: "GET",
        headers: {
            //"Content-Type": "application/json",
            Accept: "*/*",
            "x-access-token": token,
        },
    }).then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err.toString(), null));
}