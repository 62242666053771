import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { CO2_Loading } from "../../Assets/CO2_Loading";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { FilterBar } from "../../Components/Tracking/filterBar";
import VehicleProfile from "../../Components/Tracking/VehicleProfile";
import EquipmentProfile from "../../Components/Tracking/EquipmentProfile";
import { NotificationCard } from "../../Tools/lastUpdate";

const TrackingContainer = (props) => {
  const {  windowResize, isAds, lastUpdate } =
    props.store.common;
  const theme = localStorage.getItem("theme");
  const { vehicleList, 
          assetProfile, 
          fetchVehicleList, 
          selectedAsset, 
          handleRowSelect 
        } = props.store.tracking;
  const [setPreviewVehicle] = useState("");
  const [indicatorHeight, setIndicatorHeight] = useState(0);
  useEffect(() => {
    windowResize();
  }, [windowResize]);

  useEffect(() => {
    if (selectedAsset) {
      handleRowSelect(selectedAsset);
    } else {
      fetchVehicleList();
    }
  }, []);

  useEffect(() => {
    if (lastUpdate === 0) fetchVehicleList();
  }, [lastUpdate]);

  useEffect(() => {
    const height = document.getElementById("indicator")?.offsetHeight;
    setIndicatorHeight(height);
  }, [assetProfile, document.getElementById("indicator")?.offsetHeight]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   vehicleList !== null && _handleLastUpdateTime();
  // }, []);

  const _handlePreviewVehicle = (asset_name) => {
    setPreviewVehicle(asset_name);
  };

  const cardStyle1 = {
    background: Colors[theme].background,
    boxShadow: "0px 5px 10px rgba(0,0,0,0.25)",
    padding: "15px",
    borderRadius: "5px",
    marginBottom: 8,
    color: invertDark(theme),
    // minHeight: 193.69,
  };

  const rowStyle = {
    background: theme === "dark" ? Colors.darkBlue : Colors.Dark[5],
    borderRadius: "5px",
    fontSize: "12px",
    flex: 1,
  };
  //console.log("assetProfile",assetProfile);
  return vehicleList ? (
    <div>
      <NotificationCard theme={theme} lastUpdate={lastUpdate} />
      <FilterBar
        {...props}
        cardStyle1={cardStyle1}
        _handlePreviewVehicle={_handlePreviewVehicle}
        width={props.width}
      />
      {isAds ? <div style={{ marginTop: window.innerWidth <= 540 && indicatorHeight }}>
          <EquipmentProfile cardStyle1={cardStyle1} rowStyle={rowStyle} />
        </div> : (
        <div style={{ marginTop: window.innerWidth <= 540 && indicatorHeight }}>
          <VehicleProfile cardStyle1={cardStyle1} rowStyle={rowStyle} />
        </div>
      )}
    </div>
  ) : (
    <CO2_Loading  />
  );
};

export default inject("store")(withRouter(observer(TrackingContainer)));
