import { Colors, invertDark } from "../../Assets/Config/color.config";
import Store from "../../Store";
import ReactSpeedometer from "react-d3-speedometer";
import { emission_vehicle } from "../../Assets/Config/constant.config";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";

export const EmissionIndicator = inject("store")(
    observer((props) => {
        const {t} = useTranslation("new-tracking")
        const theme = localStorage.getItem("theme");
        const { assetProfile } = props.store.tracking;
        const isLoading = !assetProfile;

        const totalCarbonEmission = assetProfile ? (Number(assetProfile.fuelConsumed) * emission_vehicle) / 1000 : 0;

        const totalTripMileage = assetProfile ? Number(assetProfile.vehicleMileage) : 0;

        const emissionFactor = assetProfile && ((totalCarbonEmission / totalTripMileage) * 1000000 || 0);

        const baselineEmissionFactor = assetProfile && (Number(assetProfile.manualBaselineEmissionPerKm) || (assetProfile.totalBaselineEmission / totalTripMileage))
        const minValue = 1100
        const maxValue = 500

        return (
            !isLoading ? (
                <div className="d-flex row align-items-center justify-content-center text-center m-0 h-100"
                    style={{ color: invertDark(theme) }}>
                    <span><strong>Baseline Emission Intensity:</strong> {baselineEmissionFactor.toFixed(2)} gCO<sub>2</sub>/km</span>
                    {emissionFactor > minValue || emissionFactor < maxValue ?
                        <div style={{ background: theme === "dark" ? Colors.darkBlue : Colors.Dark[4], width: 255 }} className="py-2 rounded">
                            <i className="fa fa-exclamation-triangle pe-2" style={{ color: Colors.fuel }} />{emissionFactor > minValue ? (t("Overall Carbon Emission factor has exceeded")+" 1,100 gCO2/km") : "Incomplete Data"}</div>
                        :
                        <ReactSpeedometer
                            minValue={minValue}
                            maxValue={maxValue}
                            maxSegmentLabels={1}
                            // customSegmentStops={[500]}
                            // segments={600}
                            width={200}
                            height={125}
                            ringWidth={10}
                            needleHeightRatio={0.8}
                            value={Number(emissionFactor?.toFixed(2))}
                            valueFormat={','}
                            needleColor={Colors.lightBlue}
                            textColor={invertDark(theme)}
                            valueTextFontWeight={200}
                        />}
                    <span
                        className="w-100 mb-0 fw-bold"
                    >
                        {t("Overall Carbon Emission Intensity")} (gCO₂/km)
                    </span>
                </div>
            ) : (
                "Loading..."
            )
        );
    })
)
