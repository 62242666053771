import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { CO2_Loading } from "../../Assets/CO2_Loading";
import { Colors } from "../../Assets/Config/color.config";
import { AssetProfileManagement } from "../../Components/AssetProfileManagement/AssetProfileManagement";

const AssetProfileContainer = (props) => {
  const {width,windowResize}=props.store.common

  const {
    _fetchUnits,
    deviceList,
    fetchAssetType,
    typeOptions,
    selectedType,
    _handleSelectType,
    _handleTextChange,
    _handleSelectDevice,
    newEditData,
    fetchUpdateDevice,
    algorithmParam,
    algorithmParamOptions,
    selectedAlgorithm,
    selectedSchedule,
    scheduleListOptions,
    companyId,
    currentAssets, 
    _handleSelectAssets,
    selectedAssetType,
    _handleGetEmissionTypeUnits,
    emissionTypeUnits,
  } = props.store.assetProfile;


  useEffect(async() => {
    _fetchUnits();
    _handleGetEmissionTypeUnits()
    // fetchAlgorithmParam();
    // fetchScheduleList();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    windowResize()
  },[]) // eslint-disable-line react-hooks/exhaustive-deps


  return (
   
    <div style={{ color: Colors.Dark[5] }}>
      {deviceList ? (
        <AssetProfileManagement
          {...props}
          deviceList={deviceList}
          selectedDeviceData={toJS(newEditData)}
          typeOptions={typeOptions}
          selectedType={selectedType}
          _handleSelectType={_handleSelectType}
          _handleTextChange={_handleTextChange}
          _handleSelectDevice={_handleSelectDevice}
          currentAssets={currentAssets}
          _handleSelectAssets={_handleSelectAssets}
          fetchUpdateDevice={fetchUpdateDevice}
          algorithmParam={algorithmParam}
          algorithmParamOptions={algorithmParamOptions}
          selectedAlgorithm={selectedAlgorithm}
          selectedSchedule={selectedSchedule}
          scheduleListOptions={scheduleListOptions}
          selectedAssetType={selectedAssetType}
          emissionTypeUnits={emissionTypeUnits}
        />
      ) : (
        <CO2_Loading />
      )}
    </div>
  );
};

export default inject("store")(withRouter(observer(AssetProfileContainer)));
