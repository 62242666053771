import { Selector } from "../../Tools";
import { CompanySelector } from "../Dashboard/companySelector";
import { VehicleDetailImage2 } from "./vehicleDetailImage";
import { PreviewVehicleModal } from "./PreviewVehicleModal";
import { Colors } from "../../Assets/Config/color.config";
import { EmissionIndicator } from "./OverAllEmissionIndicator";
import { toJS } from "mobx";
import { useTranslation } from "react-i18next";

export const FilterBar = (props) => {
    const {t} = useTranslation("new-tracking")
    const {
        year,
        selectedUid,
        vehicleList,
        handleVehicleSelect,
        handleYearSelect,
        selectedAssetName,
        selectedAssetId
    } = props.store.tracking;
    const theme = localStorage.getItem("theme");
    const { isAds } = props.store.common;

    const assetTypes = [
        { value: "vehicle", label: "Vehicle", isDisabled: isAds },
        { value: "equipment", label: "Equipment", isDisabled: !isAds },
    ];

    const years = [
        { value: 2022, label: "2022" },
        { value: 2021, label: "2021" },
    ];

    const vehicleOptions = vehicleList.map((asset) => {
        return {
            label: asset.asset_name,
            value: isAds ? asset.asset_id : asset.uid,
            asset_id: asset.asset_id,
        };
    });
    const vehicle = isAds
        ? vehicleList.find((asset) => asset.asset_id === selectedAssetId)
        : vehicleList.find((asset) => asset.uid === selectedUid);

    const vehicle1 =
        isAds
            ? vehicleList.find((v) => v.asset_id === selectedAssetId)
            : vehicleList.find((v) => v.uid === selectedUid);

    const SelectVehicle = vehicle1 && {
        value: vehicle1.asset_id,
        label: vehicle1.asset_name,
    };
    return (
        <>
            {isAds && <i style={{ color: Colors.fuelLight }}>
                {t("*Showing devices, data type = power.")}
            </i>}

            <span
                style={{
                    fontSize: 23,
                    color: theme === "dark" ? Colors["light"].background : Colors.Dark[2],
                }}
            >
                {t("Unit-level")}
            </span>

            <div className="d-flex flex-row flex-wrap justify-content-between pb-3 px-0 mx-0">
                <div
                    className={`${window.innerWidth > 540 && "d-flex"
                        } gap-4 col-xl-6 col-lg-6 col-sm-12 mb-3`}
                >
                    <div className="col-12 pt-2">
                        <CompanySelector disabled={true} theme={theme} label={t("Organisation")+":"} />
                        <div className="row px-2">
                            <div className="col-4">
                                <Selector
                                    isSearchable={false}
                                    label={t("Asset Type")}
                                    options={assetTypes}
                                    defaultValue={isAds ? assetTypes[1] : assetTypes[0]}
                                    _handleSelect={(assetType) => { }}
                                    disabled={false}
                                />
                            </div>

                            <div className="col-4">
                                <Selector
                                    label={isAds ? "Equipment" : "Vehicle"}
                                    options={vehicleOptions}
                                    value={
                                        SelectVehicle ? SelectVehicle : vehicleOptions[0]
                                    }
                                    _handleSelect={(vehicle) => {
                                        handleVehicleSelect(
                                            vehicle.value,
                                            vehicle.label,
                                            vehicle.asset_id
                                        );
                                    }}
                                    disabled={false}
                                    isSearchable={false}

                                />
                            </div>

                            <div className="col-4">
                                <Selector
                                    isSearchable={false}
                                    label={t("Year")}
                                    options={years}
                                    value={years.find((y) => y.value === year)}
                                    _handleSelect={({ value, label }) => {
                                        const year = value;
                                        handleYearSelect(year);
                                    }}
                                    disabled={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className={`${window.innerWidth > 540 && "d-flex"
                        } gap-4 col-xl-6 col-lg-6 col-sm-12`}
                >
                    <div
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        className="d-flex align-items-center justify-content-center w-100 h-100"
                        style={{ ...props.cardStyle1, cursor: "pointer" }}
                    >

                        <VehicleDetailImage2 
                            text={selectedAssetName ? selectedAssetName : vehicleOptions[0]?.label}
                            isAds={isAds}
                            asset_name={vehicle ? vehicle.asset_name : vehicle1?.asset_name}
                        />

                    </div>

                    <div
                        id="indicator"
                        className="w-100 h-100"
                        // style={rowStyle }
                        style={props.cardStyle1}
                    >
                        <EmissionIndicator />
                    </div>
                </div>
            </div>
            {vehicle && (
                <PreviewVehicleModal theme={theme} asset_name={vehicle.asset_name} />
            )}
        </>
    );
};
