/* eslint-disable react/jsx-pascal-case */
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { RegisterCompanyModal } from "./RegisterCompanyModal";
import { My_Button, My_Input, My_upload_image, Selector } from "../../Tools";
import { Custom_Select } from "../../Tools/Custom_Select";
import { useHistory } from "react-router-dom";
import { inject, observer } from "mobx-react";
import Store from "../../Store";
import { useEffect, useState } from "react";
import allCountry from "../../Assets/countries.json";
import { Checkbox } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { typeOptions } from "./TypeOptions";
import { UploadImage } from "./UploadImage";
import { toJS } from "mobx";
import { useTranslation } from "react-i18next";
import { baseURL } from "../../network/URL";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { BsFillCheckSquareFill } from "react-icons/bs";
import { BsXSquareFill } from "react-icons/bs";
import Tour from "./companyDetailsTour";
import { red } from "@mui/material/colors";
import adminPanel from "../../Store/adminPanel";

export const CompanyInfoDetailsModal = inject("store")(
  observer((props) => {
    const { theme, companyList, userList, companyListWithChildCompanies } =
      props;
    const {
      selectedCompany,
      selectedCountry,
      setSelectedLevel,
      setSelectedCountry,
      _handleEditCompany,
      infoModalView,
      setInfoModalView,
    } = props.store.adminPanel;

    const { selectedVehicle, isLoading, _handleLoading } =
      props.store.dataEntry;

    const cardStyle = {
      color: invertDark(theme),
      background: Colors[theme].background,
    };

    let companyInfoNew = companyList.find((v) => v.id === selectedCompany);

    const { t } = useTranslation("admin-panel");

    const history = useHistory();
    const data = Store.adminPanel.userList;
    const { role, companyId } = props.store.common;

    const {
      level,
      selectedUser,
      setSelectedUser,
      _handleInputChange,
      userData,
      companyInfo,
      _handleCompanyInputChange,
      _handleUploadImage,
      photo,
      _handleChangeRegisterDate,
      _handleChangeExpireDate,
      _handleFormClear,
      _handleCreateCompanyNew,
      _handleCheckBox,
      _handleRegisterCompany,
    } = props.store.adminPanel;

    const [phoneNumber, setPhoneNumber] = useState("");

    const CountryOptions = allCountry?.map((country) => ({
      value: country.name,
      label: country.name,
      code: country.code.toLowerCase(),
    }));

    const levels = ["1", "2", "3", "4", "5"];

    const higherLevels = levels.filter((v) => v > level);

    const levelOptions = higherLevels.map((d, i) => ({
      value: d,
      label: d,
    }));

    const companySizeOptions = [
      {
        value: "1 - 9",
        label: "1 - 9",
      },
      {
        value: "10 - 49",
        label: "10 - 49",
      },
      {
        value: "50 - 249",
        label: "50 - 249",
      },
      {
        value: "250 - 499",
        label: "250 - 499",
      },
      {
        value: "500 and above",
        label: "500 and above",
      },
    ];

    const companyRevenueOptions = [
      {
        value: "Below 1 million",
        label: "Below 1 million",
      },
      {
        value: "1 - 3 million",
        label: "1 - 3 million",
      },
      {
        value: "3 - 5 million",
        label: "3 - 5 million",
      },
      {
        value: "5 - 50 million",
        label: "5 - 50 million",
      },
      {
        value: "Above 50 million",
        label: "Above 50 million",
      },
    ];

    const userOptions = userList?.map((v) => {
      let users = {
        id: v.id,
        label: v.username,
        value: v.username,
      };
      return users;
    });

    const openRegisterModal = () => {
      // let registerModal = new window.bootstrap.Modal(document.getElementById('registercompanymodal'))
      // registerModal.show()
      setInfoModalView(false);
    };

    const status = companyInfoNew?.company_status === "1";
    const [isChecked, setChecked] = useState(status);

    useEffect(() => {
      setChecked(status);
    }, [companyInfoNew]);

    const handleToggle = () => {
      const newStatus = !isChecked ? 1 : 0;
      setChecked(!isChecked);

      adminPanel._handleUpdateCompanyStatus(
        companyInfoNew?.id,
        newStatus,
        (error, data) => {
          if (error) {
            console.error("Error updating company status:", error);
          } else {
            console.log("Company status updated successfully:", data);
          }
        }
      );
    };
    const handleCloseModal = () => {
      _handleLoading(false);
      const newStatus = companyInfoNew?.company_status === "1" ? true : false;
      setChecked(newStatus);
    };

    return (
      // <div className="modal fade" id="companyinfomodal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      //     <div className='modal-dialog modal-lg'>
      //         <div className="modal-content" style={cardStyle}>
      //             <div className="modal-header">
      //                 <h5 className="modal-title" id="exampleModalLabel">More Details</h5>
      //                 <button type="button" className={`btn-close ${theme === 'dark' ? 'btn-close-white' : ''}`} data-bs-dismiss="modal" aria-label="Close"></button>
      //             </div>
      //             <div className="modal-body">
      //                 <div>
      //                     <div className="row">
      //                         <span className="col-4">Ascent ID</span>
      //                         <span className="col-2">:</span>
      //                         <span className="col-6 text-end">{companyInfo?.ascentAccountId == null || companyInfo?.ascentAccountId === "" ? "N/A" : companyInfo?.ascentAccountId}</span>
      //                     </div>
      //                     <div className="row">
      //                         <span className="col-4">STACS Org ID</span>
      //                         <span className="col-2">:</span>
      //                         <span className="col-6 text-end">{companyInfo?.stacs_id === null || companyInfo?.stacs_id === "null" ? "N/A" : companyInfo?.stacs_id}</span>
      //                     </div>
      //                     <div className="row">
      //                         <span className="col-4">Number of assigned units</span>
      //                         <span className="col-2">:</span>
      //                         <span className="col-6 text-end">{selectedVehicle?.length}</span>
      //                     </div>
      //                     <div className='text-warning d-flex justify-content-between align-items-center mt-4'>
      //                         {companyInfo?.stacs_id === null || companyInfo?.stacs_id === "null" ?
      //                             <span className="text-warning">
      //                             <i
      //                                 className="fas fa-exclamation-triangle"
      //                                 title="You need to re-send certificate data."
      //                                 style={{ color: "#ffcc00" }}
      //                             ></i> This company has not yet registered on ESGpedia.</span> :
      //                             <span style={{ color: '#32cd32' }}>This company has been registered on ESGpedia.</span>}
      //                         <div>
      //                             {companyInfo?.stacs_id === null || companyInfo?.stacs_id === "null" ? <button type="button" style={{ fontSize: '15px' }} className="btn btn-primary" onClick={() => openRegisterModal()}>Register</button> : ''}
      //                         </div>
      //                     </div>
      //                 </div>
      //             </div>
      //         </div>
      //     </div>
      // </div>
      <div
        className="modal fade"
        id="companyinfomodal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="w-100 d-flex align-items-center justify-content-end py-2 px-3">
          {/* {<Tour/>} */}
        </div>
        <div
          // className={`modal-dialog ${infoModalView ? 'modal-lg': ' modal-xl'}`}
          className="modal-dialog modal-xl"
        >
          <div className="view-admin-guide modal-content" style={cardStyle}>
            {infoModalView ? (
              <>
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    {/* More Details - {companyInfoNew?.name} */}
                    View Company Details
                  </h5>

                  <button
                    type="button"
                    onClick={handleCloseModal}
                    className={`btn-close ${theme === "dark" ? "btn-close-white" : ""
                      }`}
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>

                <div className="modal-body">
                  {/* <div>
                    <div className="row">
                      <span className="col-4">Ascent ID</span>
                      <span className="col-2">:</span>
                      <span className="col-6 text-end">
                        {companyInfoNew?.ascentAccountId == null ||
                        companyInfoNew?.ascentAccountId === ""
                          ? "N/A"
                          : companyInfoNew?.ascentAccountId}
                      </span>
                    </div>
                    <div className="row">
                      <span className="col-4">STACS Org ID</span>
                      <span className="col-2">:</span>
                      <span className="col-6 text-end">
                        {companyInfoNew?.stacs_id === null ||
                        companyInfoNew?.stacs_id === "null" ||
                        companyInfoNew?.stacs_id === ""
                          ? "N/A"
                          : companyInfoNew?.stacs_id}
                      </span>
                    </div>
                    <div className="row">
                      <span className="col-4">Number of assigned units</span>
                      <span className="col-2">:</span>
                      {!isLoading ? (
                        <div className="col-6 text-end loader">---</div>
                      ) : (
                        <span className="col-6 text-end">
                          {selectedVehicle?.length}
                        </span>
                      )}
                    </div>
                    <div className="row">
                      <span className="col-4">
                        Company Registration Number (Company in UEN context)
                      </span>
                      <span className="col-2">:</span>
                      <span className="col-6 text-end">
                        {companyInfoNew?.companyRegNumber === "" ||
                        companyInfoNew?.companyRegNumber === "undefined" ||
                        companyInfoNew?.companyRegNumber === "undefined " ||
                        companyInfoNew?.companyRegNumber === undefined ||
                        companyInfoNew?.companyRegNumber === null ||
                        companyInfoNew?.companyRegNumber === "null"
                          ? "N/A"
                          : companyInfoNew?.companyRegNumber}
                      </span>
                    </div>

                    <div className="text-warning d-flex justify-content-between align-items-center mt-4">
                      {companyInfoNew?.stacs_id === null ||
                      companyInfoNew?.stacs_id === "null" ||
                      companyInfoNew?.stacs_id === "" ? (
                        <span className="text-warning">
                          <i
                            className="fas fa-exclamation-triangle"
                            title="You need to re-send certificate data."
                            style={{ color: "#ffcc00" }}
                          ></i>{" "}
                          The company is not registered on ESGpedia yet.
                        </span>
                      ) : (
                        <span style={{ color: "#32cd32" }}>
                          The company has been registered on ESGpedia.
                        </span>
                      )}
                      <div>
                        {companyInfoNew?.stacs_id === null ||
                        companyInfoNew?.stacs_id === "null" ||
                        companyInfoNew?.stacs_id === "" ? (
                          <button
                            type="button"
                            style={{
                              fontSize: "15px",
                              background: "#4c5d75",
                              color: "white",
                            }}
                            className="btn"
                            onClick={() => openRegisterModal()}
                          >
                            Register
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div> */}

                  <div
                    className="d-flex flex-column p-3"
                    style={{
                      background:
                        theme === "dark"
                          ? "#25274D"
                          : "rgba(196, 196, 196, 0.3)",
                      borderRadius: "5px",
                    }}
                  >
                    <div className="d-flex justify-content-between w-100">
                      <div className="d-flex justify-content-center align-items-center w-70">
                        <div style={{ height: "80px" }}>
                          <img
                            src={baseURL + companyInfoNew?.logo}
                            alt="company logo"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <div className="d-flex flex-column justify-content-between ps-3">
                          <div className="pb-2">
                            <span
                              style={{ fontWeight: "bold", fontSize: "25px" }}
                            >
                              {companyInfoNew?.name}
                            </span>
                          </div>

                          <div className="pt-2">
                            {companyInfoNew?.stacs_id === null ||
                              companyInfoNew?.stacs_id === "null" ||
                              companyInfoNew?.stacs_id === "" ? (
                              <span
                                className="text-warning"
                                style={{ fontSize: "15px" }}
                              >
                                <i
                                  className="fas fa-exclamation-triangle"
                                  title="You need to re-send certificate data."
                                  style={{ color: "#ffcc00" }}
                                ></i>{" "}
                                The company is not registered on ESGpedia yet.
                              </span>
                            ) : (
                              <span
                                className="d-flex justify-content-center align-items-center"
                                style={{ color: "#32cd32", fontSize: "15px" }}
                              >
                                <span
                                  className="pe-2"
                                  style={{ paddingBottom: "3px" }}
                                >
                                  <BsFillPatchCheckFill />
                                </span>
                                <span>
                                  The company has been registered on ESGpedia.
                                </span>
                              </span>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="w-30 d-flex justify-content-center align-items-center">
                        {role === "super_admin" && (
                          <div
                            class="form-check form-switch d-flex "
                            style={{ paddingRight: "60px" }}
                          >
                            <div className="pt-1">
                              <span className="me-2">
                                {isChecked ? "Activated" : "Deactivated"}
                              </span>
                            </div>

                            <label className="switch">
                              <input
                                type="checkbox"
                                onClick={handleToggle}
                                checked={!isChecked}
                              />

                              <span
                                className="slider round"
                                style={{
                                  backgroundColor: !isChecked
                                    ? "red"
                                    : "#04b700",
                                  outlineColor: !isChecked ? "red" : "#04b700",
                                  border: "none",
                                  boxShadow: "none",
                                  transform: isChecked
                                    ? "translateX(0px)"
                                    : "translateX(5px)",
                                }}
                              ></span>
                            </label>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="d-flex justify-content-center w-100 mt-3">
                      <div
                        className="w-50 me-2 p-3"
                        style={{
                          background:
                            theme === "dark"
                              ? "rgba(70, 72, 102, 0.5)"
                              : "rgb(196, 196, 196)",
                          border: "1px solid rgba(128,128,128, 0.58)",
                          borderRadius: "5px",
                        }}
                      >
                        <div className="pb-2">
                          <h5>Company Informations</h5>
                        </div>
                        <div className="d-flex flex-column justify-content-between gap-4">
                          <div className="row">
                            <span className="col-3">
                              <span className="pe-3">
                                <i className="fa fa-sm fa-phone fa-flip-horizontal"></i>
                              </span>
                              Phone
                            </span>
                            <span className="col-1">:</span>
                            <span className="col-8 text-end">
                              {companyInfoNew?.companyPhone}
                            </span>
                          </div>
                          <div className="row">
                            <span className="col-3">
                              <span className="pe-3">
                                <i className="fas fa-map-marker-alt"></i>
                              </span>
                              Address
                            </span>
                            <span className="col-1">:</span>
                            <span className="col-8 text-end">
                              {companyInfoNew?.companyAddress}
                            </span>
                          </div>
                          <div className="row">
                            <span className="col-3">
                              <span className="pe-3">
                                <i className="fas fa-globe-americas"></i>
                              </span>
                              Country
                            </span>
                            <span className="col-1">:</span>
                            <span className="col-8 text-end">
                              {companyInfoNew?.country}
                            </span>
                          </div>
                          <div className="row">
                            <span className="col-3">
                              <span className="pe-3">
                                <i className="fas fa-globe"></i>
                              </span>
                              Website
                            </span>
                            <span className="col-1">:</span>
                            <span className="col-8 text-end">
                              {companyInfoNew?.companyWebsite}
                            </span>
                          </div>
                          <div className="row">
                            <span className="col-3">
                              <span className="pe-3">
                                <i class="fas fa-file-invoice-dollar"></i>
                              </span>
                              Revenue
                            </span>
                            <span className="col-1">:</span>
                            <span className="col-8 text-end">
                              {companyInfoNew?.companyRevenue}
                            </span>
                          </div>
                          <div className="row">
                            <span className="col-3">
                              <span className="pe-3">
                                <i className="far fa-building"></i>
                              </span>
                              Size
                            </span>
                            <span className="col-1">:</span>
                            <span className="col-8 text-end">
                              {companyInfoNew?.companySize}
                            </span>
                          </div>
                          <div className="row">
                            <span className="col-3">
                              <span className="pe-3">
                                <i className="fas fa-industry "></i>
                              </span>
                              Industry
                              <i className="fas fas-industry ms-4"></i> Sector
                            </span>
                            <span className="col-1">:</span>
                            <span className="col-8 text-end">
                              {companyInfoNew?.industrySector}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-column w-50">
                        <div
                          className="ms-2 p-3"
                          style={{
                            background:
                              theme === "dark"
                                ? "rgba(70, 72, 102, 0.5)"
                                : "rgb(196, 196, 196)",
                            border: "1px solid rgba(128,128,128, 0.58)",
                            borderRadius: "5px",
                          }}
                        >
                          <div className="pb-2">
                            <h5>More Details</h5>
                          </div>

                          <div className="d-flex flex-column justify-content-between gap-4">
                            <div className="d-flex justify-content-between align-items-center">
                              <span style={{ width: "200px" }}>
                                Country Of Incorporation
                              </span>
                              <span style={{ width: "10px" }}>:</span>
                              <span
                                className="text-end"
                                style={{ width: "400px" }}
                              >
                                {companyInfoNew?.countryOfIncorporation ==
                                  null ||
                                  companyInfoNew?.countryOfIncorporation === ""
                                  ? "N/A"
                                  : companyInfoNew?.countryOfIncorporation}
                              </span>
                            </div>

                            <div className="d-flex justify-content-between align-items-center">
                              <span style={{ width: "200px" }}>Ascent ID</span>
                              <span style={{ width: "10px" }}>:</span>
                              <span
                                className="text-end"
                                style={{ width: "400px" }}
                              >
                                {companyInfoNew?.ascentAccountId == null ||
                                  companyInfoNew?.ascentAccountId === ""
                                  ? "N/A"
                                  : companyInfoNew?.ascentAccountId}
                              </span>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <span style={{ width: "200px" }}>
                                STACS Org ID
                              </span>
                              <span style={{ width: "10px" }}>:</span>
                              <span
                                className="text-end"
                                style={{ width: "400px" }}
                              >
                                {companyInfoNew?.stacs_id === null ||
                                  companyInfoNew?.stacs_id === "null" ||
                                  companyInfoNew?.stacs_id === ""
                                  ? "N/A"
                                  : companyInfoNew?.stacs_id}
                              </span>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <span style={{ width: "200px" }}>
                                No. of Units
                              </span>
                              <span style={{ width: "10px" }}>:</span>
                              {!isLoading ? (
                                <div className="d-flex justify-content-end align-items-center" style={{ width: "400px"}}>
                                  <div
                                    className="text-end loader"
                                    style={{ width: '10px' }}
                                  >
                                    ---
                                  </div>
                                </div>
                              ) : (
                                <span
                                  className="text-end"
                                  style={{ width: "400px" }}
                                >
                                  {companyInfoNew?.unit_count}
                                </span>
                              )}
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <span style={{ width: "200px" }}>
                                Company Registration No.
                              </span>
                              <span style={{ width: "10px" }}>:</span>
                              <span
                                className="text-end"
                                style={{ width: "400px" }}
                              >
                                {companyInfoNew?.companyRegNumber === "" ||
                                  companyInfoNew?.companyRegNumber ===
                                  "undefined" ||
                                  companyInfoNew?.companyRegNumber ===
                                  "undefined " ||
                                  companyInfoNew?.companyRegNumber ===
                                  undefined ||
                                  companyInfoNew?.companyRegNumber === null ||
                                  companyInfoNew?.companyRegNumber === "null"
                                  ? "N/A"
                                  : companyInfoNew?.companyRegNumber}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center p-2">
                          <div>
                            {companyInfoNew?.hasWrittenEnvironmentalPolicy ==
                              "NO" ||
                              companyInfoNew?.hasWrittenEnvironmentalPolicy ==
                              "N/A" ? (
                              <span
                                className="pe-2"
                                style={{ color: "#c82828" }}
                              >
                                <BsXSquareFill
                                  style={{ marginBottom: "3px" }}
                                />
                              </span>
                            ) : (
                              <span
                                className="pe-2"
                                style={{ color: "#43e54d" }}
                              >
                                <BsFillCheckSquareFill
                                  style={{ marginBottom: "3px" }}
                                />
                              </span>
                            )}
                            <span>Environmental Policy</span>
                          </div>
                          <div>
                            {companyInfoNew?.hasUnGlobalCompactSignUp == "NO" ||
                              companyInfoNew?.hasUnGlobalCompactSignUp ==
                              "N/A" ? (
                              <span
                                className="pe-2"
                                style={{ color: "#c82828" }}
                              >
                                <BsXSquareFill
                                  style={{ marginBottom: "3px" }}
                                />
                              </span>
                            ) : (
                              <span
                                className="pe-2"
                                style={{ color: "#43e54d" }}
                              >
                                <BsFillCheckSquareFill
                                  style={{ marginBottom: "3px" }}
                                />
                              </span>
                            )}
                            <span className="mt-1">
                              UN Global Compact Sign Up
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ textAlign: "end" }} className="me-3 mb-2">
                  {companyInfoNew?.stacs_id === null ||
                    companyInfoNew?.stacs_id === "null" ||
                    companyInfoNew?.stacs_id === "" ? (
                    <button
                      type="button"
                      style={{
                        fontSize: "15px",
                        background: "#4c5d75",
                        color: "white",
                      }}
                      className="btn"
                      onClick={() => openRegisterModal()}
                    >
                      Register
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </>
            ) : (
              <>
                <div
                  className="modal-header"
                //  style={{ background: Colors[theme]?.background, color: invertDark(theme), border: "none", padding: 6 }}
                >
                  <span
                    style={{
                      fontSize: 24,
                      color: theme === "dark" ? "#fff" : Colors[theme].color,
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                      marginLeft: 2,
                    }}
                  >
                    {t(
                      `${companyInfoNew?.name} - Company Registration to ESGpedia`
                    )}
                  </span>

                  <span
                    data-bs-dismiss="modal"
                    onClick={() => _handleFormClear()}
                    style={{ cursor: "pointer", marginRight: 10 }}
                  >
                    <i className="bi bi-x-lg" />
                  </span>
                </div>

                <div
                  className="modal-body"
                  style={{ background: Colors[theme]?.background }}
                >
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{
                      //  border: theme === 'dark' ? '' : '1px solid black',
                      height: "550px",
                    }}
                  >
                    <div
                      className="d-flex flex-column justify-content-between"
                      style={{
                        borderBottomRightRadius: theme === "dark" ? 5 : "",
                        borderBottomLeftRadius: 5,
                        height: "100%",
                        width: "49%",
                        borderRight: "1px solid #dee2e6",
                        //  overflow: 'scroll'
                      }}
                    >
                      <div className="py-2 pe-5 ps-3">
                        <div className="pb-5">{t("Company Information")}</div>
                        <div className="d-flex align-items-center pb-3">
                          <span className="col-6">
                            <span style={{ color: "#ffc107" }}>*</span>{" "}
                            {t("Incorporation")}
                          </span>
                          <span className="col-6">
                            <Selector
                              //   value={companyInfo?.incorporation}
                              options={CountryOptions}
                              menuListColor="black"
                              _handleSelect={(e) => {
                                _handleCompanyInputChange({
                                  incorporation: e.value,
                                });
                              }}
                            />
                          </span>
                        </div>
                        <div className="d-flex align-items-center pb-3">
                          <span className="col-6">
                            <span style={{ color: "#ffc107" }}>*</span>{" "}
                            {t("Industry Sector")}
                          </span>
                          <span className="col-6">
                            <Selector
                              //   value={companyInfo?.sector}
                              options={typeOptions}
                              menuListColor="black"
                              _handleSelect={(e) => {
                                _handleCompanyInputChange({ sector: e.value });
                              }}
                            />
                          </span>
                        </div>

                        <div className="d-flex align-items-center pb-3">
                          <span className="col-6">{t("Company Website")}</span>
                          <span className="col-6">
                            <My_Input
                              // type={'number'}
                              id={"companyWebsite"}
                              value={
                                companyInfo?.website == "N/A"
                                  ? ""
                                  : companyInfo?.website
                              }
                              placeHolder={"Enter company website..."}
                              onChange={(e) =>
                                _handleCompanyInputChange({
                                  website: e.target.value,
                                })
                              }
                            />
                          </span>
                        </div>
                        <div className="d-flex align-items-center pb-3">
                          <span className="col-6">Phone Number</span>
                          <span className="col-6">
                            <My_Input
                              //type={"number"}
                              id={"phone"}
                              value={
                                companyInfo?.phone == "N/A"
                                  ? ""
                                  : companyInfo?.phone
                              }
                              placeHolder={"Enter company phone number..."}
                              onChange={(e) =>
                                _handleCompanyInputChange({
                                  phone: e.target.value,
                                })
                              }
                            />
                          </span>
                        </div>

                        {/* <div className='d-flex align-items-center pb-3'>
                                                    <span className='col-6'>
                                                        Company Phone Number
                                                    </span>
                                                    <span className='col-6' onChange={() => _handleCompanyInputChange({ phone: phoneNumber })}>
                                                        
                                                        <PhoneInput
                                                            international
                                                            country={'sg'}
                                                            value={phoneNumber}
                                                            onChange={setPhoneNumber}
                                                            inputStyle={{
                                                                background: theme === 'dark' && Colors.darkBlue,
                                                                color: invertDark(theme),
                                                                width: '100%',
                                                                paddingTop: 10,
                                                                paddingBottom: 10,
                                                                fontSize: 14,

                                                                boxShadow: "none",
                                                                shapeOutline: "none",
                                                                outline: "none",
                                                                border: `1px solid ${Colors.secondaryColor}`,
                                                                borderRadius: 5,
                                                            }}
                                                            buttonStyle={{
                                                                background: theme === 'dark' && Colors.darkBlue,
                                                                color: invertDark(theme),
                                                            }}
                                                            dropdownStyle={{
                                                                width: '250px',
                                                                background: theme === 'dark' && Colors.darkBlue,
                                                                color: invertDark(theme),
                                                            }}
                                                        />
                                                    </span>
                                                </div> */}

                        <div className="d-flex align-items-center pb-3">
                          <span className="col-6">{t("Company Size")}</span>
                          <span className="col-6">
                            <Selector
                              //   defaultValue={companyInfo?.size}
                              options={companySizeOptions}
                              menuListColor="black"
                              _handleSelect={(e) => {
                                _handleCompanyInputChange({ size: e.value });
                              }}
                            />
                          </span>
                        </div>

                        <div className="d-flex align-items-center pb-3">
                          <span className="col-6">{t("Company Revenue")}</span>
                          <span className="col-6">
                            <Selector
                              //   value={companyInfo?.revenue}
                              options={companyRevenueOptions}
                              menuListColor="black"
                              _handleSelect={(e) => {
                                _handleCompanyInputChange({ revenue: e.value });
                              }}
                            />
                          </span>
                        </div>

                        <div className="d-flex align-items-center pb-3">
                          <span className="col-6">
                            {t("UN Global Compact SignUp")}
                          </span>
                          <span className="col-6">
                            <span className="d-flex align-items-center">
                              <span
                                className="d-flex align-items-center pe-3"
                                onClick={() =>
                                  _handleCheckBox({ compact_signup: "YES" })
                                }
                              >
                                <Checkbox
                                  style={{
                                    color: Colors.activeColor,
                                    padding: "0px",
                                  }}
                                  checked={
                                    companyInfo?.compact_signup === "YES"
                                      ? true
                                      : false
                                  }
                                  defaultChecked={companyInfo?.compact_signup}
                                />
                                <span
                                  className="ms-1"
                                  style={{ cursor: "pointer" }}
                                >
                                  {t("YES")}
                                </span>
                              </span>
                              <span
                                className="d-flex align-items-center"
                                onClick={() =>
                                  _handleCheckBox({ compact_signup: "NO" })
                                }
                              >
                                <Checkbox
                                  style={{
                                    color: Colors.activeColor,
                                    padding: "0px",
                                  }}
                                  checked={
                                    companyInfo?.compact_signup === "NO"
                                      ? true
                                      : false
                                  }
                                  defaultChecked={companyInfo?.compact_signup}
                                />
                                <span
                                  className="ms-1"
                                  style={{ cursor: "pointer" }}
                                >
                                  {t("NO")}
                                </span>
                              </span>
                            </span>
                          </span>
                        </div>

                        <div className="d-flex align-items-center pb-3">
                          <span className="col-6">
                            {t("Environmental Policy")}
                          </span>
                          <span className="col-6">
                            <span className="d-flex align-items-center">
                              <span
                                className="d-flex align-items-center pe-3"
                                onClick={() =>
                                  _handleCheckBox({ writtenEnv_policy: "YES" })
                                }
                              >
                                <Checkbox
                                  style={{
                                    color: Colors.activeColor,
                                    padding: "0px",
                                  }}
                                  checked={
                                    companyInfo?.writtenEnv_policy === "YES"
                                      ? true
                                      : false
                                  }
                                  defaultChecked={
                                    companyInfo?.writtenEnv_policy
                                  }
                                />
                                <span
                                  className="ms-1"
                                  style={{ cursor: "pointer" }}
                                >
                                  {t("YES")}
                                </span>
                              </span>
                              <span
                                className="d-flex align-items-center"
                                onClick={() =>
                                  _handleCheckBox({ writtenEnv_policy: "NO" })
                                }
                              >
                                <Checkbox
                                  style={{
                                    color: Colors.activeColor,
                                    padding: "0px",
                                  }}
                                  checked={
                                    companyInfo?.writtenEnv_policy === "NO"
                                      ? true
                                      : false
                                  }
                                  defaultChecked={
                                    companyInfo?.writtenEnv_policy
                                  }
                                />
                                <span
                                  className="ms-1"
                                  style={{ cursor: "pointer" }}
                                >
                                  {t("NO")}
                                </span>
                              </span>
                            </span>
                          </span>
                        </div>

                        <div
                          className="d-flex align-items-center pb-3"
                          onClick={() =>
                            _handleCheckBox({
                              declarationOfLegalBusiness: true,
                            })
                          }
                        >
                          <span style={{ cursor: "pointer" }} className="me-3">
                            <Checkbox
                              style={{
                                color: Colors.activeColor,
                                padding: "0px",
                              }}
                              defaultChecked={
                                companyInfo?.declarationOfLegalBusiness == "N/A"
                                  ? ""
                                  : companyInfo?.declarationOfLegalBusiness
                              }
                            />
                          </span>
                          <span style={{ cursor: "pointer" }}>
                            {t("Company declares it is a legal business.")}
                          </span>
                        </div>

                        {/* <div className='d-flex align-items-center pb-3'>
                                                    <span className='col-6'>
                                                        Date of Account Creation
                                                    </span>
                                                    <span className='col-6'>
                                                        <My_DatePicker height={'43px'} selected={companyInfo.registerDate} onChange={(e) => _handleChangeRegisterDate(e)} />
                                                    </span>
                                                </div>

                                                <div className='d-flex align-items-center pb-3'>
                                                    <span className='col-6'>
                                                        Subscription Expiry Date
                                                    </span>
                                                    <span className='col-6'>
                                                        <My_DatePicker height={'43px'} selected={companyInfo.expireDate} onChange={(e) => _handleChangeExpireDate(e)} />
                                                    </span>
                                                </div> */}
                      </div>
                    </div>

                    <div
                      className="py-2 pe-5 ps-3"
                      style={{
                        borderBottomRightRadius: 5,
                        borderBottomLeftRadius: 5,
                        height: "100%",
                        width: "49%",
                      }}
                    >
                      <div className="pb-5">{t("Select Company Admin")}</div>

                      <div>
                        <div className="d-flex align-items-center pb-3">
                          <span className="col-4">{t("User")}</span>
                          <span className="col-8">
                            <Custom_Select
                              options={userOptions}
                              value={
                                userOptions?.find(
                                  (v) => v.label === selectedUser?.label
                                )?.label
                              }
                              _handleClick={(value) => setSelectedUser(value)}
                              selectedValue={selectedUser?.value}
                              theme={theme}
                            />
                          </span>
                        </div>

                        <div className="d-flex align-items-center pb-3">
                          <span className="col-4">
                            <span style={{ color: "#ffc107" }}>*</span>{" "}
                            {t("Designation")}
                          </span>
                          <span className="col-8">
                            <My_Input
                              required={false}
                              id={"designatoin"}
                              value={userData.designation}
                              height="38px"
                              placeHolder={"Enter job title and designation"}
                              onChange={(e) =>
                                _handleInputChange({
                                  designation: e.target.value,
                                })
                              }
                            />
                          </span>
                        </div>

                        {/* <div className='d-flex align-items-center pb-3'>
                                            <span className='col-4'>
                                                Assign Company
                                            </span>
                                            <span className='col-8'>
                                                <Selector options={CompanyOptions}  menuListColor={Colors.darkBlue} _handleSelect={(e) => _handleInputChange({ companyId: e.value })} />
                                            </span>
                                            </div> */}

                        {/* {editView === true ? <div className="mt-3 float-end" >
                                                <span style={{ cursor: 'pointer' }}>
                                                    <My_Button
                                                        customColor={theme === 'dark' ? '#0064FB' : '#04c600'} customHoverColor={theme === 'dark' ? '' : '#05e700'}
                                                        text='Update'
                                                        onClick={() => _handleUpdateUser(userId)}

                                                        style={{ minWidth: 100 }}
                                                        customAdd={<i className="bi bi-plus-square-dotted" style={{ marginLeft: "0.5em" }}></i>}
                                                    />
                                                </span>

                                            </div> : */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer d-flex float-end">
                  <div className="pe-2 py-3">
                    <My_Button
                      onClick={() => {
                        setInfoModalView(true);
                      }}
                      customColor={"#4c5d75"}
                      className="px-3 "
                      text={t("Back")}
                    />
                  </div>
                  <div className="float-end py-3">
                    <My_Button
                      onClick={() => {
                        _handleRegisterCompany();
                        _handleFormClear();
                      }}
                      customColor={"#4c5d75"}
                      className="px-3 "
                      text={t("Register")}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  })
);
