const emission_vehicle = 2.6765; //emission_factor_per_liter kgCo2/L
const emission_chiller = 0.6765;
const emission_ev_vehicle =  0.4080; //kgCo2/kw

const VEHICLE_EMISSION = {
    petrol : 2.27179,
    diesel : 2.67649,
    cng : 1.88496,
    electricity : 0.4080,
}

export { emission_vehicle, emission_chiller, emission_ev_vehicle, VEHICLE_EMISSION };
