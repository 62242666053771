import { inject, observer } from "mobx-react";
import React from "react";
import { CO2_Loading } from "../../Assets/CO2_Loading";
import "../../App.css";
import { emission_vehicle } from "../../Assets/Config/constant.config";
import CarbonEmissionChart_Chiller from "./CarbonEmissionChart_Chiller";
const assetProfile = (props) => {
  const { cardStyle1 } = props;

  const { assetProfile } = props.store.tracking;

  const isLoading = !assetProfile;
  const totalCarbonEmission = assetProfile
    ? (Number(assetProfile.energyConsumed) * emission_vehicle) / 1000
    : 0;

  // const totalTripMileage = assetProfile
  //   ? Number(assetProfile.vehicleMileage)
  //   : 0;

  // const emissionFactor = (totalCarbonEmission / totalTripMileage) * 1000000;

  const rowStyle = {
    marginRight: 5,
    minHeight: 28,
    borderBottom: `1px solid rgba(100,100,100,0.2)`,
  };
  return (
    <div
      style={cardStyle1}
      className="d-flex flex-row flex-wrap justify-content-center"
    >
      {isLoading && <CO2_Loading text="Loading..." />}
      {!isLoading && (
        <>
          <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12 col-xs-12 mb-2 ">
            <h5 className="mb-5">Equipment Profile</h5>

            <div
              className="d-flex justify-content-between align-items-center p-2 mx-0"
              style={rowStyle}
            >
              <strong style={{ textAlign: "left" }}>Data Type</strong>
              <span style={{ textAlign: "right" }}>
                {assetProfile.dataType || "--"}
              </span>
            </div>
            <div
              className="d-flex justify-content-between align-items-center p-2 mx-0"
              style={rowStyle}
              // data-title={`Vehicle Sub Type : ${assetProfile.vehicleSubType || '--'}`}
            >
              <strong style={{ textAlign: "left" }}>Sub Data Type</strong>
              <span style={{ textAlign: "right" }}>
                {assetProfile.subDataType || "--"}
              </span>
            </div>

            <div
              className="d-flex justify-content-between align-items-center p-2 mx-0"
              style={rowStyle}
            >
              <strong style={{ textAlign: "left" }}>
                Power Consumption (MWh)
              </strong>
              <span style={{ textAlign: "right" }}>
                {assetProfile.energyConsumed == null
                  ? "--"
                  : (assetProfile.energyConsumed / 1000).toFixed(2)}
              </span>
            </div>

            <div
              className="d-flex justify-content-between align-items-center px-2 mx-0"
              style={rowStyle}
            >
              <strong style={{ textAlign: "left" }}>
                Total Running Duration (Hours)
              </strong>
              <span style={{ textAlign: "right" }}>
                {assetProfile.totalInMotionDuration == null
                  ? "--"
                  : Number(assetProfile.totalInMotionDuration).toLocaleString(
                      "en-US"
                    )}
              </span>
            </div>

            <div
              className="d-flex justify-content-between align-items-center p-2 mx-0"
              style={rowStyle}
            >
              <strong style={{ textAlign: "left" }}>
                Total Carbon Emission(tonnes)
              </strong>
              <span style={{ textAlign: "right" }}>
                {assetProfile.totalCarbonEmission != null
                  ? Number(assetProfile.totalCarbonEmission).toLocaleString(
                      "en-US"
                    )
                  : totalCarbonEmission != null
                  ? Number(totalCarbonEmission.toFixed(2)).toLocaleString(
                      "en-US"
                    )
                  : "--"}
              </span>
            </div>
            <div
              className="d-flex justify-content-between align-items-center p-2 mx-0"
              style={rowStyle}
            >
              <strong style={{ textAlign: "left" }}>
                Baseline Emission Factor (gCO₂/km)
              </strong>
              <span style={{ textAlign: "right" }}>
                {assetProfile.emissionFactor != null
                  ? Number(assetProfile.emissionFactor).toLocaleString("en-US")
                  : "--"}
              </span>
            </div>
          </div>
          <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 col-xs-12 mb-5">
            <CarbonEmissionChart_Chiller />
          </div>
        </>
      )}
    </div>
  );
};

export default inject("store")(observer(assetProfile));
