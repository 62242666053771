import { EditCompany } from "./EditCompany";
import Store from "../../Store";
import { useEffect, useState } from "react";
import { ContractInfo } from "./ContractInfo";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { getCompanyList } from "../../network/fetchLanding";
import { Cookies } from "react-cookie";
import { CO2_Loading } from "../../Assets/CO2_Loading";
import { UserList } from "./UserList";
import { inject, observer } from "mobx-react";
import { DriverView } from "./DriverView";
import { useHistory } from "react-router-dom";
import { ReportList } from "./ReportList";
import { OrganizationView } from "./OrganizationView";
import { RollerShades } from "@mui/icons-material";
import { My_Button } from "../../Tools";
import { DeleteCompanyModal } from "./DeleteCompanyModal";
import { toJS } from "mobx";
import { CompanySelector } from "../../Components/Dashboard/companySelector";
import { useTranslation } from "react-i18next";
import { getChildCompanies } from "../../network/URL";
import Tour from './adminTour';

const AdminPanelContainer = inject("store")(
  observer((props) => {
    const { t } = useTranslation("admin-panel");
    const cookies = new Cookies();
    const token = cookies.get("accessToken");

    const [editMode, setEditMode] = useState("");
    const [companyList, setCompanyList] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const _handleRowClick = (name) => {
      editMode === "" && setSelectedRow(name);
    };
    const history = useHistory();

    const {
      _fetchUserList,
      userList,
      _fetchReportList,
      selectedTab,
      setSelectedTab,
      userDetail,
      _fetchGetChildCompanies,
      GetChildCompanies,
    } = props.store.adminPanel;
    const theme = localStorage.getItem("theme");
    const cardStyle1 = {
      background: Colors[props.theme].background,
      boxShadow: "0px 14px 40px rgba(0,0,0,0.25)",
      padding: "15px",
      borderRadius: "5px",
      marginBottom: 8,
      color: invertDark(props.theme),
      minHeight: 260,
    };
    const { role, userName } = props.store.common;
    const { companyId } = props.store.adminPanel;
    const { _handleFetchCompanyYears } = Store.dashboard;

    const _handleReportTab = () => {
      setSelectedTab("Report");
      _fetchReportList({ companyId: companyId });
    };

    useEffect(() => {
      // history.push('/admin_panel')
      // fetchCompany()
      _fetchGetChildCompanies();
      _fetchUserList(token, companyId, role);
      _handleFetchCompanyYears();
      // _getAllContract(token)
      // fetchCompany()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchCompany = () => {
      getCompanyList(token, role, companyId, (err, data) => {
        if (data) {
          if (userName === "co2x") {
            data = data.filter((value) => value.id === "24445513");
          }
          setCompanyList(data);
          if (JSON.stringify(selectedRow) === JSON.stringify({})) {
            setSelectedRow(data[0]);
          } else {
            setSelectedRow((prev) => {
              return data.find((d) => d.id === prev.id) || {};
            });
          }
        } else {
          console.log(err);
        }
      });
    };

    if (userDetail === null) {
      return (
        <div className="row px-2 m-0">
          <h4 style={{ color: invertDark(theme) }} className="title-admin-guide">{t("Admin Panel")}{/* <Tour/> */}</h4>

          <div className="align-self-center">
            <CompanySelector theme={theme} label={t("Organization") + ": "} />
          </div>

          <div className="d-flex text-center pb-2">
            <div
              className="company-admin-guide col-lg-2 col-md-3 col-sm-6 col-xs-6 p-2"
              style={{
                // background: v === selectedTab ? Colors.Dark[4] : null,
                borderBottom:
                  "Company" === selectedTab
                    ? `3px solid ${Colors.lightBlue}`
                    : "none",
                cursor: "pointer",
                fontWeight: "Company" === selectedTab ? "bold" : "normal",
                color: invertDark(props.theme),
              }}
              onClick={() => setSelectedTab("Company", history)}
            >
              <i className="fa fa-building pe-1" /> {t("Company")}
              {/* <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#deleteCompanyModal">
                            Delete Company
                        </button> */}
            </div>

            {/* <DeleteCompanyModal /> */}
            <div
              className="user-admin-guide col-lg-2 col-md-3 col-sm-6 col-xs-6 p-2"
              style={{
                // background: v === selectedTab ? Colors.Dark[4] : null,
                borderBottom:
                  "User" === selectedTab
                    ? `3px solid ${Colors.lightBlue}`
                    : "none",
                cursor: "pointer",
                fontWeight: "User" === selectedTab ? "bold" : "normal",
                color: invertDark(props.theme),
              }}
              onClick={() => setSelectedTab("User")}
            >
              <i className="fa fa-user pe-1" /> {t("User")}
            </div>

            <div
              className="driver-admin-guide col-lg-2 col-md-3 col-sm-6 col-xs-6 p-2"
              style={{
                // background: v === selectedTab ? Colors.Dark[4] : null,
                borderBottom:
                  "Driver" === selectedTab
                    ? `3px solid ${Colors.lightBlue}`
                    : "none",
                cursor: "pointer",
                fontWeight: "Driver" === selectedTab ? "bold" : "normal",
                color: invertDark(props.theme),
              }}
              onClick={() => setSelectedTab("Driver")}
            >
              <i className="fa fa-user pe-1" /> {t("Driver")}
            </div>

            {role === "super_admin" ? (
              <div
                className="col-lg-2 col-md-3 col-sm-6 col-xs-6 p-2"
                style={{
                  // background: v === selectedTab ? Colors.Dark[4] : null,
                  borderBottom:
                    "Report" === selectedTab
                      ? `3px solid ${Colors.lightBlue}`
                      : "none",
                  cursor: "pointer",
                  fontWeight: "Report" === selectedTab ? "bold" : "normal",
                  color: invertDark(props.theme),
                }}
                onClick={() => _handleReportTab()}
              >
                <i className="bi bi-file-earmark-text-fill pe-1" />{" "}
                {t("Reports")}
              </div>
            ) : null}
          </div>

          <div className="col-12">
            {selectedTab === "Organization" ? (
              <OrganizationView theme={props.theme} />
            ) : selectedTab === "Company" ? (
              // <div>
              //     aa
              // </div>
              <div className="d-flex  row flex-wrap">
                {GetChildCompanies?.length > 0 ? (
                  <EditCompany
                    selectedRow={selectedRow}
                    editMode={editMode}
                    setEditMode={setEditMode}
                    fetchCompany={fetchCompany}
                    companyList={GetChildCompanies}
                    userList={userList}
                  />
                ) : (
                  <CO2_Loading />
                )}
              </div>
            ) : selectedTab === "Driver" ? (
              <DriverView
                cardStyle={cardStyle1}
                theme={props.theme}
                rowStyle={props.rowStyle}
              />
            ) : selectedTab === "Report" ? (
              <ReportList theme={theme} />
            ) : userList ? (
              <UserList
                cardStyle={cardStyle1}
                theme={props.theme}
                companyList={GetChildCompanies}
              />
            ) : (
              <CO2_Loading />
            )}
          </div>
        </div>
      );
    } else {
      return <ReportList theme={theme} />;
    }
  })
);

export default AdminPanelContainer;
