/* eslint-disable react/jsx-pascal-case */
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import Store from "../../Store";
import { My_Button, Selector } from "../../Tools";
import { UserCard } from "./UserCard";
import AddUser from "../../Assets/Images/icons/addUser.png";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AddNewUserModal } from "./AddNewUserModal";
import { AssignVehiclesCompanyModal } from "./AssignVehicleCompanyModal";
import { AssignVehiclesModal } from "./AssignVehiclesModal";
import { useTranslation } from "react-i18next";
import Tour from './userTour';

export const UserListGridView = inject("store")(
  observer((props) => {
    const { t } = useTranslation("admin-panel");
    const history = useHistory();
    const data = Store.adminPanel.userList;
    const {
      editUserId,
      companies,
      _fetchCompanyList,
      _handleUserDetail,
      _setAddNewUser,
      selectedCompany,
      addVehicleId,
      companyId,
      _setAssignNewVehicleUser,
    } = props.store.adminPanel;
    const { role } = props.store.common;
    const { _handleFetchCompanyUnitsForAssign } = props.store.dataEntry;
    const { theme } = props;

    useEffect(() => {
      _fetchCompanyList(role, companyId);
    }, []);

    let key = "companyId";
    const filteredCompanyList = [
      ...new Map(data.map((item) => [item[key], item])).values(),
    ];

    const CompanyOptions = filteredCompanyList.map((v) => ({
      value: v?.companyId,
      label: v?.name,
    }));

    CompanyOptions.unshift({ value: "All", label: "All" });

    const [comId, setComId] = useState("All");
    const tempData = [];

    const d = data.map((v) => v.companyId === comId && tempData.push(v));
    const filteredData = tempData.length === 0 ? data : tempData;

    const openAssignVehiclesModal = () => {
      let myModal = new window.bootstrap.Modal(
        document.getElementById("assignVehicles")
      );
      myModal.show();
    };

    return (
      <>
        <div
          className="p-2"
          style={{
            background: theme === "dark" ? "rgb(0,0,0,0.4)" : "rgb(0,0,0,0.05)",
            color: invertDark(theme),
            height: "75vh",
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
            border: "1px solid rgb(0,0,0,0.07)",
          }}
        >
          <div className="p-2 pt-1">
            <FilterBar
              theme={theme}
              role={role}
              _setAddNewUser={_setAddNewUser}
              comId={comId}
              setComId={setComId}
              CompanyOptions={CompanyOptions}
            />
            <span></span>
          </div>
          <div
            className="row w-100 p-0 m-0 d-flex"
            style={{ overflow: "scroll", height: "65vh" }}
          >
            {filteredData.map((v, k) => (
              <div
                key={k}
                className="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 col-xs-6 p-1"
                style={{ minWidth: 230 }}
              >
                <div
                  className=""
                >
                  <UserCard editUserId={editUserId} user={v} theme={theme} />
                </div>

                {/* {role === "super_admin" ? (
                  <div className="row">
                    <div className="pe-0 me-0">
                                            <My_Button customColor={theme === 'light' && 'rgb(10, 88, 202)'} customHoverColor={theme === 'light' && 'rgb(10 109 255)'}
                                                onClick={() => _handleUserDetail(v.id, history)} text={t('View Requests')}
                                                style={{ width: '96%', fontSize: '15px', marginTop: 1, marginBottom: 5, borderTopRightRadius: '0px', borderTopLeftRadius: '0px', borderRight: '1px solid #80808026' }}>
                                            </My_Button>
                                        </div>
                    <div className="col-6 ps-1">
                                            <My_Button customColor={theme === 'light' && 'rgb(10, 88, 202)'} customHoverColor={theme === 'light' && 'rgb(10 109 255)'}
                                                style={{ width: '100%', fontSize: '15px', marginTop: 1, marginBottom: 5, borderTopRightRadius: '0px', borderTopLeftRadius: '0px' }} text={t('Assign Units')}
                                                onClick={() => { _handleFetchCompanyUnitsForAssign(v.companyId, v.id, 0); _setAssignNewVehicleUser(true) }}>
                                            </My_Button>
                                        </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col">
                      {v.role === "admin" || v.role === "super_admin" ? (
                        <></>
                      ) : (
                        <>
                          <My_Button
                                                        
                                                        customColor={theme === 'light' && 'rgb(10, 88, 202)'}
                                                        customHoverColor={theme === 'light' && 'rgb(10 109 255)'}
                                                        style={{ width: '100%', marginTop: 1, marginBottom: 5, borderTopRightRadius: '0px', borderTopLeftRadius: '0px' }}
                                                        text={t('Assign Units')}
                                                        onClick={() => { _handleFetchCompanyUnitsForAssign(v.companyId, v.id, 0); _setAssignNewVehicleUser(true) }}>

                                                    </My_Button>
                        </>
                      )}
                    </div>
                  </div>
                )} */}
                {/* <AssignVehiclesCompanyModal theme={theme} user={data} /> */}

                {/* viewRequestOnly */}
                {/* {role === "super_admin" ? 
                                    <div className="row">
                                        
                                        <div className="col">
                                            <My_Button customColor={theme === 'light' && 'rgb(10, 88, 202)'} customHoverColor={theme === 'light' && 'rgb(10 109 255)'}
                                                onClick={() => _handleUserDetail(v.id, history)} text='View Requests'
                                                style={{ width: '100%', marginTop: 1, marginBottom: 5, borderTopRightRadius: '0px', borderTopLeftRadius: '0px' }}>
                                            </My_Button>
                                        </div>
                                    </div>

                                    : null} */}
              </div>
            ))}
          </div>
        </div>

        {/* <AssignVehiclesModal theme={theme} user={data} /> */}
      </>
    );
  })
);

export const FilterBar = (props) => {
  const { CompanyOptions, comId, setComId, _setAddNewUser, role, theme } =
    props;
  const { t } = useTranslation("admin-panel");

  return (
    <div
      style={{}}
      className="d-flex justify-content-between align-items-center"
    >
      <div className="d-flex">
        <div
          // data-bs-toggle="modal"
          // data-bs-target="#exampleModal"
          // data-toggle="modal"
          // data-target=".bd-example-modal-lg"
          title="Add New User"
          className="add-user-guide d-flex justify-content-center align-items-center px-2 reduceOpacityOnhover"
          style={{
            cursor: "pointer",
            borderRadius: "100px",
            zIndex: 1000,
            width: 50,
            height: 50,
            // background: "#2d3e82",
            // border: "3px solid rgb(255,255,255,0.05)",

            // boxShadow:
            //     "rgba(0, 0, 0,1) 0px 0px 1px 1px, rgba(0, 0, 0,0.05) -5px -8px 7px 1px inset",
          }}
          onClick={() => _setAddNewUser(true)}
        >
          <img height="50px" src={AddUser} />
        </div>
        {/* <Tour/> */}
      </div>

      {/* <AddNewUserModal theme={theme} /> */}

      {role ? (
        <div
          className="row d-flex align-items-center pe-3"
          style={{ fontSize: 14 }}
        >
          <span className="col text-end p-0 me-2">
            <i className="bi bi-building pe-2"></i>
            {t("Company")} :
          </span>
          <span className="col p-0 m-0" style={{ width: 300 }}>
            <Selector
              _handleSelect={(e) => setComId(e.value)}
              value={CompanyOptions.find((v) => v.value === comId)}
              menuListColor="black"
              options={CompanyOptions}
            />
          </span>
        </div>
      ) : (
        <div
          style={{ fontSize: 18, color: theme === "dark" ? "white" : "black" }}
        >
          <span className="p-2">
            {" "}
            <i className="bi bi-building pe-2"></i>
            {CompanyOptions[0].label}
          </span>
        </div>
      )}
    </div>
  );
};
