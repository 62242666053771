import { observable, action, makeObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
// import { Credential } from "../helper/decrypt";
import { LoginFormValidation } from "../helper/formValidation";
import { fetchLogout, LoginFetch } from "../network/fetchUser";
import jwt_decode from "jwt-decode";

class Login {
  cookies = new Cookies();
  token = this.cookies.get("accessToken");
  theme = localStorage.getItem("theme") === "light" ? "light" : "dark";
  eMail = "";
  password = "";
  visible = false;
  err = {};
  isDisabled = false;
  auth = this.token ? true : false;
  role = "";
  isFirstTimeLogin = ''
  isSubscriber = ''

  constructor() {
    makeObservable(this, {
      isFirstTimeLogin:observable,
      isSubscriber:observable,
      role: observable,
      eMail: observable,
      password: observable,
      visible: observable,
      err: observable,
      isDisabled: observable,
      auth: observable,
      theme: observable,
      setAuth: action.bound,
      onChangeTheme: action.bound,
      _handleEmailChange: action.bound,
      _handlePwdChange: action.bound,
      _handleView: action.bound,
      fetchLogout: action.bound,
    });
  }

  setAuth(state) {
    this.auth = state;
  }

  onChangeTheme(theme) {
    this.theme =
      theme === "dark" || theme === "light"
        ? theme
        : this.theme === "dark"
        ? "light"
        : "dark";
    localStorage.setItem("theme", this.theme);
    var x = document.getElementsByTagName("BODY")[0];
    this.theme === "dark"
      ? (x.style.backgroundImage = "linear-gradient(-60deg,#101841,#202b60)")
      : (x.style.background = "white");
  }

  _handleEmailChange = (e) => {
    this.eMail = e.target.value;
  };
  _handlePwdChange = (e) => {
    this.password = e.target.value;
  };
  _handleView = () => {
    this.visible = !this.visible;
  };

  // _handleSaveCredential = () => {
  //   const userEnc = Credential.encryptMessage(this.eMail);
  //   const userKey = Credential.encryptMessage(this.password);

  //   // this.cookies.set("user", userEnc);
  //   this.cookies.set("user")
  //   this.cookies.set("key", userKey);
  // };
  _handleSubmit = (e, props) => {
    e.preventDefault();
    const data = { eMail: this.eMail, password: this.password };

    const ValidatedErr = LoginFormValidation(data);
    this.err = ValidatedErr;
    if (ValidatedErr.userErr) {
      document.getElementById("email").focus();
      toast.error(this.err.userErr);
    } else if (ValidatedErr.passwordErr) {
      document.getElementById("password").focus();
      toast.error(this.err.passwordErr);
    }
    if (Object.keys(ValidatedErr).length === 0) {
      runInAction(() => {
        this.isDisabled = true;
      });
      LoginFetch(data, (err, data) => {
        if (data) {
          if (!data.error) {
            // this._handleSaveCredential();
            runInAction(() => {
              this.auth = true;
            });
            const companyName = jwt_decode(data.accessToken).companyName;
            this.role = jwt_decode(data.accessToken).role;
            this.cookies.set("accessToken", data.accessToken, { path: "/" });
            this.cookies.set("company", jwt_decode(data.accessToken).companyId);
            const userType = jwt_decode(data.accessToken).userType;
            console.log("userType", userType);
            this.cookies.set("userType", userType);

            const level = jwt_decode(data.accessToken).level;
            this.cookies.set("level", level);

            const logo = jwt_decode(data.accessToken).logo;
            this.cookies.set("logo", logo);

            this.isFirstTimeLogin = jwt_decode(data.accessToken).isFirstTimeLogin;
            this.isSubscriber = jwt_decode(data.accessToken).isSubscriber;

            // this.cookies.set("logo", logo);
            // if (role === "user" && companyName !== undefined && companyName) {
            //   window.location.pathname =
            //     "/organisation-level-carbon-emission-dashboard";
            // } else {
            if (this.role === "driver") {
              toast.success("Login Success.");
              window.location.pathname = "/m_vehicles";
            }else if(this.isFirstTimeLogin ==="1"){
              toast.success("Login Success.");
              const userId = jwt_decode(data.accessToken).userId
              window.location.pathname = `/password-reset/${userId}`;
            } 
            // else if (this.isSubscriber == "subscriber") {
            //   toast.error("Subscriber cannot log in!")
            //   this.cookies.remove('accessToken')
            //   window.location.pathname = "/";
            else {
              toast.success("Login Success.");
              window.location.pathname = "/company";
            }
            // }
          } else {
            toast.error(data.error);
            this.isDisabled = false;
            document.getElementById("email").focus();
          }
        } else {
          toast.error(err.toString());
          this.isDisabled = false;
        }
      });
    }
  };

  fetchLogout = () => {
    fetchLogout((err, data) => {
      if (data) {
        console.log("hi", data);
      }
    });
  };
}

export default new Login();
