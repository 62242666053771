// import React, { useEffect } from "react";
// import UserLogin from "../../Components/Login/loginComponent";
// import { CookiesProvider } from "react-cookie";
// import "react-toastify/dist/ReactToastify.css";
// import { withRouter } from "react-router";

// const LoginContainer = (props) => {
//   const errStyle = {
//     color: "red",
//     fontSize: 12,
//     marginTop: "-20px",
//   };

//   useEffect(() => {
//     document.getElementById("email").focus();
//   }, []);

//   return (
//     <CookiesProvider>
//       <UserLogin errStyle={errStyle} />
//     </CookiesProvider>
//   );
// };

// export default withRouter(LoginContainer);


import React, { useEffect } from "react";
import UserLogin from "../../Components/Login/loginComponent";
import { CookiesProvider } from "react-cookie";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router";

const LoginContainer = (props) => {
  const errStyle = {
    color: "red",
    fontSize: 12,
    marginTop: "-20px",
  };

  useEffect(() => {
    document.getElementById("email").focus();
  }, []);

  return (
    <div
    style={{
      backgroundImage: "url('/login_page_bg.png')", // Path to your background image
      backgroundSize: "cover", // or "contain"
      backgroundPosition: "bottom center", // Adjust as needed
      backgroundRepeat: "no-repeat",
      backgroundAttachment: "fixed",
      height: "100vh",
      width: "100vw",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
    >
      <CookiesProvider>
        <UserLogin errStyle={errStyle} />
      </CookiesProvider>
    </div>
  );
};

export default withRouter(LoginContainer);