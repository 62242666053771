import Logo from "../Assets/Images/CO2_Logo.png";
import { Colors, invertDark } from "../Assets/Config/color.config";
import {
  DropdownButton,
  Dropdown,
  ButtonGroup,
  Nav,
  Button,
} from "react-bootstrap";
import DrawerMenu from "./drawer";
import { DarkModeSwitch } from "../Tools/darkModeSwitch";
import { withRouter } from "react-router";
import { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import {
  fetchGetManualEntry,
  fetchYearOfMissingData,
} from "../network/manualEntryAPI";
import { inject, observer } from "mobx-react";
import Month from "../Assets/month.json";
import Store from "../Store";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";
import { Notification } from "../Components/Notification/notificationMenu";
import { fetchLogout } from "../network/fetchUser";
import i18n from "i18next";
import { Translation, useTranslation } from "react-i18next";
import { My_Button } from "../Tools";
import LanguageMenuItem from "./LanguageMenuItem";
import { useRef } from "react";
import { PasswordResetModal } from "../Components/PasswordReset/PasswordResetModal";
import { NewsModal } from "../Components/News/NewsModal";

const { token, role } = Store.common;
const cookies = new Cookies();
const companyId = cookies.get("company") || Store.common;
const cookieCompanyId = cookies.get("company");
const companyName = cookies.get("companyName");
const planData = token && jwt_decode(token).planData;
const { companyList, companyListWithChildCompanies } = Store.landing;

// console.log(planData, 'plandatashilrrrrrbrtwylll==>')

const Navbar = (props) => {
  const { theme, width } = props;
  const { t } = useTranslation();
  const titleStyle = {
    color: Colors[theme].primaryColor,
    fontSize: "1.5em",
  };
  const notiDropdownStyle = {
    background: Colors[theme].background,
    padding: "20px",
    width: width > 800 ? 500 : width < 600 ? 500 : 500,
    borderRadius: "5px",
    boxShadow: "0 14px 40px rgba(0,0,0,0.25)",
    border: `1px solid ${invertDark(theme)} `,
    color: invertDark(theme),
    // height: 500,
    transitionDuration: "0.1s",
  };
  const greater1000 = width > 1000;
  const bet850_900 = width > 850 && width < 900;
  const bet750_850 = width > 750 && width < 850;
  const userName = token && jwt_decode(token).username;
  const role = token && jwt_decode(token).role;

  const _handleCLick = () => {
    if (role === "driver") {
    } else {
      window.location.pathname = "/company";
      cookies.remove("company");
      cookies.remove("companyName");
    }
    // if (role === "admin") {

    // } else {
    // props.history.push("/organisation-level-carbon-emission-dashboard");
    // }
  };

  useEffect(
    () =>
      fetchYearOfMissingData(token, { companyId }, (err, data) => {
        if (data && data.message === "Token Expired!") {
          window.location.pathname = "/";
          toast.info(data.message);
        }
      }),
    []
  );

  return (
    <div
      className="navbar d-flex flex-row justify-content-between align-items-center w-100 px-2"
      style={{
        position: "fixed",
        background:
          theme === "dark" ? Colors[theme].background : Colors.Dark[5],
        zIndex: 1550,
      }}
    >
      <div
        style={{ cursor: "pointer" }}
        onClick={_handleCLick}
        className="ps-1 d-flex align-items-center"
      >
        <img
          src={Logo}
          width={"60px"}
          alt="Logo"
          style={
            {
              /*marginTop: "-10px"*/
            }
          }
        />
        {width > 750 && (
          <span
            style={{
              ...titleStyle,
              fontSize: greater1000
                ? "1.5em"
                : bet850_900
                ? "1.2em"
                : bet750_850
                ? "0.9em"
                : null,
            }}
            className="fw-bolder ps-2"
          >
            CO₂ Emission Tracking & Computation Dashboard
          </span>
        )}
      </div>

      <IconGroup
        cookies={cookies}
        theme={theme}
        {...props}
        width={width}
        userName={userName}
        role={role}
        notiDropdownStyle={notiDropdownStyle}
      />
    </div>
  );
};

const IconGroup = (props) => {
  const { t } = useTranslation("dashboard");
  const {
    width,
    notiDropdownStyle,
    theme,
    cookies,
    yearOfMissingData,
    receivedUserAction,
    socket,
    setUserAction,
  } = props;
  const { role } = props.store.common;
  const { currentYear, setCurrentYear, setCurrentMonth, setSelectTable } =
    props.store.manualEntry;
  const { fetchLogout } = props.store.login;
  const [showModal, setShowModal] = useState(false);

  const dropdownRef = useRef(null);

  const {
    selectedYear,
    selectedMonth,
    _handleGetActivityLogs,
    logsNoti,
    _handleGetLogsNoti,
    setPasswordResetView,
    passwordResetView,
    issueNoti,
  } = props.store.adminPanel;
  // console.log("issue ======== >",issueNoti.length)
  const [receivedUserActionCount, setNotificationCount] = useState(0);
  // console.log(" issue noti ======== >",receivedUserActionCount)

  const [issue, setIssue] = useState(1);
  console.log("issue=======/>", issue);

  const { _handleSelectMonth } = props.store.dataEntry;
  const iconStyle = {
    fontSize: "18px",
    color: Colors.Dark[props.theme === "light" ? 2 : 4],
    padding: "12px",
    cursor: "pointer",
  };
  const [monthlyEntry, setMonthlyEntry] = useState([]);
  var months = Month.map((v) => v.label).slice(1, 13);

  const data =
    monthlyEntry &&
    months.map((d, i) => ({ month: d, data: monthlyEntry[i], index: i }));
  const notNullData = data?.filter((d, i) => d.data);

  const filteredNotNullData = notNullData?.map((d, i) => ({
    data: {
      month: d.month,
      vehicles: d.data?.vehicles?.filter(
        (v) =>
          ((v.ascentFuelConsumed === 0 || v.ascentFuelConsumed === null) &&
            (v.manualFuelConsumed === 0 || v.manualFuelConsumed === null)) ||
          ((v.ascentTripMileage === 0 || v.ascentTripMileage === null) &&
            (v.manualTripMileage === 0 || v.manualTripMileage === null))
      ),
      index: d.index,
    },
  }));
  const filteredData =
    filteredNotNullData.length === 0
      ? []
      : filteredNotNullData.filter((v) => v.data.vehicles.length > 0)?.length >
        0
      ? filteredNotNullData.filter((v) => v.data.vehicles.length > 0)
      : ["No Data"];

  const _handleCompanies = () => {
    window.location.pathname = "/company";
  };

  const _handleUserGuide = () => {
    props.history.push("/video_guide");
  };

  const openPasswordResetModal = () => {
    let myModal = new window.bootstrap.Modal(
      document.getElementById("passwordresetmodal")
    );
    myModal.show();
  };

  const openModal = () => {
    setShowModal(true);
    // console.log("clicked");
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const _handleFeedBack = () => {
    window.location.pathname = "/customer_support";
  };

  const _handleAdminPanel = () => {
    props.history.push("/admin_panel");
  };

  const _handleCompanySetup = () => {
    props.history.push("/company-setup");
  };

  const _handleCustomerSupport = () => {
    props.history.push("/customer_support");
  };

  const _handleLogs = () => {
    props.history.push("/logs");
  };

  const companylistpage = () => {
    window.location.pathname = "/company";
  };

  const handleLogout = async () => {
    fetchLogout();
    localStorage.removeItem("theme");
    cookies.remove("accessToken");
    cookies.remove("companyName");
    cookies.remove("storedTable");

    window.location.pathname = "/";
  };
  const [open, setOpen] = useState(false);
  const _handleYearSelect = (year) => {
    setMonthlyEntry([]);
    setCurrentYear(year);
    fetchEntry(year);
  };
  const _handleMonthSelect = (month) => {
    setCurrentMonth(month);
    props.history.push("/new-manual-entry");
    setOpen(false);
    _handleSelectMonth(monthlyEntry[month], month);
  };

  const handleOpen = async () => {
    setOpen(!open);
    setIssue(0);
    setNotificationCount(0);
    !open && _handleYearSelect(currentYear);
  };
  const fetchEntry = (y) => {
    const token = cookies.get("accessToken");
    const data_ = { companyId: companyId, year: y };
    fetchGetManualEntry(token, data_, (err, data) => {
      setMonthlyEntry([]);
      if (data) {
        if (data.message === "Token Expired!") {
          toast.info(data.message);
          window.location.pathname = "/";
        }
        setMonthlyEntry(data?.consumptionsByMonth);
      }
    });
  };

  useEffect(() => {
    socket?.on("userActivity", (receivedUserAction) => {
      // setUser(receivedUserAction);
      setUserAction(receivedUserAction);
      setNotificationCount((prevCount) => prevCount + 1);
    });

    return () => {
      socket?.off("userActivity");
    };
  }, [socket]);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // console.log("monthlyEntry",monthlyEntry);
  if (width < 702) {
    return (
      <div>
        {role === "driver" ? (
          <></>
        ) : (
          <DropdownButton
            id="clickbox"
            className="dropdownStart"
            show={open}
            as={ButtonGroup}
            variant="none"
            title={
              ""
              //   <span
              //     style={{ ...iconStyle, position: "relative" }}
              //     onClick={() => handleOpen(currentYear)}
              //   >
              //     <i className="bi-bell-fill" />
              //     <div
              //       style={{
              //         width: 10,
              //         height: 10,
              //         background: Colors.high,
              //         position: "absolute",
              //         right: 10,
              //         top: 14,
              //         borderRadius: "50%",
              //         border: `1.5px solid`,
              //       }}
              //     />
              //   </span>
            }
          >
            <Notification
              notiDropdownStyle={notiDropdownStyle}
              setOpen={!open}
              currentYear={currentYear}
              _handleYearSelect={_handleYearSelect}
              filteredData={filteredData}
              _handleMonthSelect={_handleMonthSelect}
              theme={theme}
            />
          </DropdownButton>
        )}

        {/* <DrawerMenu
                    onChangeTheme={props.onChangeTheme}
                    handleLogout={handleLogout}
                    theme={props.theme}
                /> */}

        {window.location.pathname === "/company" ? (
          <></>
        ) : (
          <DrawerMenu
            onChangeTheme={props.onChangeTheme}
            handleLogout={handleLogout}
            theme={props.theme}
          />
        )}
      </div>
    );
  }
  return (
    <>
      <Nav>
        <div className="d-flex flex-nowrap justify-content-between align-items-center">
          {/* <LanguageMenuItem theme={theme} /> */}
          <DarkModeSwitch
            onChangeTheme={props.onChangeTheme}
            theme={props.theme}
          />
          {role === "super_admin" && window.location.pathname !== "/company" ? (
            <DropdownButton
              id="clickbox"
              className="dropdownStart "
              show={open}
              ref={dropdownRef}
              as={ButtonGroup}
              variant="none"
              title={
                ""
                // <span
                //   style={{ ...iconStyle, position: "relative" }}
                //   onClick={() => handleOpen()}
                // >
                //   <i className="bi-bell-fill" />
                //   {receivedUserActionCount || issue === 1 ? (
                //     <div
                //       style={{
                //         width: 10,
                //         height: 10,
                //         background: Colors.high,
                //         position: "absolute",
                //         right: 10,
                //         top: 10,
                //         borderRadius: "50%",
                //         border: `1.5px solid`,
                //       }}
                //     />
                //   ) : (
                //     ""
                //   )}

                //   {/* {receivedUserActionCount > 0 ? (
                //     <div
                //       style={{
                //         position: "absolute",
                //         top: 8,
                //         right: 3,
                //         fontSize: 9,
                //         background: "red",
                //         color: "white",
                //         borderRadius: "50%",
                //         padding: "1.5px 5px",
                //       }}
                //     >
                //       {receivedUserActionCount > 9
                //         ? "9+"
                //         : receivedUserActionCount}
                //     </div>
                //   ) : (
                //     <></>
                //   )} */}
                // </span>
              }
            >
              <Notification
                role={role}
                notiDropdownStyle={notiDropdownStyle}
                setOpen={setOpen}
                handleOpen={handleOpen}
                currentYear={currentYear}
                _handleYearSelect={_handleYearSelect}
                filteredData={filteredData}
                _handleMonthSelect={_handleMonthSelect}
                open={open}
                theme={theme}
              />
            </DropdownButton>
          ) : (
            ""
          )}

          <DropdownButton
            as={ButtonGroup}
            variant="none"
            title={
              <span style={iconStyle}>
                <i className="bi-person-circle pe-2" />
                <span>
                  {props.userName}
                  <i className="bi-caret-down-fill ps-2" />
                </span>
              </span>
            }
            className="my-dropdown-toggle"
          >
            <div
              style={{
                background: Colors[theme].background,
              }}
            >
              {props.role === "super_admin" &&
                window.location.pathname !== "/company" && (
                  <>
                    <Dropdown.Item
                      style={{ color: invertDark(theme) }}
                      eventKey="1"
                      onClick={_handleCompanies}
                    >
                      {t("Companies")}
                    </Dropdown.Item>
                    {/* <Dropdown.Divider /> */}
                  </>
                )}
              {(props.role === "super_admin" || props.role === "admin") &&
                window.location.pathname !== "/company" &&
                (window.location.pathname === "/lite-dashboard" ? (
                  ""
                ) : (
                  <>
                    <Dropdown.Item
                      style={{ color: invertDark(theme) }}
                      eventKey="2"
                      onClick={_handleAdminPanel}
                      active={
                        window.location.pathname === "/admin_panel" && true
                      }
                    >
                      {t("Admin Panel")}
                    </Dropdown.Item>
                    {/* <Dropdown.Divider /> */}
                  </>
                ))}

              {/* {window.location.pathname !== "/company" && (
                <>
                  <Dropdown.Item
                    style={{ color: invertDark(theme) }}
                    eventKey="3"
                    onClick={() => openModal()}
                    data-bs-toggle="modal"
                    data-bs-target="#passwordresetmodal"
                    active={false}
                  >
                    Password Reset
                  </Dropdown.Item>
                </>
              )} */}

              {role === "driver" ? (
                ""
              ) : window.location.pathname === "/lite-dashboard" ? (
                ""
              ) : window.location.pathname === "/company" ? (
                ""
              ) : (
                <Dropdown.Item
                  style={{ color: invertDark(theme) }}
                  eventKey="4"
                  onClick={() => _handleLogs()}
                >
                  {t("Activity Logs")}
                </Dropdown.Item>
              )}

              {role === "driver" ? (
                ""
              ) : window.location.pathname === "/lite-dashboard" ? (
                ""
              ) : window.location.pathname === "/company" ? (
                ""
              ) : (
              <Dropdown.Item
                      style={{ color: invertDark(theme) }}
                      eventKey="3"
                      onClick={_handleUserGuide}
                    >
                      {t("Video Guide")}
                </Dropdown.Item>
              )}
               {window.location.pathname !== "/company" && (
                <>
                  <Dropdown.Item
                    style={{ color: invertDark(theme) }}
                    eventKey="6"
                    onClick={() => openModal()}
                    data-bs-toggle="modal"
                    data-bs-target="#newModal"
                    active={false}
                  >
                    What's New
                  </Dropdown.Item>
                </>
              )}
              <Dropdown.Item
                style={{ color: invertDark(theme) }}
                eventKey="5"
                onClick={() =>
                  window.open(
                    "https://zfrmz.com/UDrFovbc4R4qxaZsLGKV",
                    "_blank"
                  )
                }
              >
                {t("Customer Support")}
              </Dropdown.Item>

              <Dropdown.Item
                style={{ color: invertDark(theme) }}
                eventKey="6"
                onClick={() => handleLogout()}
              >
                {t("Log Out")}
              </Dropdown.Item>
            </div>
          </DropdownButton>
        </div>
      </Nav>
      {/* <PasswordResetModal
        show={showModal}
        closeModal={() => setShowModal(false)}
        theme={theme}
      /> */}
      <NewsModal
        show={showModal}
        closeModal={() => setShowModal(false)}
        theme={theme}
      />
    </>
  );
};

export default inject("store")(observer(withRouter(Navbar)));
