import plusIcon from '../../Assets/Images/icons/plusIcon.png'
import ascentLogo from '../../Assets/Images/icons/ascent-logo.png'
import { My_Button, My_Input } from '../../Tools'
import { CompanyInfo } from '../../Components/CompanySetup/CompanyInfo'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import { invertDark } from '../../Assets/Config/color.config'
import { useEffect } from 'react'
import { CompanyListTable } from './CompanyListTable'

export const FirstStep = inject("store")(
    observer((props) => {

        const { userData, companyList, fetchCompany } = props.store.landing
                const { role, companyId } = props.store.common
                const {theme} = props.store.login
                

                let companies = []
                companies.push(userData)

                useEffect(() => {
                    fetchCompany(role, companyId)
                }, [])


        return (

            <div className="row">
                <CompanyInfo />
                <div className='col-6'>
                    <CompanyListTable companyList={companyList} theme={theme} />
                </div>
            </div>

        )
    }))