import { Colors } from "../../Assets/Config/color.config";
import { AssetProfileForm } from "./AssetProfileForm";
import AddVehicleIcon from "../../Assets/Images/icons/car1.png";
import { My_Button, My_Input, My_upload_image, Selector } from "../../Tools";
import { useState } from "react";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import { useTranslation } from "react-i18next";

export const ConfirmationModalJQ = inject("store")(
    observer((props) => {
        const {t} = useTranslation("asset_profile_management")
        const { theme } = props;
        const { _handleRemoveDevice, toDelete , deviceList, unitData} = props.store.assetProfile;

        return (
            <div
                className="modal fade"
                id="confirmationModalJQ"
                tabIndex="-1"
                aria-labelledby="confirmationModalJQ"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-md modal-dialog-centered rounded">
                    <div
                        className="modal-content p-3"
                        style={{ background: Colors[theme].background }}
                    >
                        <div className="modal-body text-center" style={{ border: "none" }}>
                            <span style={{ fontSize: 18 }}>
                                <span style={{ color: "yellow" }}>
                                    <i className="bi bi-exclamation-triangle-fill pe-2"></i>
                                </span>
                                <span style={{ color: theme === 'dark' ? 'white' : 'black'}}>{t("Are you sure do you want to delete for the vehicle")}{" "}{toDelete?.license_plate}?</span>
                                
                            </span>
                        </div>
                        <div className="d-flex justify-content-end">
                            <span className="me-2" data-bs-dismiss="modal">
                                <My_Button
                                    customColor="#1976d2"
                                    customHoverColor="#0d6efd"
                                    text={t("Cancel")}
                                />
                            </span>
                            <span data-bs-dismiss="modal">
                                <My_Button
                                    customHoverColor="#c72736"
                                    customColor="#c7273694"
                                    id= "toDelete"
                                    onClick={() => _handleRemoveDevice()}
                                    text={t("Delete")}
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    })
);
