/* eslint-disable react/jsx-pascal-case */
import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { CO2_Loading } from "../../Assets/CO2_Loading";
import { Colors } from "../../Assets/Config/color.config";
import { My_Button, My_Input } from "../../Tools";
import Logo from "../../Assets/Images/CO2_Logo.png";

export const RegisterContainer = inject("store")(

    observer((props) => {
        const {
            username,
            email,
            password,
            confirmPassword,
            passView,
            confirmPassView,
            show,
            inviteLinkInfo,

            _handleUsernameChange,
            _handleEmailChange,
            _handlePasswordChange,
            _handleConfirmPasswordChange,
            _handlePassView,
            _handleConfirmPassView,
            _fetchRegister,
            _fetchInviteLinkInfo,
            _setLinkId
        } = props.store.register;
        const history = useHistory();
        let { id } = useParams();

        // useEffect(() => {
        //     _setLinkId(id);
        //     _fetchInviteLinkInfo();
        // }, [id])
        return (
            <div className="container">

                <div className="row justify-content-center">

                    <form
                        className="col-lg-4 col-md-8 col-sm-10 shadow p-4"
                        style={{
                            position: "absolute",
                            top: "50%",
                            transform: "translateY(-50%)",
                            borderRadius: "15px",
                        }}
                    >

                        <div className="form-group text-center m-0">
                            <img src={Logo} alt="logo" />
                            <p
                                style={{
                                    fontSize: 28,
                                    fontWeight: "lighter",
                                    color: Colors.Dark[4],
                                }}
                            >
                                Register Your Account
                            </p>
                            {/* {inviteLinkInfo ?
                                (inviteLinkInfo.is_registered === 1 ? <>
                                    <p
                                        style={{
                                            fontSize: 18,
                                            fontWeight: "lighter",
                                            color: Colors.Dark[4],
                                        }}
                                    >
                                        Your link is have already registered.
                                    </p>
                                    <p
                                        style={{
                                            fontSize: 18,
                                            fontWeight: "lighter",
                                            color: Colors.Dark[4],
                                        }}
                                    >
                                        Click <a href="https://co2delete.com/">here</a> to Login.
                                    </p>
                                </> : inviteLinkInfo.isExpire === true ?
                                    <><p
                                        style={{
                                            fontSize: 18,
                                            fontWeight: "lighter",
                                            color: Colors.Dark[4],
                                        }}
                                    >
                                        Your link is Expire!.
                                    </p></> :
                                    <> */}
                            <div className="py-3 text-left">
                                <div className="pt-3">
                                    <My_Input
                                        required={true}
                                        id={"username"}
                                        type={"text"}
                                        placeHolder={"Username"}
                                        value={username}
                                        onChange={(e) => { _handleUsernameChange(e.target.value) }}
                                    />
                                </div>
                                <div className="pt-3">
                                    <My_Input
                                        required={true}
                                        id={"email"}
                                        type={"email"}
                                        placeHolder={"E-mail"}
                                        value={email}
                                        onChange={(e) => { _handleEmailChange(e.target.value) }}
                                    />
                                </div>
                                <div className="pt-3">
                                    <My_Input
                                        required={true}
                                        id={"password"}
                                        type={passView ? "text" : "password"}
                                        placeHolder={"Password"}
                                        value={password}
                                        onChange={(e) => { _handlePasswordChange(e.target.value) }}
                                    />

                                    <span
                                        style={{
                                            float: "right",
                                            position: "relative",
                                            marginTop: "-55px",
                                            fontSize: "18px",
                                            marginRight: "20px",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => _handlePassView()}
                                    >
                                        <i
                                            className={`fa fa-eye${passView ? "-slash" : ""
                                                } py-4 text-secondary`}
                                        />
                                    </span>
                                </div>

                                <div className="pt-3">
                                    <My_Input
                                        required={true}
                                        id={"confirmPassword"}
                                        type={confirmPassView ? "text" : "password"}
                                        placeHolder={"Confirm Password"}
                                        value={confirmPassword}
                                        onChange={(e) => { _handleConfirmPasswordChange(e.target.value) }}
                                    />

                                    <span
                                        style={{
                                            float: "right",
                                            position: "relative",
                                            marginTop: "-55px",
                                            fontSize: "18px",
                                            marginRight: "20px",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => _handleConfirmPassView()}
                                    >
                                        <i
                                            className={`fa fa-eye${confirmPassView ? "-slash" : ""
                                                } py-4 text-secondary`}
                                        />
                                    </span>
                                </div>

                            </div>
                            <hr
                                style={{
                                    color: "#dadde1",
                                    backgroundColor: Colors.Dark[3],
                                    height: 0.5,
                                    borderColor: "#dadde1",
                                }}
                            />

                            <My_Button
                                disabled={props.store.login.isDisabled}
                                text={"Register"}
                                type={"button"}
                                id={"register"}
                                onClick={(e) => {
                                    _fetchRegister(history);
                                }}
                                style={{ width: "100%" }}
                                rightIcon={
                                    show ? (
                                        <i className="fas fa-spinner fa-spin ml-2"></i>
                                    ) : (
                                        <i className="fa fa-sign-in-alt pl-2" />
                                    )
                                }
                            />
                            {/* </>) :
                                <>
                                    <CO2_Loading />
                                </>} */}
                            <div className="pt-3 text-center" style={{ color: '#337dff', fontSize: 12, cursor: 'pointer' }} onClick={() => window.location.pathname = '/'}>
                                Already have an account?
                                <span className="text-white ps-2">Log In</span>
                            </div>

                        </div>

                    </form>

                </div>

            </div>
        );
    })
)