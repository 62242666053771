import { Colors, invertDark } from "../Assets/Config/color.config";
import { withRouter } from "react-router";
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
const MenuBar = (props) => {
  const { theme } = props;
  const cookies = new Cookies();
  const companyId = cookies.get("company");

  const selectedStyle = {
    borderBottom: `2px solid ${Colors.lightBlue}`,
    height: "34px",
    // lineHeight: "32px",
    textDecoration: "none",
    fontWeight: "bold",
  };

  const defaultStyle = {
    cursor: "pointer",
    opacity: 0.3,
  };

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });

    return () => {
      window.removeEventListener("resize", () => {
        setWidth(window.innerWidth);
      });
    };
  }, [width]);

  const _handleSideIconClick = (e, id) => {
    e.preventDefault();
    props.history.push("/" + id);
  };



  return (
    <div
      className="d-flex flex-row flex-wrap"
      style={{ color: invertDark(theme) }}
    >
      {companyId === "88883333" ? (sidebarMenuFms.map((v, k) => (
        <div className="px-4 py-2 text-center" key={k} title={v.text}>
          <div
            onClick={(e) => _handleSideIconClick(e, v.id)}
            style={
              window.location.pathname === "/" + v.id
                ? selectedStyle
                : defaultStyle
            }
          >
            {v.text}
          </div>
        </div>
      ))) : (sidebarMenu.map((v, k) => (
        <div className="px-4 py-2 text-center" key={k} title={v.text}>
          <div
            onClick={(e) => _handleSideIconClick(e, v.id)}
            style={
              window.location.pathname === "/" + v.id
                ? selectedStyle
                : defaultStyle
            }
          >
            {v.text}
          </div>
        </div>
      )))}
    </div>
  );
};
export default withRouter(MenuBar);

const sidebarMenuFms = [
  {
    id: "organisation-level-carbon-emission-dashboard",
    text: "Home",
    icon: "fa fa-home",
    link: "/organisation-level-carbon-emission-dashboard"
  },
  {
    id: "new-tracking",
    text: "Tracking",
    icon: "bi bi-pin-map-fill",
    link: "/new-tracking",
  },
  {
    id: "track_and_trace",
    text: "Track and Trace",
    icon: "fa fa-file-contract",
    link: "/track_and_trace",
  },
  {
    id: "asset_profile_management",
    text: "Assets",
    icon: "bi bi-cpu",
    link: "/asset_profile_management",
  },
];

const sidebarMenu = [
  {
    id: "organisation-level-carbon-emission-dashboard",
    text: "Home",
    icon: "fa fa-home",
    link: "/organisation-level-carbon-emission-dashboard",
  },
  {
    id: "new-tracking",
    text: "Tracking",
    icon: "bi bi-pin-map-fill",
    link: "/new-tracking",
  },
  {
    id: "track_and_trace",
    text: "Track and Trace",
    icon: "fa fa-file-contract",
    link: "/track_and_trace",
  },
  {
    id: "asset_profile_management",
    text: "Assets",
    icon: "bi bi-cpu",
    link: "/asset_profile_management",
  },
  {
    id: "manual_entry",
    text: "Manual Entry",
    icon: "fa fa-keyboard",
    link: "/manual_entry",
  },
];
