import { inject, observer } from "mobx-react";
import moment from "moment";
import { toJS } from "mobx";
import { useTranslation } from "react-i18next";

export const TripDetailAndCreateReportBtn = inject("store")(
  observer((props) => {
    const { t } = useTranslation("new-tracking");
    const { cardStyle1, theme } = props;
    const { role } = props.store.common;
    const {
      trackingDetail,
      trackingDetailVTwo,
      selectedDetail,
      handleSelecteDetail,
      showView,
      showChartView,
      showChartViewVTwo,
      assetProfile
    } = props.store.trackingVTwo;
    const {historicalStatus} = props.store.trackAndTrace;

    const rowStyle = {
      marginRight: 5,
      minHeight: 45,
      borderBottom: `1px solid rgba(100,100,100,0.2)`,
    };

    const openNewTrackingFileExportModal = () => {
      let myModal = new window.bootstrap.Modal(
        document.getElementById("newtrackingPdfModal")
      );
      myModal.show();
      console.log(myModal.show(), "mymodal===>");
    };

    return (
      <div className="">
        <div className="d-flex flex-row align-items-center justify-content-between">
          <h5 className="mb-3">{t("Trip Details")}</h5>
          {/* <span
                        className=""
                        data-bs-toggle="modal"
                        data-bs-target="#newtrackingPdfModal"
                        data-toggle="modal"
                        data-target=".bd-example-modal-lg"
                    > */}
          {role === "user" ? (
            ""
          ) : (
            <button
              type="button"
              className={`${
                theme === "dark"
                  ? "btn  btn-outline-info btn-sm m-2 mb-3"
                  : "btn btn-outline-success btn-sm m-2 mb-3"
              }`}
              style={{
                fontSize: 12,
              }}
              onClick={() => {
                console.log("Button clicked");
                openNewTrackingFileExportModal();
              }}
            >
              {t("Create Report")}
            </button>
          )}

          {/* </span> */}
        </div>

        <div
          className="d-flex justify-content-between align-items-center px-2 mx-0"
          style={rowStyle}
        >
          <span style={{ textAlign: "left" }}>{t("Unit Owner")}</span>
          <span style={{ textAlign: "right" }}>
            {selectedDetail?.unitOwner || "N/A"}
          </span>
        </div>

        <div
          className="d-flex justify-content-between align-items-center px-2 mx-0"
          style={rowStyle}
        >
          <span style={{ textAlign: "left" }}>{t("Driver")}</span>
          <span style={{ textAlign: "right" }}>
            {selectedDetail?.driver || "N/A"}
          </span>
        </div>

        <div
          className="d-flex justify-content-between align-items-center px-2 mx-0"
          style={rowStyle}
        >
          <span style={{ textAlign: "left" }}>{t("Start Time")}</span>
          <span style={{ textAlign: "right" }}>
            {selectedDetail
              ? moment(selectedDetail?.start_time).format("HH:mm:ss")
              : "N/A"}
          </span>
        </div>

        <div
          className="d-flex justify-content-between align-items-center px-2 mx-0"
          style={rowStyle}
        >
          <span style={{ textAlign: "left" }}>{t("End Time")}</span>
          <span style={{ textAlign: "right" }}>
            {selectedDetail
              ? moment(selectedDetail?.end_time).format("HH:mm:ss")
              : "N/A"}
          </span>
        </div>

        <div
          className="d-flex justify-content-between align-items-center px-2 mx-0"
          style={rowStyle}
        >
          <span style={{ textAlign: "left" }}>{t("Duration (seconds)")}</span>
          <span style={{ textAlign: "right" }}>
            {selectedDetail?.duration || "N/A"}
          </span>
        </div>

        {/* <div
          className="d-flex justify-content-between align-items-center px-2 mx-0"
          style={rowStyle}
        >
          <span style={{ textAlign: "left" }}>
            {selectedDetail?.type === "battery"
              ? t("Initial Battery Level") + " (%)"
              : t("Initial Fuel Level") + " (L)"}
          </span>
          <span style={{ textAlign: "right" }}>
            {selectedDetail?.initial || "N/A"}
          </span>
        </div> */}

        {/* <div
          className="d-flex justify-content-between align-items-center px-2 mx-0"
          style={rowStyle}
        >
          <span style={{ textAlign: "left" }}>
            {selectedDetail?.type === "battery"
              ? t("Final Battery Level") + " (%)"
              : t("Final Fuel Level") + " (L)"}
          </span>
          <span style={{ textAlign: "right" }}>
            {selectedDetail?.final || "N/A"}
          </span>
        </div> */}
{/* 
        <div
          className="d-flex justify-content-between align-items-center px-2 mx-0"
          style={rowStyle}
        >
          <span style={{ textAlign: "left" }}>
            {selectedDetail?.type === "battery"
              ? t("Initial Battery Consumed") + " (kWh)"
              : t("Initial Fuel Consumed") + " (L)"}
          </span>
          <span style={{ textAlign: "right" }}>
            {selectedDetail?.initial_consumed || "N/A"}
          </span>
        </div> */}

        {/* <div
          className="d-flex justify-content-between align-items-center px-2 mx-0"
          style={rowStyle}
        >
          <span style={{ textAlign: "left" }}>
            {selectedDetail?.type === "battery"
              ? t("Final Battery Consumed") + " (kWh)"
              : t("Final Fuel Consumed") + " (L)"}
          </span>
          <span style={{ textAlign: "right" }}>
            {selectedDetail?.final_consumed || "N/A"}
          </span>
        </div> */}

        <div
          className="d-flex justify-content-between align-items-center px-2 mx-0"
          style={rowStyle}
        >
          <span style={{ textAlign: "left" }}>{t("Average Speed")}(km/h)</span>
          <span style={{ textAlign: "right" }}>
            {selectedDetail?.avg_speed || "N/A"}
          </span>
        </div>

        <div
          className="d-flex justify-content-between align-items-center px-2 mx-0"
          style={rowStyle}
        >
          <span style={{ textAlign: "left" }}>{t("Maximum Speed")}(km/h)</span>
          <span style={{ textAlign: "right" }}>
            {selectedDetail?.max_speed || "N/A"}
          </span>
        </div>

        <div
          className="d-flex justify-content-between align-items-center px-2 mx-0"
          style={rowStyle}
        >
          <span style={{ textAlign: "left" }}>
            {selectedDetail?.type === "battery"
              ? t("Battery Consumption") + " (kWh)"
              : t("Fuel Consumption") + " (L)"}
          </span>
          <span style={{ textAlign: "right" }}>
            {Number(selectedDetail?.total_consumed).toFixed(3) || "0.000"}
          </span>
        </div>

        {/* <div
          className="d-flex justify-content-between align-items-center px-2 mx-0"
          style={rowStyle}
        >
          <span style={{ textAlign: "left" }}>Emission Factor (gCO₂/km)</span> 
          <span style={{ textAlign: "left" }}> 
            // {t("OEM Baseline Emission Factor")} (gCO₂e/km){" "} 
             {t("Baseline Emission Factor")} (gCO₂e/km){" "} 
            <i
              className="fas fa-info-circle"
              title="For the first [year] of data collection, the baseline emission factor would be based on the OEM’s technical specification of the vehicle, expressed in gCO2e/km."
              style={{ cursor: "pointer" }}
            /> 
          </span>
          <span style={{ textAlign: "right" }}>
             {selectedDetail?.manual_baseline || "N/A"} 
          </span>
        </div>   */}
            <div
              className="justify-content-between align-items-center px-2 mx-0"
              style={rowStyle}
            >
            <div className="d-flex justify-content-between">
              <span style={{ textAlign: "left" }}>
                {/* {t("Historical Baseline Emission Factor")} (gCO₂e/km){" "} */}
                {t("Baseline Emission Intensity")} ({assetProfile?.emission_type_unit === "gCO2e/km" ? "gCO₂e/km" : "gCO₂e/h"}){" "}              
              </span>
              
              <span style={{ textAlign: "right" }}>
                {selectedDetail?.baseline_emission
                 || "0.00"
                 }
              </span>
              </div>
              <div>
              <span style={{fontSize:'0.98rem'}}>
                ({t("Baseline Type")} - {selectedDetail?.baseline_emission_type})
              </span>
              </div>

            </div>
        
      </div>
    );
  })
);
