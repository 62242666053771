import { Colors, invertDark } from "../../Assets/Config/color.config";
import { AssetProfileForm } from "./AssetProfileForm";
import AddVehicleIcon from "../../Assets/Images/icons/car1.png";
import EditVehicleIcon from "../../Assets/Images/icons/editVehicle.png";
import { My_Button, My_Input, My_upload_image, Selector } from "../../Tools";
import { useState } from "react";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import { useTranslation } from "react-i18next";

export const AddVehicleMinMaxModal = inject("store")(
    observer((props) => {
        const {t} = useTranslation("asset_profile_management")
        const { theme, currentAssets} = props;
        const { deviceMinMaxData, 
                _handleMinMaxChange, 
                _handleAddNewMinMaxDevice, 
                validateError, 
                disableAddMinMaxVeicle, 
                editMinMaxMode , 
                _handleCloseModel} = props.store.assetProfile
        const [vehicleType, setVehicleType] = useState(vehicleTypeOption[0].value)
        const { role } = props.store.common

        return (
            <div
                className="modal fade show"
                id="addVehicleMinMaxModal"
                tabIndex="-1"
                aria-labelledby="addVehicleMinMaxModal"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xl rounded">
                    <div
                        className="modal-content"
                        style={{ background: Colors[theme].background, color: invertDark(theme) }}
                    >
                        <div className="modal-header" style={{ border: "none" }}>
                            {editMinMaxMode ? <span style={{ fontSize: 24 }}>
                                <img height="30px" src={EditVehicleIcon} /> {t("Edit Vehicle")}
                            </span>
                                : <span style={{ fontSize: 24 }}>
                                    <img height="30px" src={AddVehicleIcon} /> {currentAssets === "equipment" ? t("Add New Equipment") : currentAssets === "infrastructure"? t("Add New Infrastructure") : t("Add New Vehicle") }
                                </span>}
                            <span data-bs-dismiss="modal"  onClick={()=> _handleCloseModel()} style={{ cursor: 'pointer' }}>
                                <i className="bi bi-x-lg" />
                            </span>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={(e) => _handleAddNewMinMaxDevice(e, props)} className="row" style={{ fontSize: 14 }}>
                                <div className="col-4 col-sm-12 col-lg-4 col-md-4 col-xl-4 col-xs-4">
                                    
                                    {deviceMinMaxData.fuel_type !== 'Battery' ?
                                        <><div className="pb-3">

                                            <div className="mb-1">{t("fuel consumed")} {t("(Min)")}</div>
                                            <My_Input
                                                type='number'
                                                height='38px'
                                                required={false}
                                                id={"fuel_consumed_min"}
                                                placeHolder={"fuel consumed (Min)"}
                                                value={deviceMinMaxData?.fuel_consumed_min}
                                                onChange={(e) => _handleMinMaxChange(e, 'fuel_consumed_min')}
                                            />

                                        </div>
                                            <div className="pb-3">

                                                <div className="mb-1">{t("fuel consumed")} {t("(Max)")}</div>
                                                <My_Input
                                                    type='number'
                                                    height='38px'
                                                    required={false}
                                                    id={"fuel_consumed_max"}
                                                    placeHolder={"fuel consumed (Max)"}
                                                    value={deviceMinMaxData.fuel_consumed_max}
                                                    onChange={(e) => _handleMinMaxChange(e, 'fuel_consumed_max')}
                                                />
                                            </div></>
                                        :
                                        <><div className="pb-3">

                                            <div className="mb-1">{t("battery consumed")} {t("(Min)")}</div>
                                            <My_Input
                                                type='number'
                                                height='38px'
                                                required={false}
                                                id={"battery_consumed_min"}
                                                placeHolder={"battery consumed (Min)"}
                                                value={deviceMinMaxData.battery_consumed_min}
                                                onChange={(e) => _handleMinMaxChange(e, 'battery_consumed_min')}
                                            />
                                        </div>

                                            <div className="pb-3">

                                                <div className="mb-1">{t("battery consumed")} {t("(Max)")}</div>
                                                <My_Input
                                                    type='number'
                                                    height='38px'
                                                    required={false}
                                                    id={"battery_consumed_max"}
                                                    placeHolder={"battery consumed (Max)"}
                                                    value={deviceMinMaxData.battery_consumed_max}
                                                    onChange={(e) => _handleMinMaxChange(e, 'battery_consumed_max')}
                                                />

                                            </div></>
                                    }
                                    <div className="pb-3">

                                        <div className="mb-1">{t("mileage")} {t("(Min)")}</div>
                                        <My_Input
                                            type='number'
                                            height='38px'
                                            required={false}
                                            id={"mileage_min"}
                                            placeHolder={"mileage (Min)"}
                                            value={deviceMinMaxData.mileage_min}
                                            onChange={(e) => _handleMinMaxChange(e, 'mileage_min')}
                                        />

                                    </div>

                                    <div className="pb-3">

                                        <div className="mb-1">{t("mileage")} {t("(Max)")}</div>
                                        <My_Input
                                            type='number'
                                            height='38px'
                                            required={false}
                                            id={"mileage_max"}
                                            placeHolder={"mileage (Max)"}
                                            value={deviceMinMaxData?.mileage_max}
                                            onChange={(e) => _handleMinMaxChange(e, 'mileage_max')}
                                        />

                                    </div>
                                </div>

                                {/* <div className="col-4 col-sm-12 col-lg-4 col-md-4 col-xl-4 col-xs-4">

                                    

                                    <div className="pb-3">

                                        <div className="mb-1">{t("speed")} {t("(Min)")}</div>
                                        <My_Input
                                            type='number'
                                            height='38px'
                                            required={false}
                                            id={"speed_min"}
                                            placeHolder={"speed (Min)"}
                                            value={deviceMinMaxData.speed_min}
                                            onChange={(e) => _handleMinMaxChange(e, 'speed_min')}
                                        />
                                    </div>

                                    <div className="pb-3">

                                        <div className="mb-1">{t("speed")} {t("(Max)")}</div>
                                        <My_Input
                                            type='number'
                                            height='38px'
                                            required={false}
                                            id={"speed_max"}
                                            placeHolder={"speed (Max)"}
                                            value={deviceMinMaxData.speed_max}
                                            onChange={(e) => _handleMinMaxChange(e, 'speed_max')}
                                        />
                                    </div>

                                </div> */}

                                <div className="text-center">
                                    <span style={{ cursor: 'pointer' }}>
                                        <My_Button
                                            disabled={role === "viewer" ? disableAddMinMaxVeicle : "" }
                                            type="submit"
                                            customColor= {theme === 'dark' ? "#76A7DC" : "green"}
                                            className={`${ theme === 'dark' ? 'text-black' : 'text-white'}`}
                                            text={editMinMaxMode ? t("Update") : t("Add")}
                                            // data-bs-dismiss="modal"
                                            style={{ width: "30%" }}
                                           
                                        />
                                    </span>

                                </div> 
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }));

const vehicleTypeOption = [
    { value: 'Battery', label: 'Battery' },
    { value: 'Diesel', label: 'Diesel' },
    { value: 'Petrol', label: 'Petrol' },
]

const assetTypeOption = [
    { value: 'Vehicle', label: 'Vehicle' },
    { value: 'Equipment', label: 'Equipment' }
]

const UploadImage = inject("store")(
    observer((props) => {
        const {t} = useTranslation("asset_profile_management")
        const theme = props.theme;
        const { _handleTextChange, deviceMinMaxData, deviceImage,_handleUploadImage } = props.store.assetProfile;

        
        
        return (
            <div
                className="d-flex flex-column justify-content-center align-items-center"
                style={{ background: "rgb(0,0,0,0.2)" }}
            >
                <label
                    style={{
                        width: "100%",
                        borderRadius: 5,
                        cursor: "pointer",
                    }}
                    htmlFor="image-upload"
                >
                    <input
                        type="file"
                        style={{ display: "none" }}
                        id="image-upload"
                        accept="image/x-png,image/gif,image/jpeg"
                        onChange={(e) => _handleUploadImage(e)}
                    />
                    {!deviceImage ? (
                        <span className="d-flex flex-column justify-content-center align-items-center h-100 p-0 m-3">
                            <i
                                style={{ fontSize: 30 }}
                                className="bi bi-cloud-arrow-up-fill p-5"
                            ></i>
                            <div>{t("Add an Image")}</div>
                        </span>
                    ) : (
                        <span className="d-flex justify-content-center m-3">
                            <img src={deviceImage} height={'84px'} />
                        </span>
                    )}
                </label>
            </div>
        );
    }));
