import React, { useState } from "react";
import { useEffect, useRef } from "react";
import { inject, observer } from "mobx-react";
import "datatables.net-dt";
import "datatables.net-dt/css/jquery.dataTables.css";
import $ from "jquery";
import { action, toJS } from "mobx";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { CO2_Loading } from "../../Assets/CO2_Loading";

const LogsTable = inject("store")(
  observer((props) => {
    const { t } = useTranslation("admin-panel");
    const { theme } = props;

    const { logsData, loading, startDate, endDate, today } =
      props.store.adminPanel;

    const tableRef = useRef(null);

    let table = null;
    const [filterData, setFilterData] = useState([]);

    // console.log('date ====_>', startDate, endDate)

    const filteredLogs = logsData.filter((data) => {
      var dataTime = moment(data.time).format("YYYY-MM-DD HH:mm:ss");
      return (
        moment(dataTime).isSameOrAfter(
          moment(startDate).add(0, "day").format("YYYY-MM-DD HH:mm:ss")
        ) &&
        moment(dataTime).isSameOrBefore(
          moment(endDate).add(+1, "day").format("YYYY-MM-DD HH:mm:ss")
        )
      );
    });

    console.log(filteredLogs, "filteredlogoflogtablelr====>");

    // const _handleFilter = () => {
    //   const filteredLogs = logsData.filter(data => {
    //     var dataTime = moment(data.time).format('YYYY-MM-DD HH:mm:ss');
    //     return moment(dataTime).isSameOrAfter(moment(startDate).add(0, 'day').format('YYYY-MM-DD HH:mm:ss')) && moment(dataTime).isSameOrBefore(moment(endDate).add(+1, 'day').format('YYYY-MM-DD HH:mm:ss'))
    //   });
    //   setFilterData(filteredLogs)
    // }

    useEffect(() => {
      // _handleFilter();
      const styleElement = document.createElement(`style`);
      styleElement.innerHTML =
        theme === "dark"
          ? `
          .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
              color: black !important;
              border: 1px solid #E5E5E5!important;
              background-color: #E5E5E5 !important;
              /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #E5E5E5), color-stop(100%, #E5E5E5))!important; */
              background: -webkit-linear-gradient(top, #E5E5E5 0%, #E5E5E5 100%)!important;
              background: -moz-linear-gradient(top, #E5E5E5 0%, #E5E5E5 100%)!important;
              background: -ms-linear-gradient(top, #E5E5E5 0%, #E5E5E5 100%) !important;
              background: -o-linear-gradient(top, #E5E5E5 0%, #E5E5E5 100%)!important;
              background: linear-gradient(to bottom, #E5E5E5 0%, #E5E5E5 100%)!important;
            }
            .dataTables_wrapper .dataTables_paginate .paginate_button.current {
              color: black !important;
              border: 1px solid #C6C6C6!important;
              background-color: #C6C6C6 !important;
              /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FFFCFC), color-stop(100%, #969292))!important; */
              background: -webkit-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
              background: -moz-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
              background: -ms-linear-gradient(top, #FFFCFC 0%, #969292 100%) !important;
              background: -o-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
              background: linear-gradient(to bottom, #FFFCFC 0%, #969292 100%)!important;
            }
            .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
              color: black !important;
              border: 1px solid #C6C6C6!important;
              background-color: #C6C6C6 !important;
              /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FFFCFC), color-stop(100%, #969292))!important; */
              background: -webkit-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
              background: -moz-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
              background: -ms-linear-gradient(top, #FFFCFC 0%, #969292 100%) !important;
              background: -o-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
              background: linear-gradient(to bottom, #FFFCFC 0%, #969292 100%)!important;
            }

            .dataTables_wrapper .dataTables_paginate .paginate_button {
              color: white !important; 
            }

            .dataTables_wrapper .dataTables_paginate .ellipsis {
              color: white !important; 
            }

            .dataTables_wrapper .dataTables_length{
              color: white !important;
            }

            .dataTables_wrapper .dataTables_filter {
              color: white !important;
            }

            .dataTables_wrapper .dataTables_info {
              color: white !important;
            }
          `
          : `
              .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
                  color: black !important;
                  border: 1px solid #E5E5E5!important;
                  background-color: #E5E5E5 !important;
                  /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #E5E5E5), color-stop(100%, #E5E5E5))!important; */
                  background: -webkit-linear-gradient(top, #E5E5E5 0%, #E5E5E5 100%)!important;
                  background: -moz-linear-gradient(top, #E5E5E5 0%, #E5E5E5 100%)!important;
                  background: -ms-linear-gradient(top, #E5E5E5 0%, #E5E5E5 100%) !important;
                  background: -o-linear-gradient(top, #E5E5E5 0%, #E5E5E5 100%)!important;
                  background: linear-gradient(to bottom, #E5E5E5 0%, #E5E5E5 100%)!important;
                }
                .dataTables_wrapper .dataTables_paginate .paginate_button.current {
                  color: black !important;
                  border: 1px solid #C6C6C6!important;
                  background-color: #C6C6C6 !important;
                  /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FFFCFC), color-stop(100%, #969292))!important; */
                  background: -webkit-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
                  background: -moz-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
                  background: -ms-linear-gradient(top, #FFFCFC 0%, #969292 100%) !important;
                  background: -o-linear-gradient(top, #FFFCFC 0%, #969292 100%)!important;
                  background: linear-gradient(to bottom, #FFFCFC 0%, #969292 100%)!important;
                }

                .dataTables_wrapper .dataTables_paginate .paginate_button {
                  color: black !important; 
                }  

                .dataTables_wrapper .dataTables_paginate .ellipsis {
                  color: black !important; 
                }

                .dataTables_wrapper .dataTables_length{
                  color: black !important;
                }

                .dataTables_wrapper .dataTables_filter {
                  color: black !important;
                }

                .dataTables_wrapper .dataTables_info {
                  color: black !important;
                }
          `;
      document.head.appendChild(styleElement);

      // const updatedLengthMenu = logsData?.length > 15 ? [15, 20, 25] : [8, 10, 15];
      const tableElement = $(tableRef.current);

      if ($.fn.DataTable.isDataTable(tableElement)) {
        tableElement.DataTable().destroy();
      }

      table = tableElement.DataTable({
        retrieve: true,
        scrollX: true,
        // lengthMenu: updatedLengthMenu,
        order: [[0, "desc"]],
        // data: logsData,
        data: filteredLogs || [],
        pageLength: filteredLogs?.length > 15 ? 15 : 8,
        columns: [
          {
            title: t("Time"),
            data: "time",
            render: (data) => moment(data).format("YYYY-MM-DD HH:mm:ss"),
          },
          { title: t("Username"), data: "name" },
          {
            title: t("Action"),
            data: "action",
            render: (data) => data.replace(/_/g, " "),
          },
          { title: t("Action Detail"), data: "action_detail" },
        ],
        columnDefs: [
          {
            target: [0],
            width: "15%",
          },
          {
            target: [1],
            width: "15%",
          },
          {
            target: [2],
            width: "15%",
          },
          {
            target: [2],
            width: "55%",
          },
        ],
      });

      const searchInput = $(".dataTables_filter input");
      searchInput.css("color", theme === "light" ? "black" : "white");

      const entriesSelect = $(".dataTables_length select");
      entriesSelect.css(
        "background-color",
        theme === "light" ? "" : "rgb(32, 43, 96)"
      );
      entriesSelect.css("color", theme === "light" ? "black" : "white");

      let tableRow = $("table.dataTable tbody");
      tableRow.css("cursor", "pointer");
    }, [theme, logsData, t, filteredLogs]);

    useEffect(() => {
      if (table) {
        table.clear();

        var newHeaderNames = table
          .columns()
          .header()
          .toArray()
          .map(function (header) {
            return t($(header).text());
          });

        const updatedLengthMenu =
          filteredLogs?.length > 15 ? [15, 20, 25] : [8, 10, 15];

        $("div.dataTables_length select option").remove();

        updatedLengthMenu.forEach((optionValue) => {
          $("div.dataTables_length select").append(
            `<option value="${optionValue}">${optionValue}</option>`
          );
        });

        table.draw();

        table.rows.add(filteredLogs || []).draw();

        table.cells().every(function () {
          $(this.node()).css("border-color", "rgb(211,211,211, 0.3)");
        });
      }
    }, [theme, logsData, t, filteredLogs]);

    return (
      <>
        {loading? (
          <CO2_Loading />
        ) : (
          <div>
          <table
            ref={tableRef}
            className="display cell-border hover stripe"
            style={{
              width: "100%",
              color: theme === "dark" ? "white" : "black",
            }}
          ></table>
          </div>
        )}
      </>
    );
  })
);

export default LogsTable;
