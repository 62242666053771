import { useTranslation } from "react-i18next";
import { Colors } from "../../Assets/Config/color.config";
import { My_Input, My_Button, Selector } from "../../Tools";

export const AssetProfileForm = (props) => {
  const {
    theme,
    deviceData,
    typeOptions,
    _handleSelectType,
    tableIndex,
    _handleTextChange,
    fetchUpdateDevice,
  } = props;
  const {t} = useTranslation("asset_profile_management")

  return (
    <>
      <div className="d-flex flex-row flex-wrap align-items-end justify-content-between">
        <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 mx-3 ">
          <label
            style={{
              fontWeight: "bold",
              color: Colors.Dark[theme === "dark" ? 4 : 1],
            }}
          >
            {t("Asset Name")}
          </label>
          <My_Input
            required={true}
            id={"device"}
            placeHolder={"Asset Name"}
            value={deviceData.device}
            onChange={(e) => _handleTextChange(tableIndex, "device", "", e)}
          />
        </div>
        <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 mx-3">
          <Selector
            label={"Type"}
            options={typeOptions}
            defaultValue={
              deviceData.typeId
                ? typeOptions.filter((v) => v.value === deviceData.typeId)[0]
                : null
            }
            _handleSelect={(e) => {
              _handleSelectType(e, tableIndex);
            }}
          />
        </div>

        <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 mx-3">
          <label
            style={{
              fontWeight: "bold",
              fontSize: "12px",
              color: Colors.Dark[theme === "dark" ? 4 : 1],
            }}
          >
            {t("Brand")}
          </label>
          <My_Input
            required={true}
            id={"brand"}
            placeHolder={"Brand"}
            value={deviceData.brand}
            onChange={(e) => _handleTextChange(tableIndex, "brand", "", e)}
          />
        </div>
        {/* </div> */}

        <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 mx-3">
          <label
            style={{
              fontWeight: "bold",
              color: Colors.Dark[theme === "dark" ? 4 : 1],
            }}
          >
            {t("Model")}
          </label>
          <My_Input
            required={true}
            id={"model"}
            placeHolder={"Model"}
            value={deviceData.model}
            onChange={(e) => _handleTextChange(tableIndex, "model", "", e)}
          />
        </div>

        {deviceData.info &&
          Object.entries(deviceData.info).map(([key, value]) => {
            return (
              <div
                className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 mx-3"
                key={key}
              >
                <label
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: Colors.Dark[theme === "dark" ? 4 : 1],
                  }}
                >
                  {t(key)}
                </label>
                <My_Input
                  required={true}
                  id={key}
                  placeHolder={"Please set value"}
                  value={value}
                  onChange={(e) =>
                    _handleTextChange(tableIndex, "info", key, e)
                  }
                />
              </div>
            );
          })}

        <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 mx-3">
          <My_Button
            text={t("SAVE")}
            type={"submit"}
            id={"save"}
            onClick={(e) => fetchUpdateDevice(tableIndex)}
            style={{ width: "100%" }}
            rightIcon={<i className="fa fa-save ml-2"></i>}
          />
        </div>
      </div>
    </>
  );
};
