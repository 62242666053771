import { inject, observer } from "mobx-react"
import { Colors } from "../../Assets/Config/color.config";
import ReactDOM from "react-dom";
import React, { useState } from "react";

export const CustomerSupport = inject("store")(
    observer((props) => {

        return (
            <div id='zohoSupportWebToCase' align='center'>
                <form name='zsWebToCase_765725000000664003' style={{ height: '800px' }} id='zsWebToCase_765725000000664003'
                    action='https://desk.zoho.com/support/WebToCase' method='POST'
                    // onSubmit={() => {return zsValidateMandatoryFields()}} 
                    encType='multipart/form-data'
                >
                    <input type='hidden' name='xnQsjsdp' value='edbsn0b0ab6bb441aa8248a8e79a3c1db2b74' />
                    <input type='hidden' name='xmIwtLD' value='edbsnf4a1fdc0f4ce106dcd14191a609a786dccbcb942354769f501c3555bf976c631' />
                    <input type='hidden' name='xJdfEaS' value='' />
                    <input type='hidden' name='actionType' value='Q2FzZXM=' />
                    <input type="hidden" id="property(module)" value="Cases" />
                    <input type="hidden" id="dependent_field_values_Cases" value="&#x7b;&quot;JSON_VALUES&quot;&#x3a;&#x7b;&#x7d;,&quot;JSON_SELECT_VALUES&quot;&#x3a;&#x7b;&#x7d;,&quot;JSON_MAP_DEP_LABELS&quot;&#x3a;&#x5b;&#x5d;&#x7d;" />
                    <input type='hidden' name='returnURL' value='&#x2a;' />

                    <table border='0' cellSpacing='0' style={{ borderRadius: '15px'}} className="zsFormClass">
                        <tr>
                            <td colspan='2' className="zsFontClass"><strong style={{ fontSize: '18px'}}>Web Form</strong></td>
                        </tr><br />
                        <tr>
                            <td nowrap className="zsFontClass" width='25%' align='left'>First Name&nbsp;&nbsp;</td>
                            <td align='left' width='75%'>
                                <input type='text' maxLength='120' name='First Name' value='' className="manfieldbdr" />
                            </td>
                        </tr>
                        <tr>
                            <td noWrap className="zsFontClass" width='25%' align='left'>Last Name&nbsp;&nbsp;</td>
                            <td align='left' width='75%'>
                                <input type='text' maxlength='120' name='Contact Name' className="manfieldbdr" />
                            </td>
                        </tr>
                        <tr>
                            <td noWrap className="zsFontClass" width='25%' align='left'>Email&nbsp;&nbsp;</td>
                            <td align='left' width='75%'>
                                <input type='text' maxlength='120' name='Email' value='' className="manfieldbdr" />
                            </td>
                        </tr>
                        <tr>
                            <td noWrap className="zsFontClass" width='25%' align='left'>Office&#x2f;Mobile Phone Number&nbsp;&nbsp;
                            </td>
                            <td align='left' width='75%'>
                                <input type='text' maxlength='120' name='Phone' value='' className="manfieldbdr" />
                            </td>
                        </tr>
                        <tr>
                            <td noWrap className="zsFontClass" width='25%' align='left'>Classifications &nbsp;&nbsp;</td>
                            <td align='left' width='75%'>
                                <select
                                    name='Classification' value=''
                                    // onChange={setDependent(this, false)}
                                    id='Classification'
                                >
                                    <option value=''>-None-</option>
                                    <option value='Question'>Question</option>
                                    <option value='Problem'>Problem</option>
                                    <option value='Feature'>Feature</option>
                                    <option value='Others'>Others</option>
                                </select>
                            </td>
                        </tr>

                        <tr>
                            <td noWrap className="zsFontClass" width='25%' align='left'>Language &nbsp;&nbsp;</td>
                            <td align='left' width='75%'>
                                <select name='Language' value='' 
                                    // onChange={setDependent(this, false)}
                                    id='Language'>
                                    <option value=''>-None-</option>
                                    <option value='Abkhazian'>Abkhazian</option>
                                    <option value='Afar'>Afar</option>
                                    <option value='Afrikaans'>Afrikaans</option>
                                    <option value='Akan'>Akan</option>
                                    <option value='Albanian'>Albanian</option>
                                    <option value='Amharic'>Amharic</option>
                                    <option value='Arabic'>Arabic</option>
                                    <option value='Aragonese'>Aragonese</option>
                                    <option value='Armenian'>Armenian</option>
                                    <option value='Assamese'>Assamese</option>
                                    <option value='Asturian'>Asturian</option>
                                    <option value='Avaric'>Avaric</option>
                                    <option value='Avestan'>Avestan</option>
                                    <option value='Aymara'>Aymara</option>
                                    <option value='Azerbaijani'>Azerbaijani</option>
                                    <option value='Bambara'>Bambara</option>
                                    <option value='Bashkir'>Bashkir</option>
                                    <option value='Basque'>Basque</option>
                                    <option value='Belarusian'>Belarusian</option>
                                    <option value='Bengali'>Bengali</option>
                                    <option value='Bihari'>Bihari</option>
                                    <option value='Bislama'>Bislama</option>
                                    <option value='Bosnian'>Bosnian</option>
                                    <option value='Breton'>Breton</option>
                                    <option value='Bulgarian'>Bulgarian</option>
                                    <option value='Burmese'>Burmese</option>
                                    <option value='Catalan'>Catalan</option>
                                    <option value='Cebuano'>Cebuano</option>
                                    <option value='Chamorro'>Chamorro</option>
                                    <option value='Chechen'>Chechen</option>
                                    <option value='Chichewa'>Chichewa</option>
                                    <option value='Chinese (Simplified)'>Chinese (Simplified)</option>
                                    <option value='Chinese (Traditional)'>Chinese (Traditional)</option>
                                    <option value='Church Slavic'>Church Slavic</option>
                                    <option value='Chuvash'>Chuvash</option>
                                    <option value='Cornish'>Cornish</option>
                                    <option value='Corsican'>Corsican</option>
                                    <option value='Cree'>Cree</option>
                                    <option value='Croatian'>Croatian</option>
                                    <option value='Czech'>Czech</option>
                                    <option value='Danish'>Danish</option>
                                    <option value='Divehi'>Divehi</option>
                                    <option value='Dutch'>Dutch</option>
                                    <option value='Dzongkha'>Dzongkha</option>
                                    <option value='English'>English</option>
                                    <option value='Esperanto'>Esperanto</option>
                                    <option value='Estonian'>Estonian</option>
                                    <option value='Ewe'>Ewe</option>
                                    <option value='Faroese'>Faroese</option>
                                    <option value='Fijian'>Fijian</option>
                                    <option value='Filipino'>Filipino</option>
                                    <option value='Finnish'>Finnish</option>
                                    <option value='French'>French</option>
                                    <option value='Fulah'>Fulah</option>
                                    <option value='Gaelic'>Gaelic</option>
                                    <option value='Galician'>Galician</option>
                                    <option value='Ganda'>Ganda</option>
                                    <option value='Georgian'>Georgian</option>
                                    <option value='German'>German</option>
                                    <option value='Greek'>Greek</option>
                                    <option value='Guarani'>Guarani</option>
                                    <option value='Gujarati'>Gujarati</option>
                                    <option value='Haitian'>Haitian</option>
                                    <option value='Hausa'>Hausa</option>
                                    <option value='Hawaiian'>Hawaiian</option>
                                    <option value='Hebrew'>Hebrew</option>
                                    <option value='Herero'>Herero</option>
                                    <option value='Hindi'>Hindi</option>
                                    <option value='Hiri Motu'>Hiri Motu</option>
                                    <option value='Hmong'>Hmong</option>
                                    <option value='Hungarian'>Hungarian</option>
                                    <option value='Icelandic'>Icelandic</option>
                                    <option value='Ido'>Ido</option>
                                    <option value='Igbo'>Igbo</option>
                                    <option value='Indonesian'>Indonesian</option>
                                    <option value='Interlingua'>Interlingua</option>
                                    <option value='Interlingue'>Interlingue</option>
                                    <option value='Inuktitut'>Inuktitut</option>
                                    <option value='Inupiaq'>Inupiaq</option>
                                    <option value='Irish'>Irish</option>
                                    <option value='Italian'>Italian</option>
                                    <option value='Japanese'>Japanese</option>
                                    <option value='Javanese'>Javanese</option>
                                    <option value='Kalaallisut'>Kalaallisut</option>
                                    <option value='Kannada'>Kannada</option>
                                    <option value='Kanuri'>Kanuri</option>
                                    <option value='Kashmiri'>Kashmiri</option>
                                    <option value='Kazakh'>Kazakh</option>
                                    <option value='Khmer'>Khmer</option>
                                    <option value='Kikuyu'>Kikuyu</option>
                                    <option value='Kinyarwanda'>Kinyarwanda</option>
                                    <option value='Kirghiz'>Kirghiz</option>
                                    <option value='Komi'>Komi</option>
                                    <option value='Kongo'>Kongo</option>
                                    <option value='Korean'>Korean</option>
                                    <option value='Kuanyama'>Kuanyama</option>
                                    <option value='Kurdish'>Kurdish</option>
                                    <option value='Lao'>Lao</option>
                                    <option value='Latin'>Latin</option>
                                    <option value='Latvian'>Latvian</option>
                                    <option value='Limburgan'>Limburgan</option>
                                    <option value='Lingala'>Lingala</option>
                                    <option value='Lithuanian'>Lithuanian</option>
                                    <option value='Luba-Katanga'>Luba-Katanga</option>
                                    <option value='Luxembourgish'>Luxembourgish</option>
                                    <option value='Macedonian'>Macedonian</option>
                                    <option value='Malagasy'>Malagasy</option>
                                    <option value='Malay'>Malay</option>
                                    <option value='Malayalam'>Malayalam</option>
                                    <option value='Maltese'>Maltese</option>
                                    <option value='Manx'>Manx</option>
                                    <option value='Maori'>Maori</option>
                                    <option value='Marathi'>Marathi</option>
                                    <option value='Marshallese'>Marshallese</option>
                                    <option value='Mongolian'>Mongolian</option>
                                    <option value='Nauru'>Nauru</option>
                                    <option value='Navajo'>Navajo</option>
                                    <option value='Ndonga'>Ndonga</option>
                                    <option value='Nepali'>Nepali</option>
                                    <option value='North Ndebele'>North Ndebele</option>
                                    <option value='Northern Sami'>Northern Sami</option>
                                    <option value='Norwegian'>Norwegian</option>
                                    <option value='Norwegian Bokmal'>Norwegian Bokmal</option>
                                    <option value='Norwegian Nynorsk'>Norwegian Nynorsk</option>
                                    <option value='Occitan'>Occitan</option>
                                    <option value='Ojibwa'>Ojibwa</option>
                                    <option value='Oriya'>Oriya</option>
                                    <option value='Oromo'>Oromo</option>
                                    <option value='Ossetian'>Ossetian</option>
                                    <option value='Pali'>Pali</option>
                                    <option value='Pashto'>Pashto</option>
                                    <option value='Persian'>Persian</option>
                                    <option value='Polish'>Polish</option>
                                    <option value='Portuguese'>Portuguese</option>
                                    <option value='Punjabi'>Punjabi</option>
                                    <option value='Quechua'>Quechua</option>
                                    <option value='Romanian'>Romanian</option>
                                    <option value='Romansh'>Romansh</option>
                                    <option value='Rundi'>Rundi</option>
                                    <option value='Russian'>Russian</option>
                                    <option value='Samoan'>Samoan</option>
                                    <option value='Sango'>Sango</option>
                                    <option value='Sanskrit'>Sanskrit</option>
                                    <option value='Sardinian'>Sardinian</option>
                                    <option value='Serbian'>Serbian</option>
                                    <option value='Shona'>Shona</option>
                                    <option value='Sichuan Yi'>Sichuan Yi</option>
                                    <option value='Sindhi'>Sindhi</option>
                                    <option value='Sinhala'>Sinhala</option>
                                    <option value='Slovak'>Slovak</option>
                                    <option value='Slovenian'>Slovenian</option>
                                    <option value='Somali'>Somali</option>
                                    <option value='South Ndebele'>South Ndebele</option>
                                    <option value='Southern Sotho'>Southern Sotho</option>
                                    <option value='Spanish'>Spanish</option>
                                    <option value='Sundanese'>Sundanese</option>
                                    <option value='Swahili'>Swahili</option>
                                    <option value='Swati'>Swati</option>
                                    <option value='Swedish'>Swedish</option>
                                    <option value='Tagalog'>Tagalog</option>
                                    <option value='Tahitian'>Tahitian</option>
                                    <option value='Tajik'>Tajik</option>
                                    <option value='Tamil'>Tamil</option>
                                    <option value='Tatar'>Tatar</option>
                                    <option value='Telugu'>Telugu</option>
                                    <option value='Thai'>Thai</option>
                                    <option value='Tibetan'>Tibetan</option>
                                    <option value='Tigrinya'>Tigrinya</option>
                                    <option value='Tonga'>Tonga</option>
                                    <option value='Tsonga'>Tsonga</option>
                                    <option value='Tswana'>Tswana</option>
                                    <option value='Turkish'>Turkish</option>
                                    <option value='Turkmen'>Turkmen</option>
                                    <option value='Twi'>Twi</option>
                                    <option value='Uighur'>Uighur</option>
                                    <option value='Ukrainian'>Ukrainian</option>
                                    <option value='Urdu'>Urdu</option>
                                    <option value='Uzbek'>Uzbek</option>
                                    <option value='Venda'>Venda</option>
                                    <option value='Vietnamese'>Vietnamese</option>
                                    <option value='Volapük'>Volapük</option>
                                    <option value='Walloon'>Walloon</option>
                                    <option value='Welsh'>Welsh</option>
                                    <option value='Western Frisian'>Western Frisian</option>
                                    <option value='Wolof'>Wolof</option>
                                    <option value='Xhosa'>Xhosa</option>
                                    <option value='Yiddish'>Yiddish</option>
                                    <option value='Yoruba'>Yoruba</option>
                                    <option value='Zhuang'>Zhuang</option>
                                    <option value='Zulu'>Zulu</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td noWrap className="zsFontClass" width='25%' align='left'>Subject&nbsp;&nbsp;</td>
                            <td align='left' width='75%'><input type='text' maxLength='255' name='Subject' value=''
                                    className="manfieldbdr" /></td>
                        </tr>
                        <tr>
                            <td noWrap className="zsFontClass" width='25%' align='left'>Description of Issue &nbsp;&nbsp;</td>
                            <td align='left' width='75%'> 
                                <textarea name='Description' maxLength='3000' width='250' height='250'
                                    className="manfieldbdr">
                                </textarea>
                            </td>
                        </tr>
                        <tr>
                            <td noWrap className="zsFontClass" width='25%' align='left'>Attachment &nbsp;&nbsp;</td>
                            {/* <td align='left' width='75%'><span className="zsFontClass wtcuploadfile" id="zsBrowseAttachment">Attach
                                    files</span><i onmouseout='zctt.hidett()'
                                    onmouseover='zctt.showtt(&quot;You&#x20;may&#x20;include&#x20;screenshots,&#x20;images,&#x20;or&#x20;files&#x20;that&#x20;elaborate&#x20;the&#x20;issue&#x20;encountered.&#x20;&quot;)'
                                    class='wtchelpinfo' style='margin-left:10px;'></i><input class="wtcuploadinput" type='file'
                                    name='attachment_1' id='zsattachment_1' style='display:block;'
                                    onclick='zsOpenFileBrowseAttachment(event)'
                                    onchange='zsRenderBrowseFileAttachment(this.value, this)' /><input class="wtcuploadinput"
                                    type='file' name='attachment_2' id='zsattachment_2' style='display:none;'
                                    onclick='zsOpenFileBrowseAttachment(event)'
                                    onchange='zsRenderBrowseFileAttachment(this.value, this)' /> <input class="wtcuploadinput"
                                    type='file' name='attachment_3' id='zsattachment_3' style='display:none;'
                                    onclick='zsOpenFileBrowseAttachment(event)'
                                    onchange='zsRenderBrowseFileAttachment(this.value, this)' /> <input class="wtcuploadinput"
                                    type='file' name='attachment_4' id='zsattachment_4' style='display:none;'
                                    onclick='zsOpenFileBrowseAttachment(event)'
                                    onchange='zsRenderBrowseFileAttachment(this.value, this)' /> <input class="wtcuploadinput"
                                    type='file' name='attachment_5' id='zsattachment_5' style='display:none;'
                                    onclick='zsOpenFileBrowseAttachment(event)'
                                    onchange='zsRenderBrowseFileAttachment(this.value, this)' />
                                <div class="clboth"></div><span id='zsMaxSizeMessage'
                                    style='color:black;font-size: 8px;float: left;'>Each of your file(s) can be up to 20MB in
                                    size.</span><span id='zsMaxLimitMessage'
                                    style='color:black;font-size: 8px;float: left;margin-left: 14px;display: none;'>You can attach
                                    as many as 5 files at a time.</span>
                                <div id='zsFileBrowseAttachments'></div>
                            </td> */}
                        </tr>
                        <tr>
                            <td noWrap className='zsFontClass' width='25%' align='left'>Captcha&nbsp;</td>
                            <td>
                                <div id='zsCaptchaLoading'><strong style={{ color: 'white'}}>Loading...<br/><br/></strong></div>
                                <div id='zsCaptcha' style={{display: 'none'}}><img src='#' id='zsCaptchaUrl' name="zsCaptchaImage" /><a
                                        href='javascript:;'
                                        style={{color:'#00a3fe', cursor: 'pointer', marginLeft:'10px', verticalAlign:'middle', textDecoration: 'none'}}
                                        className='zsFontClass' 
                                        // onclick={zsRegenerateCaptcha()}
                                        >Refresh</a></div>
                                <div><input type='text' name='zsWebFormCaptchaWord' /><input type='hidden' name='zsCaptchaSrc'
                                        value='' /></div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{padding: '11px 5px 0px 5px'}} colSpan='2' align='center' width='25%'> <input type='submit'
                                    id="zsSubmitButton_765725000000664003" className='zsFontClass submitBtn' value='Submit'/> &nbsp; &nbsp; <input
                                    type='button' className='zsFontClass submitBtn' value='Reset' 
                                    // onClick={zsResetWebForm('765725000000664003')}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td width='25%' align='left'></td>
                            <td style={{padding: '0px 5px'}} align='left' width='75%'>
                                <div className='wb_FtCon wb_common'><span style={{ color: 'white'}}>powered by </span><a target='_blank' rel='noopener noreferrer'
                                        href='https://zoho.com/desk' className='wb_logoCon'><img className='wb_logo'
                                            src='https://d1ydxa2xvtn0b5.cloudfront.net/app/images/portalLogo.de847024ebc0131731a3.png' /></a>
                                </div>
                            </td>
                        </tr>
                    </table>

                </form>
            </div >
        )

    }))