import { inject, observer } from "mobx-react";
import Logo from "../../Assets/Images/CO2_Logo.png";
import { useHistory, useLocation } from "react-router-dom";
import React from "react";
import { Colors } from "../../Assets/Config/color.config";
import { Button } from "@mui/material";
import { SSO_URL } from "../../network/URL";

export const SubscribeContainer = inject("store")(
  observer((props) => {
    function useQuery() {
      const { search } = useLocation();

      return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let message = useQuery().get("message");

    if (message) {
      const nextURL = window.location.pathname;
      const nextTitle = "Reset original url.";
      const nextState = { additionalInformation: "Updated the URL with JS" };
      window.history.replaceState(nextState, nextTitle, nextURL);
    } else {
      message = "Welcome from CO2X.";
    }

    const openInNewTab = (url) => {
      window.open(url, "_blank", "noopener,noreferrer");
    };

    return (
      <div className="container">
        <div className="row justify-content-center">
          <form
            className="col-lg-4 col-md-8 col-sm-10 shadow p-4"
            style={{
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              borderRadius: "15px",
            }}
          >
            <div className="form-group text-center m-0">
              <img src={Logo} alt="logo" />
              <p
                className="m-3"
                style={{
                  fontSize: 28,
                  fontWeight: "lighter",
                  color: Colors.Dark[4],
                }}
              >
                CO2 Connect
              </p>
              <p
                className="m-3"
                style={{
                  fontSize: 18,
                  fontWeight: "lighter",
                  color: Colors.Dark[4],
                }}
              >
                Message : {message}
              </p>

              {/* <form method="post" target="_blank" action="https://co2delete.com/api/co2/sso/subscribe"> */}
              <form
                method="post"
                target="_blank"
                action={`${SSO_URL}co2/sso/subscribe`}
              >
                <input type="hidden" id="c_id" name="c_id" value="88880000" />
                <input
                  type="hidden"
                  id="name"
                  name="name"
                  value="Subscriber Paing Soe"
                />
                <input
                  type="hidden"
                  id="email"
                  name="email"
                  value="paingsoe.subscriber@gmail.com"
                />
                <input
                  type="hidden"
                  id="password"
                  name="password"
                  value="7aa1ec0d668f0co2xfcd"
                />
                <input type="submit" value="Subscribe CO2X"></input>
              </form>

              <form
                method="post"
                target="_blank"
                action={`${SSO_URL}co2/sso/subscribe-login`}
                // action="https://co2delete.com/api/co2/sso/subscribe-login"
                //action="https://co2xconnect.com/api/co2/sso/subscribe-login"
              >
                <input
                  type="hidden"
                  id="email"
                  name="email"
                  value="paingsoe.subscriber@gmail.com"
                  // value="kaungmyattun212000@gmail.com"
                />
                <input
                  type="hidden"
                  id="password"
                  name="password"
                  value="77a1b2d17co2x70404f3"
                />
                <input type="submit" value="CO2X Connect"></input>
              </form>

              <Button
                onClick={() =>
                  openInNewTab(
                    `${SSO_URL}co2/sso/unsubscribe?email=paingsoe.subscriber@gmail.com`
                    //"https://co2delete.com/api/co2/sso/unsubscribe?email=paingsoe.subscriber@gmail.com"
                    //"https://co2xconnect.com/api/co2/sso/unsubscribe?email=paingsoe.subscriber@gmail.com"
                  )
                }
              >
                Unsubscribe CO2X Connect
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  })
);
