import * as React from "react";

import { Tooltip, tooltipClasses } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import {
  Pagination,
  PaginationItem,
  styled,
  TableSortLabel,
} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { inject, observer } from "mobx-react";
import requestImage from "../../Assets/Images/icons/request.png";
import requestLightImage from "../../Assets/Images/icons/requestLight.png";
import { toJS } from "mobx";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const LightWebformTableNew = inject("store")(
  observer((props) => {
    const {t} = useTranslation("lite-manual-entry")
    const {
      header,
      theme,
      Key,
      createData,
      data,
      dataType,
      trackTraceData,
      _handleViewContract,
      noPagination,
      lightWebform,
      manageMode,
    } = props;
    const {
      _handleDelete,
      setEditMode,
      fetchRequestReport,
      _setToDelete,
      fetchDownloadReport,
    } = props.store.liteEntry;
    const [page, setPage] = React.useState(1);
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("");
    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };
    console.log("data is==", toJS(data), dataType);


    const VehicleTooltip = styled(({ className, ...props }) => (
      <Tooltip
        {...props}
        classes={{ popper: className }}
        placement="right-start"
      />
    ))(() => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme === "dark" ? Colors.darkBlue : Colors.Dark[5],
        color: Colors.fuelLight,
        fontSize: "15px",
        width: "280px",
        boxShadow: "0px 10px 10px rgba(0,0,0,0.1)",
        padding: "15px 10px",
      },
    }));

    const [showInfo, setShowInfo] = useState(false);
    const _handleClose = () => {
      setShowInfo(false);
    };
    

    const rows = data?.response?.map((v, k) =>
      createData(
        v.id,
        v.status,
        v.is_individual === 1 ? "Individual Asset" : "Fleet Aggregate",
        // v.is_individual === 1 ? v.vehicleTypes.map(v => v.vehicle_type) : <Tooltip title={v.vehicleTypes.map(v => v.vehicle_type)} placement="right-start"><span>Aggregate</span></Tooltip> ,
        v.is_individual === 1 ? (
          v.vehicleTypes.map((v) => v.vehicle_type)
        ) : (
          

          
          <VehicleTooltip
                title={<div className="rounded " style={{ border: "1px solid #746541" }}>
                    <div className="d-flex justify-content-between p-2">
                        <div>{t("Vehicle Type")}</div>
                        <div>{t("Number of Vehicles")}</div>
                    </div>
                    <div>
                        {v.vehicleTypes.map((d, i) => {
                        return (
                            <div className="d-flex justify-content-between px-2" style={{ borderTop: "1px solid #746541", fontSize: "12px" }}>
                                
                                
                                <span
                                    className="col-6 text-start"
                                    style={{ borderRight: "1px solid #746541" }}
                                >
                                    <div>{d.vehicle_type}</div>
                                </span>
                                <div>{d.no_of_vehicle}</div>

                                
                                {/* {v.vehicleTypes.length - 1 == i ? null : <hr />} */}
                            </div>
                        );
                        })}
                    </div>
                </div>
                }
                key={k}
            >
              <span style={{cursor: 'pointer'}}>{t("Aggregate")}
              <i style={{ color: 'yellow', cursor: 'pointer' }} className="bi bi-info-square ps-2 reduceOpacityOnhover"></i>
              </span>
                
            </VehicleTooltip>

          // <span>Aggregate
          //       <i style={{ color: 'yellow', cursor: 'pointer' }} 
          //          className="bi bi-info-square ps-2 reduceOpacityOnhover"
          //          onClick={() => setShowInfo(true)}
          //          onClickCapture={() => setShowInfo(true)}
          //          ></i>
          // </span>
          
          
        ),
        v.plate_number || "N/A",
        v.fuel_type || "N/A",
        v.total_consumption || "N/A",
        v.fuel_unit || "N/A",
        v.total_mileage,
        v.report_type,
        v.period_start,
        v.period_end,
        v.remark || "N/A",
        "action"
      )
    );

    

    console.log("rows data ", rows);

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
     
      "&:nth-of-type(odd)": {
        backgroundColor:
          props.theme === "dark" ? Colors.darkBlue : Colors.Dark[5],
        color: theme.palette.common.white,
      },
      // hide last border
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    }));
    const rowCount = 6;
    const handleChange = (event, value) => {
      setPage(value);
    };

    // console.log("count", rows.length / rowCount);
    function EnhancedTableHead(props) {
      const { order, orderBy, onRequestSort, theme, Key, _handleViewContract } =
        props;
      const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
      };

      return (
        <TableHead>
          <TableRow>
            {header.map((headCell, index) => (
              <TableCell
                key={index}
                align={"left"}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
                style={{ background: invertDark(theme) }}
              >
                {headCell.label === "" || (
                  <TableSortLabel
                    style={{
                      color:
                        orderBy === headCell.id
                          ? Colors.activeColor
                          : theme === "light"
                          ? "#F6F6F6"
                          : Colors.darkBlue,
                    }}
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(Key[index])}
                  >
                    <span className="fw-bold">{headCell.label}</span>
                  </TableSortLabel>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        
      );
    }
    function descendingComparator(a, b, orderBy) {
      const A = a[orderBy] ? a[orderBy] : undefined;
      const B = b[orderBy] ? b[orderBy] : undefined;
      if (B < A) {
        return -1;
      }
      if (B > A) {
        return 1;
      }
      return 0;
    }
    function getComparator(order, orderBy) {
      return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
    }
    const useStyles = makeStyles({
      foo: {
        "& .MuiPagination-ul": { justifyContent: "end" },
        "& .MuiPaginationItem-text": {
          color: Colors.Dark[theme === "dark" ? 4 : 1],
        },
        "& .Mui-selected": {
          backgroundColor:
            theme === "dark" ? Colors.lightBlue : Colors[theme].primaryColor,
          color: Colors.Dark[4],
        },
      },
    });
    const classes = useStyles();
    console.log("length", rows?.length);

    return (
      <React.Fragment>
        <TableContainer
          component={Paper}
          style={{ background: "none" }}
          sx={{ maxHeight: 450 }}
        >
          <Table
            stickyHeader
            sx={{ minWidth: 650 }}
            size="small"
            aria-label="a dense sticky table"
          >
            <EnhancedTableHead
              _handleViewContract={_handleViewContract}
              Key={Key}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              theme={theme}
            />
            <TableBody>
              {rows
                ?.sort(getComparator(order, orderBy))
                ?.slice((page - 1) * rowCount, page * rowCount)
                ?.map((row, index) => (
                  <StyledTableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    {Object.values(row).map(
                      (d, x) =>
                        x !== 0 &&
                        x !== 1 &&
                        d !== "action" && (
                          <TableCell
                            sx={{
                              color: invertDark(theme),
                              borderBottom: "none",
                            }}
                            key={x}
                          >
                            {d}
                          </TableCell>
                        )
                    )}
                    <TableCell
                      sx={{
                        color: invertDark(theme),
                        borderBottom: "none",
                      }}
                      key={index}
                    >
                      {manageMode ? (
                        <>
                          <span
                            className="pe-2 reduceOpacityOnhover"
                            data-bs-toggle="modal"
                            data-bs-target="#webformModal"
                            data-toggle="modal"
                            data-target=".bd-example-modal-lg"
                            onClick={() => setEditMode(true, row)}
                            style={{ color: invertDark(theme) }}
                          >
                            <i className="bi bi-pencil-square"></i>Edit
                          </span>
                          <span
                            data-bs-toggle="modal"
                            data-bs-target="#confirmDeleteModal"
                            data-toggle="modal"
                            data-target=".bd-example-modal-lg"
                            onClick={() =>
                              _setToDelete(row.id, row.license_plate)
                            }
                            className="reduceOpacityOnhover"
                            style={{ color: "#ff2d2dd1" }}
                          >
                            <i className="bi bi-trash-fill"></i>{t("Delete")}
                          </span>
                        </>
                      ) : row.status == 1 ? (
                        <span
                          data-bs-toggle="modal"
                          data-bs-target="#createReportModal"
                          data-toggle="modal"
                          data-target=".bd-example-modal-lg"
                          className="reduceOpacityOnhover"
                          style={{ color: "#237cff" }}
                        >
                          <i className="bi bi-download pe-2"></i>
                          <span onClick={() => fetchDownloadReport(row.id)}>
                            {t("Download Report")}
                          </span>
                        </span>
                      ) : (
                        <span
                          onClick={() => fetchRequestReport(row.id, "1")}
                          className="reduceOpacityOnhover"
                          style={{
                            color: theme === "dark" ? "#ffec67" : "#ff5c00",
                          }}
                        >
                          <img
                            className="me-2"
                            width="15px"
                            src={
                              theme === "dark"
                                ? requestImage
                                : requestLightImage
                            }
                          />
                          {t("Request for Report")}
                        </span>
                      )}
                    </TableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        {noPagination || (
          <div className="py-2">
            <Pagination
              size="small"
              renderItem={(item) => (
                <PaginationItem
                  {...item}
                  sx={{ color: invertDark(theme) }}
                  classes={{ selected: classes.selected }}
                />
              )}
              className={classes.foo}
              count={
                rows?.length <= rowCount
                  ? 1
                  : Math.ceil(rows?.length / rowCount)
              }
              defaultPage={1}
              page={page}
              siblingCount={1}
              boundaryCount={1}
              onChange={handleChange}
            />
          </div>
        )}
      </React.Fragment>
    );
  })
);
