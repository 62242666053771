import { Cookies } from "react-cookie";
import { Colors } from "../../Assets/Config/color.config";
import Certificate from "../../Assets/Images/certificate.png";
import { ContractDetail } from "../TrackAndTrace/contractDetail";

export const CertificateModal = () => {
  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg rounded  bg-white">
        <div className="modal-content">
          <div className="modal-header" style={{ border: "none" }}>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <img src={Certificate} alt="certificate" className="img-fluid"/>
          </div>
        </div>
      </div>
    </div>
  );
};

export const VehicleModal = ({ assetInfo, theme }) => {
  return (
    <div
      className="modal fade"
      id="exampleModal2"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg rounded">
        <div className="modal-content" style={{ background: Colors[theme].background }}>
          <div className="modal-header" style={{ border: "none" }}>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="d-flex flex-row flex-wrap">
              {assetInfo.map((v,k) => (
                <div className="col-6" key={k}>
                  <div
                    className="d-flex flex-row justify-content-between m-2 p-2 rounded"
                    style={{ color: Colors.Dark[theme==="dark"?4:1], background: theme==="dark"? Colors.darkBlue:Colors.Dark[5] }}
                  >
                    <span style={{ fontWeight: "bold" }}>{v.name} :</span>

                    <span>{v.value}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ContractModal = ({ theme,contractDetailData,contractData,_handleClose }) => {
  const cookie=new Cookies()


  return (
    <div
      className="modal fade"
      id="exampleModal2"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md rounded">
        <div className="modal-content" style={{ background: Colors[theme].background }}>
          <div className="modal-header d-flex align-items-center ps-4" style={{ border: "none",backgroundColor:'#141d49' }}>
            <h4>{cookie.get("trackingName")}</h4>
            <strong className="p-0"  style={{ paddingTop: '10px', textDecoration: 'bold', fontSize: '20px'}}>Details</strong>          
            <button
              type="button"
              className={`btn-close ${theme==='dark'&&'btn-close-white'}`}
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={_handleClose}
            ></button>
          </div>
          <div className="modal-body" style={{backgroundColor:'#19245a'}}>
            <div className="d-flex flex-row flex-wrap pt-2 ps-2" >
              <ContractDetail contractDetailData={contractDetailData} theme={theme}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
