import React from "react";
import {Colors, invertDark} from "../Assets/Config/color.config";
import "../App.css";

export const My_Input = (props) => {
  const {
    style,
    placeHolder,
    disabled,
    required,
    type,
    id,
    height,
    width,
    onChange,
    value,
    maxLength,
    pattern,
    myRef,
    accept,
    text,
    multiple,
    customColor,
    ref,
    search,
    customInputSelect
  } = props;
  const theme = localStorage.getItem("theme");
  const defaultStyle = {
    width: width === undefined ? "100%" : width,
    background:theme==='dark' ? Colors.darkBlue : "#fff",
    color:invertDark(theme),
    paddingTop:10,
    paddingBottom:10,    
    fontSize: 14,
    height: `${height}`,
    boxShadow: "none",
    shapeOutline: "none",
    outline: "none",
    border: customInputSelect ? "none" : `1px solid ${Colors.secondaryColor}`,
    borderRadius: 5,
  };
  const disabledStyle={
    background: customColor
  }
  const defaultStyle1=disabled && disabledStyle
  const userStyle = style === undefined ? {} : style;

  const __handleFocus = () => {
    document.getElementById(
      id
    ).style.border = search ? "none" : `2px solid ${Colors["dark"].primaryColor}`;
  };
  const __handleBlur = () => {
    document.getElementById(
      id
    ).style.border = search ? "none" : `2px solid ${Colors["dark"].secondaryColor}`;
  };
  // console.log('file value ',  type === 'file' && value)
  return  (
    type === 'file' ?
    <>
    <span
      className={`btn btn-file d-flex justify-content-center align-items-center text-white`}
      style={{
        ...style
      }}
    >
      {text}
      <input  
        id={id}
        type="file" 
        accept={accept}
        onChange={onChange}
        multiple={multiple?true:false}
        required
      />

    </span>
    <div style={{ fontSize: 12, minHeight: 18}}>{multiple?(value ? (value.length+(value.length>1 ? ' files': ' file')):null) :(value ? value[0]?.name : null)}</div>
    </>
    :
    <input
      // ref={myRef}
      ref={ref}
      autoComplete='new-password'
      spellCheck="false"
      id={id}
      disabled={disabled}
      required={required}
      onChange={onChange}
      style={{
        ...defaultStyle,
        // ...defaultStyle1,
        ...userStyle,
      }}
      onBlur={__handleBlur}
      pattern={pattern ? pattern : null}
      placeholder={placeHolder}
      className="form-control"
      onFocus={__handleFocus}
      type={type === undefined ? "text" : `${type}`}
      value={value != null ? value : ''}
      maxLength={maxLength}
      
    />
  );
};
