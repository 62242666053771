/* eslint-disable react-hooks/exhaustive-deps */
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { CompanySelector } from "../../Components/Dashboard/companySelector";
import { DataEntryEditForm } from "../../Components/DataEntry/DataEntryEditForm";
import { DataEntryTable } from "../../Components/DataEntry/DataEntryTable";
import { TimelineView } from "../../Components/DataEntry/TimelineView";
import { Selector } from "../../Tools";
import Store from "../../Store";
import { CO2_Loading } from "../../Assets/CO2_Loading";
import { toJS } from "mobx";
import { useTranslation } from "react-i18next";
import { DataEntryTableJQ } from "../../Components/DataEntry/DataEntryTableJQ";
import { ManualEntryHistoryViewJQ } from "../../Components/DataEntry/ManualEntryHistoryViewJQ";
import Tour from "./dataEntryTour";
import month from "../../Assets/month.json"
import { DataEntryVehicleList } from "../../Components/DataEntry/DataEntryVehicleList";
import { DataEntryTablePerUnit } from "../../Components/DataEntry/DataEntryTablePerUnit";

export const NewManualEntry = inject("store")(
  observer((props) => {
    const { _handleFetchCompanyYears, companyyearlydata } =
      props.store.dashboard;
    const { t } = useTranslation("new-manuel-entry");
    const theme = localStorage.getItem("theme");
    const {
      text,
      fileFuel,
      fileFuelName,
      filefueltype,
      quantity,
      totalamount,
      currentMonth,
      currentYear,
      currentType,
      _handleSelectYear,
      _handleSelectType,
      _fetchDataEntry,
      currentTableData,
      vehicleData,
      _handleSelectVehicle,
      dataEntryData,
      _handleGetVehicleType,
      vehicleType,
      _handleClearAudit,
      _handleCreateAudit,
      newAudit,
      setVehicleIndex,
      setManualIndex,
      _handleViewManual,
      vehicleIndex,
      tableVehicleRow,
      _handleClearAuditImages,
      _handleCreateAuditImages,
      page,
      setPaginationPage,
      _handleSelectMonth,
      auditData,
      loadingForDataEntry,
      _handleCreateOdometerFiles,
      showManualEntryHistory,
      setShowManualEntryHistory,
      VData,
      manualdataData,
      manuals,
      actionStatus,
      _handleUpdateActionStatus,
      consumptionDataPerUnit,
      selectedGrouping
    } = props.store.dataEntry;

    const { companyId, role } = props.store.common;

    const { manual_rows, filteredData3 } = props;

    const fixed_manuals = manuals?.flat();

    const fixed_manuals_withNOZero = fixed_manuals?.filter(
      (data) => data?.id !== 0 && data?.id !== undefined
    );

   

    const VData_PlateNO = toJS(VData)?.map((v) => {
      const a = v?.plate_no;
      return a;
    });

    console.log('VData --->', VData)

    const cardStyle1 = {
      background: Colors[theme].background,
      boxShadow: "0px 5px 10px rgba(0,0,0,0.25)",
      padding: "15px",
      borderRadius: "5px",
      marginBottom: 8,
      color: invertDark(theme),
      // cursor: "pointer"
    };

    const closeButtonStyle = {
      marginBottom: "1em",
      top: "10px",
      bottom: "10px",
      right: "10px",
      cursor: "pointer",
      fontSize: "18px",
      fontWeight: "bold",
      color: "white",
    };
    const closeButtonStyleBlack = {
      marginBottom: "1em",
      top: "10px",
      bottom: "10px",
      right: "10px",
      cursor: "pointer",
      fontSize: "18px",
      fontWeight: "bold",
      color: "black",
    };
    const cardStyleForManualDataTable = {
      background: Colors[theme].background,
      boxShadow: "0px 5px 10px rgba(0,0,0,0.25)",
      padding: "15px",
      borderRadius: "5px",
      marginBottom: 10,
      color: invertDark(theme),
      // position: "relative",
    };

    useEffect(() => {
      _handleFetchCompanyYears();
      _handleGetVehicleType();
      _handleCreateOdometerFiles(currentTableData);
    }, []);

    useEffect(() => {
      if (auditData) {
        _handleCreateAudit();
      }
    }, [tableVehicleRow.id, tableVehicleRow]);

    const rows = consumptionDataPerUnit
      ? consumptionDataPerUnit?.vehicles?.map((value) => {
        let fuel_consumed = "0.00";
        let trip_mileage = "0.00";
        let trip_mileage_value = 0;
        let total_odometer_start = 0;

        let showAction = 0;

        if (value.fuel_type === "battery") {
          if (value?.CAMBus) {
            if (
              value?.ascent_data?.battery_consumed &&
              value?.ascent_data?.battery_consumed > 0
            ) {
              fuel_consumed =
                Number(value.ascent_data.battery_consumed).toFixed(4) +
                " ascent";
            } else {
              fuel_consumed = "0.00";
            }
          } else {
            if (
              value.manual_data?.consumed &&
              value.manual_data?.consumed > 0
            ) {
              fuel_consumed =
                Number(value.manual_data.consumed).toFixed(4) + " manual";
            } else {
              fuel_consumed = "0.00";
            }
          }
        } else {
          if (value?.CAMBus) {
            if (
              value?.ascent_data?.fuel_consumed &&
              value?.ascent_data?.fuel_consumed > 0
            ) {
              fuel_consumed =
                Number(value.ascent_data.fuel_consumed).toFixed(4) +
                " ascent";
            } else {
              fuel_consumed = "0.00";
            }
          } else {
            if (
              value.manual_data?.consumed &&
              value.manual_data?.consumed > 0
            ) {
              fuel_consumed =
                Number(value.manual_data.consumed).toFixed(4) + " manual";
            } else {
              fuel_consumed = "0.00";
            }
          }
        }

        if (value?.GPS) {
          if (value.ascent_data?.trip_mileage) {
            trip_mileage =
              Number(value.ascent_data?.trip_mileage).toFixed(2) + " ascent";
            trip_mileage_value = Number(
              value.ascent_data?.trip_mileage
            ).toFixed(2);
          } else {
            trip_mileage = "0.00";
            trip_mileage_value = 0;
          }
        } else {
          if (value.manual_data?.trip_mileage) {
            trip_mileage =
              Number(value.manual_data?.trip_mileage).toFixed(2) + " manual";
            trip_mileage_value = Number(
              value.manual_data?.trip_mileage
            ).toFixed(2);
          } else {
            trip_mileage = "0.00";
            trip_mileage_value = 0;
          }
        }

        if (value?.GPS) {
          if (value.ascent_data?.total_mileage_start) {
            total_odometer_start = Number(
              value.ascent_data?.total_mileage_start
            ).toFixed(2);
          } else {
            total_odometer_start = Number(0);
          }
        } else {
          if (value.manual_data?.total_mileage_start) {
            total_odometer_start = Number(
              value.manual_data?.total_mileage_start
            ).toFixed(2);
          } else {
            total_odometer_start = Number(0);
          }
        }

        let odometer_end = (
          Number(total_odometer_start) + Number(trip_mileage_value)
        ).toFixed(2);

        if (companyId === value.ownerId) {
          role === "user" ? (showAction = 0) : (showAction = 1);
        } else {
          showAction = 0;
        }

        if (value?.GPS && value?.CAMBus) {
          showAction = 0;
        } else {
          if (companyId === value.ownerId) {
            role === "user" ? (showAction = 0) : (showAction = 1);
          } else {
            showAction = 0;
          }
        }

        // View Button comment

        if (role === "super_admin") {
          if (value?.GPS && value?.CAMBus) {
            showAction = 0;
          } else {
            showAction = 2;
          }
        } else if (role === "operator") {
          if (value?.GPS && value?.CAMBus) {
            showAction = 0;
          } else {
            showAction = 1;
          }
        } else if (role === "admin") {
          if (companyId === value.ownerId) {
            showAction = 1;
          } else {
            showAction = 0;
          }
        }

        let data = {
          id: value.id,
          license: value.plate_no || "N/A",
          type: value.fuel_type || "No Data",
          fuel_consumed: fuel_consumed,
          odometer_start: total_odometer_start
            ? total_odometer_start
            : "0.00",
          odometer: odometer_end === "NaN" ? "0.00" : odometer_end,
          gps: value.GPS,
          canbus: value.CAMBus,
          trip_mileage: trip_mileage,
          action:
            showAction === 1 ? "Update" : showAction === 2 ? "View" : " ",
          //showAction === 1 ? "Update" : showAction === 2 ? "View" : " ",
          // action: showAction === 1 ? "Update" : ""
        };
        return data;
      })
      : [];

    const rowsPerUnit = consumptionDataPerUnit
      ? consumptionDataPerUnit?.vehicles?.map((value) => {
        let fuel_consumed = "0.00";
        let trip_mileage = "0.00";
        let trip_mileage_value = 0;
        let total_odometer_start = 0;

        let showAction = 0;

        if (value.fuel_type === "battery") {
          if (value?.CAMBus) {
            if (
              value?.ascent_data?.battery_consumed &&
              value?.ascent_data?.battery_consumed > 0
            ) {
              fuel_consumed =
                Number(value.ascent_data.battery_consumed).toFixed(4) +
                " ascent";
            } else {
              fuel_consumed = "0.00";
            }
          } else {
            if (
              value.manual_data?.consumed &&
              value.manual_data?.consumed > 0
            ) {
              fuel_consumed =
                Number(value.manual_data.consumed).toFixed(4) + " manual";
            } else {
              fuel_consumed = "0.00";
            }
          }
        } else {
          if (value?.CAMBus) {
            if (
              value?.ascent_data?.fuel_consumed &&
              value?.ascent_data?.fuel_consumed > 0
            ) {
              fuel_consumed =
                Number(value.ascent_data.fuel_consumed).toFixed(4) +
                " ascent";
            } else {
              fuel_consumed = "0.00";
            }
          } else {
            if (
              value.manual_data?.consumed &&
              value.manual_data?.consumed > 0
            ) {
              fuel_consumed =
                Number(value.manual_data.consumed).toFixed(4) + " manual";
            } else {
              fuel_consumed = "0.00";
            }
          }
        }

        if (value?.GPS) {
          if (value.ascent_data?.trip_mileage) {
            trip_mileage =
              Number(value.ascent_data?.trip_mileage).toFixed(2) + " ascent";
            trip_mileage_value = Number(
              value.ascent_data?.trip_mileage
            ).toFixed(2);
          } else {
            trip_mileage = "0.00";
            trip_mileage_value = 0;
          }
        } else {
          if (value.manual_data?.trip_mileage) {
            trip_mileage =
              Number(value.manual_data?.trip_mileage).toFixed(2) + " manual";
            trip_mileage_value = Number(
              value.manual_data?.trip_mileage
            ).toFixed(2);
          } else {
            trip_mileage = "0.00";
            trip_mileage_value = 0;
          }
        }

        if (value?.GPS) {
          if (value.ascent_data?.total_mileage_start) {
            total_odometer_start = Number(
              value.ascent_data?.total_mileage_start
            ).toFixed(2);
          } else {
            total_odometer_start = Number(0);
          }
        } else {
          if (value.manual_data?.total_mileage_start) {
            total_odometer_start = Number(
              value.manual_data?.total_mileage_start
            ).toFixed(2);
          } else {
            total_odometer_start = Number(0);
          }
        }

        let odometer_end = (
          Number(total_odometer_start) + Number(trip_mileage_value)
        ).toFixed(2);

        if (companyId === value.ownerId) {
          role === "user" ? (showAction = 0) : (showAction = 1);
        } else {
          showAction = 0;
        }

        if (value?.GPS && value?.CAMBus) {
          showAction = 0;
        } else {
          if (companyId === value.ownerId) {
            role === "user" ? (showAction = 0) : (showAction = 1);
          } else {
            showAction = 0;
          }
        }

        // View Button comment

        if (role === "super_admin") {
          if (value?.GPS && value?.CAMBus) {
            showAction = 0;
          } else {
            showAction = 2;
          }
        } else if (role === "operator") {
          if (value?.GPS && value?.CAMBus) {
            showAction = 0;
          } else {
            showAction = 1;
          }
        } else if (role === "admin") {
          if (companyId === value.ownerId) {
            showAction = 1;
          } else {
            showAction = 0;
          }
        }

        let data = {
          id: value.id,
          // license: value.plate_no || "N/A",
          type: value.fuel_type || "No Data",
          fuel_consumed: fuel_consumed,
          odometer_start: total_odometer_start
            ? total_odometer_start
            : "0.00",
          odometer: odometer_end === "NaN" ? "0.00" : odometer_end,
          // gps: value.GPS,
          // canbus: value.CAMBus,
          trip_mileage: trip_mileage,
          action: 
            showAction === 1 ? "Update" : showAction === 2 ? "View" : " ",
          //showAction === 1 ? "Update" : showAction === 2 ? "View" : " ",
          // action: showAction === 1 ? "Update" : ""
        };
        return data; 
      })
      : [];

    return (
      <div style={{ overflowX: "hidden", overflowY: "hidden" }}>
        <div className="col d-flex align-items-center">
          <span
            className="ps-0 title-dataEntry-guide"
            style={{
              fontSize: 23,
              color:
                theme === "dark" ? Colors["light"].background : Colors.Dark[2],
            }}
          >
            {t("Data Entry")}: {t("For")}{" "}
            {currentType === "ev" ? t("Electricity") : t("Fuel")}{" "}
            {t("Consumption Data")}
            {/* <Tour/> */}
          </span>
        </div>
        <div className="row">
        <div className="col-md-6 col-lg-3 col-sm-6">
          <span className="col-3">
            <div className="d-flex flex-row justify-content-between flex-wrap pt-2 align-items-center pb-2">
              <div className="align-self-center">
                <CompanySelector
                  theme={theme}
                  label={t("Organisation") + ": "}
                />
              </div>
            </div>
            <div className="row px-2 py-1">
              <div className="ps-0 mx-1" style={{ width: 120 }}>
                <Selector
                  isSearchable={false}
                  label={t("Vehicle Type")}
                  options={typeOptions}
                  // value={typeOptions.filter((v) => v.value === currentType)[0]}
                  value={
                    vehicleType
                      ? vehicleType
                      : typeOptions.filter(
                        (v) => v.value === vehicleType.value
                      )[0]
                  }
                  _handleSelect={(value) => {
                    // console.log(value)
                    _handleSelectType(value);
                  }}
                  disabled={false}
                />
              </div>
              <div className="ps-0" style={{ width: 120 }}>
                <Selector
                  isSearchable={false}
                  label={t("Year")}
                  options={companyyearlydata}
                  defaultValue={
                    companyyearlydata.filter(
                      (v) => v.value === new Date().getFullYear()
                    )[0]
                  }
                  _handleSelect={(value) => {
                    _handleSelectYear(value.value);
                  }}
                  disabled={false}
                />
              </div>
            </div>
            {dataEntryData?.payload?.length > 0 && (
              <TimelineView theme={theme} currentMonth={currentMonth} />
            )}
          </span>
          </div>
          <div className="col-md-6 col-lg-2 col-sm-6">
          {dataEntryData?.payload && dataEntryData?.payload?.length > 0 ? (

            <span className="col-2">
              <DataEntryVehicleList theme={theme} />
            </span>) : <CO2_Loading />}
            </div>
            <div className="col-md-12 col-lg-7 col-sm-12">
          {dataEntryData?.payload && dataEntryData?.payload?.length > 0 ? (
            <span
              className="col-7 px-0 mt-2"
              style={{ height: "100%", overflowY: "scroll" }}
            >
              {/* {currentTableData && <div className="m-1 mb-3" style={cardStyle1}>  <DataEntryTable theme={theme} rows={rows} data={currentTableData.vehicles} type={currentType} _handleSelectVehicle={_handleSelectVehicle} /></div>} */}

              {/* <div className="m-1 mb-3" style={cardStyle1}>
                <DataEntryTable
                  setVehicleIndex={setVehicleIndex}
                  _handleCreateAudit={_handleCreateAudit}
                  _handleClearAudit={_handleClearAudit}
                  theme={theme}
                  rows={rows}
                  data={currentTableData?.vehicles}
                  type={currentType}
                  _handleSelectVehicle={_handleSelectVehicle}
                  _handleClearAuditImages={_handleClearAuditImages}
                  _handleCreateAuditImages={_handleCreateAuditImages}
                  setPaginationPage={setPaginationPage}
                  loadingForDataEntry={loadingForDataEntry}
                />
              </div> */}

              {/* <DataEntryTableSection 
                theme={theme} cardStyle1={cardStyle1}
              /> */}

              <div style={cardStyle1}>
                {loadingForDataEntry ? (
                  // <DataEntryTableJQ
                  //   setVehicleIndex={setVehicleIndex}
                  //   _handleCreateAudit={_handleCreateAudit}
                  //   _handleClearAudit={_handleClearAudit}
                  //   theme={theme}
                  //   rows={rows}
                  //   data={currentTableData?.vehicles}
                  //   type={currentType}
                  //   _handleSelectVehicle={_handleSelectVehicle}
                  //   _handleClearAuditImages={_handleClearAuditImages}
                  //   _handleCreateAuditImages={_handleCreateAuditImages}
                  //   setPaginationPage={setPaginationPage}
                  //   loadingForDataEntry={loadingForDataEntry}
                  // />

                  <div>
                    <span style={{ display: 'block', color: invertDark(theme), marginBottom: '10px', fontSize: '18px' }}>Data For {selectedGrouping} (
                      {month[currentMonth + 1].label + " " + currentYear})</span>
                    <DataEntryTablePerUnit
                      setVehicleIndex={setVehicleIndex}
                      _handleCreateAudit={_handleCreateAudit}
                      _handleClearAudit={_handleClearAudit}
                      theme={theme}
                      rows={rowsPerUnit}
                      data={currentTableData?.vehicles}
                      type={currentType}
                      _handleSelectVehicle={_handleSelectVehicle}
                      _handleClearAuditImages={_handleClearAuditImages}
                      _handleCreateAuditImages={_handleCreateAuditImages}
                      setPaginationPage={setPaginationPage}
                      loadingForDataEntry={loadingForDataEntry}
                    />
                  </div>

                ) : (
                  <CO2_Loading />
                )}
              </div>

              {showManualEntryHistory === true ? (
                <div className="">
                  {loadingForDataEntry ? (
                    <div className="mt-4" style={cardStyleForManualDataTable}>
                      <div
                        className="d-flex justify-content-between"
                        style={
                          theme === "dark"
                            ? closeButtonStyle
                            : closeButtonStyleBlack
                        }
                      >
                        <div className="me-2">
                          <h5>Consumption for {VData_PlateNO}</h5>
                        </div>
                        <div className="me-2">
                          <i
                            class="bi bi-x"
                            onClick={() => setShowManualEntryHistory(false)}
                          ></i>
                        </div>
                      </div>
                      <div>
                        <ManualEntryHistoryViewJQ
                          showManualEntryHistory={showManualEntryHistory}
                          setManualIndex={setManualIndex}
                          _handleViewManual={_handleViewManual}
                          theme={theme}
                          manual_rows={manual_rows}
                          actionStatus={actionStatus}
                          _handleUpdateActionStatus={_handleUpdateActionStatus}
                        />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}

              {vehicleData?.length > 0 ? (
                <div className="m-1">
                  {" "}
                  <DataEntryEditForm
                    newAudit={newAudit}
                    theme={theme}
                    currentYear={currentYear}
                  />
                </div>
              ) : (
                <></>
              )}
            </span>
          ) : (
            // <div className="text-center" style={{ color: invertDark(theme) }}>
            //     <i
            //         className="fa fa-exclamation-triangle me-2"
            //         style={{ marginTop: "20%", color: Colors.fuel }}
            //     />
            //     No Data Found for {currentYear}

            // </div>
            // <CO2_Loading />
            <span></span>
          )}
          </div>
        </div>
      </div>
    );
  })
);

const typeOptions = [
  {
    value: "ev",
    label: "EV",
  },
  {
    value: "fuel",
    label: "ICE",
  },
];

const yearsOptions = [
  {
    value: "2023",
    label: "2023",
  },
  {
    value: "2022",
    label: "2022",
  },
  {
    value: "2021",
    label: "2021",
  },
  {
    value: "2020",
    label: "2020",
  },
];
