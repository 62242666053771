import { Colors } from "../../Assets/Config/color.config";
import { MyTable } from "../../Tools/MyTable";
import { ContractModal } from "../Tracking";
import { CompanySelector } from "../../Components/Dashboard/companySelector";
import { My_Button } from "../../Tools";
import { useState } from "react";
import { ReportFilter } from "./reportFilter";
import { CreateReportModal } from "./createReportModal";
import { useTranslation } from "react-i18next";

export const TrackAndTrace = (props) => {
    const {t} = useTranslation("track_and_trace")
    const { ttData, 
            theme, 
            singleTTData, 
            _handleViewContract, 
            _handleClose 
        } = props;

    const [createMode, setCreateMode] = useState()
    return (
        <>
            <div
                className="pt-2 table-responsive"
                style={{ color: Colors.Dark[theme === "light" ? 2 : 4] }}
            >
                <h4>{t("Reports/Contract List")}</h4>
                <div className="align-self-center pt-2 d-flex justify-content-between">
                    <CompanySelector theme={theme} label={t("Organisation")+": "} />
                    {/* <My_Button onClick={() => setCreateMode(!createMode)} text={!createMode ?'Create Report':'Cancel'} small /> */}
                </div>
                <MyTable
                    createData={(
                        id,
                        tracking_name,
                        submitter_id,
                        contract_id,
                        type,
                        detail
                    ) => {
                        return { id, tracking_name, submitter_id, contract_id, type, detail };
                    }}
                    Key={[
                        "id",
                        "tracking_name",
                        "submitter_id",
                        "contract_id",
                        "type",
                        "detail",
                    ]}
                    theme={theme}
                    trackTraceData={ttData}
                    header={[
                        { id: "ids", label: "ID" },
                        { id: "tracking_name", label: t("Tracking Name") },
                        { id: "submitter_id", label: t("Submitter ID") },
                        { id: "contract_id", label: t("Contract ID") },
                        { id: "type", label: t("Type") },
                        { id: "detail", label: "" },
                    ]}
                    _handleViewContract={_handleViewContract}
                />
                {createMode && <div className="d-flex justify-content-end"><ReportFilter theme={theme} setCreateMode={setCreateMode}/></div>}
                <ContractModal
                    theme={theme}
                    singleTTData={singleTTData}
                    _handleClose={_handleClose}
                />
                <CreateReportModal/>
            </div>
        </>
    );
};
