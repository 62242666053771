/* eslint-disable jsx-a11y/alt-text */
import { toJS } from "mobx";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { My_Button, My_Input, My_upload_image, Selector } from "../../Tools";
import { inject, observer } from "mobx-react";
import { Checkbox, useForkRef } from "@mui/material";
import { baseURL } from "../../network/URL";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AssignUnitView } from "./AssignUnitView";

export const AssignVehiclesModal = inject("store")(
    observer((props) => {
        const {t} = useTranslation("admin-panel")
        const { theme, user } = props;
      
        const { _setAssignNewVehicleUser } = props.store.adminPanel;
        const {
            selectetCompany,
            selectedUser,
            checkedVehicles,
            selectedVehicle,
            availableVehicle,
            _handleAssgningVehicle,

        } = props.store.dataEntry;
        const default_img = '/cars/default_vehicle.png';
        return (
            <div
                className=""
                id=""
                tabIndex="-1"
                aria-labelledby=""
                aria-hidden="true"
            >
                <div className="">
                    <div
                        className=""
                        style={{
                            background: '',
                            color: invertDark(theme),
                            borderBottomRightRadius: 10,
                            bodderTopRightRadius: 10,
                            height: '100%'
                        }}
                    >
                        {/* <div className="d-flex justify-content-between align-items-center px-1" style={{ background: "#0a58ca", fontWeight: "bold", borderTopRightRadius: 10, height: 48 }}>
                            <h5 className="" style={{ color: "white" }}>{t("Assign Units For")}{" "}
                                {user.find((v) => v.id === selectedUser)?.username}</h5>
                            <span
                                style={{ cursor: "pointer" }}
                                className=""
                                onClick={() => _setAssignNewVehicleUser(false)}
                            >
                                <i className="text-white bi bi-x fa-2x" />
                            </span>
                        </div> */}

                        <AssignUnitView 
                            user = {user}
                            theme = {theme} 
                            default_img={default_img}
                            selectedUser={selectedUser}
                            selectedVehicle={selectedVehicle}
                            availableVehicle={availableVehicle}
                            _handleAssgningVehicle={_handleAssgningVehicle}
                        />

                        {/* <div className="d-flex justify-content-end py-2 px-2" style={{backgroundColor: ''}}>
                            <button type="button" className="btn btn-primary" aria-label="Close" onClick={() => _setAssignNewVehicleUser(false)}>{t("Save changes")}</button>
                        </div> */}

                    </div>
                </div>



            </div>
            // <div>sdfsdf</div>
        );
    }));
