import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { toJS } from "mobx";
import { My_Button } from "../../Tools";
import { useTranslation } from "react-i18next";

export const ImportValidationModal = inject("store")(
  observer((props) => {
    const {t} = useTranslation("data_import")
    const { theme, showTable, errorResponse } = props;

    const { role } = props.store.common;

    const { handleClearErrorResponse, companyName } = props.store.dataImport;

    const [openModal, setOpenModal] = useState(false);

    let cardStyle = {
      color: invertDark(theme),
      background: Colors[theme].background,
      border: "none",
    };

    const handleCloseModal = () => {
      setOpenModal(false);
      handleClearErrorResponse();
    };

    const handleOpenModal = () => {
      setOpenModal(true);
    };

    const errors = toJS(errorResponse) ;

    useEffect(() => {
      if (errors.length > 0) {
        handleOpenModal();
      }
    }, [errors]);


    return (
      <div>
        <Modal show={openModal} onHide={handleCloseModal}>
          <Modal.Header style={cardStyle}>
            <Modal.Title className="text-warning">{t("Data is required")}</Modal.Title>
            <span style={{ cursor: "pointer" }} onClick={handleCloseModal}>
              <i className="bi bi-x-lg" />
            </span>
          </Modal.Header>
          <Modal.Body style={cardStyle}>
            <p className="mb-4">
              {" "}
             {t(" The following fields are required. Please make sure all of the mandatory fields")} (<span style={{ color: "#ffc107" }}>*</span>)
              {t("include in the csv (or) Please check invalid negative value.")}
            </p>
            <ul>
              {errors?.map((error, index) => (
                <li key={index}>
                  <strong>
                    {role === "super_admin" || companyName === 'Volvo' ?
                      <>
                        {showTable === "table4" ? "For License No : " : "For Grouping : "} {""}

                      </>
                      :
                      <>
                        {"For License No : "} {""}
                      </>
                    }

                  </strong>
                  {error.grouping}
                  {error.requireField && error.requireField.length > 0 && (
                    <ol className="ml-4">
                      {error.requireField.map((field, index) => (
                        <li key={index}>{field}</li>
                      ))}
                    </ol>
                  )}
                </li>
              ))}
            </ul>
          </Modal.Body>
          <Modal.Footer style={cardStyle}></Modal.Footer>
        </Modal>
      </div>
    );
  })
);
