import plusIcon from '../../Assets/Images/icons/plusIcon.png'
import ascentLogo from '../../Assets/Images/icons/ascent-logo.png'
import { My_Button, My_Input } from '../../Tools'
import { inject, observer } from 'mobx-react'
import allCountry from '../../Assets/countries.json'
import { Selector } from '../../Tools'
import { toJS } from 'mobx'
import { Colors } from 'chart.js'
import { useState } from 'react'

export const CompanyInfo = inject("store")(
    observer((props) => {

    const { _handleInputChange, _handleCompanyRegister, userData, selectedCountry, setSelectedCountry, editView, _handleUpdateCompany, companyId} = props.store.landing
    const { theme } = props.store.login;


    const CountryOptions = allCountry?.map((country) => ({
        value: country.name,
        label: country.name,
        code: country.code.toLowerCase(),
    }));

    
    

    return (<div className="col-6">
        <div >
            <div style={{ background: theme==='dark'?'#192353':'rgb(0,0,0,0.12)', borderTopRightRadius: 5, borderTopLeftRadius: 5 }}>
                <div className='p-2'>Fill Company Information</div>
            </div>
            <div className="row m-0" style={{background: theme==='dark'?'#192353':'#fff', borderBottomRightRadius: 5, borderBottomLeftRadius: 5, minHeight: 450 }}>
                <div className="col-4 d-flex justify-content-center py-5">
                    <div style={{ border: '1px solid gray', width: '200px', height: '200px', borderRadius: 5 }} className='d-flex align-items-center justify-content-center'>
                        <img width='30px' src={plusIcon} />

                    </div>
                </div>
                <div className="col-8 py-5 pe-5">
                    <div className='d-flex align-items-center pb-3'>
                        <span className='col-4'>
                            Company Name
                        </span>
                        <span className='col'>
                            <My_Input id={"companyName"}
                                value={userData.companyName}
                                placeHolder={"Enter your company name..."}
                                onChange={(e) => _handleInputChange({companyName: e.target.value})} 
                            />
                        </span>
                    </div>

                    <div className='d-flex align-items-center pb-3'>
                        <span className='col-4'>
                            Country
                        </span>
                        <span className='col-8'>
                           <Selector options={CountryOptions} value={CountryOptions?.find(v => v.value === selectedCountry )}  menuListColor='black' _handleSelect={(e) => {_handleInputChange({country: e.value})
                        setSelectedCountry(e.value)}}  />
                        </span>
                    </div>
                    <div className='d-flex align-items-center pb-3'>
                        <span className='col-4'>

                        </span>
                        <span className='col'>
                            {editView === true ? <My_Button customColor={theme === 'dark'?'':'#04c600'} customHoverColor={theme === 'dark'?'':'#05e700'} className='col-12' text='Update' onClick={() => _handleUpdateCompany(companyId)}/> : <My_Button customColor={theme === 'dark'?'':'#04c600'} customHoverColor={theme === 'dark'?'':'#05e700'} className='col-12' text='Create' onClick={() => _handleCompanyRegister()}/>}
                        </span>
                    </div>
                </div>

            </div>
        </div>
    </div>)
}))
