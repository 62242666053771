import $, { data } from "jquery";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { useRef, useEffect, useState } from "react";
import React from "react";

import { toJS } from "mobx";
import { CO2_Loading } from "../../Assets/CO2_Loading";
import { Submit_Loading } from "../../Assets/Submit_Loading";
import { useTranslation } from "react-i18next";

export const ManualEntryTable = inject("store")(
  observer((props) => {
    const { t } = useTranslation("data_import");
    const {
      theme,
      _handleViewContract,
      noPagination,
      manualEntryData,
      filenotexist,
    } = props;
    // console.log("manuaEntryData ====>", toJS(manualEntryData));
    const { SubmitLoading, loading } = props.store.dataImport;

    const rows = manualEntryData?.map((v, i) => {
      let manualEntry = {
        date: v.date,
        grouping: v.grouping,
        captured_date: v.captured_date,
        consumed: v.consumed,
        trip_mileage: v.trip_mileage,
        error: v.error,
      };

      return manualEntry;
    });

    const tableRef = useRef(null);


    console.log("manual entry length ====>",rows.length)

    useEffect(() => {
      const styleElement = document.createElement("style");
      styleElement.innerHTML =
        theme === "dark"
          ? `

          .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
            color: black !important;
            border: 1px solid #e5e5e5!important;
            
            background-color: #e5e5e5 !important;
            /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #e5e5e5), color-stop(100%, #e5e5e5))!important; */
            background: -webkit-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
            background: -moz-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
            background: -ms-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%) !important;
            background: -o-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
            background: linear-gradient(to bottom, #e5e5e5 0%, #e5e5e5 100%)!important;
          }

          .dataTables_wrapper .dataTables_paginate .paginate_button.current {
            color: black !important;
            border: 1px solid #C6C6C6!important;
            
            background-color: #C6C6C6 !important;
            /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fffcfc), color-stop(100%, #969292))!important; */
            background: -webkit-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
            background: -moz-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
            background: -ms-linear-gradient(top, #fffcfc 0%, #969292 100%) !important;
            background: -o-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
            background: linear-gradient(to bottom, #fffcfc 0%, #969292 100%)!important;
          }

          .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
            color: black !important;
            border: 1px solid #C6C6C6!important;
            
            background-color: #C6C6C6 !important;
            /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fffcfc), color-stop(100%, #969292))!important; */
            background: -webkit-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
            background: -moz-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
            background: -ms-linear-gradient(top, #fffcfc 0%, #969292 100%) !important;
            background: -o-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
            background: linear-gradient(to bottom, #fffcfc 0%, #969292 100%)!important;
          }


        `
          : `
            .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
                color: black !important;
                border: 1px solid #e5e5e5!important;
                
                background-color: #e5e5e5 !important;
                /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #e5e5e5), color-stop(100%, #e5e5e5))!important; */
                background: -webkit-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
                background: -moz-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
                background: -ms-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%) !important;
                background: -o-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
                background: linear-gradient(to bottom, #e5e5e5 0%, #e5e5e5 100%)!important;
              }

              .dataTables_wrapper .dataTables_paginate .paginate_button.current {
                color: black !important;
                border: 1px solid #C6C6C6!important;
                
                background-color: #C6C6C6 !important;
                /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fffcfc), color-stop(100%, #969292))!important; */
                background: -webkit-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                background: -moz-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                background: -ms-linear-gradient(top, #fffcfc 0%, #969292 100%) !important;
                background: -o-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                background: linear-gradient(to bottom, #fffcfc 0%, #969292 100%)!important;
              }
   
        `;
      document.head.appendChild(styleElement);

      function updateOrderAndDisplayStart(columnIndex, direction) {
        orderColumn = columnIndex;
        orderDirection = direction;

        table.order([[orderColumn, orderDirection]]).draw();
      }

      const storedOrderColumn = localStorage.getItem("orderColumn");
      const storedOrderDirection = localStorage.getItem("orderDirection");

      let orderColumn = storedOrderColumn ? parseInt(storedOrderColumn) : 0;
      let orderDirection = storedOrderDirection || "asc";
      const tableElement = $(tableRef.current);

      const table = tableElement.DataTable({
        autoWidth: true,
        responsive: true,
        retrieve: true,
        scrollX: true,
        order: [[1, "asc"]],

        lengthMenu: [8, 10, 15],

        data: rows ,
        columns: [
          { title: t("Date"), data: "date" },
          { title: t("Plate Number"), data: "grouping" },
          { title: t("Captured Date"), data: "captured_date" },
          { title: t("Consumed"), data: "consumed" },
          { title: t("Trip Mileage") + "(km)", data: "trip_mileage" },
          { title: t("Error"), data: "error" },
        ],
        columnDefs: [
          {
            targets: [0, 1, 2, 3, 4, 5],
            render: function (data, type, row) {
              return row.error !== null
                ? '<span style="color: red;">' + data + "</span>"
                : data;
            },
          },
        ],
        paging: true,

        drawCallback: function (settings) {
          // console.log($('# ').DataTable().page.info());
          // console.log(settings.oSavedState);
        },
        language: {
          emptyTable:
          // rows == [] &&
               `<div class="no-data-found" style="color: ${invertDark(
                  theme
                )}; align-items: center; justify-content: center;">
                  <i class="fa fa-exclamation-triangle me-2" style="color: orange;"></i>
                  ${t("File Not Exist!")}
                </div>`
              ,
        },
      });

      table.column(0).nodes()?.to$().css("width", "14%");
      table.column(1).nodes()?.to$().css("width", "14%");
      table.column(2).nodes()?.to$().css("width", "14%");
      table.column(3).nodes()?.to$().css("width", "14%");
      table.column(4).nodes()?.to$().css("width", "14%");
      table.column(5).nodes()?.to$().css("width", "14%");

      table.cells().every(function () {
        $(this.node()).css("border-color", "");
      });

      const searchInput = $(".dataTables_filter input");
      searchInput.css("color", theme === "light" ? "black" : "white");

      const tableRows = $(".dataTables_wrapper tr");
      const tableColumns = $(".dataTables_wrapper td");

      tableRows.css("color", theme === "light" ? "black" : "#fff");
      tableColumns.css("color", theme === "light" ? "black" : "#fff");

      const searchlabel = $(".dataTables_filter label");
      searchlabel.css("color", theme === "light" ? "black" : "white");

      table.cells().every(function () {
        $(this.node()).css(
          "border-color",
          theme === "dark" ? "rgb(211, 211, 211, 0.3)" : ""
        );
      });

      const entriesSelect = $(".dataTables_length select");
      entriesSelect.css(
        "background-color",
        theme === "light" ? "#fff" : "#202b60"
      );
      entriesSelect.css("color", theme === "light" ? "black" : "white");

      const entriesLabel = $(".dataTables_length label");
      entriesLabel.css("color", theme === "light" ? "black" : "white");

      const tableInfo = $(".dataTables_info");
      tableInfo.css("color", theme === "light" ? "#000" : "#fff");

      const paginate = $("#manualEntryTable_paginate");
      paginate.css("color", theme === "light" ? "#000" : "#fff");

      const tableEmpty = $("#manualEntryTable");
      tableEmpty.css("color",theme === "light" ? "#000" : "#fff")
      

      $("#manualEntryTable").on("draw.dt", function () {
        const currentPage = table.page.info().page;
        const rows = table.rows({ page: "current" }).nodes();
        const cells = $(rows).find("td");

        cells.css("color", theme === "light" ? "#000" : "#fff");
      }); 

      $("#manualEntryTable").on("page.dt", function () {
        localStorage.setItem("currentPage", table.page.info().page);
      });

      const storedCurrentPage = localStorage.getItem("currentPage");
      const storedSearch = localStorage.getItem("search");

      const currentPage = storedCurrentPage ? parseInt(storedCurrentPage) : 0;
      table.page(currentPage).draw(false);

      if (storedSearch) {
        table.search(storedSearch).draw();
      }

      table.on("page.dt", function () {
        localStorage.setItem("currentPage", table.page.info().page);
      });

      table.on("search.dt", function () {
        const search = table.search();
        localStorage.setItem("search", search);
      });

 
      window.addEventListener("beforeunload", function () {
        localStorage.removeItem("orderColumn");
        localStorage.removeItem("orderDirection");
        localStorage.removeItem("currentPage");
      });

      window.addEventListener("load", function () {
        localStorage.removeItem("orderColumn");
        localStorage.removeItem("orderDirection");
        localStorage.removeItem("currentPage");
      });

      const storedPage = localStorage.getItem("currentPage");

      if (storedPage !== null) {
        const currentPage = parseInt(storedPage);
        if (!isNaN(currentPage)) {
          table.page(currentPage).draw("page");
        } else {
          table.page(0).draw("page");
        }
      } else {
        table.page(0).draw("page");
      }

      return () => {
        if ($.fn.DataTable.isDataTable("#manualEntryTable")) {
          table.destroy();
        }
      };
    }, [theme, rows]);

    return (
      <>
       {
          // rows?.length === 0
          loading ? (
            <CO2_Loading />
          ) : (
            <div>
              <table
                ref={tableRef}
                id="manualEntryTable"
                className="display cell-border hover stripe"
                style={{ width: "100%" }}
              ></table>
            </div>
          )
        }
      </>
    );
  })
);
