import { inject, observer } from 'mobx-react'
import { Colors, invertDark } from '../../Assets/Config/color.config'
import { CompanySelector } from '../../Components/Dashboard/companySelector'
import { ComparisonChart } from './ComparisonChart'
import { InfoCard } from './InfoCard'
import carImage from './images/car.png'
import usersImage from './images/users.png'
import reportImage from './images/report.png'
import clockImage from './images/clock.png'
import { useEffect } from 'react'
import { useTranslation } from "react-i18next";

export const LiteDashboardContainer = inject("store")(
    observer((props) => {
        const {t} = useTranslation("lite-dashboard")
        const { theme } = props.store.login
        const { loginTime } = props.store.common;
        const { fetchDashboardData, dashboardData} = props.store.liteEntry;
        const {_handleFetchCompanyYears} = props.store.dashboard;

        useEffect(() => {
            fetchDashboardData()
            _handleFetchCompanyYears()
        }, [])

        const petrolVehicleCount = dashboardData.Petrol ? dashboardData.Petrol.vehicleCount : 0
        const dieselVehicleCount = dashboardData.Diesel ? dashboardData.Diesel.vehicleCount : 0
        const batteryVehicleCount = dashboardData.Electricity ? dashboardData.Electricity.vehicleCount : 0
        return (
            <div style={{ color: invertDark(theme) }}>
                <span
                    style={{
                        fontSize: 23,
                        color: theme === "dark" ? Colors["light"].background : Colors.Dark[2],
                    }}
                >
                    {t("CO2X Lite Dashboard")}
                </span>
                <CompanySelector theme={theme} label={t("Organisation")+": "} />
                <div className='row'>
                    <span className='col-12 col-sm-6 col-6 col-md-6 col-lg-3 col-xl-3 col-xs-3'>
                        <InfoCard image={carImage} label={t('Number of vehicle declared')} value={dashboardData.vehicleCount} theme={theme} />
                    </span><span className='col-12 col-sm-6 col-6 col-md-6 col-lg-3 col-xl-3 col-xs-3'>
                        <InfoCard image={usersImage} label={t('Number of user')} value={dashboardData.userCount} theme={theme} />
                    </span><span className='col-12 col-sm-6 col-6 col-md-6 col-lg-3 col-xl-3 col-xs-3'>
                        <InfoCard image={reportImage} label={t('Number of report generated')} value={dashboardData.downloadReportCount} theme={theme} />
                    </span><span className='col-12 col-sm-6 col-6 col-md-6 col-lg-3 col-xl-3 col-xs-3'>
                        <InfoCard image={clockImage} label={t('Login at')} smallFont value={loginTime} theme={theme} />
                    </span>
                </div>
                <div className='row mt-4'>
                    <span className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xs-6'>
                        <ComparisonChart
                            dashboardData={dashboardData}
                            theme={theme}
                            bgColor={[
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 159, 64, 0.2)',
                            ]}
                            bdColor={[
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 159, 64, 1)',
                            ]}
                            label={t('Proportion of ICE vs EV Units')} valueData={[dieselVehicleCount + petrolVehicleCount, batteryVehicleCount]} labels={['ICE', 'EV']} />
                    </span>
                    <span className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xs-6'>
                        <ComparisonChart
                            dashboardData={dashboardData}
                            theme={theme}
                            bgColor={[
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(255, 159, 64, 0.2)',
                            ]}
                            bdColor={[
                                'rgba(54, 162, 235, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(255, 159, 64, 1)',
                            ]}
                            label={t('Proportion of Units by way of Fuel type')} valueData={[petrolVehicleCount, dieselVehicleCount, batteryVehicleCount]} labels={['Petrol', 'Diesel', 'EV']} />
                    </span>
                </div>

            </div>

        )
    }
    ))