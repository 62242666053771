import { Colors } from "../../Assets/Config/color.config";
import { inject, observer } from "mobx-react";
import { VehicleDetailImageFMS } from "./vehicleDetailImage";
import { toJS } from "mobx";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { CarbonEmissionManualChart } from "./CarbonEmissionManualChart";

export const ManualModalSection = inject("store")(
  observer((props) => {
    const { t } = useTranslation("new-tracking");
    const { manualRef, companyName, months, emission, assetProfile } = props;
    const { theme } = props.store.common;
    const {
      // assetProfile,
      year,
      selectedVehicle,
      vehicleType,
      vehicleOptions,
      month,
      dateFormat,
      yearSelected,
      startDateFormat,
      endDateFormat,
    } = props.store.trackingVTwo;
    const { isAds } = props.store.common;

    return (
      <div ref={manualRef} className="p-5">
        <span style={{ fontSize: 20, fontWeight: "bold" }}>{companyName}</span>
        {/* <span style={{ fontSize: 20, fontWeight:'bold' }}>
              Emission Report for 2022 {months[month - 1]}
            </span> */}
        <div className="d-flex justify-content-between pt-5 pb-2">
          <div style={{ color: Colors.Dark[0], fontSize: 14 }} className="row">
            <span style={{ fontWeight: "bold" }} className="col-6 text-start">
              {t("Vehicle Plate Number")}
            </span>
            <span className="col-6 text-start">: {selectedVehicle?.label}</span>
            <span style={{ fontWeight: "bold" }} className="col-6 text-start">
              {t("Vehicle Type")}
            </span>
            <span className="col-6 text-start">
              : {vehicleType?.label || "--"}
            </span>
            <span style={{ fontWeight: "bold" }} className="col-6 text-start">
              {t("Brand")}
            </span>
            <span className="col-6 text-start">
              : {assetProfile?.brand || "--"}
            </span>
            <span style={{ fontWeight: "bold" }} className="col-6 text-start">
              {t("Model")}
            </span>
            <span className="col-6 text-start">
              : {assetProfile?.model || "--"}
            </span>
            <span style={{ fontWeight: "bold" }} className="col-6 text-start">
              {t("Total Baseline Emission")}
            </span>
            <span className="col-6 text-start">
              :{" "}
              {(`${assetProfile?.manual?.totalBaselineEmission}` || "--") +
                " kgCO₂e"}
            </span>
            <span style={{ fontWeight: "bold" }} className="col-6 text-start">
              {t("Total Trip Mileage")}
            </span>
            <span className="col-6 text-start">
              : {assetProfile?.manual?.vehicleMileage + " km" || "--"}
            </span>

            <span style={{ fontWeight: "bold" }} className="col-6 text-start">
              {t("Total Consumption")}
            </span>
            <span className="col-6 text-start">
              : {""}
              {assetProfile?.manual?.totalConsumed == null
                ? "--"
                : Number(assetProfile?.manual?.totalConsumed).toLocaleString(
                    "en-US"
                  )}
              {vehicleType?.label === "EV" ? " kWh" : " L"}
            </span>
            <span style={{ fontWeight: "bold" }} className="col-6 text-start">
              {t("Baseline Emission Factor")} (gCO₂/km)
            </span>
            <span className="col-6 text-start">
              : {assetProfile?.manualBaselineEmissionPerKm + " gCO₂/km" || "--"}
            </span>
          </div>
          <div className="col-6 mb-3" style={{ background: "rgb(0,0,0,0.04)" }}>
            <div className="p-2">
              <VehicleDetailImageFMS
                text={
                  selectedVehicle
                    ? selectedVehicle?.label
                    : vehicleOptions[0]?.label
                }
                isAds={isAds}
                asset_name={
                  selectedVehicle
                    ? selectedVehicle?.label
                    : vehicleOptions[0]?.label
                }
                image={
                  selectedVehicle
                    ? selectedVehicle?.image
                    : selectedVehicle?.[0]?.image
                }
              />
              <div>{selectedVehicle?.label}</div>
            </div>
          </div>
        </div>
        <div className="text-start fw-bold pt-2 text-black">
          {t("Manual Report ")}{" "}
          {dateFormat === "Month"
            ? `for ${dayjs()
                .month(month - 1)
                .format("MMMM")}-${yearSelected}`
            : dateFormat === "Year"
            ? `for ${yearSelected}`
            : dateFormat === "7 Days" || dateFormat === "Custom"
            ? `from  ${startDateFormat} to ${endDateFormat}`
            : ""}
        </div>
        <div className="my-3">
          <div className="p-2">
            <table
              style={{
                width: "100%",
                border: `1px solid ${Colors.Dark[4]}`,
              }}
            >
              <tr>
                <th
                  className="text-start ps-2"
                  style={{ borderRight: `1px solid ${Colors.Dark[4]}` }}
                >
                  {dateFormat === "Month" ||
                  dateFormat === "7 Days" ||
                  dateFormat === "Custom"
                    ? "Date"
                    : dateFormat === "Year"
                    ? "Month"
                    : ""}
                </th>
                <th style={{ borderRight: `1px solid ${Colors.Dark[4]}` }}>
                  {t("Emission Factor")} (gCO₂/km)
                </th>
                <th style={{ borderRight: `1px solid ${Colors.Dark[4]}` }}>
                  {t("Carbon Emission")} (kgCO₂e)
                </th>
                <th>{t("Baseline Emission (kgCO₂e)")}</th>
              </tr>
              {assetProfile?.manual?.carbonEmission?.map((v) => (
                <tr style={{ borderTop: `1px solid ${Colors.Dark[4]}` }}>
                  <th
                    className="text-start ps-2"
                    style={{
                      borderRight: `1px solid ${Colors.Dark[4]}`,
                    }}
                  >
                    {dateFormat === "Month" ||
                    dateFormat === "7 Days" ||
                    dateFormat === "Custom"
                      ? v.days_months
                      : dateFormat === "Year"
                      ? months[v.month - 1]
                      : ""}
                  </th>
                  <td
                    style={{
                      borderRight: `1px solid ${Colors.Dark[4]}`,
                    }}
                  >
                    {v.emissionFactor
                      ? v.emissionFactor.toFixed(2)
                      : v.emissionFactor === null
                      ? "0.00"
                      : "0.00"}
                  </td>
                  <td
                    style={{
                      borderRight: `1px solid ${Colors.Dark[4]}`,
                    }}
                  >
                    {v.totalEmission.toFixed(4)}
                  </td>
                  <td>{v.totalBaselineEmission}</td>
                </tr>
              ))}
            </table>
          </div>
        </div>
        <div className="mt-3" style={{ background: "rgb(0,0,0,0.04)" }}>
          <div className="p-2">
            {t("Manual Emission Chart for")} {year}
            <div
              style={{ fontSize: 12 }}
              className="d-flex justify-content-between px-4 pb-2"
            >
              <span>(kgCO₂e)</span>
              <span>(gCO₂e/km)</span>
            </div>
            {assetProfile?.manual?.carbonEmission?.length > 0 ? (
              <CarbonEmissionManualChart
                showChartView="Manual"
                pdf={true}
                theme={theme}
              />
            ) : (
              t("No Data")
            )}
          </div>
        </div>
      </div>
    );
  })
);
