import {
  action,
  autorun,
  makeObservable,
  observable,
  runInAction,
  toJS,
} from "mobx";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import moment from "moment";
import Common from "./common";

import {

  getVTripsData,
  getVTripsFileData,
  getVEngineHourData,
  getVProfilesData,
  getManualEntryData,
  createVprofileData,
  createManualEntryData,
  manualEntryCreateNewData,
  manualEntryCreateOverwriteData,
  createVTripsData,
  createVEngineHoursData,
  clearImportData,
  getFileImportHistory,
  downloadImportedFile,
  importVprofileCSV,
  importManualEntryCSV,
  importVEngineHourCsv,
  importVTripCSV,
  fetchGetExtractManualFile,
  storeExtractManualData,
  storeVTripsFile,
  storeUnitProfiles,
  getUnitProfilesData,
  downloadRefFile,
  deleteManualEntryFileData
} from "../network/fileImportAPI";
import { fetchCompanyYears } from "../network/fetchDashboard";
import jwtDecode from "jwt-decode";
import { getManualEntryAPI } from "../network/URL";
// import {moment} from "moment-timezone"

class DataImport {
  cookies = new Cookies();
  token = this.cookies.get("accessToken");
  companyId = this.cookies.get("company");
  companyName = this.cookies.get("companyName");
  theme = localStorage.getItem("theme");
  showTable = "table1";
  storeTable = "";
  vTripsData = [];
  vEngineHourData = [];
  vEngineHourAcceptData=[]
  manualEntryData = [];
  manualEntryNewData = [];
  vProfilesData = [];
  vTripHeaders = [];
  vVehicleProfile = [];
  vEngineHours = [];
  manualEntry = [];
  importedVprofileData = [];
  importUnitProfileHistory = [];
  importTripHistory = [];
  importEngineHours = [];
  importManualEntry = [];
  vehicles = [];
  vehicleExist = [];
  fileuploadformdata = null;
  companyyearlydata = [];
  errorResponse = [];
  currentYear = 0;
  currentMonth = 0;
  dashboardYears = [];
  year = moment().year();
  month = moment().month() + 1;
  uploadInfo = { csv: "", fileimportDate: "" };
  file = "";
  DataImports = null;
  loading = false;
  Loading = false;
  SubmitLoading = false;
  fileimportDate = "";
  allmonths = "0";
  currentYear = new Date().getFullYear();
  activeTable = "table1";
  fileUploadCompleted = false;
  filenotexist = false;
  showconfirmsubmitmodal = null;
  disable = true;
  dataAlreadyExist = false;
  stateClear = "";
  creatingLoading = false;
  isJobInProgress = false;
  manualEntryExistVehicle = [];
  unAuthorizeVehicles = {}
  vTripsAcceptData = ""
  unitProfilesAcceptData = ""
  refFileObj=""
  isCheck= false

  constructor() {
    makeObservable(this, {
      isCheck:observable,
      refFileObj:observable,
      DataImports: observable,
      Loading: observable,
      loading: observable,
      theme: observable,
      showTable: observable,
      storeTable: observable,
      vTripsData: observable,
      vEngineHourData: observable,
      vProfilesData: observable,
      manualEntryData: observable,
      manualEntryNewData: observable,
      vTripHeaders: observable,
      vVehicleProfile: observable,
      vEngineHours: observable,
      vEngineHourAcceptData:observable,
      manualEntry: observable,
      importedVprofileData: observable,
      companyName: observable,
      importUnitProfileHistory: observable,
      importTripHistory: observable,
      importEngineHours: observable,
      importManualEntry: observable,
      fileuploadformdata: observable,
      vehicles: observable,
      dashboardYears: observable,
      // importedFile: observable,
      companyyearlydata: observable,
      errorResponse: observable,
      fileimportDate: observable,
      uploadInfo: observable,
      file: observable,
      allmonths: observable,
      currentYear: observable,
      activeTable: observable,
      fileUploadCompleted: observable,
      filenotexist: observable,
      showconfirmsubmitmodal: observable,
      disable: observable,
      vehicleExist: observable,
      dataAlreadyExist: observable,
      stateClear: observable,
      SubmitLoading: observable,
      creatingLoading: observable,
      isJobInProgress: observable,
      manualEntryExistVehicle: observable,
      unAuthorizeVehicles: observable,
      vTripsAcceptData: observable,
      unitProfilesAcceptData: observable,

      _handleCheckBox: action.bound,
      _handleClearCheckBox:action.bound,
      handleStoreUnitProfilesData: action.bound,
      handleGetUnitProfilesData: action.bound,
      handleStoreVTripsFile: action.bound,
      handleGetVTripsFileData: action.bound,
      handleTableChange: action.bound,
      handleGetTableNumber: action.bound,
      handleImportVTripCSV: action.bound,
      handleGetVTripsData: action.bound,
      handleTripCsvExport: action.bound,
      handleImportVEngineHourCSV: action.bound,
      handleGetVEngineHourData: action.bound,
      // handleGetVProfilesData: action.bound,
      handleImportVProfilesCSV: action.bound,
      handleImportManualEntyDataCSV: action.bound,
      handleGetManualEntryData: action.bound,
      handleVehicleCsvExport: action.bound,
      handleEngineHoursExport: action.bound,
      handleManualEntryExport: action.bound,
      handlegetImportedVProfileData: action.bound,
      handleCreateVTrip: action.bound,
      handleCreateEngineHour: action.bound,
      handleCreateVProfile: action.bound,
      handleCreateManualEntry: action.bound,
      handleClearImports: action.bound,
      handleGetImportUnitsHistory: action.bound,
      handleGetImportTripsHistory: action.bound,
      handleGetImportEngineHour: action.bound,
      handleGetImportManualEntry: action.bound,
      handleDownloadCSV: action.bound,
      handleFileUploadCSV: action.bound,
      handleCloseModel: action.bound,
      handleClearVehicles: action.bound,
      _handleFetchCompanyYears: action.bound,
      setCurrentMonth: action.bound,
      setCurrentYear: action.bound,
      _handleQueryParam: action.bound,
      handleYearSelect: action.bound,
      handleMonthSelect: action.bound,
      handleClearErrorResponse: action.bound,
      handleGetImportDate: action.bound,
      handleFileCaptureDate: action.bound,
      handleTableChange: action.bound,
      handleGetTableNumberOtherRoute: action.bound,
      handleshowconfirmsubmitmodal: action.bound,
      handleCreateManualEntryForOverWrite: action.bound,
      handleClearManualEntryAlreadyExist: action.bound,
      handleSetSubmitLoading: action.bound,
      handleSetCreateLoading: action.bound,
      handleClearFileImportDate: action.bound,
      handleClearUnauthorizeVehicle: action.bound,
      handleSetLoading: action.bound,
      setRefFileObj: action.bound,
      handleDownloadRefFile:action.bound,
      handleDeleteManualEntryData: action.bound
    });
  }
  _handleCroppedImage = () => { };

  _handleCheckBox = () => {
    this.isCheck = !this.isCheck;
  };

  _handleClearCheckBox = () => {
    this.isCheck = false
  }

  handleSetLoading = (value) => {
    runInAction(() => {
      this.loading = value;
    });
  };
  setRefFileObj= (value) => {
    runInAction(() => {
      this.refFileObj = value;
    });
  };

  handleSetSubmitLoading = (loading) => {
    // runInAction(() => {
    this.SubmitLoading = loading;
    // });
  };

  handleSetCreateLoading = (loading) => {
    runInAction(() => {
      this.creatingLoading = loading;
    });
  };

  setCurrentMonth = async (e, token, companyId) => {
    this.totalFuelData = undefined;
    this.setLoading(true);
    this.currentMonth = parseInt(e);
    // await //  this._handleFetchCalculatedDashboardData();
    this.setLoading(false);
  };

  handleClearUnauthorizeVehicle = async () => {
    runInAction(() => {
      this.unAuthorizeVehicles = {}
    })
  }

  setCurrentYear = async (e, token, companyId) => {
    this.totalFuelData = undefined;
    this.setLoading(true);
    // const year = parseInt(e.target.innerHTML);
    const year = parseInt(e);
    this.currentYear = year;
    if (year === 0) {
      this.currentMonth = 0;
    }
    // await // this._handleFetchCalculatedDashboardData();
    this.setLoading(false);
  };

  handleTableChange(table) {
    const domain = window.location.hostname;
    runInAction(() => {
      this.showTable = table;
      this.cookies.set("storedTable", table, {
        path: "/",
        domain: domain,
      });
      // this.stateClear = "";
    });
  }

  handleGetTableNumber() {
    this.storeTable = this.cookies.get("storedTable");

    if (this.storeTable) {
      this.showTable = this.storeTable;
    }
  }

  handleGetTableNumberOtherRoute() {
    this.showTable = "table1";
  }

  handleGetImportUnitsHistory() {
    this.handleSetLoading(true);
    getFileImportHistory(
      Common.token,
      this.companyId,
      this.year,
      this.month,
      "unit_profiles",
      (err, data) => {
        if (data?.message === "Token Expired!") {
          // toast.info(data.message);
          window.location.pathnam = "/";
        } else if (err) {
          toast.error(err);
        } else {
          runInAction(() => {
            this.importUnitProfileHistory = data;
            this.handleSetLoading(false);
          });
        }
      }
    );
  }

  // handleGetImportUnitsHistory() {
  //   runInAction(() => {
  //     this.importUnitProfileHistory = [];
  //   });

  //   const monthValue = this.month === 0 ? null : this.month; // Convert 0 to null for "All" option

  //   getFileImportHistory(
  //     Common.token,
  //     this.companyId,
  //     this.year,
  //     monthValue,
  //     "unit_profiles",
  //     (err, data) => {
  //       if (err) {
  //         toast.error(err.message);
  //         window.location.reload();
  //       } else {
  //         runInAction(() => {
  //           this.importUnitProfileHistory = data;
  //         });
  //       }
  //     }
  //   );
  // }

  handleGetImportTripsHistory() {
    this.handleSetLoading(true);
    getFileImportHistory(
      Common.token,
      this.companyId,
      this.year,
      this.month,
      "trips",
      (err, data) => {
        if (data?.message === "Token Expired!") {
          // toast.info(data.message);
          window.location.pathnam = "/";
        } else if (err) {
          toast.error(err);
        } else {
          runInAction(() => {
            this.importTripHistory = data;
            this.handleSetLoading(false);
          });
        }
      }
    );
  }

  handleGetImportEngineHour() {
    this.handleSetLoading(true);
    getFileImportHistory(
      Common.token,
      this.companyId,
      this.year,
      this.month,
      "engine_hours",
      (err, data) => {
        // console.log("vEngineHourData =====>", toJS(data))
        if (data?.message === "Token Expired!") {
          // toast.info(data.message);
          window.location.pathnam = "/";
        }
        // else if(data == []){
        //   this.importEngineHours = null;
        // }
        else if (err) {
          toast.error(err);
        } else {
          runInAction(() => {
            this.importEngineHours = data;
            this.handleSetLoading(false);
          });
        }
      }
    );
  }

  handleGetImportManualEntry() {
    this.handleSetLoading(true);
    getFileImportHistory(
      Common.token,
      this.companyId,
      this.year,
      this.month,
      "manual_entries",
      (err, data) => {
        if (data?.message === "Token Expired!") {
          // toast.info(data.message);
          window.location.pathnam = "/";
        } else if (err) {
          toast.error(err);
        } else {
          runInAction(() => {
            this.importManualEntry = data;
            this.handleSetLoading(false)
          });
        }
      }
    );
  }

  handleGetImportDate(category) { }

  handleDownloadCSV(fileName,uploadedFileName) {
    downloadImportedFile(Common.token, fileName,uploadedFileName, (err, data) => {
      console.log("fileName --->",fileName)
      if (data?.message === "Token Expired!") {
        window.location.pathname = "/";
      } else if (err) {
        if (err.message === "File not exist") {
          toast.info(err.message);
        } else {
          toast.error(err.message);
        }
      } else {
        toast.success(data?.message);
      }
    });
  }

  handleDownloadRefFile(refFileName) {
    downloadRefFile(Common.token, refFileName, (err, data) => {
      console.log("fileName --->",refFileName)
      if (data?.message === "Token Expired!") {
        window.location.pathname = "/";
      } else if (err) {
        if (err.message === "File not exist") {
          toast.info(err.message);
        } else {
          toast.error(err.message);
        }
      } else {
        toast.success(data?.message);
      }
    });
  }

  handleFileUploadCSV() {
    var csvmodal = document.getElementById("#uploadCSVFileModal");
    // var modal = document.getElementsByClassName('modal fade')[0];
    // var submitButton = document.getElementById('submitModal');
    // console.log('modal ====>', modal);
    const decode = jwtDecode(this.token);
    if (decode.role === "super_admin" || this.companyName === "Volvo" ) {
      if (this.showTable === "table1") {
        const formData = new FormData();
        formData.append("companyId", this.companyId);
        formData.append("orgFile", this.uploadInfo.csv);
        if (this.refFileObj) {
          formData.append("refFile", this.refFileObj);
        }
        // formData.append("startedUsingDate", this.uploadInfo.fileimportDate);

        importVprofileCSV(Common.token, formData, (err, data) => {
          if (data?.message === "Token Expired!") {
            window.location.pathname = "/";
            toast.info(data?.message);
          } else if (data?.error) {
            toast.warning(data?.error);
          } else if (err) {
            toast.error(err?.message);
          } else {
            toast.success(data?.message);
            this.handleGetImportUnitsHistory();
          }
        });
      } else if (this.showTable === "table2") {
        const formData = new FormData();
        formData.append("companyId", this.companyId);
        formData.append("orgFile", this.uploadInfo.csv);
        if (this.refFileObj) {
          formData.append("refFile", this.refFileObj);
        }
        importVTripCSV(Common.token, formData, (err, data) => {
          if (data?.message === "Token Expired!") {
            toast.info(data?.message);
          } else if (data?.error) {
            toast.warning(data?.error);
          } else if (err) {
            toast.error(err?.message);
          } else {
            toast.success(data?.message);
            this.showconfirmsubmitmodal = false;
            // submitButton.onclick = function() {
            //   modal.style.display = 'none';
            // }
            this.handleGetImportTripsHistory();
          }
        });
      } else if (this.showTable === "table3") {
        const formData = new FormData();
        formData.append("companyId", this.companyId);
        formData.append("orgFile", this.uploadInfo.csv);
        if (this.refFileObj) {
          formData.append("refFile", this.refFileObj);
        }
        importVEngineHourCsv(Common.token, formData, (err, data) => {
          if (data?.message === "Token Expired!") {
            toast.info(data?.message);
          } else if (data?.error) {
            toast.warning(data?.error);
          } else if (err) {
            toast.error(err?.message);
          } else {
            toast.success(data?.message);
            this.showconfirmsubmitmodal = false;
            // submitButton['data-bs-dismiss'] = 'modal'
            // submitButton['data-bs-target'] = '#uploadCSVFileModal'
            this.handleGetImportEngineHour();
          }
        });
      } else {
        const formData = new FormData();
        formData.append("companyId", this.companyId);
        formData.append("orgFile", this.uploadInfo.csv);
        if (this.refFileObj) {
          formData.append("refFile", this.refFileObj);
        }
        // console.log("reffffffff===>",this.refFileObj)
        importManualEntryCSV(Common.token, formData, (err, data) => {
          if (data?.message === "Token Expired!") {
            toast.info(data?.message);
          } else if (data?.error) {
            toast.warning(data?.error);
          } else if (err) {
            toast.error(err?.message);
          } else {
            toast.success(data?.message);
            this.showconfirmsubmitmodal = false;
            // submitButton.onclick = function() {
            //   modal.style.display = 'none';
            // }
            this.handleGetImportManualEntry();
          }
        });
      }
    } else {
      const formData = new FormData();
      formData.append("companyId", this.companyId);
      formData.append("orgFile", this.uploadInfo.csv);
      if (this.refFileObj) {
        formData.append("refFile", this.refFileObj);
      }
      // console.log("reffffffff===>",this.refFileObj)
      importManualEntryCSV(Common.token, formData, (err, data) => {
        if (data?.message === "Token Expired!") {
          toast.info(data?.message);
        } else if (data?.error) {
          toast.warning(data?.error);
        } else if (err) {
          toast.error(err?.message);
        } else {
          toast.success(data?.message);
          this.showconfirmsubmitmodal = false;
          // submitButton.onclick = function() {
          //   modal.style.display = 'none';
          // }
          this.handleGetImportManualEntry();
        }
      });
    }
  }

  handleFileCaptureDate = (value) => {
    runInAction(() => {
      this.uploadInfo.fileimportDate = "";
      this.uploadInfo.fileimportDate = value;
    });
  };

  handleCloseModel = () => {
    this.fileuploadformdata = {};
  };

  handleImportVProfilesCSV(e) {
    runInAction(() => {
      this.uploadInfo.csv = "";
    });
    let file = e.target?.files[0];
    this.uploadInfo.csv = file;
  }

  handleImportManualEntyDataCSV(e) {
    runInAction(() => {
      this.uploadInfo.csv = "";
    });
    let file = e.target?.files[0];
    this.uploadInfo.csv = file;
  }

  handleImportVEngineHourCSV(e) {
    runInAction(() => {
      this.uploadInfo.csv = "";
    });

    let file = e.target?.files[0];
    this.uploadInfo.csv = file;
  }

  handleImportVTripCSV(e) {
    runInAction(() => {
      this.uploadInfo.csv = "";
    });
    let file = e.target?.files[0];
    this.uploadInfo.csv = file;
  }

  handleGetVTripsData(id) {
    this.handleSetLoading(true);
    getVTripsData(Common.token, id, this.companyId, (err, data) => {
      if (data?.message === "Token Expired!") {
        // toast.info(data.message);
        window.location.pathname = "/";
      } else if (err) {
        toast.error(err.message);
      } else if (data?.message === "File not exist") {
        this.filenotexist = true;
        this.handleSetLoading(false)
      } else if (data?.unAuthorizeVehicle) {
        // this.vTripsData = [];
        this.unAuthorizeVehicles = data;
        console.log(this.unAuthorizeVehicles)
      } else {
        runInAction(() => {
          this.vTripsData = data;
          this.handleSetLoading(false);
        });
      }
    });
  }

  handleGetVTripsFileData(id) {
    this.handleSetLoading(true);

    getVTripsFileData(Common.token, id, this.companyId, (err, data) => {
      if (data?.message === "Token Expired!") {
        // toast.info(data.message);
        window.location.pathname = "/";
      } else if (err) {
        toast.error(err.message);
      } else if (data?.message === "File not exist") {
        this.filenotexist = true;
        this.handleSetLoading(false)
      } else if (data?.unAuthorizeVehicle) {
        // this.vTripsData = [];
        this.unAuthorizeVehicles = data;
        console.log(this.unAuthorizeVehicles)
      } else {
        runInAction(() => {
          this.vTripsData = data.payload.extract_data;
          this.vTripsAcceptData = data.payload.accept_data
          this.handleSetLoading(false);
        });
      }
    });

  }

  handleGetVEngineHourData(id) {
    this.handleSetLoading(true);
    getVEngineHourData(Common.token, id, this.companyId, (err, data) => {
      if (data?.message === "Token Expired!") {
        // toast.info(data.message);
        window.location.pathnam = "/";
      } else if (err) {
        toast.error(err.message);

      } else if (data?.message === "File not exist") {
        this.filenotexist = true;
        this.handleSetLoading(false)
      } else if (data?.unAuthorizeVehicle) {
        // this.vTripsData = [];
        this.unAuthorizeVehicles = data;
        console.log(this.unAuthorizeVehicles)
      } else {
        runInAction(() => {
          this.vEngineHourData = data.payload.extract_data;
          this.vEngineHourAcceptData=data.payload.accept_data
          this.handleSetLoading(false);
        });
      }
    });
  }

  // handleGetVProfilesData(id) {
  //   this.handleSetLoading(true);
  //   getVProfilesData(Common.token, id, (err, data) => {
  //     if (data?.message === "Token Expired!") {
  //       // toast.info(data.message);
  //       window.location.pathnam = "/";
  //     } else if (err) {
  //       toast.error(err);
  //     } else if (data?.message === "File not exist") {
  //       this.filenotexist = true;
  //       this.handleSetLoading(false);
  //     } else {
  //       runInAction(() => {
  //         this.vProfilesData = data;
  //         this.handleSetLoading(false);

  //       });
  //     }
  //   });
  // }

  handleGetUnitProfilesData(id) {
    this.handleSetLoading(true);
    getUnitProfilesData(Common.token, id, this.companyId, (err, data) => {
      if (data?.message === "Token Expired!") {
        // toast.info(data.message);
        window.location.pathnam = "/";
      } else if (err) {
        toast.error(err);
      } else if (data?.message === "File not exist") {
        this.filenotexist = true;
        this.handleSetLoading(false);
      } else {
        runInAction(() => {
          this.vProfilesData = data.payload.extract_data;
          this.unitProfilesAcceptData = data.payload.accept_data
          this.handleSetLoading(false);

        });
      }
    });
  }
  // old handle get manual entry data

  // handleGetManualEntryData(id) {
  //   runInAction(() => {
  //     this.filenotexist = false;
  //     this.manualEntryData = [];
  //     this.creatingLoading = false;
  //   });
  //   getManualEntryAPI(Common.token,this.companyId,id, (err, data) => {
  //     if (data?.message === "Token Expired!") {
  //       window.location.pathnam = "/";
  //     } else if (err) {
  //       toast.error(err);
  //     } else if (data?.message === "File not exist") {
  //       this.filenotexist = true;
  //     }
  //     // else if(data?.200){
  //     //   // this.vTripsData = [];
  //     //   this.unAuthorizeVehicles = data;
  //     // console.log(this.unAuthorizeVehicles)
  //     // }  
  //     else {
  //       runInAction(() => {
  //         this.manualEntryData = data;
  //         console.log("this.manualEntryData ===>",toJS(this.manualEntryData))
  //       });
  //     }
  //   });
  // }

  //getExtractFileData

  handleGetManualEntryData(id) {
    this.handleSetLoading(true);
    fetchGetExtractManualFile(Common.token, this.companyId, id, (err, data) => {
      if (data?.message === "Token Expired!") {
        window.location.pathnam = "/";
      } else if (data.payload === null) {
        this.manualEntryData = [];
        // console.log("err ====>",data.payload)
        this.handleSetLoading(false);
      }
      else if (err) {
        toast.error(err);
      }
      else {
        runInAction(() => {
          this.manualEntryData = data.payload.extract_data;
          this.manualEntryNewData = data.payload;
          this.handleSetLoading(false);
        });
      }
    });
  }

  handleCreateVTrip(id, history) {
    runInAction(() => {
      this.vehicles = [];
      this.errorResponse = [];
      this.handleSetSubmitLoading(true);
    });
    createVTripsData(Common.token, this.vTripsData, id, (err, data) => {
      if (data?.message === "Token Expired!") {
        window.location.pathname = "/";
        this.handleSetSubmitLoading(false);
        toast.info(data?.message);
      } else if (err) {
        this.handleSetSubmitLoading(false);
        toast.error(err.message);
      } else if (data?.error) {
        this.handleSetSubmitLoading(false);
        this.errorResponse = data?.error;
      } else if (
        data?.success === true &&
        data?.vehicleNotExists.length !== 0
      ) {
        this.handleSetSubmitLoading(false);
        this.vehicles = data;
        history.goBack();
      } else if (
        data?.success === false &&
        data?.vehicleNotExists.length !== 0
      ) {
        this.handleSetSubmitLoading(false);
        this.vehicles = data;
        history.goBack();
      } else {
        this.handleSetSubmitLoading(false);
        toast.success(data?.message);
        history.goBack();
      }
    });
  }

  handleStoreVTripsFile(id, history) {
    runInAction(() => {
      this.vehicles = [];
      this.errorResponse = [];
      this.handleSetSubmitLoading(true);
    });
    storeVTripsFile(Common.token, this.vTripsAcceptData, id, (err, data) => {
      if (data?.message === "Token Expired!") {
        window.location.pathname = "/";
        this.handleSetSubmitLoading(false);
        toast.info(data?.message);
      } else if (err) {
        this.handleSetSubmitLoading(false);
        toast.error(err.message);
      } else if (data?.error) {
        this.handleSetSubmitLoading(false);
        this.errorResponse = data?.error;
      } else if (
        data?.success === true &&
        data?.vehicleNotExists.length !== 0
      ) {
        this.handleSetSubmitLoading(false);
        this.vehicles = data;
        history.goBack();
      } else if (
        data?.success === false &&
        data?.vehicleNotExists.length !== 0
      ) {
        this.handleSetSubmitLoading(false);
        this.vehicles = data;
        history.goBack();
      } else {
        this.handleSetSubmitLoading(false);
        toast.success(data?.message);
        history.goBack();
      }
    });
  }

  handleCreateEngineHour(id, history) {
    runInAction(() => {
      this.vehicles = [];
      this.errorResponse = [];
      this.handleSetSubmitLoading(true);
    });
    createVEngineHoursData(
      Common.token,
      this.vEngineHourAcceptData,
      id,
      (err, data) => {
        if (data?.message === "Token Expired!") {
          window.location.pathname = "/";
          this.handleSetSubmitLoading(false);
          toast.info(data?.message);
        } else if (err) {
          this.handleSetSubmitLoading(false);
          toast.error(
            "Engine Hour creation not success! Please check your mandantory fields again!"
          );
        } else if (data?.error) {
          this.handleSetSubmitLoading(false);
          this.errorResponse = data?.error;
          console.log('err====>',data?.error)
        } else if (
          data?.success === true &&
          data?.vehicleNotExists.length !== 0
        ) {
          this.handleSetSubmitLoading(false);
          this.vehicles = data;
          history.goBack();
        } else if (
          data?.success === false &&
          data?.vehicleNotExists.length !== 0
        ) {
          this.handleSetSubmitLoading(false);
          this.vehicles = data;
          history.goBack();
        } else {
          this.handleSetSubmitLoading(false);
          toast.success(data?.message);
          history.goBack();
        }
      }
    );

  }

  handlegetImportedVProfileData(rows) {
    const ownerId = this.cookies.get("company");

    const vProfiles = [];

    for (let i = 0; i < rows?.length; i++) {
      const row = rows[i];
      const data = {
        grouping: row.grouping === " " ? "" : row.grouping,
        company: this.companyName,
        plate_number: row["plate_number"] === " " ? "" : row["plate_number"],
        brand: row.brand === " " ? "" : row.brand,
        model: row.model === " " ? "" : row.model,
        primary_fuel_type:
          row.primary_fuel_type === " " ? "" : row.primary_fuel_type,
        vin_number: row.vin_number === " " ? "" : row.vin_number,
        oem_baseline_emission:
          row.oem_baseline_emission === " " ? "" : row.oem_baseline_emission,
        vehicle_type: row.vehicle_type,
        vehicle_sub_type:
          row["vehicle_sub_type"] === " " ? "" : row["vehicle_sub_type"],
        vehicle_length: row.vehicle_length === " " ? "" : row.vehicle_length,
        vehicle_width: row.vehicle_width === " " ? "" : row.vehicle_width,
        vehicle_high: row.vehicle_high === " " ? "" : row.vehicle_high,
        gross_vehicle_weight:
          row.gross_vehicle_weight === " " ? "" : row.gross_vehicle_weight,
        cargo_type: row.cargo_type === " " ? "" : row.cargo_type,
        carrying_capacity:
          row.carrying_capacity === " " ? "" : row.carrying_capacity,

        secondary_fuel_type:
          row["secondary_fuel_type"] === " " ? "" : row["secondary_fuel_type"],
        fuel_tank_capacity:
          row["fuel_tank_capacity"] === " " ? "" : row["fuel_tank_capacity"],
        battery_type: row["battery_type"] === " " ? "" : row["battery_type"],
        battery_capacity:
          row["battery_capacity"] === " " ? "" : row["battery_capacity"],
        battery_energy_consumption:
          row["battery_energy_consumption"] === " "
            ? ""
            : row["battery_energy_consumption"],
        created_utc_time: row.created_utc_time,

        ownerId: ownerId,
      };
      vProfiles.push(data);
    }

    if (vProfiles === null) {
      this.importedVprofileData = [];
    } else {
      this.importedVprofileData = vProfiles;
    }
  }

  handleClearFileImportDate() {
    runInAction(() => {
      this.uploadInfo.fileimportDate = "";
    })
  }

  handleCreateVProfile(id, history) {
    runInAction(() => {
      this.vehicles = [];
      this.errorResponse = [];
      // this.uploadInfo.fileimportDate = "";
      this.handleSetSubmitLoading(true);
    });
    const vProfileData = toJS(this.importedVprofileData);

    this.handleSetLoading(true);

    createVprofileData(
      Common.token,
      vProfileData,
      id,
      this.uploadInfo.fileimportDate,
      (err, data) => {
        if (data?.message === "Token Expired!") {
          window.location.pathname = "/";
          this.handleSetSubmitLoading(false);
          toast.info(data?.message);
        } else if (err) {
          this.handleSetSubmitLoading(false);
          toast.error(err.message);
        } else if (data?.error) {
          this.handleSetSubmitLoading(false);
          this.errorResponse = data?.error;
        } else if (data?.success === true && data?.vehicleExists.length !== 0) {
          this.handleSetSubmitLoading(false);
          this.vehicles = data;
          history.goBack();
        } else if (
          data?.success === false &&
          data?.vehicleExists.length !== 0
        ) {
          this.handleSetSubmitLoading(false);
          this.vehicles = data;
          history.goBack();
        } else {
          this.handleSetSubmitLoading(false);
          toast.success(data?.message);
          history.goBack();
        }
      }
    );
    this.handleSetLoading(false);
  }

  handleStoreUnitProfilesData(id, history) {
    runInAction(() => {
      this.vehicles = [];
      this.errorResponse = [];
      // this.uploadInfo.fileimportDate = "";
      this.handleSetSubmitLoading(true);
    });
    // const vProfileData = toJS(this.importedVprofileData);

    this.handleSetLoading(true);

    storeUnitProfiles(
      Common.token,
      id,
      this.unitProfilesAcceptData,
      (err, data) => {
        if (data?.message === "Token Expired!") {
          window.location.pathname = "/";
          this.handleSetSubmitLoading(false);
          toast.info(data?.message);
        } else if (err) {
          this.handleSetSubmitLoading(false);
          toast.error(err.message);
        } else if (data?.error) {
          this.handleSetSubmitLoading(false);
          this.errorResponse = data?.error;
        } else if (data?.success === true && data?.vehicleExists.length !== 0) {
          this.handleSetSubmitLoading(false);
          this.vehicles = data;
          history.goBack();
        } else if (
          data?.success === false &&
          data?.vehicleExists.length !== 0
        ) {
          this.handleSetSubmitLoading(false);
          this.vehicles = data;
          history.goBack();
        } else {
          this.handleSetSubmitLoading(false);
          toast.success(data?.message);
          history.goBack();
        }
      }
    );
    this.handleSetLoading(false);
  }
  // old manual entry api

  // handleCreateManualEntry(id, history) {
  //   runInAction(() => {
  //     this.vehicles = [];
  //     this.errorResponse = [];
  //   });

  //   const importedManualEntryData = toJS(this.manualEntryData);

  //   createManualEntryData(
  //     Common.token,
  //     importedManualEntryData,
  //     id,
  //     (err, data) => {
  //       if (data.message === "Token Expired!") {
  //         window.location.pathname = "/";
  //         toast.info(data.message);
  //       } else if (err) {
  //         toast.error(err.message);
  //       } else if (data.error) {
  //         this.errorResponse = data.error;
  //       } else if (
  //         data.success === true &&
  //         data.vehicleNotExists.length !== 0
  //       ) {
  //         this.vehicles = data;
  //         history.goBack();
  //       } else if (
  //         data.success === false &&
  //         data.vehicleNotExists.length !== 0
  //       ) {
  //         this.vehicles = data;
  //         history.goBack();
  //       } else {
  //         toast.success(data.message);
  //         history.goBack();
  //       }
  //     }
  //   );
  // }


  handleCreateManualEntry(id, history) {
    runInAction(() => {
      this.vehicles = [];
      this.errorResponse = [];
      this.dataAlreadyExist = false;
      this.handleSetSubmitLoading(true);
      this.manualEntryExistVehicle = [];
    });
    const accept_data = this.manualEntryNewData.accept_data
    console.log("accept_data ====>", accept_data)
    if (accept_data == []) {
      this.handleSetSubmitLoading(false);
      toast.error("No Accept Data");
      history.goBack();
    } else {
      // console.log("token =====>",Common.token)
      storeExtractManualData(
        Common.token,
        accept_data,
        id,
        (err, data) => {
          if (data.message === "Token Expired!") {
            window.location.pathname = "/";
            toast.info(data.message);
          } else if (err) {
            toast.error(err.message);
          } else if (data.error) {
            this.errorResponse = data.error;
          } else if (
            data.success === true &&
            data.vehicleNotExists.length !== 0
          ) {
            this.vehicles = data;
            history.goBack();
          } else if (
            data.success === false &&
            data.vehicleNotExists.length !== 0
          ) {
            this.vehicles = data;
            history.goBack();
          } else {
            toast.success("Successfully imported!");
            history.goBack();
          }
        }
      );
    }

  }

  // handleCreateManualEntry(id, history) {
  //   runInAction(() => {
  //     this.vehicles = [];
  //     this.errorResponse = [];
  //     this.dataAlreadyExist = false;
  //     this.handleSetSubmitLoading(true);
  //     this.manualEntryExistVehicle = [];
  //   });

  //   const importedManualEntryData = toJS(this.manualEntryData);
  //   manualEntryCreateNewData(
  //     Common.token,
  //     importedManualEntryData,
  //     id,
  //     (err, data) => {
  //       if (data?.message === "Token Expired!") {
  //         window.location.pathname = "/";
  //         this.handleSetSubmitLoading(false);
  //         toast.info(data?.message);
  //       } else if (err) {
  //         this.handleSetSubmitLoading(false);
  //         toast.error(err.message);
  //       } else if (data?.error) {
  //         this.handleSetSubmitLoading(false);
  //         this.errorResponse = data?.error;
  //       } else if (
  //         data?.success === true &&
  //         data?.vehicleNotExists.length !== 0
  //       ) {
  //         this.handleSetSubmitLoading(false);
  //         this.vehicles = data;
  //         history.goBack();
  //       } else if (
  //         data?.success === false &&
  //         data?.vehicleNotExists.length !== 0
  //       ) {
  //         this.handleSetSubmitLoading(false);
  //         this.vehicles = data;
  //         history.goBack();
  //       } else if (
  //         data?.message === "Some of the manual entry data already exist." ||
  //         data?.message === "The manual entry data already exist."
  //       ) {
  //         this.dataAlreadyExist = true;
  //         this.manualEntryExistVehicle = data.manualEntryExist;
  //         this.handleSetSubmitLoading(false);
  //         // console.log(this.dataAlreadyExist, "dataalreadyexist===>")
  //       } else {
  //         this.handleSetSubmitLoading(false);
  //         toast.success(data?.message);
  //         history.goBack();
  //       }
  //     }
  //   );
  // }

  handleCreateManualEntryForOverWrite(id, overwrite, history) {
    runInAction(() => {
      this.vehicles = [];
      this.errorResponse = [];
      this.dataAlreadyExist = false;
      this.handleSetSubmitLoading(true);
    });

    const importedManualEntryData = toJS(this.manualEntryData);

    manualEntryCreateOverwriteData(
      Common.token,
      importedManualEntryData,
      id,
      overwrite,

      (err, data) => {
        if (data?.message === "Token Expired!") {
          window.location.pathname = "/";
          this.handleSetSubmitLoading(false);
          toast.info(data?.message);
        } else if (err) {
          this.handleSetSubmitLoading(false);
          toast.error(err?.message);
        } else if (data?.error) {
          this.handleSetSubmitLoading(false);
          this.errorResponse = data?.error;
        } else if (
          data?.success === true &&
          data?.vehicleNotExists?.length !== 0
        ) {
          this.handleSetSubmitLoading(false);
          this.vehicles = data;
          history.goBack();
        } else if (
          data?.success === false &&
          data?.vehicleNotExists?.length !== 0
        ) {
          this.handleSetSubmitLoading(false);
          this.vehicles = data;
          history.goBack();
        } else {
          this.handleSetSubmitLoading(false);
          toast.success(data?.message);
          history.goBack();
        }
      }
    );
  }

  handleClearManualEntryAlreadyExist() {
    runInAction(() => {
      this.dataAlreadyExist = false;
    });
  }

  handleClearImports(id, fileName) {
    clearImportData(Common.token, id, fileName, (err, data) => {
      if (data?.message === "Token Expired!") {
        toast.info(data?.message);
        window.location.pathname = "/";
      } else if (data?.success === false) {
        toast.error(data?.message);
      } else if (err) {
        toast.error(err.message);
      } else {
        toast.success(data?.message);

        const decode = jwtDecode(this.token);
        if (decode.role === "super_admin" || this.companyName === "Volvo") {
          if (this.showTable === "table1") {
            this.handleGetImportUnitsHistory();
          } else if (this.showTable === "table2") {
            this.handleGetImportTripsHistory();
          } else if (this.showTable === "table3") {
            this.handleGetImportEngineHour();
          } else {
            this.handleGetImportManualEntry();
          }
        } else {
          this.handleGetImportManualEntry();
        }
      }
    });
  }

  handleDeleteManualEntryData(id, companyId) {
    deleteManualEntryFileData(Common.token, id, companyId, (err, data) => {
      if (data?.message === "Token Expired!") {
        toast.info(data?.message);
        window.location.pathname = "/";
      } else if (data?.success === false) {
        toast.error(data?.message);
      } else if (err) {
        toast.error(err.message);
      } else {
        toast.success(data?.message);

        const decode = jwtDecode(this.token);
        if (decode.role === "super_admin" || this.companyName === "Volvo") {
          if (this.showTable === "table1") {
            this.handleGetImportUnitsHistory();
          } else if (this.showTable === "table2") {
            this.handleGetImportTripsHistory();
          } else if (this.showTable === "table3") {
            this.handleGetImportEngineHour();
          } else {
            this.handleGetImportManualEntry();
          }
        } else {
          this.handleGetImportManualEntry();
        }
      }
    });
  }

  handleClearVehicles() {
    runInAction(() => {
      this.vehicles = [];
    });
  }

  handleClearErrorResponse() {
    runInAction(() => {
      this.errorResponse = [];
    });
  }

  handleTripCsvExport() {
    this.vTripHeaders = [];

    const headers = [
      "grouping",
      "start_time",
      "to_time",
      "trip_mileage",
      "consumed",
      "start_point",
      "start_link",
      "to_point",
      "to_link",
      "initial_lvl",
      "final_lvl",
      "driver",
    ];
    headers.map((value) => {
      this.vTripHeaders.push(value);
    });
  }

  handleVehicleCsvExport() {
    this.vVehicleProfile = [];

    const headers = [
      "grouping",
      "start_time",
      "company",
      "plate_number",
      "brand",
      "model",
      "primary_fuel_type",
      "vin_number",
      "oem_baseline_emission",
      "vehicle_type",
      "vehicle_sub_type",
      "vehicle_length",
      "vehicle_width",
      "vehicle_high",
      "gross_vehicle_weight",
      "cargo_type",
      "carrying_capacity",
      "secondary_fuel_type",
      "fuel_tank_capacity",
      "battery_type",
      "battery_capacity",
      "battery_energy_consumption",
    ];
    headers.map((value) => {
      this.vVehicleProfile.push(value);
    });
  }

  handleEngineHoursExport() {
    this.vEngineHours = [];
    const headers = [
      "grouping",
      "start_time",
      "in_motion",
      "idling",
      "start_point",
      "start_link",
      "to_time",
      "to_point",
      "to_link",
      "duration_seconds",
      "total_engine_hours",
      "consumption"
    ];
    headers.map((value) => {
      this.vEngineHours.push(value);
    });
  }

  handleManualEntryExport() {
    this.manualEntry = [];
    const headers = [
      "date",
      "license_plate",
      // "odometer_start",
      // "odometer_end",
      "trip_mileage",
      "consumed",
    ];
    headers.map((value) => {
      this.manualEntry.push(value);
    });
  }

  _handleFetchCompanyYears() {
    fetchCompanyYears(this.companyId, this.token, (err, data) => {
      runInAction(() => {
        this.companyyearlydata = [];
        this.dashboardYears = [];
        this.years = [];
      });
      if (data) {
        runInAction(() => {
          this.dashboardYears = { label: "All", value: 0 };
          const yearlysorting = data?.response.sort(
            (a, b) => b.value - a.value
          );
          this.companyyearlydata = yearlysorting;
          this.years = data?.response;

          yearlysorting.forEach((v) => {
            this.dashboardYears.push(v);
          });
        });
      } else {
        toast.error(err);
      }
    });
  }

  handleMonthSelect = (value) => {
    runInAction(() => {
      this.month = value;
    });
    if (this.showTable === "table1") {
      this.handleGetImportUnitsHistory();
    } else if (this.showTable === "table2") {
      this.handleGetImportTripsHistory();
    } else if (this.showTable === "table3") {
      this.handleGetImportEngineHour();
    } else {
      this.handleGetImportManualEntry();
    }
  };

  handleYearSelect = (value) => {
    runInAction(() => {
      this.year = value;
    });
    if (this.showTable === "table1") {
      this.handleGetImportUnitsHistory();
    } else if (this.showTable === "table2") {
      this.handleGetImportTripsHistory();
    } else if (this.showTable === "table3") {
      this.handleGetImportEngineHour();
    } else {
      this.handleGetImportManualEntry();
    }
  };

  _handleQueryParam(assets, year, month) {
    this.currentYear = Number(year);
    this.currentMonth = Number(month);
    this.currentAssets = assets;
  }

  handleshowconfirmsubmitmodal = (value) => {
    this.showconfirmsubmitmodal = value;
  };
}

export default new DataImport();
