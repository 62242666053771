import { Colors, invertDark } from "../../Assets/Config/color.config";
import { My_Button, My_Input, My_upload_image, Selector } from "../../Tools";
import { Custom_Select } from "../../Tools/Custom_Select";
import { useHistory } from "react-router-dom";
import { inject, observer } from "mobx-react";
import Store from "../../Store";
import { useEffect, useState } from "react";
import allCountry from "../../Assets/countries.json";
import { Checkbox } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { typeOptions } from "./TypeOptions";
import { UploadImage } from "./UploadImage";
import { toJS } from "mobx";
import { useTranslation } from "react-i18next";

export const RegisterCompanyModal = inject("store")(
  observer((props) => {
    const { t } = useTranslation("admin-panel");
    const { theme, userList, showModal, setShowModal } = props;
    const history = useHistory();
    const data = Store.adminPanel.userList;
    const { role, companyId } = props.store.common;
    const {
      level,
      selectedUser,
      setSelectedUser,
      _handleInputChange,
      userData,
      companyInfo,
      _handleCompanyInputChange,
      _handleUploadImage,
      photo,
      _handleChangeRegisterDate,
      _handleChangeExpireDate,
      _handleFormClear,
      _handleCreateCompanyNew,
      _handleCheckBox,
      _handleRegisterCompany,
    } = props.store.adminPanel;

    const [phoneNumber, setPhoneNumber] = useState("");

    const CountryOptions = allCountry?.map((country) => ({
      value: country.name,
      label: country.name,
      code: country.code.toLowerCase(),
    }));

    const levels = ["1", "2", "3", "4", "5"];

    const higherLevels = levels.filter((v) => v > level);

    const levelOptions = higherLevels.map((d, i) => ({
      value: d,
      label: d,
    }));

    const companySizeOptions = [
      {
        value: "1 - 9",
        label: "1 - 9",
      },
      {
        value: "10 - 49",
        label: "10 - 49",
      },
      {
        value: "50 - 249",
        label: "50 - 249",
      },
      {
        value: "250 - 499",
        label: "250 - 499",
      },
      {
        value: "500 and above",
        label: "500 and above",
      },
    ];

    const companyRevenueOptions = [
      {
        value: "Below 1 million",
        label: "Below 1 million",
      },
      {
        value: "1 - 3 million",
        label: "1 - 3 million",
      },
      {
        value: "3 - 5 million",
        label: "3 - 5 million",
      },
      {
        value: "Above 5 million",
        label: "Above 5 million",
      },
    ];

    const userOptions = userList?.map((v) => {
      let users = {
        id: v.id,
        label: v.username,
        value: v.username,
      };
      return users;
    });

    return (
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        id="registercompanymodal"
        tabIndex="-1"
        aria-labelledby="registercompanymodal"
        aria-hidden="true"
        style={{ display: showModal ? "block" : "none" }}
      >
        <div className="modal-dialog modal-xl rounded">
          <div
            className="modal-content"
            style={{
              background: Colors[theme]?.background,
              color: invertDark(theme),
            }}
          >
            <div
              className="modal-header"
              style={{
                background: Colors[theme]?.background,
                color: invertDark(theme),
                border: "none",
                padding: 6,
              }}
            >
              <span
                style={{
                  fontSize: 24,
                  color: theme === "dark" ? "#fff" : Colors[theme].color,
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                  marginLeft: 2,
                }}
              >
                {t("Company Registration to ESGpedia")}
              </span>

              <span
                data-bs-dismiss="modal"
                onClick={() => _handleFormClear()}
                style={{ cursor: "pointer", marginRight: 10 }}
              >
                <i className="bi bi-x-lg" />
              </span>
            </div>

            <div
              className="modal-body"
              style={{ background: Colors[theme]?.background }}
            >
              <div
                className="d-flex align-items-center justify-content-between"
                style={{
                  border: theme === "dark" ? "" : "1px solid black",
                  height: "550px",
                }}
              >
                <div
                  className="d-flex flex-column justify-content-between"
                  style={{
                    background: theme === "dark" ? "#192353" : "#fff",
                    borderBottomRightRadius: theme === "dark" ? 5 : "",
                    borderBottomLeftRadius: 5,
                    height: "100%",
                    width: "49%",
                    borderRight: theme === "dark" ? "" : "1px solid black",
                    overflow: "scroll",
                  }}
                >
                  <div className="py-2 pe-5 ps-3">
                    <div className="pb-5">{t("Company Information")}</div>
                    <div className="d-flex align-items-center pb-3">
                      <span className="col-6">
                        <span style={{ color: "#ffc107" }}>*</span>{" "}
                        {t("Incorporation")}
                      </span>
                      <span className="col-6">
                        <Selector
                          options={CountryOptions}
                          value={companyInfo.incorporation}
                          menuListColor="black"
                          _handleSelect={(e) => {
                            _handleCompanyInputChange({
                              incorporation: e.value,
                            });
                          }}
                        />
                      </span>
                    </div>
                    <div className="d-flex align-items-center pb-3">
                      <span className="col-6">
                        <span style={{ color: "#ffc107" }}>*</span>{" "}
                        {t("Industry Sector")}
                      </span>
                      <span className="col-6">
                        <Selector
                          options={typeOptions}
                          menuListColor="black"
                          _handleSelect={(e) => {
                            _handleCompanyInputChange({ sector: e.value });
                          }}
                        />
                      </span>
                    </div>

                    <div className="d-flex align-items-center pb-3">
                      <span className="col-6">{t("Company Website")}</span>
                      <span className="col-6">
                        <My_Input
                          // type={'number'}
                          id={"companyWebsite"}
                          value={companyInfo.website}
                          placeHolder={"Enter company website..."}
                          onChange={(e) =>
                            _handleCompanyInputChange({
                              website: e.target.value,
                            })
                          }
                        />
                      </span>
                    </div>

                    {/* <div className='d-flex align-items-center pb-3'>
                                            <span className='col-6'>
                                                Company Phone Number
                                            </span>
                                            <span className='col-6' onChange={() => _handleCompanyInputChange({ phone: phoneNumber })}>
                                                
                                                <PhoneInput
                                                    international
                                                    country={'sg'}
                                                    value={phoneNumber}
                                                    onChange={setPhoneNumber}
                                                    inputStyle={{
                                                        background: theme === 'dark' && Colors.darkBlue,
                                                        color: invertDark(theme),
                                                        width: '100%',
                                                        paddingTop: 10,
                                                        paddingBottom: 10,
                                                        fontSize: 14,

                                                        boxShadow: "none",
                                                        shapeOutline: "none",
                                                        outline: "none",
                                                        border: `1px solid ${Colors.secondaryColor}`,
                                                        borderRadius: 5,
                                                    }}
                                                    buttonStyle={{
                                                        background: theme === 'dark' && Colors.darkBlue,
                                                        color: invertDark(theme),
                                                    }}
                                                    dropdownStyle={{
                                                        width: '250px',
                                                        background: theme === 'dark' && Colors.darkBlue,
                                                        color: invertDark(theme),
                                                    }}
                                                />
                                            </span>
                                        </div> */}

                    <div className="d-flex align-items-center pb-3">
                      <span className="col-6">{t("Company Size")}</span>
                      <span className="col-6">
                        <Selector
                          options={companySizeOptions}
                          menuListColor="black"
                          _handleSelect={(e) => {
                            _handleCompanyInputChange({ size: e.value });
                          }}
                        />
                      </span>
                    </div>

                    <div className="d-flex align-items-center pb-3">
                      <span className="col-6">{t("Company Revenue")}</span>
                      <span className="col-6">
                        <Selector
                          options={companyRevenueOptions}
                          menuListColor="black"
                          _handleSelect={(e) => {
                            _handleCompanyInputChange({ revenue: e.value });
                          }}
                        />
                      </span>
                    </div>

                    <div className="d-flex align-items-center pb-3">
                      <span className="col-6">
                        {t("UN Global Compact SignUp")}
                      </span>
                      <span className="col-6">
                        <span className="d-flex align-items-center">
                          <span
                            className="d-flex align-items-center pe-3"
                            onClick={() =>
                              _handleCheckBox({ compact_signup: "YES" })
                            }
                          >
                            <Checkbox
                              style={{
                                color: Colors.activeColor,
                                padding: "0px",
                              }}
                              checked={
                                companyInfo?.compact_signup === "YES"
                                  ? true
                                  : false
                              }
                            />
                            <span
                              className="ms-1"
                              style={{ cursor: "pointer" }}
                            >
                              {t("YES")}
                            </span>
                          </span>
                          <span
                            className="d-flex align-items-center"
                            onClick={() =>
                              _handleCheckBox({ compact_signup: "NO" })
                            }
                          >
                            <Checkbox
                              style={{
                                color: Colors.activeColor,
                                padding: "0px",
                              }}
                              checked={
                                companyInfo?.compact_signup === "NO"
                                  ? true
                                  : false
                              }
                            />
                            <span
                              className="ms-1"
                              style={{ cursor: "pointer" }}
                            >
                              {t("NO")}
                            </span>
                          </span>
                        </span>
                      </span>
                    </div>

                    <div className="d-flex align-items-center pb-3">
                      <span className="col-6">{t("Environmental Policy")}</span>
                      <span className="col-6">
                        <span className="d-flex align-items-center">
                          <span
                            className="d-flex align-items-center pe-3"
                            onClick={() =>
                              _handleCheckBox({ writtenEnv_policy: "YES" })
                            }
                          >
                            <Checkbox
                              style={{
                                color: Colors.activeColor,
                                padding: "0px",
                              }}
                              checked={
                                companyInfo?.writtenEnv_policy === "YES"
                                  ? true
                                  : false
                              }
                            />
                            <span
                              className="ms-1"
                              style={{ cursor: "pointer" }}
                            >
                              {t("YES")}
                            </span>
                          </span>
                          <span
                            className="d-flex align-items-center"
                            onClick={() =>
                              _handleCheckBox({ writtenEnv_policy: "NO" })
                            }
                          >
                            <Checkbox
                              style={{
                                color: Colors.activeColor,
                                padding: "0px",
                              }}
                              checked={
                                companyInfo?.writtenEnv_policy === "NO"
                                  ? true
                                  : false
                              }
                            />
                            <span
                              className="ms-1"
                              style={{ cursor: "pointer" }}
                            >
                              {t("NO")}
                            </span>
                          </span>
                        </span>
                      </span>
                    </div>

                    <div
                      className="d-flex align-items-center pb-3"
                      onClick={() =>
                        _handleCheckBox({ declarationOfLegalBusiness: true })
                      }
                    >
                      <span style={{ cursor: "pointer" }} className="me-3">
                        <Checkbox
                          style={{ color: Colors.activeColor, padding: "0px" }}
                        />
                      </span>
                      <span style={{ cursor: "pointer" }}>
                        {t("Company declares it is a legal business.")}
                      </span>
                    </div>

                    {/* <div className='d-flex align-items-center pb-3'>
                                            <span className='col-6'>
                                                Date of Account Creation
                                            </span>
                                            <span className='col-6'>
                                                <My_DatePicker height={'43px'} selected={companyInfo.registerDate} onChange={(e) => _handleChangeRegisterDate(e)} />
                                            </span>
                                        </div>

                                        <div className='d-flex align-items-center pb-3'>
                                            <span className='col-6'>
                                                Subscription Expiry Date
                                            </span>
                                            <span className='col-6'>
                                                <My_DatePicker height={'43px'} selected={companyInfo.expireDate} onChange={(e) => _handleChangeExpireDate(e)} />
                                            </span>
                                        </div> */}
                  </div>
                </div>

                <div
                  className="py-2 pe-5 ps-3"
                  style={{
                    background: theme === "dark" ? "#192353" : "#fff",
                    borderBottomRightRadius: 5,
                    borderBottomLeftRadius: 5,
                    height: "100%",
                    width: "49%",
                  }}
                >
                  <div className="pb-5">{t("Select Company Admin")}</div>

                  <div>
                    <div className="d-flex align-items-center pb-3">
                      <span className="col-4">{t("User")}</span>
                      <span className="col-8">
                        <Custom_Select
                          options={userOptions}
                          value={
                            userOptions?.find(
                              (v) => v.label === selectedUser?.label
                            )?.label
                          }
                          _handleClick={(value) => setSelectedUser(value)}
                          selectedValue={selectedUser?.value}
                          theme={theme}
                        />
                      </span>
                    </div>

                    <div className="d-flex align-items-center pb-3">
                      <span className="col-4">
                        <span style={{ color: "#ffc107" }}>*</span>{" "}
                        {t("Designation")}
                      </span>
                      <span className="col-8">
                        <My_Input
                          required={false}
                          id={"designatoin"}
                          value={userData.designation}
                          height="38px"
                          placeHolder={"Enter job title and designation"}
                          onChange={(e) =>
                            _handleInputChange({ designation: e.target.value })
                          }
                        />
                      </span>
                    </div>

                    {/* <div className='d-flex align-items-center pb-3'>
                                        <span className='col-4'>
                                            Assign Company
                                        </span>
                                        <span className='col-8'>
                                            <Selector options={CompanyOptions}  menuListColor={Colors.darkBlue} _handleSelect={(e) => _handleInputChange({ companyId: e.value })} />
                                        </span>
                                    </div> */}

                    {/* {editView === true ? <div className="mt-3 float-end" >
                                        <span style={{ cursor: 'pointer' }}>
                                            <My_Button
                                                customColor={theme === 'dark' ? '#0064FB' : '#04c600'} customHoverColor={theme === 'dark' ? '' : '#05e700'}
                                                text='Update'
                                                onClick={() => _handleUpdateUser(userId)}

                                                style={{ minWidth: 100 }}
                                                customAdd={<i className="bi bi-plus-square-dotted" style={{ marginLeft: "0.5em" }}></i>}
                                            />
                                        </span>

                                    </div> : */}
                  </div>
                </div>
              </div>

              <div className="float-end py-3">
                <My_Button
                  onClick={() => {
                    _handleRegisterCompany();
                    _handleFormClear();
                  }}
                  customColor={theme === "dark" ? "#0064FB" : "#04c600"}
                  customHoverColor={theme === "dark" ? "" : "#05e700"}
                  className="px-3 "
                  text={t("Register")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  })
);

// const assigncompanyoption = [
//     {value: 'KumoSolution', label: 'Kumo Solution'},
//     {value: 'Evercomm', label: 'Evercomm'},
//     {value: 'ABCcompany', label: 'ABC Company'}
// ]
