import React from "react";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import { Colors } from "../../Assets/Config/color.config";
import Store from "../../Store";
import Month from "../../Assets/month.json";
import { emission_chiller, emission_vehicle } from "../../Assets/Config/constant.config";
import { useTranslation } from "react-i18next";
const { isAds } = Store.common;
const Months = Month.filter((v) => v.value !== 0);

const monthFilter = Months.map((v) => {
  return {
    name: v.label.substring(0, 3),
  };
});
const carbonEmissionColor = "#413ea0";
const CarbonEmissionChart = (props) => {
  const {t} = useTranslation("new-tracking")
  const theme = localStorage.getItem("theme");
  const { assetProfile, year, selectedAssetId, vehicleList } = Store.tracking;
  const emissionByMonth = assetProfile.carbonEmissionsByMonth;
  const data = monthFilter.map((v) => {
    return {
      ...v,
      ...emissionByMonth.find((em) => em.month === monthFilter.indexOf(v) + 1),
    };
  });
  console.log("theme",theme);
  const selectedAssetName = isAds&&vehicleList?.find(asset=>selectedAssetId===asset.asset_id).asset_name
  const data1 = data.map((v, k) => {
    return {
      name: `${v.name}-${year % 100}`,
      carbonEmission: v.actual_t_co2
        ? v.actual_t_co2.toFixed(2)
        : v.totalFuelConsumed
        ? ((v.totalFuelConsumed * emission_vehicle) / 1000).toFixed(2)
        : 0,
      emissionFactor: v.actual_g_co2
        ? v.actual_g_co2.toFixed(2)
        : v.totalFuelConsumed && Number(v.totalTripMileage) != 0
        ? (
            (v.totalFuelConsumed * emission_vehicle * 1000) /
            v.totalTripMileage
          ).toFixed(2)
        : 0,
      baselineEmission: assetProfile.manualBaselineEmissionPerKm
        ? assetProfile.manualBaselineEmissionPerKm.toFixed(2)
        : (
            assetProfile.totalBaselineEmission / assetProfile.vehicleMileage
          ).toFixed(2),
    };
  });

  const labelFormat = (text) => {
    return (
      text
        .replace(/([A-Z])/g, " $1")
        .trim()
        .charAt(0)
        .toUpperCase() +
      text
        .replace(/([A-Z])/g, " $1")
        .trim()
        .slice(1)
    );
  };
  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div
          className="custom-tooltip p-2 rounded"
          style={{
            background: theme === "dark" ? Colors.darkBlue: Colors.Dark[5],
            boxShadow:"0px 10px 10px rgba(0,0,0,0.1)"
          }}
        >
          <div className="label">{`${t("Month")} : ${label}`}</div>
          <hr className="my-0"/>
          <div className="label">{`${t(labelFormat(payload[0].name))}: ${Number(
            payload[0].value
          ).toLocaleString("en-US")} tonnes`}</div>
          <div className="label">
            {`${t(labelFormat(payload[1].name))}: ${Number(
              payload[1].value
            ).toLocaleString("en-US")} gCO₂/km`}
          </div>
          <div className="label">
            {`${t(labelFormat(payload[2].name))} Factor: ${Number(
              payload[2].value
            ).toLocaleString("en-US")} gCO₂/km`}
          </div>
          {/* <p className="intro">{getIntroOfPage(label)}</p> */}
        </div>
      );
    }

    return null;
  };

  const maxLeftY = Math.ceil(Math.max(...data1.map((d) => d.carbonEmission)));
  const leftYTicks = Array(Math.ceil(maxLeftY * 2) + 1)
    .fill({})
    .map((_, i) => i * 0.5);
  const maxRightY = Math.ceil(
    Math.max(
      ...data1?.map((d) => d.emissionFactor||0),
      ...data1?.map((d) => d.baselineEmission||0)
    )
  );
  console.log('rightY', maxRightY)
  const rightYTicks = Array(Math.ceil((isNaN(maxRightY) ?? 0) / 200) + 1)
    .fill({})
    .map((_, i) => i * 200);

  return (
    <div
      className={`${window.innerWidth > 552 && "w-100"}`}
      style={{
        height: window.innerWidth > 700 ? 400 : window.innerWidth / 2,
        width: window.innerWidth > 552 ? "100%" : "350px",
        marginTop: 45,
      }}
    >
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ fontSize: "1rem" }}
      >
        <p
          className="text-center"
          style={{
            maxWidth: 150,
            color: theme === "dark" ? Colors.lightBlue : carbonEmissionColor,
          }}
        >
          CO₂ (tonnes)
        </p>
        <h2 style={{ fontSize: "1.5rem" }}>
          {assetProfile.vehicle_number_plate}
        </h2>
        <p
          className="text-center"
          style={{ maxWidth: 150, color: Colors.fuelLight }}
        >
          gCO₂/km
        </p>
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart width={500} height={400} data={data1}>
          <CartesianGrid
            stroke={
              theme === "dark" ? "rgba(255,255,255,0.1)" : Colors.light.active
            }
          />
          <XAxis dataKey="name" scale="band" tick={{ fill: "currentColor" }} />
          <YAxis
            yAxisId="left"
            orientation="left"
            tick={{
              fill: theme === "dark" ? Colors.lightBlue : carbonEmissionColor,
            }}
            interval={0}
            ticks={leftYTicks}
            tickCount={0}
          ></YAxis>
          <YAxis
            yAxisId="right"
            orientation="right"
            tick={{
              fill: theme === "dark" ? Colors.fuelLight : carbonEmissionColor,
            }}
            interval={
              window.innerWidth > 700 &&
              (rightYTicks.length > 50
                ? parseInt(rightYTicks.length / 10) - 1
                : 0)
            }
            ticks={rightYTicks}
            tickCount={0}
          ></YAxis>
          <Bar
            yAxisId="left"
            dataKey="carbonEmission"
            barSize={20}
            fill={theme === "dark" ? Colors.lightBlue : carbonEmissionColor}
          />
          <Line
            yAxisId="right"
            // type="monotone"
            dataKey="emissionFactor"
            stroke={Colors.fuelLight}
          />

          <Line
            yAxisId="right"
            // type="monotone"
            dataKey="baselineEmission"
            stroke={Colors.fuelBaseline}
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend
            wrapperStyle={{ bottom: window.innerWidth > 460 ? -10 : -25 }}
            formatter={(value, entry, index) => {
              if (value === "carbonEmission") {
                value = t("Actual")+" tCO₂";
              } else if (value === "emissionFactor") {
                value = t("Actual")+" gCO₂/km";
              } else if (value === "baselineEmission") {
                value = t("Baseline Emission Factor")+" (gCO₂/km)";
              }
              return value;
            }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CarbonEmissionChart;
