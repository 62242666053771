import { inject, observer } from "mobx-react";
import React from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import { ResponsiveContainer } from "recharts";

export const UnitCountChart = inject("store")(
  observer((props) => {
    const CanvasJSChart = CanvasJSReact.CanvasJSChart;

    const { theme, selectedUnitType } = props;

    
    const { canbusAndGps, gps, noCanbusAndGps, totalUnits, vehicleCount, units } =
      props.store.dashboard;

    let allUnits = units?.length;
    let iceUnits = (units?.filter(v => v.type == "ice"))?.length
    let evUnits = (units?.filter(v => v.type == "ev"))?.length

    let totalVehicle = selectedUnitType == "ice" ? iceUnits : selectedUnitType == "ev" ? evUnits : allUnits;

    const uniChart = {
      backgroundColor: "pink",
      marginTop: "-20px",
    };

    const options = {
      animationEnabled: true,
      exportEnabled: false,
      backgroundColor: "",
      height: 0,
      minWidth: 80,
      minHeight: 80,
      width: 250,
      height: 250,
      maxWidth: 250,
      maxHeight: 250,
      title: {
        text: "Total: " + totalVehicle,
        verticalAlign: "center",
        fontFamily: "arial",
        fontColor: theme === "dark" ? "white" : "black",
      },
      data: [
        {
          type: "doughnut",
          startAngle: 0,
          //innerRadius: 60,
          toolTipContent: "<b>{name}:</b> {y} (#percent%)",

          dataPoints: [
            {
              y: vehicleCount > 0 ? canbusAndGps : 0,
              name: "CANBus & GPS",
              color: "#94E7A9",
            },
            {
              y: vehicleCount > 0 ? gps : 0,
              name: "Only GPS",
              color: "#24AAAF",
            },
            {
              y: vehicleCount > 0 ? noCanbusAndGps : 0,
              name: "No CANBus & GPS",
              color: "#687A98",
            },
          ],
        },
      ],
    };

    return (
      <>
        <ResponsiveContainer
          className="justify-content-center"
          width="90%"
          height={0}
        >
          <CanvasJSChart options={options} />
        </ResponsiveContainer>
      </>
    );
  })
);
