import { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import { TrackAndTrace } from "../../Components/TrackAndTrace/trackAndTrace";
import {
  getAllContractByCompany,
  contractById,
} from "../../network/trackingAPI";
import { toast } from "react-toastify";
import { CO2_Loading } from "../../Assets/CO2_Loading";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { withRouter } from "react-router-dom";

const TrackAndTraceContainer = (props) => {
  const [ttData, setTTData] = useState([]);
  const [singleTTData, setSingleTTData] = useState(null);
  const cookie = new Cookies();
  const { token } = props.store.common;
  const cookies = new Cookies();
  const companyId = cookies.get('company') || props.store.common
  useEffect(() => {
    console.log("company Id :: "+companyId)
    getAllContractByCompany(companyId, token, (err, data) => {
      if (data) {
        if (data.message === "Token Expired!") {
          toast.info(data.message);
          window.location.pathname='/';
        }
        setTTData(data.data);
        if (data.error) {
          toast.warning(data.error);
        }
      } else {
        toast.error(err.error);
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const _handleViewContract = (trackId, trackingName) => {
    const companyData = {
      id: trackId,
      pageNumber: 0,
      pageSize: 10,
      searchTerm: "",
    };
    cookie.set("trackingName", trackingName);
    setSingleTTData(null);
    contractById(token, companyData, (err, data) => {
      if (data) {
        if (data.message === 'Token Expired!') {
          window.location.pathname = '/'
          toast.info(data.message)
      }
        setSingleTTData(data.data.content);
      } else {
        toast.error(err.error);
      }
    });
  };
  const _handleClose = () => {
    cookie.remove("trackId");
  };
  return ttData && ttData.length > 0 ? (
    <TrackAndTrace
      ttData={ttData}
      theme={props.theme}
      singleTTData={singleTTData}
      _handleViewContract={_handleViewContract}
      _handleClose={_handleClose}
    />
  ) : (
    <CO2_Loading  />
  );
};

export default inject("store")(withRouter(observer(TrackAndTraceContainer)));
