import { Checkbox } from "@mui/material";
import React from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { My_Button, My_Input, My_DatePicker } from "../../Tools";
import month from "../../Assets/month.json";
import { useTranslation } from "react-i18next";

const EditForm = (props) => {
  const {
    theme,
    selectedVehicle,
    updateData,
    handleInputChange,
    handlePickDate,
    handleSubmit,
    currentMonth,
    currentYear,
    open,
    setOpen,
    fuelRecords,
    isSubmitting,
    handleClear
  } = props;
  const {t} = useTranslation("new-manuel-entry")
  const tripMileageValue = updateData?.tripMileage
    ? updateData.tripMileage.includes("ascent")
      ? Number(
          updateData.tripMileage.toString().replace(" ascent", "")
        ).toFixed(2)
      : Number(updateData.tripMileage)
    : null;
  const fuelConsumedValue = updateData?.fuelConsumed
    ? updateData?.fuelConsumed?.includes("ascent")
      ? Number(
          updateData.fuelConsumed.toString().replace(" ascent", "")
        ).toFixed(2)
      : Number(updateData.fuelConsumed)
    : null;
  return (
    <div
      className="mx-2 my-3 p-3"
      style={{
        background: Colors[theme].background,
        borderRadius: "7px",
        color: invertDark(theme),
        boxShadow:
          theme === "dark" ? "none" : "0px 0px 10px rgba(75,171,237,0.35)",
      }}
    >
      <div className="d-flex justify-content-between pb-1">
        <div style={{ fontSize: 16 }}>
          {t("Editing data for")} <b>{selectedVehicle.licensePlate}</b>
          {" "}  ({month[currentMonth + 1].label + " " + currentYear})
        </div>
        <div style={{ fontSize: 16 }}>
          {/* {month.find((v) => v.index === currentMonth).month}-{currentYear} */}
          
          <i
              onClick={() => handleClear()}
              style={{ cursor: "pointer" }}
              className="bi bi-x-lg"
            ></i>
        </div>
      </div>
      <div
        className="m-2 p-2"
        style={{ borderRadius: "4px", border: `1px solid ${Colors.Dark[2]}` }}
      >
        <div className="d-flex flex-row flex-wrap justify-content-around">
          <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-12">
            <div className="m-1">
              {t("Fuel Consumed (Litres)")}
              {updateData?.fuelConsumed?.includes("ascent") && (
                <span className=" float-end mt-1" style={{ fontSize: 12 }}>
                  <i
                    className="fa fa-check-circle"
                    style={{ color: Colors.low, fontSize: 13 }}
                  />{" "}
                  {t("Data from Ascent")}
                </span>
              )}
              <My_Input
                id={"fuelConsumed"}
                // style={{ maxHeight: '30px'}}
                type={"number"}
                value={fuelConsumedValue}
                disabled={updateData?.fuelConsumed?.includes("ascent")}
                customColor={Colors.dark.primaryColor}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-12">
            <div className="m-1">
              {t("Fuel Records")}
              <My_Input
                id={"fuelRecords"}
                style={{ backgroundColor: Colors.lightBlue }}
                type={"file"}
                accept={"image/*,.xml,.xlsx"}
                text={"Upload"}
                onChange={handleInputChange}
                value={fuelRecords}
                multiple={true}
              />
            </div>
          </div>
        </div>
        <hr className="my-0" style={{ background: Colors.Dark[2] }} />
        <div className="d-flex flex-row flex-wrap justify-content-around">
          <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-12">
            <div className="m-1 mb-2">
              {t("Odometer")} (km)
              {updateData.odometer === "" &&
                !updateData.odometer &&
                updateData.tripMileage === "" &&
                !updateData.tripMileage && (
                  <span
                    style={{ fontSize: 12 }}
                    className="text-danger float-end mt-1"
                  >
                    <i
                      style={{ fontSize: 13 }}
                      className="fas fa-exclamation-circle text-danger me-1"
                    />
                    {t("Odometer can't be null.")}
                  </span>
                )}
              <My_Input
                id={"odometer"}
                type={"number"}
                // style={{ maxHeight: '30px'}}
                value={updateData.odometer ? Number(updateData?.odometer) : ""}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-12">
            <div className="m-1">
              {t("Date captured for Odometer")}
              <My_DatePicker
                id={"date"}
                height={"43px"}
                selected={updateData?.date}
                onChange={handlePickDate}
              />
            </div>
          </div>
        </div>
        <div className="d-flex flex-row flex-wrap justify-content-around">
          <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-12">
            <div className="m-1">
              {t("Before(Start of Month)")}
              <My_Input
                id={"startOfMonthImage"}
                style={{ backgroundColor: Colors.lightBlue }}
                type={"file"}
                accept={"image/*"}
                text={"Upload"}
                onChange={handleInputChange}
                value={updateData?.startOfMonthImage}
              />
            </div>
          </div>
          <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-12">
            <div className="m-1">
              {t("After(End of Month)")}
              <My_Input
                id={"endOfMonthImage"}
                style={{ backgroundColor: Colors.lightBlue }}
                type={"file"}
                accept={"image/*"}
                text={"Upload"}
                onChange={handleInputChange}
                value={updateData?.endOfMonthImage}
              />
            </div>
          </div>
        </div>
        <hr className="my-0" style={{ background: Colors.Dark[2] }} />
        <div className="d-flex flex-row flex-wrap justify-content-around">
          <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-12">
            <div className="m-1 mb-2">
              {t("Total Mileage (km)")}
              {updateData.odometer === "" &&
                !updateData.odometer &&
                updateData.tripMileage === "" &&
                !updateData.tripMileage && (
                  <span
                    style={{ fontSize: 12 }}
                    className="text-danger float-end mt-1"
                  >
                    <i
                      style={{ fontSize: 13 }}
                      className="fas fa-exclamation-circle text-danger me-1"
                    />
                    {t("Total mileage can't be null.")}
                  </span>
                )}
              {updateData?.tripMileage?.includes("ascent") && (
                <span className=" float-end mt-1" style={{ fontSize: 12 }}>
                  <i
                    className="fa fa-check-circle"
                    style={{ color: Colors.low, fontSize: 13 }}
                  />{" "}
                  {t("Data from Ascent")}
                </span>
              )}
              <My_Input
                type={"number"}
                id={"tripMileage"}
                disabled={updateData?.tripMileage?.includes("ascent")}
                value={tripMileageValue}
                customColor={Colors.dark.primaryColor}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-12"></div>
        </div>
      </div>
      <div className="d-flex flex-row flex-wrap justify-content-between mx-2">
        <span style={{ cursor: "pointer" }} onClick={() => setOpen(!open)}>
          <Checkbox checked={open} style={{ color: Colors.activeColor }} /> 
          {t("I confirm that the above data is correct and true to the best of my knowledge.")}
        </span>
        <div className="mx-1"style={{backgroundColor:"red"}}>
          <My_Button
            text={t("Submit")}
            type={"submit"}
            disabled={
              isSubmitting ||
              (open
                ? updateData.fuelConsumed === "" ||
                  !updateData.fuelConsumed ||
                  (updateData.tripMileage === "" &&
                    !updateData.tripMileage &&
                    updateData.odometer === "" &&
                    !updateData.odometer)
                  ? true
                  : false
                : true)
            }
            customColor={Colors.low}
            style={{
              backgroundColor: Colors.low,
              width: "100px",
              height: "35px",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default EditForm;
