import { useEffect, useState } from "react";
import { Colors, invertDark } from "../Assets/Config/color.config";
import Store from "../Store";

export const NotificationCard = ({ lastUpdate, theme }) => {
  const { _handleLastUpdateTime } = Store.common;
  useEffect(() => {
    const interval = setInterval(() => {
      // _handleLastUpdateTime();
    }, 60000);
    return () => clearInterval(interval);
  }, [lastUpdate]);
  return (
    <div
      className="py-2"
      style={{
        position: "fixed",
        right: 0,
        bottom: 3,
        fontSize: 14,
        zIndex: 1,
      }}
    >
      <span
        className="rounded"
        style={{
          background: "rgba(0,0,0,0.5)",
          padding: 10,
          color: invertDark(theme),
        }}
      >
        <i
          className="fa fa-circle px-1"
          style={{ fontSize: 10, color: Colors.low }}
        />
        {lastUpdate === 0
          ? `Updated less than a minute ago`
          : lastUpdate
            ? `Last updated on ${lastUpdate} minute${
              lastUpdate !== 1 ? "s" : ""
            } ago`
            : null}
      </span>
    </div>
  );
};
