import { inject, observer } from "mobx-react";
import { CarbonChart } from "./CarbonChart";
import { CO2_Loading } from "../../Assets/CO2_Loading";
import { useTranslation } from "react-i18next";
import { Colors } from "../../Assets/Config/color.config";
import { toJS } from "mobx";

export const CarbonComparisonView = inject("store")(
    observer((props) => {
        const { t } = useTranslation("new-tracking")
        const { theme, chartView } = props;
        const { assetProfiles,
            handleShowChartView,
            showChartViewVTwo,
            handleShowChartViewVTwo,
            selectedVehicle,
            fmsVehicleLst,
            vehicleIds,
          
        } = props.store.trackingVTwo;

        const showChartView = chartView
            ? chartView
            : props.store.trackingVTwo.showChartView;

        let newUnitSetting = fmsVehicleLst?.map(v => v.unitSetting)?.filter(unit => vehicleIds.includes(unit.unitId));
        // let selectedUnit = selectedVehicle?.length == undefined ? selectedVehicle : selectedVehicle[selectedVehicle?.length - 1]

        // let singleUnitSetting = newUnitSetting?.find(v => v.unitId === selectedUnit?.value)
      
        return (
            <>
                {
                    assetProfiles?.length === 0 && selectedVehicle?.length === undefined ?
                        (
                            <CO2_Loading text={t("Loading...")} />

                        ) : assetProfiles?.length === 0 && selectedVehicle?.length > 0 ?
                            (
                                <CO2_Loading text={t("Loading...")} />

                            ) : assetProfiles?.length === 0 && selectedVehicle?.length === 0 ?
                                (
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "50vh",
                                            marginRight: "3em",
                                        }}
                                    >
                                        <div className="" style={{ color: theme === 'dark' ? 'white' : 'black' }}>
                                            <i
                                                className="fa fa-exclamation-triangle me-2"
                                                style={{ marginTop: "20%", color: "orange" }}
                                            ></i>
                                            {t("No Data Found")}
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        {/* <div className="d-flex justify-content-between align-items-center mb-3" style={{ width: "100%" }}>
                                            <h5 className="">
                                                {showChartView === "daily"
                                                    ? t("Daily Emission Profile")
                                                    : t("Monthly Emission Profile")}
                                            </h5>

                                            <div className="">
                                                <div className="btn-group btn-group-sm" role="group">
                                                    <div
                                                        type="button"

                                                        className={`${showChartViewVTwo === "CANBus"
                                                            ? (theme === 'dark' ? "btn-custom-canbus" : 'btn-custom-canbus-light')
                                                            : (theme === 'dark' ? "btn-custom-canbus-inactive" : 'btn-custom-canbus-light-inactive')
                                                            }`}
                                                        onClick={() => handleShowChartViewVTwo("CANBus")}
                                                        style={{ zIndex: 0 }}
                                                    >
                                                        {t("CANBus")}
                                                    </div>
                                                    <div
                                                        type="button"
                                                        className={`${showChartViewVTwo === "manual"
                                                            ? (theme === 'dark' ? "btn-custom-manual" : 'btn-custom-manual-light')
                                                            : (theme === 'dark' ? "btn-custom-manual-inactive" : 'btn-custom-manual-light-inactive')
                                                            }`}
                                                        onClick={() => handleShowChartViewVTwo("manual")}
                                                        style={{ zIndex: 0 }}
                                                    >
                                                        {t("Manual")}
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        {assetProfiles?.map((assetProfile, index) => {
                                            return (
                                                
                                                <div style={{ height: '450px', width: '100%', background: Colors[theme].background, marginBottom: '10px', borderRadius: '5px'}}>
                                                    <CarbonChart assetProfile={assetProfile} theme={theme} chartView={showChartView} singleUnitSetting={newUnitSetting[index]} />
                                                </div>
                                            )
                                        })}
                                    </>
                                )
                }
            </>

        )
    })
)