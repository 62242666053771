import { inject, observer } from "mobx-react";
import { Fragment } from "react";
import StackedBarChart from "../../Assets/Charts/StackedBars";

const NetZeroChart = (props) => {
  const {
    data,
    keys,
    currentCo2Emission,
    scope1_currentCo2Emission, 
    scope2_currentCo2Emission, 
    scope3_currentCo2Emission,
    offset,
    baseLineCo2Emission,
    scope1_baseLineCo2Emission, 
    scope2_baseLineCo2Emission, 
    scope3_baseLineCo2Emission,
    Scope1,
    Scope2,
    Scope3    
  } = props;

  const theme = props.store.login.theme || "dark";
  
  return (
    <Fragment>
      <StackedBarChart
        data={data}
        keys={keys}
        theme={theme}
        currentCo2Emission={currentCo2Emission}
        scope1_currentCo2Emission={scope1_currentCo2Emission}
        scope2_currentCo2Emission={scope2_currentCo2Emission}
        scope3_currentCo2Emission={scope3_currentCo2Emission}
        baseLineCo2Emission={baseLineCo2Emission}
        scope1_baseLineCo2Emission={scope1_baseLineCo2Emission}
        scope2_baseLineCo2Emission={scope2_baseLineCo2Emission}
        scope3_baseLineCo2Emission={scope3_baseLineCo2Emission}        
        offset={offset}
        Scope1={Scope1}
        Scope2={Scope2}
        Scope3={Scope3}
      />
    </Fragment>
  );
};

export default inject("store")(observer(NetZeroChart));
