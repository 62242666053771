import { inject, observer } from "mobx-react";
import moment from "moment";
import { useRef, useEffect, useState } from "react";
import "datatables.net-dt";
import "datatables.net-dt/css/jquery.dataTables.css";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { toJS, untracked } from "mobx";
import { Button } from "react-bootstrap";
import { ChangeOwnerModalJQ } from "./ChangeOwnerModalJQ";
import * as React from "react";
import $ from "jquery";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";
import { CO2_Loading } from "../../Assets/CO2_Loading";
import { useHistory } from "react-router-dom";
import { AddVehicleModalJQ } from "./AddVehicleModalJQ";
import { AddVehicleMinMaxModalJQ } from "./AddVehicleMinMaxModalJQ";
import { useTranslation } from "react-i18next";

export const UnitsDataTableJQ = inject("store")(
  observer((props) => {
    const { t } = useTranslation("asset_profile_management");
    const { theme, _handleSelectedDetail, lvlCompanies, filteredData } = props;

    const { landingAscent, role } = props.store.common;

    const {
      deviceList,
      _handleSelectChange,
      setCanbusChecked,
      setGpsChecked,
      _handleEdit,
      _handleEditMinMax,
      _setToDelete,
      companyId,
      unitData,
      _handleSetUnitData,
      gpsChecked,
    } = props.store.assetProfile;

    const addVehicleModal = () => {
      let myModal = new window.bootstrap.Modal(
        document.getElementById("addVehicleModalJQ")
      );
      myModal.show();
    };

    const addVehicleMinMaxModal = () => {
      let myModal = new window.bootstrap.Modal(
        document.getElementById("addVehicleMinMaxModalJQ")
      );
      myModal.show();
    };

    const confirmationModal = () => {
      let myModal = new window.bootstrap.Modal(
        document.getElementById("confirmationModalJQ")
      );
      myModal.show();
    };

    const changeOwnerModal = () => {
      let myModal = new window.bootstrap.Modal(
        document.getElementById("changeownermodalJQ")
      );
      myModal.show();
    };

    let historicalEmission = [
      ["GBH8992T_0436517", 305.2],
      ["SLQ298B_0416725", 303.39],
      ["PC8022D_0446052", 339.1],
      ["YP4020K_9541103", 559.34],
      ["GBJ1871U_0416741", 358.24],
    ];

    const historicalEmissionMap = Object.fromEntries(historicalEmission);

    const rows = deviceList?.map((data, index) => {
      let unit = {
        id: data.id,
        enableCANbus: data.unitSetting.enableCANbus,
        enableGPS: data.unitSetting.enableGPS,
        companyId: data.ownerId,
        company: data.name,
        grouping: data.grouping || "N/A",
        plate_number: data.plate_number || "N/A",
        model: data.model || "N/A",
        cargo_type: data.cargo_type || "N/A",
        fuel_type: data.fuel_type || "N/A",
        brand: data.brand || "N/A",
        vin: data.vin_number || "N/A",
        // oem: data.manual_baseline_emission_km || "N/A",
        oem: data.baselineEmission + `${data.emissionTypeUnit}` || "",
        // historicalEmission: data.oem_history || "N/A",
        baselineEmissionType: data.baselineEmissionType || "N/A",
        // automation_status: "" + data.automation_status,
        active_status: data.is_active,
        gps: "" + data.unitSetting.enableGPS,
        CAMbus: "" + data.unitSetting.enableCANbus,
        action: "",
      };
      // console.log("active_status",unit)
      return unit;
    });

    // const [selectedCompany, setSelectedCompany] = useState("All");
    // const filteredData =
    //   selectedCompany === "All"
    //     ? rows
    //     : rows.filter((v, i) => v.companyId === selectedCompany);

    const [unitId, setUnitId] = React.useState();
    const [editId, setEditId] = React.useState();
    const [deleteId, setDeleteId] = React.useState();

    const tableRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      const styleElement = document.createElement("style");
      styleElement.innerHTML =
        theme === "dark"
          ? `
            .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
                color: black !important;
                border: 1px solid #e5e5e5!important;
                
                background-color: #e5e5e5 !important;
                /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #e5e5e5), color-stop(100%, #e5e5e5))!important; */
                background: -webkit-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
                background: -moz-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
                background: -ms-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%) !important;
                background: -o-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
                background: linear-gradient(to bottom, #e5e5e5 0%, #e5e5e5 100%)!important;
              }

              .dataTables_wrapper .dataTables_paginate .paginate_button.current {
                color: black !important;
                border: 1px solid #C6C6C6!important;
                
                background-color: #C6C6C6 !important;
                /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fffcfc), color-stop(100%, #969292))!important; */
                background: -webkit-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                background: -moz-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                background: -ms-linear-gradient(top, #fffcfc 0%, #969292 100%) !important;
                background: -o-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                background: linear-gradient(to bottom, #fffcfc 0%, #969292 100%)!important;
              }

              .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
                color: black !important;
                border: 1px solid #C6C6C6!important;
                
                background-color: #C6C6C6 !important;
                /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fffcfc), color-stop(100%, #969292))!important; */
                background: -webkit-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                background: -moz-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                background: -ms-linear-gradient(top, #fffcfc 0%, #969292 100%) !important;
                background: -o-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                background: linear-gradient(to bottom, #fffcfc 0%, #969292 100%)!important;
              }


            `
          : `
                .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
                    color: black !important;
                    border: 1px solid #e5e5e5!important;
                    
                    background-color: #e5e5e5 !important;
                    /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #e5e5e5), color-stop(100%, #e5e5e5))!important; */
                    background: -webkit-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
                    background: -moz-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
                    background: -ms-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%) !important;
                    background: -o-linear-gradient(top, #e5e5e5 0%, #e5e5e5 100%)!important;
                    background: linear-gradient(to bottom, #e5e5e5 0%, #e5e5e5 100%)!important;
                  }

                  .dataTables_wrapper .dataTables_paginate .paginate_button.current {
                    color: black !important;
                    border: 1px solid #C6C6C6!important;
                    
                    background-color: #C6C6C6 !important;
                    /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fffcfc), color-stop(100%, #969292))!important; */
                    background: -webkit-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                    background: -moz-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                    background: -ms-linear-gradient(top, #fffcfc 0%, #969292 100%) !important;
                    background: -o-linear-gradient(top, #fffcfc 0%, #969292 100%)!important;
                    background: linear-gradient(to bottom, #fffcfc 0%, #969292 100%)!important;
                  }
       
            `;
      document.head.appendChild(styleElement);

      function updateOrderAndDisplayStart(columnIndex, direction) {
        orderColumn = columnIndex;
        orderDirection = direction;

        table.order([[orderColumn, orderDirection]]).draw();
      }

      const storedOrderColumn = localStorage.getItem("orderColumn");
      const storedOrderDirection = localStorage.getItem("orderDirection");

      let orderColumn = storedOrderColumn ? parseInt(storedOrderColumn) : 0;
      let orderDirection = storedOrderDirection || "asc";

      const table = $("#unitsTable").DataTable({
        autoWidth: true,
        responsive: true,
        retrieve: true,
        scrollX: true,
        order: [[1, "asc"]],
        // language: {
        //     searchPlaceholder: 'Search',
        // },

        lengthMenu: [8, 10, 15],

        data: filteredData,
        columns: [
          { title: t("Company"), data: "company" },
          { title: t("Grouping"), data: "grouping" },
          { title: t("Plate Number"), data: "plate_number" },
          { title: t("Model"), data: "model" },
          { title: t("Cargo Type"), data: "cargo_type" },
          { title: t("Fuel Type"), data: "fuel_type" },
          { title: t("Brand"), data: "brand" },
          { title: t("VIN Number"), data: "vin" },
          {
            title: t("Baseline Emission Intensity"),
            // "OEM" +
            // '<span style="font-size:100%" title="OEM Baseline Emission (gCO2e/km)" class="fa fa-info-circle"></span>',
            data: "oem",
          },
          // {
          //   title: t("Historical Baseline Emission Factor"),
          //   data: "historicalEmission",
          // },
          { title: t("Baseline Type"), data: "baselineEmissionType" },
          // {
          //   title: "Automation Status",
          //   data: "automation_status",
          //   render: function (data, type, row) {
          //     const Colors = {
          //       low: "green",
          //       high: "red",
          //     };

          //     if (data === "true") {
          //       return (
          //         '<i class="fa fa-check pe-1" style="color: ' +
          //         Colors.low +
          //         ';"></i>'
          //       );
          //     } else if (data === "false") {
          //       return (
          //         '<i class="fas fa-times pe-1" style="color: ' +
          //         Colors.high +
          //         ';"></i>'
          //       );
          //     } else {
          //       return data;
          //     }
          //   },
          // },
          {
            title: "Status",
            data: "active_status",
            render: function (data, type, row) {
              if (data === "active") {
                return "Active";
              } else {
                return "Inactive";
              }
            },
          },
          {
            title: "GPS",
            data: "gps",
            render: function (data, type, row) {
              const Colors = {
                low: "green",
                high: "red",
              };

              if (data === "true") {
                return (
                  '<i class="fa fa-check pe-1" style="color: ' +
                  Colors.low +
                  ';"></i>'
                );
              } else if (data === "false") {
                return (
                  '<i class="fas fa-times pe-1" style="color: ' +
                  Colors.high +
                  ';"></i>'
                );
              } else {
                return data;
              }
            },
          },
          {
            title: t("CANbus"),
            data: "CAMbus",
            render: function (data, type, row) {
              const Colors = {
                low: "green",
                high: "red",
              };

              if (data === "true") {
                return (
                  '<i class="fa fa-check pe-1" style="color: ' +
                  Colors.low +
                  ';"></i>'
                );
              } else if (data === "false") {
                return (
                  '<i class="fas fa-times pe-1" style="color: ' +
                  Colors.high +
                  ';"></i>'
                );
              } else {
                return data;
              }
            },
          },
          {
            title: t("Action"),
            render: function (data, type, row) {
              // return '<span class="py-2">' +
              //   '<button type="button" class="btn btn-sm m-1" ' +
              //   `style="background-color: #4c5d45; ` +
              //   ` data-unitId=${JSON.stringify(row.id)} ` +
              //   `onclick="editMinMax(event);">` +
              //   ` <i class="bi bi-sliders" title="Edit" style="color: white;" data-unitId=${JSON.stringify(
              //     row.id
              //   )}></i> ` +
              //   "</button>" +
              //   "</span>"

              return `
                  ${
                    role === "super_admin" && companyId === row.companyId
                      ? `<button type="button" id="change-owner-button" class="btn sm m-1"
                            style="background-color: #4c5d75; color: white;"
                            data-unitId=${JSON.stringify(row.id)}
                            onclick="changeOwnerButtonClick(event)">
                            ${t("Change Owner")}      
                    </button>`
                      : ""
                  }

                  ${
                    landingAscent?.enableSetupUnit === 1
                      ? role === "user"
                        ? ""
                        : `<button type="button" class="btn btn-sm m-1"
                    style="background-color: #4c5d75; color: white;"
                      data-unitId=${JSON.stringify(row.id)}
                      onclick="editClick(event)">
                      <i class="fas fa-edit" title="Vehicle Profile" style="color: white;" data-unitId=${JSON.stringify(
                        row.id
                      )}></i>
                  </button>

                  <button type="button" class="btn btn-sm m-1"
                    style="background:  #4c5d45; color: white;"
                    data-unitId=${JSON.stringify(row.id)}
                    data-toggle="modal" data-target="#companyinfomodal"
                    onclick="editMinMax(event)"
                  >
                    <i class="bi bi-sliders" title="Edit Threshold" style="color: white;" data-unitId=${JSON.stringify(
                      row.id
                    )}></i>   
                  </button>
        
                    <button type="button" class="btn btn-danger btn-sm m-1"
                      data-unitId=${JSON.stringify(row.id)}
                      onclick="deleteClick(event)">
                      <i class="bi bi-trash-fill" title="Delete" data-unitId=${JSON.stringify(
                        row.id
                      )}></i>
                    </button>`
                      : ""
                  }
                  `;
            },
          },
        ],
        columnDefs:
          companyId === "96888485"
            ? [
                {
                  target: [0],
                  width: "15%",
                },
                {
                  target: [1],
                  width: "15%",
                },
                {
                  target: [7],
                  width: "15%",
                },
                {
                  target: [10],
                  width: "5%",
                },
                {
                  targets: [2, 3],
                  width: "10%",
                },
                {
                  target: [4, 5, 6, 11, 12, 8],
                  width: "5%",
                },
                // {
                //   target: [13],
                //   width: "15%",
                // },
              ]
            : [
                {
                  target: [0],
                  width: "10%",
                },
                {
                  target: [1],
                  width: "10%",
                },
                {
                  target: [7],
                  width: "10%",
                },
                {
                  target: [10],
                  width: "10%",
                },
                // {
                //   target: [13],
                //   width: "10%",
                // },
                {
                  target: [2, 3, 4, 5, 6],
                  width: "5%",
                },
              ],
        paging: filteredData?.length > 0,

        drawCallback: function (settings) {
          console.log($("#unitsTable").DataTable().page.info());
          console.log(settings.oSavedState);
        },
      });

      // table.column(0).nodes().to$().css("width", "15%");
      // table.column(1).nodes().to$().css("width", "10%");
      // table.column(2).nodes().to$().css("width", "10%");
      // table.column(3).nodes().to$().css("width", "10%");
      // table.column(4).nodes().to$().css("width", "12%");
      // table.column(5).nodes().to$().css("width", "10%");
      // table.column(7).nodes().to$().css("width", "7%");
      // table.column(8).nodes().to$().css("width", "7%");
      // table
      //   .column(10)
      //   .nodes()
      //   .to$()
      //   .css(
      //     "width",
      //     role === "super_admin" && companyId === filteredData.companyId
      //       ? "25%"
      //       : "14%"
      //   );

      table.cells().every(function () {
        $(this.node()).css("border-color", "rgb(211, 211, 211, 0.3)");
      });

      const searchInput = $(".dataTables_filter input");
      searchInput.css("color", theme === "light" ? "black" : "white");

      const searchlabel = $(".dataTables_filter label");
      searchlabel.css("color", theme === "light" ? "black" : "white");

      const entriesSelect = $(".dataTables_length select");
      entriesSelect.css(
        "background-color",
        theme === "light" ? "#fff" : "#202b60"
      );
      entriesSelect.css("color", theme === "light" ? "black" : "white");

      const entriesLabel = $(".dataTables_length label");
      entriesLabel.css("color", theme === "light" ? "black" : "white");

      const tableInfo = $(".dataTables_info");
      tableInfo.css("color", theme === "light" ? "#000" : "#fff");

      const paginate = $(".dataTables_paginate paginate_button");
      paginate.css("color", "#000 !important");

      // const paginateButton = $('.dataTables_wrapper .dataTables_paginate .paginate_button');
      // paginateButton.css('color', '#000 !important');
      // paginateButton.css('border', '1px solid #white !important');
      // paginateButton.css('background-color', '#c6c6c6bf!important');
      // paginateButton.css('background', '-webkit-linear-gradient(top, #C6C6C6 0%, #C6C6C6 100%)!important');
      // paginateButton.css('background', '-moz-linear-gradient(top, #C6C6C6 0%, #C6C6C6 100%)!important');
      // paginateButton.css('background', '-ms-linear-gradient(top, #C6C6C6 0%, #C6C6C6 100%) !important');
      // paginateButton.css('background', '-o-linear-gradient(top, #C6C6C6 0%, #C6C6C6 100%)!important');
      // paginateButton.css('background', 'linear-gradient(to bottom, #C6C6C6 0%, #C6C6C6 100%)!important');

      $("#unitsTable").on("click", "tbody tr", function () {
        const data = table.row(this).data();
        _handleSetUnitData(data);
      });

      const storedCurrentPage = localStorage.getItem("currentPage");
      const storedSearchKeyword = localStorage.getItem("searchKeyword");

      const currentPage = storedCurrentPage ? parseInt(storedCurrentPage) : 0;
      table.page(currentPage).draw(false);

      if (storedSearchKeyword) {
        table.search(storedSearchKeyword).draw();
      }

      table.on("page.dt", function () {
        localStorage.setItem("currentPage", table.page.info().page);
      });

      table.on("search.dt", function () {
        const searchKeyword = table.search();
        localStorage.setItem("searchKeyword", searchKeyword);
      });

      window.changeOwnerButtonClick = function (event) {
        event.preventDefault();
        const unitId = event.target.dataset.unitid;
        const unit = rows.find((r) => r.id === unitId);

        _handleSelectChange(unit.companyId);
        setUnitId(unit.id);
        setCanbusChecked(unit.enableCANbus);
        setGpsChecked(unit.enableGPS);
        // updateOrderAndDisplayStart('asc');
        changeOwnerModal();
      };

      window.editClick = function (event) {
        const editId = event.target.dataset.unitid;
        const unit = rows.find((r) => r.id == editId);
        console.log("unit --->", unit);
        addVehicleModal();
        _handleEdit(unit);
        // updateOrderAndDisplayStart('asc');
      };

      window.editMinMax = function (event) {
        const editId = event.target.dataset.unitid;
        const unit = rows.find((r) => r.id == editId);

        addVehicleMinMaxModal();
        _handleEditMinMax(unit);
      };

      window.deleteClick = function (event) {
        const deleteId = event.target.dataset.unitid;
        const unit = rows.find((r) => r.id === deleteId);
        console.log("unnit===>", unit);
        confirmationModal();
        _setToDelete(unit);
        // updateOrderAndDisplayStart('asc');
        // event.preventDefault();
        // event.stopPropagation();
      };

      window.addEventListener("beforeunload", function () {
        localStorage.removeItem("orderColumn");
        localStorage.removeItem("orderDirection");
        localStorage.removeItem("currentPage");
      });

      window.addEventListener("load", function () {
        localStorage.removeItem("orderColumn");
        localStorage.removeItem("orderDirection");
        localStorage.removeItem("currentPage");
      });

      const storedPage = localStorage.getItem("currentPage");

      if (storedPage) {
        const currentPage = parseInt(storedPage);
        table.page(currentPage).draw("page");
      }

      return () => {
        if ($.fn.DataTable.isDataTable("#unitsTable")) {
          table.destroy();
        }
      };
    }, [filteredData, theme, rows]);

    return (
      <div>
        <table
          ref={tableRef}
          id="unitsTable"
          className="display cell-border hover stripe"
          style={{ width: "100%" }}
        ></table>

        <ChangeOwnerModalJQ
          theme={theme}
          lvlCompanies={lvlCompanies}
          unitId={unitId}
          deviceList={deviceList}
          checked={gpsChecked}
        />
        <AddVehicleModalJQ theme={theme} />
        <AddVehicleMinMaxModalJQ theme={theme} />
      </div>
    );
  })
);
