import { Selector } from "../../Tools";
import { inject, observer } from "mobx-react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { FuelHistoryTable } from "./FuelHistoryTable";
import moment from "moment";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { toJS } from "mobx";
import { filter } from "d3";

export const FuelRecord = inject("store")(
  observer((props) => {
    const {t} = useTranslation("admin-panel")
    const { theme, filteredfuelhistorydatafordriver } = props;
    const cardStyle1 = {
      background: Colors[theme].background,
      boxShadow: "0px 5px 10px rgba(0,0,0,0.25)",
      padding: "15px",
      borderRadius: "5px",
      marginBottom: 8,
      color: invertDark(theme),
      // minHeight: 193.69,
    };

    const {
      monthOption,
      month,
      yearOption,
      year,
      _handleSelectMonth,
      _handleSelectYear,
      _fetchVehicleLst,
      vehicleHistoryOption,
      _handleSelectVehicle,
      selectedHistoryVehicles,
      fuelHistoryDataForDriver,
      _setViewImage
    } = props.store.driver;

    const { dashboardYears } = props.store.dashboard

    useEffect(() => {
      _fetchVehicleLst();
    }, []);

    const rows = fuelHistoryDataForDriver?.map((value) => {
      let history = {
        date: moment.utc(value.record_date).format("yyyy-MM-DD HH:mm:ss"), 
        license: value.plate_number,
        fuel: value.fuel_type,
        litres:
          value.fuel_type === "Battery"
            ? value.consumed + " kWh"
            : value.consumed + " L",
        amount: Number(value.amount).toLocaleString(),
      };
      return history;
    });

    return (
      <>
        <div className="col-12 p-1" style={{paddingTop: '3em'}}>
          <h4 style={{ color: invertDark(theme), paddingRight: 30 }}>
            {t("Fuel Record")}
          </h4>

          <div style={cardStyle1}>
            <div className="d-flex align-items-center mb-2">
              <span className="col-4 pe-2">
                <Selector
                  label={t("Year")}
                  defaultValue={dashboardYears.filter((v) => v.value === year)}
                  _handleSelect={(e) => _handleSelectYear(e)}
                  options={dashboardYears}
                />
              </span>
              <span className="col-4 pe-2">
                <Selector
                  label={t("Month")}
                  defaultValue={monthOption.filter((v) => v.value === month)}
                  _handleSelect={(e) => _handleSelectMonth(e)}
                  options={monthOption}
                />
              </span>
              <span className="col-4">
                <Selector
                  label={t("Vehicles")}
                  defaultValue={selectedHistoryVehicles}
                  _handleSelect={(e) => _handleSelectVehicle(e)}
                  options={vehicleHistoryOption}
                />
              </span>
            </div>

            <FuelHistoryTable
              theme={theme}
              rows={rows}
              _setViewImage = {_setViewImage}
              data={fuelHistoryDataForDriver}
              filteredfuelhistorydatafordriver={filteredfuelhistorydatafordriver}

            />

          </div>
        </div>
      </>
    );
  })
);
