import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { CompanySelector } from "../../Components/Dashboard/companySelector";
import { ConfirmDeleteCSVfileModal } from "../../Components/DataImport/ConfirmDeleteCSVfileModal";
import { ConfirmSubmitCSVfileModal } from "../../Components/DataImport/ConfirmSubmitCSVfileModal";
import { SampleDownloadCSV } from "../../Components/DataImport/SampleDownloadCSV";
import { DataImportHistoryTable } from "../../Components/DataImport/DataImportHistoryTable";
import { Link, useLocation } from "react-router-dom";
import { TimeSelectorForDataImport } from "../../Components/DataImport/TimeSelectorForDataImport";
import { CO2_Loading } from "../../Assets/CO2_Loading";
import { YearMonthSelector } from "../../Components/DataImport/YearMonthSelector";
import { Cookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { toJS } from "mobx";

export const DataImport = inject("store")(
  observer((props) => {
    const {t} = useTranslation("data_import")
    const { theme } = props;
    const { role } = props.store.common;
    const {
      handleTripCsvExport,
      handleVehicleCsvExport,
      handleEngineHoursExport,
      handleManualEntryExport,
      handleGetTableNumber,
      handleGetImportUnitsHistory,
      handleGetImportTripsHistory,
      handleGetImportEngineHour,
      handleGetImportManualEntry,
      importUnitProfileHistory,
      importTripHistory,
      importEngineHours,
      importManualEntry,
      handleDownloadCSV,
      showTable,
      _handleFetchCompanyYears,
      companyyearlydata,
      year,
      month,
      handleYearSelect,
      handleMonthSelect,
      handleGetTableNumberOtherRoute,
      companyName,
      SubmitLoading, loading,vProfilesData
    } = props.store.dataImport;
    // console.log("vProfilesData =====>",toJS(vProfilesData))
    // console.log("loading =======>", loading)
    // // const {
    //   setCurrentMonth,
    //   setCurrentYear,
    //   _handleQueryParam,
    //   dashboardYears
    // } = props.Store.dashboard;

    // const cookie = new Cookies();
    // const companyId = cookie.get('company') || props.store.common.companyId
    // const { width, windowResize, token, lastUpdate, isAds } =
    //     props.store.common;

    const [showInfo, setShowInfo] = useState(false);
    const _handleClose = () => {
      setShowInfo(false);
    };

    const cardStyle1 = {
      background: Colors[theme].background,
      boxShadow: "0px 5px 10px rgba(0,0,0,0.25)",
      padding: "15px",
      borderRadius: "5px",
      marginBottom: 8,
      color: invertDark(theme),
      // cursor: "pointer",
    };

    const rowStyle = {
      background: theme === "dark" ? Colors.darkBlue : Colors.Dark[5],
      borderRadius: "5px",
      fontSize: "12px",
      flex: 1,
    };

    const cardStyle = {
      background: Colors[theme]?.background,
      boxShadow: "2px 4px 11px rgba(0,0,0,0.25)",
      padding: "10px",
      width: "420px",
      borderRadius: "15px",
      fontSize: "12px",
      color: Colors.Dark[theme === "dark" ? 4 : 1],
      height: "145px",
      transitionDuration: "0.1s",
    };

    // const cookies = new Cookies();
    // const companyName = cookies.get("companyName");

    function useQuery() {
      const { search } = useLocation();

      return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let queryMonth = useQuery().get("month");
    let queryYear = useQuery().get("year");

    const isLoading = false;

    const pathName = window.location.pathname;

    // console.log(showTable);

    useEffect(() => {
      if (role === "super_admin" || companyName === "Volvo") {
        if (showTable === "table1") {
          handleVehicleCsvExport();
          handleGetImportUnitsHistory(year, month);
        } else if (showTable === "table2") {
          handleTripCsvExport();
          handleGetImportTripsHistory(year, month);
        } else if (showTable === "table3") {
          handleEngineHoursExport();
          handleGetImportEngineHour(year, month);
        } else {
          handleManualEntryExport();
          handleGetImportManualEntry(year, month);
        }
      } else {
        handleManualEntryExport();
        handleGetImportManualEntry(year, month);
      }
      _handleFetchCompanyYears();

      // if (pathName.includes('data_import')) {
      //   cookies.remove("storedTable");
      // }

      // if (queryYear && queryMonth) {
      //   _handleQueryParam(queryYear, queryMonth);

      // }
    }, [showTable]);

    return (
      <>
        <div className="col d-flex align-items-center">
          <span
            className="col-4 ps-0 "
            style={{
              fontSize: 23,
              color:
                theme === "dark" ? Colors["light"].background : Colors.Dark[2],
            }}
          >
            {t("Data Import")}
          </span>
        </div>
        <div className="row">
          <span className="col-12">
            <div className="d-flex flex-row justify-content-between align-items-center flex-wrap pt-2 pb-2">
              <div className="align-self-center">
                <CompanySelector theme={theme} label={t("Organisation")+": "} />
              </div>
              <div>
                {/* <TimeSelectorForDataImport companyyearlydata={companyyearlydata} /> */}
                <YearMonthSelector
                  {...props}
                  companyyearlydata={companyyearlydata}
                  handleYearSelect={handleYearSelect}
                  handleMonthSelect={handleMonthSelect}
                  theme={theme}
                />
              </div>
            </div>
          </span>
        </div>

        <SampleDownloadCSV
          companyName={companyName}
          role={role}
          theme={theme}
          cardStyle={cardStyle}
          cardStyle1={cardStyle1}
          // companyyearlydata={companyyearlydata}
          ConfirmDeleteCSVfileModal={ConfirmDeleteCSVfileModal}
          ConfirmSubmitCSVfileModal={ConfirmSubmitCSVfileModal}
        />

        {/* <SampleCSVTables
          theme={theme}
          cardStyle={cardStyle}
          cardStyle1={cardStyle1}
          ConfirmDeleteCSVfileModal={ConfirmDeleteCSVfileModal}
          ConfirmSubmitCSVfileModal={ConfirmSubmitCSVfileModal} /> */}
        {/* {importUnitProfileHistory.length > 0 ?
          <DataImportHistoryTable
            theme={theme}
            importUnitProfileHistory={importUnitProfileHistory}
            importTripHistory={importTripHistory}
            importEngineHours={importEngineHours}
            importManualEntry={importManualEntry}
            handleDownloadCSV={handleDownloadCSV}
            showTable={showTable}
          /> : <CO2_Loading />
        } */}
          <DataImportHistoryTable
          companyName={companyName}
          role={role}
          theme={theme}
          importUnitProfileHistory={importUnitProfileHistory}
          importTripHistory={importTripHistory}
          importEngineHours={importEngineHours}
          importManualEntry={importManualEntry}
          handleDownloadCSV={handleDownloadCSV}
          showTable={showTable}
        />
        

        {/* {importEngineHours.length>0 ? <>
          <DataImportHistoryTable
          companyName ={companyName}
          role= {role}
          theme={theme}
          importUnitProfileHistory={importUnitProfileHistory}
          importTripHistory={importTripHistory}
          importEngineHours={importEngineHours}
          importManualEntry={importManualEntry}
          handleDownloadCSV={handleDownloadCSV}
          showTable={showTable}
        /> 
        </> : <>
         <div className="text-center" style={{ color: invertDark(theme) }}>
                        <i
                            className="fa fa-exclamation-triangle me-2"
                            style={{ marginTop: "20%", color: "#fff"}}
                        />
                        No Data Found for Engine Hours

                    </div>
        </>} */}
      </>
    );
  })
);
