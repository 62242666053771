import { useEffect } from "react";
import { Colors } from "../../Assets/Config/color.config";
import { My_Button, My_DatePicker, Selector } from "../../Tools";
import { inject, observer } from "mobx-react";
import trackAndTrace from "../../Store/trackAndTrace";

export const ReportFilter = inject("store")(
    observer((props) => {
        const { setCreateMode, theme } = props;
        const { fetchFMSVehicles, fmsVehicleLst } = props.store.tracking
        const { assetTypeOptions, reportTypeOptions, reportType, fuelTypeOptions, _handleFilter, assetType, fuelType, startTime, endTime, _handleCheck, _handleCreateReport } = trackAndTrace;

        useEffect(() => {
            fetchFMSVehicles()
        }, [])

        const filteredList = assetType === 'all' && fuelType === 'all' ? fmsVehicleLst
            : assetType === 'all' && fuelType !== 'all' ? fmsVehicleLst?.filter(v => v.type === fuelType)
                : assetType !== 'all' && fuelType === 'all' ? fmsVehicleLst?.filter(v => v.type === assetType)
                    : fmsVehicleLst?.filter(v => v.type === assetType && v.type === fuelType)
        return (<div className="p-3" style={{ background: Colors[theme].background, width: '600px' }}>
            <div className="d-flex justify-content-between">
                <span style={{ fontSize: 20, fontWeight: 'bold' }}>Create Report</span><i onClick={() => setCreateMode(false)} style={{ cursor: 'pointer' }} className="bi bi-x-lg" />
            </div>
            <div className="row">

                <div className="col-6">
                    {/* <div><Selector defaultValue={assetTypeOptions.filter((v) => v.value === assetType)}
                        _handleSelect={(e) => _handleFilter(e, 'assetType')} menuListColor="black" options={assetTypeOptions} label='Asset Type' />
                    </div> */}
                    <div><Selector defaultValue={reportTypeOptions.filter((v) => v.value === reportType)}
                        _handleSelect={(e) => _handleFilter(e, 'reportType')} menuListColor="black" options={reportTypeOptions} label='Report Type' />
                    </div>
                    <div className="mt-2"><Selector defaultValue={fuelTypeOptions.filter((v) => v.value === fuelType)}
                        _handleSelect={(e) => _handleFilter(e, 'fuelType')} menuListColor="black" options={fuelTypeOptions} label='Fuel Type' />
                    </div>

                    {reportType === 'Monthly' ?
                        <><label className="fw-bold mt-2">Month:</label>
                            <My_DatePicker height={38} dateFormat='MMMM' selected={startTime} noTimeInput showMonthYearPicker onChange={(e) => _handleFilter(e, 'month')} />
                        </> :
                        <><label className="fw-bold mt-2">Year:</label>
                            <My_DatePicker height={38} dateFormat='yyyy' selected={startTime && endTime} noTimeInput showYearPicker onChange={(e) => _handleFilter(e, 'year')} />
                        </>}

                </div>
                <div className="col-6">
                    <div className="fw-bold">
                        Units
                        <div style={{ background: 'rgb(255,255,255,0.1)', borderRadius: 5 }}>
                            <div className="p-2" style={{ height: 238, overflow: 'scroll' }}>
                                {filteredList?.map((v, i) => (<div><input className="me-2" type='checkBox' onClick={() => _handleCheck(v.grouping, v.id)} />{v.grouping}</div>))}
                            </div>

                        </div>

                    </div>
                </div>
                <div className="row m-0" data-bs-toggle="modal"
                    data-bs-target="#createReportModal"
                    data-toggle="modal">
                    <My_Button onClick={_handleCreateReport} text='Create' className='mt-4' />
                </div>
            </div>



        </div>)
    }))