import { useEffect, useState } from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config"; import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { FormControl } from "@mui/material";

import { My_Button, My_Input, My_upload_image, Selector } from "../../Tools";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import { useTranslation } from "react-i18next";


export const ChangeOwnerModalJQ = inject("store")(
    observer((props) => {
        const {t} = useTranslation("asset_profile_management")
        const { theme, lvlCompanies, unitId, deviceList, checkedGps, checkedCanbus } = props;
        const { role, companyId } = props.store.common;
        const { _handleSelectChange, _handleChangeOwner, selectedCompany, gpsChecked,statusChecked,setStatusChecked, canbusChecked, setGpsChecked, setCanbusChecked, unitData } = props.store.assetProfile;

        const companyOptions = lvlCompanies?.map((v) => ({
            value: v.companyId,
            label: v.name,

        }));

        // let GpsTrue = checkedGps ? checkedGps : false;
        // let CanbusTrue = checkedCanbus ? checkedCanbus : false;


        const handleStatusSwitch = (e) => {
            if(statusChecked ==="active"){
                setStatusChecked("un_active")
            }else{
                setStatusChecked("active")
            }
            console.log("statuschecked===>",statusChecked)
        }

        const handleGpsSwitch = (e) => {
            setGpsChecked(!gpsChecked)
        }

        const handleCanbusSwitch = (e) => {
            setCanbusChecked(!canbusChecked)
        }


        
        // const CO2Switch = styled((props) => (
        //     <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
        //   ))(({ theme }) => ({
        //     width: 42,
        //     height: 26,
        //     padding: 0,
        //     '& .MuiSwitch-switchBase': {
        //       padding: 0,
        //       margin: 2,
        //       transitionDuration: '300ms',
        //       '&.Mui-checked': {
        //         transform: 'translateX(16px)',
        //         color: '#fff',
        //         '& + .MuiSwitch-track': {
        //           backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        //           opacity: 1,
        //           border: 0,
        //         },
        //         '&.Mui-disabled + .MuiSwitch-track': {
        //           opacity: 0.5,
        //         },
        //       },
        //       '&.Mui-focusVisible .MuiSwitch-thumb': {
        //         color: '#33cf4d',
        //         border: '6px solid #fff',
        //       },
        //       '&.Mui-disabled .MuiSwitch-thumb': {
        //         color:
        //           theme.palette.mode === 'light'
        //             ? theme.palette.grey[100]
        //             : theme.palette.grey[600],
        //       },
        //       '&.Mui-disabled + .MuiSwitch-track': {
        //         opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        //       },
        //     },
        //     '& .MuiSwitch-thumb': {
        //       boxSizing: 'border-box',
        //       width: 22,
        //       height: 22,
        //     },
        //     '& .MuiSwitch-track': {
        //       borderRadius: 26 / 2,
        //       backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        //       opacity: 1,
        //       transition: theme.transitions.create(['background-color'], {
        //         duration: 500,
        //       }),
        //     },
        // }));

        const filteredDevicelist = deviceList?.find(v => v.id === unitId)
        console.log("status ======>",filteredDevicelist?.active_status)

        // useEffect(() => {
        //     setStatusChecked(filteredDevicelist?.is_active)
        //   }, [statusChecked]);
        return (
          <div
            className="modal fade show"
            id="changeownermodalJQ"
            tabIndex="-1"
            aria-labelledby="changeownermodalJQ"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-md rounded">
              <div
                className="modal-content"
                style={{
                  background: Colors[theme]?.background,
                  color: invertDark(theme),
                }}
              >
                <div
                  className="modal-header"
                  style={{
                    background: Colors[theme]?.background,
                    color: invertDark(theme),
                    border: "none",
                    padding: 6,
                  }}
                >
                  <span
                    style={{
                      fontSize: 24,
                      color: theme === "dark" ? "#fff" : Colors[theme].color,
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                      marginLeft: 2,
                    }}
                  >
                    {t("Change Owner for")} {filteredDevicelist?.grouping}
                  </span>

                  <span
                    data-bs-dismiss="modal"
                    style={{ cursor: "pointer", marginRight: 5 }}
                  >
                    <i className="bi bi-x-lg" />
                  </span>
                </div>

                <div
                  className="modal-body"
                  style={{ background: Colors[theme]?.background }}
                >
                  <div
                    style={{ border: theme === "dark" ? "" : "none" }}
                    className="d-flex flex-column justify-content-center"
                  >
                    <div className="row pb-3">
                      <div className="col-3 d-flex justify-content-between align-items-center">
                        <span className="text-nowrap text-start ">
                          {t("Company")}
                        </span>
                        <span>:</span>
                      </div>
                      <div className="col-9">
                        <span className="w-100">
                          <Selector
                            options={companyOptions}
                            value={companyOptions?.find(
                              (v) => v.value === selectedCompany
                            )}
                            menuListColor="black"
                            _handleSelect={(e) => {
                              _handleSelectChange(e.value);
                              _handleSelectChange(e.value);
                            }}
                          />
                        </span>
                      </div>
                    </div>

                    <div className="row pb-3">
                      <div className="col-3 d-flex justify-content-between align-items-center">
                        <span className="text-nowrap text-start ">
                          {t("Setting")}
                        </span>
                        <span>:</span>
                      </div>

                      <div className="col-9">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            checked={gpsChecked}
                            onChange={(e) => handleGpsSwitch(e)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexSwitchCheckDefault"
                          >
                            {t("GPS Tracker")}
                          </label>
                        </div>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                            checked={canbusChecked}
                            onChange={(e) => handleCanbusSwitch(e)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexSwitchCheckChecked"
                          >
                            {t("CANbus Adaptor")}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row pb-3">
                      <div className="col-3 d-flex justify-content-between align-items-center">
                        <span className="text-nowrap text-start ">
                          {t("Status")}
                        </span>
                        <span>:</span>
                      </div>

                      <div className="col-9">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            checked={statusChecked==="active" || filteredDevicelist?.active_status ==="active" ? true:false}
                            onChange={(e) =>handleStatusSwitch(e)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexSwitchCheckDefault"
                          >
                            {statusChecked==="active"
                              ? "Active"
                              : "Inactive"}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" py-3 px-3 text-end" data-bs-dismiss="modal">
                  <My_Button
                    onClick={() =>
                      _handleChangeOwner(unitId, gpsChecked,canbusChecked)
                    }
                    customColor={theme === "dark" ? "#0064FB" : "#04c600"}
                    customHoverColor={theme === "dark" ? "" : "#05e700"}
                    className="px-3 "
                    text={t("Update")}
                  />
                </div>
              </div>
            </div>
          </div>
        );
    }));


// const assigncompanyoption = [
//     {value: 'KumoSolution', label: 'Kumo Solution'},
//     {value: 'Evercomm', label: 'Evercomm'},
//     {value: 'ABCcompany', label: 'ABC Company'}
// ]
