import { Colors, invertDark } from "../../Assets/Config/color.config";
import { My_Button } from "../../Tools";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState, useRef } from "react";
import { My_DatePicker } from "../../Tools";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Cookies } from "react-cookie";
import { Submit_Loading } from "../../Assets/Submit_Loading";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useTranslation } from "react-i18next";
import { Checkbox, useForkRef } from "@mui/material";

export const ConfirmSubmitCSVfileModal = inject("store")(
  observer((props) => {
    const { t } = useTranslation("data_import");
    const { id, showTable, history } = props;
    const { role } = props.store.common;
    const { theme } = props.store.login;
    const {
      handleCreateVTrip,
      handleCreateEngineHour,
      handleCreateVProfile,
      handleCreateManualEntry,
      handleFileCaptureDate,
      uploadInfo,
      handleCloseModel,
      handleTripCsvExport,
      handleVehicleCsvExport,
      handleEngineHoursExport,
      handleManualEntryExport,
      handleGetTableNumber,
      companyName,
      dataAlreadyExist,
      handleSetSubmitLoading,
      SubmitLoading,
      handleClearFileImportDate,
      fileimportDate,
      handleStoreVTripsFile,
      handleStoreUnitProfilesData
    } = props.store.dataImport;
    const { isCheck, _handleCheckBox,_handleClearCheckBox } =props.store.dataEntry;

    // console.log(uploadInfo.fileimportDate, "uploadinfofileimportdate====>");

    // console.log(SubmitLoading, "submitloading===>")

    // const cookies = new Cookies();
    // const companyName = cookies.get("companyName");

    const [fileDate, setFileDate] = useState("");

    const [modalVisible, setModalVisible] = useState(false);

    const fileInputRef = useRef(null);

    const [selectedFile, setSelectedFile] = useState(null);
    const [showAlert, setShowAlert] = useState(false);

    const [isSubmitted, setIsSubmitted] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const handleSubmission = () => {
      if (dataAlreadyExist === true) {
        setModalVisible(true);
      } else if (role === "super_admin" || companyName === "Volvo") {
        showTable === "table1"
          ? handleCreateVProfile(id, history)
          : showTable === "table2"
          ? handleStoreVTripsFile(id, history)
          : showTable === "table3"
          ? handleCreateEngineHour(id, history)
          : handleCreateManualEntry(id, history);
      } else {
        handleCreateManualEntry(id, history);
      }
    };

    useEffect(() => {
      handleTripCsvExport();
      handleVehicleCsvExport();
      handleEngineHoursExport();
      handleManualEntryExport();
      handleGetTableNumber();
    }, []);

    const handleModalClose = () => {
      setSelectedFile(null);
      setShowAlert(false);
      setIsSubmitted(false);
      setFileDate("");
      handleCloseModel();
      setModalVisible(false);
      handleClearFileImportDate();
      _handleClearCheckBox();
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    };

    useEffect(() => {
      return () => {
        // Clear the fileDate state when the modal is closed
        setFileDate("");
      };
    }, []);
    return (
      <div
        className="modal fade "
        id="confirmSubmitCSVfileModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="confirmSubmitCSVfileModal"
        aria-hidden="true"
        style={{ display: modalVisible ? "block" : "none" }}
      >
        <div
          className="modal-dialog"
          style={{
            backgroundColor: theme === "dark" ? "rgb(32, 43, 96)" : "#fff",
            borderRadius: 5
          }}
        >
          <div
            className="modal-content "
            style={{
              backgroundColor: theme === "dark" ? "rgb(32, 43, 96)" : "#fff",
              borderRadius: 5,
            }}
          >
            <div
              className="modal-header"
              style={{ borderBottom: "none", paddingBottom: "none" }}
            >
              <h1
                className="modal-title"
                id="confirmSubmitCSVfileModal"
                style={{
                  color: theme === "dark" ? "white" : "black",
                  fontSize: 30,
                  paddingBottom: "none",
                }}
              >
                {t("Confirm")}
              </h1>
              {/* <span data-bs-dismiss="modal" style={{ cursor: "pointer" }}>
                <i className="bi bi-x-lg" />
              </span> */}
            </div>

            <div className="modal-body">
              <h5
                style={{
                  color: theme === "dark" ? "rgb(198, 198, 198)" : "#000",
                }}
              >
                {" "}
                {t("Are you sure you want to submit this file?")}
              </h5>

              <h4 style={{ color: "red" }}>{t("Notice")}</h4>

              {role === "super_admin" || companyName === "Volvo" ? (
                showTable === "table1" ? (
                  <>
                    <span
                      style={{
                        color: theme === "dark" ? "rgb(198, 198, 198)" : "#000",
                      }}
                    >
                      <h6>{t("These field must include for Unit Profile")}</h6>
                      <ul>
                        <li>dateformat : DD/MM/YYYY</li>
                        <li>grouping</li>
                        <li>company</li>
                        <li>plate_number</li>
                        <li>brand</li>
                        <li>model</li>
                        <li>primary_fuel_type</li>
                        <li>vin_number</li>
                        <li>oem_baseline_emission</li>
                      </ul>
                    </span>

                    {/* {role === "super_admin" || companyName === "Volvo" ? ( */}
                    {/* <div className="mt-4">
                      <span
                        className=""
                        style={{
                          color:
                            theme === "dark" ? "rgb(198, 198, 198)" : "#000",
                        }}
                      >
                        {t("Vehicle started using date(optional)")}
                      </span>
                      <div className="mt-2">
                        <My_DatePicker
                          id="date"
                          height="43px"
                          selected={fileDate}
                          onChange={(date) => {
                            if (date <= new Date()) {
                              handleFileCaptureDate(date);
                              setFileDate(uploadInfo.fileimportDate);
                            }
                          }}
                          noTimeInput={true}
                          dateFormat="yyyy-MM-dd"
                          maxDate={new Date()}
                        />
                      </div>
                    </div> */}
                    {/* ) : (
                    <></>
                  )} */}
                  </>
                ) : showTable === "table2" ? (
                  <>
                    <span
                      style={{
                        color: theme === "dark" ? "rgb(198, 198, 198)" : "#000",
                      }}
                    >
                      <h6>{t("These field must include for Trips")}</h6>
                      <ul>
                        <li>dateformat : DD/MM/YYYY</li>
                        <li>grouping</li>
                        <li>start_time</li>
                        <li>to_time</li>
                        <li>trip_mileage</li>
                        <li>consumed</li>
                      </ul>
                    </span>
                  </>
                ) : showTable === "table3" ? (
                  <>
                    <span
                      style={{
                        color: theme === "dark" ? "rgb(198, 198, 198)" : "#000",
                      }}
                    >
                      <h6>{t("These field must include for Engine Hours")}</h6>
                      <ul>
                        <li>dateformat : DD/MM/YYYY</li>
                        <li>grouping</li>
                        <li>start_time</li>
                        <li>in_motion</li>
                        <li>idling</li>
                      </ul>
                    </span>
                    {/* <h6>Date format for Engine Hours</h6>
                      <ul>
                        <li>start_time & to_time must be date format of (yyyy-MM-DD HH:mm:ss)</li>
                      </ul> */}
                  </>
                ) : showTable === "table4" ? (
                  <>
                    <span
                      style={{
                        color: theme === "dark" ? "rgb(198, 198, 198)" : "#000",
                      }}
                    >
                      <h6>{t("These field must include for Manual Entry")}</h6>
                      <ul>
                        <li>date(dateformat : DD/MM/YYYY)</li>
                        <li>grouping</li>
                      </ul>
                    </span>
                    {/* <h6>Date format for Manual Entry</h6>
                      <ul>
                        <li>date in the table must be (YYYY-MM-DD)</li>
                      </ul> */}
                  </>
                ) : (
                  <></>
                )
              ) : (
                <>
                  <span
                    style={{
                      color: theme === "dark" ? "rgb(198, 198, 198)" : "#000",
                    }}
                  >
                    <h6>{t("These field must include for Manual Entry")}</h6>
                    <ul>
                      <li>date</li>
                      <li>grouping</li>
                    </ul>
                  </span>
                  {/* <h6>Date format for Manual Entry</h6>
                      <ul>
                        <li>date in the table must be (YYYY-MM-DD)</li>
                      </ul> */}
                </>
              )}
            
            </div>

            <div className="modal-footer" style={{ borderTop: "none" }}>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={handleModalClose}
              >
                {t("Cancel")}
              </button>

              <span data-bs-dismiss="modal" aria-label="Close">
              <button
                  type="button"
                  className={`${
                    theme === "dark"
                      ? "btn btn-primary btn-1 px-1"
                      : "btn btn-success btn-1 px-1"
                  }`}
                  style={{ width: 80 }}
                  // disabled={!isCheck}
                  onClick={() => {
                    handleSetSubmitLoading(true);
                    _handleClearCheckBox();
                    if (role === "super_admin" || companyName === "Volvo") {
                      showTable === "table1"
                        ? handleStoreUnitProfilesData(id, history)
                        : showTable === "table2"
                        ? handleStoreVTripsFile(id, history)
                        : showTable === "table3"
                        ? handleCreateEngineHour(id, history)
                        : handleCreateManualEntry(id, history);
                    } else {
                      handleCreateManualEntry(id, history);
                    }
                  }}
                >
                  {t("Submit")}
                </button>

              </span>
            </div>
          </div>
        </div>
      </div>
    );
  })
);
