import * as API from "./URL";

export const getProfileAndDailyData = async (data, token, callback) => {
    // console.log("Plate Numbers"+data)
    return fetch(API.profileAndDailyData, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "x-access-token": token,
        },
        body: JSON.stringify(data),
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err, null));
}


export const getProfileAndDailyDataV2 = async (data, token, callback) => {
    return fetch(API.profileAndDailyDataV2, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "x-access-token": token,
        },
        body: JSON.stringify(data),
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err, null));
}

export const getAllAsset = async (companyId, token, callback) => {
    const data = { companyId };
    return fetch(API.getAllAsset, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "x-access-token": token,
        },
        body: JSON.stringify(data),
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err, null));
};

export const VehicleTripData = async (token, data, callback) => {
    // const data={vehicleUID:UID,startTime:T1,endTime:T2}
    return await fetch(`${API.VehicleTripData}/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "x-access-token": token,
        },
        body: JSON.stringify(data),
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))

        .catch((err) => callback(err, null));
    // callback(err, null));
};

export const getAllContract = async (token, callback) => {
    return fetch(`${API.getAllContract}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "x-access-token": token,
        },
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err, null));
};

export const getAllContractByCompany = async (companyId, token, callback) => {
    return fetch(`${API.getAllContractByCompany}/?companyId=${companyId}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "x-access-token": token,
        },
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err, null));
};

export const getAllContractByCompanyNew = async (companyId, year, token, callback) => {
    if (year === 'All') {
        return fetch(`${API.getAllContractByCompany}/?companyId=${companyId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                "x-access-token": token,
            },
        })
            .then((res) => res.json())
            .then((data) => callback(null, data))
            .catch((err) => callback(err, null));
    } else {
        return fetch(`${API.getAllContractByCompany}/?companyId=${companyId}&year=${year}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                "x-access-token": token,
            },
        })
            .then((res) => res.json())
            .then((data) => callback(null, data))
            .catch((err) => callback(err, null));
    }
};


export const contractById = async (token, data, callback) => {
    return fetch(`${API.contractById}/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "x-access-token": token,
        },
        body: JSON.stringify(data),
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err, null));
};

export const getContractDetail = async (batchId, token, callback) => {
    return fetch(`${API.getContractDetail}?batchId=${batchId}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "x-access-token": token,
        },

    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err, null));
};


export const getAssetProfileById = async (id, token, callback) => {
    return fetch(`${API.getAssetById}/?assetId=${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "x-access-token": token,
        },
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err, null));
};

export const fetchTrackingData = async (token, companyId, callback) => {
    const data = { companyId };
    return fetch(API.TrackingData, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "x-access-token": token,
        },
        body: JSON.stringify(data),
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err, null));
};

export const getIssuanceReport = async (companyId, startTime, endTime, units, unitId, token, callback) => {
    return fetch(`${API.issuanceReportAPI}?companyId=${companyId}&plates=${units}&start=${startTime}&end=${endTime}&vehicleIds=${unitId}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "x-access-token": token,
        },
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err, null));
};

export const updateCertificates = async (id, token, callback) => {
    return fetch(`${API.updateCertificatesAPI}/?id=${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "x-access-token": token,
        },
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err, null));
};

export const updateCertificatesV2 = async (id, token, callback) => {
    return fetch(`${API.updateCertificatesV2API}?id=${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "x-access-token": token,
        },
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err, null));
};


export const sendCertificates = async (companyId, year, month, token, callback) => {
    return fetch(`${API.sendCertificatesAPI}?companyId=${companyId}&year=${year}&month=${month}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "x-access-token": token,
        },
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err, null));
};

export const getStacsHistory = async (year, month, companyId, token, callback) => {
    return fetch(`${API.stacsHistoryAPI}?year=${year}&month=${month}&companyId=${companyId}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "x-access-token": token,
        }
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) =>callback(err, null))
}