// LogsToast.js
import React, { useEffect, useState } from 'react';
import CustomToast from './CustomToast';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const LogsToast = ({ userAction, position }) => {
  const history = useHistory()
  const [toasts, setToasts] = useState([]);

    const handleOpenNoti = () => {
    history.push("/logs");
    // console.log("hello");
  };

  useEffect(() => {
    if (userAction && userAction.action_detail) {
      const newToast = {
        id: Date.now(),
        action: userAction.action,
        time: userAction.log_time,
        detail: userAction.action_detail,
      };

      setToasts((prevToasts) => [newToast, ...prevToasts]);

      setTimeout(() => {
        setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== newToast.id));
      }, 3000);
    }
  }, [userAction]);

  return (
    <div
    className={`toast-list toast-list--${position}`}
    style={{
      // maxHeight: "360px",
      overflowY: toasts.length > 4 ? "auto" : "hidden",
      display: "flex",
      flexDirection: "column-reverse",
    }}    
    aria-live="assertive"
  >
      {/* {toasts.map((toast) => (
        <CustomToast
          key={toast.id}
          toast={toast}
          onClose={() => setToasts((prevToasts) => prevToasts.filter((t) => t.id !== toast.id))}
          handleOpenNoti={handleOpenNoti}
        />
      ))} */}
    </div>
  );
};

export default LogsToast;



