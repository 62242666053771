import { Colors } from "./Config/color.config";
import Spinner from "./Images/icons/spinner.gif";
import darkLoading from "./Images/darkLoading.svg"
import lightLoading from "./Images/lightLoading.svg"

export const CO2_Loading = (props) => {
  const { height } = props;
  return (
    <div className="d-flex flex-column justify-content-center align-items-center" style={{ marginTop: 50, height: height }}>
      {/* <img src={lightLoading} alt="loading" width={50} /> */}
      <div className="loader mb-3"></div>
      <span style={{ color: Colors.Dark[4] }}>Loading... </span>
      
    </div>
  );
};
