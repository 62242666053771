/* eslint-disable react/jsx-pascal-case */
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import {
    Pagination,
    PaginationItem,
    styled,
    TableSortLabel,
} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { CreateUserModal } from "../../Components/Tracking";
import { CreateUser } from "../../network/fetchUser";
import { Cookies } from "react-cookie";
import { useState } from "react";
import Store from "../../Store";
import { My_Button, My_Input, Selector } from "../../Tools";
import { inject, observer } from "mobx-react";
import { ConfirmDeleteModal } from "./ConfirmDeleteModal";
import jwt_decode from "jwt-decode";
import { useTranslation } from "react-i18next";

export const UserListTable = inject("store")(
    observer((props) => {
        const {t} = useTranslation('admin-panel')
        const cookies = new Cookies();
        const token = cookies.get("accessToken");
        const [page, setPage] = React.useState(1);
        const { header, theme } = props;
        const [userData, setUserData] = useState({ role: "user" });
        const [showModal, setShowModal] = useState("");
        const [order, setOrder] = React.useState("asc");
        const [orderBy, setOrderBy] = React.useState("");
        const handleRequestSort = (event, property) => {
            const isAsc = orderBy === property && order === "asc";
            setOrder(isAsc ? "desc" : "asc");
            setOrderBy(property);
        };

        function descendingComparator(a, b, orderBy) {
            const A = a[orderBy] ? a[orderBy] : undefined;
            const B = b[orderBy] ? b[orderBy] : undefined;
            if (B < A) {
                return -1;
            }
            if (B > A) {
                return 1;
            }
            return 0;
        }
        function getComparator(order, orderBy) {
            return order === "desc"
                ? (a, b) => descendingComparator(a, b, orderBy)
                : (a, b) => -descendingComparator(a, b, orderBy);
        }

        function EnhancedTableHead(props) {
            const { order, orderBy, onRequestSort, theme } = props;
            const createSortHandler = (property) => (event) => {
                onRequestSort(event, property);
            };

            const key = ["username", "email", "companyName", "role", "edit"];
            return (
                <TableHead>
                    <TableRow>
                        {header.map((headCell, index) => (
                            <TableCell
                                key={index}
                                align={"left"}
                                padding={headCell.disablePadding ? "none" : "normal"}
                                sortDirection={orderBy === headCell.id ? order : false}
                                style={{ background: invertDark(theme) }}
                            >
                                {headCell.label === "" || (
                                    <TableSortLabel
                                        style={{
                                            color:
                                                orderBy === headCell.id
                                                    ? Colors.activeColor
                                                    : theme === "light"
                                                        ? "#F6F6F6"
                                                        : Colors.darkBlue,
                                        }}
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : "asc"}
                                        onClick={createSortHandler(key[index])}
                                    >
                                        <span className="fw-bold">{headCell.label}</span>
                                    </TableSortLabel>
                                )}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
            );
        }

        const data = Store.adminPanel.userList;
        const { _fetchUserList } = Store.adminPanel;

        const createData = (username, email, companyName, role, id) => {
            return { username, email, companyName, role, id };
        };
        const rows = data.map((v, k) =>
            createData(v.username, v.email, v.companyName, v.role, v.id)
        );

        const rowCount = 10;
        const handleChange = (event, value) => {
            setPage(value);
        };
        const useStyles = makeStyles({
            foo: {
                "& .MuiPagination-ul": { justifyContent: "end" },
                "& .MuiPaginationItem-text": {
                    color: Colors.Dark[theme === "dark" ? 4 : 1],
                },
                "& .Mui-selected": {
                    backgroundColor:
                        theme === "dark" ? Colors.lightBlue : Colors[theme].primaryColor,
                    color: Colors.Dark[4],
                },
            },
        });
        const classes = useStyles();
        const _handleCreateUser = () => {
            CreateUser(token, userData, (err, data) => {
                if (data.error) {
                    alert(data.error);
                } else if (data.message) {
                    alert(data.message);
                }
                if (data.message === "User Created!") {
                    // alert(data.message)
                    _handleFromClear();
                    document.querySelector("body").classList.remove("modal-open");
                    document.getElementById("exampleModal").classList.remove("show");
                    document.getElementById("exampleModal").style.display = "none";
                    const list = document.querySelectorAll(".modal-backdrop");
                    for (let i = 0; i < list.length; i++) {
                        list[i].style.display = "none";
                    }
                    _fetchUserList(token);
                } else console.log(err);
            });
        };

        const _handleFromClear = () => {
            userData.username = '';
            userData.email = '';
            userData.password = '';
            userData.role = ""
            setUserData(userData);
        }

        const _handleTextChange = (user) => {
            const userInfo = { ...userData, ...user };
            setUserData(userInfo);
        };

        const {
            _handleEditUser,
            userEditMode,
            tempData,
            username,
            _handleUserInputChange,
            _handleConfirmEdit,
            _handleDeleteUser,
            isOpenDeleteModal,
            _openDeleteModal,
        } = props.store.adminPanel;

        const { role } = props.store.common;
        const roleOption = [
            { value: "admin", label: "admin" },
            { value: "user", label: "user" },
        ]
        const userId = token && jwt_decode(token).userId;
        return (
            <React.Fragment>
                <div className="py-2 d-flex flex-row justify-content-between p-2">
                    <div className="d-flex align-items-center">
                        <h3 style={{ color: invertDark(theme), paddingRight: 30 }}>
                            {t("User List")}
                        </h3>
                        <span
                            className="px-2"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            data-toggle="modal"
                            data-target=".bd-example-modal-lg"
                        >
                            <button
                                onClick={() => setShowModal("show")}
                                type="button"
                                className="btn text-light"
                                style={{
                                    background: Colors.low,
                                    fontSize: 12,
                                    padding: 5,
                                    width: 100,
                                    height: 30,
                                }}
                            >
                                {t("Create User")}<i className="ps-2 bi bi-plus-circle"></i>
                            </button>
                        </span>                        
                    </div>
                </div>
                <TableContainer
                    component={Paper}
                    style={{ background: "none" }}
                    sx={{ maxHeight: 500 }}
                >
                    <Table
                        stickyHeader
                        sx={{ minWidth: 650 }}
                        size="small"
                        aria-label="a dense sticky table"
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            theme={theme}
                        />
                        <TableBody>
                            {rows
                                .sort(getComparator(order, orderBy))
                                .slice((page - 1) * rowCount, page * rowCount)
                                .map((row, index) => (
                                    <TableRow
                                        // style={{background:userEditMode===row.id?'gray':null }}
                                        key={index}
                                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                    >
                                        <TableCell
                                            sx={{ color: invertDark(theme), borderBottom: "none" }}
                                        >
                                            {userEditMode === row.id ? (
                                                <My_Input
                                                    style={{ height: 35 }}
                                                    value={tempData.username}
                                                    type={"text"}
                                                    id={'username'}
                                                    // height={10}
                                                    onChange={(e) =>
                                                        _handleUserInputChange(e, "username")
                                                    }
                                                />
                                            ) : (
                                                row.username
                                            )}
                                        </TableCell>
                                        <TableCell
                                            sx={{ color: invertDark(theme), borderBottom: "none" }}
                                        >
                                            {userEditMode === row.id ? (
                                                <My_Input
                                                    style={{ height: 35 }}
                                                    value={tempData.email}
                                                    type={"text"}
                                                    id={'email'}
                                                    // height={10}
                                                    onChange={(e) => _handleUserInputChange(e, "email")}
                                                />
                                            ) : (
                                                row.email
                                            )}
                                        </TableCell>
                                        <TableCell
                                            sx={{ color: invertDark(theme), borderBottom: "none" }}
                                        >
                                            {row.companyName}
                                        </TableCell>
                                        <TableCell
                                            sx={{ color: invertDark(theme), borderBottom: "none" }}
                                        >
                                            {userEditMode === row.id ? (
                                                <Selector
                                                    value={roleOption.filter(v => v.value === tempData.role)}
                                                    menuListColor="black"
                                                    _handleSelect={(e) => _handleUserInputChange(e, "role")}
                                                    options={roleOption}
                                                />

                                            ) : (
                                                row.role
                                            )}
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            sx={{ color: invertDark(theme), borderBottom: "none" }}
                                        >
                                            {userEditMode === row.id ? (
                                                <>
                                                    <My_Button
                                                        // disabled={editMode !== '' && true}
                                                        onClick={(e) => _handleEditUser()}
                                                        text={<div style={{ fontSize: 15 }}>{t("Cancel")}</div>}
                                                        small
                                                    />
                                                    <My_Button
                                                        className="ms-2"
                                                        // disabled={editMode !== '' && true}
                                                        onClick={(e) => _handleConfirmEdit(token)}
                                                        text={<div style={{ fontSize: 15 }}>{t("Save")}</div>}
                                                        small
                                                    />
                                                </>
                                            ) : (
                                                role !== 'super_admin' &&
                                                <>
                                                    <span
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#deleteModal"
                                                        data-toggle="modal"
                                                        data-target=".bd-delete-modal-lg"
                                                        onClick={() => _openDeleteModal(token, row)}
                                                    >
                                                        {userId !== row.id && <My_Button
                                                            leftIcon={<i style={{ color: Colors.high, fontSize: 15 }} className="bi bi-trash-fill"></i>}
                                                            className='me-2'
                                                            small
                                                        />}
                                                    </span>
                                                    <My_Button
                                                        leftIcon={<i style={{ fontSize: 15 }} className="bi bi-pencil-square"></i>}
                                                        onClick={(e) => _handleEditUser(token, row)}
                                                        small
                                                    />
                                                </>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className="py-2">
                    {" "}
                    <Pagination
                        size="small"
                        renderItem={(item) => (
                            <PaginationItem
                                {...item}
                                sx={{ color: invertDark(theme) }}
                                classes={{ selected: classes.selected }}
                            />
                        )}
                        className={classes.root}
                        count={
                            rows.length <= rowCount ? 1 : Math.ceil(rows.length / rowCount)
                        }
                        defaultPage={1}
                        page={page}
                        siblingCount={1}
                        boundaryCount={1}
                        onChange={handleChange}
                    />
                </div>
                
                <CreateUserModal
                    companyList={props.companyList}
                    userData={userData}
                    // showModal={showModal}
                    _handleTextChange={_handleTextChange}
                    _handleCreateUser={_handleCreateUser}
                    theme={props.theme}
                    cardStyle={props.cardStyle}
                    rowStyle={props.rowStyle}
                    projectName={props.projectName}
                />
                <ConfirmDeleteModal cardStyle={props.cardStyle} theme={props.theme}
                />
            </React.Fragment>
        );
    })
);
