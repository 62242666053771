import { Colors, invertDark } from "../../Assets/Config/color.config";
import { My_Button } from "../../Tools";
import { inject, observer } from "mobx-react";
import Store from "../../Store";
import { useTranslation } from "react-i18next";
import { useEffect, useRef } from "react";
import { Checkbox } from "@mui/material";

export const ConfirmDeleteCSVfileModal = inject("store")(
  observer((props) => {
    const { t } = useTranslation("data_import");
    const { id, status, companyId, showTable, fileName } = props;
    console.log("table", showTable);
    // console.log("company", companyId);
    // console.log("status=====>",status)
    const theme = Store.login.theme;
    const {
      handleClearImports,
      handleDeleteManualEntryData,
      isCheck,
      _handleCheckBox,
      _handleClearCheckBox,
    } = props.store.dataImport;
    const cardStyle = {
      color: invertDark(theme),
      background: Colors[theme].background,
    };
    const handleBackdropClick = (e) => {
      if (e.target.id === "confirmDeleteCSVfileModal") {
        _handleClearCheckBox();
      }
    };
    return (
      <div
        className="modal fade"
        id="confirmDeleteCSVfileModal"
        // tabIndex="-1"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="confirmDeleteCSVfileModal"
        aria-hidden="true"
        // ref={modalRef}
        onClick={handleBackdropClick}
      >
        <div
          className={
            status === "1" && showTable === "table4"
              ? "modal-dialog modal-lg rounded"
              : "modal-dialog modal-md rounded"
          }
          style={{
            backgroundColor: theme === "dark" ? "rgb(32, 43, 96)" : "#fff",
            borderRadius: 5,
          }}
        >
          <div className="modal-content" style={cardStyle}>
            <div className="modal-header" style={{ border: "none" }}>
              <h3 className="text-warning">{t("Warning")} !</h3>
              <span
                data-bs-dismiss="modal"
                style={{ cursor: "pointer" }}
                onClick={_handleClearCheckBox}
              >
                <i className="bi bi-x-lg" />
              </span>
            </div>
            <div className="modal-body mt-0">
              {showTable === "table4" && status === "1" ? (
                <>
                  {t(
                    "Deleting this record will remove important data entries pertaining to mileage or fuel consumption which has impact on the calculation for CO2e emissions as well as the baseline emission factors."
                  )}
                </>
              ) : (
              <>{t("Do you want to delete this file?")}</>
              )}
            </div>
            <div className="row-12 d-flex p-3 justify-content-between">
              <div className="col-md-9">
                {status === "1" && showTable === "table4" && (
                  <span
                    style={{
                      color: Colors.Dark[theme === "light" ? 2 : 4],
                      cursor: "pointer",
                      marginBottom: 30,
                    }}
                    onClick={() => _handleCheckBox()}
                  >
                    <Checkbox
                      checked={isCheck}
                      style={{ color: Colors.activeColor, padding: 0 }}
                    />{" "}
                    {t(
                      "I understand that once deleted, this task cannot be recovered."
                    )}
                    <br></br>
                  </span>
                )}
              </div>
              <div className="col-md-3 d-flex justify-content-end">
                <div style={{ marginRight: 20 }}>
                  <span className="" data-bs-dismiss="modal" aria-label="Close">
                    <My_Button
                      id="cancel"
                      type="button"
                      className="btn"
                      customColor="#4c5d75"
                      style={{}}
                      text={t("Cancel")}
                      onClick={_handleClearCheckBox}
                    />
                  </span>
                </div>
                <div>
                  <span
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    // style={{ opacity: 0.7 }}
                    style={{
                      cursor: !isCheck ? "not-allowed" : "pointer",
                    }}
                  >
                    {status === "1" && showTable === "table4" ? (
                      <My_Button
                        id="delete"
                        type="button"
                        className="btn text-light"
                        customColor="red"
                        text={t("Delete")}
                        disabled={!isCheck}
                        onClick={() => {
                          if (isCheck) {
                            handleDeleteManualEntryData(id, companyId);
                            _handleClearCheckBox();
                          }
                        }}
                      />
                    ) : (
                      <My_Button
                        id="delete"
                        type="button"
                        className="btn text-light"
                        customColor="red"
                        text={t("Delete")}
                        // disabled={!isCheck}
                        onClick={() => {
                          if (showTable === "table4") {
                            handleDeleteManualEntryData(id, companyId);
                            // _handleClearCheckBox();
                          } else {
                            handleClearImports(id, fileName);
                          }
                        }}
                      />
                    )}
                  </span>
                </div>
              </div>
            </div>
            {/* <div className="text-end p-3">
             
            </div> */}
          </div>
        </div>
      </div>
    );
  })
);
