/* eslint-disable react/jsx-pascal-case */
import { inject, observer, useAsObservableSource } from "mobx-react";
import { useState } from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { MonthlyDetailTable } from "./MonthlyDetailTable";
import { Button } from "react-bootstrap";
import { getContractDetail } from "../../network/trackingAPI";
import common from "../../Store/common";
import moment from "moment";
import month from "../../Assets/month.json";
import { CO2_Loading } from "../../Assets/CO2_Loading";
import { useTranslation } from "react-i18next";
import { toJS } from "mobx";
import { MonthlyDetailDataTable } from "./MonthlyDetailDataTable";
import Tour from "./ReportTour";

export const MonthlyDataDetailModel = inject("store")(
  observer((props) => {
    const { t } = useTranslation("track_and_trace");
    const { theme, role, unitsTab, companyType } = props;

    const currentMonth = moment().month() + 1;
    const year = moment().year();

    const {
      selectedMonthlyDetail,
      selectedYear,
      view,
      _handleView,
      loading,
      _handleViewClose,
    } = props.store.trackAndTrace;

    const rows = selectedMonthlyDetail?.units?.map((data) => {
      const row =
        companyType === "1"
          ? {
              plate_number: data.plate_number ? data.plate_number : "N/A",
              consumption:
                data.type === "battery"
                  ? Number(data.battery_consumed).toFixed(2) + " kWh"
                  : Number(data.fuel_consumed).toFixed(2) + " L",
              mileage:
                data.trip_mileage === null
                  ? "0 km"
                  : Number(data.trip_mileage).toFixed(2) + " km",
              engine_hour: "-",
              baseline_emission:
                Number(data.baseline_emission).toFixed(2) + " kgCO₂e",
              history_baseline_emission:
                Number(data.history_baseline_emission).toFixed(2) + " kgCO₂e",
              co2_emission: Number(data.carbon_emission).toFixed(2) + " kgCO₂e",
            }
          : {
              plate_number: data.plate_number ? data.plate_number : "N/A",
              consumption:
                data.type === "battery"
                  ? Number(data.battery_consumed).toFixed(2) + " kWh"
                  : Number(data.fuel_consumed).toFixed(2) + " L",
              engine_hr:
                data.trip_mileage === null
                  ? "0 km"
                  : Number(data.trip_mileage).toFixed(2) + " km",
              baseline_emission:
                Number(data.baseline_emission).toFixed(2) + " kgCO₂e",
              history_baseline_emission:
                Number(data.history_baseline_emission).toFixed(2) + " kgCO₂e",
              co2_emission: Number(data.carbon_emission).toFixed(2) + " kgCO₂e",
            };

      return row;
    });

    return (
      <div
        className="modal fade"
        id="monthlyDataDetail"
        tabIndex="-1"
        aria-labelledby="monthlyDataDetail"
        aria-hidden="true"
      >
        <div className="w-100 d-flex align-items-center justify-content-end py-2 pt-4 px-3">
          {/* <Tour/> */}
        </div>
        <div className="modal-dialog modal-xl rounded">
          <div
            className="modal-content detailView-report-guide"
            style={{
              background: Colors[theme].background,
              color: invertDark(theme),
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title">
                {" "}
                {t("Details for")}{" "}
                {selectedMonthlyDetail &&
                  month[selectedMonthlyDetail.month].label}
              </h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => _handleViewClose()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              {loading ? (
                <CO2_Loading theme={theme} />
              ) : (
                <div className="row">
                  <div className="col-9">
                    {selectedMonthlyDetail?.units?.length > 0 ? (
                      <MonthlyDetailDataTable
                        theme={theme}
                        rows={rows}
                        companyType={companyType}
                      />
                    ) : (
                      <span
                        className="d-flex justify-content-center align-items-center"
                        style={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          height: "100px",
                        }}
                      >
                        There is no units in this company.
                      </span>
                    )}
                  </div>
                  <div className="col-3">
                    {selectedMonthlyDetail &&
                    selectedMonthlyDetail?.certificate ? (
                      <div className="row">
                        <span className="fw-bold">{t("Certificate")}</span>
                        <span className="mt-1">
                          {t("Batch ID")} :{" "}
                          {selectedMonthlyDetail?.certificate?.batchId}
                        </span>
                        <span className="mt-1">
                          {t("Status")} :{" "}
                          {
                            selectedMonthlyDetail?.certificate
                              ?.certificate_status
                          }
                        </span>

                        <div className="col-12">
                          <Button
                            type="button"
                            // onClick={async () => {
                            //   await getContractDetail(
                            //     selectedMonthlyDetail?.certificate?.batchId,
                            //     common.token,
                            //     (err, data) => {
                            //       var batch_id = {
                            //         batchId:
                            //         selectedMonthlyDetail?.certificate?.batchId,
                            //       };
                            //       if (data) {
                            //         _handleView();
                            //         setContractDetailData({
                            //           ...data,
                            //           ...batch_id,
                            //         });
                            //       }
                            //     }
                            //   );
                            // }}
                            onClick={() => _handleView()}
                            style={{
                              background: "#4c5d75",
                              color: "white",
                              border: "none",
                            }}
                            className="btn btn-sm mt-1"
                          >
                            {t("View")}
                          </Button>

                          {role === "super_admin" && unitsTab === "own" ? (
                            selectedMonthlyDetail?.showWarning == 1 ? (
                              <span>
                                <Button
                                  type="button"
                                  style={{
                                    background: "#4c5d75",
                                    color: "white",
                                    border: "none",
                                  }}
                                  className="btn btn-sm mt-1 mx-2"
                                  data-bs-dismiss="modal"
                                  data-bs-toggle="modal"
                                  data-bs-target="#resendcertificateconfirmmodal"
                                >
                                  {t("Re-Send")}
                                </Button>
                              </span>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="row">
                        <span className="fw-bold">{t("Certificate")}</span>
                        {selectedYear === year &&
                        currentMonth === selectedMonthlyDetail?.month ? (
                          <>
                            <span className="mt-1">
                              {t("Status")} : {t("Pending")}
                            </span>
                          </>
                        ) : (
                          <>
                            <span className="mt-1">
                              {t("Status")} : {t("No certificate data")}
                            </span>
                            {role === "super_admin" && unitsTab === "own" ? (
                              <div className="col-12">
                                <Button
                                  type="button"
                                  className="btn btn-sm mt-1"
                                  data-bs-dismiss="modal"
                                  data-bs-toggle="modal"
                                  data-bs-target="#sendcertificateconfirmmodal"
                                >
                                  {t("Send")}
                                </Button>
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </div>
                    )}

                    {view ? (
                      selectedMonthlyDetail ? (
                        <div className="row mt-3">
                          <span className="fw-bold">
                            {t("Certificate Data")}
                          </span>
                          <span className="mt-1">
                            {t("Month")} :{" "}
                            {selectedMonthlyDetail?.certificate?.month}
                          </span>
                          <span className="mt-1">
                            {t("Batch ID")} :{" "}
                            {selectedMonthlyDetail?.certificate?.batchId}
                          </span>
                          <span className="mt-1">
                            {t("CO2 Emission")} :{" "}
                            {selectedMonthlyDetail?.certificate?.currentEmission
                              ? Number(
                                  selectedMonthlyDetail?.certificate
                                    ?.currentEmission
                                ).toFixed(2) + " kgCO₂e"
                              : "N/A"}
                          </span>
                          <span className="mt-1">
                            {t("CO2 Emission Offset")} :{" "}
                            {selectedMonthlyDetail?.certificate?.offset
                              ? Number(
                                  selectedMonthlyDetail?.certificate?.offset
                                )?.toFixed(2) + " kgCO₂e"
                              : "N/A"}
                          </span>
                          <span className="mt-1">
                            {t("Total trips")} :{" "}
                            {selectedMonthlyDetail?.certificate?.totalTrip}
                          </span>
                          <span className="mt-1">
                            {t("Unit")} :{" "}
                            {selectedMonthlyDetail?.certificate?.vehicleCount}
                          </span>
                        </div>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  })
);
