import { inject, observer } from "mobx-react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { AddNewOrganizationModal } from "./AddNewOrganizationModal";
import { baseURL } from "../../network/URL";
import { useTranslation } from "react-i18next";

export const OrganizationView = inject("store")(

    observer((props) => {
        const {t} = useTranslation("admin-panel")
        // const { OrganizationView } = props.store.adminPanel;
        const { theme } = props;
        return (
            <>

                <div
                    className="" style={{ background: theme === 'dark' ? "rgb(0,0,0,0.4)" : "rgb(0,0,0,0.05)", color: invertDark(theme), height: "50vh", borderTopLeftRadius: 10, borderTopRightRadius: 10, borderBottomLeftRadius: 10, border: '1px solid rgb(0,0,0,0.07)' }}
                >
                    <div style={{ background: '#192353', color: '#fff', fontWeight: 'bold', borderTopLeftRadius: 10, borderTopRightRadius: 10 }} className="p-2">
                        <i className="bi bi-people-fill mx-2" ></i>

                        {t("Organization")}

                        <i className="bi bi-plus-square-fill mx-2" style={{ color: '#04C600' }} data-bs-toggle="modal" data-bs-target="#addneworganization"></i>
                    </div>

                    <div className="d-flex  pt-3">

                        {dummy.map((v, k) => (

                            <div key={k} className="col-2 text-center  mt-2 mx-3">
                               
                                        <img className="bg-white rounded" width={'60%'}  src={baseURL + v.logo} />
                                
                                    <div className="fw-bold ">
                                    {v.orgName}
                                    </div>
                             
                            </div>
                        ))}

                    </div>

                    <AddNewOrganizationModal theme={theme} />

                </div>
            </>

        );
    }));

const dummy = [
    { logo: '/uploads/companyLogo/1b38bdbf-1457-46c3-816e-a17f15b7ef89.jpeg', orgName: 'ABC' },
    { logo: '/uploads/companyLogo/24596801.png', orgName: 'Something' }
]