import { Link } from "react-router-dom";
import { inject, observer } from 'mobx-react';
import { invertDark } from "../../Assets/Config/color.config";
import Store from "../../Store";
import { useEffect } from "react";
import { CompanySelector } from "../../Components/Dashboard/companySelector";
import { CO2_Loading } from "../../Assets/CO2_Loading";
import RegCertificateTable from "../../Components/Certificate/RegCertificatesTable";
import { useTranslation } from "react-i18next";

const REGCertificateContainer = (props) => {
    const {t} = useTranslation('dashboard')
    const theme = Store.login.theme;
    const _handleViewREGCertificate = Store.dashboard._handleViewREGCertificate;

    const { _handleFetchStacsCertificates, regCertificates } = props.store.dashboard;

    useEffect(() => {
        _handleFetchStacsCertificates();
    }, [])

    return (
        <div>
            <span style={{ color: invertDark(theme) }}>
                <Link to="#" onClick={() => _handleViewREGCertificate()}>{t("Home")}</Link> /{t("Certificates")}
            </span>
            <div className="mb-2">
                <div className=" d-flex justify-content-between">
                    <div className="pt-2">
                        <CompanySelector theme={theme} label={t("Organisation")+": "} />
                    </div>
                </div>
            </div>
            {regCertificates ? <div style={{
                background: theme === 'dark' ? 'linear-gradient(65deg, rgb(16, 24, 65), rgb(32, 43, 96))' : '',
                boxShadow: 'rgba(0, 0, 0, 0.25) 0px 5px 10px',
                padding: '15px',
                borderRadius: '5px',
                marginBottom: '8px',
                color: 'rgb(198, 198, 198)'
            }}>
                <RegCertificateTable theme={theme} regCertificates={regCertificates} />
            </div> : <CO2_Loading />}

        </div>
    );
};

export default inject("store")(observer(REGCertificateContainer));
