import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import * as React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";


import TableCell from "@mui/material/TableCell";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { Button } from "react-bootstrap";
import '../../stick-table.css'

import {
    Pagination,
    PaginationItem,
    styled,
    TableSortLabel,
} from "@mui/material";

import { AssignVehiclesCompanyModal } from "../../Containers/AdminPanel/AssignVehicleCompanyModal";

import { UpdateCompanyModal } from "./UpdateCompanyModal";
import { DeleteCompanyModal } from "../../Containers/AdminPanel/DeleteCompanyModal";
import { RegisterCompanyModal } from "../../Containers/AdminPanel/RegisterCompanyModal";
import { useTranslation } from "react-i18next";
import { toJS } from "mobx";


export const CompanyTable = inject("store")(
    observer(
        withRouter((props) => {
          const {t} = useTranslation("admin-panel")
            const { theme, 
                    _handleViewContract, 
                    noPagination, 
                    rows, 
                    userList,
                    _handleEditCompany, 
                    selectedCountry, 
                    setSelectedCountry, 
                    companyList, 
                    _handleDeleteCompany, 
                    _fetchdeleteCompanyInfo,
                    role } = props;

            const { _FetchUnitsForCompanyAssign, 
                    companyId } = props.store.dataEntry;

            const { setSelectedCompany,
                    setSelectedLevel } = props.store.adminPanel;   
                    const [scrollOffset, setScrollOffset] = React.useState(0);
                    const tableContainerRef = React.useRef(null);

         
            const Key = [
                // "logo",
                "name",
                "level",
                "country",
                "type",
                "ascent_id",
                "action"
            ];

            const openEditModal = () => {
                let myModal = new window.bootstrap.Modal(document.getElementById('updatecompanymodal'))
                myModal.show()
            }

            const openAssignModal = () => {
                let myModal = new window.bootstrap.Modal(document.getElementById('assignCompanyVehicles'))
                myModal.show()
            }

            const openRegisterModal = () => {
                let myModal = new window.bootstrap.Modal(document.getElementById('registercompanymodal'))
                myModal.show()
            }

            console.log("companyList", toJS(props.companyList))

            const [page, setPage] = React.useState(1);
            const [order, setOrder] = React.useState("asc");
            const [orderBy, setOrderBy] = React.useState("");
            const [selectedIndex, setSelectedIndex] = React.useState("");

            const handleRequestSort = (event, property) => {
                const isAsc = orderBy === property && order === "asc";
                setOrder(isAsc ? "desc" : "asc");
                setOrderBy(property);
                console.log('property', property)
            };

            const headers = [
                // { label: "", key: "logo", align: "left" },
                { label: t("Name"), key: "name", align: "left" },
                { label: t("Level"), key: "level", align: "left" },
                { label: t("Country"), key: "country", align: "left" },
                { label: t("Company Type"), key: "type", align: "left" },
                { label: t("Ascent ID"), key: "ascent_id", align: "left" },
                { label: "", key: "action", align: "center" },

            ];

          
            const StyledTableRow = styled(TableRow)(({ theme }) => ({
                cursor: "pointer",
                "&:nth-of-type(odd)": {
                    backgroundColor:
                        props.theme === "dark" ? Colors.darkBlue : Colors.Dark[5],
                    color: theme.palette.common.white,
                },
                // hide last border
                "&:last-child td, &:last-child th": {
                    border: 0,
                },
            }));

            const rowCount = 8;
            const handleChange = (event, value) => {
                setPage(value);
            };

            function EnhancedTableHead(props) {
                const {
                    order,
                    orderBy,
                    onRequestSort,
                    theme,
                    Key,
                    _handleViewContract,
                } = props;
                const createSortHandler = (property) => (event) => {
                    onRequestSort(event, property);
                };

              // React.useEffect(() => {
              //     const handleScroll = () => {
              //       setScrollOffset(tableContainerRef.current.scrollLeft);
              //     };
                
              //     tableContainerRef.current.addEventListener('scroll', handleScroll);
                
              //     return () => {
              //       if (tableContainerRef.current) {
              //       tableContainerRef.current.removeEventListener('scroll', handleScroll);
              //       }
              //     };
              //   }, []);
              
                return (
                  <TableHead>
                    <TableRow>
                      {headers.map((headCell, index) => (
                        <TableCell
                          key={index}
                          align={headCell.align}
                          padding={headCell.disablePadding ? "none" : "normal"}
                          sortDirection={
                            orderBy === headCell.id ? order : false
                          }
                          style={{
                            background:
                              theme === "dark"
                                ? Colors.blackblue
                                : invertDark(theme),
                          }}
                          // className={
                          //   index === 0 ? "fixed-cell left" : "" // Apply the class for the first cell
                          // }
                        >
                          {headCell.label === "" || (
                            <TableSortLabel
                              style={{
                                minWidth: 80,
                                color:
                                  orderBy === headCell.id
                                    ? Colors.activeColor
                                    : theme === "light"
                                    ? "#F6F6F6"
                                    : Colors.Dark[4],
                              }}
                              active={orderBy === headCell.id}
                              direction={
                                orderBy === headCell.id ? order : "asc"
                              }
                              onClick={createSortHandler(Key[index])}
                            >
                              <span className="fw-bold">{headCell.label}</span>
                            </TableSortLabel>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                );
            }

            function descendingComparator(a, b, orderBy) {
                const A = a[orderBy] ? a[orderBy].toString().trim() : undefined;
                const B = b[orderBy] ? b[orderBy].toString().trim() : undefined;
                if (B < A) {
                    return -1;
                }
                if (B > A) {
                    return 1;
                }
                return 0;
            }

            function getComparator(order, orderBy) {
                return order === "desc"
                    ? (a, b) => descendingComparator(a, b, orderBy)
                    : (a, b) => -descendingComparator(a, b, orderBy);
            }

            const useStyles = makeStyles({
                foo: {
                    "& .MuiPagination-ul": { justifyContent: "end" },
                    "& .MuiPaginationItem-text": {
                        color: Colors.Dark[theme === "dark" ? 4 : 1],
                    },
                    "& .Mui-selected": {
                        backgroundColor: Colors.Dark[4],
                        color: Colors.darkBlue,
                    },
                },
            });

            const classes = useStyles();
            return (
              <React.Fragment>
                <TableContainer
                  component={Paper}
                  style={{ background: "none" }}
                  sx={{ maxHeight: 450 }}
                  ref={tableContainerRef} // Attach the ref to the container
                >
                  <Table
                    stickyHeader
                    sx={{ minWidth: 650 }}
                    size="small"
                    aria-label="a dense sticky table"
                    // className="app-table"
                  >
                    <EnhancedTableHead
                      _handleViewContract={_handleViewContract}
                      Key={Key}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      theme={theme}
                    />
                    <TableBody>
                      {rows.length > 0 &&
                        rows
                          .sort(getComparator(order, orderBy))
                          .slice((page - 1) * rowCount, page * rowCount)
                          .map((row, index) => (
                            <StyledTableRow
                              style={{
                                background:
                                  selectedIndex === row.id
                                    ? Colors.Dark[3]
                                    : "",
                              }}
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              {Object.values(row)
                                .filter((f) => typeof f != "number")
                                .map(
                                  (d, x) =>
                                    x != 0 && (
                                      <TableCell
                                        sx={{
                                          color: invertDark(theme),
                                          borderBottom: "none",
                                        }}
                                        key={x}
                                        // className={
                                        //   x === 1 ? "fixed-cell left" : "" // Add the class for the first column
                                        // }
                                        // style={{
                                        //   background:
                                        //     scrollOffset > 0 && x === 1
                                        //       ? theme === "dark"
                                        //         ? Colors.blackblue
                                        //         : "#fff"
                                        //       : "",
                                        // }}
                                      >
                                        {d === "view" ? (
                                          <div>
                                            <div className="d-flex">
                                              <Button
                                                type="button"
                                                onClick={() => {
                                                  var selectedCompany =
                                                    props.companyList.find(
                                                      (v) => v.id === row.id
                                                    );

                                                  _handleEditCompany(
                                                    selectedCompany
                                                  );
                                                  setSelectedCountry(
                                                    selectedCompany.country
                                                  );
                                                  setSelectedLevel(
                                                    selectedCompany.level
                                                  );
                                                  openEditModal();
                                                }}
                                                className="btn btn-sm m-1"
                                              >
                                                {t("Edit")}
                                              </Button>

                                              {row.id === companyId ? (
                                                <></>
                                              ) : (
                                                <Button
                                                  type="button"
                                                  onClick={() => {
                                                    const selectedCompany =
                                                      props.companyList.find(
                                                        (v) => v.id === row.id
                                                      );
                                                    setSelectedCompany(
                                                      selectedCompany.id
                                                    );
                                                    _FetchUnitsForCompanyAssign(
                                                      selectedCompany.id
                                                    );
                                                    openAssignModal();
                                                  }}
                                                  className="btn btn-sm m-1"
                                                >
                                                  {t("Assign Units")}
                                                </Button>
                                              )}

                                              {role === "super_admin" &&
                                              props.companyList?.find(
                                                (v) => v.id === row.id
                                              )?.stacs_id === "null" ? (
                                                <Button
                                                  type="button"
                                                  className="btn btn-sm m-1"
                                                  onClick={() => {
                                                    const selectedCompany =
                                                      props.companyList.find(
                                                        (v) => v.id === row.id
                                                      );
                                                    setSelectedCompany(
                                                      selectedCompany.id
                                                    );
                                                    openRegisterModal();
                                                  }}
                                                >
                                                  {t("Register")}
                                                </Button>
                                              ) : (
                                                ""
                                              )}

                                              {/* <span data-bs-toggle="modal" data-bs-target="#deleteCompanyModal">
                                                                        <button className="btn btn-sm m-1 bg-danger" type="button" style={{color:"#ffffff"}} 
                                                                        onClick={()=> {
                                                                            const selectedCompany = props.companyList.find(v => v.id === row.id);
                                                                            _handleDeleteCompany(selectedCompany) ;
                                                                            _fetchGetAllSameLevelCompanies(row.id);
                                                                            _fetchGetChildCompanies(row.id)}}> Delete </button>
                                                                    </span>
                                                                    
                                                                    
                                                                    
                                                                    {/* <Button type="button"
                                                                        onClick={() => {

                                                                        }}
                                                                        className="btn btn-danger btn-sm m-1"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#confirmationModal"
                                                                        data-toggle="modal"
                                                                        data-target=".bd-example-modal-lg">Delete</Button> */}
                                            </div>
                                          </div>
                                        ) : (
                                          // : (
                                          //     d.includes("/uploads/") ?
                                          //         <img className="bg-white rounded me-2" height={30} src={baseURL + d} />
                                          d
                                        )}
                                      </TableCell>
                                    )
                                )}
                            </StyledTableRow>
                          ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <DeleteCompanyModal
                  theme={theme}
                  _fetchdeleteCompanyInfo={_fetchdeleteCompanyInfo}
                />

                <UpdateCompanyModal
                  theme={theme}
                  selectedCountry={selectedCountry}
                  setSelectedCountry={setSelectedCountry}
                />

                <RegisterCompanyModal theme={theme} userList={userList} />

                {noPagination || (
                  <div className="py-2">
                    <Pagination
                      size="small"
                      renderItem={(item) => (
                        <PaginationItem
                          {...item}
                          sx={{ color: invertDark(theme) }}
                          classes={{ selected: classes.selected }}
                        />
                      )}
                      className={classes.foo}
                      count={
                        rows.length <= rowCount
                          ? 1
                          : Math.ceil(rows.length / rowCount)
                      }
                      defaultPage={1}
                      page={page}
                      siblingCount={1}
                      boundaryCount={1}
                      onChange={handleChange}
                    />
                  </div>
                )}
                <AssignVehiclesCompanyModal theme={theme} user={companyList} />
              </React.Fragment>
            );
        }
        ))
)